const dashboardPageStepsAnnotator = [
    {
        selector: '[data-tour="step-menu"]',
        content: "Ana menü seçenekleri burada listelenir."
    },
    {
        selector: '[data-tour="step-message"]',
        content: "Uygulama içinde platform yöneticisi veya veri sağlayıcılar ile mesajlaşmak için buraya tıklayabilirsiniz."
    },
    {
        position:"left",
        selector: '[data-tour="step-profile"]',
        content: "Avatar resminiz ve kullanıcı adınızın olduğu bu bölümün üzerine geldiğinizde profil sayfanıza gidebileceğiniz veya uygulamadan çıkış yapabileceğiniz bir menü açılır."
    },
    {
        selector: '[data-tour="step-1"]',
        content: "Pano sayfasında varsayılan olarak son 24 saatteki veriler gösterilmektedir. Buradaki filtre alanlarından başlangıç-bitiş tarihine göre filtreleme yapabilirsiniz. Ayrıca grafiklerinizi günlük, haftalık veya aylık seçenekleriyle görüntüleyebilirsiniz. "
    },
    {
        selector: '[data-tour="step-2"]',
        content: "Devam eden görevlerinizi, devam eden işlerinizi, ve tamamlanan işleriniz üzerindeki etiket sayınızı görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-3"]',
        content: "Tamamlamış olduğunuz görevler bu alanda listelenmektedir. Görev başarı puanlarınıza ve görev üzerindeki etiket sayılarınıza detaylar düğmesine basarak ulaşabilirsiniz."
    },
    {
        selector: '[data-tour="step-4"]',
        content: "Etiketleme skoru ve seviye bilgilerinizi bu alanda görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-5"]',
        content: "Tamamlamış oldugunuz işlerin sayısı-zaman grafiğini burada görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-6"]',
        content: "Tamamlamış olduğunuz işler üzerindeki etiket sayılarınız kullanılarak hazırlanan etiket sayısı-zaman grafiğini burada görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-tour-icon"]',
        content: "Açık olan sayfada gezintiye çıkmak için bu butonu kullanabilirsiniz. "
    },

  ];

  export default dashboardPageStepsAnnotator;