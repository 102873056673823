// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Select from 'antd/lib/select';
import { useState, useEffect } from 'react';
import getCore from '../../cvat-core-wrapper';

const cvat = getCore();

interface Props {
    parentMethod: (userList: any) => void;
    mode?:"Default";
    user: any;
}
/**
 *
 * @param props
 * @returns User Selection Template
 */
export default function UserSelectorDashboard(props: Props): JSX.Element {
    const {
        parentMethod,
        mode,
        user
    } = props;
    const [usersSearched, setUsers] = useState([]);

    return (
        <Select
            style={{minWidth: '240px'}}
            allowClear={true}
            size='default'
            placeholder='Kullanıcı Seç'
            showSearch
            autoClearSearchValue
            onSearch={async (value: string): Promise<void> => {
                if(value.length >= 3){
                    let usersAfterSearch;
                    if(user.groups.includes('provider'))
                        usersAfterSearch = await cvat.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value},  null, true);
                    else
                        usersAfterSearch = await cvat.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value},  ["annotator"]);
                    let usersFinal = new Array()
                    for(let i = 0; i < usersAfterSearch.users.length ; i++){
                        usersFinal.push(usersAfterSearch.users[i].id)
                    }
                    setUsers(usersFinal)
                }else{
                    setUsers([])
                }
            }}
            className='dashboard-page-selector'
            onChange={(value: string, option:any): void => {
                if(option)
                    parentMethod(option.key)
                else
                    parentMethod(null)
            }}
        >
            {usersSearched.map((user): JSX.Element => (
                <Select.Option key={user.id} value={user.username}>
                    {user.username}
                </Select.Option>
            ))}
        </Select>
    );
}
