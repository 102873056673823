// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Tabs from 'antd/lib/tabs';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import {
    Table,
    Button,
    Popconfirm,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import Upload, { RcFile } from 'antd/lib/upload';
import Empty from 'antd/lib/empty';
import Tree, { AntTreeNode, TreeNodeNormal } from 'antd/lib/tree/Tree';

import consts from 'consts';
import i18n from '../../i18n';
import { FileResourceTypeSelection } from 'cvat-core/src/enums';

import getCore from 'cvat-core-wrapper';
import { UploaderAdd } from 'icons';
const cvat = getCore();

export interface Files {
    local: File[];
    share: string[];
    remote: string[];
    dataset: any[];
    ssbdataset: any[];
}

interface State {
    files: Files;
    expandedKeys: string[];
    active: string;
    datasets: Object[];
    ssbDatasets: Object[];
    disabled: boolean;
    selectedDatasetId: '',
}

interface Props {
    withRemote: boolean;
    treeData: TreeNodeNormal[];
    onLoadData: (key: string, success: () => void, failure: () => void) => void;
    onLoad: (files: any) => void;
    isModify: boolean;
    parentMethod: (state: boolean) => void;
    getFileType: (state: any) => void;
    user: any;
}

export default class FileManager extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            files: {
                local: [],
                share: [],
                remote: [],
                dataset: [],
                ssbdataset: [],
            },
            expandedKeys: [],
            active: FileResourceTypeSelection.LOCAL,
            datasets: [],
            ssbDatasets: [],
            disabled: false,
            selectedDatasetId: '',
        };

        this.loadData('/');
        this.getDatasets();

        //this.getSSBDatasets();

        this.getFileType = this.getFileType.bind(this);
    }

    /**
     * Set files based on FileResourceTypeSelection type
     * @returns Files consists of local, share, remote, dataset, ssbdataset variables
     */
    public getFiles(): Files {
        const {
            active,
            files,
        } = this.state;
        return {
            local: active === FileResourceTypeSelection.LOCAL ? files.local : [],
            share: active === FileResourceTypeSelection.SHARED ? files.share : [],
            remote: active === FileResourceTypeSelection.REMOTE ? files.remote : [],
            dataset: active === FileResourceTypeSelection.DATASET ? files.dataset : [],
            ssbdataset: active === FileResourceTypeSelection.SSBDATASET ? files.ssbdataset : [],
        };
    }

    /**
     * Get selected dataset
     *
     * @returns selectedDatasetId
     */
    public getSelectedDataset(): any {
        return this.state.selectedDatasetId
    }

    /**
     * Get file type
     * @param type string
     */
    public getFileType(type: string) {
        const { getFileType } = this.props;
    }

    /**
     * Handle loading data
     *
     * @param key string
     * @returns Promise
     */
    private loadData = (key: string): Promise<void> => new Promise<void>(
        (resolve, reject): void => {
            const { onLoadData } = this.props;

            const success = (): void => resolve();
            const failure = (): void => reject();
            onLoadData(key, success, failure);
        },
    );

    /**
     * Clear state
     */
    public reset(): void {
        this.setState({
            expandedKeys: [],
            active: 'local',
            files: {
                local: [],
                share: [],
                remote: [],
                dataset: [],
                ssbdataset: [],
            },
        });
    }

    /**
     * Set datasets state
     */
    private async getDatasets() {
        const datasets = await cvat.datasets.get(null, 'all')
        this.setState({
            datasets: datasets,
        })
    }

    /**
     * Set ssbDatasets state
     */
    private async getSSBDatasets() { //Todo
        const ssbdatasets = await cvat.datasets.getVSPDatasets(null, 'all');
        this.setState({
            ssbDatasets: ssbdatasets,
        })
    }

    /**
     * Render ssbDatasets selector
     * @returns Tabs
     */
    private renderSSBDatasetSelector(): JSX.Element {
        const { files } = this.state
        const { ssbDatasets } = this.state
        const { parentMethod, getFileType } = this.props
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: i18n.t('keywords.name'),
                dataIndex: 'name',
                key: 'name',
                render: text => <a style={{ color: "#0F123F" }}>{text}</a>,
            },
            {
                title: i18n.t('shortcuts-dialog.action'),
                key: 'action',
                render: (record) => (
                    <Popconfirm
                        title={i18n.t('file-manager.useDatasetConfirm')}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        onConfirm={
                            (e) => this.setState({
                                files: {
                                    local: [],
                                    remote: [],
                                    share: [],
                                    dataset: [],
                                    ssbdataset: [record.id]
                                },
                                disabled: true,
                                selectedDatasetId: record.id,
                            },
                                () => {
                                    parentMethod(true)
                                    getFileType(FileResourceTypeSelection.SSBDATASET + " " + ssbDatasets.filter(obj => obj.id === this.state.files.ssbdataset[0])[0].mediaType)
                                }
                            )
                        }
                        disabled={this.state.disabled}
                    >
                        <Button id="select" disabled={this.state.disabled}> {i18n.t('keywords.select')} </Button>
                    </Popconfirm>
                ),
            },
        ];
        return (
            <Tabs.TabPane key={FileResourceTypeSelection.SSBDATASET} tab={i18n.t('file-manager.fromVSPDatasets')}>
                <Table className="custom-table-datasets" rowKey='id' rowClassName={(record, index) => { return "table-row-datasets" }} columns={columns} dataSource={ssbDatasets}></Table>
            </Tabs.TabPane>
        );
    }

    /**
     * Render dataset selector
     *
     * @returns Tabs
     */
    private renderDatasetSelector(): JSX.Element {
        const { files } = this.state
        const { datasets } = this.state
        const { parentMethod, getFileType } = this.props
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: i18n.t('keywords.name'),
                dataIndex: 'name',
                key: 'name',
                render: text => <a style={{ color: "#0F123F" }}>{text}</a>,
            },
            {
                title: i18n.t('shortcuts-dialog.action'),
                key: 'action',
                render: (record) => (
                    <Popconfirm
                        title={i18n.t('file-manager.useDatasetConfirm')}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        onConfirm={
                            (e) => this.setState({
                                files: {
                                    local: [],
                                    remote: [],
                                    share: [],
                                    dataset: [record.id],
                                    ssbdataset: [],
                                },
                                disabled: true,
                                selectedDatasetId: record.id,
                            },
                                () => {
                                    parentMethod(true)
                                    getFileType(FileResourceTypeSelection.DATASET + " " + datasets.filter(obj => obj.id === this.state.files.dataset[0])[0].mediaType)
                                }
                            )
                        }
                        disabled={this.state.disabled}
                    >
                        <Button id="select" size="small" className='button-select' disabled={this.state.disabled}> {i18n.t('keywords.select')} </Button>
                    </Popconfirm>
                ),
            },
        ];
        return (
            <Tabs.TabPane key={FileResourceTypeSelection.DATASET} tab={i18n.t('file-manager.fromDatasets')}>
                <Table className="custom-table-datasets" rowKey='id' rowClassName={(record, index) => { return "table-row-datasets" }} columns={columns} dataSource={datasets}></Table>
            </Tabs.TabPane>
        );
    }

    /**
     * Render local file selector
     *
     * @returns Tabs
     */
    private renderLocalSelector(): JSX.Element {
        const { files } = this.state;
        const { onLoad, parentMethod, getFileType } = this.props

        return (
            <Tabs.TabPane key={FileResourceTypeSelection.LOCAL} tab={i18n.t('file-manager.localFiles')}>
                <Upload.Dragger
                    className="uploader-style"
                    multiple
                    listType='text'
                    fileList={files.local as any[]}
                    showUploadList={files.local.length < 5 && {
                        showRemoveIcon: false,
                    }}
                    beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                        this.setState({
                            files: {
                                local: newLocalFiles,
                                remote: [],
                                share: [],
                                dataset: [],
                                ssbdataset: [],
                            },
                        }, () => {
                            onLoad(this.state.files.local);
                            parentMethod(false)
                            getFileType(this.state.files.local[0].name)
                        });
                        return false;
                    }}
                >
                    <p className='ant-upload-drag-icon'>
                        <Icon component={UploaderAdd} />
                    </p>
                    <Text type="secondary">{i18n.t('file-manager.clickOrDrag')}</Text>
                    <br />
                    <Text type="secondary">
                        {i18n.t('file-manager.multipleFileSupport')}
                    </Text>
                </Upload.Dragger>
                {files.local.length >= 5
                    && (
                        <>
                            <br />
                            <Text className='cvat-text-color'>
                                {i18n.t('{{count}} files selected', { count: files.local.length })}
                            </Text>
                        </>
                    )}
                {(this.state.active === FileResourceTypeSelection.LOCAL) ? getFileType(FileResourceTypeSelection.LOCAL) : ''}
            </Tabs.TabPane>
        );
    }

    /**
     * Render share file selector
     *
     * @returns  Tabs
     */
    private renderShareSelector(): JSX.Element {
        function renderTreeNodes(data: TreeNodeNormal[]): JSX.Element[] {
            return data.map((item: TreeNodeNormal) => {
                if (item.children) {
                    return (
                        <Tree.TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                            isLeaf={item.isLeaf}
                            selectable={item.isLeaf}
                        >
                            {renderTreeNodes(item.children)}
                        </Tree.TreeNode>
                    );
                }

                return <Tree.TreeNode key={item.key} {...item} dataRef={item} />;
            });
        }

        const { SHARE_MOUNT_GUIDE_URL } = consts;
        const { treeData, onLoad, parentMethod, getFileType } = this.props;
        const {
            expandedKeys,
            files,
        } = this.state;

        return (
            <Tabs.TabPane key={FileResourceTypeSelection.SHARED} tab={i18n.t('file-manager.serverFiles')} onTabClicked>
                {treeData[0].children && treeData[0].children.length
                    ? (
                        <Tree
                            className='cvat-share-tree'
                            checkable
                            showLine
                            checkStrictly={false}
                            expandedKeys={expandedKeys}
                            checkedKeys={files.share}
                            loadData={(node: AntTreeNode): Promise<void> => this.loadData(
                                node.props.dataRef.key,
                            )}
                            onExpand={(newExpandedKeys: string[]): void => {
                                this.setState({
                                    expandedKeys: newExpandedKeys,
                                });
                            }}
                            onCheck={
                                (checkedKeys: string[] | {
                                    checked: string[];
                                    halfChecked: string[];
                                }): void => {
                                    const keys = (checkedKeys as string[])
                                    this.setState({
                                        files: {
                                            local: [],
                                            remote: [],
                                            share: keys,
                                            dataset: [],
                                            ssbdataset: [],
                                        },
                                    }, () => {
                                        onLoad();
                                        parentMethod(false)
                                        getFileType(FileResourceTypeSelection.SHARED)
                                    });
                                }
                            }
                        >
                            {renderTreeNodes(treeData)}
                        </Tree>
                    ) : (
                        <div className='uploader-style shared'>
                            <Empty />
                            <Paragraph className='cvat-text-color'>
                                Please, be sure you had
                                <Text strong>
                                    <a style={{ color: "rgb(108, 99, 255)" }} href={SHARE_MOUNT_GUIDE_URL}> mounted </a>
                                </Text>
                                share before you built CVAT and the shared storage contains files
                            </Paragraph>
                            {this.state.active === FileResourceTypeSelection.SHARED ? getFileType(FileResourceTypeSelection.SHARED) : ""}
                        </div>
                    )}

            </Tabs.TabPane>
        );
    }

    /**
     * Render remote file selector
     *
     * @returns  Tabs
     */
    private renderRemoteSelector(): JSX.Element {
        const { files } = this.state;
        const { onLoad, parentMethod, getFileType } = this.props

        return (
            <Tabs.TabPane key={FileResourceTypeSelection.REMOTE} tab={i18n.t('file-manager.remoteFiles')}>
                <Input.TextArea
                    id="URL_area"
                    placeholder={i18n.t('file-manager.oneURLPerLine')}
                    rows={6}
                    className="input-remote-upload"

                    value={[...files.remote].join('\n')}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
                        this.setState({
                            files: {
                                share: [],
                                local: [],
                                dataset: [],
                                remote: event.target.value.split('\n'),
                                ssbdataset: [],
                            },
                        }, () => {
                            onLoad();
                            parentMethod(false);
                            getFileType(this.state.files.remote[0])
                        });
                    }}
                />
                {(this.state.active === FileResourceTypeSelection.REMOTE) ? getFileType(FileResourceTypeSelection.REMOTE) : ''}
            </Tabs.TabPane>
        );
    }

    /**
     * Render all tabs
     *
     * @returns  Tabs
     */
    public render(): JSX.Element {
        const { withRemote, isModify, getFileType } = this.props;
        const { active } = this.state;
        return (
            <>
                <Tabs
                    type='card'
                    className="file-manager"
                    activeKey={active}
                    tabBarGutter={5}
                    onChange={
                        (activeKey: string): void => {
                            this.setState({
                                active: activeKey as any,
                                disabled: false
                            })

                        }
                    }
                >
                    {this.renderLocalSelector()}
                    {/* { this.renderShareSelector() } */}
                    {withRemote && this.renderRemoteSelector()}
                    {!isModify && this.renderDatasetSelector()}
                    {/* { (this.props.user.groups.includes("ssbprovider") || (this.props.user.groups.includes("admin"))) &&!isModify && this.renderSSBDatasetSelector()} */}
                </Tabs>
            </>
        );
    }
}
