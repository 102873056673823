// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import { Row, Col } from 'antd/lib/grid';
import InputNumber from 'antd/lib/input-number';
import { SliderValue } from 'antd/lib/slider';

import { Workspace } from 'reducers/interfaces';
import LeftGroup from './left-group';
import RightGroup from './right-group';
import PlayerNavigation from './player-navigation';
import PlayerButtons from './player-buttons';

interface Props {
    jobStatus: string;
    savingJobStatus: boolean;
    jobInstance: any;
    playing: boolean;
    saving: boolean;
    savingStatuses: string[];
    frameNumber: number;
    frameFilename: string;
    inputFrameRef: React.RefObject<InputNumber>;
    startFrame: number;
    stopFrame: number;
    undoAction?: string;
    redoAction?: string;
    workspace: Workspace;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    playPauseShortcut: string;
    nextFrameShortcut: string;
    previousFrameShortcut: string;
    forwardShortcut: string;
    backwardShortcut: string;
    focusFrameInputShortcut: string;
    changeWorkspace(workspace: Workspace): void;
    hasGuidelinesFile:boolean;
    showStatistics(): void;
    showShortcuts(): void;
    onSwitchPlay(): void;
    onSaveAnnotation(): void;
    onSaveAnnotationWaiting(): Promise<void>,
    onPrevFrame(): void;
    onNextFrame(): void;
    onForward(): void;
    onBackward(): void;
    onFirstFrame(): void;
    onLastFrame(): void;
    onSliderChange(value: SliderValue): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    changeJobStatus(status: string): void;
    downloadTaskGuidelines():void;
    user: any;
    textTask: boolean;
    pcTask:boolean;
}

/**
 * Create component for annotaion top bar
 *
 * @param props
 * @returns Row
 */
export default function AnnotationTopBarComponent(props: Props): JSX.Element {
    const {
        jobInstance,
        saving,
        savingStatuses,
        undoAction,
        redoAction,
        playing,
        frameNumber,
        frameFilename,
        inputFrameRef,
        startFrame,
        stopFrame,
        workspace,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        playPauseShortcut,
        nextFrameShortcut,
        previousFrameShortcut,
        forwardShortcut,
        backwardShortcut,
        focusFrameInputShortcut,
        savingJobStatus,
        jobStatus,
        hasGuidelinesFile,
        user,
        showStatistics,
        showShortcuts,
        changeWorkspace,
        onSwitchPlay,
        onSaveAnnotation,
        onSaveAnnotationWaiting,
        onPrevFrame,
        onNextFrame,
        onForward,
        onBackward,
        onFirstFrame,
        onLastFrame,
        onSliderChange,
        onInputChange,
        onURLIconClick,
        onUndoClick,
        onRedoClick,
        changeJobStatus,
        downloadTaskGuidelines,
        textTask,
        pcTask
    } = props;

    return (
        <Row className="annotation-topbar-row" type='flex' >
            <LeftGroup
                saving={saving}
                savingStatuses={savingStatuses}
                undoAction={undoAction}
                redoAction={redoAction}
                saveShortcut={saveShortcut}
                undoShortcut={undoShortcut}
                redoShortcut={redoShortcut}
                onSaveAnnotation={onSaveAnnotation}
                onUndoClick={onUndoClick}
                onRedoClick={onRedoClick}
                showShortcuts={showShortcuts}
                textTask={textTask}
                pcTask={pcTask}
            />
            <Col className='cvat-annotation-header-player-group'>
                <Row data-tour="step-topbar-player-nav" type='flex' align='middle'>
                    <PlayerButtons
                        playing={playing}
                        playPauseShortcut={playPauseShortcut}
                        nextFrameShortcut={nextFrameShortcut}
                        previousFrameShortcut={previousFrameShortcut}
                        forwardShortcut={forwardShortcut}
                        backwardShortcut={backwardShortcut}
                        onPrevFrame={onPrevFrame}
                        onNextFrame={onNextFrame}
                        onForward={onForward}
                        onBackward={onBackward}
                        onFirstFrame={onFirstFrame}
                        onLastFrame={onLastFrame}
                        onSwitchPlay={onSwitchPlay}
                        textTask={textTask}
                    />
                    <PlayerNavigation
                        startFrame={startFrame}
                        stopFrame={stopFrame}
                        frameNumber={frameNumber}
                        frameFilename={frameFilename}
                        focusFrameInputShortcut={focusFrameInputShortcut}
                        inputFrameRef={inputFrameRef}
                        onSliderChange={onSliderChange}
                        onInputChange={onInputChange}
                        onURLIconClick={onURLIconClick}
                    />
                </Row>
            </Col>
            <RightGroup
                jobStatus = {jobStatus}
                jobInstance = {jobInstance}
                savingJobStatus = {savingJobStatus}
                onSaveAnnotation={onSaveAnnotation}
                onSaveAnnotationWaiting={onSaveAnnotationWaiting}
                saving={saving}
                workspace={workspace}
                changeWorkspace={changeWorkspace}
                showStatistics={showStatistics}
                changeJobStatus={changeJobStatus}
                downloadTaskGuidelines={downloadTaskGuidelines}
                hasGuidelinesFile = {hasGuidelinesFile}
                showShortcuts={showShortcuts}
                user={user}
                textTask={textTask}
            />
        </Row>
    );
}
