/**
 * @description Dataset page user interface tour steps
 */
const datasetsSteps = [
    {
        selector:'.step-dataset-name',
        content:"Veri kümesinin ismini bu kolonda görebilirsiniz. "
    },
    {
        selector:'.step-dataset-mediaType',
        content:"Veri kümesine bulunan veri tipini bu kolonda görebilirsiniz. "
    },
    {
        selector:'.step-dataset-fileCount',
        content:"Veri kümesinde bulunan dosya sayısını bu kolonda görebilirsiniz. "
    },
    {
        selector:'.step-dataset-privacy',
        content:"Veri kümesinin gizli olup olmadığını görüntüleyip, buraya tıklayarak veri kümesinin gizlilik durumunu değiştirebilirsiniz. "
    },
    // {
    //     selector:'tr > td:nth-of-type(5)',
    //     content:"Buraya basarak yönlendirildiğiniz sayfada veri kümesine ekleme yapabilirsiniz.  "
    // },
    // {
    //     selector: '[data-tour="step-expand"]',
    //     content: "Veri kümesi detaylarını görmek için buraya tıklayın. ",
    //     /* action: node => {
    //         node.click();
    //       }, */
    //     highlightedSelectors:['.ant-table-expanded-row'],
    //     mutationObservables:['.ant-table-tbody'],
    // },
    // {
    //     selector: '.ant-table-expanded-row',
    //     content: "Veri seti gizli ise bu bölümde veri setini görev yaratırken kullanabilecek olan veri sağlayıcıları seçebilirsiniz. Eğer gizli değil ise görevin özelliklerini görüntüleyebilirsiniz. ",

    // }

  ];

  export default datasetsSteps;
