/*
* Copyright (C) 2019 Intel Corporation
* SPDX-License-Identifier: MIT
*/

/* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */

/* global
    require:false
*/

(() => {
    const PluginRegistry = require('./plugins');
    const serverProxy = require('./server-proxy');
    const {
        isBoolean,
        isInteger,
        isEnum,
        isString,
        checkFilter,
    } = require('./common');

    const {
        TaskStatus,
        TaskMode,
    } = require('./enums');

    const User = require('./user');
    const AnnotaUser = require('./annotaUser');
    const { AnnotationFormats } = require('./annotation-formats.js');
    const { ArgumentError } = require('./exceptions');
    const { Task } = require('./session');
    const i18n = require('../../cvat-ui/src/i18n');

    function attachUsers(task, users) {
        if (task.assignee !== null) {
            [task.assignee] = users.filter((user) => user.id === task.assignee);
        }

        for (const segment of task.segments) {
            for (const job of segment.jobs) {
                if (job.assignee !== null) {
                    job.assignee = new User(job.assignee)
                }
            }
        }

        if (task.owner !== null) {
            task.owner = new User(task.owner)
        }

        return task;
    }

    function implementAPI(cvat) {
        cvat.plugins.list.implementation = PluginRegistry.list;
        cvat.plugins.register.implementation = PluginRegistry.register.bind(cvat);

        cvat.server.about.implementation = async () => {
            const result = await serverProxy.server.about();
            return result;
        };

        cvat.server.share.implementation = async (directory) => {
            const result = await serverProxy.server.share(directory);
            return result;
        };

        cvat.server.formats.implementation = async () => {
            const result = await serverProxy.server.formats();
            return new AnnotationFormats(result);
        };

        cvat.server.userAgreements.implementation = async () => {
            const result = await serverProxy.server.userAgreements();
            return result;
        };

        cvat.server.getSystemResources.implementation = async () => {
            const result = await serverProxy.server.getSystemResources();
            return result;
        };

        cvat.server.downloadSystemResources.implementation = async (exportFormat) => {
            const result = await serverProxy.server.downloadSystemResources(exportFormat);
            return result;
        };

        cvat.server.getSystemLogList.implementation = async (startDate, endDate, userId, pageNum) => {
            const result = await serverProxy.server.getSystemLogList(startDate, endDate, userId, pageNum);
            return result;
        };

        cvat.server.getSystemLogStats.implementation = async (startDate, endDate, numRecords, level) => {
            const result = await serverProxy.server.getSystemLogStats(startDate, endDate, numRecords, level);
            return result;
        };

        cvat.server.getJobLogs.implementation = async () => {
            const result = await serverProxy.server.getJobLogs();
            return result;
        };

        cvat.server.getDashboardData.implementation = async (startDate, endDate, userId, timeFilter) => {
            const result = await serverProxy.server.getDashboardData(startDate, endDate, userId, timeFilter);
            return result;
        };

        cvat.server.getPaymentsUsers.implementation = async (startDate, endDate) => {
            const result = await serverProxy.server.getPaymentsUsers(startDate, endDate);
            return result;
        };

        cvat.server.makePayment.implementation = async (startDate, endDate, username, receiptForm) => {
            const result = await serverProxy.server.makePayment(startDate, endDate, username, receiptForm);
            return result;
        };

        cvat.server.uploadReceipt.implementation = async (paymentId, receiptForm) => {
            const result = await serverProxy.server.uploadReceipt(paymentId, receiptForm);
            return result;
        };

        cvat.server.deletePayment.implementation = async (paymentId) => {
            const result = await serverProxy.server.deletePayment(paymentId);
            return result;
        };

        cvat.server.getDashboardDataStatistics.implementation = async (taskId, startDate, endDate, userId) => {
            const result = await serverProxy.server.getDashboardDataStatistics(taskId, startDate, endDate, userId);
            return result;
        };

        cvat.users.createSpecialAnnotatorRequest.implementation = async (email) => {
            const result = await serverProxy.users.createSpecialAnnotatorRequest(email);
            return result;
        };

        cvat.server.downloadDashboardData.implementation = async (startDate, endDate, userId, exportFormat) => {
            const result = await serverProxy.server.downloadDashboardData(startDate, endDate, userId, exportFormat);
            return result;
        };

        cvat.server.downloadPaymentsData.implementation = async (startDate, endDate, exportFormat) => {
            const result = await serverProxy.server.downloadPaymentsData(startDate, endDate, exportFormat);
            return result;
        };

        cvat.server.downloadReceipt.implementation = async (paymentId) => {
            const result = await serverProxy.server.downloadReceipt(paymentId);
            return result;
        };

        cvat.server.downloadTaskGuidelines.implementation = async (taskId) => {
            const result = await serverProxy.server.downloadTaskGuidelines(taskId);
            return result;
        };

        cvat.server.sendModelExportInfoToYZP.implementation = async (taskId, exportType) => {
            const result = await serverProxy.server.sendModelExportInfoToYZP(taskId, exportType);
            return result;
        };

        cvat.server.register.implementation = async (username, firstName, lastName,
            email, password1, password2, userConfirmations) => {
            await serverProxy.server.register(username, firstName, lastName, email,
                password1, password2, userConfirmations);
        };

        cvat.server.validateCaptcha.implementation = async (token) => {
            const result = await serverProxy.server.validateCaptcha(token);
            return result;
        };

        cvat.server.annotaRegisterSpecialAnnotator.implementation = async (username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
            smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
            certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType, uuid) => {
            const result = await serverProxy.server.annotaRegisterSpecialAnnotator(username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType, uuid);
            return result;
        };

        cvat.server.annotaRegister.implementation = async (username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
            smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
            certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType) => {
            const result = await serverProxy.server.annotaRegister(username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType);
            return result;
        };

        cvat.server.verifyInformation.implementation = async (username,firstName,lastName, tckn, tcserial) => {
            const result = await serverProxy.server.verifyInformation(username,firstName,lastName, tckn, tcserial);
            return result;
        };

        cvat.server.login.implementation = async (username, password, captchaToken) => {
            await serverProxy.server.login(username, password, captchaToken);
        };

        cvat.server.logout.implementation = async () => {
            await serverProxy.server.logout();
        };

        cvat.server.reset.implementation = async (email) => {
            const result = await serverProxy.server.reset(email);
            return result;
        };

        cvat.server.resetConfirm.implementation = async (uidb64, token, password1, password2) => {
            const result = await serverProxy.server.resetConfirm(uidb64, token, password1, password2);
            return result;
        };

        cvat.server.authorized.implementation = async () => {
            const result = await serverProxy.server.authorized();
            return result;
        };

        cvat.server.request.implementation = async (url, data) => {
            const result = await serverProxy.server.request(url, data);
            return result;
        };

        cvat.users.get.implementation = async (filter) => {
            checkFilter(filter, {
                self: isBoolean,
                page: isInteger,
            });

            let users = null;
            if ('self' in filter && filter.self) {
                users = await serverProxy.users.getSelf();
                users = [users];
            }
            else if('page' in filter){
                users = await serverProxy.users.getUsers(null,filter.page);
            } else {
                users = await serverProxy.users.getUsers();
            }

            users = users.map((user) => new User(user));
            return users;
        };

        cvat.tasks.expandDataset.implementation = async (files,id,maskPoints) => {
            response = await serverProxy.tasks.expandDataset(files,id,maskPoints);
            return response;
        };

        cvat.tasks.assigntask.implementation = async (taskId,userId) => {
            response = await serverProxy.tasks.assigntask(taskId,userId);
            return response;
        };

        cvat.tasks.assigntaskRandom.implementation = async (taskId,userId) => {
            response = await serverProxy.tasks.assigntaskRandom(taskId,userId);
            return response;
        };

        cvat.tasks.setassigneelimit.implementation = async (taskId,assigneeCount) => {
            response = await serverProxy.tasks.setassigneelimit(taskId,assigneeCount);
            return response;
        };

        cvat.tasks.setTaskDifficulty.implementation = async (taskId,taskDifficulty) => {
            response = await serverProxy.tasks.setTaskDifficulty(taskId,taskDifficulty);
            return response;
        };

        cvat.datasets.downloadDataset.implementation = async (setId, onDownloadProgressStatus) => {
            response = await serverProxy.datasets.downloadDataset(setId, onDownloadProgressStatus);
            return response;
        };

        cvat.tasks.downloadAnnotationImages.implementation = async (id,size) => {
            response = await serverProxy.tasks.downloadAnnotationImages(id,size);
            return response;
        };

        cvat.jobs.trainingstate.implementation = async (userId,jobId,state) => {
            response = await serverProxy.jobs.trainingstate(userId,jobId,state);
            return response;
        };

        cvat.jobs.selecttraining.implementation = async (userId,taskId) => {
            response = await serverProxy.jobs.selecttraining(userId,taskId);
            return response;
        };

        cvat.jobs.selecttest.implementation = async (userId,taskId) => {
            response = await serverProxy.jobs.selecttest(userId,taskId);
            return response;
        };

        cvat.jobs.teststate.implementation = async (userId,jobId,state) => {
            response = await serverProxy.jobs.teststate(userId,jobId,state);
            return response;
        };

        cvat.jobs.getTrainingList.implementation = async (state, userId) => {
            response = await serverProxy.jobs.getTrainingList(state, userId);
            return response;
        };

        cvat.jobs.getTrainingTestTasks.implementation = async (taskType) => {
            response = await serverProxy.jobs.getTrainingTestTasks(taskType);
            return response;
        };

        cvat.datasets.get.implementation = async (setId, count, pageNum, datasetViewType) => {
            response = await serverProxy.datasets.getDatasets(setId, count, pageNum, datasetViewType);
            return response;
        };

        cvat.datasets.getVSPDatasets.implementation = async (setId, count, pageNum) => {
            response = await serverProxy.datasets.getVSPDatasets(setId, count, pageNum);
            return response;
        };

        cvat.datasets.getCkanDatasets.implementation = async (name) => {
            response = await serverProxy.datasets.getCkanDatasets(name);
            return response;
        };

        cvat.tasks.getTextAnnotations.implementation = async (projectId, username) => {
            response = await serverProxy.tasks.getTextAnnotations(projectId, username);
            return response;
        };

        cvat.tasks.deleteTextAnnotations.implementation = async (projectId, username) => {
            response = await serverProxy.tasks.deleteTextAnnotations(projectId, username);
            return response;
        };

        cvat.datasets.deleteDataset.implementation = async (setId) => {
            response = await serverProxy.datasets.deleteDataset(setId);
            return response;
        };

        cvat.datasets.updateDatasetName.implementation = async (setId, setName) => {
            response = await serverProxy.datasets.updateDatasetName(setId, setName);
            return response;
        };

        cvat.datasets.trackObject.implementation = async (data) => {
            response = await serverProxy.datasets.trackObject(data);
            return response;
        };

        cvat.datasets.setPrivacy.implementation = async (id) => {
            response = await serverProxy.datasets.setPrivacy(id);
            return response;
        };

        cvat.tasks.setPrivacyTask.implementation = async (id) => {
            response = await serverProxy.tasks.setPrivacyTask(id);
            return response;
        };

        cvat.datasets.setUsers.implementation = async (id,users) => {
            response = await serverProxy.datasets.setUsers(id,users);
            return response;
        };

        cvat.tasks.setUsersTask.implementation = async (id,users) => {
            response = await serverProxy.tasks.setUsersTask(id,users);
            return response;
        };

        cvat.tasks.removeUsersTask.implementation = async (id,users) => {
            response = await serverProxy.tasks.removeUsersTask(id,users);
            return response;
        };

        cvat.tasks.setViewersTask.implementation = async (id,users) => {
            response = await serverProxy.tasks.setViewersTask(id,users);
            return response;
        };

        cvat.tasks.removeViewersTask.implementation = async (id,users) => {
            response = await serverProxy.tasks.removeViewersTask(id,users);
            return response;
        };

        cvat.users.getAnnotaSelf.implementation = async (userId) => {
            response = await serverProxy.users.getAnnotaSelf(userId);
            return response;
        };

        cvat.users.updateUser.implementation = async (userId, fields) => {
            response = await serverProxy.users.updateUser(userId, fields);
            return response;
        };

        cvat.users.updateUserPatch.implementation = async (userId, fields) => {
            response = await serverProxy.users.updateUserPatch(userId, fields);
            return response;
        };

        cvat.users.updateInformations.implementation = async (userId, fields) => {
            response = await serverProxy.users.updateInformations(userId, fields);
            return response;
        };

        cvat.users.updateAvatarId.implementation = async (userId, avatarId) => {
            response = await serverProxy.users.updateAvatarId(userId, avatarId);
            return response;
        };

        cvat.users.updatePhoto.implementation = async (userId, photo) => {
            response = await serverProxy.users.updatePhoto(userId, photo);
            return response;
        }

        cvat.users.changePassword.implementation = async (userId, pass, newPass) => {
            response = await serverProxy.users.changePassword(userId, pass, newPass);
            return response;
        };

        cvat.datasets.removeUser.implementation = async (id,userId) => {
            response = await serverProxy.datasets.removeUser(id,userId);
            return response;
        };

        cvat.users.approve.implementation = async (userId) => {
            response = await serverProxy.users.approve(userId);
            return response;
        };

        cvat.users.reject.implementation = async (userId) => {
            response = await serverProxy.users.reject(userId);
            return response;
        };

        cvat.users.forgive.implementation = async (userId) => {
            response = await serverProxy.users.forgive(userId);
            return response;
        };

        cvat.users.demote.implementation = async (userId) => {
            response = await serverProxy.users.demote(userId);
            return response;
        }

        cvat.users.promote.implementation = async (userId) => {
            response = await serverProxy.users.promote(userId);
            return response;
        };

        cvat.users.bless.implementation = async (userId) => {
            response = await serverProxy.users.bless(userId);
            return response;
        };

        cvat.users.curse.implementation = async (userId) => {
            response = await serverProxy.users.curse(userId);
            return response;
        };

        cvat.users.block.implementation = async (userId) => {
            response = await serverProxy.users.block(userId);
            return response;
        };

        cvat.users.unblock.implementation = async (userId) => {
            response = await serverProxy.users.unblock(userId);
            return response;
        };

        cvat.users.give.implementation = async (userId) => {
            response = await serverProxy.users.give(userId);
            return response;
        };

        cvat.users.take.implementation = async (userId) => {
            response = await serverProxy.users.take(userId);
            return response;
        };

        cvat.users.makeDP.implementation = async (userId) => {
            response = await serverProxy.users.makeDP(userId);
            return response;
        };

        cvat.users.makeSA.implementation = async (userId) => {
            response = await serverProxy.users.makeSA(userId);
            return response;
        };

        cvat.users.takeDP.implementation = async (userId) => {
            response = await serverProxy.users.takeDP(userId);
            return response;
        };

        cvat.users.makeSSBDP.implementation = async (userId) => {
            response = await serverProxy.users.makeSSBDP(userId);
            return response;
        };

        cvat.users.takeSSBDP.implementation = async (userId) => {
            response = await serverProxy.users.takeSSBDP(userId);
            return response;
        };
        cvat.users.takeSA.implementation = async (userId) => {
            response = await serverProxy.users.takeSA(userId);
            return response;
        };

        cvat.users.makeAnnotator.implementation = async (userId) => {
            response = await serverProxy.users.makeAnnotator(userId);
            return response;
        };

        cvat.users.takeAnnotator.implementation = async (userId) => {
            response = await serverProxy.users.takeAnnotator(userId);
            return response;
        };

        cvat.users.addRole.implementation = async (userId, role) => {
            response = await serverProxy.users.addRole(userId, role);
            return response;
        };

        cvat.users.removeRole.implementation = async (userId, role) => {
            response = await serverProxy.users.removeRole(userId, role);
            return response;
        };

        cvat.users.createVerification.implementation = async (correlation_id,verificationType,phoneNumber,email, emailCode, token) => {
            response = await serverProxy.users.createVerification(correlation_id,verificationType,phoneNumber,email, emailCode, token);
            return response;
        };

        cvat.users.createVerificationSpecialAnnotator.implementation = async (correlation_id,verificationType,phoneNumber,email, emailCode, token, uuid) => {
            response = await serverProxy.users.createVerificationSpecialAnnotator(correlation_id,verificationType,phoneNumber,email, emailCode, token, uuid);
            return response;
        };

        cvat.users.createContactMail.implementation = async (targetEmail, userEmail, fullName, message) => {
            response = await serverProxy.users.createContactMail(targetEmail, userEmail, fullName, message);
            return response;
        };

        cvat.users.checkVerification.implementation = async (correlation_id,target,code,type) => {
            response = await serverProxy.users.checkVerification(correlation_id,target,code,type);
            return response;
        };

        cvat.users.verifyCode.implementation = async (correlation_id,target,code) => {
            response = await serverProxy.users.verifyCode(correlation_id,target,code);
            return response;
        };

        cvat.users.getAnnotaUsers.implementation = async (userId,pageNum,state,level,expert,isBlocked,getAll,isSuperuser,filter,role, isDPDashBoardSearch) => {
            checkFilter(filter, {
                name: isString,
                id: isInteger,
                search: isString,
            });

            const searchParams = new URLSearchParams();
            if(filter != null) {
                if ('search' in filter && Object.keys(filter).length > 1) {
                    if (!('page' in filter && Object.keys(filter).length === 2)) {
                        throw new ArgumentError(
                            i18n.default.t("api-impl.dontUseSearchWOthers"),
                        );
                    }
                }
                for (const field of ['name','search','id']) {
                    if (Object.prototype.hasOwnProperty.call(filter, field)) {
                        searchParams.set(field, filter[field]);
                    }
                }
            }

            let users = null;
            let count = null;
            users = await serverProxy.users.getAnnotaUsers(userId,pageNum,state,level,expert,isBlocked,getAll,isSuperuser,searchParams.toString(),role, isDPDashBoardSearch);
            count = users.count;
            if(userId != null){
                users = [users]
                users = users.map((user) => new AnnotaUser(user));
            }
            else{
                users = users.results.map((user) => new AnnotaUser(user));
            }

            return {users: users,
                    count: count };
        };

        cvat.management.getFaq.implementation = async () => {
            response = await serverProxy.management.getFaq();
            return response;
        };
        cvat.management.getLevelScoreIntervals.implementation = async () => {
            response = await serverProxy.management.getLevelScoreIntervals();
            return response;
        };
        cvat.management.updateLevelScoreIntervals.implementation = async (levelScoreIntervals) => {
            response = await serverProxy.management.updateLevelScoreIntervals(levelScoreIntervals);
            return response;
        };
        cvat.management.checkUpdateLevelScoreIntervalsStatus.implementation = async (id) => {
            response = await serverProxy.management.checkUpdateLevelScoreIntervalsStatus(id);
            return response;
        };
        cvat.management.getRoles.implementation = async (pageNum) => {
            response = await serverProxy.management.getRoles(pageNum);
            return response;
        };
        cvat.management.getPermissions.implementation = async () => {
            response = await serverProxy.management.getPermissions();
            return response;
        };
        cvat.management.createRole.implementation = async (roleName,permissions) => {
            response = await serverProxy.management.createRole(roleName,permissions);
            return response;
        };
        cvat.management.deleteRole.implementation = async (id) => {
            response = await serverProxy.management.deleteRole(id);
            return response;
        };
        cvat.management.updateRole.implementation = async (id,permissions) => {
            response = await serverProxy.management.updateRole(id,permissions);
            return response;
        };
        cvat.management.createFaq.implementation = async (number, question, answer) => {
            response = await serverProxy.management.createFaq(number, question, answer);
            return response;
        };
        cvat.management.updateFaq.implementation = async (id, number, question, answer) => {
            response = await serverProxy.management.updateFaq(id, number, question, answer);
            return response;
        };
        cvat.management.deleteFaq.implementation = async (id) => {
            response = await serverProxy.management.deleteFaq(id);
            return response;
        };
        cvat.management.getRegistrationStatus.implementation = async () => {
            response = await serverProxy.management.getRegistrationStatus();
            return response;
        };
        cvat.management.getRegistrationStatusPublic.implementation = async () => {
            response = await serverProxy.management.getRegistrationStatusPublic();
            return response;
        };
        cvat.management.updateRegistrationStatus.implementation = async (status) => {
            response = await serverProxy.management.updateRegistrationStatus(status);
            return response;
        };
        cvat.management.getThresholdStatus.implementation = async () => {
            response = await serverProxy.management.getThresholdStatus();
            return response;
        };
        cvat.management.updateThresholdStatus.implementation = async (status) => {
            response = await serverProxy.management.updateThresholdStatus(status);
            return response;
        };

        cvat.management.getJobThresholdStatus.implementation = async () => {
            response = await serverProxy.management.getJobThresholdStatus();
            return response;
        };

        cvat.management.getSessionTimeoutDuration.implementation = async () => {
            response = await serverProxy.management.getSessionTimeoutDuration();
            return response;
        }

        cvat.management.getPaymentLimitStatus.implementation = async () => {
            response = await serverProxy.management.getPaymentLimitStatus();
            return response;
        };

        cvat.management.getJobCompleteTimesStatus.implementation = async () => {
            response = await serverProxy.management.getJobCompleteTimesStatus();
            return response;
        };

        cvat.management.updateJobThresholdStatus.implementation = async (status) => {
            response = await serverProxy.management.updateJobThresholdStatus(status);
            return response;
        };

        cvat.management.updatePaymentLimitStatus.implementation = async (minimum, maximum) => {
            response = await serverProxy.management.updatePaymentLimitStatus(minimum, maximum);
            return response;
        };

        cvat.management.updateJobCompleteTimesStatus.implementation = async (annotation_job, evaluation_job) => {
            response = await serverProxy.management.updateJobCompleteTimesStatus(annotation_job, evaluation_job);
            return response;
        };

        cvat.management.getAnnotationPriceStatus.implementation = async () => {
            response = await serverProxy.management.getAnnotationPriceStatus();
            return response;
        };
        cvat.management.updateAnnotationPriceStatus.implementation = async (status) => {
            response = await serverProxy.management.updateAnnotationPriceStatus(status);
            return response;
        };

        cvat.management.updateSessionTimeoutDuration.implementation = async (sessionTimeoutDuration) => {
            response = await serverProxy.management.updateSessionTimeoutDuration(sessionTimeoutDuration);
            return response;
        };

        cvat.management.updatePaymentConstant.implementation = async (value) => {
            response = await serverProxy.management.updatePaymentConstant(value);
            return response;
        };

        cvat.management.getPaymentConstant.implementation = async () => {
            response = await serverProxy.management.getPaymentConstant();
            return response;
        };

        cvat.management.updateAutoSaveDuration.implementation = async (value) => {
            response = await serverProxy.management.updateAutoSaveDuration(value);
            return response;
        };

        cvat.management.getAutoSaveDuration.implementation = async () => {
            response = await serverProxy.management.getAutoSaveDuration();
            return response;
        };

        cvat.messages.getMessageUsersInteracted.implementation = async () => {
            response = await serverProxy.messages.getMessageUsersInteracted();
            return response;
        };

        cvat.messages.getMessages.implementation = async (userId, pageNum) => {
            response = await serverProxy.messages.getMessages(userId, pageNum);
            return response;
        };

        cvat.messages.createMessage.implementation = async (recipientId, content) => {
            response = await serverProxy.messages.createMessage(recipientId, content);
            return response;
        };

        cvat.messages.markMessages.implementation = async (senderId) => {
            response = await serverProxy.messages.markMessages(senderId);
            return response;
        };

        cvat.jobs.get.implementation = async (filter) => {
            checkFilter(filter, {
                taskID: isInteger,
                jobID: isInteger,
            });

            if (('taskID' in filter) && ('jobID' in filter)) {
                throw new ArgumentError(
                    i18n.default.t("api-impl.onlyOneFieldAllowed"),
                );
            }

            if (!Object.keys(filter).length) {
                throw new ArgumentError(
                    i18n.default.t("api-impl.jobMustntBeEmpty"),
                );
            }

            let tasks = null;
            if ('taskID' in filter) {
                tasks = await serverProxy.tasks.getTasks(`id=${filter.taskID}`);
            } else {
                const job = await serverProxy.jobs.getJob(filter.jobID);
                if (typeof (job.task_id) !== 'undefined') {
                    tasks = await serverProxy.tasks.getTasks(`id=${job.task_id}`);
                }
            }

            // If task was found by its id, then create task instance and get Job instance from it
            if (tasks !== null && tasks.length) {
                const users = (await serverProxy.users.getUsers())
                    .map((userData) => new User(userData));
                const task = new Task(attachUsers(tasks[0], users));

                return filter.jobID ? task.jobs
                    .filter((job) => job.id === filter.jobID) : task.jobs;
            }

            return [];
        };

        cvat.jobs.getUserJobs.implementation = async (userId) => {
            response = await serverProxy.jobs.getUserJobs(userId);
            return response;
        };

        cvat.jobs.getUserJobsStatistics.implementation = async (userId,timeFilter) => {
            response = await serverProxy.jobs.getUserJobsStatistics(userId, timeFilter);
            return response;
        };

        cvat.jobs.getTaskJobs.implementation = async (taskId) => {
            response = await serverProxy.jobs.getTaskJobs(taskId);
            return response;
        };

        cvat.jobs.getTrainingJobs.implementation = async (state) => {
            response = await serverProxy.jobs.getTrainingJobs(state);
            return response;
        };

        cvat.jobs.getTestJobs.implementation = async (state,userId, pageNum) => {
            response = await serverProxy.jobs.getTestJobs(state,userId, pageNum);
            return response;
        };

        cvat.tasks.get.implementation = async (filter, tasksViewType, taskType, onlyTasks, annotaTaskStatus, taskClassificationType, expiredOnly) => {
            checkFilter(filter, {
                page: isInteger,
                name: isString,
                id: isInteger,
                owner: isString,
                assignee: isString,
                search: isString,
                status: isEnum.bind(TaskStatus),
                mode: isEnum.bind(TaskMode),
            });

            if ('search' in filter && Object.keys(filter).length > 1) {
                if (!('page' in filter && Object.keys(filter).length === 2)) {
                    throw new ArgumentError(
                        i18n.default.t("api-impl.dontUseSearchWOthers"),
                    );
                }
            }

            if ('id' in filter && Object.keys(filter).length > 1) {
                if (!('page' in filter && Object.keys(filter).length === 2)) {
                    throw new ArgumentError(
                        i18n.default.t("api-impl.dontUseIdWOthers"),
                    );
                }
            }

            const searchParams = new URLSearchParams();
            for (const field of ['name', 'owner', 'assignee', 'search', 'status', 'mode', 'id', 'page']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams.set(field, filter[field]);
                }
            }

            const users = []
            const tasksData = await serverProxy.tasks.getTasks(searchParams.toString(), tasksViewType, taskType, onlyTasks, annotaTaskStatus, taskClassificationType, expiredOnly);
            const tasks = tasksData
                .map((task) => attachUsers(task, users))
                .map((task) => new Task(task));


            tasks.count = tasksData.count;

            return tasks;
        };

        cvat.tasks.getAnnotaTasks.implementation = async (state, taskId, pageNum,filter) => {

            checkFilter(filter, {
                name: isString,
                id: isInteger,
                search: isString,
            });

            if ('search' in filter && Object.keys(filter).length > 1) {
                if (!('page' in filter && Object.keys(filter).length === 2)) {
                    throw new ArgumentError(
                        i18n.default.t("api-impl.dontUseSearchWOthers"),
                    );
                }
            }

            const searchParams = new URLSearchParams();
            for (const field of ['name','search','id']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams.set(field, filter[field]);
                }
            }

            response = await serverProxy.tasks.getAnnotaTasks(state, taskId, pageNum,searchParams.toString());
            return response;
        };

        cvat.tasks.approveTask.implementation = async (taskId) => {
            response = await serverProxy.tasks.approveTask(taskId);
            return response;
        };

        cvat.frames.getPreview.implementation = async (tid) => {
            response = await serverProxy.frames.getPreview(tid);
            return response;
        };

        cvat.tasks.getTaskPath.implementation = async (taskId) => {
            response = await serverProxy.tasks.getTaskPath(taskId);
            return response;
        };

        cvat.tasks.getTextTaskDataset.implementation = async (projectId,labels=null) => {
            response = await serverProxy.tasks.getTextTaskDataset(projectId, labels);
            return response;
        };

        cvat.tasks.getPCDAnnotations.implementation = async (dataId, username, jobId, fileMode) => {
            response = await serverProxy.tasks.getPCDAnnotations(dataId, username, jobId, fileMode);
            return response;
        };

        cvat.tasks.rejectTask.implementation = async (taskId) => {
            response = await serverProxy.tasks.rejectTask(taskId);
            return response;
        };

        cvat.tasks.setTaskType.implementation = async (taskId,taskType) => {
            response = await serverProxy.tasks.setTaskType(taskId,taskType);
            return response;
        }

        cvat.tasks.getPointCloudTasks.implementation = async () => {
            response = await serverProxy.tasks.getPointCloudTasks();
            return response;
        }

        cvat.groups.getGroups.implementation = async (userId, groupId) => {
            response = await serverProxy.groups.getGroups(userId, groupId);
            return response;
        }
        cvat.groups.getSpecialAnnotators.implementation = async () => {
            response = await serverProxy.groups.getSpecialAnnotators();
            return response;
        }
        cvat.groups.deleteGroup.implementation = async (id) => {
            response = await serverProxy.groups.deleteGroup(id);
            return response;
        }
        cvat.jobs.deleteJob.implementation = async (id) => {
            response = await serverProxy.jobs.deleteJob(id);
            return response;
        }
        cvat.groups.createNewGroup.implementation = async (userId, groupName) => {
            response = await serverProxy.groups.createNewGroup(userId, groupName);
            return response;
        }
        cvat.groups.setGroupUsers.implementation = async(groupId, users) => {
            response = await serverProxy.groups.setGroupUsers(groupId, users);
            return response;
        }
        cvat.users.setUserPermissions.implementation = async(userId,permissions) => {
            response = await serverProxy.users.setUserPermissions(userId,permissions);
            return response;
        }
        cvat.groups.removeGroupUsers.implementation = async(groupId,users) => {
            response = await serverProxy.groups.removeGroupUsers(groupId, users);
            return response;
        }
        cvat.groups.updateGroupName.implementation = async(id,name) => {
            response = await serverProxy.groups.updateGroupName(id, name);
            return response;
        }

        return cvat;
    }

    module.exports = implementAPI;
})();
