import React from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form,
    Button,
    Input,
    Icon,
    Row,
    Col,
    Modal
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
const core = getCore();

let spanSize = ((Number)(13000/window.innerWidth))| 0;

class RegisterHelpComponent extends React.PureComponent<FormComponentProps> {

    sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 10 },
        xl: { span: spanSize },
    };

    constructor(props: any) {
        super(props);
        this.state = {
            charCount: 0
        };
    }

    evalCharCount = e => {
        this.setState({
          charCount: e.target.value.length,
        });
    };

    /**
     * Handle submiting form

     * @param e FormEvent
     */
    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const { form } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                //DO SUBMIT WORK
                this.handleSendContact()
            }
        });
    };

    /**
     * Render name field
     *
     * @returns Form Item
     */
    private renderNameField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('fullName', {
                    rules: [{
                        required: true,
                        message: 'Lütfen adınızı ve soyadınızı giriniz.',
                    }],
                })(
                    <Input
                        autoComplete='name'
                        prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder='Ad ve Soyad'
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render email field
     *
     * @returns Form Item
     */
    private renderEmailField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item>
                {form.getFieldDecorator('email', {
                    rules: [{
                        type: 'email',
                        message: 'Geçersiz e-posta adresi!',
                    }, {
                        required: true,
                        message: 'Lütfen e-posta adresinizi girin',
                    }],
                })(
                    <Input
                        autoComplete='email'
                        prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder='E-posta adresi'
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render message field
     *
     * @returns Form Item
     */
    private renderMessageField(): JSX.Element {
        const { form } = this.props;

        return (
            <div>
                <Form.Item>
                    {form.getFieldDecorator('message', {
                        rules: [{
                            required: true,
                            message: 'Lütfen mesajınızı girin',
                        }],
                    })(
                        <TextArea
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            placeholder='Mesajınız'
                            onChange = {this.evalCharCount}
                        />
                    )}
                </Form.Item>
                <text style={{float:'right'}}>Karakter Sayısı: {this.state.charCount}/4000</text>
            </div>
        );
    }

    /**
     * Handles send contact
     *
     * @returns Form Item
     */
    private async handleSendContact(){
        const { form } = this.props;
        form.getFieldValue('fullName')
        let contactRequest = await core.users.createContactMail("destek@verikovani.ssb.gov.tr",form.getFieldValue('email'),form.getFieldValue('fullName'),form.getFieldValue('message'))
        let response = contactRequest.status;
        if(response != "Success"){
            Modal.error({
                title: 'Hata!',
                content: response,
            });
        }
        else{
            Modal.success({
                title: 'Bilgi!',
                content: contactRequest.message,
                onOk() {
                  window.location.assign('/home')
                },
            });
        }
      }

    /**
     * Render contact us from
     *
     * @returns Form within div
     */
    public render(): JSX.Element {

        return (
            <Row type='flex' justify='center' align='middle'>
                <Col {...this.sizes}>
                    <Form onSubmit={this.handleSubmit} className='login-form'>
                        <a href={window.location.href.split('/')[0] + '/home'}><img src='/veri_kovani_logo.svg' width='100%' vspace="50"></img></a>
                        <Title level={3}>Bize Ulaşın</Title>
                        {this.renderNameField()}
                        {this.renderEmailField()}
                        {this.renderMessageField()}

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                            >
                                Gönder
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

        );

    }
}

export default Form.create<FormComponentProps>()(RegisterHelpComponent);
