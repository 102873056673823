// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT


const validationPatterns = {
    validatePasswordLength: {
        pattern: /(?=.{8,})/,
        message: 'Şifreniz en az 8 karakterden oluşmalı',
    },

    passwordContainsNumericCharacters: {
        pattern: /(?=.*[0-9])/,
        message: 'Şifreniz en az bir rakam içermeli',
    },

    passwordContainsUpperCaseCharacter: {
        pattern: /(?=.*[A-ZĞÜŞÖÇİ])/,
        message: 'Şifreniz en az bir büyük harf içermeli',
    },

    passwordContainsSpecialCharacter: {
        pattern: /(?=.*[[~!@#$%^&*()_+{}\":;.,'])/,
        message: 'Şifreniz en az bir özel karakter içermeli',
    },

    passwordContainsLowerCaseCharacter: {
        pattern: /(?=.*[a-zığüşöç])/,
        message: 'Şifreniz en az bir küçük harf içermeli',
    },

    validateUsernameLength: {
        pattern: /(?=.{5,})/,
        message: 'Kullanıcı adı en az 5 karakterden oluşmalı',
    },

    validateUsernameCharacters: {
        pattern: /^[a-zA-Z0-9_-ığüşöçĞÜŞÖÇİ]{5,}$/,
        message: 'Sadece (a-z), (A-Z), (0-9) karakterleri kullanılabilir',
    },

    /*
        \p{Pd} - dash connectors
        \p{Pc} - connector punctuations
        \p{Cf} - invisible formatting indicator
        \p{L} - any alphabetic character
        Useful links:
        https://stackoverflow.com/questions/4323386/multi-language-input-validation-with-utf-8-encoding
        https://stackoverflow.com/questions/280712/javascript-unicode-regexes
        https://stackoverflow.com/questions/6377407/how-to-validate-both-chinese-unicode-and-english-name
    */
    validateName: {
        // eslint-disable-next-line
        pattern: /^[a-zA-ZığüşöçĞÜŞÖÇİ]{2,}(([',. -][a-zA-ZığüşöçĞÜŞÖÇİ ])?[a-zA-ZığüşöçĞÜŞÖÇİ]+)*$/,
        message: 'Geçersiz ad',
    },

    validateAttributeName: {
        pattern: /\S+/,
        message: 'Geçersiz nitelik adı',
    },

    validateLabelName: {
        pattern: /\S+/,
        message: 'Geçersiz etiket adı',
    },

    validateAttributeValue: {
        pattern: /\S+/,
        message: 'Geçersiz nitelik değeri',
    },

    validateURL: {
        // eslint-disable-next-line
        pattern: /^((https?:\/\/)|(git@))[^\s$.?#].[^\s]*$/, // url, ssh url, ip
        message: 'Geçersiz URL',
    },

    validatePath: {
        // eslint-disable-next-line
        pattern: /^\[\/?([A-z0-9-_+]+\/)*([A-z0-9]+\.(xml|zip|json))\]$/,
        message: 'Geçersiz Git yolu',
    },

    validatePhoneNumber: {
        pattern: /^(\d{10})$/,
        message: 'Geçersiz telefon numarası',
    },

    validatePostCode: {
        pattern: /^(\d{5})$/,
        message: 'Geçersiz posta kodu',
    },

    validateTCKN: {
        pattern: /^(\d{11})$/,
        message: 'Geçersiz TC kimlik numarası',
    },
    validateIBAN: {
        pattern: /^(\d{24})$/,
        message: 'Geçersiz IBAN',
    },

    validateTCSerial: {
        pattern: /^(\?[A-Z])(\d{8})$/,
        message: 'Geçersiz seri numarası',
    },

    validateNewFaqNumber: {
        pattern: /^(\d)*$/,
        message: 'Geçersiz soru numarası',
    },
};

export default validationPatterns;
