// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import JobListComponent from 'components/task-page/job-list';
import { updateJobAsync } from 'actions/tasks-actions';
import {
    Task,
    CombinedState,
} from 'reducers/interfaces';

interface OwnProps {
    task: Task;
    taskPath: any;
    annotaTask: any
}

interface StateToProps {
    registeredUsers: any[];
    annotaUser: any,
    user: any,
}

interface DispatchToProps {
    onJobUpdate(jobInstance: any): void;
}

/**
 *
 * @param state -> combined state containing user information
 * @returns registered users, annota user and user information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    return {
        registeredUsers: state.users.users,
        annotaUser: state.auth.annotaUser,
        user: state.auth.user
    };
}

/**
 *
 * @param dispatch
 * @returns onJobUpdate action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onJobUpdate: (jobInstance: any): void => dispatch(updateJobAsync(jobInstance)),
    };
}

function TaskPageContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        task,
        registeredUsers,
        onJobUpdate,
        annotaUser,
        user,
        taskPath,
        annotaTask
    } = props;

    return (
        <JobListComponent
            taskInstance={task.instance}
            registeredUsers={registeredUsers}
            onJobUpdate={onJobUpdate}
            annotaUser= {annotaUser}
            user={user}
            taskPath={taskPath}
            annotaTask={annotaTask}
        />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskPageContainer);
