// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Tooltip from 'antd/lib/tooltip';
import Select from 'antd/lib/select';
import Table from 'antd/lib/table';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Icon from 'antd/lib/icon';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

interface Props {
    collecting: boolean;
    data: any;
    visible: boolean;
    assignee: string;
    startFrame: number;
    stopFrame: number;
    zOrder: boolean;
    bugTracker: string;
    jobStatus: string;
    savingJobStatus: boolean;
    closeStatistics(): void;
    changeJobStatus(status: string): void;
    user: any;
    owner: any;
}

/**
 * Create dialog for statistical information
 *
 * @param props consists of collecting, data, visible, jobStatus, assignee, startFrame, stopFrame, zOrder, bugTracker, savingJobStatus variables and closeStatistics, changeJobStatus, savingJobStatus functions
 * @returns Modal
 */
export default function StatisticsModalComponent(props: Props): JSX.Element {
    const {
        collecting,
        data,
        visible,
        jobStatus,
        assignee,
        startFrame,
        stopFrame,
        zOrder,
        bugTracker,
        closeStatistics,
        changeJobStatus,
        savingJobStatus,
        user,
        owner,
    } = props;
    const { t } = useTranslation();

    const baseProps = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        okText:"Tamam",
        width: 1000,
        visible,
        closable: false,
    };

    if (collecting || !data) {
        return (
            <Modal
                {...baseProps}
            >
                <Spin style={{ margin: '0 50%' }} />
            </Modal>
        );
    }

    const rows = Object.keys(data.label).map((key: string) => ({
        key,
        label: key,
        rectangle: `${data.label[key].rectangle.shape} / ${data.label[key].rectangle.track}`,
        polygon: `${data.label[key].polygon.shape} / ${data.label[key].polygon.track}`,
        polyline: `${data.label[key].polyline.shape} / ${data.label[key].polyline.track}`,
        points: `${data.label[key].points.shape} / ${data.label[key].points.track}`,
        tags: data.label[key].tags,
        manually: data.label[key].manually,
        interpolated: data.label[key].interpolated,
        total: data['textLabels']? data['textLabels'].textLabels[key]:data.label[key].total   ,
    }));

    rows.push({
        key: '___total',
        label: 'Total',
        rectangle: `${data.total.rectangle.shape} / ${data.total.rectangle.track}`,
        polygon: `${data.total.polygon.shape} / ${data.total.polygon.track}`,
        polyline: `${data.total.polyline.shape} / ${data.total.polyline.track}`,
        points: `${data.total.points.shape} / ${data.total.points.track}`,
        tags: data.total.tags,
        manually: data.total.manually,
        interpolated: data.total.interpolated,
        total: data['textLabels']? data['textLabels'].total:data.total.total,
    });

    const makeShapesTracksTitle = (title: string): JSX.Element => (
        <Tooltip title={t('top-bar.shapesTracks')}>
            <Text strong style={{ marginRight: 5 }}>{title}</Text>
            <Icon className='cvat-info-circle-icon' type='question-circle' />
        </Tooltip>
    );

    let columns;
    if(data['textLabels']){
        columns = [{
            title: <Text strong> {t('standard-workspace.label')} </Text>,
            dataIndex: 'label',
            key: 'label',
        },{
            title: <Text strong> {t('top-bar.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        }];
    }else{
        columns = [{
            title: <Text strong> {t('standard-workspace.label')} </Text>,
            dataIndex: 'label',
            key: 'label',
        }, {
            title: makeShapesTracksTitle(t('top-bar.rectangle')),
            dataIndex: 'rectangle',
            key: 'rectangle',
        }, {
            title: makeShapesTracksTitle(t('top-bar.polygon')),
            dataIndex: 'polygon',
            key: 'polygon',
        }, {
            title: makeShapesTracksTitle(t('top-bar.polyline')),
            dataIndex: 'polyline',
            key: 'polyline',
        }, {
            title: makeShapesTracksTitle(t('top-bar.points')),
            dataIndex: 'points',
            key: 'points',
        }, {
            title: <Text strong> {t('top-bar.tags')} </Text>,
            dataIndex: 'tags',
            key: 'tags',
        }, {
            title: <Text strong> {t('top-bar.manually')} </Text>,
            dataIndex: 'manually',
            key: 'manually',
        }, {
            title: <Text strong> {t('top-bar.interpolated')} </Text>,
            dataIndex: 'interpolated',
            key: 'interpolated',
        }, {
            title: <Text strong> {t('top-bar.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        }];
    }

    function changeJobStatusWrapper(value:any) {

        if(value == "completed" && !props.user.groups.includes('admin') && props.user.id != props.owner.id) {
            Modal.confirm({
                title: 'İşi tamamladığınızda bu işe geri dönemeyeceksiniz.',
                content: 'Yaptığınız etiketlerle birlikte işi tamamlamak istediğinize emin misiniz?',
                onOk: () => {
                    changeJobStatus("completed")
                },
                okButtonProps: {
                    type: 'danger',
                },
                okText: 'Tamamla',
                cancelText: 'Vazgeç',
            });
        }
        else{
            changeJobStatus(value)
        }
    }

    return (
        <Modal
            {...baseProps}
        >
            <div className='cvat-job-info-modal-window'>
                <Row type='flex' justify='start'>
                    <Col>
                        <Text strong className='cvat-text'>{t('top-bar.jobStatus')}</Text>
                        <Select value={jobStatus} onChange={changeJobStatusWrapper}>
                            <Select.Option key='1' value='annotation'>{t('top-bar.annotation')}</Select.Option>
                            <Select.Option key='2' value='validation'>{t('top-bar.validation')}</Select.Option>
                            <Select.Option key='3' value='completed'>{t('tasks-page.completed')}</Select.Option>
                        </Select>
                        {savingJobStatus && <Icon type='loading' />}
                    </Col>
                </Row>
                <Row type='flex' justify='start'>
                    <Col>
                        <Text className='cvat-text'>{t('top-bar.overview')}</Text>
                    </Col>
                </Row>
                <Row type='flex' justify='start'>
                    <Col span={5}>
                        <Text strong className='cvat-text'>{t('keywords.assignee')}</Text>
                        <Text className='cvat-text'>{assignee}</Text>
                    </Col>
                    <Col span={5}>
                        <Text strong className='cvat-text'>{t('keywords.startFrame')}</Text>
                        <Text className='cvat-text'>{startFrame}</Text>
                    </Col>
                    <Col span={5}>
                        <Text strong className='cvat-text'>{t('keywords.stopFrame')}</Text>
                        <Text className='cvat-text'>{stopFrame}</Text>
                    </Col>
                    <Col span={5}>
                        <Text strong className='cvat-text'>{t('keywords.frames')}</Text>
                        <Text className='cvat-text'>{stopFrame - startFrame + 1}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>{t('create-task.zOrder')}</Text>
                        <Text className='cvat-text'>{zOrder.toString()}</Text>
                    </Col>
                </Row>
                { !!bugTracker && (
                    <Row type='flex' justify='start' className='cvat-job-info-bug-tracker'>
                        <Col>
                            <Text strong className='cvat-text'>{t('top-bar.bugTracker')}</Text>
                            <a href={bugTracker}>{bugTracker}</a>
                        </Col>
                    </Row>
                )}
                <Row type='flex' justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Text className='cvat-text'>{t('top-bar.annotationStatistics')}</Text>
                        <Table
                            scroll={{ y: 400 }}
                            bordered
                            pagination={false}
                            columns={columns}
                            dataSource={rows}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}
