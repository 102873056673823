// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Menu, { ClickParam } from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import { Select, Button } from 'antd';
import DumpSubmenu from 'components/actions-menu/dump-submenu';
import LoadSubmenu from 'components/actions-menu/load-submenu';
import ExportSubmenu from 'components/actions-menu/export-submenu';
import { useTranslation } from 'react-i18next';
import ReIDPlugin from './reid-plugin';
import getCore from 'cvat-core-wrapper';
const cvat = getCore();
import SubMenu from 'antd/lib/menu/SubMenu';
import { useState, useEffect } from 'react';

interface Props {
    taskMode: string;
    loaders: any[];
    dumpers: any[];
    loadActivity: string | null;
    dumpActivities: string[] | null;
    exportActivities: string[] | null;
    installedReID: boolean;
    taskID: number;
    onClickMenu(params: ClickParam, file?: File, labels?:any): void;
    jobInstance: any;
    user: any;
    annotaUser: any
}

export enum Actions {
    DUMP_TASK_ANNO = 'dump_task_anno',
    LOAD_JOB_ANNO = 'load_job_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    REMOVE_ANNO = 'remove_anno',
    OPEN_TASK = 'open_task',
    EXPORT_TEXT_DATASET = 'export_text_dataset',
    DOWNLOAD_IMAGES_LARGE = 'download_images_large',
    DOWNLOAD_IMAGES_MIDDLE = 'download_images_middle',
    DOWNLOAD_IMAGES_SMALL = 'download_images_small',
    EXPORT_POINT_CLOUD = 'export_point_cloud',
    EXPORT_POINT_CLOUD_TXT = 'export_point_cloud_txt',
    SELECT_LABELS = 'select_labels'
}

/**
 * In task page at top left most part, setting button exists
 * With this menu button annotations can be downloaded, deleted, uploaded or images can be downloaded with different quality levels
 *
 * @param props consists of taskMode, loaders, dumpers, loadActivity, dumpActivities, installedReID, taskID, jobInstance, user, annotaUser variables and onClickMenu functions
 * @returns Menu
 */
export default function AnnotationMenuComponent(props: Props): JSX.Element {
    const {
        taskMode,
        loaders,
        dumpers,
        onClickMenu,
        loadActivity,
        dumpActivities,
        exportActivities,
        installedReID,
        taskID,
        jobInstance,
        user,
        annotaUser
    } = props;
    const { t } = useTranslation();

    const [labelSelectionModalVisible, setLabelSelectionModalVisible] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState();

    // useEffect(() => {
    //     // let labelsArray = new Array()
    //     for(let i=0; i<jobInstance.task.labels.length; i++) {
    //         setSelectedLabels(jobInstance.task.labels[i].id);
    //         // labelsArray.push(jobInstance.task.labels[i].id)
    //     }
    // })

    let permissions = annotaUser[0].group_role_permissions
    let hasDumpPermission = permissions.filter(object => object.permission_id__name == 'dump_annotations').length > 0
    let hasImageExportPermission = permissions.filter(object => object.permission_id__name == 'export_images').length > 0

    let latestParams: ClickParam | null = null;
    async function onClickMenuWrapper(params: ClickParam | null, file?: File): void {
        const copyParams = params || latestParams;
        if (!copyParams) {
            return;
        }
        latestParams = params;

        if (copyParams.keyPath.length === 2) {
            const [, action] = copyParams.keyPath;
            if (action === Actions.LOAD_JOB_ANNO) {
                if (file) {
                    Modal.confirm({
                        title: t('top-bar.annotationWillBeLost'),
                        content: t('top-bar.uploadAnnotationContinue'),
                        onOk: () => {
                            onClickMenu(copyParams, file);
                        },
                        okButtonProps: {
                            type: 'danger',
                        },
                        okText: t('top-bar.upload')
                    });
                }
            } else {
                let annotatedImagesResult;
                if (copyParams.key === Actions.DOWNLOAD_IMAGES_LARGE) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(jobInstance.task.id, 'large');
                }  else if (copyParams.key === Actions.DOWNLOAD_IMAGES_MIDDLE) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(jobInstance.task.id, 'middle');
                }  else if (copyParams.key === Actions.DOWNLOAD_IMAGES_SMALL) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(jobInstance.task.id, 'small');
                } else if (copyParams.key === Actions.EXPORT_POINT_CLOUD) {
                    let jobAssignee = jobInstance.assignee?  jobInstance.assignee.username : "none"
                    let pcdExportResult = await cvat.tasks.getPCDAnnotations(jobInstance.task.dataId, jobAssignee, jobInstance.id, true);
                } else if (copyParams.key === Actions.EXPORT_POINT_CLOUD_TXT) {
                    let jobAssignee = jobInstance.assignee?  jobInstance.assignee.username : "none"
                    let pcdExportResult = await cvat.tasks.getPCDAnnotations(jobInstance.task.dataId, jobAssignee, jobInstance.id, false);
                } else{
                    onClickMenu(copyParams, undefined, selectedLabels)
                }
                if(annotatedImagesResult){
                    if(annotatedImagesResult == 'notReady'){
                        Modal.error({
                            title: 'Etiketlenmiş imgeler hazır değil!',
                            content: `Görev üzerinde tamamlannmış bir iş bulunmalı!`,
                        });
                    }else if(annotatedImagesResult == 'preparing'){
                        Modal.error({
                            title: 'Etiketlenmiş imgeler hazırlanıyor!',
                            content: `Lütfen daha sonra tekrar deneyiniz!`,
                        });
                    }
                }
            }
        } else if (copyParams.key === Actions.REMOVE_ANNO) {
            Modal.confirm({
                title: t('top-bar.annotationWillBeLost'),
                content: t('top-bar.removeAnnotationContinue'),
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    type: 'danger',
                },
                okText: t('keywords.delete'),
            });
        } else if (copyParams.key === Actions.EXPORT_TEXT_DATASET) {
            let textTaskJsonExportResult = await cvat.tasks.getTextTaskDataset(jobInstance.task.tasksIdAnnota[0].doccanoProjectId, selectedLabels);
            return;
        }/* else if (copyParams.key === Actions.EXPORT_POINT_CLOUD) {
            let jobAssignee = jobInstance.assignee?  jobInstance.assignee.username : "none"
            let pcdExportResult = await cvat.tasks.getPCDAnnotations(jobInstance.task.dataId, jobAssignee, jobInstance.id);
            return;
        } */
        else if (copyParams.key === Actions.SELECT_LABELS) {
            setLabelSelectionModalVisible(true)
        } else {
            onClickMenu(copyParams);
        }
    }

    return (
        <div>
        <Menu onClick={onClickMenuWrapper} className='cvat-annotation-menu' selectable={false}>
            {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) != 0 && ((user.id == jobInstance.task.owner.id  && hasDumpPermission) || user.isSuperuser)) ? <Menu.Item key={Actions.EXPORT_TEXT_DATASET}>Etiketleri İndir</Menu.Item> : null}
            {(jobInstance.task.tasksIdAnnota[0].pointCloudFileName) && (user.id == jobInstance.task.owner.id || user.isSuperuser) ?
                <SubMenu title="Etiketleri İndir">
                    <Menu.Item key={Actions.EXPORT_POINT_CLOUD}>.pcd</Menu.Item>
                    <Menu.Item key={Actions.EXPORT_POINT_CLOUD_TXT}>.txt</Menu.Item>
                </SubMenu>
            : null}
            {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) == 0 && !jobInstance.task.tasksIdAnnota[0].pointCloudFileName && ((user.id == jobInstance.task.owner.id && hasImageExportPermission) || user.isSuperuser) ) ?
                   <SubMenu title="İmgeleri İndir">
                        <Menu.Item key={Actions.DOWNLOAD_IMAGES_LARGE}>Yükseltilmiş</Menu.Item>
                        <Menu.Item key={Actions.DOWNLOAD_IMAGES_MIDDLE}>Orijinal</Menu.Item>
                        <Menu.Item key={Actions.DOWNLOAD_IMAGES_SMALL}>Küçültülmüş</Menu.Item>
                    </SubMenu>
            : null}
            {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) == 0 && !jobInstance.task.tasksIdAnnota[0].pointCloudFileName && ((user.id == jobInstance.task.owner.id && hasDumpPermission)|| user.isSuperuser)) &&
                DumpSubmenu({
                    taskMode,
                    dumpers,
                    dumpActivities,
                    menuKey: Actions.DUMP_TASK_ANNO,
                })
            }
            {!jobInstance.task.tasksIdAnnota[0].pointCloudFileName && ((user.id == jobInstance.task.owner.id && hasDumpPermission) || user.isSuperuser) && <Menu.Item key={Actions.SELECT_LABELS}>
                İndirilecek etiketleri seç
            </Menu.Item> }
            {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) == 0 && !jobInstance.task.tasksIdAnnota[0].pointCloudFileName && (user.id == jobInstance.task.owner.id || user.isSuperuser)) &&
                LoadSubmenu({
                    loaders,
                    loadActivity,
                    onFileUpload: (file: File): void => {
                        onClickMenuWrapper(null, file);
                    },
                    menuKey: Actions.LOAD_JOB_ANNO,
                })
            }
            {/* {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) == 0 && (user.id == jobInstance.task.owner.id || user.isSuperuser)) &&
                ExportSubmenu({
                    exporters: dumpers,
                    exportActivities,
                    menuKey: Actions.EXPORT_TASK_DATASET,
                })
            } */}

            <Menu.Item key={Actions.REMOVE_ANNO}>
                {t('standard-workspace.removeAnno')}
            </Menu.Item>
            <Menu.Item key={Actions.OPEN_TASK}>
                <a href={`/tasks/${taskID}`} onClick={(e: React.MouseEvent) => e.preventDefault()}>
                    {t('standard-workspace.openTask')}
                </a>
            </Menu.Item>
            { installedReID && <ReIDPlugin /> }
        </Menu>

        <Modal
            closable={false}
            visible={labelSelectionModalVisible}
            // onOk={()=>setLabelSelectionModalVisible(false)}
            footer={[
                <Button key="submit" className="button-send" type="primary" onClick={()=>setLabelSelectionModalVisible(false)}>
                  Tamam
                </Button>,
            ]}
            >
            <Select
                dropdownStyle={{background:"#F2F4F8"}}
                className="input-create-task"
                placeholder="İndirilecek etiketleri seçiniz"
                mode="multiple"
                // showArrow={true}
                style={{ width: '100%' }}
                value={selectedLabels}
                onChange={(value) => setSelectedLabels(value)}
                >
                    {jobInstance.task.labels.map((label: any): JSX.Element => (
                        <Select.Option
                            value={label.id}
                            key={label.id}
                        >
                            {label.name}
                        </Select.Option>
                    ))}
            </Select>
        </Modal>
        </div>
    );
}
