// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Row, Col } from 'antd/lib/grid';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import ResetForm, { ResetData } from './reset-form';

interface ResetPageComponentProps {
    onReset: (email: string) => void;
}

/**
 *
 * @param props
 * @returns Reset Page Component for /reset-page, reset password component
 */
function ResetPageComponent(props: ResetPageComponentProps & RouteComponentProps): JSX.Element {
    /**
     * @description Column sizes object
     */
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    /**
     * @description get onReset event function from passed props
     */
    const {
        onReset,
    } = props;
    const { t } = useTranslation();

    /**
     * @description Template for rendering Title and Reset Form
     */
    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                <Col {...sizes}>
                    <img src='/veri_kovani_logo.svg' width='100%' vspace="50"></img>
                    <Title level={2}> {t('keywords.passwordReset')} </Title>
                    <ResetForm
                        onSubmit={(resetData: ResetData): void => {
                            onReset(resetData.email);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default withRouter(ResetPageComponent);
