// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Tag from 'antd/lib/tag';
import Icon from 'antd/lib/icon';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import i18n from '../../i18n'
import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import { MenuCustomIcon } from 'icons';

import getCore from 'cvat-core-wrapper';
import patterns from 'utils/validation-patterns';
import { getReposData, syncRepos } from 'utils/git-utils';
import UserSelector from './user-selector';
import TasksUsers from './task-users';
import TaskViewers from './task-viewers';
import LabelsEditorComponent from '../labels-editor/labels-editor';
import { StartTaskVector,CompletedTaskVector, NewJobVector, MessageIconTask } from 'icons';
import NewMessageComponent from '../../containers/messages-page/new-message-component'
import { Popconfirm, Divider, Spin, Table, Progress, Card, Dropdown, List, Checkbox, Select } from 'antd';
const { Option } = Select;
import TextArea from 'antd/lib/input/TextArea';
import './styles.scss';

const core = getCore();

interface Props {
    user: any;
    previewImage: string;
    taskInstance: any;
    installedGit: boolean; // change to git repos url
    registeredUsers: any[];
    viewer: boolean;
    onTaskUpdate: (taskInstance: any) => void;
    updateJoblist: (taskInstance: any) => void;
    annotaUser: any
}

interface State {
    name: string;
    bugTracker: string;
    repository: string;
    repositoryStatus: string;
    modalVisible: boolean;
    annotaTask:any;
    metaDataModalVisible: boolean;
    privateModalVisible:boolean;
    setViewerModalVisible:boolean;
    sendYZPModalOpen:boolean;
    yzpModelExportType: string;
}

/**
 * @class_component DetailsComponent rendering details for task page
 */
export default class DetailsComponent extends React.PureComponent<Props, State> {
    private mounted: boolean;
    private previewImageElement: HTMLImageElement;
    private previewWrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);

        const { taskInstance } = props;

        this.mounted = false;
        this.previewImageElement = new Image();
        this.previewWrapperRef = React.createRef<HTMLDivElement>();
        this.state = {
            name: taskInstance.name,
            bugTracker: taskInstance.bugTracker,
            repository: '',
            repositoryStatus: '',
            modalVisible: false,
            annotaTask:"",
            metaDataModalVisible: false,
            privateModalVisible:false,
            setViewerModalVisible:false,
            sendYZPModalOpen:false,
            yzpModelExportType:""
        };
        //this.props.updateJoblist(taskInstance)
    }

    public componentDidMount(): void {
        const { taskInstance, previewImage } = this.props;
        const { previewImageElement, previewWrapperRef } = this;

        this.mounted = true;

        previewImageElement.onload = () => {
            const { height, width } = previewImageElement;
            if (width > height) {
                previewImageElement.style.width = '100%';
            } else {
                previewImageElement.style.height = '100%';
            }
        };

        previewImageElement.src = previewImage;
        previewImageElement.alt = i18n.t('task-page.preview');
        if (previewWrapperRef.current) {
            previewWrapperRef.current.appendChild(previewImageElement);
        }
        this.getTaskState(taskInstance.id)
    }


    public componentDidUpdate(prevProps: Props): void {
        const { taskInstance } = this.props;

        if (prevProps !== this.props) {
            this.setState({
                name: taskInstance.name,
                bugTracker: taskInstance.bugTracker,
            });
        }
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    /**
     * @async
     * @param id
     * @description set privacy of task on db
     */
    async setPrivacy(id: any){
        let response = null;
        response = await core.tasks.setPrivacyTask(id);
        this.getTaskState(id)
    }

    /**
     * @async
     * @description assign task
     */
    async assignRandomJob(){
        const {
            taskInstance,
            updateJoblist
        } = this.props;
        let assingRandomTask = await core.tasks.assigntaskRandom(taskInstance.id)
        let response = assingRandomTask.data.status;
        updateJoblist(taskInstance);
        if(response != i18n.t('response-messages.success')){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: response,
            });
        }
        else{
            Modal.success({
                title: i18n.t('keywords.success'),
                content: response,
            });
        }
    }

    private async modelExport(exportType: "train" | "eval") {

        const {
            taskInstance,
            annotaUser
        } = this.props;



        let doccanoProtocol = typeof (process.env.REACT_APP_DOCCANO_PROTOCOL) === 'undefined'
            ? 'http' : process.env.REACT_APP_DOCCANO_PROTOCOL;
        let doccanoHost = typeof (process.env.REACT_APP_DOCCANO_HOST) === 'undefined'
            ? 'localhost' : process.env.REACT_APP_DOCCANO_HOST;
        const doccanoPort = typeof (process.env.REACT_APP_DOCCANO_PORT) === 'undefined'
            ? '8000' : process.env.REACT_APP_DOCCANO_PORT;

        let backendAPIDoccano = `${doccanoProtocol}://${doccanoHost}:${doccanoPort}`;

        let doccanoProjectId = taskInstance.tasksIdAnnota[0].doccanoProjectId;

        if (doccanoProjectId != 0) {
            // Doccano

            let body =
            {
                "tcId": `${annotaUser[0].tckn}`,
                "mtypCode": "txt_class",
                "dsetType": exportType,
                "dsetName": `${taskInstance.name}`,
                "dsetDetail": `${taskInstance.name}`,
                "requestUrl": `${backendAPIDoccano}/ssyz-export-text-classification/${doccanoProjectId}`
            };

            await fetch(`${process.env.SSYZ_MODEL_EXPORT_URL}`, {
                method: 'POST',
                body: JSON.stringify(body),
            });
        }
        else {
            // CVAT
            // TODO :
        }
    }

    /**
     *
     * @returns task name JSX template
     */
    private renderTaskName(): JSX.Element {
        const { name } = this.state;
        const {
            taskInstance,
            onTaskUpdate,
            user,
            annotaUser
        } = this.props;

        let permissions = annotaUser[0].group_role_permissions
        let hasShareTaskPermission = permissions.filter(object => object.permission_id__name == 'share_task').length > 0

        let isPointCloudTask = taskInstance.tasksIdAnnota[0].pointCloudFileName != null;
        let isDoccanoTask = taskInstance.tasksIdAnnota[0].doccanoProjectId != 0;
        let isTextClassificationTask = taskInstance.tasksIdAnnota[0].doccanoProjectId != 0 && taskInstance.tasksIdAnnota[0].doccanoProjectType == "text-classification";

        return (
            <Title
                data-tour="step-task-name"
                level={4}
                editable={(user.isSuperuser || taskInstance.owner.id == user.id) && {
                    onChange: (value: string): void => {
                        this.setState({
                            name: value,
                        });
                        taskInstance.name = value;
                        onTaskUpdate(taskInstance);
                    },
                }}
                className='cvat-title'
                style={{marginTop:"1%",marginLeft:"2%"}}
            >
                Görev #{taskInstance.id}:{name}
                <Text style={{display:"flex",float:"right", fontSize:"14px"}}>
                    {(this.props.user.id == this.props.taskInstance.owner.id || this.props.user.isSuperuser) &&
                        <div>
                            {
                                process.env.DEPLOYMENT_PLACE == "SSYZ" &&
                                (!isPointCloudTask && (!isDoccanoTask || isTextClassificationTask)) &&
                                <>
                                    <Button className="guideline-button" type="primary" size="default" onClick={() => this.setState({ sendYZPModalOpen: true })}>
                                        <Icon type="upload" />
                                        Etiketleri Model Eğitim Portal'a Gönder
                                    </Button>
                                    <Modal title="Gönderilecek model tipini seçin" visible={this.state.sendYZPModalOpen} onOk={() => {
                                        this.sendModelExportInfoToYZP();
                                        this.setState({ sendYZPModalOpen: false });
                                    }} onCancel={() => {
                                        this.setState({ sendYZPModalOpen: false });
                                    }}>
                                        <Select
                                            labelInValue
                                            defaultValue={{ key: 'Bir model tipi seçin' }}
                                            style={{ width: 240, justifyContent: "center" }}
                                            onChange={(value) => {
                                                this.setState({ yzpModelExportType: value.key })
                                            }}
                                        >
                                            {!isTextClassificationTask &&
                                                [<Option value="Obj-YZP 1.1">Nesne Tespiti</Option>,
                                                <Option value="Seg-YZP 1.0">Bölütleme</Option>
                                                ]
                                            }
                                            {isTextClassificationTask &&
                                                <Option value="txt_class">Metin Sınıflandırma</Option>
                                            }
                                        </Select>
                                    </Modal>
                                </>

                            }
                            &nbsp;&nbsp;
                            <a data-tour="step-task-private" style={{color:"#6C63FF"}} onClick={(e)=>{ this.setState({privateModalVisible:true})}}>Gizle</a>
                            &nbsp;&nbsp;
                            {(hasShareTaskPermission || this.props.user.isSuperuser) && <a data-tour="step-task-share" style={{color:"#6C63FF"}} onClick={(e)=>{ this.setState({setViewerModalVisible:true})}}>Paylaş</a>}
                        </div>
                    }
                    { !(user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator'))) &&
                        <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} viewer={this.props.viewer} />}>
                            <Icon data-tour="step-task-opts" style={{ transform: "scale(0.8)", top: "-9px",position: "relative"}} className='cvat-menu-icon' component={MenuCustomIcon} onClick={(e)=>{e.preventDefault();e.stopPropagation()}} />
                        </Dropdown>
                    }
                </Text>
            </Title>
        );
    }

    /**
     *
     * @returns privacy modal template
     */
    renderPrivacyModal(){
        return(
            <Modal
            width="fit-content"
            visible={this.state.privateModalVisible}
            onCancel={() => this.setState({privateModalVisible:false})}
            footer={[]}
            destroyOnClose={true}
            title="Görevi Gizle"
            >
                <div style={{ marginTop: '1.7%' }} >
                    <Col span={24}>
                    <Checkbox style={{ zoom: '1.2' }} checked={this.state.annotaTask.isPrivate} onChange={(e) => this.setPrivacy(this.state.annotaTask.id.id)}>Gizli</Checkbox>
                    <Text style={{fontSize:"14px"}} type="secondary">Göreve etiketleyici eklemeden görevi yalnızca gizleyebilirsiniz.</Text>
                    </Col>
                    <br/>
                    {(this.state.annotaTask.isPrivate) && <TasksUsers taskInstance={this.props.taskInstance} user={this.props.user}></TasksUsers>}
                </div>

            </Modal>
        );
    }

    /**
     * @returns viewers modal template by using TaskViewers component instane
     */
    renderViewersModal(){
        return(
            <Modal
            visible={this.state.setViewerModalVisible}
            onCancel={() => this.setState({setViewerModalVisible:false})}
            footer={[]}
            destroyOnClose={true}
            title="Görevi Paylaş"
            >
                <TaskViewers taskInstance={this.props.taskInstance} user={this.props.user}></TaskViewers>
            </Modal>
        );
    }

    /**
     *
     * @returns preview image JSX Element
     */
    private renderPreview(): JSX.Element {
        const { previewWrapperRef } = this;

        // Add image on mount after get its width and height to fit it into wrapper
        return (
            <div ref={previewWrapperRef} className='cvat-task-preview-wrapper' />
        );
    }

    /**
     *
     * @returns task parameter JSX Element
     */
    private renderParameters(): JSX.Element {
        const { taskInstance, user,viewer } = this.props;
        const { overlap } = taskInstance;
        const { segmentSize } = taskInstance;
        const { imageQuality } = taskInstance;
        const zOrder = taskInstance.zOrder.toString();

        return (
            <>
                <Row type='flex' justify='start' align='middle' hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                    <Col span={12}>
                        <Text strong >{i18n.t('create-task.overlapSize')}: </Text>
                        <Text >{overlap}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong >{i18n.t('create-task.segmentSize')}: </Text>
                        <Text >{segmentSize}</Text>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row type='flex' justify='space-between' align='middle' hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                    <Col span={12}>
                        <Text strong >{i18n.t('create-task.imageQuality')}: </Text>
                        <Text >{imageQuality}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong >{i18n.t('create-task.zOrder')}: </Text>
                        <Text >{i18n.t('keywords.' + zOrder)}</Text>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row type='flex' justify='space-between' align='middle' hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                    <Col span={12}>
                        <Text strong >Görev Sahibi: </Text>
                        <Text >{taskInstance.owner.username}</Text>
                    </Col>
                </Row>
            </>
            // Button should be shown only for authorized users
        );
    }

    /**
     *
     * @returns New Message Component instance
     */
    renderModal(){
        const owner = this.props.taskInstance.owner ? this.props.taskInstance.owner : null;
        return (
            <NewMessageComponent
                visible={this.state.modalVisible}
                setInvisible={()=> this.setState({modalVisible : false})}
                user={this.props.user}
                recipient={owner}
            />
        )
    }

    /**
     * @async
     * @description download task guidelines
     */
    async downloadTaskGuidelines() {
        let response;
        let loading = true;
        Modal.info({
            title: "Veri indiriliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });
        response = await core.server.downloadTaskGuidelines(this.props.taskInstance.id);

        if (response) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     * @async
     * @description send model export info to YZP
     */
     async sendModelExportInfoToYZP() {
        if (this.state.yzpModelExportType == null || this.state.yzpModelExportType == "") {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Lütfen gönderilecek model tipini seçin!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
            return;
        }
        let response;
        let loading = true;
        Modal.info({
            title: "Bilgiler Yapay Zeka Platformuna gönderiliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });
        response = await core.server.sendModelExportInfoToYZP(this.props.taskInstance.id, this.state.yzpModelExportType);

        if (response) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: "Bilgiler Yapay Zeka Platformuna başarıyla gönderildi!",
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Bilgiler Yapay Zeka Platformuna gönderilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     *
     * @returns guidelines template
     */
    renderGuidelines(){
        const {
            taskInstance,
            registeredUsers,
            onTaskUpdate,
            user,
            viewer
        } = this.props;

        return(
            <Card data-tour="step-guidelines" title={i18n.t('keywords.guidelines')}
                style={{height:"100%", borderRadius:"8px",width:"-webkit-fill-available"}}
                bodyStyle={{display:"flex",height:"81.5%",paddingTop:"10px", flexDirection:"column",maxWidth:"75vh"}} //maxwidth should be checked for different screen sizes
                headStyle={{height:"18.5%", fontWeight:"600"}}
                extra={taskInstance.owner.id!=user.id &&
                        <div data-tour="step-guideline-message">
                            <Text>Mesaj Gönder</Text> <Icon onClick={() => this.setState({ modalVisible: true })} component={MessageIconTask} />
                        </div>
                    }
            >
               {/*  <TextArea style={{border:"none"}} rows={12} defaultValue={taskInstance.guidelines} disabled>

                </TextArea> */}
                    <Text style={{overflowWrap:"break-word"}}>
                        {taskInstance.guidelines}
                    </Text>
                {this.props.taskInstance.hasGuidelinesFile &&
                        <Popconfirm
                            title= "Veriyi indirmek istediğinize emin misiniz?"
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => this.downloadTaskGuidelines()
                            }
                        >
                            <div style={{marginTop:"auto",display:"flex", justifyContent:"center"}}>
                            <Button className="guideline-button"  type="primary" size="default">
                            <Icon type="download"/>
                                Yönerge Dokümanını İndir
                            </Button>
                            </div>
                        </Popconfirm>
                }
            </Card>
        )
    }

    /**
     *
     * @returns task info JSX template
     */
    private renderTaskInfo(): JSX.Element {
        const {
            taskInstance,
        } = this.props;

        const created = moment(taskInstance.createdDate).locale('tr').format('LLL');
        let updated = moment(taskInstance.updatedDate).locale('tr').fromNow();

        let typeTask = ''
        if(taskInstance.tasksIdAnnota[0].doccanoProjectId != 0)
            typeTask = 'Metin'
        else
            typeTask = 'Görsel'

        let testOrTrainingTypeIndicator = ''

        try {
            let testOrTrainingType = taskInstance.tasksId.find((task) => task.taskId == taskInstance.id).taskType
            switch(testOrTrainingType){
                case 'test':
                    testOrTrainingTypeIndicator = ' | Test'
                    break;
                case 'training':
                    testOrTrainingTypeIndicator = ' | Eğitim'
                    break;
                default:
                    testOrTrainingTypeIndicator = ''
            }
        } catch (error) {
            // Do nothing
        }

        return (
            <div>
                <Text type='secondary'>
                    {i18n.t('task-page.createdBy', {created: created})}
                </Text>
                <br></br>
                <Text type='secondary'>
                    Son güncelleme: {updated}
                </Text>
                <br></br>
                <Text>Görev Zorluğu: {taskInstance.tasksIdAnnota[0].taskDifficulty}</Text>
                <br></br>
                <Text>{typeTask}{testOrTrainingTypeIndicator}</Text>
            </div>
        );
    }

    /**
     *
     * @returns Dataset Repository JSX Element
     */
    private renderDatasetRepository(): JSX.Element | boolean {
        const { taskInstance, user } = this.props;
        const {
            repository,
            repositoryStatus,
        } = this.state;

        return (
            !!repository
                && (
                    <Row hidden={!(user.isSuperuser || taskInstance.owner.id == user.id)}>
                        <Col className='cvat-dataset-repository-url'>
                            <Text strong className='cvat-text-color'>{i18n.t('create-task.annotationRepoURL')}</Text>
                            <br />
                            <a href={repository} rel='noopener noreferrer' target='_blank'>{repository}</a>
                            {repositoryStatus === 'sync'
                                && (
                                    <Tag color='blue'>
                                        <Icon type='check-circle' />
                                        {i18n.t('task-page.synchronized')}
                                    </Tag>
                                )}
                            {repositoryStatus === 'merged'
                                && (
                                    <Tag color='green'>
                                        <Icon type='check-circle' />
                                        {i18n.t('task-page.merged')}
                                    </Tag>
                                )}
                            {repositoryStatus === 'syncing'
                                && (
                                    <Tag color='purple'>
                                        <Icon type='loading' />
                                        {i18n.t('task-page.syncing')}
                                    </Tag>
                                )}
                            {repositoryStatus === '!sync'
                                && (
                                    <Tag
                                        color='red'
                                        onClick={(): void => {
                                            this.setState({
                                                repositoryStatus: 'syncing',
                                            });

                                            syncRepos(taskInstance.id).then((): void => {
                                                if (this.mounted) {
                                                    this.setState({
                                                        repositoryStatus: 'sync',
                                                    });
                                                }
                                            }).catch((error): void => {
                                                if (this.mounted) {
                                                    Modal.error({
                                                        width: 800,
                                                        title: i18n.t('task-page.synchronizeRepoError'),
                                                        content: error.toString(),
                                                    });

                                                    this.setState({
                                                        repositoryStatus: '!sync',
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        <Icon type='warning' />
                                        {i18n.t('task-page.synchronize')}
                                    </Tag>
                                )}
                        </Col>
                    </Row>
                )
        );
    }

    /**
     *
     * @deprecated is not used anymore.
     */
    private renderBugTracker(): JSX.Element {
        const {
            taskInstance,
            onTaskUpdate,
            user,
            viewer
        } = this.props;
        const { bugTracker } = this.state;

        let shown = false;
        const onChangeValue = (value: string): void => {
            if (value && !patterns.validateURL.pattern.test(value)) {
                if (!shown) {
                    Modal.error({
                        title: i18n.t('task-page.couldNotUpdate', {task: taskInstance.id}),
                        content: i18n.t('task-page.issueTrackerError'),
                        onOk: (() => {
                            shown = false;
                        }),
                    });
                    shown = true;
                }
            } else {
                this.setState({
                    bugTracker: value,
                });

                taskInstance.bugTracker = value;
                onTaskUpdate(taskInstance);
            }
        };

        if (bugTracker) {
            return (
                <Row type="flex" justify="start" hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                    <Col span={24}>
                        <Text  strong>{i18n.t('keywords.issueTracker')}</Text>
                        <br />
                        <Text className="bugtracker-component" editable={!viewer&&{ onChange: onChangeValue }}>{bugTracker}</Text>
                        <Button
                            hidden={viewer}
                            type='ghost'
                            size='small'
                            onClick={(): void => {
                                // false positive
                                // eslint-disable-next-line
                                window.open(bugTracker, '_blank');
                            }}
                            className='cvat-open-bug-tracker-button'
                        >
                                {i18n.t('task-page.openTheIssue')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        return (
            <Row hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                <Col span={16}>
                    <Text strong >{i18n.t('keywords.issueTracker')}</Text>
                    <br />
                    <div style={{marginTop:"1%",display:"flex"}}>
                        <Text className="bugtracker-component"
                            editable={!viewer&&{ onChange: onChangeValue }}
                        >
                            {i18n.t('task-page.notSpecified')}
                        </Text>
                    </div>

                </Col>
            </Row>
        );
    }

    /**
     * @async
     * @param taskId
     * @description get tasks and set state
     */
    async getTaskState(taskId: any) {
        let response = null;
        response = await core.tasks.getAnnotaTasks(null, taskId, null);
        this.setState({
            annotaTask: response
        })
    }

    /**
     *
     * @returns Progress component JSX template
     */
    private renderProgress(): JSX.Element {
        const {
            taskInstance
        } = this.props;
        // Count number of jobs and performed jobs
        const numOfJobs = taskInstance.jobs.length;
        const numOfCompleted = taskInstance.jobs.filter(
            (job: any): boolean => job.status === 'completed',
        ).length;

        // Progress appearence depends on number of jobs
        let progressColor = null;
        let progressText = null;
        if (taskInstance.tasksIdAnnota[0].state == 'completed') {
            progressColor = 'cvat-task-completed-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.completedProgress')}</Text>;
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.inProgress')}</Text>;
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.pending')}</Text>;
        }

        let taskState = "";
        if (taskInstance.tasksIdAnnota[0].state == 'allocated') {
            taskState = i18n.t('tasks-page.taskReachedAssigneeLimit')
        }
        return (
                <Col span={24} >
                    <Row type='flex' justify='space-between' align='top'>
                        <Col>
                            <svg height='8' width='8' className={progressColor}>
                                <circle cx='4' cy='4' r='4' strokeWidth='0' />
                            </svg>
                            {progressText}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Progress
                                className={`${progressColor} cvat-task-progress`}
                                percent={numOfCompleted * 100 / numOfJobs}
                                strokeColor='#1890FF'
                                showInfo={false}
                                strokeWidth={5}
                                size='small'
                            />
                        </Col>
                    </Row>
                    <Row>{taskState}</Row>
                </Col>
        );
    }

    private renderProgressJobs(): JSX.Element {
        const {
            taskInstance,
        } = this.props;
        // Count number of jobs and performed jobs
        const numOfJobs = taskInstance.jobs.length;
        const numOfCompleted = taskInstance.jobs.filter(
            (job: any): boolean => job.status === 'completed',
        ).length;

        return (
            <Col span={24}>
                <Col >
                    <Text style={{color:"#5A5C69"}} type='secondary'>{i18n.t('tasks-page.taskCompletionUpdated', { numOfCompleted: numOfCompleted, maxJobCountForTask: numOfJobs })}</Text>
                </Col>
            </Col>
        );
    }

    /**
     *
     * @returns MetaData Table template
     */
    renderMetaDataModal() {
        const {taskInstance} =this.props;
        const columnsMetaData = [
            {
                title: 'Anahtar',
                dataIndex: 'key',
                key: 'ket',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },
            {
                title: 'Değer',
                dataIndex: 'value',
                key: 'value',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },

        ];
        return (
            <Modal
                visible={this.state.metaDataModalVisible}
                onCancel={() => this.setState({metaDataModalVisible:false})}
                footer={[]}
                destroyOnClose={true}
                title="Anahtar-Değer Verisi"
            >
            <Table
                className="cvat-metadata-table"
                rowClassName={()=> {return "table-metadata-row"}}
                rowKey='fileName'
                locale={{emptyText: 'Meta Veri Bulunmuyor!'}}
                columns={columnsMetaData}
                dataSource={taskInstance.tasksIdAnnota[0].meta_data_task}
                pagination={false}>
            </Table>
         </Modal>
        )
    }

    /**
     *
     * @returns Labels Editor Template
     */
    private renderLabelsEditor(): JSX.Element {
        const {
            taskInstance,
            onTaskUpdate,
            user,
            viewer
        } = this.props;

        let textTaskFlag = Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) != 0

        return (
            <Row hidden={!(user.isSuperuser || taskInstance.owner.id == user.id || viewer)}>
                <Col  data-tour="step-label-editor-task">
                    <LabelsEditorComponent

                        labels={taskInstance.labels.map(
                            (label: any): string => label.toJSON(),
                        )}
                        onSubmit={(labels: any[]): void => {
                            taskInstance.labels = labels
                                .map((labelData): any => new core.classes.Label(labelData));
                            onTaskUpdate(taskInstance);
                        }}
                        textTask = {textTaskFlag}
                        viewer={viewer}
                    />
                </Col>
            </Row>
        );
    }

    /**
     *
     * @returns Details Component JSX Template by using class functions
     */
    public render(): JSX.Element {

        const {
            taskInstance,
            user,
            viewer,
            annotaUser
        } = this.props;

        let taskState=taskInstance.tasksIdAnnota[0].state;
        const numOfCompleted = taskInstance.jobs.filter(
            (job: any): boolean => job.status === 'completed',
        ).length;
        if(taskState!="completed" && numOfCompleted)
            taskState="inProgress"
        let role;
        if(user.groups.includes("admin"))
            role="admin"
        else if(user.groups.includes("provider"))
            role="provider"
        else
            role="annotator"
            return (
            <div style={{display:"contents"}}>
                <div style={{width:"100%"}}>
                    <Row type='flex' justify='start'>
                        <Col span={24}>
                            <div className="title-component">
                                { this.renderTaskName() }
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='cvat-task-details' style={{marginTop:"-2px"}}>
                    {(role=="admin"||role=="provider"||taskInstance.owner.id == user.id)?
                        <div data-tour="step-task-info">
                            <Row type='flex' justify='space-between' style={{marginBottom:"2%"}}>
                                <Col style={{display:"flex"}}span={8}>
                                    { this.renderPreview() }
                                </Col>
                                <Col style={{display:"flex", flexDirection:"column"}}span={8}>
                                    {/* { this.renderBugTracker() }
                                    <br/> */}
                                    { this.renderParameters() }
                                </Col>
                                <Col style={{display:"flex",flexDirection:"column",alignItems: "flex-end"}}span={8}>
                                    {this.renderGuidelines()}
                                    <a data-tour="step-task-metadata-modal" style={{color:"#6C63FF",float:"right"}} onClick={(e)=>{ this.setState({metaDataModalVisible:true})}}>Anahtar-Değer Verisi</a>
                                    {this.renderMetaDataModal()}
                                </Col>
                            </Row>
                            <Row type='flex' justify='start' align='middle'>
                                <Col span={12}>

                                    {this.renderProgress()}

                                    {this.renderProgressJobs()}
                                    { this.renderTaskInfo() }
                                    {this.renderModal()}

                                </Col>
                                <Col span={12}>
                                    { this.renderDatasetRepository() }
                                    { this.renderLabelsEditor() }
                                </Col>
                            </Row>
                            {this.renderPrivacyModal()}
                            {this.renderViewersModal()}
                        </div>:
                        <Row type='flex' justify='space-between'>
                            <Col data-tour="step-task-info" style={{display:"flex",flexDirection:"column"}} span={8}>
                                <Col style={{display:"flex"}}>
                                { this.renderPreview() }
                                </Col>
                                {this.renderProgress()}
                                {this.renderProgressJobs()}
                                { this.renderTaskInfo() }
                                {this.renderModal()}

                            </Col>
                            <Col data-tour="step-random-assign" style={{display:"flex"}}  span={8}>
                                <Row type="flex" justify="center" align="top">
                                {taskState=="proper"&&
                                    <Col span={18} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                        <Text>Henüz iş atayan yok. Görevi başlatan sen ol!</Text>
                                        <br></br>
                                        <br></br>
                                        <Icon component={StartTaskVector}></Icon>

                                    </Col>
                                }
                                {taskState=="inProgress"&&
                                    <Col  span={18} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                        <Text>Bu görevin etiketleyicilerinden biri olun.</Text>
                                        <br></br>
                                        <br></br>
                                        <Icon component={NewJobVector}></Icon>
                                    </Col>
                                }
                                {(taskState=="completed"||taskState=="allocated")&&
                                    <Col span={18} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                        <Text>Bu görev tam kapasiteye ulaştı.</Text>
                                        <br></br>
                                        <br></br>
                                        <Icon component={CompletedTaskVector}></Icon>
                                    </Col>
                                }

                                {(taskState=="proper"||taskState=="inProgress")&&
                                    <Col span={12} style={{display:"flex",justifyContent:"center"}}>
                                        <br></br>
                                        <Button className="button-segment-details" onClick={(e)=>this.assignRandomJob()} size="large">İş Ata</Button>
                                    </Col>
                                }
                                </Row>
                            </Col>
                            <Col  style={{display:"flex"}} span={8} >
                                {this.renderGuidelines()}
                            </Col>

                        </Row>
                    }
                </div>
            </div>
        );
    }
}
