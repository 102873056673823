// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'
import { Select } from 'antd';
import { TaskType } from 'cvat-core/src/enums';

interface VisibleTopBarProps {
    user: any;
    onSearch: (value: string) => void;
    searchValue: string;
    onTaskTypeChanged: (value: string) => void;
    taskType: string;
};

const { Option } = Select;
/**
 * Top Bar Component 
 * @param props includes user, searchValue, taskType, and functions, which are onSearch, onTaskTypeChanged
 * @returns div including row and text
 */
function TopBarComponent(props: VisibleTopBarProps & RouteComponentProps): JSX.Element {
    const {
        searchValue,
        history,
        onSearch,
        user,
        onTaskTypeChanged,
        taskType=TaskType.ALL,
    } = props;
    const { t } = useTranslation();

    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                {/*<Col md={22} lg={18} xl={16} xxl={14}>
                    <Text strong>{t('top-bar.defaultProject')}</Text>
                </Col>*/}
            </Row>
            <Text className='cvat-title'>{t('top-bar.tasks')}</Text>


        </>
    );
}

export default withRouter(TopBarComponent);
