import React, {Component} from 'react';
import {
    Row,
    Col,
    Modal,
    Button,
    Select,
    Spin
} from 'antd';

import {
    Store,
} from 'redux';

import { connect } from 'react-redux';
import { withRouter,BrowserRouter as Router,Route,Switch,Link,Redirect } from 'react-router-dom';
import { changeTrackerListAsync, changeTrackerMode, saveAnnotationsAsync, getJobAnnotationsAsync } from '../actions/annotation-actions';
import {
    CombinedState,
} from 'reducers/interfaces';
import { getCVATStore } from 'cvat-store';

import getCore from 'cvat-core-wrapper';
import { stat } from 'fs';
const core = getCore();
import i18n from '../i18n'


interface DispatchToProps {
    releaseTracker: (trackingList: any[]) => void;
    updateTrackerMode: (trackingMode: boolean) => void;
    save: (trackingMode: boolean) => void;
    updateAnnotations: (job: any) => void;
}

/**
 * 
 * @param state 
 * @returns 
 */
function mapStateToProps(state: any): object {
    return {
    };
}

/**
 *
 * @param dispatch
 * @returns releaseTracker, updateTrackerMode, save, updateAnnotations action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        releaseTracker: (trackingList: any[]): void => dispatch(changeTrackerListAsync(trackingList)),
        updateTrackerMode: (trackingMode: boolean): void => dispatch(changeTrackerMode(trackingMode)),
        save: (job: any): void => dispatch(saveAnnotationsAsync(job)),
        updateAnnotations: (job: any) : void => dispatch(getJobAnnotationsAsync(job))
    };
}

let store: null | Store<CombinedState> = null;

/**
 * 
 * @returns CVATStore if store variable not initialized
 */
function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}

/**
 * @class Defining props and event functions for rendering component instance
 * @description Renders MultiTracker instance in render method
 */
class MultiTracker extends Component {
    constructor(props){
        super(props);
        this.state = {
            onTrackerMode: false
        };
    }


    componentDidMount() {
    }


    /**
     * @description Apply props functions and update state for starting tracker
     */
    private startTracker(){
        const state: CombinedState = getStore().getState();
        let jobInstance = state.annotation.job.instance
        this.props.save(jobInstance)
        this.props.releaseTracker(new Array())
        this.props.updateTrackerMode(true)
        this.setState({
            onTrackerMode: true
        })
    }

    /**
     * @description Apply props functions and update state for stopping tracker
     */
    private stopTracker(){
        this.props.updateTrackerMode(false)
        this.setState({
            onTrackerMode: false
        })
    }

    /**
     * @description Necessary state update, Modal update, and API calls for tracking Object
     */
    private async trackObjects(){
        const state: CombinedState = getStore().getState();
        let trackingObjects = state.annotation.annotations.trackingObjects
        let tracking = true;
        Modal.info({
            title: i18n.t('response-messages.trackingInProgress'),
            visible: tracking,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });

        let resultJson = {
            "tracks" : trackingObjects,
            "trackerLimit": state.annotation.annotations.trackerLimit
        }

        const result = await core.datasets.trackObject(resultJson);
        tracking = false;
        let jobInstance = state.annotation.job.instance
        if (result.data.status === i18n.t('response-messages.success')) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: i18n.t('response-messages.successfulTracking'),
                onOk : () => {
                    this.props.updateAnnotations(jobInstance)
                    this.props.releaseTracker(new Array())
                    this.props.updateTrackerMode(false)
                    this.setState({
                        onTrackerMode: false
                    })
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: i18n.t('keywords.error'),
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     * 
     * @returns Multi Track Mode buttons according to tracker mode
     */
    render () {
        const state: CombinedState = getStore().getState();
        let jobInstance = state.annotation.job.instance
        if(jobInstance.task.tasksIdAnnota[0].doccanoProjectId == 0 && !jobInstance.task.tasksIdAnnota[0].pointCloudFileName){
            return (
                <div style={{display:"table", overflow:"hidden", height:"35px", lineHeight:"0px", marginTop: "6px"}}>
                <div style={{padding:"5px",backgroundColor:"white", display:"table-cell", verticalAlign: "middle"}}>
                    <text className="multi-track-text">{i18n.t('multi-tracker.multiTracker')} &nbsp;</text>
                    {!this.state.onTrackerMode ?
                        <Button type='link' size='small' style={{color:"#6C63FF"}} onClick={() => this.startTracker()}>{i18n.t('multi-tracker.start')} </Button>
                    : null}
                    {this.state.onTrackerMode ?
                        <Button type='link' size='small' style={{color:"#6C63FF"}} onClick={() => this.stopTracker()}>{i18n.t('multi-tracker.stop')} </Button>
                    : null}
                    {this.state.onTrackerMode ?
                        <Button type='link' size='small' style={{color:"#6C63FF"}} onClick={() => this.trackObjects()}>{i18n.t('multi-tracker.track')}</Button>
                    : null}
                </div>
                </div>
            )
        }else{
            return (
                <div></div>
            )
        }

    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiTracker));