// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import {
    Modal
} from 'antd';
import { UserConfirmation } from 'components/register-page/register-form';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'

const cvat = getCore();

export enum AuthActionTypes {
    AUTHORIZED_SUCCESS = 'AUTHORIZED_SUCCESS',
    AUTHORIZED_FAILED = 'AUTHORIZED_FAILED',
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    REGISTER = 'REGISTER',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    REGISTER_FAILED = 'REGISTER_FAILED',
    LOGOUT = 'LOGOUT',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_FAILED = 'LOGOUT_FAILED',
    GETUSER_SUCCESS = 'GETUSER_SUCCESS',
}

const authActions = {
    authorizeSuccess: (user: any, annotaUser: any, config: any) => createAction(AuthActionTypes.AUTHORIZED_SUCCESS, { user, annotaUser, config }),
    authorizeFailed: (error: any) => createAction(AuthActionTypes.AUTHORIZED_FAILED, { error }),
    login: () => createAction(AuthActionTypes.LOGIN),
    loginSuccess: (user: any, annotaUser: any) => createAction(AuthActionTypes.LOGIN_SUCCESS, { user, annotaUser }),
    loginFailed: (error: any) => createAction(AuthActionTypes.LOGIN_FAILED, { error }),
    register: () => createAction(AuthActionTypes.REGISTER),
    registerSuccess: (user: any, annotaUser: any) => createAction(AuthActionTypes.REGISTER_SUCCESS, { user, annotaUser }),
    registerFailed: (error: any) => createAction(AuthActionTypes.REGISTER_FAILED, { error }),
    logout: () => createAction(AuthActionTypes.LOGOUT),
    logoutSuccess: () => createAction(AuthActionTypes.LOGOUT_SUCCESS),
    logoutFailed: (error: any) => createAction(AuthActionTypes.LOGOUT_FAILED, { error }),
    getUserSuccess: (users: any[]) => createAction(AuthActionTypes.GETUSER_SUCCESS, { users }),
};

export type AuthActions = ActionUnion<typeof authActions>;

/**
 * 
 * Actions for registration for cvat
 * 
 * @param username string
 * @param firstName string
 * @param lastName string
 * @param email string
 * @param password1 string
 * @param password2 string
 * @param confirmations UserConfirmation[]
 * @returns ThunkAction
 */

export const registerAsync = (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    password1: string,
    password2: string,
    confirmations: UserConfirmation[],
): ThunkAction => async (
    dispatch,
) => {
    dispatch(authActions.register());

    try {
        await cvat.server.register(username, firstName, lastName, email, password1, password2, confirmations);
        const users = await cvat.users.get({ self: true });
        const annotaUserInstance = (await cvat.users.getAnnotaUsers(users[0].id,null))
        dispatch(authActions.registerSuccess(users[0],annotaUserInstance.users));
    } catch (error) {
        dispatch(authActions.registerFailed(error));
    }
};

/**
 * 
 * Actions for registration for annota
 * 
 * @param username string
 * @param firstName string
 * @param lastName string
 * @param email string
 * @param password1 string
 * @param password2 string
 * @param phoneNumber string
 * @param emailCode string
 * @param smsCode string
 * @param regId string
 * @param birthDate Date
 * @param postCode string
 * @param address string
 * @param city string
 * @param town string
 * @param isWorking string
 * @param workplace string
 * @param experienceExplanation string
 * @param certificateExplanation string
 * @param plExplanation string
 * @param aiExplanation string
 * @param othersExplanation string
 * @param educationInfo JSON
 * @param tckn string
 * @param tcSerial string
 * @param base64photo string
 * @param regType string
 * @param captchaCallback function
 * @returns ThunkAction
 */

export const registerAnnotaAsync = (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    password1: string,
    password2: string,
    phoneNumber: string,
    emailCode: string,
    smsCode: string,
    regId: string,
    birthDate: Date,
    postCode: string,
    address: string,
    city: string,
    town: string,
    isWorking: string,
    workplace: string,
    experienceExplanation: string,
    certificateExplanation: string,
    plExplanation: string,
    aiExplanation: string,
    othersExplanation: string,
    educationInfo: JSON,
    tckn: string,
    tcSerial: string,
    base64photo: string,
    regType: string,
    captchaCallback: () => void
    ): ThunkAction => async (
        dispatch,
    ) => {
        dispatch({
            type: AuthActionTypes.REGISTER,
            payload: {},
        });
        let jsonStringToSend = JSON.stringify(educationInfo);
        let users = null;
        let annotaUserInstance = null;
        let response = null;
        try {
            response = await cvat.server.annotaRegister(username,firstName,lastName,email,password1,password2,"+90"+phoneNumber,emailCode,
                smsCode,regId,birthDate,postCode,address,city,town,isWorking,base64photo,workplace,experienceExplanation,
                 certificateExplanation, plExplanation, aiExplanation, othersExplanation, jsonStringToSend, tckn, tcSerial, regType);
            if(response.status != i18n.t('response-messages.success')){
                Modal.error({
                    title: i18n.t('keywords.error'),
                    content: response.status,
                    onOk() {captchaCallback()}
                });
            }
            else{
                let userMailAddresSplitted = String(email).split("@")
                let domainName = userMailAddresSplitted[userMailAddresSplitted.length-1]

                //can be used later: if(domainName != "ssb.gov.tr")
                try{
                    await cvat.server.login(username, password1)
                }catch(error){
                }
                try{
                    await cvat.server.logout()
                    dispatch(authActions.logoutSuccess())
                }catch(error){
                }

                Modal.info({
                    title: 'Başvuru Başarılı!',
                    content: 'Teşekkürler',
                    okText: 'Tamam',
                    onOk() {
                        /*if(domainName == "ssb.gov.tr")
                            window.location.assign('/tasks')
                        else*/
                        window.location.assign('/home')
                    },
                });
                return;

                //It can be needed later (all code blocks related to registerOnly check)
                /*if(cvat.config.prodType == "registerOnly"){
                    Modal.info({
                        title: 'Kayıt Başarılı!',
                        content: 'Teşekkürler',
                        okText: 'Tamam',
                        onOk() {
                            window.location.reload();
                          },
                    });
                    return;
                }*/
            }
            users = await cvat.users.get({ self: true });
            annotaUserInstance = (await cvat.users.getAnnotaUsers(users[0].id,null))
        } catch (error) {
            dispatch(authActions.registerFailed(error));
            captchaCallback()
            return;
        }
        //dispatch(registerSuccess(users[0], annotaUserInstance.users));
    };

/**
 * 
 * Actions for registration for special user for annota
 * 
 * @param username string
 * @param firstName string
 * @param lastName string
 * @param email string
 * @param password1 string
 * @param password2 string
 * @param phoneNumber string
 * @param emailCode string
 * @param smsCode string
 * @param regId string
 * @param birthDate Date
 * @param postCode string
 * @param address string
 * @param city string
 * @param town string
 * @param isWorking string
 * @param workplace string
 * @param experienceExplanation string
 * @param certificateExplanation string
 * @param plExplanation string
 * @param aiExplanation string
 * @param othersExplanation string
 * @param educationInfo JSON
 * @param tckn string
 * @param tcSerial string
 * @param base64photo string
 * @param regType string
 * @param captchaCallback function
 * @param uuid string
 * @returns ThunkAction
 */

export const registerAnnotaAsyncSpecialAnnotator = (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    password1: string,
    password2: string,
    phoneNumber: string,
    emailCode: string,
    smsCode: string,
    regId: string,
    birthDate: Date,
    postCode: string,
    address: string,
    city: string,
    town: string,
    isWorking: string,
    workplace: string,
    experienceExplanation: string,
    certificateExplanation: string,
    plExplanation: string,
    aiExplanation: string,
    othersExplanation: string,
    educationInfo: JSON,
    tckn: string,
    tcSerial: string,
    base64photo: string,
    regType: string,
    captchaCallback: () => void,
    uuid: string
    ): ThunkAction => async (
        dispatch,
    ) => {
        dispatch({
            type: AuthActionTypes.REGISTER,
            payload: {},
        });
        let jsonStringToSend = JSON.stringify(educationInfo);
        let users = null;
        let annotaUserInstance = null;
        let response = null;
        try {
            response = await cvat.server.annotaRegisterSpecialAnnotator(username,firstName,lastName,email,password1,password2,"+90"+phoneNumber,emailCode,
                smsCode,regId,birthDate,postCode,address,city,town,isWorking,base64photo,workplace,experienceExplanation,
                    certificateExplanation, plExplanation, aiExplanation, othersExplanation, jsonStringToSend, tckn, tcSerial, regType, uuid);
            if(response.status != i18n.t('response-messages.success')){
                Modal.error({
                    title: i18n.t('keywords.error'),
                    content: response.status,
                    onOk() {captchaCallback()}
                });
            }
            else{
                let userMailAddresSplitted = String(email).split("@")
                let domainName = userMailAddresSplitted[userMailAddresSplitted.length-1]

                //can be used later: if(domainName != "ssb.gov.tr")
                try{
                    await cvat.server.login(username, password1)
                }catch(error){
                }
                try{
                    await cvat.server.logout()
                    dispatch(authActions.logoutSuccess())
                }catch(error){
                }

                Modal.info({
                    title: 'Başvuru Başarılı!',
                    content: 'Teşekkürler',
                    okText: 'Tamam',
                    onOk() {
                        /*if(domainName == "ssb.gov.tr")
                            window.location.assign('/tasks')
                        else*/
                        window.location.assign('/home')
                    },
                });
                return;

                //It can be needed later (all code blocks related to registerOnly check)
                /*if(cvat.config.prodType == "registerOnly"){
                    Modal.info({
                        title: 'Kayıt Başarılı!',
                        content: 'Teşekkürler',
                        okText: 'Tamam',
                        onOk() {
                            window.location.reload();
                            },
                    });
                    return;
                }*/
            }
            users = await cvat.users.get({ self: true });
            annotaUserInstance = (await cvat.users.getAnnotaUsers(users[0].id,null))
        } catch (error) {
            dispatch(authActions.registerFailed(error));
            captchaCallback()
            return;
        }
        //dispatch(registerSuccess(users[0], annotaUserInstance.users));
    };

/**
 * 
 * Actions for login
 * 
 * @param username string
 * @param password string
 * @param captchaToken string
 * @returns ThunkAction
 */

export const loginAsync = (username: string, password: string, captchaToken: string): ThunkAction => async (dispatch) => {
    dispatch(authActions.login());

    try {
        await cvat.server.login(username, password, captchaToken);
        const users = await cvat.users.get({ self: true });
        const annotaUserInstance = (await cvat.users.getAnnotaUsers(users[0].id,null))

        dispatch(authActions.loginSuccess(users[0], annotaUserInstance.users));

    } catch (error) {
        dispatch(authActions.loginFailed(error));
        return;
    }
};

/**
 * 
 * Actions for reset password
 * 
 * @param email string
 * @returns ThunkAction
 */

export const resetPasswordAsync = (email: string): ThunkAction => async (dispatch) => {
    let response = null

    try {
        response = await cvat.server.reset(email);
    } catch (error) {
        if(String(error).toLowerCase().includes('invalid') || String(error).toLowerCase().includes('geçersiz')){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: "Girilen e-posta adresi ile bir hesap kayıtlı bulunmamaktadır.",
            });
        }
        return;
    }

    if(response != 'Success'){
        Modal.error({
            title: i18n.t('keywords.error'),
            content: response,
        });
    }else{
        Modal.info({
            title: 'Şifre sıfırlama isteğiniz alındı!',
            content: 'Lütfen talimatlar için e-posta adresinize gelen e-postayı kontrol ediniz.',
            okText: 'Tamam',
            onOk() {
                window.location.assign('/home')
            },
        });
    }
};

/**
 * 
 * Actions for reset password confirm
 * 
 * @param uidb64 string
 * @param token string
 * @param password1 string
 * @param password2 string
 * @returns ThunkAction
 */

export const resetPasswordConfirmAsync = (uidb64: string, token: string, password1: string, password2: string): ThunkAction => async (dispatch) => {
    let response = null

    try {
        response = await cvat.server.resetConfirm(uidb64, token, password1, password2);
    } catch (error) {
        if(String(error).toLowerCase().includes('invalid') || String(error).toLowerCase().includes('geçersiz')){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: "Geçersiz şifre sıfırlama simgesi. Lütfen yeni bir şifre sıfırlama e-postası isteyiniz.",
            });
        }
        return;
    }

    if(response != 'Success'){
        Modal.error({
            title: i18n.t('keywords.error'),
            content: response,
        });
    }else{
        Modal.info({
            title: 'Şifreniz başarı ile sıfırlandı!',
            content: 'Sisteme yeni şifreniz ile giriş yapabilirsiniz.',
            okText: 'Tamam',
            onOk() {
                window.location.assign('/home')
            },
        });
    }
};

/**
 * 
 * Actions for logout
 * 
 * @returns ThunkAction
 */

export const logoutAsync = (): ThunkAction => async (dispatch) => {
    dispatch(authActions.logout());

    try {
        await cvat.server.logout();
        dispatch(authActions.logoutSuccess());
        window.location.replace(`${cvat.config.doccanoUIRedirectURL}/authenticator?username=user&action=logout`)
    } catch (error) {
        dispatch(authActions.logoutFailed(error));
    }
};

/**
 * 
 * Actions for authorization
 * 
 * @returns ThunkAction
 */

export const authorizedAsync = (): ThunkAction => async (dispatch) => {
    try {
        const result = await cvat.server.authorized();

        if (result) {
            const userInstance = (await cvat.users.get({ self: true }))[0];
            const annotaUserInstance = (await cvat.users.getAnnotaUsers(userInstance.id,null))

            dispatch(authActions.authorizeSuccess(userInstance,annotaUserInstance.users, null));
        } else {
            const configList = await cvat.management.getRegistrationStatusPublic();
            let config = null
            if(configList.length > 0)
                config = configList[0]
            dispatch(authActions.authorizeSuccess(null,null,config));
        }
    } catch (error) {
        dispatch(authActions.authorizeFailed(error));
    }
};
