import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Popconfirm, Icon, Modal, Table, Select, Spin, Checkbox } from 'antd';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'
import { AuthState } from '../reducers/interfaces';
import Column from 'antd/lib/table/Column';
import {
    CombinedState,
} from 'reducers/interfaces';
import { Store } from 'redux';
import { getCVATStore } from 'cvat-store';
import Text from 'antd/lib/typography/Text';

import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';

const cvat = getCore();
let store: null | Store<CombinedState> = null;
let datasetViews = new Array();
let DatasetList = <div></div>

let children = new Array();
var myMapForUserSelections = new Map();


function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}
interface StateToProps {
    auth: AuthState;
}

function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}
class VSPDatasetsPage extends React.PureComponent<StateToProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPage: 1,
            totalTestCount: 0,
            datasetViews: [],
            users: [],
            checkedAll: false,
            datasetsToBeUsed: [],
            datasetLoading: false,
            editModalVisible: false,
            editDatasetID: 0,
            editDatasetName: "",
            editDatasetNewName: "",
        }

        //this.componentDidMount();
    }

    componentDidMount() {
        this.getUsers("",1);
        this.handleTests = this.handleTests.bind(this);
        const testsAsync = this.getDatasets(1);
        testsAsync.then(this.handleTests.bind(self));
    }

    /**
     *
     * @param filter
     * @param pageNum
     * @description get all aproved+non-blocked users and create selection list option for each user
     */
    //get all aproved+non-blocked users and create selection list option for each user
    async getUsers(filter: any, pageNum: any){
        let users;
        children = new Array();
        const filteredQuery = { ...filter };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }
        users = await cvat.users.getAnnotaUsers(null,pageNum,'approved',null,null,"False", true, null, filteredQuery);

        for (let i = 0; i < users.users.length; i++) {
            let valueCurrent  = users.users[i].id.id + '%%%' + users.users[i].id.username
            if(users.users[i].id.groups.filter(elem => elem==="provider" || elem ==='admin').length > 0){
                children.push(
                    <Select.Option value={valueCurrent} label={users.users[i].id.username}>
                        <div className="demo-option-label-item">
                            {users.users[i].id.username}
                        </div>
                    </Select.Option>)
            }
        }

        this.setState({
            users : users,
        });
    }

    private filter = (inputValue: String, option: any): boolean => {
        if(inputValue.startsWith('id:') && inputValue.length > 3){
            if(inputValue.split(':').pop() == option.props.value.split('%%%')[0])
                return true
        }
        else{
            if(option.props.value.split('%%%')[1].toLowerCase().includes(inputValue.toLowerCase()))
                return true
        }
        return false;
    }

    /**
     * @async
     * @param value
     * @description construct search query with value, then asynchronous call to get users with query
     */
    private handleSearch = async (value: string): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const { users } = state;

        const query = { ...(users.gettingQuery) };
        const search = value.replace(/\s+/g, ' ').replace(/\s*:+\s*/g, ':').trim();

        const fields = ['name', 'id'];
        for (const field of fields) {
            query[field] = null;
        }
        query.search = null;

        let specificRequest = false;
        for (const param of search.split(/[\s]+and[\s]+|[\s]+AND[\s]+/)) {
            if (param.includes(':')) {
                const [field, fieldValue] = param.split(':');
                if (fields.includes(field) && !!fieldValue) {
                    specificRequest = true;
                    if (field === 'id') {
                        if (Number.isInteger(+fieldValue)) {
                            query[field] = +fieldValue;
                        }
                    } else {
                        query[field] = fieldValue;
                    }
                }
            }
        }

        if (!specificRequest && value) {
            query.search = value;
        }

        //NOT SURE TO WAIT OR NOT (User Experience)
        await this.getUsers(query,null)
    };

    /**
     * @async
     * @param testJobs
     * @description create dataset views template for test jobs
     */
    async handleTests(testJobs: any[]) {
        let datasetViews = new Array();
        let warning = i18n.t('datasets.deleteDatasetWarning');

        datasetViews.push(
            <Table rowKey={record => record.id}
                dataSource={testJobs}
                pagination={false}
                locale={{emptyText: 'Sonuç Bulunamadı'}}
                expandedRowRender={(record) => <>{this.handleExpand(record)}</>}
                >

                <Column title="Veri Kümesi Adı" dataIndex="name" render={(text, record) => <>{this.nameRender(record)}</>} />

                <Column title={i18n.t('datasets.mediaType')} dataIndex="mediaType" />
                <Column title={i18n.t('datasets.fileCount')} dataIndex="fileCount" />

                <Column title="İşlemler" key="action" render={(record) =>
                    <div>
                        <Link to={{ pathname: '/datasets/modify/' + record.id }}> <Button type="default" >{i18n.t('datasets.expand')}</Button></Link>
                        { this.props.auth.annotaUser[0].id.groups.includes("ssbprovider") ?
                        <Popconfirm
                            title={warning}
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => this.deleteDataset(record.id)
                            }
                        >
                            <Button style={{ marginLeft: '5%' }}  >{i18n.t('datasets.delete')} </Button>
                        </Popconfirm> : null }

                        { this.props.auth.annotaUser[0].id.groups.includes("ssbprovider") ?
                        <Checkbox style={{ marginLeft: '5%' }} checked={record.isPrivate} onChange={(e) => this.setPrivacy(record.id)}>{i18n.t('datasets.private')}
                        </Checkbox> : null }

                    </div>
                }
                />
            </Table>
        );
        this.setState({
            datasetViews: datasetViews
        });
    }

    /**
     *
     * @param test
     * @returns expansion template for selected test
     */
    private handleExpand(test: any){
        const columns = [
            {
                title: i18n.t('admin-page.userName'),
                dataIndex: 'username',
                key: 'username',
                render: text => <a>{text}</a>,
            },
            {
                title: i18n.t('admin-page.name'),
                dataIndex: 'first_name',
                key: 'first_name',
                render: text => <a>{text}</a>,
            },
            {
                title: i18n.t('admin-page.lastName'),
                dataIndex: 'last_name',
                key: 'last_name',
                render: text => <a>{text}</a>,
            },
            {
                title: i18n.t('admin-page.email'),
                dataIndex: 'email',
                key: 'email',
                render: text => <a>{text}</a>,
            },
            {
                title: i18n.t('datasets.delete'),
                key: 'action',
                render: (record) => (
                      <Popconfirm
                          title={i18n.t('datasets.deleteUserWarning')}
                          cancelText={i18n.t('keywords.no')}
                          okText={i18n.t('keywords.yes')}
                          onConfirm={
                              (e) => {
                                this.removeUserFromDataset(test.id,record.id)
                              }
                          }
                      >
                          <Button > {i18n.t('datasets.delete')} </Button>
                      </Popconfirm>
                ),
              },
        ];

        return(

            (test.isPrivate) ?
                <div>
                <h1 style={{marginTop:'1%'}}>{i18n.t('datasets.details')}</h1>
                <Row  >
                    <Col span={8}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder={i18n.t('datasets.selectSpecialAnnotators')}
                            onChange={(e) => this.handleChangeUserSelector(e,test.id)}
                            onSearch={this.handleSearch}
                            optionLabelProp="label"
                            filterOption={this.filter}
                        >
                            {children}
                        </Select>
                    </Col>
                    <Col span={3} style={{ marginLeft: '1%' }}>
                        <Button type='primary' onClick={() => this.setUsers(test.id)} block>{i18n.t('datasets.selectUsers')}</Button>
                    </Col>
                </Row>
                    <Table bordered title={()=><>{i18n.t('datasets.specialAnnotators')}</>} style={{ marginTop: '2%' }} rowKey='fileName'  locale={{emptyText: i18n.t('datasets.noSpecialAnnotators')}} columns={columns} dataSource={test.specialAnnotators}></Table>

                 </div> : "Gizli Değil"

        )
    }

    /**
     *
     * @param value
     * @param datasetId
     * @description Select dataset provider user
     */
    public handleChangeUserSelector(value: any, datasetId: any) {
        for(let i = 0; i < value.length ; i++){
            value[i] = value[i].split("%%%")[0]
        }

        let usersTotal = {
            "userIds": value
        }
        myMapForUserSelections.set(datasetId, usersTotal);
    }

    /**
     * @async
     * @param id
     * @description set privacy api proxy call
     */
    async setPrivacy(id: any){
        let datasets;
        datasets = await cvat.datasets.setPrivacy(id);
        this.updateList()
    }

    public async removeUserFromDataset(datasetId: any, userId: any){
        await cvat.datasets.removeUser(datasetId,userId);
        this.updateList()
    }

    /**
     * @description update dataset list, used after modifications
     */
    public updateList(){
        myMapForUserSelections.clear()
        const testsAsync = this.getDatasets(this.state.currentPage);
        testsAsync.then(this.handleTests.bind(self));
    }

    /**
     *
     * @param datasetId
     * @description update user selections for dataset, get user selections from myMapForUserSelections
     */
    public async setUsers(datasetId: any){
        var userSelectionsForRelatedDataset = myMapForUserSelections.get(datasetId)
        if(userSelectionsForRelatedDataset != null)
            await cvat.datasets.setUsers(datasetId,JSON.stringify(userSelectionsForRelatedDataset));
            this.updateList()
    }

    /**
     *
     * @param set
     * @returns Template for displaying dataset name
     */
    nameRender = (set: any) => {
        if (this.props.auth.annotaUser[0].id.groups[0] === 'admin' || (this.props.auth.annotaUser[0].id.groups[0]) === 'provider')
            return (<div>{set.name}
                <Button style={{ marginLeft: '1%' }} size="small"
                    onClick={() => this.showEditNameModal(set.id, set.name)}><Icon type="edit" /></Button></div>);
        else
            return (<p>{set.name}</p>);

    }

    /**
     *
     * @param id
     * @param name
     * @description sets state for displaying dataset name editing modal
     */
    showEditNameModal = (id: number, name: string) => {
        this.setState({
            editModalVisible: true,
            editDatasetID: id,
            editDatasetName: name,
            editDatasetNewName: "",
        });
    };

    /**
     * @async
     * @description update dataset name api proxy call, sets state for edit database name modal visibility
     */
    handleOk = async () => {
        await cvat.datasets.updateDatasetName(this.state.editDatasetID, this.state.editDatasetNewName);
        this.componentDidMount();
        this.setState({
            editModalVisible: false,
            editDatasetNewName: "",
        });
    };

    /**
     * @description cancel modal
     */
    handleCancel = () => {
        this.setState({
            editModalVisible: false,
            editDatasetNewName: "",
        });
    };

    /**
     * @async
     * @param value
     * @returns datasets
     * @description get datasets api proxy call, sets total test count
     */
    async getDatasets(value: any) {
        let datasets;
        datasets = await cvat.datasets.getVSPDatasets(null, 10, value);
        this.setState({
            totalTestCount: datasets.count
        });
        return datasets;
    }

    /**
     * @async
     * @param datasetId
     * @returns response
     * @description deletes dataset with given id, delete dataset api proxy call
     */
    async deleteDataset(datasetId: any) {
        let response;
        response = await cvat.datasets.deleteDataset(datasetId);
        const testsAsync = this.getDatasets(1);
        testsAsync.then(this.handleTests.bind(self));
        this.setState({
            currentPage: 1,
        });
        return response;
    }

    /**
     *
     * @returns dataset list JSX template
     */
    private renderDatasetList() {
        DatasetList = <div style={{paddingTop:"15px"}}>{this.state.datasetViews}</div>
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('datasets.datasets')}</Text>
                {DatasetList}
                <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                    <Col>
                        <ConfigProvider locale ={trTR}>
                            <Pagination defaultCurrent={1}
                                    pageSize={10}
                                    onChange={this.handleChange}
                                    current={this.state.currentPage}
                                    total={this.state.totalTestCount}
                                    showQuickJumper={true}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
                <Modal
                    title={i18n.t('datasets.editModalTitle')}
                    visible={this.state.editModalVisible}
                    onOk={this.handleOk}
                    okText='Tamam'
                    cancelText='İptal'
                    onCancel={this.handleCancel}
                >
                    <p>{i18n.t('datasets.oldDatasetName')}: {this.state.editDatasetName}</p>
                    <p>{i18n.t('datasets.newDatasetName')}:
                        <input style={{ marginLeft: "1%" }} type="text" className="regular h2" value={this.state.editDatasetNewName}
                            onChange={(event) => this.setState({ editDatasetNewName: event.target.value })} />
                    </p>
                </Modal>
            </div>
        )
    }

    //TODO: pagination for datasets
    handleChange = value => {
        datasetViews = new Array()
        const tasksAsync = this.getDatasets(value);
        tasksAsync.then(this.handleTests.bind(self));
        this.setState({
            currentPage: value,
        });
    };

    public render() {
        return this.renderDatasetList();
    }
}

export default withRouter(connect(
    mapStateToProps,
)(VSPDatasetsPage));
