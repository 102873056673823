// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Dropdown from 'antd/lib/dropdown';
import Tooltip from 'antd/lib/tooltip';
import Modal from 'antd/lib/modal';
import Progress from 'antd/lib/progress';
import moment from 'moment';
import i18n from '../../i18n'
import { Avatar, Alert } from 'antd';
import { LateJobIcon} from 'icons';
import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import { ActiveInference } from 'reducers/interfaces';
import { MenuCustomIcon } from 'icons';

import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
import { TaskPageSelection, TaskStatus, TaskType } from '../../../../cvat-core/src/enums';
const cvat = getCore();

import consts from 'consts';
const {AVATAR_MAP} = consts;
/**
 * @description props for Task Item
 */
export interface TaskItemProps {
    taskInstance: any;
    previewImage: string;
    deleted: boolean;
    hidden: boolean;
    activeInference: ActiveInference | null;
    user: any;
    cancelAutoAnnotation(): void;
}

class TaskItemComponent extends React.PureComponent<TaskItemProps & RouteComponentProps> {
    constructor(props: TaskItemProps & RouteComponentProps) {
        super(props);
        this.state = {
            annotaTask: any,
            userHasJobOnTask: false,
        }
    }
    /**
     * @description Function for rendering preview image from props or Google user API
     * @returns col
     */
    private renderPreview(): JSX.Element {
        const { previewImage } = this.props;
        let previewShow;
        if (previewImage != null && previewImage != "") {
            previewShow = previewImage
        }
        else {
            previewShow = "https://lh3.googleusercontent.com/proxy/o41TXuOJYnVr7oLT9M5aIdJGjno_jHsixB9eR1jZlfiQI534gdRdUxRqma5t69pbdG2jLk5g8O8a_w90DScLPlXT0s_NtywwfXK5ruMhGjwXaEhU3LeenulR4ACw-GkKVO3Ir082ODio3FW7Q-DY-IX9hXCb"
        }
        return (
                <Col span={24}>
                    <div className='cvat-task-item-preview-wrapper-updated'>
                        <img alt={i18n.t('task-page.preview')} className='cvat-task-item-preview' src={previewShow} />
                    </div>
                    {/* <Avatar style={{ justifyContent: "center", display: "flex", margin: "auto" }} src={previewShow} shape="square" size={170}></Avatar><br></br> */}
                </Col>
        );
    }
    /**
     * @description function for rendering secret task alert
     * @returns Tooltip
     */
    private renderSecretTaskAlert(): JSX.Element {
        return (
            <Tooltip title={<Text style={{color:"white"}}>Bu görev veri sağlayıcı tarafından gizlenmiştir.</Text>} style={{fontSize:"10px", borderColor:"#5A5C69", backgroundColor:"#5A5C69", opacity:"0.85"}}>
                <Icon style={{transform: "scale(1.3)",position: "absolute",top: "12px",right: "34px"}} type="lock" />
            </Tooltip>
        )
    }
    /**
     * @description function for rendering Description part of app
     * @returns Col
     */
    private renderDescription(): JSX.Element {
        // Task info
        const { taskInstance, user } = this.props;
        const { id } = taskInstance;
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        const updated = moment(taskInstance.updatedDate).locale(i18n.t('keywords.locale')).fromNow();
        const created = moment(taskInstance.createdDate).locale(i18n.t('keywords.locale')).format(i18n.t('keywords.dateFormat'));
        let viewer = taskInstance.tasksIdAnnota[0].viewers.filter(vUser => user.id == vUser.id).length>0

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;
        let marginTop = '-5%'
        if(user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator')))
            marginTop = '0%'
        return (
                <Col span={23} className='cvat-task-item-description' style={{marginLeft:'5%', marginTop:marginTop}}>
                    <Text style={{fontSize:"16px"}} strong type='secondary'>{`#${id}: `}</Text>
                    <Text style={{color:"#5A5C69",fontSize:"16px"}} className='cvat-text-color'>{name}</Text>
                    {/* <br />
                    { owner
                        && (
                            <>
                                <Text type='secondary'>
                                    {i18n.t('task-page.createdBy', { owner: owner, created: created })}
                                </Text>
                                <br />
                            </>
                        )}
                    <Text type='secondary'>{i18n.t('tasks-page.lastUpdated') + `: ${updated}`}</Text>  */}
                    <div style={{float:'right'}}>
                        {taskInstance.tasksIdAnnota[0].isPrivate ? this.renderSecretTaskAlert() : null}

                        { !(user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator'))) &&
                        <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} viewer={viewer} />}>
                            <Icon style={{zIndex:1, transform: "scale(0.8)"}} className='cvat-menu-icon' component={MenuCustomIcon} onClick={(e)=>{e.preventDefault();e.stopPropagation()}} />
                        </Dropdown>}
                    </div>
                </Col>
        );
    }
    /**
     * Rendering Description details
     * @returns col
     */
    private renderDescriptionDetails(): JSX.Element {
        // Task info
        const { taskInstance } = this.props;
        const { id } = taskInstance;
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        const updated = moment(taskInstance.updatedDate).locale('tr').fromNow();
        const created = moment(taskInstance.createdDate).locale('tr').format(i18n.t('keywords.dateFormat'));

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;

        return (
                <Col span={20} className='cvat-task-item-description' style={{marginLeft:'5%',marginTop:'-12%'}}>
                    <br />
                    { owner
                        && (
                            <>
                                <Text type='secondary'>
                                    {i18n.t('task-page.createdBy', { owner: owner, created: created })}
                                </Text>
                                <br />
                            </>
                        )}
                    <Text type='secondary'>{i18n.t('tasks-page.lastUpdated') + `: ${updated}`}</Text>
                </Col>
        );
    }
    /**
     * Rendering progress part
     * @returns col
     */
    private renderProgress(): JSX.Element {
        const {
            taskInstance,
            activeInference,
            cancelAutoAnnotation,
        } = this.props;
        // Count number of jobs and performed jobs
        let numOfJobs = 0
        let numOfCompleted = 0
        if(taskInstance.jobStatistics){
            numOfJobs = taskInstance.jobStatistics.numOfJobs
            numOfCompleted = taskInstance.jobStatistics.numOfCompletedJobs
        }
        const maxJobCountForTask = taskInstance.segments.length * taskInstance.tasksIdAnnota[0].numOfAssigneeLimit

        // Progress appearence depends on number of jobs
        let progressColor = null;
        let progressText = null;
        if (taskInstance.tasksIdAnnota[0].state == 'completed') {
            progressColor = 'cvat-task-completed-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.completedProgress')}</Text>;
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.inProgress')}</Text>;
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.pending')}</Text>;
        }

        let taskState = "";
        if (taskInstance.tasksIdAnnota[0].state == 'allocated') {
            taskState = i18n.t('tasks-page.taskReachedAssigneeLimit')
        }

        return (
                <Col span={24} style={{marginLeft:'5%',marginTop:'-8%'}}>
                    <Row type='flex' justify='space-between' align='top'>
                        <Col>
                            <svg height='8' width='8' className={progressColor}>
                                <circle cx='4' cy='4' r='4' strokeWidth='0' />
                            </svg>
                            {progressText}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Progress
                                className={`${progressColor} cvat-task-progress`}
                                percent={numOfCompleted * 100 / numOfJobs}
                                strokeColor='#1890FF'
                                showInfo={false}
                                strokeWidth={5}
                                size='small'
                            />
                        </Col>
                    </Row>
                    <Row>{taskState}</Row>
                    { activeInference
                        && (
                            <>
                                <Row>
                                    <Col>
                                        <Text strong>{i18n.t('keywords.automaticAnnotation')}</Text>
                                    </Col>
                                </Row>
                                <Row type='flex' justify='space-between'>
                                    <Col span={22}>
                                        <Progress
                                            percent={Math.floor(activeInference.progress)}
                                            strokeColor={{
                                                from: '#108ee9',
                                                to: '#87d068',
                                            }}
                                            showInfo={false}
                                            strokeWidth={5}
                                            size='small'
                                        />
                                    </Col>
                                    <Col span={1} className='close-auto-annotation-icon'>
                                        <Tooltip title={i18n.t('tasks-page.cancelAutomaticAnnotation')}>
                                            <Icon
                                                type='close'
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: i18n.t('tasks-page.cancelAutomaticAnnotationQuestion'),
                                                        content: i18n.t('tasks-page.progressWillBeLost'),
                                                        okType: 'danger',
                                                        onOk() {
                                                            cancelAutoAnnotation();
                                                        },
                                                    });
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </>
                        )}
                </Col>
        );
    }
    /**
     * Rendering Proggress of Jobs
     * @returns col
     */
    private renderProgressJobs(): JSX.Element {
        const {
            taskInstance,
            activeInference,
            cancelAutoAnnotation,
        } = this.props;
        // Count number of jobs and performed jobs
        let numOfJobs = 0
        let numOfCompleted = 0
        if(taskInstance.jobStatistics){
            numOfJobs = taskInstance.jobStatistics.numOfJobs
            numOfCompleted = taskInstance.jobStatistics.numOfCompletedJobs
        }
        const maxJobCountForTask = taskInstance.segments.length * taskInstance.tasksIdAnnota[0].numOfAssigneeLimit

        // Progress appearence depends on number of jobs
        let progressColor = null;
        let progressText = null;
        if (taskInstance.tasksIdAnnota[0] == 'completed') {
            progressColor = 'cvat-task-completed-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.completedProgress')}</Text>;
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.inProgress')}</Text>;
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = <Text strong className={progressColor}>{i18n.t('tasks-page.pending')}</Text>;
        }

        let taskState = "";
        if (taskInstance.tasksIdAnnota[0].state == 'allocated') {
            taskState = i18n.t('tasks-page.taskReachedAssigneeLimit')
        }

        return (
                <Col span={24} style={{marginLeft:'5%',marginTop:'-8%'}}>
                    <Col >
                        <Text style={{color:"#5A5C69"}} type='secondary'>{i18n.t('tasks-page.taskCompletionUpdated', { numOfCompleted: numOfCompleted, maxJobCountForTask: numOfJobs })}</Text>
                    </Col>
                </Col>

        );
    }
    /**
     * Rendering task type
     * @returns col
     */
    private renderTaskType(): JSX.Element {
        const {
            taskInstance,
            user
        } = this.props;

        let viewer = taskInstance.tasksIdAnnota[0].viewers.filter(vUser => user.id == vUser.id).length>0
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        let typeTask = ''
        if(taskInstance.tasksIdAnnota[0].doccanoProjectId != 0)
            typeTask = 'Metin'
        else
            typeTask = 'Görsel'


        let userAssigned = 0
        let hasNotifiedJob = 0
        if(taskInstance.jobStatistics){
            userAssigned = taskInstance.jobStatistics.userJobs
            hasNotifiedJob = taskInstance.jobStatistics.notifiedUserJobs
        }

        let testOrTrainingTypeIndicator = ''

        try {
            let testOrTrainingType = taskInstance.tasksId.find((task) => task.taskId == taskInstance.id).taskType
            switch(testOrTrainingType){
                case 'test':
                    testOrTrainingTypeIndicator = ' | Test'
                    break;
                case 'training':
                    testOrTrainingTypeIndicator = ' | Eğitim'
                    break;
                default:
                    testOrTrainingTypeIndicator = ''
            }
        } catch (error) {
            // Do nothing
        }
        

        return (
            // <Row type='flex' justify='space-between'>
            // //     <Col span={6} style={{marginBottom:'-5%'}}>
            // //         <Col >
            // //             <Text style={{color:"#5A5C69"}} type='secondary'>{typeTask}</Text>
            // //         </Col>
            // //     </Col>
            //     {viewer &&
            //     <Col span={17} style={{marginBottom:'-5%'}}>
            //         <Col >
            //             <Text style={{color:"#5A5C69"}} type='secondary'>Paylaşılan Görev: {owner} tarafından</Text>
            //         </Col>
            //     </Col>
            //     }
            // </Row>
            <Col span={23} style={{marginBottom:'-5%', marginLeft: '5%'}}>
                <Col >
                    <Text style={{color:"#5A5C69"}} type='secondary'>{typeTask}{testOrTrainingTypeIndicator}</Text>
                    {viewer &&
                        <Text style={{color:"#5A5C69", float:'right', marginRight: '5%'}} type='secondary'>Paylaşılan Görev: {owner} tarafından</Text>
                     }
                    { hasNotifiedJob>0 && <Tooltip title="Süresi Geçmiş İşiniz Bulunuyor!">
                        <Icon component={LateJobIcon} style={{marginRight: '4%',float: 'right', fontSize:'24px'}}/></Tooltip> }
                    {(userAssigned > 0) &&
                         <Avatar style={{color:"#5A5C69", float:'right', marginRight: '5%'}} src={AVATAR_MAP.get(user.avatarId)}/>
                    }

                </Col>

            </Col>

        );
    }
    /**
     * Rendering Assigned state
     * @returns col
     */
    private renderAssigned(): JSX.Element {
        const {
            taskInstance,
            user
        } = this.props;

        const userAssigned = taskInstance.jobs.filter(
            (job: any): boolean => job.assignee && job.assignee.id === user.id,
        ).length;

        let assigned = 'ATANDI'
        if(userAssigned == 0)
            assigned = ''

        return (
            <Col span={2}>
                <Col >
                    <Text type='secondary' style={{color:'#FCCE5A'}}>{assigned}</Text>
                </Col>
            </Col>

        );
    }
    /**
     * Rendering Navigation part
     * @returns col
     */
    private renderNavigation(): JSX.Element {
        const {
            taskInstance,
            history,
            user
        } = this.props;
        const { id } = taskInstance;
        let viewer = taskInstance.tasksIdAnnota[0].viewers.filter(vUser => user.id == vUser.id).length>0

        return (
            <Col span={2} >
                {/*<Row type='flex' justify='end'>
                    <Col>
                        <Button
                            id="open"
                            className='cvat-item-open-task-button'
                            type='primary'
                            size='large'
                            ghost
                            href={`/tasks/${id}`}
                            onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                history.push(`/tasks/${id}`)
                            }}
                        >
                            {i18n.t('keywords.open')}
                        </Button>
                    </Col>
                </Row>*/}
                <Col hidden={user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator'))} className='cvat-item-open-task-actions'>
                    {/* <Text className='cvat-text-color'>{i18n.t('keywords.actions')}</Text> */}
                    <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} viewer={viewer} />}>
                        <Icon style={{zIndex:1}} className='cvat-menu-icon' component={MenuCustomIcon} onClick={(e)=>{e.preventDefault();e.stopPropagation()}} />
                    </Dropdown>
                </Col>
            </Col>
        );
    }
    /**
     * Get Task State of TaskID
     * @param taskId Id of Task
     */
    async getTaskState(taskId: any) {
        let response = null;
        //response = await cvat.tasks.getAnnotaTasks(null, taskId, null);
        this.setState({
            annotaTask: response
        })
    }
    /**
     * After components mounted, call getTaskState function
     */
    public componentDidMount() {
        const { id } = this.props.taskInstance;
        this.getTaskState(id)
    }
    /**
     * Rendering Task Item part 
     * @returns Row
     */
    public render(): JSX.Element {
        const {
            deleted,
            hidden,
            taskInstance,
            user
        } = this.props;
        const style = {};
        if (deleted) {
            (style as any).pointerEvents = 'none';
            (style as any).opacity = 0.5;
        }

        const { jobs } = this.props.taskInstance;

        /*if (hidden) {
            (style as any).display = 'none';
        }*/

        return (
            <Row className='cvat-tasks-list-item-updated' type='flex' style={{ ...style }}>
                {this.renderPreview()}
                {this.renderDescription()}
                {/* {this.renderNavigation()} */}
                {this.renderProgress()}
                {this.renderProgressJobs()}
                {!(user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator'))) && this.renderDescriptionDetails()}
                {/* {this.renderAssigned()} */}
                {this.renderTaskType()}
            </Row>
        )
    };
}

export default withRouter(TaskItemComponent);
