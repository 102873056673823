import React from 'react';

import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import {
    Col,
    Row,
} from 'antd';

import RegisterForm, { RegisterData } from './register-form';
import { useTranslation } from 'react-i18next';

let spanSize= ((Number)(13000/document.documentElement.clientWidth))| 0;

interface RegisterPageComponentProps {
    fetching: boolean;
    onRegister: (username: string, firstName: string,
        lastName: string, email: string,
        password1: string, password2: string,
        phoneNumber: string, emailCode: string, smsCode: string,
        regId: string, birthDate: Date,
        postCode: string, address: string, city: string, town: string,
        isWorking: string, workplace: string, experienceExplanation: string,
        certificateExplanation: string, plExplanation: string, aiExplanation: string,
        othersExplanation: string,educationInfo: JSON, tckn: string, tcSerial: string,
        base64Photo: string, regType: string, captchaCallback:() => void) => void;
}

/**
 * Create registration form
 *
 * @param props consist of fetchin boolean and onRegister state
 * @returns registration form
 */
function RegisterPageComponent(
    props: RegisterPageComponentProps & RouteComponentProps,
): JSX.Element {
    const sizes = {
        xs: { span: 16 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 16 },
        xl: { span: spanSize },
    };

    const {
        fetching,
        onRegister,
    } = props;
    const { t } = useTranslation();
    const style={
        position: 'absolute', left: '50%',
        transform: 'translate(-50%)'
    }
    const vw = document.documentElement.clientWidth;
    return (
        <Row type={vw > 1000 ? 'flex': undefined} justify={vw > 1000 ? 'center': undefined}
        align={vw > 1000 ? 'top': undefined}>
            <br/>
            <br/>
            <Col span={16}>
                <Row style={{marginTop:"5%"}} type="flex" justify="center" align="top">
                    <Col span={8}>
                        <img src='/veri_kovani_logo.svg' width='100%' vspace="50"></img>
                    </Col>
                </Row>
                <Row type="flex" justify="center">
                    <Title level={2}> Hesap Oluştur </Title>
                    <Col style={{display:"flex", justifyContent:"center"}} span={24}>
                        <RegisterForm
                            fetching={fetching}
                            onSubmit={(registerData: RegisterData): void => {
                                onRegister(
                                    registerData.username,
                                    registerData.firstName,
                                    registerData.lastName,
                                    registerData.email,
                                    registerData.password1,
                                    registerData.password2,
                                    registerData.phoneNumber,
                                    registerData.emailCode,
                                    registerData.smsCode,
                                    registerData.regId,
                                    registerData.birthDate,
                                    registerData.postCode,
                                    registerData.address,
                                    registerData.city,
                                    registerData.town,
                                    registerData.isWorking,
                                    registerData.workplace,
                                    registerData.experienceExplanation,
                                    registerData.certificateExplanation,
                                    registerData.plExplanation,
                                    registerData.aiExplanation,
                                    registerData.othersExplanation,
                                    registerData.educationInfo,
                                    registerData.tckn,
                                    registerData.tcSerial,
                                    registerData.base64Photo,
                                    registerData.regType,
                                    registerData.captchaCallback
                                );
                            }}
                        />
                    </Col>
                </Row>
                {/* <Row type='flex' justify='start' align='top'>
                    <Col>
                        <Text strong>
                            Hesabın var mı?
                            <Link to='/auth/login'> Giriş yap </Link>
                        </Text>
                    </Col>
                </Row> */}
            </Col>
        </Row>
    );
}

export default withRouter(RegisterPageComponent);
