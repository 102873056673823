import getCore from 'cvat-core-wrapper';
import React, {useState} from 'react';

import { useIdleTimer } from 'react-idle-timer'

type IdleAutoSaverProps = {
    onIdle: VoidFunction
}

const cvat = getCore();

function IdleAutoSaver({ onIdle }: IdleAutoSaverProps){

    const [autoSaveTimeout, setAutoSaveTimeout] = useState(20);

    const idleTimeoutInMinutes = typeof (process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES) === 'undefined' ? 1 : parseInt(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES);

    const onIdleWrapped = () => {
        console.log('User is idle');
        onIdle();
    }

    const onActive = () => {
        console.log('User is active');
    }

    cvat.management.getAutoSaveDuration().then((resp) => {
        setAutoSaveTimeout(resp.value);
    });

    const idleTimer = useIdleTimer({ onIdle: onIdleWrapped, onActive, timeout: 1000 * autoSaveTimeout });

    return (<></>);
}

export default IdleAutoSaver;