import React from 'react';
import './styles.scss';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form,
    Button,
    Input,
    Icon,
    Row,
    Col,
    Modal,
    Select
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
import i18n from 'i18n';
import Text from 'antd/lib/typography/Text';

const core = getCore();
interface OwnProps{
    setModalState(value:boolean):void;
}
class ContactUsComponent extends React.PureComponent<FormComponentProps&OwnProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            charCount: 0,
            mailSelect: "bilgi@verikovani.ssb.gov.tr"
        };
    }

    evalCharCount = e => {
        this.setState({
          charCount: e.target.value.length,
        });
    };

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const { form } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                //DO SUBMIT WORK
                this.handleSendContact()
            }
        });
    };

    /**
     * Render name field
     *
     * @returns Form Item
     */
    private renderNameField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('fullName', {

                })(
                    <Input
                        autoComplete='name'
                        prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)',backgroundColor:"#F2F4F8" }} />}
                        placeholder='Alıcı Ad Soyad'
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render email field
     *
     * @returns Form Item
     */
    private renderEmailField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item>
                {form.getFieldDecorator('email', {
                    rules: [{
                        type: 'email',
                        message: 'Geçersiz e-posta adresi!',
                    }, {
                        required: true,
                        message: 'Lütfen e-posta adresinizi girin',
                    }],
                })(
                    <Input
                        autoComplete='email'
                        prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)',backgroundColor:"#F2F4F8" }} />}
                        placeholder='Alıcı e-posta adresi'
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render message field
     *
     * @returns Form Item
     */
    private renderMessageField(): JSX.Element {
        const { form } = this.props;

        return (
            <div>
                <Form.Item>
                    {form.getFieldDecorator('message', {
                        rules: [{
                            required: true,
                            message: 'Lütfen mesajınızı girin',
                        }],
                    })(
                        <TextArea
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical',backgroundColor:"#F2F4F8"}}
                            maxLength={4000}
                            placeholder='Mesajınızı yazınız...'
                            onChange = {this.evalCharCount}
                        />
                    )}
                </Form.Item>
                <text style={{marginTop:"-25px",float:'right'}}>Karakter Sayısı: {this.state.charCount}/4000</text>
            </div>
        );
    }

    /**
     * Render target email field
     *
     * @returns Form Item
     */
    private renderTargetEmailField(): JSX.Element {
        const { form } = this.props;

        return (
            <div>
                <Form.Item>
                    <Select defaultValue="Gönderici e-posta adresini seçiniz." onChange={(value) => this.setState({ mailSelect: value })}>
                        <Select.Option value="bilgi@verikovani.ssb.gov.tr">bilgi@verikovani.ssb.gov.tr</Select.Option>
                        <Select.Option value="destek@verikovani.ssb.gov.tr">destek@verikovani.ssb.gov.tr</Select.Option>
                    </Select>
                </Form.Item>
            </div>
        );
    }

    /**
     * Handles send contact
     *
     * @returns Form Item
     */
    private async handleSendContact(){
      const { form,setModalState } = this.props;
      form.getFieldValue('fullName')
      let contactRequest = await core.users.createContactMail(this.state.mailSelect,form.getFieldValue('email'),form.getFieldValue('fullName'),form.getFieldValue('message'))
      let response = contactRequest.status;
      if(response != "Success"){
          Modal.error({
              title: 'Hata!',
              content: response,
              onOk() {
                setModalState(true);
              },
          });
      }
      else{
          Modal.success({
              title: 'Bilgi!',
              content: contactRequest.message,
              onOk() {
                setModalState(false);
              },
          });
      }
    }

    /**
     * Render contact us from
     *
     * @returns Form within div
     */
    public render(): JSX.Element {

        return (
        <div className="approval-page">
            <Form onSubmit={this.handleSubmit} className='contact-us'>
{/*                     <Text className="cvat-title">Kullanıcıya Ulaş</Text>
*/}
                {this.renderTargetEmailField()}
                {this.renderNameField()}
                {this.renderEmailField()}
                {this.renderMessageField()}
                <br/>
                <Form.Item>
                    <Button
                        style={{backgroundColor:"#0F123F",opacity:"0.75"}}
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                    >
                        Gönder
                    </Button>
                </Form.Item>
            </Form>
        </div>
        );

    }
}

export default Form.create<FormComponentProps>()(ContactUsComponent);
