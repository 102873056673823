// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import Text from 'antd/lib/typography/Text';
import i18n from '../../i18n'
import { Select, Checkbox } from 'antd';
import Input from 'antd/lib/input';

import { TasksQuery } from 'reducers/interfaces';
import FeedbackComponent from 'components/feedback/feedback';
import TaskListContainer from 'containers/tasks-page/tasks-list';
import TopBar from './top-bar';
import EmptyListComponent from './empty-list';
import { TaskPageSelection, AnnotaTaskStatus, TaskType } from '../../../../cvat-core/src/enums';
import { Row, Col, Tabs } from 'antd';
import { Divider } from 'antd';
import cvatApp from 'components/cvat-app';

const { TabPane } = Tabs;

const { Option } = Select;

interface TasksPageProps {
    tasksFetching: boolean;
    gettingQuery: TasksQuery;
    numberOfTasks: number;
    numberOfVisibleTasks: number;
    numberOfHiddenTasks: number;
    onGetTasks: (gettingQuery: TasksQuery, tasksViewType: string, taskType: string, annotaTaskStatus: string, taskClassificationType: string, onlyExpired: string) => void;
    hideEmptyTasks: (hideEmpty: boolean) => void;
    user: any;
    annotaUser: any;
}
/**
 * 
 * @param gettingQuery TasksQuery which will be used generating Search Field
 * @returns Search Field String 
 */
function getSearchField(gettingQuery: TasksQuery): string {
    let searchString = '';
    for (const field of Object.keys(gettingQuery)) {
        if (gettingQuery[field] !== null && field !== 'page') {
            if (field === 'search') {
                return (gettingQuery[field] as any) as string;
            }

            // not constant condition
            // eslint-disable-next-line
            if (typeof (gettingQuery[field] === 'number')) {
                searchString += `${field}:${gettingQuery[field]} AND `;
            } else {
                searchString += `${field}:"${gettingQuery[field]}" AND `;
            }
        }
    }

    return searchString.slice(0, -5);
}
/**
 * 
 * @param previousQuery TasksQuery which will be used generating new query
 * @param searchString Search string
 * @returns updated TasksQuery
 */
function updateQuery(previousQuery: TasksQuery, searchString: string): TasksQuery {
    const params = new URLSearchParams(searchString);
    const query = { ...previousQuery };
    for (const field of Object.keys(query)) {
        if (params.has(field)) {
            const value = params.get(field);
            if (value) {
                if (field === 'id' || field === 'page') {
                    if (Number.isInteger(+value)) {
                        query[field] = +value;
                    }
                } else {
                    query[field] = value;
                }
            }
        } else if (field === 'page') {
            query[field] = 1;
        } else {
            query[field] = null;
        }
    }

    return query;
}

class TasksPageComponent extends React.PureComponent<TasksPageProps & RouteComponentProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            tasksViewType: TaskPageSelection.ALL,
            taskType: undefined,
            viewTypeUpdated: false,
            annotaTaskStatus: undefined,
            taskClassificationType: undefined,
            onlyExpired: undefined,
        }
        this.changeViewType = this.changeViewType.bind(this);
        this.filterTaskType = this.filterTaskType.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.filterAnnotaTaskStatus=this.filterAnnotaTaskStatus.bind(this);
        this.filterTaskClassificationType=this.filterTaskClassificationType.bind(this);
        this.filterTaskExpiredJob=this.filterTaskExpiredJob.bind(this);
    }
    /**
     * Handling resize by updating width and height 
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    public componentDidMount(): void {
        const {
            gettingQuery,
            location,
            onGetTasks,
        } = this.props;

        const query = updateQuery(gettingQuery, location.search);
        onGetTasks(query, TaskPageSelection.ALL, TaskType.ALL, AnnotaTaskStatus.ALL, "", 'false');
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }

    public componentDidUpdate(prevProps: TasksPageProps & RouteComponentProps): void {
        const {
            location,
            gettingQuery,
            onGetTasks,
        } = this.props;

        if (prevProps.location.search !== location.search || this.state.viewTypeUpdated) {
            // get new tasks if any query changes
            const query = updateQuery(gettingQuery, location.search);
            message.destroy();
            onGetTasks(query, this.state.tasksViewType, this.state.taskType, this.state.annotaTaskStatus, this.state.taskClassificationType, this.state.onlyExpired);
            this.setState({ viewTypeUpdated: false })
            return;
        }

        /*if (numberOfHiddenTasks) {
            message.destroy();
            message.info(
                <>
                    <Text>
                        Some tasks have not been showed because they do not have any data.
                    </Text>
                    <Button
                        type='link'
                        onClick={(): void => {
                            hideEmptyTasks(false);
                            message.destroy();
                        }}                <Dropdown overlay={TaskTypeMenu}>
                    <a className="ant-dropdown-link" onClick={this.changeTaskType} >
                    Task Type <DownOutlined />
                    </a>
                </Dropdown>
                    >
                        Show all
                    </Button>
                </>, 7,
            );
        }*/
    }
    /**
     * Handling Search Function according to value and updates URL
     * @param value string value 
     */
    private handleSearch = (value: string): void => {
        const {
            gettingQuery,
        } = this.props;

        const query = { ...gettingQuery };
        const search = value.replace(/\s+/g, ' ').replace(/\s*:+\s*/g, ':').trim();

        const fields = ['name', 'mode', 'owner', 'assignee', 'status', 'id'];
        for (const field of fields) {
            query[field] = null;
        }
        query.search = null;

        let specificRequest = false;
        for (const param of search.split(/[\s]+and[\s]+|[\s]+AND[\s]+/)) {
            if (param.includes(':')) {
                const [field, fieldValue] = param.split(':');
                if (fields.includes(field) && !!fieldValue) {
                    specificRequest = true;
                    if (field === 'id') {
                        if (Number.isInteger(+fieldValue)) {
                            query[field] = +fieldValue;
                        }
                    } else {
                        query[field] = fieldValue;
                    }
                }
            }
        }

        query.page = 1;
        if (!specificRequest && value) { // only id
            query.search = value;
        }

        this.updateURL(query);
    };

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }
    /**
     * modifying query object and updating url accordingly
     * @param page variable to be used for query
     */
    private handlePagination = (page: number): void => {
        const {
            gettingQuery,
        } = this.props;

        // modify query object
        const query = { ...gettingQuery };
        query.page = page;

        // update url according to new query object
        this.updateURL(query);
    };
    /**
     * Updating URL acc to gettingQuery
     * @param gettingQuery TasksQuery which will be used generating new query string
     */
    private updateURL(gettingQuery: TasksQuery): void {
        const { history } = this.props;
        let queryString = '?';
        for (const field of Object.keys(gettingQuery)) {
            if (gettingQuery[field] !== null) {
                queryString += `${field}=${gettingQuery[field]}&`;
            }
        }

        const oldQueryString = history.location.search;
        if (oldQueryString !== queryString) {
            history.push({
                search: queryString.slice(0, -1),
            });

            // force update if any changes
            this.forceUpdate();
        }
    }

    /**
     * rendering the tasks-page
     * @returns div
     */
    public render(): JSX.Element {
        const {
            tasksFetching,
            gettingQuery,
            numberOfVisibleTasks,
            user,
            history,
            annotaUser
        } = this.props;

        if (tasksFetching) {
            return (
                <Spin size='large' className='cvat-spinner' />
            );
        }
        const buttonStyle={
            color:"#F2F4F8",
            background: "#0F123F 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #B3B3B329",
            border: "1px solid #707070",
            borderRadius: "8px",
            opacity: "0.75",
        }

        let mobileView = false
        if(this.state.width / this.state.height   < 1.7){
            mobileView = true
        }

        let permissions = annotaUser[0].group_role_permissions
        let hasTaskCreatePermission = permissions.filter(object => object.permission_id__name == 'create_task').length > 0
        let is_expert = annotaUser[0].expert == 'expert'
        let span_buttons_bar = 10
        let span_button = 8
        if (is_expert){
            span_buttons_bar = 12
            span_button = 6
        }
        return (
            <div className='cvat-tasks-page'>
                <TopBar
                    user={this.props.user}
                    onSearch={this.handleSearch}
                    searchValue={getSearchField(gettingQuery)}
                    onTaskTypeChanged={this.filterTaskType.bind(this)}
                    taskType={this.state.taskType}
                />
                {!mobileView ?
                <div style={{paddingTop:'15px'}}>
                <Row type="flex" justify="space-around" gutter={[48,0]}>
                    <Col span={5}></Col>
                    <Col span={span_buttons_bar}>
                        <Row type="flex" justify="center">
                            <Col>
                                <Button data-tour="step-1" size="large" className="tasks-button"  style={this.state.tasksViewType=="1"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("1");}}>{i18n.t('tasks-page.allTasks')}</Button>
                            </Col>
                            <Col>
                                <Button data-tour="step-2" size="large" className="tasks-button"  style={this.state.tasksViewType=="2"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("2");}}>{i18n.t('tasks-page.myTasks')}</Button>
                            </Col>
                           {hasTaskCreatePermission && <Col>
                                <Button data-tour="step-shared" size="large" className="tasks-button"  style={this.state.tasksViewType=="4"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("4") }}>{i18n.t('tasks-page.sharedTasks')} </Button>
                            </Col> }
                            {is_expert &&
                            <Col>
                                <Button data-tour="step-approve" size="large" className="tasks-button"  style={this.state.tasksViewType=="5"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("5") }}>Doğrulama</Button>
                            </Col> }
                        </Row>
                    </Col>
                    <Col span={user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator'))?5:3.3}>
                        <Button
                            data-tour="step-create-task"
                            style={{ marginTop: "30px"}}
                            className="button-default-tasks-page"
                            hidden={!user.groups.includes('admin') && !hasTaskCreatePermission}
                            size='large'
                            onClick={
                                (): void => history.push('/tasks/create')
                            }
                            icon='plus'
                        >
                            {i18n.t('top-bar.createNewTask')}
                        </Button>
                    </Col>
                </Row>
                <Row type='flex' justify='space-around' align='bottom' >
                    <Col span={9} style={{padding:"15px 20px 5px 20px"}}>
                        <Col span={24}>
                            <Text>Filtrele:  </Text>
                            <Select data-tour="step-3" dropdownStyle={{background:"#F2F4F8"}} style={{width:"150px"}} className="task-page-selector" value={this.state.annotaTaskStatus} placeholder="Görev Durumu" onChange={this.filterAnnotaTaskStatus}>
                                <Option value="1">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                <Option value="2">İlerleme Altında</Option>
                                <Option value="3">Beklemede</Option>
                                <Option value="4">Tamamlanmış</Option>
                            </Select> &nbsp;

                            <Select data-tour="step-4" dropdownStyle={{background:"#F2F4F8"}} style={{width:"150px"}} className="task-page-selector" value={this.state.taskType} placeholder="Görev Türleri" onChange={this.filterTaskType}>
                                <Option value="1">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                <Option value="2">{i18n.t('tasks-page.taskTypesTextual')}</Option>
                                {/* <Option value="3">{i18n.t('tasks-page.taskTypesVisual')}</Option> */}
                                <Option value="4">{i18n.t('tasks-page.taskTypesPcd')}</Option>
                                <Option value="5">{i18n.t('tasks-page.taskTypesVideo')}</Option>
                                <Option value="6">{i18n.t('tasks-page.taskTypesImage')}</Option>

                            </Select> &nbsp;

                            {
                                this.state.taskType == 2 &&
                                <Select dropdownStyle={{background:"#F2F4F8"}} style={{width:"160px"}} className="task-page-selector" value={this.state.taskClassificationType} placeholder="Görev Kategorisi" onChange={this.filterTaskClassificationType}>
                                    <Option value="ALL">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                    <Option value="NLP">Doğal Dil İşleme</Option>
                                    <Option value="NER">Varlık İsim Tanıma</Option>
                                    <Option value="Classification">Doküman Sınıflandırma</Option>
                                    <Option value="SentimentAnalysis">Duygu Analizi</Option>
                                    <Option value="SequenceToSequence">Sekans Öğrenme</Option>
                                    <Option value="Other">Diğer</Option>
                                </Select>
                            }
                            {
                                (this.state.taskType == 5 || this.state.taskType == 6)  &&
                                <Select dropdownStyle={{background:"#F2F4F8"}} style={{width:"160px"}} className="task-page-selector" value={this.state.taskClassificationType} placeholder="Görev Kategorisi" onChange={this.filterTaskClassificationType}>
                                    <Option value="ALL">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                    <Option value="ObjectDetection">Nesne Tespiti</Option>
                                    <Option value="ObjectTracking">Nesne Takibi</Option>
                                    <Option value="EventExtraction">Olay Çıkarımı</Option>
                                    <Option value="Segmentation">Anlamsal Bölütleme</Option>
                                    <Option value="Other">Diğer</Option>
                                </Select>
                            }

                        </Col>

                    </Col>
                    <Col span={5} style={{padding:"15px 20px 5px 20px"}}>

                        </Col>
                    <Col span={7} style={{padding:"5px 20px 5px 20px", display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                        <Col hidden={!user.groups.includes('admin')} span={10} style={{paddingTop:"10px",display:"flex"}}>
                            <Checkbox onChange={this.filterTaskExpiredJob} checked={(this.state.onlyExpired === 'true')}>Gecikmiş uzman işleri</Checkbox>
                        </Col>
                        <Col data-tour="step-5" span={user.groups.includes('admin') ? 14:24} style={{paddingTop:"10px",display:"flex"}}>

                            <Input.Search
                                className="task-page-selector"
                                defaultValue={getSearchField(gettingQuery)}
                                onSearch={this.handleSearch}
                                size='default'
                                placeholder={i18n.t('keywords.search')}
                            />
                        </Col>
                    </Col>

                </Row>
                </div> :
                <div >
                <Row type="flex" justify="space-around" gutter={[48,16]}>
                    <Col span={24}>
                    <Row type="flex" justify="center" align="middle" gutter={[0,2]}>
                        <Col span={24} align='center'>
                            <Button size="small" className="tasks-button"  style={this.state.tasksViewType=="1"?{...buttonStyle}:{}} onClick={(e) => { this.changeViewType("1");}}>{i18n.t('tasks-page.allTasks')}</Button>
                        </Col>
                        <Col span={24} align='center'>
                            <Button size="small" className="tasks-button"  style={this.state.tasksViewType=="2"?{...buttonStyle}:{}} onClick={(e) => { this.changeViewType("2");}}>{i18n.t('tasks-page.myTasks')}</Button>
                        </Col>
                        <Col span={24} align='center'>
                            <Button size="small" className="tasks-button"  style={this.state.tasksViewType=="4"?{...buttonStyle}:{}} onClick={(e) => { this.changeViewType("4") }}>{i18n.t('tasks-page.sharedTasks')} </Button>
                        </Col>
                    </Row>
                    </Col>

                </Row>
                <Row type='flex' justify='start' align='middle' gutter={[48,5]}>
                    <Col span={24}>
                        <Col span={24} align='middle'>
                            <Text>Filtrele:  </Text>
                            <Select dropdownStyle={{background:"#F2F4F8"}} style={{width:"150px"}} className="task-page-selector" value={this.state.annotaTaskStatus}  placeholder="Görev Durumu" onChange={this.filterAnnotaTaskStatus}>
                                <Option value="1">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                <Option value="2">İlerleme Altında</Option>
                                <Option value="3">Beklemede</Option>
                                <Option value="4">Tamamlanmış</Option>
                            </Select> &nbsp;
                            <Select dropdownStyle={{background:"#F2F4F8"}}style={{width:"150px"}} className="task-page-selector" value={this.state.taskType} placeholder="Görev Türleri" onChange={this.filterTaskType}>
                                <Option value="1">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                <Option value="2">{i18n.t('tasks-page.taskTypesTextual')}</Option>
                                {/* <Option value="3">{i18n.t('tasks-page.taskTypesVisual')}</Option> */}
                                <Option value="4">{i18n.t('tasks-page.taskTypesPcd')}</Option>
                                <Option value="5">{i18n.t('tasks-page.taskTypesVideo')}</Option>
                                <Option value="6">{i18n.t('tasks-page.taskTypesImage')}</Option>
                            </Select>
                            {
                                this.state.taskType == 2 &&
                                <Select dropdownStyle={{background:"#F2F4F8"}} style={{width:"160px"}} className="task-page-selector" value={this.state.taskClassificationType} placeholder="Görev Kategorisi" onChange={this.filterTaskClassificationType}>
                                    <Option value="ALL">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                    <Option value="NLP">Doğal Dil İşleme</Option>
                                    <Option value="NER">Varlık İsim Tanıma</Option>
                                    <Option value="Classification">Doküman Sınıflandırma</Option>
                                    <Option value="SentimentAnalysis">Duygu Analizi</Option>
                                    <Option value="SequenceToSequence">Sekans Öğrenme</Option>
                                    <Option value="Other">Diğer</Option>
                                </Select>
                            }
                            {
                                this.state.taskType == 3 &&
                                <Select dropdownStyle={{background:"#F2F4F8"}} style={{width:"160px"}} className="task-page-selector" value={this.state.taskClassificationType} placeholder="Görev Kategorisi" onChange={this.filterTaskClassificationType}>
                                    <Option value="ALL">{i18n.t('tasks-page.taskTypesAll')}</Option>
                                    <Option value="ObjectDetection">Nesne Tespiti</Option>
                                    <Option value="ObjectTracking">Nesne Takibi</Option>
                                    <Option value="EventExtraction">Olay Çıkarımı</Option>
                                    <Option value="Segmentation">Anlamsal Bölütleme</Option>
                                    <Option value="Other">Diğer</Option>
                                </Select>
                            }
                        </Col>
                    </Col>
                    <Col span={16} align='middle' style={{marginLeft:'17%'}}>
                        <Input.Search
                            className="task-page-selector"
                            defaultValue={getSearchField(gettingQuery)}
                            onSearch={this.handleSearch}
                            size='large'
                            placeholder={i18n.t('keywords.search')}
                        />
                    </Col>
                </Row>
                </div>

                }

                    {numberOfVisibleTasks
                        ? (
                            <TaskListContainer
                                onSwitchPage={this.handlePagination}
                            />
                        ) : <EmptyListComponent canCreateNewTask = {!user.groups.includes('admin') && !hasTaskCreatePermission}/>
                    }
                    {/*<FeedbackComponent />*/}
                </div>
        );
    }
    /**
     * Changing the View Type by setting the State
     * @param key number for using switch-case. 
     */
    public changeViewType(key: string) {
        this.forceUpdate();
        this.setState({
            viewTypeUpdated: true,
        })
        switch (key) {
            case "1":
                this.setState({ tasksViewType: TaskPageSelection.ALL })
                break;
            case "2":
                this.setState({ tasksViewType: TaskPageSelection.MYTASKS })
                break;
            case "3":
                this.setState({ tasksViewType: TaskPageSelection.COMPLETED })
                break;
            case "4":
                this.setState({ tasksViewType: TaskPageSelection.SHARED })
                break;
            case "5":
                this.setState({ tasksViewType: TaskPageSelection.EVALUATION })
                break;
        }
        this.handlePagination(1);
    }
    /**
     * Filtering Task Types 
     * @param taskTypeIn task Type İnput
     */
    public filterTaskType(taskTypeIn: string) {
        this.forceUpdate();
        this.setState({
            viewTypeUpdated: true,
            taskType: taskTypeIn,
            taskClassificationType: undefined
        })
        this.handlePagination(1);
    }
    /**
     * Filtering the Annota Task Status
     * @param annotaTaskStatusIn annotaTaskStatus filter value
     */
    public filterAnnotaTaskStatus(annotaTaskStatusIn: string) {
        this.forceUpdate();
        this.setState({
            viewTypeUpdated: true,
            annotaTaskStatus: annotaTaskStatusIn,
        })
        this.handlePagination(1);
    }
    /**
     * Filtering the Classification Type
     * @param taskClassificationTypeIn taskClassificationType value
     */
    public filterTaskClassificationType(taskClassificationTypeIn: string) {
        this.forceUpdate();
        this.setState({
            viewTypeUpdated: true,
            taskClassificationType: taskClassificationTypeIn,
        })
        this.handlePagination(1);
    }
    /**
     * Filtering Task Expired Jobs 
     * @param shouldFilter object to be used on decision whether or not filter
     */
    public filterTaskExpiredJob(shouldFilter) {
        this.forceUpdate();
        console.log(shouldFilter.target.checked)
        if(shouldFilter.target.checked === true){
            console.log("filter")
            this.setState({
                viewTypeUpdated: true,
                onlyExpired: 'true',
            })
        }
        else {
            console.log("no filter")
            this.setState({
                viewTypeUpdated: true,
                onlyExpired: 'false',
            })
        }
        this.handlePagination(1);
    }
}

export default withRouter(TasksPageComponent);