import '../styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Dropdown, Menu, Collapse, Progress,Input,Divider, Descriptions, Card, Row, Col, Popconfirm, Button, Icon, Modal, Spin } from 'antd';
import { Line, Pie } from '@ant-design/charts';
import i18n from '../i18n';
import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import getCore from '../cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type SystemResourcesProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class_component System Resources class Component for /stats route
 */
class SystemResourcesComponent extends React.PureComponent<SystemResourcesProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {

            cpuPercent:0,
            ramPercent:0,
            diskPercent:0,
            data:[],
            cpuData: [],
            memoryData: [],
            diskData: [],
            isDownloadPopVisible: false,
            exportFormat: ""
        }
        this.getSystemResources = this.getSystemResources.bind(this);
    }

    /**
     * @description menu template for export format dropdown
     */
    menu = (
        <Menu>
            <Menu.Item key="csv" onClick={() => {
                            this.setState({ isDownloadPopVisible: true });
                            this.setState({ exportFormat: "csv" });
                        }}>

                    csv

            </Menu.Item>
            <Menu.Item key="xlsx" onClick={() => {
                            this.setState({ isDownloadPopVisible: true });
                            this.setState({ exportFormat: "xlsx" });
                        }}>

                    xlsx

            </Menu.Item>
            <Menu.Item key="docx" onClick={() => {
                            this.setState({ isDownloadPopVisible: true });
                            this.setState({ exportFormat: "docx" });
                        }}>

                    docx

            </Menu.Item>
            <Menu.Item key="pdf" onClick={() => {
                            this.setState({ isDownloadPopVisible: true });
                            this.setState({ exportFormat: "pdf" });
                        }}>

                    pdf

            </Menu.Item>
        </Menu>
    );

    public componentDidMount(): void {
        this.getSystemResources();
    }

    /**
     * @description get system resources api proxy call, set stats on state
     */
    public getSystemResources() {

        let promise = cvat.server.getSystemResources();
            promise.then(
                stats => {
                    console.log(stats)
                    this.setState({
                        cpuPercent:stats.cpuPercent,
                        diskPercent: stats.disk_percent,
                        diskAvail: stats.disk_available,
                        diskTotal: stats.disk_total,
                        diskUsage: stats.disk_usage,
                        memoryAvail: stats.memory_available,
                        memoryTotal: stats.memory_total,
                        memoryUsage: stats.memory_usage,
                        ramPercent:stats.memory_percent,
                        cpuData: stats.cpu_data,
                        memoryData: stats.memory_data,
                        diskData: stats.disk_data
                    })

            }
        )
    }

    /**
     * @async
     * @description download system resources api call with export format from state, handle response with success and error Modals
     */
    async downloadSystemResources() {

        let response;
        let loading = true;
        Modal.info({
            title: "Veri indiriliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });

        if(this.state.exportFormat == ""){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri formatı algılanamadı!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        } else {
            response = await cvat.server.downloadSystemResources(this.state.exportFormat);
        }

        if (response.status === 200) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
        this.setState({exportFormat: ""})
    }

    /**
     *
     * @returns CPU usage chart template
     */
    renderCpuUsage(){
        const config = {
            data: this.state.cpuData,
            height: 400,
            xField: 'key',
            yField: 'value',
            point: {
              size: 4,
              shape: 'diamond',
            },
            meta: {
              key: { alias: 'Anahtar' },
              value: { alias: 'Değer' },
            },
        };
        return(
            <Card style={{borderRadius: "8px",minHeight:"737px"}} title="İşlemci" bodyStyle={{minHeight:"633px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}>
                <Line  {...config}/>
                <Text strong>{i18n.t("system-resources-page.cpuUsage")}</Text>
                <Col span={24}>
                <Progress type="dashboard" percent={this.state.cpuPercent}></Progress>
                </Col>
            </Card>
        )
    }

    /**
     *
     * @returns Memory usage chart template
     */
    renderMemoryUsage(){
        const config = {
            data: this.state.memoryData,
            height: 400,
            xField: 'key',
            yField: 'value',
            point: {
              size: 4,
              shape: 'diamond',
            },
            meta: {
                key: { alias: 'Anahtar' },
                value: { alias: 'Değer' },
            },
        };
        return(
            <Card style={{borderRadius: "8px"}} title="Bellek" bodyStyle={{minHeight:"633px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}>
                <Line  {...config}/>
                <Text strong>{i18n.t("system-resources-page.ramUsage")}</Text>
                <Row style={{marginTop:"12px"}}>
                    <Col span={12}>
                    <Progress type="dashboard" percent={this.state.ramPercent}></Progress>
                    <br/>
                    <Text style={{fontSize:"12px"}}>{i18n.t("system-resources-page.totalRam")}: {this.state.memoryTotal} GB</Text>
                    </Col>
                    <Col span={12}>
                    {this.renderRamPie()}
                    </Col>
                </Row>
            </Card>
        )
    }

    /**
     *
     * @returns Disk usage chart template
     */
    renderDiskUsage(){
        const config = {
            data: this.state.diskData,
            height: 400,
            xField: 'key',
            yField: 'value',
            point: {
              size: 4,
              shape: 'diamond',
            },
            meta: {
                key: { alias: 'Anahtar' },
                value: { alias: 'Değer' },
            },
        };
        return(
            <Card style={{borderRadius: "8px"}} title="Saklama Alanı" bodyStyle={{minHeight:"633px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}>
                <Line  {...config}/>
                <Text strong>{i18n.t("system-resources-page.diskUsage")}</Text>
                <Row style={{marginTop:"12px"}}>
                    <Col span={12}>
                    <Progress type="dashboard" percent={this.state.diskPercent}></Progress>
                    <br/>
                    <Text style={{fontSize:"12px"}}>{i18n.t("system-resources-page.totalDisk")}: {this.state.diskTotal} GB</Text>
                    </Col>
                    <Col span={12}>
                    {this.renderDiskPie()}
                    </Col>
                </Row>
            </Card>
        )
    }

    /**
     *
     * @returns Disk usage Pie template
     */
    renderDiskPie(){
        var data = [
            {
              type: i18n.t("system-resources-page.availDisk"),
              value: this.state.diskAvail,
            },
            {
              type: i18n.t("system-resources-page.ramInUse"),
              value: this.state.diskUsage,
            },

          ];
        var config = {
            legend: {layout: 'vertical',
            position: 'bottom'},
            //appendPadding: 10,
            data: data,
            angleField: 'value',
            colorField: 'type',
            color: ['#86DFEC', '#EC868D'],
            radius: 0.9,
            label: {
            type: 'inner',
            offset: '-30%',
            content: function content(_ref) {
                var value = _ref.value;
                return ''.concat(value.toFixed(0), 'GB');
            },
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
            },
            interactions: [{ type: 'element-active' }],
        };
          return <Pie width={166} height={200} {...config}/>;
    }

    /**
     *
     * @returns Ram usage Pie template
     */
    renderRamPie(){
        var data = [
            {
              type: i18n.t("system-resources-page.availRam"),
              value: this.state.memoryAvail,
            },
            {
              type: i18n.t("system-resources-page.ramInUse"),
              value: this.state.memoryUsage,
            },

          ];
        var config = {
            legend: {layout: 'vertical',
            position: 'bottom'},
            //appendPadding: 10,
            data: data,
            angleField: 'value',
            colorField: 'type',
            color: ['#86DFEC', '#EC868D'],
            radius: 0.9,
            label: {
                type: 'inner',
                offset: '-30%',
                content: function content(_ref) {
                    var value = _ref.value;
                    return ''.concat(value.toFixed(0), 'GB');
                },
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            interactions: [{ type: 'element-active' }],
        };
          return <Pie width={166} height={200} {...config}/>;
    }

    /**
     *
     * @returns system resources page template by using class functions
     */
    render() {
        return (
            <div className={window.location.href.includes("stats")?"approval-page":"system-resources"}>
                <Text style={!window.location.href.includes("stats")?{fontSize:"16px",marginBottom:"12px",color:"#5A5C69"}:{}} className={window.location.href.includes("stats")?"cvat-title":"cvat-text-color"}>{i18n.t("header.systemResources")}</Text>
                <Popconfirm
                    title= "Veriyi indirmek istediğinize emin misiniz?"
                    cancelText={i18n.t('keywords.no')}
                    okText={i18n.t('keywords.yes')}
                    visible={this.state.isDownloadPopVisible}
                    onConfirm={(e) => {
                        this.setState({
                            isDownloadPopVisible: false
                        })
                        this.downloadSystemResources()
                    }}
                    onCancel={() => {
                        this.setState({
                            isDownloadPopVisible: false
                        })
                    }}
                >
                    <Dropdown overlay={this.menu} placement="bottomCenter" >
                        <Button>
                            <Icon type="file-excel"/>
                            {i18n.t('dashboard-page.download')}
                        </Button>
                    </Dropdown>
                    {/* <Button style={{marginLeft:"3px"}} className="button-default-groups-page" type="primary" icon="download" size="small" ></Button> */}
                </Popconfirm>
                <div style={{paddingTop:"15px"}}>
                <Row type="flex" justify="space-between" gutter={[32,32]}>
                    <Col span={7}>
                        {this.renderCpuUsage()}
                    </Col>
                    <Col span={7}>
                        {this.renderMemoryUsage()}
                    </Col>
                    <Col span={7}>
                        {this.renderDiskUsage()}
                    </Col>
                </Row>
                </div>


{/*

                <Divider></Divider>

                <Descriptions title={i18n.t("system-resources-page.cpu")} layout="vertical" bordered column={4} >
                    <Descriptions.Item label={i18n.t("system-resources-page.cpuUsage")}>
                        <Progress type="dashboard" percent={this.state.cpuPercent}></Progress>
                    </Descriptions.Item>
                </Descriptions>
 */}
                {/* <Descriptions title={i18n.t("system-resources-page.ram")} layout="vertical" bordered column={4} >
                    <Descriptions.Item label={i18n.t("system-resources-page.ramUsage")}>
                        <Progress type="dashboard" percent={this.state.ramPercent}></Progress>

                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.totalRam")}>{this.state.memoryTotal} GB</Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.availRam")}>{this.state.memoryAvail} GB</Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.ramInUse")}>{this.state.memoryUsage} GB</Descriptions.Item>
                </Descriptions> */}
{/*
                <Descriptions title={i18n.t("system-resources-page.disk")} layout="vertical" bordered column={4}>
                    <Descriptions.Item label={i18n.t("system-resources-page.diskUsage")}>
                        <Progress type="dashboard" percent={this.state.diskPercent}></Progress>
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.totalDisk")}>{this.state.diskTotal} GB</Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.availDisk")}>{this.state.diskAvail} GB</Descriptions.Item>
                    <Descriptions.Item label={i18n.t("system-resources-page.diskInUse")}>{this.state.diskUsage} GB</Descriptions.Item>

                </Descriptions> */}

            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SystemResourcesComponent));
