const annotationPageSteps = [
    {
        selector: '[data-tour="step-topbar-left"]',
        content: "Etiketleri silme, kaydetme, yaptığınız işlemi geri/ileri alma düğmeleri, tam ekran modu ve kısayolları görüntüleme özellikleri bu alanda yer almaktadır."
    },
    {
        selector: '[data-tour="step-topbar-player-nav"]',
        content: "Görevde bu alandaki oynatıcı düğmeleri ile hareket edebilirsiniz. Sağ tarafta bulunan kaydırma düğmesi ile de görev üzerinde hareket edebilir ve kaçıncı çerçevede olduğunuzu takip edebilirsiniz."
    },
    {
        selector: '[data-tour="step-topbar-right"]',
        content: "Görevde çoklu nesne takibi başlatma özelliği ve görevi tamamlama düğmeleri burada bulunmaktadır."
    },
    {
        selector: '[data-tour="step-canvas"]',
        content: "Görevi üzerinde etiketleme yapacağınız alan burasıdır."
    },
    {
        selector: '[data-tour="step-sider"]',
        content: "Bu alandan etiketleme yapacağınız şekli seçip etiketlemeye başlayabilir, üzerinde çalıştığınıx imgeyi döndürme, ekrana oturtma gibi özellikleri kullanabilirsiniz. Aşağıda bulunan mini bar düğmesi ile görev yönerge ve istatistiklerine ulaşabilirsiniz."
    },
    {
        selector: '[data-tour="step-objects-sider"]',
        content: "Bulunduğunuz çerçeve üzerinde yaptığınız etiketler bu alanda listelenir."
    },
  ];

  export default annotationPageSteps;