// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    Modal,
    Popconfirm
} from 'antd';
import consts from 'consts';
const {AVATAR_MAP} = consts;
import getCore from 'cvat-core-wrapper';
import UserSelector from './user-selector';
import Title from 'antd/lib/typography/Title';
import { LateJobIcon, EvaluationJobIcon, EvaluationJobIcon1 } from 'icons';

const core = getCore();

const baseURL = core.config.backendAPI.slice(0, -7);

interface Props {
    taskInstance: any;
    registeredUsers: any[];
    onJobUpdate(jobInstance: any): void;
    annotaUser: any,
    user: any,
    taskPath: any,
    annotaTask: any
}

/**
 *
 * @param props
 * @returns Job List Component for individual task view for /task
 */
function JobListComponent(props: Props & RouteComponentProps): JSX.Element {
    const {
        taskInstance,
        registeredUsers,
        onJobUpdate,
        annotaUser,
        taskPath,
        user,
        annotaTask,
        history: {
            push,
        },
    } = props;
    const { t } = useTranslation();

    let { jobs, id: taskId, owner } = taskInstance;

    // let pcdCheck = String(taskPath).substring(String(taskPath).length-4,String(taskPath).length)
    // let pcdHref;
    // let protocol = baseURL.substring(0,5)
    // if(pcdCheck == ".pcd"){
    //     let pointCloudFileName = taskPath.split("/").pop()
    //     if(protocol == "https")
    //         pcdHref = baseURL.substring(0,baseURL.length-4) + "8446" + "/edit/%2F" + taskInstance.id  +"%2Fraw%2F" + String(pointCloudFileName)
    //     else
    //         pcdHref = baseURL.substring(0,baseURL.length-4) + "3006" + "/edit/%2F" + taskInstance.id  +"%2Fraw%2F" + String(pointCloudFileName)
    // }


    if(props.user.isSuperuser == false && annotaUser[0].id.id != owner.id){
        jobs = jobs.filter(object => object.assignee != null && object.assignee.id == annotaUser[0].id.id)
    }

    let doccanoPath = ''
    //cancel redirect for now
    /*if(Number(annotaTask.doccanoProjectId) != 0){
        doccanoPath = core.config.doccanoUIRedirectURL + "/projects/" + annotaTask.doccanoProjectId + '/' + annotaTask.doccanoProjectType
    }*/

    /**
     * @description construct columns to render job list for given task
     */
    const columns = [{
        title: t('keywords.job'),
        dataIndex: 'job',
        key: 'job',
        render: (jobInstance: any): JSX.Element => (
            (doccanoPath == '') ? (
            // (pcdCheck != ".pcd" ) ?
            <div>
                <div  onAuxClick={(e):void=>{
                    if(props.user.isSuperuser == false && annotaUser[0].id.id != owner.id  && jobInstance.status === 'completed') {

                            Modal.error({
                                title: "Tamamlandı durumundaki işi görüntüleyemezsiniz.",
                                onOk : () => {
                                    Modal.destroyAll();
                                },
                            });
                    }
                }} onClick={(e: React.MouseEvent): void => {
                    if(props.user.isSuperuser == false && annotaUser[0].id.id != owner.id  && jobInstance.status === 'completed') {

                            Modal.error({
                                title: "Tamamlandı durumundaki işi görüntüleyemezsiniz.",
                                onOk : () => {
                                    Modal.destroyAll();
                                },
                            });
                    }}}
                >
                    {user.groups.includes("annotator")&&<Avatar src={AVATAR_MAP.get(user.avatarId)}></Avatar>}

                    <Button
                        id="link"
                        type='link'
                        disabled={jobInstance.status=='completed'&&props.user.isSuperuser==false}
                        onClick={(e: React.MouseEvent): void => {
                            if(props.user.isSuperuser == false && annotaUser[0].id.id != owner.id  && jobInstance.status === 'completed') {
                                e.preventDefault();
                                Modal.error({
                                    title: "Tamamlandı durumundaki işi görüntüleyemezsiniz.",
                                    onOk : () => {
                                        Modal.destroyAll();
                                    },
                                });
                            }
                            else {
                                e.preventDefault();
                                push(`/tasks/${taskId}/jobs/${jobInstance.id}`);
                            }
                        }}
                        href={`/tasks/${taskId}/jobs/${jobInstance.id}`}
                    >
                        {t('keywords.job') + `#${jobInstance.id}`}
                    </Button>
                </div>
                {/*|
                <Button id="link" type='link' href={`${baseURL}/?id=${id}`}>{t('task-page.oldUI')}</Button>*/}
            </div>
            // : <a href={String(pcdHref)}>{ t('keywords.job') + `#${id}` }</a>
            ) : <a href={String(doccanoPath)}>{ t('keywords.job') + `#${id}` }</a>
        ),
    }, {
        dataIndex: 'job',
        key: 'job',
        render: (jobInstance: any): JSX.Element => {

            return (<>
                {jobInstance.expertJob ? <Tooltip title="Etiket Doğrulama İşi">
                <Icon component={EvaluationJobIcon1} style={{transform:'scale(0.8)'}}/></Tooltip>  : <></>}
            {jobInstance.notified ? <Tooltip title="Süresi Geçmiş İş">
                <Icon component={LateJobIcon} style={{marginLeft:'5%', transform:'scale(0.8)'}}/></Tooltip>  : <></>}
            </>
            );
        }
    }, {
        title: t('keywords.frames'),
        dataIndex: 'frames',
        key: 'frames',
        className: 'cvat-text-color',
        render:(frames:string)=>{
            return(<Text>Sayfalar: {frames}</Text>)
        }
    }, {
        title: t('task-page.startedOn'),
        dataIndex: 'started',
        key: 'started',
        className: 'cvat-text-color',
    }, {
        title: t('task-page.duration'),
        dataIndex: 'duration',
        key: 'duration',
        className: 'cvat-text-color',
    }, {
        title: t('keywords.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status: string): JSX.Element => {
            let progressColor = null;
            let statusLabel = null;
            if (status === 'completed') {
                progressColor = 'cvat-job-completed-color';
                statusLabel = 'Tamamlandı'
            } else if (status === 'validation') {
                progressColor = 'cvat-job-validation-color';
                statusLabel = 'Doğrulama'
            } else {
                progressColor = 'cvat-job-annotation-color';
                statusLabel = 'İlerleme Altında'
            }

            return (
                <Text strong className={progressColor}>{ statusLabel }</Text>
            );
        },
    }, {
        title: t('keywords.assignee'),
        dataIndex: 'assignee',
        key: 'assignee',
        render: (jobInstance: any): JSX.Element => {
            const assignee = jobInstance.assignee ? jobInstance.assignee.username : null;

            if(props.user.isSuperuser == true){
                return (
                    <UserSelector
                        users={registeredUsers}
                        value={assignee}
                        onChange={(value: string): void => {
                            let [userInstance] = [...registeredUsers]
                                .filter((user: any) => user.username === value);

                            if (userInstance === undefined) {
                                userInstance = null;
                            }

                            // eslint-disable-next-line
                            // jobInstance.assignee = userInstance;
                            // onJobUpdate(jobInstance);
                        }}
                        onJobUpdate = {onJobUpdate}
                        jobInstance = {jobInstance}
                    />
                );
            }else{
                return (
                    <Text strong>{assignee}</Text>
                );
            }
        }
    },  {
            dataIndex: 'job',
            key: 'job',
            render: (jobInstance: any): JSX.Element => {

                if(props.user.isSuperuser == true){
                    return (
                        <Popconfirm
                          title="Bu işi silmek istediğinizden emin misiniz?"
                          cancelText={t('keywords.no')}
                          okText={t('keywords.yes')}
                          onConfirm={
                            async (): Promise<void> => {
                                let result = await core.jobs.deleteJob(jobInstance.id)
                                if(result.data.status == t("response-messages.success")){
                                    Modal.success({
                                        title: 'Bilgi',
                                        content: "İş başarıyla silindi!",
                                        okText: 'Tamam',
                                        onOk() {
                                            window.location.reload()
                                        },
                                    });
                                }else{
                                    Modal.error({
                                        title: 'Hata',
                                        content: result.data.status,
                                    });
                                }
                            }
                          }
                        >
                            {/* <Button className="button-default-jobs-table">
                                Kaldır
                            </Button> */}
                            <Icon style={{color:"#001529",fontSize:"large"}}type="delete"></Icon>
                      </Popconfirm>

                    );
                }else{
                    return (
                        <div></div>
                    );
                }
            }
        }

    ];

    let completed = 0;

    /**
     * @description construct data source for JSX Template of Job List
     */
    const data = jobs.reduce((acc: any[], job: any) => {
        if (job.status === 'completed') {
            completed++;
        }

        // const created = moment(props.taskInstance.createdDate).locale('tr');

        acc.push({
            key: job.id,
            job: job,
            frames: `${job.startFrame}-${job.stopFrame}`,
            status: `${job.status}`,
            started: `${moment(job.assignedAt).locale('tr').format('Do MMMM YYYY HH:mm')}`,
            duration: `${moment.duration(moment(moment.now()).diff(moment(job.assignedAt).locale('tr'))).locale('tr').humanize()}`,
            assignee: job,
            expert_job: job.expertJob,
            notified: job.notified,
            created_at: job.createdAt,
            assigned_at: job.assignedAt
        });

        return acc;
    }, []);
    /**
     * @description returns Job List Table and list functionalities template
     */
    return (
        <div data-tour="step-jobs-list" style={{width:"100%"}} >
            <div style={{width:"100%" ,marginTop:"2.5%"}}>
                <Row type='flex' justify='space-between' align='middle'>
                    <Col span={24}>
                        <div className="title-component">
                            <Title
                                level={4}
                                 className='cvat-text-color'
                                style={{marginTop:"1%",marginLeft:"2%"}}
                            >
                                {t('keywords.jobs')}
                                <Tooltip trigger='click' title={t('messages.copiedToClipboard')}>
                                    <Button
                                        type='link'
                                        style={{color:"#6C63FF"}}
                                        onClick={(): void => {
                                            let serialized = '';
                                            const [latestJob] = [...taskInstance.jobs].reverse();
                                            for (const job of taskInstance.jobs) {
                                                serialized += t('keywords.job') + `#${job.id}`.padEnd(`${latestJob.id}`.length + 6, ' ');
                                                serialized += `: ${baseURL}/?id=${job.id}`
                                                    .padEnd(`${latestJob.id}`.length + baseURL.length + 8, ' ');
                                                serialized += `: [${job.startFrame}-${job.stopFrame}]`
                                                    .padEnd(`${latestJob.startFrame}${latestJob.stopFrame}`.length + 5, ' ');

                                                if (job.assignee) {
                                                    serialized += '\t ' + t('task-page.assignedTo',{user:job.assignee.username});
                                                }
                                                serialized += '\n';
                                            }
                                            copy(serialized);
                                        }}
                                    >
                                        <Icon twoToneColor="#6C63FF" type='copy' theme='twoTone' />
                                        {t('keywords.copy')}
                                    </Button>
                                </Tooltip>
                                <Text type="secondary" style={{fontSize:"15px",float:"right",marginRight:"2%"}}>
                                    {t('task-page.jobCompletion', {completed: completed, started: data.length})}
                                </Text>
                            </Title>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='cvat-task-job-list' style={{marginTop:"-2px"}}>

                <Table
                    className='cvat-task-jobs-table'
                    columns={columns}
                    dataSource={data}
                    rowClassName={(record,index)=> record.notified ? "table-row-jobs" : "table-row-jobs"}
                />
            </div>
        </div>
    );
}

export default withRouter(JobListComponent);
