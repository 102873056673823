const tasksPageExpertProviderSteps = [
    {
            selector: '[data-tour="step-1"]',
            content: "Sistemdeki gizli olmayan bütün görevler bu sekmede listelenir. "
        },
        {
            selector: '[data-tour="step-2"]',
            content: "Üzerinizde işi bulunan görevler bu sekmede listelenir. "
        },
        {
            selector: '[data-tour="step-shared"]',
            content: "Başka veri sağlayıcılarıyla paylaştığınız görevler bu sekmede listelenir. "
        },
        {
            selector: '[data-tour="step-approve"]',
            content: "Diğer veri sağlayıcılarının doğrulanacağı görevler bu sekmede listelenir. "
        },
        {
            selector: '[data-tour="step-3"]',
            content: "Görevleri görev durumuna gore buradan filtreleyebilirsiniz. "
        },
        {
            selector: '[data-tour="step-4"]',
            content: "Görevleri metinsel veya görsel görevler olarak buradan filtreleyebilirsiniz. "
        },
        {
            selector: '[data-tour="step-create-task"]',
            content: "Bu düğmeye basarak görev oluşturma sayfasına gidebilirsiniz. "
        },
        {
            selector: '[data-tour="step-5"]',
            content: "Görevleri buraya yazacağınız bir anahtar kelime ile sorgulayabilirsiniz. "
        }
      ];

      export default tasksPageExpertProviderSteps;