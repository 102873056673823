/*
* Copyright (C) 2019 Intel Corporation
* SPDX-License-Identifier: MIT
*/

(() => {
    /**
        * Class representing a user
        * @memberof module:API.cvat.classes
        * @hideconstructor
    */
    class AnnotaUser {
        constructor(initialData) {
            const data = {
                id: null,
                level: null,
                state: null,
                expert: null,
                isBlocked: null,
                phoneNumber: null,
                dateOfBirth: null,
                computerExperience: null,
                address: null,
                postCode: null,
                town: null,
                city: null,
                isWorking: null,
                workPlace: null,
                education: null,
                photoFile: null,
                certificates: null,
                programmingLanguages: null,
                aiAndAnnotationExperience: null,
                otherExperience: null,
                canLogin: null,
                iban: null,
                payment_requested: null,
                group_role_permissions: null,
                percentageOfCurrentLevel: null,
                tckn: null,
            };

            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property)
                    && property in initialData) {
                    data[property] = initialData[property];
                }
            }

            Object.defineProperties(this, Object.freeze({
                id: {
                    /**
                        * @name id
                        * @type {integer}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.id,
                },
                level: {
                    /**
                        * @name level
                        * @type {string}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.level,
                },
                state: {
                    /**
                        * @name state
                        * @type {boolean}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.state,
                },
                expert: {
                    /**
                        * @name expert
                        * @type {boolean}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.expert,
                },
                isBlocked: {
                    /**
                        * @name isBlocked
                        * @type {boolean}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.isBlocked,
                },

                photoFile: {
                    /**
                        * @name base64Photo
                        * @type {string}
                        * @memberof module:API.cvat.classes.AnnotaUser
                        * @readonly
                        * @instance
                    */
                    get: () => data.photoFile,
                },

                canLogin: {
                    get: () => data.canLogin,
                },

                phoneNumber: {
                    get: () => data.phoneNumber,
                },

                dateOfBirth: {
                    get: () => data.dateOfBirth,
                },

                computerExperience: {
                    get: () => data.computerExperience,
                },

                certificates: {
                    get: () => data.certificates,
                },

                programmingLanguages: {
                    get: () => data.programmingLanguages,
                },

                aiAndAnnotationExperience: {
                    get: () => data.aiAndAnnotationExperience,
                },

                tckn: {
                    get: () => data.tckn,
                },

                otherExperience: {
                    get: () => data.otherExperience,
                },

                address: {
                    get: () => data.address,
                },

                postCode: {
                    get: () => data.postCode,
                },

                town: {
                    get: () => data.town,
                },

                city: {
                    get: () => data.city,
                },

                isWorking: {
                    get: () => data.isWorking,
                },

                workPlace: {
                    get: () => data.workPlace,
                },

                education: {
                    get: () => data.education,
                },

                iban: {
                    get: () => data.iban,
                },

                payment_requested: {
                    get: () => data.payment_requested,
                },

                group_role_permissions: {
                    get: () => data.group_role_permissions,
                },

                percentageOfCurrentLevel: {
                    get: () => data.percentageOfCurrentLevel,
                },

            }));
        }
    }
    module.exports = AnnotaUser;
})();
