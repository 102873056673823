import './styles.scss';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import {
    Button,
    Col,
    Icon,
    Modal,
    Row,
    Table,
    Tooltip,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import TestTrainingItemComponent from "./test-training-item";
import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';

import getCore from 'cvat-core-wrapper';
import { string, func } from 'prop-types';
import i18n from '../i18n'
import { Tabs } from 'antd';
import Column from 'antd/lib/table/Column';
import ReactPlayer from 'react-player/file'
import { InfoIconYellow, BookIconYellow, PeopleInfoIcon, NoDataVector } from 'icons';
import { timeStamp } from 'node:console';

// import videoAnnotationVideo from '../../dist/images/video_etiketleme.mp4'
// import textAnnotationVideo from '../../dist/images/metin_etiketleme.mp4'
// import imageAnnotationVideo from '../../dist/images/resim_etiketleme.mp4'

const { TabPane } = Tabs;
const cvat = getCore();
let backendEndPoint = String(cvat.config.backendAPI)
let backendRoot = String(cvat.config.root)

const styleBlock={
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 20px #B3B3B329",
    border: "0.5px solid #E3E6F0",
    borderRadius: "8px"
}
interface StateToProps {
    auth: AuthState;
    check: number
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatching function
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type ApprovalPageProps = StateToProps & DispatchToProps
    & RouteComponentProps;

/**
 * @class_component Test Training Page for /testtraining
 */
class TestTrainingPage extends React.PureComponent<ApprovalPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            check: string,
            trainings: [],
            tests: [],
            isTrainings: false,
            exampleTab: true,
            modalVisible: false,
            videoAnnotationVideo: "",
            textAnnotationVideo: "",
            imageAnnotationVideo: "",
            width: 1920,
            height: 1080,
        }
        this.renderInstructionsPage = this.renderInstructionsPage.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleTrainings = this.handleTrainings.bind(this)
        this.handleTests = this.handleTests.bind(this)
        this.getTasks = this.getTasks.bind(this)
        this.getTests = this.getTests.bind(this)
        this.renderTestPage = this.renderTestPage.bind(this);
        this.renderTrainingPage = this.renderTrainingPage.bind(this);
        this.loadVideo();
    }

    /**
     * set window width and height
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    /**
     *
     * @param testTasks
     * @param testTasksContinuing
     * @description set test view template on state
     */
    public handleTests(testTasks: any[], testTasksContinuing: any[]) {
        let testViews = new Array();
        testViews = testTasks.map(
            item=>
                <TestTrainingItemComponent updateTests={this.getTests} updateTrainings={this.getTasks} taskType="test" taskInstance={item} continuingTasks={testTasksContinuing} previewImage={backendEndPoint + '/tasks/' + item.id + '/data?type=preview'} user={this.props.auth.user} />
        );

        this.setState({
            tests: testViews
        })
    }

    /**
     *
     * @param trainingTasks
     * @param trainingTasksContinuning
     * @description set training view template
     */
    public handleTrainings(trainingTasks: any[], trainingTasksContinuning: any[]) {
        let trainingViews = new Array();
        trainingViews = trainingTasks.map(
            item=>
                <TestTrainingItemComponent updateTests={this.getTests} updateTrainings={this.getTasks} taskType="training" taskInstance={item} continuingTasks={trainingTasksContinuning} previewImage={backendEndPoint + '/tasks/' + item.id + '/data?type=preview'} user={this.props.auth.user} />
        );
        this.setState({
            trainings: trainingViews
        })
    }

    /**
     *
     * @param response
     * @description set error on Modal
     */
    public popupError(response: string) {
        if (response != i18n.t('response-messages.success')) {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: response,
            });
        }
    }

    public componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.props.verifyAuthorized();
        this.getTests();
        this.getTasks();
        if (this.props.location.state && this.props.location.state.redirectMessage != null && this.props.location.state.referrer == '/tasks')
            this.state.modalVisible = true;
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     * @async
     * @description import videos, sets state
     */
    async loadVideo() {
        let videoAnnota = await import("../../dist/images/video_etiketleme.mp4")
        let imgAnnota = await import("../../dist/images/resim_etiketleme.mp4")
        let textAnnota = await import("../../dist/images/metin_etiketleme.mp4")

        this.setState({ videoAnnotationVideo: videoAnnota.default, textAnnotationVideo: textAnnota.default, imageAnnotationVideo: imgAnnota.default })
    }

    /**
     * @async
     * @param userId
     * @returns void, get test tasks, handle tests
     */
    async getTests(userId?: any) {
        const testTasks = await cvat.jobs.getTrainingTestTasks('test');
        let testTasksContinuing;
        if(userId)
            testTasksContinuing = await cvat.jobs.getTestJobs(null, userId);
        else
            testTasksContinuing = await cvat.jobs.getTestJobs(null, this.props.auth.user.id);
        this.handleTests(testTasks.results, testTasksContinuing)
        return;
    }

    /**
     * @async
     * @param userId
     * @returns void, get training tasks, handle tasks
     */
    async getTasks(userId?: any) {
        const trainingTasks = await cvat.jobs.getTrainingTestTasks('training');
        let trainingTasksContinuing;
        if(userId)
            trainingTasksContinuing = await cvat.jobs.getTrainingList('started', userId);
        else
            trainingTasksContinuing = await cvat.jobs.getTrainingList('started', this.props.auth.user.id);
        this.handleTrainings(trainingTasks.results, trainingTasksContinuing.results)
        return
    }

    /**
     *
     * @param self
     * @param value
     * @description change views type according to value param
     */
    private changeViewType(self: any, value: string) {
        self.setState({
            tests: [],
            trainings: [],
            exampleTab: false,
        });
        if (value == "1") {
            self.setState({ isTrainings: false });
            this.getTests()
        } else if (value == "2") {
            self.setState({ isTrainings: true });
            this.getTasks()
        } else {
            self.setState({ exampleTab: true });
        }
    }

    /**
     *
     * @returns task list template
     */
    private renderTaskList() {
        const tasks = this.state.isTrainings ? this.state.trainings : this.state.tests;
        let spanParameter = parseInt(15000/this.state.width)

        let zoom = '100%'
        if(innerHeight >= innerWidth){
            spanParameter = 24,
            zoom = '70%'
        }
        if(tasks.length>0){
        const views = tasks.map(item=><Col span={spanParameter} style={{zoom:zoom}}>{item}</Col>)
        if (!this.state.exampleTab) {
            return (
                <Row type='flex' justify='space-around' align='middle'>
                    { views }
                </Row>
            )
        }
        }else{
           return (
            <div style={{position: "fixed",display: "flex",left: "45.5%",top: "50%",flexDirection: "column",alignItems: "center"}}><Icon component={NoDataVector}/><br />
            <Text style={{ fontSize: "18px" }} type="secondary">VERİ YOK</Text><br/><Text type="secondary">Bu çeşitte hiç görev oluşturulmamış.</Text></div>
           )
        }
    }

    /**
     * @description close Modal
     */
    handleModalClose = () => {
        this.setState({ modalVisible: false });
        this.props.location.state = null;
    };

    /**
     *
     * @returns training section template
     */
    renderTrainingPage() {
        return (
            <div>
                <br></br>
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Bu sayfada yaptığınız çalışmalar etiketleyici statünüze etki etmeyecek ve puanlamaya tabi olmayacaktır.</h3>
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Etiketleme becerilerinizi ve etiketleme araçlarını kullanımınızı pekiştirmeniz için dilediğiniz kadar deneme yapabilirsiniz.</h3>

            </div>
        )
    }

    /**
     *
     * @returns instruction section template
     */
    renderInstructionsPage() {
        let mobileView = false
        if(this.state.width <= this.state.height){
            mobileView = true
        }
        if(!mobileView){
            return (
                <div style={{marginTop:"3%"}}>
                    <div style={{...styleBlock, padding:"2%",display:"flex", justifyContent:"space-around", margin:"auto", width:"55.6%"}}>
                    <Tooltip title="Etiketleme eğitim dokümanını yeni sekmede açmak için buraya tıklayın.">
                            <a style={{display:"grid"}} href="../images/etiketleme_egitim_dokumani.pdf#view=FitH" target="_blank">
                                <h3 style={{color:"#232323",top:"15.8%"}}>Eğitim akışı için tıklayın:</h3>
                                <Icon component={InfoIconYellow}/>
                            </a>
                        </Tooltip>
                        <Tooltip title="Etiketleme eğitim dokümanını yeni sekmede açmak için buraya tıklayın.">
                            <a  style={{display:"grid"}} href="../images/vk_kullanim_kilavuzu.pdf#view=FitH" target="_blank">
                                <h3 style={{color:"#232323",top:"15.8%"}}>Kullanım kılavuzu için tıklayın:</h3>
                                <Icon component={BookIconYellow}/>
                            </a>
                        </Tooltip>
                    </div>
                    <br></br><br></br><br></br>
                    <h2 style={{color:"#0F123F", fontWeight:"600", justifyContent: "center", display: "flex", margin:"auto" }}>Etiketleme Nasıl Yapılır?</h2>
                    <Tabs size="large" tabBarStyle={{ justifyContent: "center", display: "flex", margin:"auto", width:"40%"}} >
                        <TabPane tab="Video etiketleme" key="1">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%"}}>
                                <ReactPlayer url={this.state.videoAnnotationVideo} width="40%" height="40%" controls={true} />
                            </div>
                        </TabPane>
                        <TabPane tab="Metin etiketleme" key="2">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%" }}>
                                <ReactPlayer url={this.state.textAnnotationVideo} width="40%" height="40%" controls={true} />
                            </div>
                        </TabPane>
                        <TabPane tab="Resim etiketleme" key="3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%" }}>
                                <ReactPlayer url={this.state.imageAnnotationVideo} width="40%" height="40%" controls={true} />
                            </div>
                        </TabPane>
                    </Tabs>
                    <Row style={{marginTop:"4%"}}justify="center" type="flex">
                    <h3 style={{color:"#5A5C69"}}>Kafanızda hala sorular mı var? Bize 'destek@verikovani.ssb.gov.tr' adresinden ulaşabilirsiniz.</h3>
                    </Row>
                    <Row type="flex" justify="center">
                    <Icon component={PeopleInfoIcon}/>

                    </Row>
                </div>
            )
        }
        else{
            return (
                <div>
                    <Col span = {22} style={{marginTop:'4%', marginLeft:'5%'}}>
                        <div style={{...styleBlock, padding:"2%",display:"flex", justifyContent:"space-around", margin:"auto"}}>
                        <Tooltip title="Etiketleme eğitim dokümanını yeni sekmede açmak için buraya tıklayın.">
                                <a style={{display:"grid"}} href="../images/etiketleme_egitim_dokumani.pdf#view=FitH" target="_blank">
                                    <h3 style={{color:"#232323",top:"15.8%"}}>Eğitim akışı için tıklayın:</h3>
                                    <Icon component={InfoIconYellow}/>
                                </a>
                            </Tooltip>
                            <Tooltip title="Etiketleme eğitim dokümanını yeni sekmede açmak için buraya tıklayın.">
                                <a  style={{display:"grid"}} href="../images/etiketleme_egitim_dokumani.pdf#view=FitH" target="_blank">
                                    <h3 style={{color:"#232323",top:"15.8%"}}>Kullanım kılavuzu için tıklayın:</h3>
                                    <Icon component={BookIconYellow}/>
                                </a>
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span = {24} align='center' style={{marginTop:'4%'}}>
                        <h2 style={{color:"#0F123F", justifyContent: "center", display: "flex", margin:"auto" }}>Etiketleme Nasıl Yapılır?</h2>
                    </Col>
                    <Col span = {20} style={{marginLeft: '8%'}}>
                        <Tabs size="large" tabBarStyle={{ justifyContent: "center", display: "flex", margin:"auto", width:"90%"}} >
                            <TabPane tab="Video" key="1">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%"}}>
                                    <ReactPlayer url={this.state.videoAnnotationVideo} width="100%" height="40%" controls={true} />
                                </div>
                            </TabPane>
                            <TabPane tab="Metin" key="2">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%" }}>
                                    <ReactPlayer url={this.state.textAnnotationVideo} width="100%" height="40%" controls={true} />
                                </div>
                            </TabPane>
                            <TabPane tab="Resim" key="3">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"3.61%" }}>
                                    <ReactPlayer url={this.state.imageAnnotationVideo} width="100%" height="40%" controls={true} />
                                </div>
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col span = {24} align='center'>
                        <h3 style={{color:"#5A5C69"}}>Kafanızda hala sorular mı var? Bize 'destek@verikovani.ssb.gov.tr' adresinden ulaşabilirsiniz.</h3>
                    </Col>
                    <Col span = {24} align='center'>
                        <Icon component={PeopleInfoIcon}/>
                    </Col>
                </div>
            )
        }
    }

    /**
     *
     * @returns test section template
     */
    renderTestPage() {
        return (
            <div>
                <br></br>
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Bu sayfada yaptığınız etiketlemeler sistem tarafından değerlendirilecek olup sizin etiketleyici statünüzü belirlemektedir.</h3>
                <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Etiketleyici statünüzü profil bilgilerinizden görüntüleyebilirsiniz.</h3>

            </div>
        )

    }

    /**
     *
     * @returns test training page template using class functions
     */
    public render() {
        const buttonStyle={
            color:"#F2F4F8",
            background: "#0F123F 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #B3B3B329",
            border: "1px solid #707070",
            borderRadius: "8px",
            opacity: "0.75",
        }
        let mobileView = false
        if(this.state.width <= this.state.height){
            mobileView = true
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title" >{i18n.t('header.test')}</Text>
                {!mobileView ?
                <div style={{paddingTop:'15px'}}>
                <Row type="flex" justify="center"  gutter={[48,0]}>
                    <Col>
                        <Button data-tour="step-training" size="large" className="training-test-button"   style={this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "3") }}>{i18n.t('test-training.instructions')}</Button>
                    </Col>
                    <Col>
                        <Button data-tour="step-test" size="large" className="training-test-button"  style={!this.state.isTrainings&&!this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "1"); this.renderTestPage() }}>{i18n.t('test-training.tests')}</Button>
                    </Col>
                    <Col>
                        <Button data-tour="step-trial"  size="large" className="training-test-button"  style={this.state.isTrainings&&!this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "2"); this.renderTrainingPage() }}>{i18n.t('test-training.trainings')}</Button>
                    </Col>
                </Row>
                {this.state.exampleTab && this.renderInstructionsPage()}
                </div> :
                <div>
                <Row type="flex" justify="center" gutter={[0,2]}>
                    <Col span={24} style={{marginTop:'5%'}}>
                    <Col span ={8} align='center'>
                        <Button size="large" className="training-test-button"   style={this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "3") }}>{i18n.t('test-training.instructions')}</Button>
                    </Col>
                    <Col span ={8} align='center'>
                        <Button  size="large" className="training-test-button"  style={!this.state.isTrainings&&!this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "1"); this.renderTestPage() }}>{i18n.t('test-training.tests')}</Button>
                    </Col>
                    <Col span ={8} align='center'>
                        <Button size="large" className="training-test-button"  style={this.state.isTrainings&&!this.state.exampleTab?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType(this, "2"); this.renderTrainingPage() }}>{i18n.t('test-training.trainings')}</Button>
                    </Col>
                    </Col>
                    <Col span={24}>
                        {this.state.exampleTab && this.renderInstructionsPage()}
                    </Col>
                </Row>
                </div> }



                {!this.state.exampleTab && this.state.isTrainings && this.renderTrainingPage()}
                {!this.state.exampleTab && !this.state.isTrainings && this.renderTestPage()}
                {!this.state.exampleTab&&
                    <div>
                        {this.renderTaskList()}
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TestTrainingPage));
