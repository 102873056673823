const createTaskSteps = [
    {
        selector: '[data-tour="step-name"]',
        content: "Oluşturacağınız görevin adını bu alana girebilirsiniz."
    },
    {
        selector: '[data-tour="step-difficulty"]',
        content: "Görev zorluğunu bu alandan belirleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-user-limit"]',
        content: "Görev için kullanıcı limitini bu alandan belirleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-type-privacy"]',
        content: "Görev türünü ve görevin gizliliğini bu alandan ayarlayabilirsiniz."
    },
    {
        selector: '[data-tour="step-guide-shape-category"]',
        content: "Bu alandan göreve yönerge ve önizleme ekleyebilirsiniz. Etiketleme görevinde kullanılmasını istediğiniz şekilleri ve görev kategorisini seçebilirsiniz."
    },
    {
        selector: '[data-tour="step-labels"]',
        content: "Görevde etiketlenecek nesneler için etiket bilgilerini bu alandan girebilirsiniz."
    },
    {
        selector: '[data-tour="step-metadata"]',
        content: "Görevinize anahtar-değer verisi ekleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-files"]',
        content: "Etiketlenmesini istediğiniz dosyaları bu alandan seçebilirsiniz."
    },
    {
        selector: '[data-tour="step-advanced"]',
        content: "Görevin imge kalitesi, kesişim boyutu ve kesit boyutu gibi özelliklerini bu panel altında düzenleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-submit"]',
        content: "Buraya basarak görevi oluşturabilirsiniz."
    },
  ];

  export default createTaskSteps;