// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import { useTranslation } from 'react-i18next';

import {
    EmptyTasksIcon as EmptyModelsIcon,
} from 'icons';

/**
 *
 * @returns Empty List Component for Models Page Empty List
 */
export default function EmptyListComponent(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='cvat-empty-models-list'>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Icon className='cvat-empty-models-icon' component={EmptyModelsIcon} />
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Text strong>{t('models-page.noModelUploaded')} ...</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Text type='secondary'>{t('models-page.toAnnotateYourTasksAutomatically')}</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Link to='/models/create'>{t('models-page.uploadNewModel')}</Link>
                </Col>
            </Row>
        </div>
    );
}
