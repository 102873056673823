// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Timeline from 'antd/lib/timeline';
import Dropdown from 'antd/lib/dropdown';
import { ShortcutIcon } from 'icons';
import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import {
    MainMenuIcon,
    SaveIcon,
    UndoIcon,
    RedoIcon,
    FullscreenIcon
} from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
    saving: boolean;
    textTask: boolean;
    pcTask: boolean;
    savingStatuses: string[];
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    showShortcuts():void

}

/**
 * Create component group for saving, undo and redo annotation, set fullscreen, open shortcut dialog
 *
 * @param props consists of saving, savingStatuses, undoAction, redoAction, saveShortcut, undoShortcut, redoShortcut, textTask, pcTask variables ans onSaveAnnotation, onUndoClick, onRedoClick, showShortcuts functions
 * @returns Col
 */
function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        savingStatuses,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        textTask,
        pcTask,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        showShortcuts
    } = props;

    const { t } = useTranslation();
    return (
        <Col data-tour="step-topbar-left" className='cvat-annotation-header-left-group'>
            <Dropdown overlay={<AnnotationMenuContainer />}>
                <Button type='link' className='cvat-annotation-header-button'>
                    <Icon component={MainMenuIcon} />
                    {/* Menu */}
                </Button>
            </Dropdown>
            <Button
                title={`${t('standard-workspace.saveCurrentChanges')} ${saveShortcut}`}
                onClick={saving ? undefined : onSaveAnnotation}
                type='link'
                className={saving
                    ? 'cvat-annotation-disabled-header-button'
                    : 'cvat-annotation-header-button'}
            >
                <Icon component={SaveIcon} />
                {/* { saving ? t('standard-workspace.saving') : t('standard-workspace.save') } */}
                <Modal
                    title={`${t('standard-workspace.savingChangesOnServer')} ${saveShortcut}`}
                    visible={saving}
                    footer={[]}
                    closable={false}
                >
                    <Timeline pending={savingStatuses[savingStatuses.length - 1] || t('standard-workspace.pending')}>
                        {
                            savingStatuses.slice(0, -1)
                                .map((
                                    status: string,
                                    id: number,
                                // eslint-disable-next-line react/no-array-index-key
                                ) => <Timeline.Item key={id}>{status}</Timeline.Item>)
                        }
                    </Timeline>
                </Modal>
            </Button>
            <Button
                title={`${t('standard-workspace.undo')}: ${undoAction} ${undoShortcut}`}
                disabled={!undoAction}
                style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onUndoClick}
            >
                <Icon component={UndoIcon} />
                {/* <span>{t('standard-workspace.undo')}</span> */}
            </Button>
            <Button
                title={`${t('standard-workspace.redo')}: ${redoAction} ${redoShortcut}`}
                disabled={!redoAction}
                style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onRedoClick}
            >
                <Icon component={RedoIcon} />
                {/* {t('standard-workspace.redo')} */}
            </Button>

            <Button
                type='link'
                className='cvat-annotation-header-button'
                title={t('top-bar.fullscreen')}
                onClick={(): void => {
                    if (window.document.fullscreenEnabled) {
                        if (window.document.fullscreenElement) {
                            window.document.exitFullscreen();
                        } else {
                            window.document.documentElement.requestFullscreen();
                        }
                    }
                }}
            >
                <Icon component={FullscreenIcon} />
                {/* {t('top-bar.fullscreen')} */}
            </Button>
            {!(textTask||pcTask)&&<Button type='link' className='cvat-annotation-header-button' onClick={showShortcuts}>
                <Icon component={ShortcutIcon} />
                {t('top-bar.shortcuts')}
            </Button>}
        </Col>
    );
}

export default React.memo(LeftGroup);
