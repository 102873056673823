// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import FileManagerComponent, { Files } from 'components/file-manager/file-manager';

import { loadShareDataAsync } from 'actions/share-actions';
import {
    ShareItem,
    CombinedState,
} from 'reducers/interfaces';

interface OwnProps {
    ref: any;
    withRemote: boolean;
    onLoad: (files:any) => void;
    isModify: boolean;
    parentMethod: (state: boolean) => void;
    getFileType: (type:any) => void;
    user:any;
}

interface StateToProps {
    treeData: TreeNodeNormal[];
}

interface DispatchToProps {
    getTreeData(key: string, success: () => void, failure: () => void): void;
}

/**
 *
 * @param state -> combined state containing treeData root information
 * @returns treeData information
 * @description Defines convert function converting items and path to TreeNodeNormal then returns treeData by using convert function
 */
function mapStateToProps(state: CombinedState): StateToProps {
    /**
     *
     * @param items
     * @param path
     * @returns tree representation of items
     */
    function convert(items: ShareItem[], path?: string): TreeNodeNormal[] {
        return items.map((item): TreeNodeNormal => {
            const isLeaf = item.type !== 'DIR';
            const key = `${path}${item.name}${isLeaf ? '' : '/'}`;
            return {
                key,
                isLeaf,
                title: item.name || 'root',
                children: convert(item.children, key),
            };
        });
    }

    const { root } = state.share;
    return {
        treeData: convert([root], ''),
    };
}

/**
 *
 * @param dispatch
 * @returns getTreeData action dispatching function
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getTreeData: (key: string, success: () => void, failure: () => void): void => {
            dispatch(loadShareDataAsync(key, success, failure));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;

/**
 * @class Render method returns FileManagerComponent
 * @description Passes props to FileManagerComponent, defines getFiles and getSelectedDataset and reset functions
 */
export class FileManagerContainer extends React.PureComponent<Props> {
    private managerComponentRef: any;

    public getFiles(): Files {
        return this.managerComponentRef.getFiles();
    }

    public getSelectedDataset(): any {
        return this.managerComponentRef.getSelectedDataset();
    }

    public reset(): Files {
        return this.managerComponentRef.reset();
    }

    public render(): JSX.Element {
        const {
            treeData,
            getTreeData,
            withRemote,
            onLoad,
            isModify,
            parentMethod,
            getFileType,
            user
        } = this.props;

        return (
            <FileManagerComponent
                treeData={treeData}
                onLoadData={getTreeData}
                withRemote={withRemote}
                ref={(component): void => {
                    this.managerComponentRef = component;
                }}
                user = {user}
                onLoad={onLoad}
                isModify = {isModify}
                parentMethod = {parentMethod}
                getFileType={getFileType}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(FileManagerContainer);
