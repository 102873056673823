// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';

import {VeriKovaniLogo, navigationIcon } from 'icons';
import consts from 'consts';
import { useTranslation } from 'react-i18next';
import { Tooltip, Avatar } from 'antd';
import { MailIcon, NewMailIcon} from "icons";
const {AVATAR_MAP, AVATAR_LEVEL_MAP, AVATAR_LEVEL_MAP_EXPERT, NEW_MESSAGE_MAP} = consts;
import { useState, useEffect } from 'react';
import { useTour } from "@reactour/tour";

interface HeaderContainerProps {
    onLogout: () => void;
    logoutFetching: boolean;
    installedAnalytics: boolean;
    installedAutoAnnotation: boolean;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    serverHost: string;
    username: string;
    isAdmin: boolean;
    groups: any;
    hasNewMessages: number;
    avatarId: any;
    level:any;
    expert:any;
    toolName: string;
    serverVersion: string;
    serverDescription: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
    switchSettingsShortcut: string;
    userState:string;
}

type Props = HeaderContainerProps & RouteComponentProps;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function HeaderContainer(props: Props): JSX.Element {
    const {
        installedTFSegmentation,
        installedAutoAnnotation,
        installedTFAnnotation,
        installedAnalytics,
        username,
        groups,
        hasNewMessages,
        avatarId,
        toolName,
        serverHost,
        serverVersion,
        serverDescription,
        coreVersion,
        canvasVersion,
        uiVersion,
        onLogout,
        logoutFetching,
        isAdmin,
        switchSettingsShortcut,
        userState,
        level,
        expert
    } = props;

    const renderModels = installedAutoAnnotation
        || installedTFAnnotation
        || installedTFSegmentation;

    const {
        CHANGELOG_URL,
        LICENSE_URL,
        GITTER_URL,
        FORUM_URL,
        GITHUB_URL,
    } = consts;

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [scrolled, setScroll] = useState(0);
    const { setIsOpen } = useTour();
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll)

        return () =>{
            window.removeEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll)
        }
    }, []);

    /**
     * Handle scroll
     * @param e event
     */
    var handleScroll =  function(e) {
        if(e.path[1].scrollY > 150){
            setScroll(e.path[1].scrollY);
        }else{
            setScroll(0)
        }
    };

    /**
     * Get page guide link
     * @returns link string
     */
    function getPageGuideLink():string {
        const { groups } = props

        let link = "../guides/vk_kullanim_kilavuzu";
        let url = String(window.location.pathname)
        let temp = url.split('/')

        if(temp.includes("jobs")){
            return "../../../images/vk_kullanim_kilavuzu.pdf";
        }
        temp.forEach(element =>{ if(element != "")  link =  link + "_"+ (!/\d/.test(element) ? element : "details")})

        if(groups.includes("provider") && (url == "/dashboard" || url == "/tasks" || url.includes("/tasks/"))){
            link += "_dp";
        }
        return  link + ".pdf";
    }

    /**
     * Return about modal
     */
    function aboutModal(): void {
        Modal.info({
            title: `${toolName}`,
            content: (
                <div>
                    <p>
                        {`${serverDescription}`}
                    </p>
                    <p>
                        <Text strong>
                            Server version:
                        </Text>
                        <Text type='secondary'>
                            {` ${serverVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Core version:
                        </Text>
                        <Text type='secondary'>
                            {` ${coreVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Canvas version:
                        </Text>
                        <Text type='secondary'>
                            {` ${canvasVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            UI version:
                        </Text>
                        <Text type='secondary'>
                            {` ${uiVersion}`}
                        </Text>
                    </p>
                    <Row type='flex' justify='space-around'>
                        <Col><a href={CHANGELOG_URL} target='_blank' rel='noopener noreferrer'>{'What\'s new?'}</a></Col>
                        <Col><a href={LICENSE_URL} target='_blank' rel='noopener noreferrer'>License</a></Col>
                        <Col><a href={GITTER_URL} target='_blank' rel='noopener noreferrer'>Need help?</a></Col>
                        <Col><a href={FORUM_URL} target='_blank' rel='noopener noreferrer'>Forum on Intel Developer Zone</a></Col>
                    </Row>
                </div>
            ),
            width: 800,
            okButtonProps: {
                style: {
                    width: '100px',
                },
            },
        });
    }

    const { t } = useTranslation();
    let userOnlySystemAdmin = groups.includes("system_admin") && !(groups.includes("annotator")||groups.includes("provider")||groups.includes("candidate")||isAdmin||groups.includes("admin"));
    let rejectedOrBlocked = (userState=="rejected"||userState=="blocked")
    let avatar;
    if(level === 0){
        avatar = AVATAR_MAP.get(avatarId)
    } else if(expert === true){
        avatar = AVATAR_LEVEL_MAP_EXPERT.get(avatarId+level)
    }
    else{
        avatar = AVATAR_LEVEL_MAP.get(avatarId+level)
    }

    avatar = `/${avatar}`;

    const menu = (
        <Menu className='cvat-header-menu' mode='vertical'>
            { (!userOnlySystemAdmin&&!rejectedOrBlocked) &&<Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/profile')
                }
            >
                <Icon type='user' />
                {t('keywords.profile')}
            </Menu.Item>}
           { isAdmin && <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/manage-platform')
                }
            >
                <Icon type='setting' />
                {t('keywords.settings')}
            </Menu.Item>}
           {/*  <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/change-pass')
                }
            >
                <Icon type='lock' />
                Şifre Değiştir
            </Menu.Item> */}
            {/* <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/payments')
                }
            >
                <Icon type='credit-card' />
                Ödeme
            </Menu.Item> */}
            {/* <Menu.Item onClick={() => aboutModal()}>
                <Icon type='info-circle' />
                {t('header.about')}
            </Menu.Item> */}
            <Menu.Item
                id="logOut"
                onClick={onLogout}
                disabled={logoutFetching}
            >
                {logoutFetching ? <Icon type='loading' /> : <Icon type='logout' />}
                {t('header.logOut')}
            </Menu.Item>

        </Menu>
    );

    let height = '100px'
    let iconClass = 'veri-kovani-logo-icon-header'
    let dropdownClassIcon = 'cvat-header-menu-icon'
    let dropdownClass = 'cvat-header-menu-dropdown'
    let transformMessageIcon = 'scale(1.0)'
    let transformAvatar = 'scale(1.5)'
    let marginAvatar = "15px"
    let marginMessageIcon = '2%'
    let transformScale = ""
    let marginLeft = "0px";
    // if(innerHeight>innerWidth){
    if(innerWidth/ innerHeight   < 1.7){
        iconClass = 'veri-kovani-logo-icon-header-vertical'
        height = '60px'
        dropdownClassIcon = 'cvat-header-menu-icon-vertical'
        dropdownClass = 'cvat-header-menu-dropdown-vertical'
        transformMessageIcon = 'scale(0.8)'
        marginMessageIcon = '0%'
        transformAvatar = "scale(1)"
        marginAvatar = "0px"
    }
    else if(scrolled>0){
        let scale = (1/(scrolled*0.004))
        marginLeft = "calc(10vh - "+(scrolled*0.04)+"vh)"
        if(scale > 1){
            scale = 1;
            marginLeft = "10px";
        }
        else if(scale < 0.6){
            scale = 0.6
            marginLeft = "-50px"
        }
        transformScale = "scale("+ scale+")";
        height = 100/(scrolled*0.004)+"px";
    }
    return (
        <Layout.Header className='cvat-header' style={{ backgroundColor: "#FFFFFF",maxHeight:"100px",minHeight:"60px", height: height, position: 'fixed', zIndex: 3, width: '100%',boxShadow: "0px 10px 13px #B3B3B329"}} >
            <div id="rightH" className='cvat-left-header'>
                <Icon className={iconClass} style={(scrolled>0&&!(innerWidth/ innerHeight   < 1.7))?{transform:transformScale,marginLeft:marginLeft}:{}}component={VeriKovaniLogo} onClick={
                        (): void => {
                            if(!groups.includes('candidate')){
                                props.history.push('/dashboard')
                            }else{
                                Modal.info({
                                    title: 'Bilgi',
                                    content: "Görevler sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                    okText: 'Tamam',
                                });
                            }
                            }
                        }/>
                 {/* {(!groups.includes("system_admin")) &&(
                    <Tooltip overlayClassName="custom-tooltip" title={t('header.dashboard')}>
                        <Button
                            type="primary"
                            id="dashboard"
                            className='cvat-header-button'
                            value='dashboard'
                            onClick={
                                (): void => {
                                    if(!groups.includes('candidate')){
                                        props.history.push('/dashboard')
                                    }else{
                                        Modal.info({
                                            title: 'Bilgi',
                                            content: "Görevler sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                            okText: 'Tamam',
                                        });
                                    }
                                    }
                                }
                        >

                            <Icon className="header-icon" style={{color:"white"}}  type='home'></Icon>
                            <br></br>
                            {t('header.dashboard')}
                        </Button>
                </Tooltip>
                 )
                }
                {(!groups.includes("system_admin")) &&(
                    <Tooltip overlayClassName="custom-tooltip" title={t('header.tasks')}>
                        <Button
                            type="primary"
                            id="tasks"
                            className='cvat-header-button'
                            value='tasks'
                            onClick={
                                (): void => {
                                    if(!groups.includes('candidate')){
                                        props.history.push('/tasks?page=1')
                                    }else{
                                        Modal.info({
                                            title: 'Bilgi',
                                            content: "Görevler sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                            okText: 'Tamam',
                                        });
                                    }
                                    }
                                }
                        >

                            <Icon className="header-icon" style={{color:"white"}}  type='unordered-list'></Icon>
                            <br></br>
                            {t('header.tasks')}

                        </Button>
                    </Tooltip>
                    )
                }
                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.pendingTasks')}>
                            <Button
                                type="primary"
                                id="pendingTasks"
                                className='cvat-header-button'
                                value='taskApprove'
                                onClick={
                                    (): void => props.history.push('/approve-tasks')
                                }
                            >
                                <Icon className="header-icon"style={{color:"white"}} type='file-done'></Icon>
                                <br></br>
                                {t('header.pendingTasks')}
                            </Button>
                        </Tooltip>
                    )
                }
                {(!groups.includes("system_admin"))
                 &&(
                    <Tooltip overlayClassName="custom-tooltip" title={t('header.test')}>
                        <Button
                            type="primary"
                            id="test"
                            className='cvat-header-button'
                            value='test'
                            onClick={
                                (): void => props.history.push('/testtraining')
                            }
                        >
                            <Icon className="header-icon"style={{color:"white"}}type="calculator"></Icon>
                            <br></br>
                            {t('header.test')}
                        </Button>
                    </Tooltip>
                    )
                }
                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.testApprove')}>
                            <Button
                                type="primary"
                                id="testApprove"
                                className='cvat-header-button'
                                value='tests'
                                onClick={
                                    (): void => props.history.push('/evaluate-tests')
                                }
                            >
                                <Icon className="header-icon"style={{color:"white"}}type="check"></Icon>
                                <br></br>
                                {t('header.testApprove')}

                            </Button>
                        </Tooltip>
                    )
                }
                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.manageUsers')}>
                            <Button
                                type="primary"
                                id="manageUsers"
                                className='cvat-header-button'
                                value='users'
                                onClick={
                                    (): void => props.history.push('/manage-users')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="user"></Icon>
                                <br></br>
                                {t('header.manageUsers')}
                            </Button>
                        </Tooltip>
                    )
                }
                {(isAdmin || groups.includes('provider'))
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.uploadData')}>
                            <Button
                                type="primary"
                                id="uploadData"
                                className='cvat-header-button'
                                value='data'
                                onClick={
                                    (): void => props.history.push('/datasets/create')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="upload"></Icon>
                                <br></br>
                                {t('header.uploadData')}
                            </Button>
                        </Tooltip>
                    )
                }
                {(isAdmin || groups.includes('provider'))
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.datasets')}>
                            <Button
                                type="primary"
                                id="rawData"
                                className='cvat-header-button'
                                value='datasets_page'
                                onClick={
                                    (): void => props.history.push('/datasets')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}}type="database"></Icon>
                                <br></br>
                                {t('header.datasets')}
                            </Button>
                        </Tooltip>
                    )
                }
                {(isAdmin || groups.includes('ssbprovider'))
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="SSB Veri Kümeleri">
                            <Button
                                type="primary"
                                id="rawData"
                                className='cvat-header-button'
                                value='vsp_datasets_page'
                                onClick={
                                    (): void => props.history.push('/ssb/datasets')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}}type="database"></Icon>
                                <br></br>
                                SSB Veri Kümeleri
                            </Button>
                        </Tooltip>
                    )
                }
                {(isAdmin || groups.includes('provider'))
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Kullanıcı Grupları">
                            <Button
                                id="providerGroupPage"
                                type="primary"
                                className='cvat-header-button'
                                value='provider_group_page'
                                onClick={
                                    (): void => props.history.push('/providergroup')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="team"></Icon>
                                <br></br>
                                Kullanıcı Grupları
                            </Button>
                        </Tooltip>
                    )
                } */}
                {
                    /*
                    (isAdmin || groups.includes('provider'))
                        && (
                            <Button
                                id="datasets"
                                style={{ borderColor: 'orange' }}
                                ghost
                                className='cvat-header-button'
                                value='datasets_page-ddo'
                                onClick={
                                    (): void => props.history.push('/datasets-ddo')
                                }
                            >
                                {t('header.datasets')}
                            </Button>
                        )*/
                }
                {/* {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.managePlatform')}>
                            <Button
                                type="primary"
                                id="managePlatform"
                                className='cvat-header-button'
                                value='platform'
                                onClick={
                                    (): void => props.history.push('/manage-platform')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="desktop"></Icon>
                                <br></br>
                                {t('header.managePlatform')}
                            </Button>
                        </Tooltip>
                    )
                }
                {renderModels
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Models">
                            <Button
                                type="primary"
                                id="models"
                                className='cvat-header-button'
                                value='models'
                                onClick={
                                    (): void => props.history.push('/models')
                                }
                            >
                                <Icon  className="header-icon" style={{color:"white"}}type="apartment"></Icon>
                                <br></br>
                                Models
                            </Button>
                        </Tooltip>
                    )}
                {installedAnalytics && isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Analytics">
                            <Button
                                type="primary"
                                id="analytics"
                                className='cvat-header-button'
                                onClick={
                                    (): void => {
                                        // false positive
                                        // eslint-disable-next-line
                                        let alteredServerHost = serverHost.substring(0, serverHost.length - 4) + '5601'
                                        window.open(`${alteredServerHost}/app/kibana`, '_blank');
                                    }
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="bar-chart"></Icon>
                                <br></br>
                                Analytics
                            </Button>
                        </Tooltip>
                    )}
                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Logs">
                            <Button
                                type="primary"
                                id="logs"
                                className='cvat-header-button'
                                onClick={
                                    (): void => props.history.push('/logs')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="bar-chart"></Icon>
                                <br></br>
                                Logs
                            </Button>
                        </Tooltip>
                )}

                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title={t('header.annotationEvaluation')}>
                            <Button
                                type="primary"
                                id="edy"
                                className='cvat-header-button'
                                value='edy'
                                onClick={
                                    (): void => props.history.push('/annotation-eval-page')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="schedule"></Icon>
                                <br></br>


                                {t('header.annotationEvaluation')}
                            </Button>
                        </Tooltip>


                    )
                }
                {isAdmin
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Kullanıcıya Ulaş">
                            <Button
                                type="primary"
                                id="contact-us"
                                className='cvat-header-button'
                                value='contact-us'
                                onClick={
                                    (): void => props.history.push('/contact-us')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="mail"></Icon>
                                <br></br>
                                Kullanıcıya Ulaş
                            </Button>
                        </Tooltip>
                    )
                }
                {(isAdmin || groups.includes("system_admin"))
                    && (
                        <Tooltip overlayClassName="custom-tooltip" title="Sistem Kaynakları">
                            <Button
                                type="primary"
                                id="contact-us"
                                className='cvat-header-button'
                                value='contact-us'
                                onClick={
                                    (): void => props.history.push('/stats')
                                }
                            >
                                <Icon className="header-icon" style={{color:"white"}} type="bar-chart"></Icon>
                                <br></br>
                                {t('header.systemResources')}
                            </Button>
                        </Tooltip>
                    )
                }
                { (!groups.includes("system_admin")) &&
                    (
                    <Tooltip overlayClassName="custom-tooltip" title="Mesajlarım">
                        <Button
                            type="primary"
                            id="messages"
                            className='cvat-header-button'
                            value='messages'
                            onClick={
                                (): void => props.history.push('/messages')
                            }
                        >
                            <Icon className="header-icon" style={{color:"white"}} type="message"></Icon>
                            <br></br>
                            Mesajlarım
                        </Button>
                    </Tooltip>
                    )
                } */}

            </div>
            <div className='cvat-right-header'>
                {/*
                <Button
                    className='cvat-header-button'
                    type='link'
                    onClick={
                        (): void => {
                            // false positive
                            // eslint-disable-next-line security/detect-non-literal-fs-filename
                            window.open(GITHUB_URL, '_blank');
                        }
                    }
                >
                    <Icon type='github' />
                    <Text className='cvat-text-color'>GitHub</Text>
                </Button>
                <Button
                    className='cvat-header-button'
                    type='link'
                    onClick={
                        (): void => {
                            // false positive
                            // eslint-disable-next-line
                            window.open(`${serverHost}/documentation/user_guide.html`, '_blank')
                        }
                    }
                >
                    <Icon type='question-circle' />
                    Help
                </Button>
                */}


                <Tooltip title="Sayfa kılavuzunu yeni sekmede açmak için buraya tıklayın.">
                    <a href= {getPageGuideLink()} target="_blank"  style={{ marginRight:'1%'}}>
                        <Icon style={{color:"rgb(190, 192, 207)", fontSize: '190%'}} type ="question-circle"/>
                    </a>
                </Tooltip>


                <Tooltip title="Sayfada gezintiye çıkın">
                        <Icon data-tour="step-tour-icon" onClick={() => setIsOpen(true)} style={{marginRight:'1%',color:"rgb(190, 192, 207)"}} component={navigationIcon}></Icon>
                </Tooltip>
                { (!userOnlySystemAdmin&&!rejectedOrBlocked) &&
                    (
                    <Tooltip title="Mesajlarım">
                        <Icon data-tour="step-message"
                            onClick={
                                (): void => props.history.push('/messages')
                            }
                            style={{color:"black", fontSize: '175%', marginRight: marginMessageIcon, transform:transformMessageIcon}}
                            component={ hasNewMessages < 10 ? NEW_MESSAGE_MAP.get(hasNewMessages) : NEW_MESSAGE_MAP.get(10)}>
                        </Icon>
                    </Tooltip>
                    )
                }
                <Dropdown overlay={menu} className={dropdownClass}>
                    <div data-tour="step-profile">
                        {(!userOnlySystemAdmin && !rejectedOrBlocked) && <img style={{ transform: transformAvatar, marginRight: marginAvatar, height: "32px" }} src={avatar} />}

                        <Text strong style={{ color: 'black' }}>
                            {/* {username.length > 14 ? `${username.slice(0, 10)} ...` : username} */}
                            {username}
                        </Text>
                        <Icon className={dropdownClassIcon} type='caret-down' style={{ color: 'black' }} />
                        </div>
                </Dropdown>
            </div>
        </Layout.Header>
    );
}

export default withRouter(HeaderContainer);
