import React from 'react';

import {
    Row,
    Col,
    Alert,
    Button,
    notification,
    Input,
    Modal,
    Collapse,
    Checkbox,
    Radio,
    Table,
    Popconfirm,
    Select,
    List,
    Progress
} from 'antd';

import Text from 'antd/lib/typography/Text';

import DatasetFileManagerContainer from 'containers/file-manager/dataset-file-manager';
import { Files } from 'components/file-manager/file-manager';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import i18n from '../../i18n'
import RadioGroup from 'antd/lib/radio/group';
import {isVideo} from '../file-type-validator';
import {FileResourceTypeSelection} from 'cvat-core/src/enums';

let maskArray = new Array()

export interface CreateDatasetData {
    basic: BaseConfiguration;
    files: Files;
}

interface Props {
    onCreate: (data: CreateDatasetData) => void;
    status: string;
    installedGit: boolean;
    statusDatasetUploadProgress: string
}

type State = CreateDatasetData;

const defaultState = {
    basic: {
        name: '',
        maskStartFrame: '',
        maskStopFrame: '',
        maskJson: {},
        maskListView: [],
        multiMaskViews: [],
        isPrivate: true
    },
    files: {
        local: [],
        share: [],
        remote: [],
    },
    enableMask: false,
    fileType: '',
    metaData: [],
    key: '',
    value: '',
    projectName: '',
    mediaType: '',
    dataFormat: '',
    dataTimeLength: '',
    dataSize: '',
    dataContext: '',
    dataPrivacy: '',
    dataUsage: ''
};

export default class CreateDatasetContent extends React.PureComponent<Props, State> {
    private basicConfigurationComponent: any;
    private fileManagerContainer: any;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
        this.renderMultiMasking = this.renderMultiMasking.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.getFileType = this.getFileType.bind(this);
        this.addpair = this.addpair.bind(this)
    }

    /**
     * Set enableMask and fileType state
     *
     * @param type string
     */
    public getFileType(type:string){
        if(type === FileResourceTypeSelection.SHARED){
            this.setState({
                enableMask:true
            });
         }
         else if(type !== ""){
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(type);
            const fileType = ext[1] ? ext[1] : this.state.fileType;
            this.setState({
                fileType: fileType,
                enableMask: (isVideo(ext[1]) || isVideo(fileType))
            });
        }
        else{
            this.setState({
                fileType:"",
                enableMask:false
            });
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        const { status } = this.props;


        if (status === 'CREATED' && prevProps.status !== 'CREATED') {
            notification.info({
                message: i18n.t('create-dataset.datasetUploaded'),
            });

            this.basicConfigurationComponent.resetFields();
            this.fileManagerContainer.reset();

            this.setState({
                ...defaultState,
            });

            this.setState({
                maskStartFrame: '',
                maskStopFrame: '',
                maskJson: {},
                maskListView: [],
                multiMaskViews: [],
                isPrivate:true,
                metaData: [],
                key: '',
                value: '',
                projectName: '',
                mediaType: '',
                dataFormat: '',
                dataTimeLength: '',
                dataSize: '',
                dataContext: '',
                dataPrivacy: '',
                dataUsage: ''
            });
        }
    }

    /**
     * Set return value of window event
     *
     * @param e event
     * @returns confirmationMessage
     */
    public pageLeaveWarning(e) {
        var confirmationMessage = 'Veri yüklenirken sayfadan ayrılmak istediğinize emin misiniz?';

        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }

    /**
     * Validate and set state files
     *
     * @returns !!totalLen
     */
    private validateFiles = (): boolean => {
        const files = this.fileManagerContainer.getFiles();
        files.remote = files.remote.filter(fileURL => fileURL.replace(/\s/g, '').length)
        this.setState({
            files
        });
        const totalLen = Object.keys(files).reduce(
            (acc, key) => acc + files[key].length, 0,
        );

        return !!totalLen;
    };

    /**
     * Handle submiting form
     *
     * @param values BaseConfiguration
     */
    private handleSubmitBasicConfiguration = (values: BaseConfiguration): void => {
        this.setState({
            basic: { ...values },
        });
    };

    /**
     * Handle submiting form
     *
     * @param values BaseConfiguration
     */
    private handleSubmitClick = (): void => {
        if (!this.validateFiles()) {
            notification.error({
                message: i18n.t('create-dataset.createDatasetFailed'),
                description: i18n.t('modify-dataset-page.atLeastOneFile'),
            });
            return;
        }

        this.basicConfigurationComponent.submit()
        .then(() => {
            return new Promise((resolve): void => {
                resolve();
            });
        }).then((): void => {
            const { onCreate } = this.props;
            window.addEventListener("beforeunload", this.pageLeaveWarning)
            onCreate(this.state);
        }).catch((): void => {
            notification.error({
                message: i18n.t('create-dataset.createDatasetFailed'),
                description: i18n.t('messages.checkValues'),
            });
        });
    };

    /**
     * Render basic configuration form
     *
     * @returns Col
     */
    private renderBasicBlock(): JSX.Element {
        return (
            <Col span={24}>
                <BasicConfigurationForm
                    wrappedComponentRef={
                        (component: any): void => { this.basicConfigurationComponent = component; }
                    }
                    onSubmit={this.handleSubmitBasicConfiguration}
                />
            </Col>
        );
    }

    /**
     * Render files block
     *
     * @returns Col
     */
    private renderFilesBlock(): JSX.Element {
        return (
            <Col data-tour="step-file-manager" span={24}>
                <Text type='danger' className='cvat-text-color'>* </Text>
                <Text className='cvat-text-color'>{i18n.t('keywords.selectFiles')}:</Text>
                <DatasetFileManagerContainer
                    ref={
                        (container: any): void => { this.fileManagerContainer = container; }
                    }
                    withRemote
                    parentMethod = {this.getFiles}
                    getFileType = {this.getFileType}
                />
            </Col>
        );
    }

    /**
     * Add mask
     *
     * @param start
     * @param stop
     * @param fileIndex
     */
    public addMask(start: any, stop: any, fileIndex: any){
        let id;
        if(maskArray.length == 0)
            id = 0;
        else
            id = maskArray[maskArray.length-1].id+1

        let mask = {
            "id": id,
            "fileId": fileIndex,
            "start": start,
            "stop": stop
        }

        maskArray.push(
            mask
        )

        let maskTotal = {
            "maskpoint": maskArray
        }

        this.setState({
            maskStartFrame: '',
            maskStopFrame: '',
            maskJson: maskTotal,
        });

    }

    /**
     * Add key value pair with an id
     *
     * @param key
     * @param value
     */
    public addpair(key: any, value: any){
        let id;
        if(this.state.metaData.length == 0)
            id = 0;
        else
            id = this.state.metaData[this.state.metaData.length-1].id+1

        let pair = {
            "id": id,
            "key": key,
            "value": value,
        }

        let currentMetaData = this.state.metaData
        currentMetaData.push(
            pair
        )

        this.setState({
            key: '',
            value: '',
            metaData: currentMetaData,
        });
    }

    /**
     * Set project name
     *
     * @param e event
     */
    async setProjectName(e: any){
        this.setState({
            projectName: e.target.value
        })
    }

    /**
     * Set media type
     *
     * @param e event
     */
    async setMediaType(e: any){
        this.setState({
            mediaType: e
        })
    }

    /**
     * Set data format
     *
     * @param e event
     */
    async setDataFormat(e: any){
        this.setState({
            dataFormat: e.target.value
        })
    }

    /**
     * Set data time length
     *
     * @param e event
     */
    async setDataTimeLength(e: any){
        this.setState({
            dataTimeLength: e.target.value
        })
    }

    /**
     * Set data size
     *
     * @param e event
     */
    async setDataSize(e: any){
        this.setState({
            dataSize: e.target.value
        })
    }

    /**
     *  Set data context
     *
     * @param e event
     */
    async setDataContext(e: any){
        this.setState({
            dataContext: e.target.value
        })
    }

    /**
     * Set data privacy
     *
     * @param e event
     */
    async setDataPrivacy(e: any){
        this.setState({
            dataPrivacy: e
        })
    }

    /**
     * Set data usage
     *
     * @param e event
     */
    async setDataUsage(e: any){
        this.setState({
            dataUsage: e.target.value
        })
    }


    /**
     * Render masking field
     *
     * @param fileIndex fileId
     * @returns div
     */
    public renderMaskingField(fileIndex: any){

        let currentMasks = new Array();
        let maskForFile = maskArray.filter(object => object.fileId == fileIndex)
        for (let i = 0; i < maskForFile.length; i++) {
            currentMasks.push(
                <ul class="list-unstyled" key={maskForFile[i].fileId} style={{paddingInlineStart:"unset"}}  >
                    <Row gutter = {8} >
                        <Col span = {8}>
                            <p>{i18n.t('keywords.startFrame')}: {maskForFile[i].start}</p>
                        </Col>
                        <Col span = {8}>
                            <p>{i18n.t('keywords.stopFrame')}: {maskForFile[i].stop}</p>
                        </Col>
                        <Col span = {8}>
                            <Button className="button-default-create-task" type='primary' size='small' ghost  onClick={(): void => {
                                let updateMaskArray = maskArray.filter(object => object.id != maskForFile[i].id)
                                maskArray = updateMaskArray
                                let maskTotal = {
                                    "maskpoint": maskArray
                                }
                                this.setState({ maskJson: maskTotal, })
                            }}>{i18n.t('keywords.delete')}</Button>
                        </Col>
                    </Row>
                </ul>
            )
        }
        let maskingPointsContent = <div></div>;
        let currentStop = ''
        let currentStart = ''
            maskingPointsContent = <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px", paddingLeft:"0.5%", paddingTop:"1%",maxWidth:"fit-content"}}>
                {/* <Collapse defaultActiveKey={"2"} accordion style={{marginBottom: '3%'}}>
                    <CollapsePanel header={i18n.t('keywords.maskedIntervals')} key={1}>

                    </CollapsePanel>
                </Collapse> */}
                {currentMasks}
                <Row gutter = {8} style={{marginTop: '3%'}}>
                <Col span = {8}>
                    <Input
                            className="input-create-task"
                            defaultValue = ''
                            placeholder={i18n.t('keywords.startFrame')}
                            onChange={(value) => currentStart = value.target.value}
                    />
                </Col>
                <Col span = {8}>
                    <Input
                            className="input-create-task"
                            placeholder={i18n.t('keywords.stopFrame')}
                            onChange={(value) => currentStop = value.target.value}
                    />
                </Col>
                <Col span = {4}>
                    <Button
                        className="button-create-task"
                        type='primary'
                        size='middle'
                        onClick={(): void => {
                                if(Number(currentStart) > Number(currentStop)){
                                     Modal.error({
                                         title: i18n.t('keywords.error'),
                                         content: i18n.t('messages.startFrameCannotBeLarger'),
                                     });
                                }else if(currentStart == '' || currentStart == undefined ){
                                     Modal.error({
                                         title: i18n.t('keywords.error'),
                                         content: i18n.t('messages.enterStartFrame'),
                                     });
                                }else if(currentStop == '' || currentStop == undefined){
                                     Modal.error({
                                         title: i18n.t('keywords.error'),
                                         content: i18n.t('messages.enterStopFrame'),
                                     });
                                }else{
                                    this.addMask(currentStart,currentStop,fileIndex)
                                }

                            }
                        }
                    >{i18n.t('keywords.add')}</Button>
                </Col>
            </Row>
            </div>
        return(
            <div>
                {maskingPointsContent}
            </div>
        );
    }

    /**
     * Get files
     */
    public getFiles(){
        //Reset mask variables after user uploads new files
        maskArray = new Array();
        this.validateFiles()
        this.setState({
            maskJson: {},
            isPrivate: this.state.isPrivate
        })
    }

    /**
     * Render multi masking
     * @returns div
     */
    public renderMultiMasking() : JSX.Element{
        if(this.fileManagerContainer == null || this.fileManagerContainer == undefined){
            return(
                <div></div>
            );
        }
        else{
            let multiMaskViews = new Array();
            let fileContent = new Array()
            let localFlag = false
            if(this.state.files.local.length > 0){
                fileContent = this.state.files.local
                localFlag = true
            }
            else if(this.state.files.remote.length > 0)
                fileContent = this.state.files.remote
            else
                fileContent = this.state.files.share
            for (let i = 0; i < fileContent.length; i++) {
                multiMaskViews.push(
                    <ul className="list-unstyled" key={fileContent[i]} >
                        {/* <Row gutter = {8} >
                        {(localFlag) ? <h1>{fileContent[i].name}</h1>: <h1>{fileContent[i]}</h1>}
                        </Row> */}
                        <Row gutter = {8} >
                            <Col span = {24}>
                            {this.renderMaskingField(i)}
                            </Col>
                        </Row>
                    </ul>
                )
            }
            return(
                <Row gutter = {8} >
                    { multiMaskViews}
                </Row>
            );
        }
    }

    /**
     * Set privacy asynchronously
     *
     * @param e event
     */
    async setPrivacy(e: any){
        if(e.target.value ===  i18n.t('keywords.private')){
            this.setState({
                isPrivate: true
            })
        }else{
            this.setState({
                isPrivate: false
            })
        }
    }

    /**
     * Render metadata part
     *
     * @returns div
     */
    private renderMetaData(): JSX.Element {

        return (
            <div data-tour="step-metadata">
                <Text className='cvat-text-color'>Anahtar-Değer:</Text>
                <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px",marginTop:"10px", paddingLeft:"0.5%", paddingTop:"1%"}}>
                <Row gutter = {8} >
                    <Col span = {8}>
                        <Input
                            data-tour="step-key"
                            className="input-create-task"
                            style={{backgroundColor:"#FFFFFF"}}
                            placeholder='Anahtar'
                            value = {this.state.key}
                            onChange={(value) =>
                                this.setState({
                                    key: value.target.value
                                })
                            }
                        />
                    </Col>
                    <Col span = {8}>
                        <Input
                            data-tour="step-value"
                            className="input-create-task"
                            style={{backgroundColor:"#FFFFFF"}}
                            placeholder='Değer'
                            value = {this.state.value}
                            onChange={(value) =>
                                this.setState({
                                    value: value.target.value
                                })
                            }
                        />
                    </Col>
                    <Col span = {6}>
                        <Button
                            data-tour="step-add-pair"
                            type='primary'
                            size='small'
                            className="button-create-task"
                            onClick={(): void => {
                                if(this.state.key == '' || this.state.key == undefined ){
                                    Modal.error({
                                        title: i18n.t('keywords.error'),
                                        content: 'Anahtarı giriniz!',
                                    });
                                }else if(this.state.value == '' || this.state.value == undefined ){
                                    Modal.error({
                                        title: i18n.t('keywords.error'),
                                        content: 'Değeri giriniz!',
                                    });
                                }else{
                                    this.addpair(this.state.key, this.state.value)
                                }
                            }
                            }
                        >{i18n.t('keywords.add')}</Button>
                    </Col>
                </Row>
                <List
                    style={{ marginTop: '2%' }}
                    rowKey='fileName'
                    dataSource={this.state.metaData}
                    size="small"
                    pagination={false}
                    locale={{ emptyText: 'Anahtar-Değer verisi bulunmuyor.' }}
                    renderItem={item => (
                        <Row type="flex" gutter={[12,12]}>
                            <Col span={8}>
                                <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.key}</Text>

                            </Col>
                            <Col span={8}>
                                <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.value}</Text>

                            </Col>
                            <Col span={4}>
                                <Popconfirm
                                    title="Silmek istediğinizden emin misiniz?"
                                    cancelText={i18n.t('keywords.no')}
                                    okText={i18n.t('keywords.yes')}
                                    onConfirm={
                                        (e) => {
                                            let currentMetaData = this.state.metaData
                                            currentMetaData = currentMetaData.filter(object => object.id != item.id)
                                            this.setState({
                                                metaData: currentMetaData
                                            })
                                        }
                                    }
                                >
                                    <Button size="small" className="button-default-create-task"> {i18n.t('keywords.delete')} </Button>
                                </Popconfirm>
                            </Col>

                        </Row>
                    )}
                >
                </List>
            </div>
        </div>
        );
    }

    /**
     * Render create task card
     *
     * @returns div
     */
    public render(): JSX.Element {
        const { status, statusDatasetUploadProgress } = this.props;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED';
        let statusMessage = status
        if(statusDatasetUploadProgress == '100'){
            statusMessage = 'Yüklenen veri sunucuya kaydediliyor'
        }
        if(status == 'CREATED' || status == 'FAILED'){
            window.removeEventListener('beforeunload', this.pageLeaveWarning);
        }
        return (
            <div className='cvat-create-task-content' >
               {/*  <Col span={24}>
                    <Text className='cvat-title-original'>{i18n.t('create-dataset.uploadDataset')}</Text>
                </Col> */}
                <Row type='flex' justify='space-between' align='middle'>
                <Col span={12}>
                { this.renderBasicBlock() }
                </Col>
                </Row>
              {/*   <Col span={18} style={{marginTop:'-1.5%'}}> //removed due to design choice
                    <RadioGroup  disabled={true} defaultValue={i18n.t('keywords.private')} onChange={(e) => this.setPrivacy(e)}>
                        <Radio value={i18n.t('keywords.private')} >{i18n.t('keywords.private')}</Radio>
                        <Radio value={i18n.t('keywords.public')}  >{i18n.t('keywords.public')}</Radio>
                    </RadioGroup>
                </Col> */}
                <Row data-tour="step-specs" type='flex' justify='space-between' align='middle'>
                    <Text className="cvat-text-color" style={{marginBottom:"10px"}}>Veri Kümesi Özellikleri</Text>
                    <Col span={24} >
                        <Row gutter={8}>
                            <Col span={12}>
                                <Input  className="input-create-task" value ={this.state.projectName} placeholder="Proje Adı" onChange={(e) => this.setProjectName(e)}/>
                            </Col>
                            <Col span={12}>
                                <Select
                                    className="input-create-task"
                                    style={{ width: '100%' }}
                                    placeholder="Veri Türü"
                                    dropdownStyle={{background:"#F2F4F8"}}
                                    onChange={(e) => this.setMediaType(e)}
                                >
                                    <Select.Option value={"text"}  >Metin</Select.Option>
                                    <Select.Option value={"image"} >Görüntü</Select.Option>
                                    <Select.Option value={"video"} >Video</Select.Option>
                                    <Select.Option value={"pcd"} >3 Boyutlu Nokta Kümesi</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{marginTop:'2.5%'}}>
                            <Col span={12}>
                                <Input className="input-create-task" value ={this.state.dataFormat} placeholder="Veri Formatı" onChange={(e) => this.setDataFormat(e)}/>
                            </Col>
                            <Col span={12}>
                                <Input className="input-create-task" value ={this.state.dataTimeLength} placeholder="Veri Miktarı" onChange={(e) => this.setDataTimeLength(e)}/>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{marginTop:'2.5%'}}>
                            <Col span={12}>
                                <Input className="input-create-task" value ={this.state.dataSize} placeholder="Veri Boyutu" onChange={(e) => this.setDataSize(e)}/>
                            </Col>
                            <Col span={12}>
                                <Input className="input-create-task" value ={this.state.dataContext} placeholder="Veri İçeriği" onChange={(e) => this.setDataContext(e)}/>
                            </Col>
                        </Row>
                        <Row gutter={8} style={{marginTop:'2.5%'}}>
                            <Col span={12}>
                                <Select
                                    className="input-create-task"
                                    style={{ width: '100%' }}
                                    placeholder="Gizlilik Derecesi"
                                    dropdownStyle={{background:"#F2F4F8"}}
                                    onChange={(e) => this.setDataPrivacy(e)}
                                >
                                    <Select.Option value={"unclassified"}  >Tasnif Dışı</Select.Option>
                                    <Select.Option value={"private"} >Gizli</Select.Option>
                                    <Select.Option value={"restricted"} >Hizmete Özel</Select.Option>
                                </Select>
                            </Col>
                            <Col span={12}>
                                <Input className="input-create-task" value ={this.state.dataUsage} placeholder="Kullanım Durumu" onChange={(e) => this.setDataUsage(e)}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br/>
                <Row type='flex' justify='space-between' gutter={12}>
                    <Col span={12}>
                        {this.renderMetaData()}
                    </Col>
                    <Col span={12}>
                        { this.renderFilesBlock() }
                        {this.state.enableMask ?<Col span={22}>{ this.renderMultiMasking() }</Col>: <></>}
                    </Col>
                    {loading? <Col span={18}> <Alert message={statusMessage} /></Col>:<></>}
                    {loading? <Col span={8}> <Progress percent={statusDatasetUploadProgress} /></Col> :<></>}
                </Row>
                <br/>
                <Row justify="center" type="flex">
                    <Button
                        data-tour="step-upload"
                        loading={loading}
                        disabled={loading}
                        className="button-create-task"
                        size="large"
                        type='primary'
                        onClick={this.handleSubmitClick}
                    >
                        Veri Kümesini Yükle
                    </Button>
                </Row>
            </div>
        );
    }
}
