// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import SVGVeriKovaniLogo from './../dist/vklogo.svg'
import SVGCVATLogo from './assets/cvat-logo.svg';
import SVGAccountIcon from './assets/account-icon.svg';
import SVGEmptyTasksIcon from './assets/empty-tasks-icon.svg';
import SVGMenuIcon from './assets/menu-icon.svg';
import SVGCursorIcon from './assets/cursor-icon.svg';
import SVGMoveIcon from './assets/move-icon.svg';
import SVGRotateIcon from './assets/rotate-icon.svg';
import SVGFitIcon from './assets/fit-to-window-icon.svg';
import SVGZoomIcon from './assets/zoom-icon.svg';
import SVGRectangleIcon from './assets/rectangle-icon.svg';
import SVGPolygonIcon from './assets/polygon-icon.svg';
import SVGPointIcon from './assets/point-icon.svg';
import SVGPolylineIcon from './assets/polyline-icon.svg';
import SVGTagIcon from './assets/tag-icon.svg';
import SVGMergeIcon from './assets/merge-icon.svg';
import SVGGroupIcon from './assets/group-icon.svg';
import SVGSplitIcon from './assets/split-icon.svg';
import SVGMainMenuIcon from './assets/main-menu-icon.svg';
import SVGSaveIcon from './assets/save-icon.svg';
import SVGUndoIcon from './assets/undo-icon.svg';
import SVGRedoIcon from './assets/redo-icon.svg';
import SVGFirstIcon from './assets/first-icon.svg';
import SVGBackJumpIcon from './assets/back-jump-icon.svg';
import SVGPreviousIcon from './assets/previous-icon.svg';
import SVGPlayIcon from './assets/play-icon.svg';
import SVGPauseIcon from './assets/pause-icon.svg';
import SVGNextIcon from './assets/next-icon.svg';
import SVGForwardJumpIcon from './assets/forward-jump-icon.svg';
import SVGLastIcon from './assets/last-icon.svg';
import SVGInfoIcon from './assets/info-icon.svg';
import SVGFullscreenIcon from './assets/fullscreen-icon.svg';
import SVGObjectOutsideIcon from './assets/object-outside-icon.svg';
import SVGObjectInsideIcon from './assets/object-inside-icon.svg';
import SVGBackgroundIcon from './assets/background-icon.svg';
import SVGForegroundIcon from './assets/foreground-icon.svg';
import SVGCubeIcon from './assets/cube-icon.svg';
import SVGResetPerspectiveIcon from './assets/reset-perspective.svg';
import SVGGuidelinesIcon from './assets/book.svg';
import SVGShortcutIcon from './assets/shortcut.svg';
import SVGDoneIcon from './assets/done.svg';
import SVGListIcon from './assets/list.svg';
import SVGLookUpIcon from './assets/lookup.svg';
import SVGTrophyIcon from './assets/trophy.svg';
import SVGMedalIcon from './assets/medal.svg';
import SVGMedalFadedIcon from './assets/medal-faded.svg';
import SVGMailIcon from './assets/mail-icon.svg';
import SVGNewMailIcon from './assets/new-mail-icon.svg';
import SVGSendIcon from './assets/send-icon.svg';
import SVGInfoYellowIcon from './assets/info-icon-yellow.svg';
import SVGBookYellowIcon from './assets/book-icon-yellow.svg';
import SVGPeopleInfoIcon from './assets/people-info-icon.svg';
import SVGMenuCustomIcon from './assets/menu-icon-custom.svg';
import SVGAvatar1 from './assets/avatar-1.svg';
import SVGAvatar2 from './assets/avatar-2.svg';
import SVGAvatar3 from './assets/avatar-3.svg';
import SVGAvatar4 from './assets/avatar-4.svg';
import SVGAvatar5 from './assets/avatar-5.svg';
import SVGAvatar6 from './assets/avatar-6.svg';
import SVGAvatar7 from './assets/avatar-7.svg';
import SVGAvatar8 from './assets/avatar-8.svg';
import SVGAvatar9 from './assets/avatar-9.svg';
import SVGAvatar10 from './assets/avatar-10.svg';
import SVGStartTask from './assets/start-task-vector.svg';
import SVGNewJob from './assets/get-new-job.svg';
import SVGCompletedTask from './assets/görev-detay-vector2.svg';
import SVGLinkIcon from './assets/link-icon.svg';
import SVGMessageTask from './assets/message-task.svg'
import SVGNoMessage from './assets/no-message-vector.svg'
import SVGNewMailIcon1 from "./assets/new-message-1.svg";
import SVGNewMailIcon2 from "./assets/new-message-2.svg";
import SVGNewMailIcon3 from "./assets/new-message-3.svg";
import SVGNewMailIcon4 from "./assets/new-message-4.svg";
import SVGNewMailIcon5 from "./assets/new-message-5.svg";
import SVGNewMailIcon6 from "./assets/new-message-6.svg";
import SVGNewMailIcon7 from "./assets/new-message-7.svg";
import SVGNewMailIcon8 from "./assets/new-message-8.svg";
import SVGNewMailIcon9 from "./assets/new-message-9.svg";
import SVGNewMailIcon10 from "./assets/new-message-10.svg";
import SVGUploaderAdd from "./assets/uploader-add.svg";
import SVGAvatarChangeIcon from "./assets/avatar-change.svg";
import SVGAvatarSelectIcon from "./assets/avatar-select.svg";
import SVGAvatarArrowForward from "./assets/avatar-arrow-forward.svg";
import SVGAvatarArrowBackward from "./assets/avatar-arrow-backward.svg";
import SVGTestTrainingIcon from "./assets/egitim-test-icon.svg";
import SVGSpinIcon from "./assets/VEP-Logo-kovan.svg";
import SVGContactUserIcon from "./assets/contact-user-icon.svg";
import SVGEmptyCircleIcon from "./assets/empty-circle-icon.svg";
import SVGEvalIcon from "./assets/degerlendirme-icon.svg";
import SVGLogsIcon from './assets/loglar-icon.svg';
import SVGNoData from "./assets/no-data-vector.svg";
import SVGDashboardScoreVector from "./assets/pano-etiketleme-skoru-vector.svg";
import SVGEvaluationJobIcon from "./assets/evaluation-job-icon.svg";
import SVGEvaluationJobIcon1 from "./assets/evaluation-job-icon1.svg";
import SVGLateJobIcon from "./assets/late-job-icon.svg";
import SVGNavigationIcon from "./assets/navigation-icon.svg";

export const VeriKovaniLogo = React.memo(
    (): JSX.Element => <SVGVeriKovaniLogo />,
);

export const CVATLogo = React.memo(
    (): JSX.Element => <SVGCVATLogo />,
);
export const AccountIcon = React.memo(
    (): JSX.Element => <SVGAccountIcon />,
);
export const EmptyTasksIcon = React.memo(
    (): JSX.Element => <SVGEmptyTasksIcon />,
);
export const MenuIcon = React.memo(
    (): JSX.Element => <SVGMenuIcon />,
);
export const CursorIcon = React.memo(
    (): JSX.Element => <SVGCursorIcon />,
);
export const MoveIcon = React.memo(
    (): JSX.Element => <SVGMoveIcon />,
);
export const RotateIcon = React.memo(
    (): JSX.Element => <SVGRotateIcon />,
);
export const FitIcon = React.memo(
    (): JSX.Element => <SVGFitIcon />,
);
export const ZoomIcon = React.memo(
    (): JSX.Element => <SVGZoomIcon />,
);
export const RectangleIcon = React.memo(
    (): JSX.Element => <SVGRectangleIcon />,
);
export const PolygonIcon = React.memo(
    (): JSX.Element => <SVGPolygonIcon />,
);
export const PointIcon = React.memo(
    (): JSX.Element => <SVGPointIcon />,
);
export const PolylineIcon = React.memo(
    (): JSX.Element => <SVGPolylineIcon />,
);
export const TagIcon = React.memo(
    (): JSX.Element => <SVGTagIcon />,
);
export const MergeIcon = React.memo(
    (): JSX.Element => <SVGMergeIcon />,
);
export const GroupIcon = React.memo(
    (): JSX.Element => <SVGGroupIcon />,
);
export const SplitIcon = React.memo(
    (): JSX.Element => <SVGSplitIcon />,
);
export const MainMenuIcon = React.memo(
    (): JSX.Element => <SVGMainMenuIcon />,
);
export const SaveIcon = React.memo(
    (): JSX.Element => <SVGSaveIcon />,
);
export const UndoIcon = React.memo(
    (): JSX.Element => <SVGUndoIcon />,
);
export const RedoIcon = React.memo(
    (): JSX.Element => <SVGRedoIcon />,
);
export const FirstIcon = React.memo(
    (): JSX.Element => <SVGFirstIcon />,
);
export const BackJumpIcon = React.memo(
    (): JSX.Element => <SVGBackJumpIcon />,
);
export const PreviousIcon = React.memo(
    (): JSX.Element => <SVGPreviousIcon />,
);
export const PauseIcon = React.memo(
    (): JSX.Element => <SVGPauseIcon />,
);
export const PlayIcon = React.memo(
    (): JSX.Element => <SVGPlayIcon />,
);
export const NextIcon = React.memo(
    (): JSX.Element => <SVGNextIcon />,
);
export const ForwardJumpIcon = React.memo(
    (): JSX.Element => <SVGForwardJumpIcon />,
);SVGDoneIcon
export const LastIcon = React.memo(
    (): JSX.Element => <SVGLastIcon />,
);
export const InfoIcon = React.memo(
    (): JSX.Element => <SVGInfoIcon />,
);
export const FullscreenIcon = React.memo(
    (): JSX.Element => <SVGFullscreenIcon />,
);
export const ObjectOutsideIcon = React.memo(
    (): JSX.Element => <SVGObjectOutsideIcon />,
);
export const ObjectInsideIcon = React.memo(
    (): JSX.Element => <SVGObjectInsideIcon />,
);
export const BackgroundIcon = React.memo(
    (): JSX.Element => <SVGBackgroundIcon />,
);
export const ForegroundIcon = React.memo(
    (): JSX.Element => <SVGForegroundIcon />,
);
export const CubeIcon = React.memo(
    (): JSX.Element => <SVGCubeIcon />,
);
export const ResetPerspectiveIcon = React.memo(
    (): JSX.Element => <SVGResetPerspectiveIcon />,
);
export const GuidelinesIcon = React.memo(
    (): JSX.Element => <SVGGuidelinesIcon />,
);
export const ShortcutIcon = React.memo(
    (): JSX.Element => <SVGShortcutIcon />,
);
export const DoneIcon = React.memo(
    (): JSX.Element => <SVGDoneIcon />,
);
export const ListIcon = React.memo(
    (): JSX.Element => <SVGListIcon />,
);
export const LookUpIcon = React.memo(
    (): JSX.Element => <SVGLookUpIcon />,
);
export const TrophyIcon = React.memo(
    (): JSX.Element => <SVGTrophyIcon />,
);
export const MedalIcon = React.memo(
    (): JSX.Element => <SVGMedalIcon />,
);
export const MedalFadedIcon = React.memo(
    (): JSX.Element => <SVGMedalFadedIcon />,
);
export const MailIcon = React.memo(
    (): JSX.Element => <SVGMailIcon />,
);
export const NewMailIcon = React.memo(
    (): JSX.Element => <SVGNewMailIcon />,
);
export const SendIcon = React.memo(
    (): JSX.Element => <SVGSendIcon />,
);
export const InfoIconYellow = React.memo(
    (): JSX.Element => <SVGInfoYellowIcon />,
);
export const BookIconYellow = React.memo(
    (): JSX.Element => <SVGBookYellowIcon />,
);
export const PeopleInfoIcon = React.memo(
    (): JSX.Element => <SVGPeopleInfoIcon />,
);
export const MenuCustomIcon = React.memo(
    (): JSX.Element => <SVGMenuCustomIcon />,
);
export const Avatar1 = React.memo(
    (): JSX.Element => <SVGAvatar1 />,
);
export const Avatar2 = React.memo(
    (): JSX.Element => <SVGAvatar2 />,
);
export const Avatar3 = React.memo(
    (): JSX.Element => <SVGAvatar3 />,
);
export const Avatar4 = React.memo(
    (): JSX.Element => <SVGAvatar4 />,
);
export const Avatar5 = React.memo(
    (): JSX.Element => <SVGAvatar5 />,
);
export const Avatar6 = React.memo(
    (): JSX.Element => <SVGAvatar6 />,
);
export const Avatar7 = React.memo(
    (): JSX.Element => <SVGAvatar7 />,
);
export const Avatar8 = React.memo(
    (): JSX.Element => <SVGAvatar8 />,
);
export const Avatar9 = React.memo(
    (): JSX.Element => <SVGAvatar9 />,
);
export const Avatar10 = React.memo(
    (): JSX.Element => <SVGAvatar10 />,
);
export const NewJobVector = React.memo(
    (): JSX.Element => <SVGNewJob />,
);
export const StartTaskVector = React.memo(
    (): JSX.Element => <SVGStartTask />,
);
export const LinkIcon = React.memo(
    (): JSX.Element => <SVGLinkIcon />,
);
export const CompletedTaskVector = React.memo(
    (): JSX.Element => <SVGCompletedTask/>,
);
export const MessageIconTask = React.memo(
    (): JSX.Element => <SVGMessageTask />,
);
export const NoMessageVector = React.memo(
    (): JSX.Element => <SVGNoMessage />,
);
export const NewMailIcon1 = React.memo(
    (): JSX.Element => <SVGNewMailIcon1 />,
);
export const NewMailIcon2 = React.memo(
    (): JSX.Element => <SVGNewMailIcon2 />,
);
export const NewMailIcon3 = React.memo(
    (): JSX.Element => <SVGNewMailIcon3 />,
);
export const NewMailIcon4 = React.memo(
    (): JSX.Element => <SVGNewMailIcon4 />,
);
export const NewMailIcon5 = React.memo(
    (): JSX.Element => <SVGNewMailIcon5 />,
);
export const NewMailIcon6 = React.memo(
    (): JSX.Element => <SVGNewMailIcon6 />,
);
export const NewMailIcon7 = React.memo(
    (): JSX.Element => <SVGNewMailIcon7 />,
);
export const NewMailIcon8 = React.memo(
    (): JSX.Element => <SVGNewMailIcon8 />,
);
export const NewMailIcon9 = React.memo(
    (): JSX.Element => <SVGNewMailIcon9 />,
);
export const NewMailIcon10 = React.memo(
    (): JSX.Element => <SVGNewMailIcon10 />,
);
export const UploaderAdd = React.memo(
    (): JSX.Element => <SVGUploaderAdd />,
);
export const AvatarChangeIcon = React.memo(
    (): JSX.Element => <SVGAvatarChangeIcon />,
);
export const AvatarSelectIcon = React.memo(
    (): JSX.Element => <SVGAvatarSelectIcon />,
);
export const AvatarArrowForward = React.memo(
    (): JSX.Element => <SVGAvatarArrowForward />,
);
export const AvatarArrowBackward = React.memo(
    (): JSX.Element => <SVGAvatarArrowBackward />,
);
export const TestTrainingIcon = React.memo(
    (): JSX.Element => <SVGTestTrainingIcon />,
);
export const VKSpin = React.memo(
    (): JSX.Element => <SVGSpinIcon />,
);
export const ContactUserIcon = React.memo(
    (): JSX.Element => <SVGContactUserIcon />,
);
export const EmptyCircleIcon = React.memo(
    (): JSX.Element => <SVGEmptyCircleIcon />,
);
export const EvalIcon = React.memo(
    (): JSX.Element => <SVGEvalIcon />,
);
export const LogsIcon = React.memo(
    (): JSX.Element => <SVGLogsIcon />,
);
export const NoDataVector = React.memo(
    (): JSX.Element => <SVGNoData />,
);
export const DashboardScoreVector = React.memo(
    (): JSX.Element => <SVGDashboardScoreVector />,
);
export const EvaluationJobIcon = React.memo(
    (): JSX.Element => <SVGEvaluationJobIcon />,
);
export const EvaluationJobIcon1 = React.memo(
    (): JSX.Element => <SVGEvaluationJobIcon1 />,
);
export const LateJobIcon = React.memo(
    (): JSX.Element => <SVGLateJobIcon/>,
);
export const navigationIcon = React.memo(
    (): JSX.Element => <SVGNavigationIcon/>,
);