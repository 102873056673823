import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Popconfirm, Icon, Modal, Table, Select, Spin, Checkbox, Divider, Tabs, Empty, List, Progress} from 'antd';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'
import VSPDatasetsPage from '../containers/vsp-datasets-page';
import { AuthState } from '../reducers/interfaces';
import Column from 'antd/lib/table/Column';
import {
    CombinedState,
} from 'reducers/interfaces';
import { Store } from 'redux';
import { getCVATStore } from 'cvat-store';
import Text from 'antd/lib/typography/Text';

import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';
import { recordKeyCombination } from 'react-hotkeys';
import $ from 'jquery';
import { downloadDatasetUpdateStatus } from '../actions/tasks-actions';

const cvat = getCore();
let store: null | Store<CombinedState> = null;
let datasetViews = new Array();
let DatasetList = <div></div>

let children = new Array();
var myMapForUserSelections = new Map();


function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}
interface StateToProps {
    auth: AuthState;
    datasetDownloadProgress: string
}

/**
 *
 * @param state
 * @returns auth and dataset download progress action dispatching functions
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
        datasetDownloadProgress: state.tasks.activities.creates.datasetDownloadProgress
    };
}

interface DispatchToProps {
    updateDownloadStatus: (progress: string) => void;
}

/**
 *
 * @param dispatch
 * @returns updateDownloadStatus action dispatching functions
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateDownloadStatus: (progress: string): void => dispatch(downloadDatasetUpdateStatus(progress)),
    };
}
class DatasetsPage extends React.PureComponent<StateToProps&DispatchToProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPage: 1,
            totalTestCount: 0,
            datasetViews: [],
            users: [],
            checkedAll: false,
            datasetsToBeUsed: [],
            datasetLoading: false,
            editModalVisible: false,
            editDatasetID: 0,
            editDatasetName: "",
            editDatasetNewName: "",
            onVspPage:false,
            datasetViewType: "all",
        }
        if(this.detecMobile()){
            Modal.info({
                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                okText: 'Tamam',
            });
            setTimeout(function(){ window.location.assign('/dashboard') }, 100);
        }
    }

    public detecMobile() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true
        } else {
            return false
        }
    }

    componentDidMount() {
        this.getUsers("",1);
        this.handleTests = this.handleTests.bind(this);
        const testsAsync = this.getDatasets(1);
        testsAsync.then(this.handleTests.bind(self));
    }

    /**
     * @async
     * @param filter
     * @param pageNum
     * @description get all aproved+non-blocked users and create selection list option for each user
     */
    async getUsers(filter: any, pageNum: any){
        let users;
        children = new Array();
        const filteredQuery = { ...filter };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }
        users = await cvat.users.getAnnotaUsers(null,pageNum,'approved',null,null,"False", true, null, filteredQuery);

        for (let i = 0; i < users.users.length; i++) {
            let valueCurrent  = users.users[i].id.id + '%%%' + users.users[i].id.username
            if(users.users[i].id.groups.filter(elem => elem==="provider" || elem ==='admin').length > 0){
                console.log(users.users[i].id.groups)
                children.push(
                    <Select.Option value={valueCurrent} label={users.users[i].id.username}>
                        <div className="demo-option-label-item">
                            {users.users[i].id.username}
                        </div>
                    </Select.Option>)
            }
        }

        this.setState({
            users : users,
        });
    }

    private filter = (inputValue: String, option: any): boolean => {
        if(inputValue.startsWith('id:') && inputValue.length > 3){
            if(inputValue.split(':').pop() == option.props.value.split('%%%')[0])
                return true
        }
        else{
            if(option.props.value.split('%%%')[1].toLowerCase().includes(inputValue.toLowerCase()))
                return true
        }
        return false;
    }

    /**
     * @async
     * @param value
     * @description construct search query with value, then asynchronous call to get users with query
     */
    private handleSearch = async (value: string): Promise<void> => {
        const state: CombinedState = getStore().getState();
        const { users } = state;

        const query = { ...(users.gettingQuery) };
        const search = value.replace(/\s+/g, ' ').replace(/\s*:+\s*/g, ':').trim();

        const fields = ['name', 'id'];
        for (const field of fields) {
            query[field] = null;
        }
        query.search = null;

        let specificRequest = false;
        for (const param of search.split(/[\s]+and[\s]+|[\s]+AND[\s]+/)) {
            if (param.includes(':')) {
                const [field, fieldValue] = param.split(':');
                if (fields.includes(field) && !!fieldValue) {
                    specificRequest = true;
                    if (field === 'id') {
                        if (Number.isInteger(+fieldValue)) {
                            query[field] = +fieldValue;
                        }
                    } else {
                        query[field] = fieldValue;
                    }
                }
            }
        }

        if (!specificRequest && value) {
            query.search = value;
        }

        await this.getUsers(query,null)
    };

    getSize(){
        const thWidthArr = [...document.querySelectorAll('.ant-table-tbody>tr:first-of-type>td')].map(td => td.offsetWidth);
        console.log(thWidthArr);
       /*  const table2 = document.querySelectorAll('table')[1];
        */
        document.querySelectorAll('table')[0].querySelectorAll('th').forEach((th, i) => {
                th.style.width = thWidthArr[i] + 'px';
                th.style.padding="16px 16px 16px 1px";
            })
    }

    /**
     * @async
     * @param testJobs
     * @description create dataset views template for test jobs
     */
    async handleTests(testJobs: any[]) {
        let datasetViews = new Array();
        let warning = i18n.t('datasets.deleteDatasetWarning');
        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasDatasetDownloadPermission = permissions.filter(object => object.permission_id__name == 'download_datasets').length > 0
        console.log(this.props.auth)
        datasetViews.push(
            <div>
            <Table rowKey={record => record.id}
                dataSource={testJobs}
                pagination={false}
                expandIconColumnIndex={5}
                expandIconAsCell={false}
                rowClassName={(record,index)=> {return"table-row-datasets"}}
                className="custom-table-datasets"
                expandIcon={({ expanded, onExpand, record }) =>

                  <Icon data-tour="step-expand" type="down" onClick={e => onExpand(record, e)} />

              }
                locale={{emptyText: 'Sonuç Bulunamadı'}}
                expandedRowRender={(record,index) => <>{this.handleExpand(record)}</>}
                style={{background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 10px 30px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "6px"}}
                >

                <Column className='step-dataset-name' title="Veri Kümesi Adı" dataIndex="name" render={(text, record) => <>{this.nameRender(record)}</>} />

                <Column className="step-dataset-mediaType" title={i18n.t('datasets.mediaType')} dataIndex="mediaType" />
                <Column className="step-dataset-fileCount" title={i18n.t('datasets.fileCount')} dataIndex="fileCount" />
                <Column className="step-dataset-privacy" title="Veri Gizliliği" render={(record) =>
                (!record.is_vsp ||(record.is_vsp && this.props.auth.user.groups.includes("ssbprovider")) )&&
                    <Checkbox style={{ marginLeft: '5%' }} checked={record.isPrivate} onChange={(e) => this.setPrivacy(record.id)}>{i18n.t('datasets.private')}</Checkbox>
                }/>
                <Column key="action" render={(record) =>
                    (!record.is_vsp ||(record.is_vsp && this.props.auth.user.groups.includes("ssbprovider")) )&&
                        <div>

                            <Link to={{ pathname: '/datasets/modify/' + record.id }}> <Button className="button-groups-page" type="default" >{i18n.t('datasets.expand')}</Button></Link>
                            {this.props.auth.user.groups.includes("admin")&&<Popconfirm
                                title={warning}
                                cancelText={i18n.t('keywords.no')}
                                okText={i18n.t('keywords.yes')}
                                onConfirm={
                                    (e) => this.deleteDataset(record.id)
                                }
                            >
                                <Button className="button-default-groups-page" style={{ marginLeft: '5%' }}  >{i18n.t('datasets.delete')} </Button>
                            </Popconfirm>}
                            {(this.props.auth.user.groups.includes("admin") || hasDatasetDownloadPermission) &&<Popconfirm
                                title= "Veriyi indirmek istediğinize emin misiniz?"
                                cancelText={i18n.t('keywords.no')}
                                okText={i18n.t('keywords.yes')}
                                onConfirm={
                                    (e) => this.downloadDataset(record.id)
                                }
                            >
                                <Button className="button-default-groups-page" style={{ marginLeft: '5%' }}>İndir</Button>
                            </Popconfirm>}
                        </div>
                    }
                />
                <Column>
                </Column>
            </Table>
            </div>
        );
        this.setState({
            datasetViews: datasetViews
        });
        this.getSize()
    }

    public pageLeaveWarning(e) {
        var confirmationMessage = 'Veri indirilirken sayfadan ayrılmak istediğinize emin misiniz?';

        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }

    /**
     * @async
     * @param datasetId
     * @description download dataset api proxy call
     */
    async downloadDataset(datasetId: any) {
        const {updateDownloadStatus} = this.props
        let response;
        updateDownloadStatus('0')
        window.addEventListener("beforeunload", this.pageLeaveWarning)
        response = await cvat.datasets.downloadDataset(datasetId, async (status: string): Promise<void> => {
            updateDownloadStatus(status);
        });
        window.removeEventListener('beforeunload', this.pageLeaveWarning);
        updateDownloadStatus('-1')
        if (response.status === 200) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     *
     * @param test
     * @returns expansion template for selected test
     */
    private handleExpand(test: any){
        const columnsMetaData = [
            {
                title: 'Anahtar',
                dataIndex: 'key',
                key: 'ket',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },
            {
                title: 'Değer',
                dataIndex: 'value',
                key: 'value',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },

        ];

        return(
            <div>
                <Row type="flex" gutter={[12,12]}>
                    {<Col span={12}>
                        <Row>
                            <Col span={12}>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Veri setini paylaşmak istediğiniz veri sağlayıcıyı seçin"
                                    onChange={(e) => this.handleChangeUserSelector(e,test.id)}
                                    onSearch={this.handleSearch}
                                    optionLabelProp="label"
                                    filterOption={this.filter}
                                >
                                    {children}
                                </Select>
                            </Col>
                            <Col span={3} style={{ marginLeft: '1%' }}>
                                <Button type='primary' className="button-groups-page" onClick={() => this.setUsers(test.id)} block>Paylaş</Button>
                            </Col>
                        </Row>
                            <List
                                style={{ marginTop: '2%' }}
                                rowKey={record => record.id}
                                dataSource={test.specialAnnotators}
                                size="small"
                                pagination={false}
                                locale={{ emptyText: 'Sonuç Bulunamadı' }}
                                header={<Text>Paylaşılan Kullanıcı Adı/Mail adresi</Text>}
                                renderItem={record => (
                                    <div style={{ display: "flex" }}>
                                        {this.props.auth.user.username !== record.username && !test.authority.map(x => x.username).includes(record.username) && <><div style={{ marginBottom: "10px", marginRight: "7px", padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>
                                            <Col span={5}>
                                                <Text>{record.username}</Text>
                                            </Col>
                                            <Col span={8}>
                                                <Text>{record.email}</Text>
                                            </Col>
                                        </div><Popconfirm
                                            title={i18n.t('datasets.deleteUserWarning')}
                                            cancelText={i18n.t('keywords.no')}
                                            okText={i18n.t('keywords.yes')}
                                            onConfirm={(e) => {
                                                this.removeUserFromDataset(test.id, record.id);
                                            } }
                                        >
                                                <Button className="button-groups-page" style={{ float: "right" }}> Kaldır</Button>
                                            </Popconfirm></>}
                                    </div>
                                )}
                            >
                            </List>
                       </Col>
                    }
                    <Col span={12}>
                        <Tabs className="datasets-details-tabs" type="card">
                            <Tabs.TabPane tab={<Text strong>Veri Kümesi Özellikleri</Text>} key="1">
                                { test.metaDataDatasetSpecific.length != 0 ?
                                    <Row justify="space-around" align="middle">
                                        <Col span={10} className="gutter-row" style={{marginRight: "4px", padding: "6px 10px"}} >
                                            <div className="expand-even">
                                                <p>Proje Adı: {test.metaDataDatasetSpecific[0].project_name}</p>
                                                <p>Veri Türü: {this.getMediaType(test.metaDataDatasetSpecific[0].media_type)}</p>
                                                <p>Veri Formatı: {test.metaDataDatasetSpecific[0].data_format}</p>
                                                <p>Veri Miktarı: {test.metaDataDatasetSpecific[0].data_time_length}</p>
                                            </div>
                                        </Col>
                                        <Col span={10} className="gutter-row" style={{marginRight: "4px", padding: "6px 10px"}} >
                                            <div className="expand-even">
                                                <p>Veri Boyutu: {test.metaDataDatasetSpecific[0].data_size}</p>
                                                <p>Veri İçeriği: {test.metaDataDatasetSpecific[0].data_context}</p>
                                                <p>Gizlilik Derecesi: {this.getPrivacy(test.metaDataDatasetSpecific[0].data_privacy)}</p>
                                                <p>Kullanım Durumu: {test.metaDataDatasetSpecific[0].data_usage}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    :
                                    <Empty/>
                                }
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<Text strong>Anahtar-Değer Verisi</Text>} key="2">
                                <Table
                                    className="cvat-metadata-table"
                                    rowClassName={()=> {return "table-metadata-row"}}
                                    rowKey='fileName'  locale={{emptyText: 'Meta Veri Bulunmuyor!'}}
                                    columns={columnsMetaData}
                                    dataSource={test.meta_data}
                                    pagination={false}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        )
    }

    public getPrivacy(value: any){
        switch (value) {
            case 'unclassified':
                return 'Tasnif Dışı';
            case 'private':
                return 'Gizli';
            case 'restricted':
                return 'Hizmete Özel';
            case null:
                return '';
        }
    }

    public getMediaType(value: any){
        switch (value) {
            case 'video':
                return 'Video';
            case 'text':
                return 'Metin';
            case 'other':
                return 'Diğer';
            case 'image':
                return 'Görüntü';
            case null:
                return '';
        }
    }

    /**
     *
     * @param value
     * @param datasetId
     * @description Select dataset provider user
     */
    public handleChangeUserSelector(value: any, datasetId: any) {
        for(let i = 0; i < value.length ; i++){
            value[i] = value[i].split("%%%")[0]
        }

        let usersTotal = {
            "userIds": value
        }
        myMapForUserSelections.set(datasetId, usersTotal);
    }

    /**
     * @async
     * @param id
     * @description set privacy api proxy call
     */
    async setPrivacy(id: any){
        let datasets;
        datasets = await cvat.datasets.setPrivacy(id);
        this.updateList()
    }

    /**
     * @async
     * @param datasetId
     * @param userId
     * @description remove user with userId param from dataset with datasetId param
     */
    public async removeUserFromDataset(datasetId: any, userId: any){
        await cvat.datasets.removeUser(datasetId,userId);
        this.updateList()
    }

    /**
     * @description update dataset list, used after modifications
     */
    public updateList(){
        myMapForUserSelections.clear()
        const testsAsync = this.getDatasets(this.state.currentPage, this.state.datasetViewType);
        testsAsync.then(this.handleTests.bind(self));
    }
    /**
     *
     * @param datasetId
     * @description update user selections for dataset, get user selections from myMapForUserSelections
     */
    public async setUsers(datasetId: any){
        var userSelectionsForRelatedDataset = myMapForUserSelections.get(datasetId)
        if(userSelectionsForRelatedDataset != null)
            await cvat.datasets.setUsers(datasetId,JSON.stringify(userSelectionsForRelatedDataset));
            this.updateList()
    }

    /**
     *
     * @param set
     * @returns Template for displaying dataset name
     */
    nameRender = (set: any) => {
        if (this.props.auth.annotaUser[0].id.groups[0] === 'admin' || (this.props.auth.annotaUser[0].id.groups[0]) === 'provider')
            return (<div>{set.name}
                <Icon onClick={() => this.showEditNameModal(set.id, set.name)} type="edit" /></div>);
        else
            return (<p>{set.name}</p>);

    }

    /**
     *
     * @param id
     * @param name
     * @description sets state for displaying dataset name editing modal
     */
    showEditNameModal = (id: number, name: string) => {
        this.setState({
            editModalVisible: true,
            editDatasetID: id,
            editDatasetName: name,
            editDatasetNewName: "",
        });
    };

    /**
     * @async
     * @description update dataset name api proxy call, sets state for edit database name modal visibility
     */
    handleOk = async () => {
        await cvat.datasets.updateDatasetName(this.state.editDatasetID, this.state.editDatasetNewName);
        this.componentDidMount();
        this.setState({
            editModalVisible: false,
            editDatasetNewName: "",
        });
    };

    /**
     * @description cancel modal
     */
    handleCancel = () => {
        this.setState({
            editModalVisible: false,
            editDatasetNewName: "",
        });
    };

    /**
     * @async
     * @param value
     * @returns datasets
     * @description get datasets api proxy call, sets total test count
     */
    async getDatasets(value: any, datasetViewType: any) {
        let datasets;
        datasets = await cvat.datasets.get(null, 10, value, datasetViewType);
        this.setState({
            totalTestCount: datasets.count
        });
        return datasets;
    }

    /**
     * @async
     * @param datasetId
     * @returns response
     * @description deletes dataset with given id, delete dataset api proxy call
     */
    async deleteDataset(datasetId: any) {
        let response;
        response = await cvat.datasets.deleteDataset(datasetId);
        const testsAsync = this.getDatasets(1, this.state.datasetViewType);
        testsAsync.then(this.handleTests.bind(self));
        this.setState({
            currentPage: 1,
        });
        return response;
    }

    /**
     *
     * @returns dataset list JSX template
     */
    private renderDatasetList() {
        /* const buttonStyle={
            color:"#F2F4F8",
            background: "#0F123F 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #B3B3B329",
            border: "1px solid #707070",
            borderRadius: "8px",
            opacity: "0.75",
        } */

        const buttonStyle={
            color:"#F2F4F8",
            background: "#0F123F 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #B3B3B329",
            border: "1px solid #707070",
            borderRadius: "8px",
            opacity: "0.75",
        }

        const {datasetDownloadProgress} = this.props
        DatasetList = <div style={{paddingTop:"15px"}} className="datasets-page">{this.state.datasetViews}</div>
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('datasets.datasets')}</Text>
                <Modal
                    title = "Veri indiriliyor..."
                    visible={datasetDownloadProgress != '-1' && datasetDownloadProgress != '0'}
                    closable = {false}
                    footer={null}
                >
                    <Spin size='large' className='vk-spinner'/>
                    <Progress style={{marginTop: '20px'}} percent={datasetDownloadProgress} />
                </Modal>
               {/* {(this.props.auth.user.groups.includes('ssbprovider')||this.props.auth.user.groups.includes('admin'))&& <div style={{paddingTop:'15px'}}>
                <Row type="flex" justify="center"  gutter={[48,0]}>
                    <Col>
                        <Button size="large" className="training-test-button"   style={!this.state.onVspPage?{...buttonStyle}:{}} onClick={(e) => { this.setState({onVspPage:false})}}>Veri Kümeleri</Button>
                    </Col>
                    <Col>
                        <Button size="large" className="training-test-button"  style={this.state.onVspPage?{...buttonStyle}:{}} onClick={(e) => { this.setState({onVspPage:true})}}>SSB Veri Kümeleri</Button>
                    </Col>

                </Row>
                </div>}
                {!this.state.onVspPage? */}
                    <div>
                    <Row type="flex" justify="center">
                            <Col>
                                <Button data-tour="step-2" size="large" className="tasks-button"  style={this.state.datasetViewType=="all"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeDatasetViewType("all") }}>{i18n.t('datasets.myDatasets')}</Button>
                            </Col>
                            <Col>
                                <Button data-tour="step-2" size="large" className="tasks-button"  style={this.state.datasetViewType=="shared"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeDatasetViewType("shared") }}>{i18n.t('datasets.sharedDatasets')}</Button>
                            </Col>
                            <Col>
                                <Button data-tour="step-2" size="large" className="tasks-button"  style={this.state.datasetViewType=="sharedWithMe"?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeDatasetViewType("sharedWithMe") }}>{i18n.t('datasets.sharedWithMeDatasets')}</Button>
                            </Col>
                        </Row>
                        {DatasetList}
                        <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                            <Col>
                                <ConfigProvider locale ={trTR}>
                                    <Pagination defaultCurrent={1}
                                            pageSize={10}
                                            onChange={this.handleChange}
                                            current={this.state.currentPage}
                                            total={this.state.totalTestCount}
                                            showQuickJumper={true}
                                    />
                                </ConfigProvider>
                            </Col>
                        </Row>
                        <Modal
                            title={i18n.t('datasets.editModalTitle')}
                            visible={this.state.editModalVisible}
                            onOk={this.handleOk}
                            okText='Tamam'
                            cancelText='İptal'
                            onCancel={this.handleCancel}
                        >
                            <p>{i18n.t('datasets.oldDatasetName')}: {this.state.editDatasetName}</p>
                            <p>{i18n.t('datasets.newDatasetName')}:
                                <input style={{ marginLeft: "1%" }} type="text" className="regular h2" value={this.state.editDatasetNewName}
                                    onChange={(event) => this.setState({ editDatasetNewName: event.target.value })} />
                            </p>
                        </Modal>
                    </div>{/* :
                <VSPDatasetsPage/>
            } */}
            </div>
        )
    }

    changeDatasetViewType = (type) => {
        datasetViews = new Array();
        const tasksAsync = this.getDatasets(this.state.currentPage, type);
        tasksAsync.then(this.handleTests.bind(self));
        this.setState({datasetViewType:type});
    };

    //TODO: pagination for datasets
    handleChange = value => {
        datasetViews = new Array()
        const tasksAsync = this.getDatasets(value, this.state.datasetViewType);
        tasksAsync.then(this.handleTests.bind(self));
        this.setState({
            currentPage: value,
        });
    };

    /**
     *
     * @returns dataset list template by using class functions
     */
    public render() {

        return this.renderDatasetList();
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DatasetsPage));
