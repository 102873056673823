// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';
import Icon from 'antd/lib/icon';
import CanvasWrapperContainer from 'containers/annotation-page/standard-workspace/canvas-wrapper';
import ControlsSideBarContainer from 'containers/annotation-page/standard-workspace/controls-side-bar/controls-side-bar';
import ObjectSideBarContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
import PropagateConfirmContainer from 'containers/annotation-page/standard-workspace/propagate-confirm';
import CanvasContextMenuContainer from 'containers/annotation-page/standard-workspace/canvas-context-menu';
import CanvasPointContextMenuContainer from 'containers/annotation-page/standard-workspace/canvas-point-context-menu';
import Button from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import { ShortcutIcon, GuidelinesIcon, InfoIcon } from 'icons';
import { Modal, Row, Col, Popconfirm, Divider } from 'antd';

import Iframe from 'react-iframe'
import getCore from 'cvat-core-wrapper';
const core = getCore();
const {Content,Sider}=Layout;
interface OwnProps {
    job: any;
    hasGuidelinesFile: boolean;
    showStatistics(): void;
    downloadTaskGuidelines():void;
    showShortcuts():void;
}

/**
 * Create standard workspace component
 *
 * @param props OwnProps
 * @returns Layout
 */
export default function StandardWorkspaceComponent(props: OwnProps): JSX.Element {
    const { t } = useTranslation();
    let jobTask = props.job.task
    let textAnnotationUrl = ''
    try{
        textAnnotationUrl = core.config.doccanoUIRedirectURL + '/projects/' + jobTask.tasksIdAnnota[0].doccanoProjectId + "/" + jobTask.tasksIdAnnota[0].doccanoProjectType + "?username=" + props.job.assignee.username
    }
    catch{
        textAnnotationUrl = core.config.doccanoUIRedirectURL + '/projects/' + jobTask.tasksIdAnnota[0].doccanoProjectId + "/" + jobTask.tasksIdAnnota[0].doccanoProjectType + "?username=" + "none"
    }
    const baseURL = core.config.backendAPI
    let token = localStorage.getItem('token');
    if(token){
        token = token.slice(1).slice(0, token.length-2);
    }
    let hitachiUrl = "";

    if(baseURL.includes("7000")){
        try{
            hitachiUrl = core.config.hitachiURL + "/edit/%2F" + jobTask.dataId  +"%2Fraw%2F" + "0.pcd" + "?token=" + token + "&username=" + props.job.assignee.username + "&jobId=" + props.job.id
        }
        catch{
            hitachiUrl = core.config.hitachiURL + "/edit/%2F" + jobTask.dataId  +"%2Fraw%2F" + "0.pcd" + "?token=" + token + "&username=" + "none" + "&jobId=" + props.job.id
        }
    }else{
        try{
            hitachiUrl = core.config.hitachiURL + "/edit/%2Fdata%2F" + jobTask.dataId  +"%2Fraw%2F" + "0.pcd" + "?token=" + token + "&username=" + props.job.assignee.username + "&jobId=" + props.job.id
        }
        catch{
            hitachiUrl = core.config.hitachiURL + "/edit/%2Fdata%2F" + jobTask.dataId  +"%2Fraw%2F" + "0.pcd" + "?token=" + token + "&username=" + "none" + "&jobId=" + props.job.id
        }
    }

    console.log(hitachiUrl)
    return (
        <Layout hasSider className='cvat-standard-workspace'>

            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) == 0 && jobTask.tasksIdAnnota[0].pointCloudFileName == null) ? <CanvasWrapperContainer /> : null}

            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) == 0 && jobTask.tasksIdAnnota[0].pointCloudFileName == null) ?
                <Layout.Sider
                style={{background: "#F2F4F8",color: "#5A5C69"}}
                zeroWidthTriggerStyle={{background: "#D1D3E2",color: "#5A5C69"}}
                    className='cvat-sider-zero-width-trigger'
                    width={57}
                    collapsible
                    defaultCollapsed
                    collapsedWidth="0"
                >
                   {/*  <Button type='link' className='cvat-annotation-header-button' onClick={props.showShortcuts}>
                        <Icon component={ShortcutIcon} />
                        {t('top-bar.shortcuts')}
                    </Button> */}
                    <Button
                        type='link'
                        className='cvat-annotation-header-button'
                        onClick={(e: React.MouseEvent) => {
                            Modal.info({
                                width: 700,
                                icon: <Icon component={GuidelinesIcon} />,
                                title: t('keywords.guidelines'),
                                style: {whiteSpace: "pre-wrap"},
                                content:(<div>
                                    {props.hasGuidelinesFile &&
                                        <Row gutter={[8, 8]}>
                                            <Col>
                                            <Popconfirm
                                                title= "Veriyi indirmek istediğinize emin misiniz?"
                                                cancelText={t('keywords.no')}
                                                okText={t('keywords.yes')}
                                                onConfirm={
                                                    (e) => props.downloadTaskGuidelines()
                                                }
                                            >
                                                <Button style={{marginTop:"5px"}} type="default" ghost size="small">
                                                <Icon type="file-pdf"/>
                                                {t('top-bar.downloadGuidelines')}
                                                </Button>
                                            </Popconfirm>
                                            </Col>
                                        </Row>
                                    }
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            <Divider/>
                                            <text>{props.job.task.guidelines}</text>
                                        </Col>
                                    </Row>
                                    </div>
                                ),
                            });
                        }}
                    >
                        <Icon component={GuidelinesIcon} />
                        {t('keywords.guidelines')}
                    </Button>
                    <Button type='link' className='cvat-annotation-header-button' onClick={props.showStatistics}>
                        <Icon component={InfoIcon} />
                        {t('top-bar.statistics')}
                    </Button>
                </Layout.Sider>
                : null}

            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) == 0 && jobTask.tasksIdAnnota[0].pointCloudFileName == null) ? <ControlsSideBarContainer /> : null}
            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) != 0 && jobTask.tasksIdAnnota[0].pointCloudFileName == null) ?
            <div data-tour="step-canvas" style={{display:"contents"}}>
                    <Iframe
                    url={textAnnotationUrl}
                    id="myId"
                    width= "100%"
                    position="relative"
                    />
                    <Layout>
                    <Content/>
                <Sider style={{background:"#F2F4F8",color: "#5A5C69"}}
                className="sider-text-iframe"
                    //className='cvat-sider-zero-width-trigger'
                    width={57}
                    collapsible
                    defaultCollapsed={true}
                    collapsedWidth={0}
                >
                    {/* <Button type='link' className='cvat-annotation-header-button' onClick={props.showShortcuts}>
                        <Icon component={ShortcutIcon} />
                        {t('top-bar.shortcuts')}
                    </Button> */}
                    <Button
                        type='link'
                        className='cvat-annotation-header-button'
                        onClick={(e: React.MouseEvent) => {
                            Modal.info({
                                width: 700,
                                icon: <Icon component={GuidelinesIcon} />,
                                title: t('keywords.guidelines'),
                                style: {whiteSpace: "pre-wrap"},
                                content:(<div>
                                    {props.hasGuidelinesFile &&
                                        <Row gutter={[8, 8]}>
                                            <Col>
                                            <Popconfirm
                                                title= "Veriyi indirmek istediğinize emin misiniz?"
                                                cancelText={t('keywords.no')}
                                                okText={t('keywords.yes')}
                                                onConfirm={
                                                    (e) => props.downloadTaskGuidelines()
                                                }
                                            >
                                                <Button style={{marginTop:"5px"}} type="default" ghost size="small">
                                                <Icon type="file-pdf"/>
                                                {t('top-bar.downloadGuidelines')}
                                                </Button>
                                            </Popconfirm>
                                            </Col>
                                        </Row>
                                    }
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            <Divider/>
                                            <text>{props.job.task.guidelines}</text>
                                        </Col>
                                    </Row>
                                    </div>
                                ),
                            });
                        }}
                    >
                        <Icon component={GuidelinesIcon} />
                        {t('keywords.guidelines')}
                    </Button>
                    <Button type='link' className='cvat-annotation-header-button' onClick={props.showStatistics}>
                        <Icon component={InfoIcon} />
                        {t('top-bar.statistics')}
                    </Button>
                </Sider>

                </Layout></div>
            : null}

            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) == 0 && jobTask.tasksIdAnnota[0].pointCloudFileName != null) ?
                <Iframe
                url={hitachiUrl}
                id="myId"
                width= "100%"
                position="relative"
                frameBorder={0}/>
            : null}

            {(Number(jobTask.tasksIdAnnota[0].doccanoProjectId) == 0 && jobTask.tasksIdAnnota[0].pointCloudFileName == null) ?<ObjectSideBarContainer /> : null}
            <PropagateConfirmContainer />
            <CanvasContextMenuContainer />
            <CanvasPointContextMenuContainer />
        </Layout>
    );
}
