import React from 'react';

import {
    Input,
} from 'antd';

import Form, { FormComponentProps } from 'antd/lib/form/Form';
import i18n from '../../i18n'

export interface BaseConfiguration {
    name: string;
}

type Props = FormComponentProps & {
    onSubmit(values: BaseConfiguration): void;
};

class BasicConfigurationForm extends React.PureComponent<Props> {

    /**
     * Handle submiting form
     *
     * @returns Promise
     */
    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                form,
                onSubmit,
            } = this.props;

            form.validateFields((error, values): void => {
                if (!error) {
                    onSubmit({
                        name: values.name,
                    });
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Reset form fields
     */
    public resetFields(): void {
        const { form } = this.props;
        form.resetFields();
    }

    /**
     * Render form
     *
     * @returns Form
     */
    public render(): JSX.Element {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form onSubmit={(e: React.FormEvent): void => e.preventDefault()}>
                <Form.Item hasFeedback label={<span className="cvat-text-color">{i18n.t('keywords.name')}</span>}>
                    { getFieldDecorator('name', {
                        rules: [{
                            required: true,
                            message: i18n.t('messages.name'),
                        }],
                    })(
                        <Input  className="input-create-task"
                            data-tour="step-dataset-name"
                        />,
                    ) }
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create<Props>()(BasicConfigurationForm);
