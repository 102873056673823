import React from 'react';
import { connect } from 'react-redux';

import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import DatasetFileManagerComponent, { DatasetFiles } from 'components/file-manager/dataset-file-manager';
import { loadShareDataAsync } from 'actions/share-actions';
import {
    ShareItem,
    CombinedState,
} from 'reducers/interfaces';

interface OwnProps {
    ref: any;
    withRemote: boolean;
    parentMethod: void;
    getFileType: (type: any) => void;
}

interface StateToProps {
    treeData: TreeNodeNormal[];
}

interface DispatchToProps {
    getTreeData(key: string, success: () => void, failure: () => void): void;
}

/**
 *
 * @param state -> combined state containing treeData root information
 * @returns treeData information
 * @description Defines convert function converting items and path to TreeNodeNormal then returns treeData by using convert function
 */
function mapStateToProps(state: CombinedState): StateToProps {
    /**
     *
     * @param items
     * @param path
     * @returns tree representation of items
     */
    function convert(items: ShareItem[], path?: string): TreeNodeNormal[] {
        return items.map((item): TreeNodeNormal => {
            const isLeaf = item.type !== 'DIR';
            const key = `${path}${item.name}${isLeaf ? '' : '/'}`;
            return {
                key,
                isLeaf,
                title: item.name || 'root',
                children: convert(item.children, key),
            };
        });
    }

    const { root } = state.share;
    return {
        treeData: convert([root], ''),
    };
}

/**
 *
 * @param dispatch
 * @returns getTreeData action dispatching function
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getTreeData: (key: string, success: () => void, failure: () => void): void => {
            dispatch(loadShareDataAsync(key, success, failure));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;

/**
 * @class Render method returns DatasetFileManagerComponent
 * @description Passes props to DatasetFileManagerComponent, defines getFiles and reset functions
 */
export class DatasetFileManagerContainer extends React.PureComponent<Props> {
    private managerComponentRef: any;

    public getFiles(): DatasetFiles {
        return this.managerComponentRef.getFiles();
    }

    public reset(): DatasetFiles {
        return this.managerComponentRef.reset();
    }

    public render(): JSX.Element {
        const {
            treeData,
            getTreeData,
            withRemote,
            parentMethod,
            getFileType
        } = this.props;

        return (
            <DatasetFileManagerComponent
                treeData={treeData}
                onLoadData={getTreeData}
                withRemote={withRemote}
                ref={(component): void => {
                    this.managerComponentRef = component;
                }}
                getFileType = {getFileType}
                parentMethod = {parentMethod}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(DatasetFileManagerContainer);
