import React from 'react';
import { connect } from 'react-redux';

import { CombinedState } from 'reducers/interfaces';
import ModifyDatasetPage from 'components/modify-dataset-page/modify-dataset-page';
import { ModifyDatasetData } from 'components/modify-dataset-page/modify-dataset-content';
import { modifyDatasetAsync } from 'actions/tasks-actions';

interface StateToProps {
    status: string;
    installedGit: boolean;
    user:any;
}

interface DispatchToProps {
    onCreate: (data: ModifyDatasetData) => void;
}

/**
 *
 * @param dispatch
 * @returns onCreate action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onCreate: (data: ModifyDatasetData): void => dispatch(modifyDatasetAsync(data)),
    };
}

/**
 *
 * @param state -> combined state containing models
 * @returns creates task activity, git integration and user information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    const { creates } = state.tasks.activities;
    return {
        ...creates,
        installedGit: state.plugins.list.GIT_INTEGRATION,
        user:state.auth.user
    };
}

/**
 *
 * @param props
 * @returns ModifyDatasetPage component
 * @description Passes props to ModifyDatasetPage component
 */
function ModifyDatasetPageContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <ModifyDatasetPage {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModifyDatasetPageContainer);
