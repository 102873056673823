// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';

import { FitIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { useTranslation } from 'react-i18next';

interface Props {
    canvasInstance: Canvas;
}

/**
 * Control labeling data to fit canvar
 *
 * @param props consists of canvasInstance
 * @returns Icon within tooptip
 */
function FitControl(props: Props): JSX.Element {
    const {
        canvasInstance,
    } = props;
    const { t } = useTranslation();
    return (
        <Tooltip title={`${t('standard-workspace.fitTheImage')}`} placement='right'>
            <Icon component={FitIcon} onClick={(): void => canvasInstance.fit()} />
        </Tooltip>
    );
}

export default React.memo(FitControl);
