import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Input } from 'antd';
import 'antd/dist/antd.css';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { exportDatasetAsync } from 'actions/tasks-actions';
import { recordKeyCombination } from 'react-hotkeys';
import { array } from 'prop-types';
import UserJobsStatisticsPage from 'containers/user-job-statistics'

const cvat = getCore();
const { Option } = Select;
let UserList = <div></div>
let userTable = new Array();

/**
 * @class_component PaymentPageUsers component
 */
class PaymentPageUsers extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            filter: "all",
            currentPage: 1,
            totalRelatedUserCount: 0,
            clickedOption: ""
        }
        this.handleOptChange = this.handleOptChange.bind(this);
        const usersAsync = this.getUsers(this.state.filter, 1);
    }

    /**
     * @async
     * @param users
     * @description set userTable template for payments page
     */
    //expandedRowRender={(record) => <>{this.handleExpandStatistics(record.id)} </>}
    async handleUsers(users: any[]) {
        userTable = new Array();
        userTable.push(
            <Table rowKey={record => record.id.id} locale={{emptyText: 'Sonuç Bulunamadı'}}
                dataSource={users} pagination={false}>

                <Column title={i18n.t('admin-page.userName')} dataIndex="id.username" key="id.username" />
                <Column title={i18n.t('admin-page.name')} dataIndex="id.first_name" key="id.first_name" />
                <Column title={i18n.t('admin-page.lastName')} dataIndex="id.last_name" key="id.last_name" />
                <Column title={i18n.t('admin-page.userState')} render={(record) => <> {this.getStatus(record.state)} </>} key="state" />
                {/* <Column title={i18n.t('admin-page.userLevel')} render={(record) => <> {this.getStatus(record.level)}</>} key="level" />
                <Column title={i18n.t('admin-page.userExpert')} render={(record) => <> {this.getStatus(record.expert)}</>} key="expert" />
                <Column title={i18n.t('admin-page.actions')} key="action" render={(record) =>
                    <div>
                        {this.showUserSetOptionsTable(record)}
                        <Button id={record.id.username + "Apply"} type="primary" onClick={() => this.handleUser(record.id.id, this.state.clickedOption)}>Uygula</Button>
                    </div>
                }
                /> */}
                <Column title="IBAN" render={(record) => <> {record.iban} </>} key="iban" />
                <Column title="Ödeme Talebi" render={(record) => <> {this.getStatusPayment(record.payment_requested)} </>} key="payment_requested" />
            </Table>
        );

        this.setState({
            state: this.state
        });
    }

    /**
     *
     * @param record
     * @returns statistics expansion template for record
     */
    public handleExpandStatistics(record: any){
        return(
            <div style = {{marginLeft: "0%"}}>
                <UserJobsStatisticsPage user={record}></UserJobsStatisticsPage>
            </div>
        )
    }
    private getStatusPayment(state: Boolean) {
        switch (state) {
            case false:
                return 'Yok';
            case true:
                return 'Var';
            case null:
                return '';
        }
    }

    /**
     * @deprecated is not used anymore
     * @param user
     * @returns per user expansion template
     */
    private handleExpand(user: any) {
        let JSONUserEducation = JSON.parse(user.education);
        return (
            <div>
                <Divider orientation="left">{i18n.t('admin-page.userInfo')}</Divider>
                <Row justify="space-around" align="middle">
                    <Col span={8} className="gutter-row" style={{marginRight: "4px", padding: "6px 10px", backgroundColor: "#f0f2f5", borderRadius: "8px" }} >
                        <div className="expand-even">
                            <p>{i18n.t('admin-page.email')}: {user.id.email}</p>
                            { (user.dateOfBirth != null) ? <p>{i18n.t('admin-page.birthDate')}: {user.dateOfBirth.toString().substring(0, 10)}</p> : null}
                            <p>{i18n.t('admin-page.phoneNumber')}: {user.phoneNumber}</p>
                            <p>{i18n.t('admin-page.city')}: {user.city}</p>
                            <p>{i18n.t('admin-page.town')}: {user.town}</p>
                            <p>{i18n.t('admin-page.address')}: {user.address}</p>
                            <p>{i18n.t('admin-page.postCode')}: {user.postCode}</p>
                        </div>
                    </Col>
                    <Col span={8} className="gutter-row" style={{ marginRight: "4px", padding: "6px 10px", backgroundColor: "#f0f2f5", borderRadius:"8px" }}>
                        <p>{i18n.t('admin-page.workingState')}: {(user.isWorking == "Working") ? "Çalışıyor" : (user.isWorking == "Student") ? "Öğrenci" : "Mezun"}</p>
                        <p>{i18n.t('admin-page.company')}: {user.workPlace}</p>
                        <p>Temel Bilgisayar Kullanım Becerileri: {user.computerExperience}</p>
                        <p>Sertifika bilgisi: {user.certificates}</p>
                        <p>Pogramlama Dilleri: {user.programmingLanguages}</p>
                        <p>Yapay Zeka ve Etiketleme Konularında Deneyim: {user.aiAndAnnotationExperience}</p>
                        <p>Diğer deneyimler: {user.otherExperience}</p>
                    </Col>
                    <Col span={6} className="gutter-row" style={{ marginRight: "4px", padding: "6px 10px", backgroundColor: "#f0f2f5", borderRadius:"8px" }} >
                        <b>{i18n.t('admin-page.educationInfo')}</b>
                        {(JSONUserEducation != null && JSONUserEducation.highSchool.length != 0) ? <p>{i18n.t('admin-page.highSchool')}</p> : null}
                        {(JSONUserEducation != null && JSONUserEducation.highSchool.length != 0) ? this.renderEducationFiled(JSONUserEducation.highSchool) : null}
                        {(JSONUserEducation != null && JSONUserEducation.associate.length != 0) ? <p>{i18n.t('admin-page.associate')}</p> : null}
                        {(JSONUserEducation != null && JSONUserEducation.associate.length != 0) ? this.renderEducationFiled(JSONUserEducation.associate) : null}
                        {(JSONUserEducation != null && JSONUserEducation.bachelor.length != 0) ? <p>{i18n.t('admin-page.bachelor')}</p> : null}
                        {(JSONUserEducation != null && JSONUserEducation.bachelor.length != 0) ? this.renderEducationFiled(JSONUserEducation.bachelor) : null}
                        {(JSONUserEducation != null && JSONUserEducation.master.length != 0) ? <p>{i18n.t('admin-page.master')}</p> : null}
                        {(JSONUserEducation != null && JSONUserEducation.master.length != 0) ? this.renderEducationFiled(JSONUserEducation.master) : null}
                        {(JSONUserEducation != null && JSONUserEducation.phd.length != 0) ? <p>{i18n.t('admin-page.phd')}</p> : null}
                        {(JSONUserEducation != null && JSONUserEducation.phd.length != 0) ? this.renderEducationFiled(JSONUserEducation.phd) : null}
                    </Col>
                </Row>
            </div>
        );
    }

    /**
     *
     * @param schoolList
     * @returns school view template for school list
     */
    private renderEducationFiled(schoolList: any): JSX.Element {
        let schoolViews = new Array();
        let schoolsTaken = schoolList
        for (let i = 0; i < schoolsTaken.length; i++) {
            schoolViews.push(
                <ul class="list-unstyled" key={schoolsTaken[i].id} >
                    <Row gutter={8} >
                        <Col span={8}>
                            <p>{i18n.t('admin-page.schoolName')}: {schoolsTaken[i].schoolName}</p>
                        </Col>
                        <Col span={8}>
                            <p>{i18n.t('admin-page.department')}: {schoolsTaken[i].department}</p>
                        </Col>
                    </Row>
                </ul>
            )
        }
        return (
            <div>
                {schoolViews}
            </div>
        );
    }

    /**
     * @deprecated is not uset anymore
     * @param user
     * @returns some option template
     */
    public showUserSetOptionsTable(user: any) {

        let optItems = new Array();

        if (user.canLogin == 1) {
            optItems.push(<Option value="take"> Giriş İzni Kaldır</Option>);
        }
        else
            optItems.push(<Option value="give"> Giriş İzni Ver  </Option>);

        if (user.id.groups.includes('provider'))
            optItems.push(<Option value="takeDP">Veri Sağlayıcı Kaldır</Option>);
        else
            optItems.push(<Option value="makeDP">Veri Sağlayıcı Yap</Option>);

        if (!(user.id.groups.includes('annotator') || (user.id.groups.includes('candidate'))))
            optItems.push(<Option value="makeAnnotator">Etiketleyici Yap</Option>);
        else
            optItems.push(<Option value="takeAnnotator">Etiketleyici Kaldır</Option>);


            if (user.isBlocked == 1) { //user blocked
            optItems.push(<Option value="unblock" >{i18n.t('admin-page.unblock')}</Option>);
        } else { //user not blocked
            optItems.push(<Option value="block">{i18n.t('admin-page.block')}</Option>);
            if (user.state == "pending" && user.isBlocked == 0) {
                optItems.push(<Option value="approve">{i18n.t('admin-page.approve')}</Option>);
                optItems.push(<Option value="reject">{i18n.t('admin-page.reject')} </Option>);
            }
            else if (user.state == "rejected") {
                optItems.push(<Option value="forgive">{i18n.t('admin-page.forgive')}</Option>);
            } else if (user.state == "approved") {
                optItems.push(<Option value="promote">{i18n.t('admin-page.promote')}</Option>);
                optItems.push(<Option value="demote">{i18n.t('admin-page.demote')}</Option>)
                optItems.push(<Option value="bless">{i18n.t('admin-page.bless')}</Option>);
                optItems.push(<Option value="curse">{i18n.t('admin-page.curse')}</Option>);
            }
        }
        let selectMenu = (
            <Select id={user.id.username + "Dropdown"} defaultValue={i18n.t('admin-page.actions')} style={{ width: 200 }} onChange={this.handleOptChange} >
                {optItems}
            </Select>
        )
        return selectMenu;
    }

    /**
     * @deprecated is not used anymore
     * @param userId
     * @param action
     */
    private async handleUser(userId: any, action: any) {
        let response = null;
        switch (action) {
            case ("approve"): {
                response = await cvat.users.approve(Number(userId));
                break;
            }
            case ("reject"): {
                response = await cvat.users.reject(Number(userId));
                break;
            }
            case ("forgive"): {
                response = await cvat.users.forgive(Number(userId));
                break;
            }
            case ("demote"):{
                response = await cvat.users.demote(Number(userId));
                break;
            }
            case ("promote"): {
                response = await cvat.users.promote(Number(userId));
                break;
            }
            case ("bless"): {
                response = await cvat.users.bless(Number(userId));
                break;
            }
            case ("curse"): {
                response = await cvat.users.curse(Number(userId));
                break;
            }
            case ("block"): {
                response = await cvat.users.block(Number(userId));
                break;
            }
            case ("unblock"): {
                response = await cvat.users.unblock(Number(userId));
                break;
            }
            case ("give"): {
                response = await cvat.users.give(Number(userId));
                break;
            }
            case ("take"): {
                response = await cvat.users.take(Number(userId));
                break;
            }
            case ("makeDP"): {
                response = await cvat.users.makeDP(Number(userId));
                break;
            }
            case ("takeDP"): {
                response = await cvat.users.takeDP(Number(userId));
                break;
            }
            case ("makeAnnotator"):{
                response = await cvat.users.makeAnnotator(Number(userId));
                break;
            }
            case ("takeAnnotator"):{
                response = await cvat.users.takeAnnotator(Number(userId));
                break;
            }
        }
        if (response.data.status != i18n.t('response-messages.success')) {
            Modal.error({
                content: response.data.status
            })
        }
        else {
            Modal.success({
                content: "Başarılı!" // action.toUpperCase() + " " + response.data.status
            })
        }

        const usersAsync = this.getUsers(this.state.filter, this.state.currentPage);
        userTable = new Array();
    }

    /**
     * @async
     * @param filter
     * @param pageNum
     * @returns users
     * @description setState for users count, get users async api proxy call with given filter and pageNum, handleUsers
     */
    async getUsers(filter: any, pageNum: any) {
        let users;
        if (filter == "all")
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null);
        else if (filter == "approval")
            users = await cvat.users.getAnnotaUsers(null, pageNum, 'pending', null, null, "False");
        else if (filter == "blocked")
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, "True");
        else if (filter == "rejected")
            users = await cvat.users.getAnnotaUsers(null, pageNum, 'rejected', null, null);
        else if (filter == "approved")
            users = await cvat.users.getAnnotaUsers(null, pageNum, 'approved', null, null, "False");
        else if (typeof filter == "object" && ("search" in filter))
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, null, null, null, filter);

        let usersArr = this.filterOutSuperUser(users.users);
        this.setState({
            totalRelatedUserCount: users.count,
        });

        this.handleUsers(usersArr)
        return users;
    }

    /**
     *
     * @param users
     * @returns usersArray without super users
     */
    private filterOutSuperUser(users: any) {
        let usersArr = new Array;
        for (let i = 0; i < users.length; i++) {
            if (!users[i].id.is_superuser)
                usersArr.push(users[i]);
        }
        return usersArr;
    }

    /**
     *
     * @returns users list JSX template
     */
    private renderUserList() {
        UserList = <div>{userTable}</div>;

        return (
            <div className='approval-page'>
                <h1 style={{marginTop:'1%'}}>{i18n.t('admin-page.users')}</h1>
                <Select style={{ width: '10%', margin: "5px 3px" }} defaultValue={i18n.t('admin-page.all')} onChange={(value) => this.setFilter(value)}>
                    <Option value="all">{i18n.t('admin-page.all')}</Option>
                    <Option value="approval">{i18n.t('admin-page.approval')}</Option>
                    <Option value="blocked">{i18n.t('admin-page.blocked')}</Option>
                    <Option value="rejected">{i18n.t('admin-page.rejected')}</Option>
                    <Option value="approved">{i18n.t('admin-page.approved')}</Option>
                </Select>
                <Col md={5} lg={4} xl={3} xxl={2}>
                    <Input.Search
                        onSearch={this.setSearchFilter.bind(this)}
                        size='large'
                        placeholder={i18n.t('keywords.search')}
                    />
                </Col>
                {UserList}
                <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                <Col>
                    <Pagination
                        defaultCurrent= {1}
                        pageSize= {10}
                        onChange= {this.handleChange}
                        current={this.state.currentPage}
                        total={ this.state.totalRelatedUserCount}
                    />
                </Col>
            </Row>
            </div>
        );
    }

    /**
     * @async
     * @param takenFilter
     * @description sets new filter on state, clears userTable array
     */
    public async setFilter(takenFilter: any) {
        this.setState({
            filter: takenFilter,
            currentPage: 1
        });
        const usersAsync = this.getUsers(takenFilter, 1);
        userTable = new Array();
    }

    /**
     * @async
     * @param searchString
     * @returns void
     * @description set searchString on state, clears userTable array
     */
    public async setSearchFilter(searchString: any) {
        if(searchString === ""){
            this.setFilter("all");
            return;
        }
        this.setState({
            filter: {"search": searchString},
            currentPage: 1
        });
        const usersAsync = this.getUsers({"search": searchString}, 1);
        userTable = new Array();
    }

    /**
     *
     * @param value
     * @description sets state for current page on page change, clears out user array
     */
    handleChange = value => {
        userTable = new Array()
        const tasksAsync = this.getUsers(this.state.filter, value);
        this.setState({
            currentPage: value,
        });
    };

    /**
     *
     * @param value
     * @description sets state on option change
     */
    handleOptChange = value => {
        this.setState({ clickedOption: value });
    };

    private getStatus(state: String) {
        switch (state) {
            case 'initial':
                return 'Aday Etiketleyici';
            case 'pending':
                return 'Onay Bekliyor';
            case 'approved':
                return 'Onaylı';
            case null:
                return '';
            case 'freshman':
                return '1';
            case 'sophomore':
                return '2';
            case 'junior':
                return '3';
            case 'senior':
                return '4';
            case 'master':
                return '5';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    /**
     *
     * @returns payment page user list template
     */
    public render() {
        return this.renderUserList();
    }
}

export default withRouter(connect(
)(PaymentPageUsers));
