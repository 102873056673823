import '../styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Icon, Spin, Collapse, Divider, Table, ConfigProvider, Input, DatePicker, Card, Modal, Select } from 'antd';
import Column from 'antd/lib/table/Column';
import i18n from '../i18n';
import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import getCore from '../cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';

import UserSelectorDashboard from './dashboard-page/user-selector-dashboard';
import trTR from 'antd/es/locale/tr_TR';
const dateFormat = ['YYYY-MM-DD HH:MM'];
const { Option } = Select;

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns auth properties
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type LogsPageProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

let LogList = <div></div>
let logViews = new Array();

/**
 * @class_component LogsPageComponent
 */
class LogsPageComponent extends React.PureComponent<LogsPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            logsTable: [],
            logsCount: 0,
            currentPage: 1,
            pageSize: 20,
            numRecords: 250,
            level: "ERROR",
            startDate: null,
            endDate: null,
            userId: null,
            logins:[],
            falseLogins:[],
            activeUsers:[],
            taskCreations:[],
            errors:[],
            errorsModalVisible: false,
            jobLogs: {}
        }
        this.getLogStats = this.getLogStats.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.getSelectedUser = this.getSelectedUser.bind(this);
        this.setErrorsModalInvisible = this.setErrorsModalInvisible.bind(this);
        this.setNumRecords = this.setNumRecords.bind(this);
        this.setLevel = this.setLevel.bind(this);
    }

    public componentDidMount(): void {
        // this.handleLogs = this.handleLogs.bind(this);
        // const logsAsync = this.getLogList(1, this.state.startDate, this.state.endDate, this.state.userId);
        // logsAsync.then(this.handleLogs.bind(self));

        this.getJobLogs();
        this.getLogStats();
    }

    /**
     * @description get job logs api proxy call, set state
     */
    private async getJobLogs() {
        let _jobLogs = await cvat.server.getJobLogs()

        this.setState({
            jobLogs: _jobLogs
        })

    }

    /**
     *
     * @param pageNum
     * @param startDate
     * @param endDate
     * @param userId
     * @returns logs.result
     * @description get system log list, filter with start and end date, user id, page num
     */
    async getLogList(pageNum: any, startDate: any, endDate: any, userId: any) {
        let logs;
        logs = await cvat.server.getSystemLogList(startDate, endDate, userId, pageNum);
        this.setState({
            logsCount: logs.count
        });
        return logs.result;
    }

    /**
     * @description get system logs statistics api proxy call
     */
    private getLogStats(){
        let promise = cvat.server.getSystemLogStats(this.state.startDate, this.state.endDate, this.state.numRecords, this.state.level);
        promise.then(
            stats => {
                this.setState({
                    logins: stats.logins,
                    activeUsers: stats.activeUsers,
                    taskCreations: stats.taskCreations,
                    errors: stats.errors,
                    falseLogins: stats.falseLogins
                })
            }
        )
    }

    /**
     * @async
     * @param logs
     * @description set logsView template on state
     */
    async handleLogs(logs: any[]) {
        let logsView = new Array();

        logsView.push(
            <Table rowKey={record => record.id}
                dataSource={logs}
                pagination={false}
                locale={{emptyText: 'Sonuç Bulunamadı'}}
                >
                <Column title="type" dataIndex="type" />
                <Column title="timestamp" dataIndex="timestamp" />
                <Column title= "username" dataIndex="username" />
                <Column title= "event" dataIndex="event" />
                <Column title= "taskid" dataIndex="taskid" />
                <Column title= "jobid" dataIndex="jobid" />

            </Table>
        );
        this.setState({
            logsTable: logsView
        });
    }

    /**
     * @deprecated is not used anymore
     */
    public renderLoader() {
        return <Spin style={{ margin: '0 50%' }} />
    }

    public handleStartDateChange(date:any, datestring:any) {
        this.setState({ startDate: datestring})
    }

    public handleEndDateChange(date:any, datestring:any) {
        this.setState({ endDate: datestring})
    }

    public getSelectedUser(user: any) {
        this.setState({
            userId: user,
        });
    }

    /**
     * @deprecated is not used anymore
     * @returns Log list template
     */
    private renderLogList() {
        LogList = <div>{this.state.logsTable}</div>
        return (
            <div className='approval-page'>
                <h1 style={{marginTop:'1%'}}>logs</h1>
                {LogList}
                <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                    <Col>
                        <ConfigProvider locale ={trTR}>
                            <Pagination defaultCurrent={1}
                                    pageSize={this.state.pageSize}
                                    onChange={this.handlePageChange}
                                    current={this.state.currentPage}
                                    total={this.state.logsCount}
                                    showQuickJumper={true}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </div>
        )
    }

    /**
     * @deprecated is not used anymore
     * @param value
     * @description page change event handler
     */
    handlePageChange = value => {
        logViews = new Array()
        const logsAsync = this.getLogList(value, this.state.startDate, this.state.endDate, this.state.userId);
        logsAsync.then(this.handleLogs.bind(self));
        this.setState({
            currentPage: value,
        });
    };

    public setErrorsModalInvisible() {
        this.setState({
            errorsModalVisible: false,
        });
    }

    public setNumRecords(value: any) {
        this.setState({
            numRecords: value,
        });
    }

    public setLevel(value: any) {
        this.setState({
            level: value,
        });
    }

    /**
     *
     * @returns Login card template
     */
    renderLoginsCard(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}} title="Sisteme Girişler">
                <Table
                    dataSource={this.state.logins}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250']}}
                >
                    <Column title="Kullanıcı" dataIndex="username" key="username"  />
                    <Column title="Giriş Zamanı" dataIndex="loginTime" key="loginTime" />
                </Table>
            </Card>
        )
    }

    /**
     *
     * @returns False Login List Card Template
     */
    renderFalseLoginsCard(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}  title="Hatalı Girişler">
                <Table
                    dataSource={this.state.falseLogins}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250']}}
                >
                    <Column title="Kullanıcı Hesabı" dataIndex="username" key="username"  />
                    <Column title="Deneme Zamanı" dataIndex="loginTime" key="loginTime" />
                </Table>
            </Card>
        )
    }

    /**
     *
     * @returns Active Users Card Template
     */
    renderActiveUsersCard(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}  title="Son İşlemler">
                <Table
                    dataSource={this.state.activeUsers}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250']}}
                >
                    <Column title="Kullanıcı" dataIndex="username" key="username"  />
                    <Column title="İşlem Sayısı" dataIndex="numActivities" key="numActivities" />
                </Table>
            </Card>
        )
    }

    /**
     *
     * @returns Task Creation Card Template
     */
    renderTaskCreationsCard(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}  title="Oluşturulan Görevler">
                <Table
                    dataSource={this.state.taskCreations}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250']}}
                >
                    <Column title="Görev" dataIndex="taskId" key="taskId"  />
                    <Column title="Oluşturulma Zamanı" dataIndex="creationTime" key="creationTime" />
                </Table>
            </Card>
        )
    }

    /**
     *
     * @returns Errors Card Template
     */
    renderErrorsCard(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}}  title="Sistem Mesajları">
                <Table
                    dataSource={this.state.errors}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250']}}
                >
                    <Column title="Mesaj" dataIndex="message" key="message"  />
                    <Column title="Oluşma Zamanı" dataIndex="creationTime" key="creationTime" />
                    {/* <Column render={(record)=>
                            <Button disabled = {record.stackTrace.length == 0} size="small" type="link" style={{color:"#6C63FF"}} onClick={()=>{this.setState({errorsModalVisible:true, stackTrace:record.stackTrace})}} >Detaylar</Button>
                        }/> */}
                </Table>
            </Card>
        )
    }

    /**
     *
     * @returns Jobs Card Template
     */
    renderJobsCard() {
        return (
            <Card style={{ borderRadius: "8px" }} headStyle={{ background: "#F2F4F8", color: "#0F123F", fontWeight: 600 }} title="İş Kuyrukları">
                <Table
                    dataSource={this.state.jobLogs.workers}
                    size="small"
                    scroll={{ y: 200 }}
                    pagination={{ defaultPageSize: 50, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['50', '100', '250'] }}
                >
                    <Column title="İşçi Adı" dataIndex="name" key="name" />
                    <Column title="Durum" dataIndex="state" key="state" />
                    <Column title="Başarılı" dataIndex="successful_jobs" key="successful_jobs" />
                    <Column title="Başarısız" dataIndex="failed_jobs" key="failed_jobs" />
                    <Column title="Toplam Çalışma Süresi (s)" dataIndex="total_working_time" key="total_working_time" />
                </Table>
            </Card >
        )
    }

    /**
     *
     * @returns Errors Modal Template
     */
    renderErrorsModal() {
        return (
            <Modal
                title="Detaylar"
                visible={this.state.errorsModalVisible}
                onCancel={this.setErrorsModalInvisible}
                footer={<Button type="primary" onClick={this.setErrorsModalInvisible}> Ok </Button>}
                destroyOnClose={true}
                >
                <p> {this.state.stackTrace}</p>

            </Modal>
        )
    }

    /**
     *
     * @returns Logs page template
     */
    render() {
        return (
            <div className="approval-page">
                <Text className="cvat-title">Sistem Logları</Text>

                <ConfigProvider locale ={trTR}>
                    <div style={{marginTop:"15px"}}>
                        <Text>Filtrele: </Text>

                        <Select placeholder='Kayıt Sayısı' style={{ width: 150, margin: "5px 3px"}} onChange={this.setNumRecords}>
                                <Option value="250">250</Option>
                                <Option value="500">500</Option>
                                <Option value="1000">1000</Option>
                        </Select>

                        <Select placeholder='Seviye' style={{ width: 150, margin: "5px 3px"}} onChange={this.setLevel}>
                                <Option value="INFO">Bilgi</Option>
                                <Option value="ERROR">Hata</Option>
                                <Option value="EXCEPTION">Kritik</Option>
                        </Select>
                        <DatePicker style={{marginLeft:"5px"}}format = {dateFormat}
                            placeholder='Başlangıç Tarihi' onChange={this.handleStartDateChange}
                        />
                        <DatePicker style={{marginLeft:"5px"}} format = {dateFormat}
                            placeholder='Bitiş Tarihi' onChange={this.handleEndDateChange}
                        />

                        <Button style={{marginLeft:"5px"}} className="button-default-groups-page" onClick={this.getLogStats} > {i18n.t('dashboard-page.filter')} </Button>
                    </div>
                    <Row style={{marginTop:"20px"}} type="flex" justify="space-between" gutter={[32,32]}>
                        <Col span={8}>
                            {this.renderLoginsCard()}
                        </Col>
                        <Col span={8}>
                            {this.renderActiveUsersCard()}
                        </Col>
                        <Col span={8}>
                            {this.renderTaskCreationsCard()}
                        </Col>
                        {this.renderErrorsModal()}
                    </Row>

                    <Row type="flex" justify="space-between" gutter={[32,32]}>
                        <Col span={8}>
                        {this.renderFalseLoginsCard()}
                        </Col>
                        <Col span={16}>
                            {this.renderJobsCard()}
                        </Col>
                    </Row>

                    <Row type="flex" justify="space-between" gutter={[32, 32]}>
                        <Col span={24}>
                        {this.renderErrorsCard()}
                        </Col>
                    </Row>

                </ConfigProvider>
                {/* <Row type='flex' justify='center' align='middle'>
                    <Col className='cvat-tasks-list' md={22} lg={18} xl={16} xxl={14}>
                        {this.renderLogList()}
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(LogsPageComponent));
