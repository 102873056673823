// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';
import { useTranslation } from 'react-i18next';

import { ZoomIcon } from 'icons';
import { ActiveControl } from 'reducers/interfaces';
import { Canvas } from 'cvat-canvas-wrapper';

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
}

/**
 * Create component to resize the image
 *
 * @param props consists of canvasInstance and activeControl variables
 * @returns Icon within Tooltip
 */
function ResizeControl(props: Props): JSX.Element {
    const {
        activeControl,
        canvasInstance,
    } = props;
    const { t } = useTranslation();

    return (
        <Tooltip title={t('standard-workspace.roi')} placement='right'>
            <Icon
                component={ZoomIcon}
                className={activeControl === ActiveControl.ZOOM_CANVAS
                    ? 'cvat-active-canvas-control' : ''}
                onClick={(): void => {
                    if (activeControl === ActiveControl.ZOOM_CANVAS) {
                        canvasInstance.zoomCanvas(false);
                    } else {
                        canvasInstance.cancel();
                        canvasInstance.zoomCanvas(true);
                    }
                }}
            />
        </Tooltip>
    );
}

export default React.memo(ResizeControl);
