import './styles.scss';
import React from 'react';

import {
    Tabs,
    Icon,
    Input,
    Upload,
} from 'antd';
import i18n from '../../i18n'

import Tree, { AntTreeNode, TreeNodeNormal } from 'antd/lib/tree/Tree';
import { RcFile } from 'antd/lib/upload';
import Text from 'antd/lib/typography/Text';
import { array } from 'prop-types';
import { FileResourceTypeSelection } from 'cvat-core/src/enums';
import { UploaderAdd } from 'icons';
export interface DatasetFiles {
    local: File[];
    share: string[];
    remote: string[];
}

interface State {
    files: DatasetFiles;
    expandedKeys: string[];
    active: string;
    keys: string[],
}

interface Props {
    withRemote: boolean;
    treeData: TreeNodeNormal[];
    onLoadData: (key: string, success: () => void, failure: () => void) => void;
    parentMethod: void;
    getFileType: (type: any) => void;
}

export default class DatasetFileManager extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            files: {
                local: [],
                share: [],
                remote: [],
            },
            expandedKeys: [],
            active: FileResourceTypeSelection.LOCAL,
            keys: [],
        };

        this.loadData('/');
    }

    /**
     * Get files from local, share and remote
     *
     * @returns DatasetFiles consists of local, share and remote variables
     */
    public getFiles(): DatasetFiles {
        const {
            active,
            files,
        } = this.state;
        return {
            local: active === FileResourceTypeSelection.LOCAL ? files.local : [],
            share: active === FileResourceTypeSelection.SHARED ? files.share : [],
            remote: active === FileResourceTypeSelection.REMOTE ? files.remote : [],
        };
    }

    /**
     * Handle loading data
     *
     * @param key string
     * @returns Promise
     */
    private loadData = (key: string): Promise<void> => new Promise<void>(
        (resolve, reject): void => {
            const { onLoadData } = this.props;

            const success = (): void => resolve();
            const failure = (): void => reject();
            onLoadData(key, success, failure);
        },
    );

    /**
     * Clear state
     */
    public reset(): void {
        this.setState({
            expandedKeys: [],
            active: FileResourceTypeSelection.LOCAL,
            files: {
                local: [],
                share: [],
                remote: [],
            },
        });
    }

    /**
     * Render local file selector
     *
     * @returns Tabs which have local uploader
     */
    private renderLocalSelector(): JSX.Element {
        const { files } = this.state;

        return (
            <Tabs.TabPane key={FileResourceTypeSelection.LOCAL} tab={i18n.t('file-manager.localFiles')}>
                <Upload.Dragger
                    className="uploader-style"
                    multiple
                    listType='text'
                    fileList={files.local as any[]}
                    showUploadList={files.local.length < 5 && {
                        showRemoveIcon: false,
                    }}
                    beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                        this.setState({
                            files: {
                                remote: [],
                                share: [],
                                local: newLocalFiles,
                            }
                        },
                            () => {
                                this.props.parentMethod();
                                this.props.getFileType(this.state.files.local[0].name);
                            }
                        );

                        return false;
                    }}
                >
                    <p className='ant-upload-drag-icon'>
                        <Icon component={UploaderAdd} />
                    </p>
                    <p className='ant-upload-text'>{i18n.t('file-manager.clickOrDrag')}</p>
                    <p className='ant-upload-hint'>
                        {i18n.t('file-manager.multipleFileSupport')}
                    </p>
                </Upload.Dragger>
                {files.local.length >= 5
                    && (
                        <>
                            <br />
                            <Text className='cvat-text-color'>
                                {`${files.local.length} files selected`}
                            </Text>
                        </>
                    )
                }
                {this.state.active === FileResourceTypeSelection.LOCAL ? this.props.getFileType(FileResourceTypeSelection.LOCAL) : ""}
            </Tabs.TabPane>
        );
    }

    /**
     * Render share file selector
     *
     * @returns Tabs which have share uploader
     */
    private renderShareSelector(): JSX.Element {
        function renderTreeNodes(data: TreeNodeNormal[]): JSX.Element[] {
            return data.map((item: TreeNodeNormal) => {
                if (item.children) {
                    return (
                        <Tree.TreeNode
                            title={item.title}
                            key={item.key}
                            dataRef={item}
                            isLeaf={item.isLeaf}
                        >
                            {renderTreeNodes(item.children)}
                        </Tree.TreeNode>
                    );
                }

                return <Tree.TreeNode key={item.key} {...item} dataRef={item} />;
            });
        }

        const { treeData } = this.props;
        const {
            expandedKeys,
            files,
            keys,
        } = this.state;

        return (
            <Tabs.TabPane key={FileResourceTypeSelection.SHARED} tab={i18n.t('file-manager.serverFiles')}>
                {treeData.length
                    ? (
                        <Tree
                            className='cvat-share-tree'
                            checkable
                            showLine
                            checkStrictly={false}
                            expandedKeys={expandedKeys}
                            checkedKeys={keys}
                            loadData={(node: AntTreeNode): Promise<void> => this.loadData(
                                node.props.dataRef.key,
                            )}
                            onExpand={(newExpandedKeys: string[]): void => {
                                this.setState({
                                    expandedKeys: newExpandedKeys,
                                });
                            }}
                            onCheck={
                                (checkedKeys: string[] | {
                                    checked: string[];
                                    halfChecked: string[];
                                }): void => {
                                    const keys = (checkedKeys as string[])
                                    const checkedFiles = keys.filter(object => (object.split("/").pop().split(".").length > 1));
                                    this.setState({
                                        files: {
                                            local: [],
                                            remote: [],
                                            share: checkedFiles,
                                        },
                                        keys: keys,
                                    },
                                        () => {
                                            this.props.parentMethod();
                                            this.props.getFileType(FileResourceTypeSelection.SHARED);
                                        }
                                    );
                                }}
                        >
                            {renderTreeNodes(treeData)}
                        </Tree>
                    ) :
                    <div className='uploader-style shared'>
                        <Text className='cvat-text-color'>{i18n.t('file-manager.noFiles')}
                        </Text>
                    </div>
                }
                {this.state.active === FileResourceTypeSelection.SHARED ? this.props.getFileType(FileResourceTypeSelection.SHARED) : ""}
            </Tabs.TabPane>
        );
    }

    /**
     * Render remote file selector
     *
     * @returns Tabs which have remote uploader
     */
    private renderRemoteSelector(): JSX.Element {
        const { files } = this.state;


        return (
            <Tabs.TabPane key={FileResourceTypeSelection.REMOTE} tab={i18n.t('file-manager.remoteFiles')}>
                <Input.TextArea
                    placeholder={i18n.t('file-manager.oneURLPerLine')}
                    rows={6}
                    className="input-remote-upload"
                    value={[...files.remote].join('\n')}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
                        this.setState({
                            files: {
                                local: [],
                                share: [],
                                remote: event.target.value.split('\n'),
                            }
                        },
                            () => {
                                this.props.parentMethod();
                                this.props.getFileType(this.state.files.remote[0]);
                            }
                        );
                    }}
                />
                {(this.state.active === FileResourceTypeSelection.REMOTE) ? this.props.getFileType(FileResourceTypeSelection.REMOTE) : ''}
            </Tabs.TabPane>
        );
    }

    /**
     * Render all tabs file selector
     * 
     * @returns Tabs which have local, share and remote uploader
     */
    public render(): JSX.Element {
        const { withRemote } = this.props;
        const { active } = this.state;

        return (
            <>
                <Tabs
                    style={{ marginTop: "10px" }}
                    type='card'
                    className="file-manager"
                    activeKey={active}
                    tabBarGutter={5}
                    onChange={
                        (activeKey: string): void =>
                            this.setState({
                                active: activeKey as any,
                            })
                    }
                >
                    {this.renderLocalSelector()}
                    {/* { this.renderShareSelector() } */}
                    {withRemote && this.renderRemoteSelector()}
                </Tabs>
            </>
        );
    }
}
