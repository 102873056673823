// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Select from 'antd/lib/select';
import { useState, useEffect } from 'react';
import getCore from 'cvat-core-wrapper';
import "./styles.scss";
import { Tooltip } from 'antd';
const cvat = getCore();

interface Props {
    parentMethod: (userList: any) => void;
    onChange: (user: string) => void;
    mode?:"Default";
    user: any;
}

/**
 *
 * @param props
 * @returns User Selection for sending a message
 */
export default function UserSelectorMessaging(props: Props): JSX.Element {
    const {
        parentMethod,
        onChange,
        mode,
        user
    } = props;
    console.log(user)
    let searchRoles =[];
    const [usersSearched, setUsers] = useState([]);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    if(user.isSuperuser)
        searchRoles = ["admin","provider","annotator"]
    else
        searchRoles = ["admin","provider"]
    return (
        <Tooltip overlayClassName="user-selector-tooltip" title="Aramayı etkinleştirmek için en az üç harf giriniz">
        <Select
            data-tour="step-recipient"
            allowClear={true}
            mode={mode? mode:"multiple"}
            size='small'
            placeholder='Kullanıcıları Ara'
            showSearch
            className="user-selector-message"
            autoClearSearchValue
            onSearch={async (value: string): Promise<void> => {
                if(value.length >= 3){
                    let usersAfterSearch = await cvat.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value},  searchRoles);
                    let usersFinal = new Array()
                    for(let i = 0; i < usersAfterSearch.users.length ; i++){
                        if(usersAfterSearch.users[i].id.id != user.id){
                            usersFinal.push(usersAfterSearch.users[i].id)
                        }
                    }
                    setUsers(usersFinal)
                }else{
                    setUsers([])
                }
            }}
            onChange={(value: string, option:any): void => {
                if(mode)
                    parentMethod(option.key)
                else
                    parentMethod(value)
            }}
        >
            {usersSearched.map((user): JSX.Element => (
                <Select.Option key={user.id} value={user.username}>
                    {user.username}
                </Select.Option>
            ))}
        </Select>
        </Tooltip>
    );
}
