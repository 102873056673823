// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import {
    Task,
    TasksQuery,
    CombinedState,
} from 'reducers/interfaces';

import TasksPageComponent from 'components/tasks-page/tasks-page';

import {
    getTasksAsync,
    hideEmptyTasks,
} from 'actions/tasks-actions';

interface StateToProps {
    tasksFetching: boolean;
    gettingQuery: TasksQuery;
    numberOfTasks: number;
    numberOfVisibleTasks: number;
    annotaUser: any;
    user: any;
    numberOfHiddenTasks: number;
}

interface DispatchToProps {
    onGetTasks: (gettingQuery: TasksQuery, tasksViewType: string, taskType: string, annotaTaskStatus: string, taskClassificationType: string, onlyExpired: string) => void;
    hideEmptyTasks: (hideEmpty: boolean) => void;
}

/**
 *
 * @param state -> combined state containing tasks and auth user information
 * @returns tasks and user information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    const { tasks } = state;

    return {
        tasksFetching: state.tasks.fetching,
        gettingQuery: tasks.gettingQuery,
        numberOfTasks: state.tasks.count,
        numberOfVisibleTasks: state.tasks.current.length,
        annotaUser: state.auth.annotaUser,
        user: state.auth.user,
        numberOfHiddenTasks: tasks.hideEmpty ? tasks.current
            .filter((task: Task): boolean => !task.instance.jobs.length).length : 0,
    };
}

/**
 *
 * @param dispatch
 * @returns onGetTasks and hideEmptyTasks action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onGetTasks: (query: TasksQuery, tasksViewType: string, taskType: string, annotaTaskStatus: string, taskClassificationType: string, onlyExpired: string): void => {
            dispatch(getTasksAsync(query, tasksViewType, taskType, true, annotaTaskStatus, taskClassificationType, onlyExpired));
        },
        hideEmptyTasks: (hideEmpty: boolean): void => {
            dispatch(hideEmptyTasks(hideEmpty));
        },
    };
}

type TasksPageContainerProps = StateToProps & DispatchToProps;

/**
 *
 * @param props
 * @returns TasksPageComponent passing it props
 */
function TasksPageContainer(props: TasksPageContainerProps): JSX.Element {
    return (
        <TasksPageComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TasksPageContainer);
