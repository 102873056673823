import './styles.scss';
import React, { Component } from 'react';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Button, Collapse, Row, Col, Pagination, Table, Input, Modal, Popconfirm, Select, Icon, Radio, Divider, Card, List, Typography } from 'antd';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'
import Column from 'antd/lib/table/Column';
import { getCVATStore } from 'cvat-store';
import { AuthState, CombinedState } from '../reducers/interfaces';
import TextArea from 'antd/lib/input/TextArea';
import UserSelector from '../components/create-task-page/user-selector-task';
import Text from 'antd/lib/typography/Text';
import {
    DeleteOutline
} from '@ant-design/icons';
import { recordKeyCombination } from 'react-hotkeys';

const { Panel } = Collapse;
const cvat = getCore();
let store: null | Store<CombinedState> = null;
let backendEndPoint = String(cvat.config.backendAPI)
let backendRoot = String(cvat.config.root)

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}
interface VisibleTopBarProps {
    user: any;
    onSearch: (value: string) => void;
    searchValue: string;
    auth: AuthState;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

type ProviderGroupPageProps = VisibleTopBarProps & RouteComponentProps;

/**
 * @class_component ProviderGroupPage class component
 */
class ProviderGroupPage extends React.PureComponent<ProviderGroupPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            groupCount: 0,
            groups: [],
            users: [],
            currentGroupUsers: [],
            modalType: "",
            addUserVisible: false,
            visible: false,
            newGroupName: "",
            editGroupID: 0,
            editGroupName: "",
            editGroupNewName: "",
            userRequestMail: "",
            errorFlagEmail: true,
            width: 1920,
            height: 1080,
            specialAnnotators: [],
        }

        this.getSelectedUsers = this.getSelectedUsers.bind(this);
        this.handleUserGroups = this.handleUserGroups.bind(this);
        this.handleUserGroups(this.props.auth.user.id);
        this.handleCreateSpecialAnnotatorRequest = this.handleCreateSpecialAnnotatorRequest.bind(this);
        this.handleSpecialAnnotators = this.handleSpecialAnnotators.bind(this);
        this.handleSpecialAnnotators();
        this.handleResize = this.handleResize.bind(this);
    }

    /**
     * @description Resize window width and height
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     * @description calls for user groups update
     */
    public updateList() {
        this.setState({addUserVisible:false})
        const groupsAsync = this.handleUserGroups(this.props.auth.user.id);
    }

    /**
     *
     * @param userList
     * @description sets state on users with userList param
     */
    public getSelectedUsers(userList: any) {
        this.setState({
            users: userList,
        });
    }

    /**
     * @async
     * @param groupId
     * @param userName
     * @description remove users with userName given with param from group with groupId given with param
     */
    public async removeUsersFromGroup(groupId: any, userName: any) {
        let usersTotal = {
            "userIds": [userName]
        }
        await cvat.groups.removeGroupUsers(groupId, JSON.stringify(usersTotal));
        this.updateList()
    }

    /**
     * @async
     * @param groupId
     * @description sets users on state to groupId given with param
     */
    public async setUsers(groupId: any) {
        let usersTotal = {
            "userIds": this.state.users
        }
        await cvat.groups.setGroupUsers(groupId, JSON.stringify(usersTotal));
        this.updateList()
    }

    /**
     * @async
     * @param userId
     * @description get user groups with userId passed with param, sets state on groups, user cound, group new name
     */
    async handleUserGroups(userId: Number) {
        let response = await cvat.groups.getGroups(userId);
        this.setState({
            totalRelatedUserCount: response.data.count,
            groups: response.data.results,
            editGroupNewName: ""
        });
    }

    /**
     * @async
     * @description get special annotators api proxy call, sets state on specialAnnotators with response
     */
    async handleSpecialAnnotators() {
        let response = await cvat.groups.getSpecialAnnotators();
        this.setState({
            specialAnnotators: response.data,
        });
    }

    /**
     * @async
     * @description update group name api proxy call using info in state, updates list
     */
    async modifyGroupName() {
        const response = await cvat.groups.updateGroupName(this.state.editGroupID, this.state.editGroupNewName);
        this.updateList();
    }

    /**
     * @async
     * @description create new group api proxy call using info in state, clear state, updates list
     */
    async handleCreateNewGroup() {
        const response = await cvat.groups.createNewGroup(this.props.auth.user.id, this.state.newGroupName);
        this.setUsers(response.data.id);
        this.setState({
            newGroupName: ""
        });
        this.updateList();
    }

    /**
     * @async
     * @description create special annotator api proxy call using info in state, handle error and success with Modals
     */
    async handleCreateSpecialAnnotatorRequest() {
        let result = await cvat.users.createSpecialAnnotatorRequest(this.state.userRequestMail)
        if (result.status == 'Success') {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Kayıt daveti başarıyla gönderildi!',// Kayıt adresi: ' + result.register_link,
                onOk: () => {
                    this.setState({
                        visibleUserRequest: false,
                        userRequestMail: ""
                    });
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: result.status,
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     * @async
     * @param id
     * @description delete group having passed id with param, update the list
     */
    async deleteGroup(id: number) {
        const response = await cvat.groups.deleteGroup(id);
        this.updateList();
    }

    /**
     *
     * @param item
     * @param type
     * @description set state to show Modal
     */
    private showModal(item: any, type: any) {

        this.setState({
            visible: true,
            modalType: type,
            editGroupName: item.groupName,
            editGroupID: item.id
        })

    }

    /**
     *
     * @param email
     * @returns validation result boolean
     */
    public validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    /**
     * @deprecated is not used anymore.
     * @param e Modal CANCEL event
     * @description sets state to cancel request
     */
    handleCancelSpecialAnnotatorRequest = e => {
        this.setState({
            visibleUserRequest: false,
            userRequestMail: ""
        }), () => {
            Modal.destroyAll();
        };
    };

    /**
     *
     * @param e Modal CANCEL event
     * @description sets state to close Modal
     */
    handleCancel = e => {
        this.setState({
            visible: false,
            editGroupNewName: ""
        }), () => {
            Modal.destroyAll();
        };
    };

    /**
     *
     * @param e Modal OK event
     * @returns void, group creation or modification Modals by function calls
     */
    handleOk = e => {
        this.setState({
            visible: false,
        }, () => {
            if (this.state.modalType === "createNewGroup") {
                this.handleCreateNewGroup();
            }
            else {
                this.modifyGroupName();
            }
            Modal.destroyAll();
        });

        return;
    }

    /**
     *
     * @returns Modal template according to modalType on state
     */
    private renderModal() {
        const self = this;
        switch (this.state.modalType) {
            case "editGroupName":
                return (<Modal
                    title={i18n.t('datasets.editModalTitle')}
                    visible={this.state.visible && this.state.modalType === "editGroupName"}
                    onOk={this.handleOk}
                    okText='Tamam'
                    cancelText='İptal'
                    onCancel={this.handleCancel}
                >
                    <p>Eski Grup Adı: {this.state.editGroupName}</p>
                    <p>Yeni Grup Adı:
                        <input style={{ marginLeft: "1%" }} type="text" className="regular h2" value={this.state.editGroupNewName}
                            onChange={(event) => this.setState({ editGroupNewName: event.target.value })} />
                    </p>
                </Modal>)
        }
    }

    /**
     *
     * @param specialAnnotators
     * @returns speacial annotators section template
     */
    private renderSpecialAnnotators(specialAnnotators: List<Map<String, any>>) {
        let spanGroupCard = 6
        if(this.state.width < this.state.height){
            spanGroupCard = 24
        }
        return [
            <Col span={spanGroupCard}>
                <Card title={<h2 style={{ color: "#5A5C69", textAlign: "center" }}>Özel Etiketleyicilerim</h2>}

                    headStyle={{
                        color: "#5A5C69"
                    }}
                    style={{
                        background: "#F8F9FC 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        minHeight:"312px"
                    }}>
                    <h3 style={{ color: "#5A5C69", textAlign: "center" }}><b>Henüz Kaydolmamış Davetli Üyeler</b></h3>
                    <Table
                        style={{ marginTop: '2%' }}
                        rowKey={record => record.id}
                        dataSource={specialAnnotators.filter((item: any) => !item.approved)}
                        pagination={false}
                    >
                        <Column title="E-Posta" render={(record) => record.email}></Column>
                        <Column title="Kullanıcı Adı" render={(_) => '-'}></Column>

                    </Table>
                    <div style={{ height: 50 }}></div>
                    <h3 style={{ color: "#5A5C69", textAlign: "center" }}><b>Kaydolmuş Davetli Üyeler</b></h3>
                    <Table
                        style={{ marginTop: '2%' }}
                        rowKey={record => record.id}
                        dataSource={specialAnnotators.filter((item: any) => item.approved)}
                        pagination={false}
                    >
                        <Column title="E-Posta" render={(record) => record.email}></Column>
                        <Column title="Kullanıcı Adı" render={(record) => record.username}></Column>

                    </Table>
                </Card>
            </Col>
        ]
    }

    /**
     *
     * @param groupsArr
     * @returns group list section template
     */
    private renderGroupsList(groupsArr: any) {
        let rowArr = new Array()
        let groupCardsArr = new Array()
        let spanGroupCard = 6
        if(this.state.width < this.state.height){
            spanGroupCard = 24
        }
        for (let groupCounter = 0; groupCounter < groupsArr.length; groupCounter++) {
            for (let i = 0; i < groupsArr.length / 4; i++) {
                for (let j = 0; j < 4; j++) {
                    let item = groupsArr[groupCounter]
                    groupCardsArr.push(
                        <Col span={spanGroupCard}>
                            <Card title={<div>{item.groupName} <Icon type="edit" onClick={(e) => this.showModal(item, "editGroupName")} />
                                    <Popconfirm
                                        title="Grubu silmek istediğinize emin misiniz?"
                                        cancelText={i18n.t('keywords.no')}
                                        okText={i18n.t('keywords.yes')}
                                        onConfirm={
                                            (e) => {
                                                this.deleteGroup(item.id)
                                            }
                                        }
                                    >
                                        <Icon style={{float:"right"}} type="delete" />
                                    </Popconfirm></div>}

                                headStyle={{
                                    color: "#5A5C69"
                                }}
                                style={{
                                    background: "#F8F9FC 0% 0% no-repeat padding-box",
                                    borderRadius: "8px",
                                    minHeight:"312px"
                                }}>
                                <h4 style={{ color: "#5A5C69" }}>Grup Üyeleri</h4>
                                <List
                                    style={{ marginTop: '2%' }}
                                    rowKey={record => record.id.id}
                                    dataSource={item.members}
                                    size="small"
                                    pagination={false}
                                    locale={{ emptyText: 'Sonuç Bulunamadı' }}
                                    renderItem={record => (
                                        <div style={{ display: "flex" }}>
                                            <Typography.Text style={{ marginBottom: "10px", marginRight: "7px", padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{record.id.username}</Typography.Text>
                                            <Popconfirm
                                                title={i18n.t('datasets.deleteUserWarning')}
                                                cancelText={i18n.t('keywords.no')}
                                                okText={i18n.t('keywords.yes')}
                                                onConfirm={
                                                    (e) => {
                                                        this.removeUsersFromGroup(item.id, record.id.username)
                                                    }
                                                }
                                            >
                                                <Button className="button-default-groups-page" style={{ float: "right" }} size="small"> Kaldır</Button>
                                            </Popconfirm>
                                        </div>
                                    )}
                                >
                                </List>
                                <Row >
                                    <Button icon="plus" size="small" type="primary" className="button-groups-page" style={{ marginTop: "1%", minWidth: "86px" }} onClick={(e) => this.setState({addUserVisible:!this.state.addUserVisible,editGroupID:item.id})}>Üye Ekle</Button>

                                    {this.state.addUserVisible && item.id ==this.state.editGroupID &&
                                        <div style={{marginTop:"20px",display:"flex"}}>
                                            <UserSelector
                                                parentMethod={this.getSelectedUsers}
                                                onChange={(value: string): void => {
                                                }}
                                                size="default"
                                                placeholder="Kullanıcı adı gir"
                                                className="add-user-groups-page"
                                            />
                                            <Button size="small" className="button-default-groups-page" style={{ float: "right" }} onClick={(e) => this.setUsers(item.id)}>Ekle</Button>
                                        </div>
                                    }
                                </Row>
                            </Card>
                        </Col>
                    )
                    groupCounter++;
                    if (groupCounter >= groupsArr.length)
                        break;
                }
                rowArr.push(
                    <Row gutter={[16, 32]}>
                        {groupCardsArr}
                    </Row>
                )
                groupCardsArr = new Array()

                if (groupCounter >= groupsArr.length)
                    break;
            }
            if (groupCounter >= groupsArr.length)
                break;
            groupCounter--;

        }

        return rowArr;
    }

    /**
     *
     * @returns top component template
     */
    private renderTopComp() {
        let spanTopCard = 12
        let width = '90%'
        if(this.state.width < this.state.height){
            spanTopCard = 24
            width = '100%'
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title">Kullanıcı Grupları</Text>
                <div style={{paddingTop:"15px"}}>
                <div style={{ padding: "1% 2% 1% 2%", width: width, left: "15.9%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 10px 30px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "6px" }}>
                    <Row justify="space-between" type="flex" gutter={[48, 32]}>
                        <Col span={spanTopCard}>
                            <h3 style={{ fontWeight: 700, color: "#5A5C69" }}>Yeni Grup Oluştur</h3>
                            <Input
                                className="input-groups-page"
                                placeholder='Grup Adı'
                                defaultValue={this.state.newGroupName}
                                onChange={(e) => this.setState({ newGroupName: e.target.value })}
                                data-tour="step-new-group-name"
                            />
                            <br></br><br></br>
                            <UserSelector
                                parentMethod={this.getSelectedUsers}
                                onChange={(value: string): void => {
                                }}
                                size="default"
                                placeholder="Grup Üyesi Seç"
                                className="input-groups-page"
                                showArrow={true}
                                dataTour="step-group-user"
                            />
                            <Button data-tour="step-create" size="small" type="primary" className="button-groups-page" style={{ float: "right", marginTop: "1%", minWidth: "86px" }} onClick={(e) => this.handleCreateNewGroup()}>Oluştur</Button>
                        </Col>
                        <Col data-tour="step-email" span={spanTopCard}>
                            <h3 style={{ fontWeight: 700, color: "#5A5C69" }}>Kullanıcı Davet Et</h3>
                            <Input
                                className="input-groups-page"
                                placeholder='E-posta Adresi'
                                type='email'
                                onChange={(e) => {
                                    if (!this.validateEmail(e.target.value)) {
                                        this.setState({
                                            userRequestMail: e.target.value,
                                            errorFlagEmail: true
                                        })
                                    } else {
                                        this.setState({
                                            userRequestMail: e.target.value,
                                            errorFlagEmail: false
                                        })
                                    }

                                }}
                            />
                            {(this.state.errorFlagEmail === true && this.state.userRequestMail != "") ? <Text style={{ color: "red", fontStyle: "italic" }}>"Geçersiz e-posta adresi!"</Text> : null}
                            <Button size="small" type="primary" className="button-groups-page" style={{ float: "right", marginTop: "1%", minWidth: "86px" }} onClick={(e) => this.handleCreateSpecialAnnotatorRequest()}>Davet Et</Button>
                        </Col>
                    </Row>

                    <Row data-tour="step-groups" gutter={16}>
                        <h3 style={{ fontWeight: 700, color: "#5A5C69" }}>Gruplar</h3>
                        {this.renderGroupsList(this.state.groups)}
                        {this.renderSpecialAnnotators(this.state.specialAnnotators)}
                        </Row>

                        {/* {this.state.groups.length > 0 ?
                            <Row data-tour="step-groups" gutter={16}>
                                <h3 style={{ fontWeight: 700, color: "#5A5C69" }}>Gruplar</h3>
                                {this.renderGroupsList(this.state.groups)}
                                {this.renderSpecialAnnotators()}

                            </Row> : <Row data-tour="step-groups" gutter={16}>
                        <h3 style={{ fontWeight: 700, color: "#5A5C69" }}>Gruplar</h3>
                                <Text style={{ color: "#5A5C69" }}>Henüz grup oluşturulmamış.</Text>
                            </Row>} */}
                </div>
                </div>
            </div>

        )
    }

    /**
     *
     * @returns provider group page template using class functions
     */
    public render() {
        return (
            <div>
                {this.renderTopComp()}
                {this.renderModal()}
            </div>);
    }
}

export default withRouter(connect(mapStateToProps,
)(ProviderGroupPage));
