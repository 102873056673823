// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Row, Col } from 'antd/lib/grid';
import { useTranslation } from 'react-i18next';

import ResetConfirmForm, { ResetConfirmData } from './reset-confirm-form';

interface ResetConfirmPageComponentProps {
    onReset: (uidb64: string, token: string, password1: string, password2: string) => void;
}
/**
 *
 * @param props
 * @returns Password Reset Confirmation JSX Template for auth/reset-password-confirm
 */
function ResetConfirmPageComponent(props: ResetConfirmPageComponentProps & RouteComponentProps): JSX.Element {
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    const {
        onReset,
    } = props;
    const { t } = useTranslation();

    /**
     * @description Pwd Reset Confirmation Component rendering title and ResetConfirmForm Component instance
     */
    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                <Col {...sizes}>
                    <img src='/veri_kovani_logo.svg' width='100%' vspace="50"></img>
                    <Title level={2}> {t('keywords.passwordReset')} </Title>
                    <ResetConfirmForm
                        onSubmit={(resetConfirmData: ResetConfirmData): void => {
                            onReset(resetConfirmData.uidb64, resetConfirmData.token, resetConfirmData.password1, resetConfirmData.password2);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default withRouter(ResetConfirmPageComponent);
