// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Text from 'antd/lib/typography/Text';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import { useTranslation } from 'react-i18next';

interface Props {
    currentAttribute: string;
    currentIndex: number;
    attributesCount: number;
    normalizedKeyMap: Record<string, string>;
    nextAttribute(step: number): void;
}

/**
 * Create attibute switcher which has tooltips and buttons
 * 
 * @param props consists of currentAttribute, currentIndex, attributesCount, normalizedKeyMap variables and nextAttribute function
 * @returns
 */
function AttributeSwitcher(props: Props): JSX.Element {
    const {
        currentAttribute,
        currentIndex,
        attributesCount,
        nextAttribute,
        normalizedKeyMap,
    } = props;
    const { t } = useTranslation();
    const title = `${currentAttribute} [${currentIndex + 1}/${attributesCount}]`;
    return (
        <div className='attribute-annotation-sidebar-switcher'>
            <Tooltip title={`${t('attribute-switcher.prev')} ${normalizedKeyMap.PREVIOUS_ATTRIBUTE}`}>
                <Button disabled={attributesCount <= 1} onClick={() => nextAttribute(-1)}>
                    <Icon type='left' />
                </Button>
            </Tooltip>
            <Tooltip title={title}>
                <Text className='cvat-text'>{currentAttribute}</Text>
                <Text strong>{` [${currentIndex + 1}/${attributesCount}]`}</Text>
            </Tooltip>
            <Tooltip title={`${t('attribute-switcher.next')} ${normalizedKeyMap.NEXT_ATTRIBUTE}`}>
                <Button disabled={attributesCount <= 1} onClick={() => nextAttribute(1)}>
                    <Icon type='right' />
                </Button>
            </Tooltip>
        </div>
    );
}

export default React.memo(AttributeSwitcher);
