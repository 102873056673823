import './styles.scss';
import React, {Component} from 'react';
import getCore from 'cvat-core-wrapper';

const cvat = getCore();
/**
 * RedirectComponent for redirection to /home handling, differentiates between development and production
 */
export default class RedirectComponent extends Component {
    constructor(props: any) {
        super(props);
    }

    public componentDidMount(){
        if(cvat.config.reactAppEnv == 'development')
            window.location.assign('auth/login')
        else
            window.location.assign('home.html')
    }

    public render(): JSX.Element {
        return (
            <div></div>
        )
    }
}
