// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Select from 'antd/lib/select';
import { useState, useEffect } from 'react';
import getCore from 'cvat-core-wrapper';
import User from '../../../../cvat-core/src/user'
const cvat = getCore();

interface Props {
    value: string | null;
    users: any[];
    onChange: (user: string) => void;
    onJobUpdate: (jobInstance: any) => void,
    jobInstance: any
}

/**
 *
 * @param props
 * @returns user selection template for task page
 */
export default function UserSelector(props: Props): JSX.Element {
    const {
        value,
        users,
        onChange,
        onJobUpdate,
        jobInstance
    } = props;
    const [usersSearched, setUsers] = useState(users);
    const [selectedUser, setSelectedUser] = useState(value);

    if (props.value !== selectedUser) setSelectedUser(props.value);

    /**
     * @description Task page user selector template with Select component
     */
    return (
        <Select
            value={selectedUser || '—'}
            size='small'
            showSearch
            onSearch={async (value: string): Promise<void> => {
                if(value.length >= 3){
                    let usersAfterSearch = await cvat.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value});
                    let usersFinal = new Array()
                    for(let i = 0; i < usersAfterSearch.users.length ; i++){
                        usersFinal.push(usersAfterSearch.users[i].id)
                    }
                    setUsers(usersFinal)
                }else{
                    setUsers(users)
                }
            }}
            className='cvat-user-selector'
            onChange={(value: string): void => {

                setSelectedUser(value);
                let userInstance = usersSearched
                    .filter((user: any) => user.username === value);

                let userObject;
                if (userInstance[0] === undefined) {
                    userObject = null;
                }else{
                    if(userInstance[0] instanceof User)
                        userObject = userInstance[0]
                    else
                        userObject = new User(userInstance[0])
                }

                // eslint-disable-next-line
                jobInstance.assignee = userObject;
                onJobUpdate(jobInstance);
            }}
        >
            <Select.Option key='-1' value='—'>—</Select.Option>
            {usersSearched.map((user): JSX.Element => (
                <Select.Option key={user.id} value={user.username}>
                    {user.username}
                </Select.Option>
            ))}
        </Select>
    );
}
