import React,{ useState, useEffect, useRef } from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form,
    Input,
    Modal,
    Select
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getCore from 'cvat-core-wrapper';
import patterns from 'utils/validation-patterns';


const core = getCore();
interface Props {
    visible:boolean;
    item:any;
    setInvisible:()=>void;
    updateFaq:()=>void;
}

/**
 * @class Frequently Asked Questions Update Form Component class
 */
class UpdateFaqComponent extends React.PureComponent<Props&FormComponentProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            charCountQuestion: 0,
            charCountAnswer: 0,
            question: this.props.item.question,
            answer: this.props.item.answer,
            number: this.props.item.number,
        };
    }

    /**
     *
     * @param e event on question input
     * @description set state on question character count and question
     */
    evalCharCountQuestion = e => {
        this.setState({
            charCountQuestion: e.target.value.length,
            question: e.target.value,
        });
    };

    /**
     *
     * @param e event on answer input
     * @description set state on answer character count and answer
     */
    evalCharCountAnswer = e => {
        this.setState({
            charCountAnswer: e.target.value.length,
            answer: e.target.value,
        });
    };

     /**
     *
     * @param e event on number input
     * @description set state on number
     */
    numberInput = e => {
        this.setState({
            number: e.target.value,
        });
    };

    /**
     *
     * @param rule
     * @param value
     * @param callback
     * @description call validation on frequently asked question input number
     */
    private validateNewFaqNumber = (rule: any, value: any, callback: any): void => {
        if (!patterns.validateNewFaqNumber.pattern.test(value)) {
            callback(patterns.validateNewFaqNumber.message);
        }
        callback();
    };

    /**
     *
     * @returns Question number field template
     */
    private renderQNumberField(){

        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('number', {
                        rules: [{
                            required: true,
                            message: 'Lütfen soru numarasını girin',
                        },{
                            validator: this.validateNewFaqNumber
                        }], initialValue:this.props.item.number
                    })(
                        <TextArea
                            id="questionNumber"
                            rows={1}
                            style={{resize: 'vertical'}}
                            onChange = {this.numberInput}
                        />
                    )}
                </Form.Item>
            </div>
        );
    }

    /**
     *
     * @returns Question body field template
     */
    private renderQuestionField(){

        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('question', {
                        rules: [{
                            required: true,
                            message: 'Lütfen soruyu girin',
                        }], initialValue:this.props.item.question
                    })(
                        <TextArea
                            id="questionBody"
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            onChange = {this.evalCharCountQuestion}
                        />
                    )}
                </Form.Item>
                <p  style={{float:'right'}}>Karakter Sayısı: {this.state.charCountQuestion}/4000</p>
            </div>
        );
    }

    /**
     *
     * @returns Answer body field template
     */
    private renderAnswerField(){

        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('answer', {
                        rules: [{
                            required: true,
                            message: 'Lütfen cevabı girin',
                        }], initialValue:this.props.item.answer
                    })(
                        <TextArea
                            id="answerBody"
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            onChange = {this.evalCharCountAnswer}
                        />
                    )}
                </Form.Item>
                <p  style={{float:'right'}}>Karakter Sayısı: {this.state.charCountAnswer}/4000</p>
            </div>
        );
    }

    /**
     * @async
     * @description sends frequently asked questions update request, handle error
     */
   private async handleUpdateFaq(){
        const {form} = this.props;
        let resp = await core.management.updateFaq(this.props.item.id, this.props.item.number, form.getFieldValue("question"), form.getFieldValue("answer"))
        let response = resp.status;
        console.log(resp)
        if(response != "Başarılı"){
            Modal.error({
                title: 'Hata!',
              content: response,
            });
        }
        else{
             Modal.success({
                okButtonProps:{id:"ok"},
              title: 'Başarılı!',
              content: resp.message,
            });
            this.props.setInvisible();
            this.props.form.setFieldsValue({number: "",question:"", answer:""});
            this.props.updateFaq();
        }
    }

    /**
     *
     * @param e event on form submission
     * @description calls handleUpdateFaq function
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (err) {
            console.log('Received values of form: ', values);
          }
          else {
            console.log('Received values of form: ', values);

            this.handleUpdateFaq();
          }
        });
    };

    /**
     *
     * @returns Form template
     */
    public render() {
        return (
        <div>
            <Form>
            <Modal
                maskClosable={false}
                title="Güncelle"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                okButtonProps={{id:"send",htmlType:'submit' }}
                okText="Kaydet"
                onCancel={()=>{
                    this.props.setInvisible();
                    this.props.form.setFieldsValue({number:"", question:"", answer:""});
                }}
                destroyOnClose={true}
            >
                {/* {this.renderQNumberField()} */}
                {this.renderQuestionField()}
                {this.renderAnswerField()}

            </Modal>
            </Form>
        </div>
        );
    }
}

export default Form.create<FormComponentProps&Props>()(UpdateFaqComponent);
