import {VideoTypeList} from 'containers/video-types';

/**
 * 
 * @param name file type of the video
 * @returns Boolean about video type or not
 */
export function isVideo(name: string) {
    if(name){
        if(name.startsWith("3"))
            name = "_"+name;
    }
    if (name in VideoTypeList) {
        return true;
    }else if( name === "video"){
        return true;
    }


    return false;
}

/**
 * 
 * @param name text type
 * @returns Boolean whether or not a text
 */
export function isText(name: string){
    return name === 'txt' || name === 'text';
}