// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';
import Popover from 'antd/lib/popover';

import { RotateIcon } from 'icons';
import { Rotation } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
    clockwiseShortcut: string;
    anticlockwiseShortcut: string;
    rotateFrame(rotation: Rotation): void;
}

/**
 * Create component to rotate the image
 *
 * @param props consists of clockwiseShortcut, anticlockwiseShortcut variables and rotateFrame function
 * @returns Icon within Tooltip
 */
function RotateControl(props: Props): JSX.Element {
    const {
        anticlockwiseShortcut,
        clockwiseShortcut,
        rotateFrame,
    } = props;
    const { t } = useTranslation();

    return (
        <Popover
            overlayClassName='cvat-rotate-canvas-controls'
            placement='right'
            content={(
                <>
                    <Tooltip title={`${t('standard-workspace.rotateImageCClockwise')} ${anticlockwiseShortcut}`} placement='topRight'>
                        <Icon
                            className='cvat-rotate-canvas-controls-left'
                            onClick={(): void => rotateFrame(Rotation.ANTICLOCKWISE90)}
                            component={RotateIcon}
                        />
                    </Tooltip>
                    <Tooltip title={`${t('standard-workspace.rotateImageClockwise')} ${clockwiseShortcut}`} placement='topRight'>
                        <Icon
                            className='cvat-rotate-canvas-controls-right'
                            onClick={(): void => rotateFrame(Rotation.CLOCKWISE90)}
                            component={RotateIcon}
                        />
                    </Tooltip>
                </>
            )}
            trigger='hover'
        >
            <Icon component={RotateIcon} />
        </Popover>
    );
}

export default React.memo(RotateControl);
