// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import _cvat from 'cvat-core/src/api';

const cvat: any = _cvat;

const protocol = typeof (process.env.REACT_APP_API_PROTOCOL) === 'undefined'
    ? 'http' : process.env.REACT_APP_API_PROTOCOL;
const doccanoProtocol = typeof (process.env.REACT_APP_DOCCANO_PROTOCOL) === 'undefined'
    ? 'http' : process.env.REACT_APP_DOCCANO_PROTOCOL;
const host = typeof (process.env.REACT_APP_API_HOST) === 'undefined'
    ? 'localhost' : process.env.REACT_APP_API_HOST;
const doccanoHost = typeof (process.env.REACT_APP_DOCCANO_HOST) === 'undefined'
    ? 'localhost' : process.env.REACT_APP_DOCCANO_HOST;
const doccanoPort = typeof (process.env.REACT_APP_DOCCANO_PORT) === 'undefined'
    ? 'localhost' : process.env.REACT_APP_DOCCANO_PORT;
const port = typeof (process.env.REACT_APP_API_PORT) === 'undefined'
    ? '7000' : process.env.REACT_APP_API_PORT;
const reactAppEnv = typeof (process.env.REACT_APP_ENV) === 'undefined'
    ? 'development' : process.env.REACT_APP_ENV;

const productType = typeof (process.env.PRODUCT_TYPE_FRONTEND) === 'undefined'
    ? 'all' : process.env.PRODUCT_TYPE_FRONTEND;

cvat.config.backendAPIDoccano = `${doccanoProtocol}://${doccanoHost}:${doccanoPort}`;

const doccanoUIRedirectURL = typeof (process.env.REACT_APP_API_HOST) === 'undefined'
    ? 'http://localhost:4000' : cvat.config.backendAPIDoccano;
cvat.config.doccanoUIRedirectURL = doccanoUIRedirectURL

const hitachiProtocol = typeof (process.env.REACT_APP_HITACHI_PROTOCOL) === 'undefined'
    ? 'http' : process.env.REACT_APP_HITACHI_PROTOCOL;
const hitachiHost = typeof (process.env.REACT_APP_HITACHI_HOST) === 'undefined'
    ? 'localhost' : process.env.REACT_APP_HITACHI_HOST;
const hitachiPort = typeof (process.env.REACT_APP_HITACHI_PORT) === 'undefined'
    ? '3006' : process.env.REACT_APP_HITACHI_PORT;

cvat.config.hitachiURL = `${hitachiProtocol}://${hitachiHost}:${hitachiPort}`;

cvat.config.backendAPI = `${protocol}://${host}:${port}/api/v1`;
cvat.config.backendAPIHitachi = `http://${host}:3006`;
cvat.config.root = `${protocol}://${host}:${port}`;
cvat.config.prodType = productType;
cvat.config.reactAppEnv = reactAppEnv;

export default function getCore(): any {
    return cvat;
}
