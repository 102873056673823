// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Slider, { SliderValue } from 'antd/lib/slider';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Collapse from 'antd/lib/collapse';

import { ColorBy } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
    appearanceCollapsed: boolean;
    colorBy: ColorBy;
    opacity: number;
    selectedOpacity: number;
    blackBorders: boolean;
    showBitmap: boolean;
    showProjections: boolean;

    collapseAppearance(): void;
    changeShapesColorBy(event: RadioChangeEvent): void;
    changeShapesOpacity(event: SliderValue): void;
    changeSelectedShapesOpacity(event: SliderValue): void;
    changeShapesBlackBorders(event: CheckboxChangeEvent): void;
    changeShowBitmap(event: CheckboxChangeEvent): void;
    changeShowProjections(event: CheckboxChangeEvent): void;
}

/**
 * Create component for appearance
 * With this component labels can be colored as an instance, group or label, their opacities can selected or default opacity can be used.
 * Also, black border, bitmap and projections can be displayed
 *
 * @param props consists of appearanceCollapsed, colorBy, opacity, selectedOpacity, blackBorders, showBitmap, showProjections variables and collapseAppearance, changeShapesColorBy, changeShapesOpacity, changeSelectedShapesOpacity, changeShapesBlackBorders, changeShowBitmap, changeShowProjections functions
 * @returns
 */
function AppearanceBlock(props: Props): JSX.Element {
    const {
        appearanceCollapsed,
        colorBy,
        opacity,
        selectedOpacity,
        blackBorders,
        showBitmap,
        showProjections,
        collapseAppearance,
        changeShapesColorBy,
        changeShapesOpacity,
        changeSelectedShapesOpacity,
        changeShapesBlackBorders,
        changeShowBitmap,
        changeShowProjections,
    } = props;
    const { t } = useTranslation();
    return (
        <Collapse
            onChange={collapseAppearance}
            activeKey={appearanceCollapsed ? [] : ['appearance']}
            className='cvat-objects-appearance-collapse'
        >
            <Collapse.Panel
                header={
                    <Text strong>{t('standard-workspace.appearance')}</Text>
                }
                key='appearance'
            >
                <div className='cvat-objects-appearance-content'>
                    <Text type='secondary'>{t('standard-workspace.colorBy')}</Text>
                    <Radio.Group value={colorBy} onChange={changeShapesColorBy}>
                        <Radio.Button value={ColorBy.INSTANCE}>{t('standard-workspace.instance')}</Radio.Button>
                        <Radio.Button value={ColorBy.GROUP}>{t('standard-workspace.group')}</Radio.Button>
                        <Radio.Button value={ColorBy.LABEL}>{t('standard-workspace.label')}</Radio.Button>
                    </Radio.Group>
                    <Text type='secondary'>{t('standard-workspace.opacity')}</Text>
                    <Slider
                        onChange={changeShapesOpacity}
                        value={opacity}
                        min={0}
                        max={100}
                    />
                    <Text type='secondary'>{t('standard-workspace.selectedOpacity')}</Text>
                    <Slider
                        onChange={changeSelectedShapesOpacity}
                        value={selectedOpacity}
                        min={0}
                        max={100}
                    />
                    <Checkbox
                        onChange={changeShapesBlackBorders}
                        checked={blackBorders}
                    >
                        {t('standard-workspace.blackBorders')}
                    </Checkbox>
                    <Checkbox
                        onChange={changeShowBitmap}
                        checked={showBitmap}
                    >
                        {t('standard-workspace.showBitmap')}
                    </Checkbox>
                    <Checkbox
                        onChange={changeShowProjections}
                        checked={showProjections}
                    >
                        {t('standard-workspace.showProjections')}
                    </Checkbox>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

export default React.memo(AppearanceBlock);
