import React, {Component} from 'react';
import {
    Row,
    Col,
    Checkbox,
    Button,
    Table,
    Popconfirm,
    ConfigProvider,
    Collapse,
    List,
    Icon
} from 'antd';
import Text from 'antd/lib/typography/Text';

import { connect } from 'react-redux';
import { withRouter,BrowserRouter as Router,Route,Switch,Link,Redirect } from 'react-router-dom';
import UserSelector from '../create-task-page/user-selector-task';
import i18n from '../../i18n'
import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
const core = getCore();
import tr_TR from 'antd/lib/locale/tr_TR';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Column from 'antd/lib/table/Column';

interface Props{
    taskInstance:any;
    user:any;
}

/**
 * @class_component TaskUsers to render task user view in task page
 */
class TasksUsers extends Component<Props> {
    constructor(props:any){
        super(props);
        this.state = {
            annotaTask: any,
            users: [],
            userGroups: [],
        };
        this.getSelectedUsers = this.getSelectedUsers.bind(this);
    }
    componentDidMount() {
        this.getAnnotaTask(this.props.taskInstance.id)
        this.handleUserGroups()
    }

    /**
     * @async
     * @param taskId
     * @description get annota task with param taskId, update state
     */
    async getAnnotaTask(taskId: any){
        let response = null;
        response = await core.tasks.getAnnotaTasks(null,taskId);
        this.setState({
            annotaTask : response,
        }, () => {
        });
    }

    /**
     *
     * @param userList
     * @description sets state with given userList param
     */
    public getSelectedUsers(userList: any){
        this.setState({
            users : userList,
        });
    }

    /**
     * @async
     * @param id
     * @description set privacy of task on api proxy call
     */
    async setPrivacy(id: any){
        let response = null;
        response = await core.tasks.setPrivacyTask(id);
        this.getAnnotaTask(id)
    }

    /**
     * @async
     * @param id
     * @param userList
     * @description set users on task with api proxy call
     */
    public async setUsers(id: any, userList?: any){
        let usersTotal;
        if(userList){
            let userTotalList = new Array()
            for(let i = 0; i < userList.length; i++){
                userTotalList.push(userList[i].id.username)
            }
            usersTotal = {
                "userIds": userTotalList
            }
        }else{
            usersTotal = {
                "userIds": this.state.users
            }
        }
        await core.tasks.setUsersTask(id,JSON.stringify(usersTotal));
        this.getAnnotaTask(id)
    }

    /**
     * @async
     * @param id
     * @param users
     * @description remove users from task api proxy call
     */
    public async removeUsers(id: any, users: any){
        let usersTotal = {
            "userIds": users
        }
        await core.tasks.removeUsersTask(id,JSON.stringify(usersTotal));
        this.getAnnotaTask(id)
    }

    /**
     * @async
     * @description get user groups from db, set state on userGroups
     */
    async handleUserGroups() {
        let response = await core.groups.getGroups(this.props.user.id);
        this.setState({
            userGroups: response.data.results
        });
    }

    /**
     *
     * @param test
     * @returns handle expansion template per given test
     */
    public handleExpand(test: any){
        return(
            <Table
                rowKey={record => record.id.id}
                dataSource={test.members}
                pagination={false}
                showHeader={false}
                locale={{ emptyText: 'Sonuç Bulunamadı' }}
            >
                <Column dataIndex="id.username" key="id.username" />
            </Table>
        )
    }

    /**
     *
     * @returns user list view template and functionalities by using class functions
     */
    render () {
        const columnsUserGroups = [
            {
                title: i18n.t('groups.groupName'),
                dataIndex: 'groupName',
                key: 'groupName',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },
            {
                key: 'action',
                render: (record) => (
                    <div>
                        <Popconfirm
                            title={i18n.t('groups.addWarning')}
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => {
                                    this.setUsers(this.state.annotaTask.id.id,record.members)
                                }
                            }
                        >
                            <Button className="button-segment-details" size="small" > {i18n.t('keywords.add')} </Button>
                        </Popconfirm>
                        &nbsp;
                        &nbsp;
                        <Popconfirm
                        title={i18n.t('groups.removeWarning')}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        onConfirm={
                            (e) => {
                                let removingUsers = new Array()
                                for(let i = 0; i < record.members.length; i++){
                                    removingUsers.push(record.members[i].id.username)
                                }
                                this.removeUsers(this.state.annotaTask.id.id,removingUsers)
                            }
                        }
                    >
                            <Button className="button-default-groups-table" size="small"> {i18n.t('keywords.delete')} </Button>
                        </Popconfirm>
                    </div>
                ),
            },
            {

            },
        ];
        return (
            <div className='mini-component'>
                    <ConfigProvider locale={tr_TR}>
                        <Row type='flex' justify='start' style={{ marginTop: '1%' }} gutter={8}>
                            <Col span = {12} >
                                <Row gutter={8}>
                                    <Col span={12}>
                                    <UserSelector
                                        parentMethod={this.getSelectedUsers}
                                        onChange={(value: string): void => {
                                        }}
                                        size="default"
                                        placeholder="Kullanıcı Ara"
                                        className="add-user-task-page"
                                        showArrow={true}
                                    />
                                    </Col>
                                    <Col span={3} >
                                    <Button type='primary' className="button-segment-details"  onClick={() => this.setUsers(this.state.annotaTask.id.id)}>{i18n.t('keywords.add')}</Button>
                                    </Col>
                                </Row>
                                <Col span={16} style={{marginTop:"1%"}}>
                                <List
                                    style={{ marginTop: '2%' }}
                                    rowKey={record => record.id}
                                    dataSource={this.state.annotaTask.users}
                                    size="small"
                                    pagination={{size:"small", defaultPageSize: 5, showSizeChanger: true, position: 'bottom' , pageSizeOptions: ['5', '10', '20']}}
                                    locale={{ emptyText: i18n.t('datasets.noSpecialAnnotators') }}
                                    renderItem={record => (
                                        <div style={{ display: "flex" }}>
                                            <Text style={{ marginBottom: "10px", marginRight: "7px", padding: "2%", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>
                                                {record.username}
                                                <Popconfirm
                                                title={i18n.t('datasets.deleteUserWarning')}
                                                cancelText={i18n.t('keywords.no')}
                                                okText={i18n.t('keywords.yes')}
                                                onConfirm={
                                                    (e) => {
                                                        this.removeUsers(this.state.annotaTask.id.id,[record.username])
                                                    }
                                                }
                                                >
                                                    <Icon style={{float:"right", padding:"1%"}} type="delete" />
                                                </Popconfirm>
                                            </Text>

                                        </div>
                                    )}
                                >
                                </List>
                                </Col>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <Text strong>Kullanıcı Grupları</Text>
                                    <br/>
                                    <Text type="secondary">Eklediğiniz gruptaki üyeler görev etiketleyicilerine eklenir.</Text>
                                    <Table
                                        className="cvat-task-groups-table"
                                        rowClassName={(record,index)=> {return"table-row-groups"}}
                                        rowKey={record => record.id}
                                        expandedRowRender={(record) =>this.handleExpand(record)}
                                        locale={{emptyText: i18n.t('groups.noUserGroups')}}
                                        columns={columnsUserGroups}
                                        expandIconColumnIndex={2}
                                        expandIconAsCell={false}
                                        expandIcon={({ expanded, onExpand, record }) =>

                                            <Icon type="down" onClick={e => onExpand(record, e)} />

                                        }
                                        dataSource={this.state.userGroups}
                                        pagination={{ size:"small", defaultPageSize: 5, showSizeChanger: true, position: 'bottom' , pageSizeOptions: ['5', '10', '20']}}>
                                    </Table>
                                </div>
                            </Col>
                            <Col span={8}></Col>
                        </Row>

                    </ConfigProvider>
            </div>
        )
    }
}
export default withRouter(connect()(TasksUsers));
