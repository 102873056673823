import '../styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Tabs, Progress, Spin } from 'antd';
import 'antd/dist/antd.css';
import getCore from '../../cvat-core-wrapper';

import i18n from '../../i18n';
import Column from 'antd/lib/table/Column';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';

const { TabPane } = Tabs;
const cvat = getCore();
let backendEndPoint = String(cvat.config.backendAPI)
const { Option } = Select;
interface Props {
    taskId: string;
    visible: boolean;
    setInvisible: () => void;
    startDate:any;
    endDate:any;
    userId:any;
}
/**
 * @class_component defining event functions and passing props to render Job Statistics Modal with userJobs template
 */
class DashboardJobStatisticsPage extends Component<Props> {
    constructor(props: any) {
        super(props);
        this.state = {
            userJobs: [],
            // filter: "current",
            // total_annotation: 0
        }
        this.callback = this.callback.bind(this);
    }
    public componentDidUpdate(prevProps:any): void {
        if (this.props.taskId !== prevProps.taskId || this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate || this.props.userId !== prevProps.userId)
            this.callback(this.props.taskId, this.props.startDate, this.props.endDate, this.props.userId);
    }

    /**
     * @async
     * @param key
     * @param startDate
     * @param endDate
     * @param userId
     * @description gets dashbboard statistics and creates template for passing to Modal component
     */
    public async callback(key: any, startDate: any, endDate: any, userId: any) {
        if (key) {
            let response = await cvat.server.getDashboardDataStatistics(key, startDate, endDate, userId);
            let jobs = response.jobs

            this.setState({
                userJobs:
                <ConfigProvider locale ={trTR}>
                    <Table style={{ width:"100%" }}
                        rowKey={record => record.id}
                        size="small"
                        dataSource={jobs}
                        pagination={{ defaultPageSize: 5, showSizeChanger: true, position: 'bottom', pageSizeOptions: ['5', '10', '20'] }}
                    >
                        <Column align='center' title={i18n.t('dashboard-page.assignee')} dataIndex="assignee" />

                        <Column align='center' title={i18n.t('annotation-eval-page.jobID')} dataIndex="id" />

                        {/* <Column align='center' title={i18n.t('annotation-eval-page.taskID')} dataIndex="task_id" /> */}

                        <Column align='center' title="Başarım Puanı" render={(record) => <>
                            {(record.score === 0 ? "-" : ((record.score-0.5)*100).toPrecision(3)+"%")}</>
                            } />

                        <Column align='center' title={i18n.t('annotation-eval-page.annotationScore')} render={(record) => <>
                            {(record.score === 0 ? "-" : (record.annotation_point))}</>
                            } />

                        {/* <Column align='center' title={i18n.t('statistics-page.annotation')} render={(record) =>
                            <>{(record.shapeCount + record.tagCount === 0 ? "-" : record.shapeCount + record.tagCount)}</>
                            } />

                        <Column align='center' title={i18n.t('statistics-page.track')} render={(record) =>
                            <>{(record.interpolationCount + record.trackerUsedObjectsCount === 0 ? "-" : record.interpolationCount + record.trackerUsedObjectsCount)}</>
                            } /> */}

                        <Column align='center' title={i18n.t('statistics-page.totalAnnotation')} render={(record) =>
                            <>{(record.total === 0 ? "-" : record.total)}</>
                        } />

                        <Column align='center' title={i18n.t('statistics-page.completedTime')} render={(record) =>
                            <>{record.completed_at}</>
                        } />

                    </Table>
                </ConfigProvider>
            });
        }
    }

    public renderUserJobs() {
        return this.state.userJobs;
    }

    // public async setFilter(takenFilter: any) {
    //     this.setState({
    //         filter: takenFilter,
    //     }, () => {
    //         this.callback(this.props.userId, this)
    //     });
    // }

    /**
     *
     * @returns Modal instance using user jobs template containing statistics
     */
    public render() {
        return (
            <Modal
                width="60%"
                maskClosable={false}
                title="Detaylar"
                visible={this.props.visible}
                footer={<Button type="primary" onClick={() => this.props.setInvisible()}> Ok </Button>}
                destroyOnClose={true}
                closable={false}>

                {this.renderUserJobs()}

            </Modal>
        )
    }
}

export default withRouter(connect(
)(DashboardJobStatisticsPage));
