import '../../styles.scss';
import React from 'react';

import {
    Row,
    Col,
} from 'antd';
import { useTranslation } from 'react-i18next';

import Text from 'antd/lib/typography/Text';

import ModifyDatasetContent, { ModifyDatasetData } from './modify-dataset-content';

interface Props {
    onCreate: (data: ModifyDatasetData) => void;
    status: string;
    installedGit: boolean;
    user: any;
}

/**
 *
 * @param props
 * @returns Modify Dataset Page Component for /datasets/modify
 */
export default function ModifyDatasetPage(props: Props): JSX.Element {
    const {
        status,
        onCreate,
        installedGit,
        user
    } = props;
    const { t } = useTranslation();

    /**
     * @description Modify Dataset Page Template rendering title and ModifyDatasetContent Component instance
     */
    return (
        <div className="approval-page">
            <Text className='cvat-title'>{t('modify-dataset-page.expandDataset')}</Text>
            <Row type='flex' justify='center' align='top' className='cvat-create-task-form-wrapper'>
                <ModifyDatasetContent
                    status={status}
                    onCreate={onCreate}
                    installedGit={installedGit}
                    user={user}
                />
            </Row>
        </div>
    );
}
