/**
 * @description Training page tour selector and content
 */
const testTrainingPageSteps = [
    {
        selector: '[data-tour="step-training"]',
        content: "Bu sekmeye tıklayarak eğitim dokümanlarını görüntüleyebilirsiniz. Kullanım kılavuzunu indirip, örnek kullanım videolarını izleyebilirsiniz. "
    },
    {
        selector: '[data-tour="step-test"]',
        content: "Bu sekmeye tıklayarak test görevlerini görüntüleyebilirsiniz. Tamamladığınız testlerin puanlarını görüntüleyebilir, yeni test alabilir, devam eden testinize ulaşıp tamamlayabilirsiniz."
    },
    {
        selector: '[data-tour="step-trial"]',
        content: "Bu sekmeye tıklayarak eğitim görevlerini görüntüleyebilirsiniz. Eğitim görevleri test görevlerine başlamadan önce kendinizi denemek ve uygulamayı tanımak adına alabileceğiniz görevlerdir."
    },
    {
        selector: '[data-tour="step-tour-icon"]',
        content: "Açık olan sayfada gezintiye çıkmak için bu butonu kullanabilirsiniz. "
    },


  ];

  export default testTrainingPageSteps;
