import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Table, Input, Radio, Card, Icon, Modal } from 'antd';
import getCore from 'cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import i18n from '../i18n';
import { EditOutline } from '@ant-design/icons';

const cvat = getCore();

/**
 * @class_component ChangePassword Component
 */
class ChangePassword extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            user: null,
        }
        this.getUser();

    }

    /**
     * @async
     * @description get annota user by using get user api proxy calls, set state
     */
    async getUser() {
        const [user] = await cvat.users.get({ self: true });
        const annotaUser = await cvat.users.getAnnotaSelf(user.id);

        this.setState({
            user: annotaUser["models_to_return"][0],
            pass: "",
            newPass: "",
            newPassValidation: "",
        })
    }

    /**
     * @async
     * @description changePassword api proxy call, show Modal on success and error
     */
    private async changePass() {
        const response = await cvat.users.changePassword(this.state.user.id_id, this.state.pass, this.state.newPass);
        if (response.Success) {
            Modal.info({
                title: "Bilgilendirme",
                content: "Şifre Başarıyla Değiştirildi!",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: "Hata",
                content: "Şifre Değiştirilemedi, Şu Anki Şifrenizi Doğru Giriniz!",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     *
     * @returns password validation result
     */
    private checkPass() {
        const pass = this.state.newPass;
        const pattern1 = /(?=.{8,})/
        const pattern2 = /(?=.*[0-9])/
        const pattern3 = /(?=.*[A-ZĞÜŞÖÇİ])/
        const pattern4 = /(?=.*[a-zığüşöç])/
        return pattern1.test(pass) && pattern2.test(pass) && pattern3.test(pass) && pattern4.test(pass)
    }

    /**
     * @description submit new password
     */
    private submit() {
        if (this.state.newPass !== this.state.newPassValidation) {
            Modal.error({
                title: "Hata",
                content: "Yeni şifre uyuşmuyor!",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        } else if (!this.checkPass()) {
            Modal.error({
                title: "Hata",
                content: "Şifrenizde en az 1 büyük harf, 1 küçük harf, 1 rakam olmalıdır ve en az 8 karakter olmalıdır!",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        }
        else {
            this.changePass();
        }
    }

    /**
     *
     * @returns change password template
     */
    render() {
        return (
            <div>
                <Col style={{ marginTop: "5%"}}>
                    <Row style={{ marginBottom: "1%" }}>
                        <Input placeholder="Eski Şifrenizi Girin" type="password" onChange={(e) => this.setState({ pass: e.target.value })} />
                    </Row>
                    <Row style={{ marginBottom: "1%"}}>
                        <Input placeholder="Yeni Şifrenizi Girin" type="password" onChange={(e) => this.setState({ newPass: e.target.value })} />
                    </Row>
                    <Row style={{ marginBottom: "1%"}}>
                        <Input placeholder="Yeni Şifrenizi Tekrar Edin" type="password" onChange={(e) => this.setState({ newPassValidation: e.target.value })} />
                    </Row>
                    <Row style={{ marginBottom: "1%"}}>
                        <Button style={{float:"right"}} type="primary" onClick={(e) => this.submit()}>Değiştir</Button>
                    </Row>
                </Col>
            </div>
        )
    }
}

export default withRouter(connect(
)(ChangePassword));
