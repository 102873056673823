import React, {Component} from 'react';
import {
    Row,
    Col,
    Modal,
    Button,
    Select,
    Table,
    Checkbox
} from 'antd';

import { connect } from 'react-redux';
import { withRouter,BrowserRouter as Router,Route,Switch,Link,Redirect } from 'react-router-dom';
import { updateJoblistAsync } from '../../actions/tasks-actions';
import i18n from '../../i18n'

import getCore from '../../cvat-core-wrapper';
import { any } from 'prop-types';
import { Task } from '../../reducers/interfaces';
import Title from 'antd/lib/typography/Title';
import Column from 'antd/lib/table/Column';

const core = getCore();
var myMapForUserSelections = new Map();

interface DispatchToProps {
    updateJoblist: (taskInstanceForJobListUpdate: any) => void;
}

function mapStateToProps(state: any): object {
    return {
    };
}

/**
 *
 * @param dispatch
 * @returns updateJobList action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateJoblist: (taskInstanceForJobListUpdate: any): void => dispatch(updateJoblistAsync(taskInstanceForJobListUpdate)),
    };
}
interface Props {
    viewer:boolean;
    taskInstance:Task;
    user:any;
}

/**
 * @class_component Segment Details Component for task page segment detail view
 */
class SegmentDetails extends Component<Props> {
    constructor(props:any){
        super(props);
        this.state = {
            segments: [],
            annotaTask: any,
            segmentAssigneeCounts:[],
            user: any,
            users: [],
            setViewers:false,
        };
    }
    componentDidMount() {
        this.getAnnotaTask(this.props.taskInstance.id)
    }

    /**
     * @async
     * @param segmentsTaken
     * @description prepare segments and update state
     */
    async handleSegments(segmentsTaken: any[]){

        let segments = new Array();
        for (let i = 0; i < segmentsTaken.length; i++) {
            let segmentStatus = "Pending"
            let segmentStatusIdentifier = ""
            let currentSegmentInfo = segmentsTaken[i].jobs
            let currentSegmentAssigneeCount = currentSegmentInfo.length
            let completedJobs = currentSegmentInfo.filter(object => object.status == "completed")
            if(completedJobs.length == 0){
                segmentStatus = "Pending"
                segmentStatusIdentifier = i18n.t('tasks-page.pending')
            }
            else if(completedJobs.length < currentSegmentAssigneeCount){
                segmentStatus = "In Progress"
                segmentStatusIdentifier = i18n.t('tasks-page.inProgress')
            }
            else{
                segmentStatus = "Completed"
                segmentStatusIdentifier = i18n.t('tasks-page.completed')
            }
            segments.push(
                {
                    id:segmentsTaken[i].id,
                    frames: segmentsTaken[i].start_frame + "-" + segmentsTaken[i].stop_frame,
                    assigneeLimit:this.state.annotaTask.numOfAssigneeLimit,
                    assignedUserCount:currentSegmentAssigneeCount
                }
            )
        }
        this.setState({
            segments : segments
        })
    }

    /**
     *
     * @param value
     * @param segmentId
     */
    public onChangeSpecialAnnotatorList(value: any, segmentId: any) {
        myMapForUserSelections.set(segmentId, value);
    }

    /**
     * @async
     * @description pass segments passed with props to handler
     */
    async getSegments(){
        //const [task] = await core.tasks.get({ id: this.props.taskInstance.id });
        this.handleSegments(this.props.taskInstance.segments)
    }

    /**
     * @async
     * @param taskId
     * @description get annota task and user list and set state
     */
    async getAnnotaTask(taskId: any){
        let response = null;
        response = await core.tasks.getAnnotaTasks(null,taskId);
        let users = await core.users.get({ self: true });
        let defaultUserList;
        if(!response.isPrivate){
            let usersAfterSearch = await core.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": ''});
            let usersFinal = new Array()
            for(let i = 0; i < usersAfterSearch.users.length ; i++){
                usersFinal.push(usersAfterSearch.users[i].id)
            }
            defaultUserList = usersFinal
        }else{
            defaultUserList = response.users
        }
        this.setState({
            annotaTask : response,
            user: users[0],
            users: defaultUserList
        }, () => {
            this.getSegments()
        });
    }

    /**
     *
     * @param segmentId
     * @description assign task using param and update job list, handle error and success with Modals
     */
    private async handleClickAssignSegment(segmentId: any){
        let tryApiAssignTask = await core.tasks.assigntask(segmentId)
        let response = tryApiAssignTask.data.status;
        this.props.updateJoblist(this.props.taskInstance)
        if(response != i18n.t('response-messages.success')){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: response,
            });
        }
        else{
            Modal.success({
                title: i18n.t('keywords.success'),
                content: response,
            });
        }
        this.componentDidMount()
    }

    /**
     * @async
     * @param segmentId
     * @description assign segment of task to another user
     */
    private async handleClickAssignSegmentToOtherUser(segmentId: any){
        let selectedUserIdForSegment = myMapForUserSelections.get(segmentId)
        if(selectedUserIdForSegment != null){
            let tryApiAssignTask = await core.tasks.assigntask(segmentId,selectedUserIdForSegment)
            let response = tryApiAssignTask.data.status;
            this.props.updateJoblist(this.props.taskInstance)
            if(response !=  i18n.t('response-messages.success')){
                Modal.error({
                    title: i18n.t('keywords.error'),
                    content: response,
                });
            }
            else{
                Modal.success({
                    title: i18n.t('keywords.success'),
                    content: response,
                });
            }
            this.componentDidMount()
        }
    }

    /**
     * @description return Segment Detail Table template
     */
    renderSegments(){
        return(
            <Table className="custom-table-segments" rowKey={record => record.id} dataSource={this.state.segments} pagination={false} locale={{emptyText: 'Sonuç Bulunamadı'}}>
                <Column title={i18n.t('keywords.frames')} dataIndex="frames"/>
                <Column title={i18n.t('segment-details.assigneeLimit')} dataIndex="assigneeLimit" />
                <Column title={i18n.t('segment-details.assignedUserCount')} dataIndex="assignedUserCount" />
                <Column render={(record)=>{
                    return(
                        <div>
                                {(this.state.annotaTask.id.owner.id == this.state.user.id && this.state.annotaTask.datasetConnection != null)&&
                                    <>
                                    <div style={{display:"contents"}} >
                                        <Select
                                            size="default"
                                            className="select-user"
                                            showArrow={true}
                                            showSearch
                                            onSearch={async (value: string): Promise<void> => {
                                                if(value.length >= 3){
                                                    let usersFinal;
                                                    if(!this.state.annotaTask.isPrivate){
                                                        let usersAfterSearch = await core.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value});
                                                        usersFinal = new Array()
                                                        for(let i = 0; i < usersAfterSearch.users.length ; i++){
                                                            usersFinal.push(usersAfterSearch.users[i].id)
                                                        }
                                                    }else{
                                                        usersFinal = this.state.annotaTask.users
                                                    }
                                                    this.setState({
                                                        users : usersFinal,
                                                    }, () => {
                                                        this.getSegments()
                                                    });
                                                }
                                            }}
                                            style={{ width: "225px",margin:"5px" }}
                                            placeholder={i18n.t('segment-details.chooseAnnotator')}
                                            optionFilterProp="children"
                                            onChange={(e) => this.onChangeSpecialAnnotatorList(e,record.id)}
                                            onDropdownVisibleChange = {async (value: string): Promise<void> => {
                                                this.getAnnotaTask(this.props.taskInstance.id)
                                            }}
                                        >
                                            {this.state.users.map((user): JSX.Element => (
                                                <Select.Option key={user.username} value={user.id}>
                                                    {user.username}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                        <Button type='primary' style={{margin:"5px"}} className="button-segment-details"  onClick={() => this.handleClickAssignSegmentToOtherUser(record.id)}>{i18n.t('segment-details.assign')}</Button>
                                    </>}
                                {!this.props.viewer &&
                                        <Button type='primary' style={{margin:"5px"}}  className="button-segment-details"  onClick={() => this.handleClickAssignSegment(record.id)}>{i18n.t('segment-details.assignToMe')}</Button>
                                }
                        </div>
                    );
                }}
                />
            </Table>
        )
    }

    /**
     *
     * @returns Title and segment detail template using class functions
     */
    render () {

        return (
             <div style={{width:"100%"}} data-tour="step-segments">
                <div style={{width:"100%" ,marginTop:"2.5%"}}>
                    <Row type='flex' justify='start'>
                        <Col span={24}>
                            <div className="title-component">
                                <Title
                                    level={4}
                                    className='cvat-text-color'
                                    style={{marginTop:"1%",marginLeft:"2%"}}
                                >
                                    {i18n.t('keywords.segments')}
                                </Title>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='cvat-task-details' style={{marginTop:"-2px"}}>
                    <Row type='flex' justify='start' align='middle'>
                        <Col span = {24}>
                            {this.renderSegments()}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SegmentDetails));
