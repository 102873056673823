// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Breadcrumb from 'antd/lib/breadcrumb'
import Dropdown from 'antd/lib/dropdown';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';

import { CVATLogo, AccountIcon, VeriKovaniLogo, EvalIcon, LogsIcon } from 'icons';
import consts from 'consts';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { TestTrainingIcon } from '../../icons';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

interface HeaderContainerProps {
    onLogout: () => void;
    logoutFetching: boolean;
    installedAnalytics: boolean;
    installedAutoAnnotation: boolean;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    serverHost: string;
    username: string;
    isAdmin: boolean;
    groups: any;
    toolName: string;
    serverVersion: string;
    serverDescription: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
    switchSettingsShortcut: string;
    annotaUser: any
}

type Props = HeaderContainerProps & RouteComponentProps;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

/**
 * Check mobile usage
 *
 * @returns true if mobile is used
 */
function detecMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true
    } else {
        return false
    }
}


function SidebarContainer(props: Props): JSX.Element {
    const {
        installedTFSegmentation,
        installedAutoAnnotation,
        installedTFAnnotation,
        installedAnalytics,
        username,
        groups,
        toolName,
        serverHost,
        serverVersion,
        serverDescription,
        coreVersion,
        canvasVersion,
        uiVersion,
        onLogout,
        logoutFetching,
        isAdmin,
        switchSettingsShortcut,
        annotaUser
    } = props;

    let permissions = annotaUser[0].group_role_permissions
    let hasStatsPermission = permissions.filter(object => object.permission_id__name == 'view_system_usage').length > 0
    let hasLogsPermission = permissions.filter(object => object.permission_id__name == 'view_logs').length > 0
    let hasDatasetCreatePermission = permissions.filter(object => object.permission_id__name == 'create_dataset').length > 0
    let hasUserGroupsCreatePermission = permissions.filter(object => object.permission_id__name == 'create_user_groups').length > 0
    let hasListDatasetPermission = permissions.filter(object => object.permission_id__name == 'list_datasets').length > 0
    let hasDashboardViewPermission = permissions.filter(object => object.permission_id__name  == 'view_dashboard').length > 0
    let hasTestTrainingViewPermission = permissions.filter(object => object.permission_id__name  == 'view_test_training').length > 0
    let hasViewTasksPermission = permissions.filter(object => object.permission_id__name  == 'annotate_image').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'annotate_text').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'annotate_video').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'annotate_3dpc').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'create_task').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'approve_tasks').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'dump_annotations').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'export_images').length > 0 ||
        permissions.filter(object => object.permission_id__name  == 'share_task').length > 0

    let hasEvaluateTestsPermission = permissions.filter(object => object.permission_id__name  == 'evaluate_tests').length > 0
    let hasApproveTasksPermission = permissions.filter(object => object.permission_id__name  == 'approve_tasks').length > 0
    let hasViewUsersPermission = permissions.filter(object => object.permission_id__name  == 'view_users').length > 0


    const [collapsed, setCollapse] = useState(window.location.href.includes("/jobs/"));
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [scrolled, setScroll] = useState(0);

    const renderModels = installedAutoAnnotation
        || installedTFAnnotation
        || installedTFSegmentation;

    const {
        CHANGELOG_URL,
        LICENSE_URL,
        GITTER_URL,
        FORUM_URL,
        GITHUB_URL,
    } = consts;

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        if (window.location.href.includes("/jobs/") || innerHeight >= innerWidth) {
            setCollapse(true)
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll)
        }
    }, []);

    /**
     * Handle scroll
     * @param e event
     */
    var handleScroll = function (e) {
        if (e.path[1].scrollY > 150) {
            setScroll(e.path[1].scrollY);
        } else {
            setScroll(0)
        }
    };

    /**
     * Create Modal to render versions
     */
    function aboutModal(): void {
        Modal.info({
            title: `${toolName}`,
            content: (
                <div>
                    <p>
                        {`${serverDescription}`}
                    </p>
                    <p>
                        <Text strong>
                            Server version:
                        </Text>
                        <Text type='secondary'>
                            {` ${serverVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Core version:
                        </Text>
                        <Text type='secondary'>
                            {` ${coreVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Canvas version:
                        </Text>
                        <Text type='secondary'>
                            {` ${canvasVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            UI version:
                        </Text>
                        <Text type='secondary'>
                            {` ${uiVersion}`}
                        </Text>
                    </p>
                    <Row type='flex' justify='space-around'>
                        <Col><a href={CHANGELOG_URL} target='_blank' rel='noopener noreferrer'>{'What\'s new?'}</a></Col>
                        <Col><a href={LICENSE_URL} target='_blank' rel='noopener noreferrer'>License</a></Col>
                        <Col><a href={GITTER_URL} target='_blank' rel='noopener noreferrer'>Need help?</a></Col>
                        <Col><a href={FORUM_URL} target='_blank' rel='noopener noreferrer'>Forum on Intel Developer Zone</a></Col>
                    </Row>
                </div>
            ),
            width: 800,
            okButtonProps: {
                style: {
                    width: '100px',
                },
            },
        });
    }

    const { t } = useTranslation();

    const menu = (
        <Menu className='cvat-header-menu' mode='vertical'>
            <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/profile')
                }
            >
                <Icon type='setting' />
                {t('keywords.profile')}
            </Menu.Item>
            <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/change-pass')
                }
            >
                <Icon type='lock' />
                Şifre Değiştir
            </Menu.Item>
            <Menu.Item
                //title={`Press ${switchSettingsShortcut} to switch`}
                onClick={
                    (): void => props.history.push('/payments')
                }
            >
                <Icon type='credit-card' />
                Ödeme
            </Menu.Item>
            {/* <Menu.Item onClick={() => aboutModal()}>
                <Icon type='info-circle' />
                {t('header.about')}
            </Menu.Item> */}
            <Menu.Item
                id="logOut"
                onClick={onLogout}
                disabled={logoutFetching}
            >
                {logoutFetching ? <Icon type='loading' /> : <Icon type='logout' />}
                {t('header.logOut')}
            </Menu.Item>

        </Menu>
    );

    /**
     * Create list of Menu.Item based on permissions
     * @returns Array of Menu.Item
     */
    function prepareMenu(): any {
        let menuItemsArr = new Array();
        let userIsAdmin = isAdmin || groups.includes("admin")
        if (userIsAdmin || hasDashboardViewPermission) {
            menuItemsArr.push(

                <Menu.Item key="1"
                    id="dashboard"
                    className='cvat-header-button'
                    onClick={
                        (): void => {
                            if (!groups.includes('candidate')) {
                                props.history.push('/dashboard')
                            } else {
                                Modal.info({
                                    title: 'Bilgi',
                                    content: "Pano sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                    okText: 'Tamam',
                                });
                            }
                        }
                    }>
                    <Icon className="header-icon" style={{ color: "white" }} type='home' />
                    <span>{t('header.dashboard')}</span>
                </Menu.Item>);
            !collapsed && menuItemsArr.push(<Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />)
        }

        if (userIsAdmin || hasViewTasksPermission) {
            !(userIsAdmin || hasApproveTasksPermission) ? menuItemsArr.push(
                <Menu.Item key="2"
                    id="tasks"
                    className='cvat-header-button'
                    onClick={
                        (): void => {
                            if (!groups.includes('candidate')) {
                                props.history.push('/tasks?page=1')
                            } else {
                                Modal.info({
                                    title: 'Bilgi',
                                    content: "Görevler sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                    okText: 'Tamam',
                                });
                            }
                        }

                    }>
                    <Icon className="header-icon" style={{ color: "white" }} type='unordered-list' />
                    <span>{t('header.tasks')}</span>
                </Menu.Item>
            ) : menuItemsArr.push(
                <SubMenu popupClassName="sider-popup" title={collapsed ? <Icon className="header-icon" style={{ color: "white" }} type='unordered-list' /> : <span><Icon className="header-icon" style={{ color: "white" }} type='unordered-list' />{t('header.tasks')}</span>}>
                    <Menu.Item key="2"
                        id="tasks"
                        className='cvat-header-button'
                        onClick={
                            (): void => {
                                if (!groups.includes('candidate')) {
                                    props.history.push('/tasks?page=1')
                                } else {
                                    Modal.info({
                                        title: 'Bilgi',
                                        content: "Görevler sayfasını görüntüleyebilmek için yönetici onayınızı bekleyiniz.",
                                        okText: 'Tamam',
                                    });
                                }
                            }
                        }>
                        <span>{t('header.tasks')}</span>
                    </Menu.Item>
                    <Menu.Item key="3"
                        id="pendingTasks"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/approve-tasks')
                        }>
                        <span>{t('header.pendingTasks')}</span>
                    </Menu.Item>
                </SubMenu>)

            !collapsed && menuItemsArr.push(<Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />);
        }

        !(userIsAdmin || hasEvaluateTestsPermission) ?
            (hasTestTrainingViewPermission && menuItemsArr.push(
            <Menu.Item key="4"
                id="test"
                className='cvat-header-button'
                onClick={
                    (): void => props.history.push('/testtraining')
                }

            >
                <Icon className="header-icon" style={{ color: "white" }} component={TestTrainingIcon}></Icon>
                <span>{t('header.test')}</span>
            </Menu.Item>))
            : menuItemsArr.push(
                <SubMenu popupClassName="sider-popup" title={collapsed ? <Icon className="header-icon" style={{ color: "white" }} component={TestTrainingIcon} /> : <span><Icon className="header-icon" style={{ color: "white" }} component={TestTrainingIcon} />Eğitim</span>}>
                    <Menu.Item key="4"
                        id="test"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/testtraining')
                        }
                    >
                        <span>{t('header.test')}</span>
                    </Menu.Item>
                    <Menu.Item key="5"
                        id="testApprove"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/evaluate-tests')
                        }>
                        <span>{t('header.testApprove')}</span>
                    </Menu.Item>
                </SubMenu>)
        !collapsed && menuItemsArr.push(<Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />);

        (userIsAdmin || hasDatasetCreatePermission || hasListDatasetPermission) && menuItemsArr.push(
            <SubMenu popupClassName="sider-popup" title={collapsed ? <Icon className="header-icon" style={{ color: "white" }} type="database" /> : <span><Icon className="header-icon" style={{ color: "white" }} type="database" />Veriler</span>}>
                {(userIsAdmin || hasDatasetCreatePermission) && (
                    <Menu.Item key="7"
                        id="uploadData"
                        className='cvat-header-button'
                        onClick={
                            (): void => {
                                if (detecMobile()) {
                                    Modal.info({
                                        content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                                        okText: 'Tamam',
                                    })
                                } else {
                                    props.history.push('/datasets/create')
                                }
                            }
                        }>
                        <span>{t('header.uploadData')}</span>
                    </Menu.Item>
                )}

                {(userIsAdmin || hasListDatasetPermission) && (
                    <Menu.Item key="8"
                        id="rawData"
                        className='cvat-header-button'
                        onClick={
                            (): void => {
                                if (detecMobile()) {
                                    Modal.info({
                                        content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                                        okText: 'Tamam',
                                    })
                                } else {
                                    props.history.push('/datasets')
                                }
                            }
                        }>
                        <span>{t('header.datasets')}</span>
                    </Menu.Item>
                )}
                {/* {(isAdmin || groups.includes('ssbprovider')) &&(
            <Menu.Item key="9"
            id="rawData"
            className='cvat-header-button'
            onClick={
                (): void => props.history.push('/ssb/datasets')
            }>
                <span>SSB Veri Kümeleri</span>
            </Menu.Item>
        )} */}
            </SubMenu>)
        if (userIsAdmin || hasUserGroupsCreatePermission && !collapsed) {
            menuItemsArr.push(
                <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
            )
        }
        if (userIsAdmin) {
            menuItemsArr.push(
                <SubMenu popupClassName="sider-popup" title={collapsed ? <Icon className="header-icon" style={{ color: "white" }} type="team" /> : <span><Icon className="header-icon" style={{ color: "white" }} type="team" />Kullanıcılar</span>}>
                    <Menu.Item key="10"
                        id="providerGroupPage"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/providergroup')
                        }>
                        <span>Kullanıcı Grupları</span>
                    </Menu.Item>
                    <Menu.Item key="6"
                        id="manageUsers"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/manage-users')
                        }>
                        <span>Kullanıcı Listesi</span>
                    </Menu.Item>
                    <Menu.Item key="18"
                        id="manageRoles"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/roles')
                        }>
                        <span>Kullanıcı Rolleri</span>
                    </Menu.Item>
                </SubMenu>)
                if (userIsAdmin && !collapsed)
                menuItemsArr.push(
                    <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
            )
        }
        else if (!userIsAdmin && hasViewUsersPermission && hasUserGroupsCreatePermission) {

            menuItemsArr.push(
                <SubMenu popupClassName="sider-popup" title={collapsed ? <Icon className="header-icon" style={{ color: "white" }} type="team" /> : <span><Icon className="header-icon" style={{ color: "white" }} type="team" />Kullanıcılar</span>}>

                <Menu.Item key="6"
                        id="manageUsers"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/manage-users')
                        }>
                        <span>Kullanıcı Listesi</span>
                    </Menu.Item>
                    <Menu.Item key="10"
                        id="providerGroupPage"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/providergroup')
                        }>
                        <span>Kullanıcı Grupları</span>
                    </Menu.Item>
                    </SubMenu>
                )
                if (userIsAdmin || hasViewUsersPermission && !collapsed)
                    menuItemsArr.push(
                        <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
                )
        }
        else if (!userIsAdmin && hasUserGroupsCreatePermission) {
            menuItemsArr.push(
                <Menu.Item key="10"
                    id="providerGroupPage"
                    className='cvat-header-button'
                    onClick={
                        (): void => props.history.push('/providergroup')
                    }>
                    <Icon className="header-icon" style={{ color: "white" }} type="team" />
                    <span>Kullanıcı Grupları</span>
                </Menu.Item>)
            if (userIsAdmin || hasUserGroupsCreatePermission && !collapsed)
                menuItemsArr.push(
                    <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
                )
        }
        else if (!userIsAdmin && hasViewUsersPermission) {
            menuItemsArr.push(
                <Menu.Item key="6"
                        id="manageUsers"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/manage-users')
                        }>
                        <Icon className="header-icon" style={{ color: "white" }} type="team" />
                        <span>Kullanıcı Listesi</span>
                    </Menu.Item>)
            if (userIsAdmin || hasViewUsersPermission && !collapsed)
                menuItemsArr.push(
                    <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
                )
        }
        // renderModels && menuItemsArr.push(
        //         <Menu.Item key="12"
        //             id="models"
        //             className='cvat-header-button'
        //             onClick={
        //                 (): void => props.history.push('/models')
        //             }>
        //             <Icon className="header-icon" style={{ color: "white" }} type="apartment"></Icon>
        //             <span>Models</span>
        //         </Menu.Item>
        //     );
        //     (renderModels && !collapsed) && menuItemsArr.push(
        //         <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
        //     )

        userIsAdmin && menuItemsArr.push(
            <Menu.Item key="15"
                id="edy"
                className='cvat-header-button'
                onClick={
                    (): void => props.history.push('/annotation-eval-page')
                }>
                <Icon className="header-icon" style={{ color: "white" }} component={EvalIcon}></Icon>
                <span>{t('header.annotationEvaluation')}</span>
            </Menu.Item>
        );
        (userIsAdmin && !collapsed) && menuItemsArr.push(
            <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
        )

        if (userIsAdmin || hasLogsPermission || hasStatsPermission) {
            if (userIsAdmin || hasLogsPermission) {
                menuItemsArr.push(
                    <Menu.Item key="14"
                        id="logs"
                        className='cvat-header-button'
                        onClick={
                            (): void => props.history.push('/logs')
                        }>
                        <Icon className="header-icon" style={{ color: "white" }} component={LogsIcon}></Icon>
                        <span>Loglar</span>
                    </Menu.Item>
                );
            }
            if (!collapsed)
                menuItemsArr.push(<Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
                );
            if (hasStatsPermission) {
                menuItemsArr.push(<Menu.Item key="17"
                    id="contact-us"
                    className='cvat-header-button'
                    onClick={
                        (): void => props.history.push('/stats')
                    }>
                    <Icon className="header-icon" style={{ color: "white" }} type="bar-chart"></Icon>
                    <span>{t('header.systemResources')}</span>
                </Menu.Item>)
                if (!collapsed)
                    menuItemsArr.push(<Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%', minWidth: '83%', width: '83%' }} />
                    )
            }
        }
        return menuItemsArr;
    }

    let collapsedWidth = 85
    let trigger = null
    let position = 'sticky'
    let height = 'calc(100vh - 100px)'
    let width = "293px";
    let top = 100
    let overflow = 'auto'
    if (window.location.href.includes("/jobs/") || innerWidth / innerHeight < 1.7) {
        collapsedWidth = 0
        width = "0px";
        trigger = false
        overflow = 'inherit'
        // if(innerHeight >= innerWidth){
        if (innerWidth / innerHeight < 1.7) {

            position = 'fixed'
            height = 'calc(100vh - 60px)'
            top = 60
        }
    } if (scrolled > 0 && !(innerWidth / innerHeight < 1.7)) {

        let heightValue = 100 / (scrolled * 0.004);
        if (heightValue > 100)
            heightValue = 100;
        else if (heightValue < 60)
            heightValue = 60
        height = `calc(100vh - ${heightValue}px)`
        top = heightValue
    }

    return (
        //<Sider style={{ backgroundColor: '#0F123F',overflow: 'auto', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex:1 }} collapsible collapsed={collapsed} onCollapse={(e) => setCollapse(e)}>
        <Sider data-tour="step-menu" style={{ backgroundColor: '#0F123F', height: height, position: position, zIndex: 100, left: 0, top: top, bottom: 0, width: width, overflow: overflow }}
            collapsible
            width={width != "0px" ? "min-content" : "inherit"}
            collapsed={collapsed}
            onCollapse={(e) => setCollapse(e)}
            trigger={trigger}
            collapsedWidth={collapsedWidth}
            breakpoint="lg"
            zeroWidthTriggerStyle={{ backgroundColor: '#0F123F' }}
        >
            <div className="logo" />
            <Menu style={{ backgroundColor: '#0F123F' }} className="custom-sider-style" theme="dark" mode="inline">

                {prepareMenu()}
                {/* {(isAdmin) &&(
                <Menu.Item key="11"
                    id="managePlatform"
                    className='cvat-header-button'
                    onClick={
                        (): void => props.history.push('/manage-platform')
                    }>
                    <Icon className="header-icon" style={{color:"white"}} type="desktop"></Icon>
                    <span>{t('header.managePlatform')}</span>
                </Menu.Item>
            )} */}
                {/* {(isAdmin && !collapsed) &&(
                <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%',  minWidth: '83%', width:'83%'}} />
            )} */}
                {/* {(isAdmin ) &&(
                <Menu.Item key="16"
                    id="contact-us"
                    className='cvat-header-button'
                    onClick={
                        (): void => props.history.push('/contact-us')
                    }>
                    <Icon className="header-icon" style={{color:"white"}} type="mail"></Icon>
                    <span>Kullanıcıya Ulaş</span>
                </Menu.Item>
            )}
            {(isAdmin && !collapsed) &&(
                <Menu.Divider style={{ marginLeft: '10%', marginRight: '500%', marginTop: '-1%', marginBottom: '-1%',  minWidth: '83%', width:'83%'}} />
            )} */}{/* {(!groups.includes("system_admin")) &&(
                <Menu.Item key="18"
                    id="messages"
                    className='cvat-header-button'
                    onClick={
                        (): void => props.history.push('/messages')
                    }>
                    <Icon className="header-icon" style={{color:"white"}} type="message"></Icon>
                    <span>Mesajlarım</span>
                </Menu.Item>
            )} */}
            </Menu>
            {collapsed ?
                <img style={{ margin: "auto", marginTop: "5px", width: "43px", height: "43px", transform: "scale(0.8)", display: "block", cursor: "pointer" }} src="/sider_trigger_right.png" onClick={(e) => setCollapse(false)}></img> :
                <img style={{ margin: "auto", marginTop: "5px", width: "43px", height: "43px", transform: "scale(0.8)", display: "block", cursor: "pointer" }} src="/sider_trigger_left.png" onClick={(e) => setCollapse(true)}></img>}

            {!collapsed && <Text style={{ color: "#8187B0", position: "absolute", bottom: 30, left: 30 }} strong >Versiyon {process.env.REACT_APP_VERSION}</Text>}

        </Sider>
    );
}

export default withRouter(SidebarContainer);
