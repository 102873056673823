// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Model } from 'reducers/interfaces';
import BuiltModelItemComponent from './built-model-item';

interface Props {
    models: Model[];
}

/**
 *
 * @param props
 * @returns Built Model Item List as IntegratedModelsListComponent used in models-page
 */
export default function IntegratedModelsListComponent(props: Props): JSX.Element {
    const { models } = props;
    const { t } = useTranslation();

    /**
     * @description constructs items using BuiltModelItemComponent instances
     */
    const items = models.map((model): JSX.Element => (
        <BuiltModelItemComponent key={model.name} model={model} />
    ));

    /**
     * @description Renders BuiltModelItemComponents as table
     */
    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14}>
                    <Text className='cvat-text-color' strong>{t('keywords.primary')}</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14} className='cvat-models-list'>
                    <Row type='flex' align='middle' style={{ padding: '10px' }}>
                        <Col span={4} xxl={3}>
                            <Text strong>{t('models-page.framework')}</Text>
                        </Col>
                        <Col span={6} xxl={7}>
                            <Text strong>{t('keywords.name')}</Text>
                        </Col>
                        <Col span={5} offset={7}>
                            <Text strong>{t('keywords.labels')}</Text>
                        </Col>
                    </Row>
                    { items }
                </Col>
            </Row>
        </>
    );
}
