import React from 'react';
import { connect } from 'react-redux';

import { CombinedState } from 'reducers/interfaces';
import CreateDatasetPage from 'components/create-dataset-page/create-dataset-page';
import { CreateDatasetData } from 'components/create-dataset-page/create-dataset-content';
import { createDatasetAsync } from 'actions/tasks-actions';

interface StateToProps {
    status: string;
    installedGit: boolean;
    statusDatasetUploadProgress: string;
}

interface DispatchToProps {
    onCreate: (data: CreateDatasetData) => void;
}

/**
 *
 * @param dispatch
 * @returns onCreate action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onCreate: (data: CreateDatasetData): void => dispatch(createDatasetAsync(data)),
    };
}

/**
 *
 * @param state -> combined state containing tasks and plugins information
 * @returns creates task activity and git plugin information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    const { creates } = state.tasks.activities;
    return {
        ...creates,
        installedGit: state.plugins.list.GIT_INTEGRATION,
    };
}

/**
 *
 * @param props
 * @returns CreateDatasetPage component
 * @description Passes props to CreateDatasetPage
 */
function CreateDatasetPageContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <CreateDatasetPage {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateDatasetPageContainer);
