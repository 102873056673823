// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';

import { Workspace } from 'reducers/interfaces';
import { InfoIcon, FullscreenIcon, GuidelinesIcon, ShortcutIcon } from 'icons';
import { Tooltip, Modal, Popconfirm, Divider } from 'antd';
import MultiTracker from 'components/multi-track-mode'

interface Props {
    savingJobStatus: boolean;
    jobStatus: string;
    jobInstance: any;
    onSaveAnnotation(): void;
    onSaveAnnotationWaiting(): Promise<void>;
    saving: boolean;
    workspace: Workspace;
    hasGuidelinesFile: boolean;
    user: any;
    textTask: boolean;
    changeJobStatus(status: string): void;
    showStatistics(): void;
    changeWorkspace(workspace: Workspace): void;
    downloadTaskGuidelines(): void;
    showShortcuts(): void
}

/**
 * Create component group to start tracker and complete task
 *
 * @param props consists of saving, textTask, jobInstance, savingJobStatus, jobStatus variables and changeJobStatus, onSaveAnnotationWaiting functions
 * @returns Col
 */
function RightGroup(props: Props): JSX.Element {
    const { showStatistics, changeJobStatus, changeWorkspace, onSaveAnnotation,
        onSaveAnnotationWaiting, saving,
        downloadTaskGuidelines, showShortcuts,
        hasGuidelinesFile, workspace, textTask,
        jobInstance, savingJobStatus, jobStatus} = props;
    const { t } = useTranslation();

    const [isCompleteClicked, setIsCompleteClicked] = useState(false);

    function jobStatusChanger() {
        if (!props.user.groups.includes("admin") && props.user.id != jobInstance.task.owner.id) {
            Modal.confirm({
                title: "İşi tamamladığınızda bu işe geri dönemeyeceksiniz.",
                content:
                    "Yaptığınız etiketlerle birlikte işi tamamlamak istediğinize emin misiniz?",
                onOk: () => {
                    changeJobStatus("completed");
                },
                okButtonProps: {
                    type: "danger",
                },
                okText: "Tamamla",
                cancelText: "Vazgeç",
            });
        } else {
            changeJobStatus("completed");
        }
        setIsCompleteClicked(false);
    }

    async function changeJobStatusWrapper() {
        if(!saving){
            await onSaveAnnotationWaiting();
            jobStatusChanger();
        }
        else{
            jobStatusChanger();
        }
    }

    let projectType = jobInstance.task.tasksIdAnnota[0].doccanoProjectType
    let projectTypeTr =  "";
    if(projectType == "sequence-labeling"){
        projectTypeTr = "Metin Etiketleme"
    }else if(projectType == "text-classification"){
        projectTypeTr = "Doküman Sınıflandırma"

    }else if(projectType == "sequence-to-sequence"){
        projectTypeTr = "Sekans Öğrenme"
    }
    return (
        <Col data-tour="step-topbar-right" className='cvat-annotation-header-right-group'>
            { textTask &&
                <div>
                    <Divider type="vertical"></Divider>
                    <Text strong>Görev Tipi: {projectTypeTr}</Text>
{/*                     <Divider type="vertical"></Divider>
                    <Text>Görev Kategorisi: </Text> */}
                    <Divider type="vertical"></Divider>
                </div>
            }
            <MultiTracker></MultiTracker> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {
                !props.user.groups.includes('admin') && props.user.id != jobInstance.task.owner.id && jobInstance.task.tasksId.length > 0 && jobInstance.task.tasksId[0].taskType == "test" ? <div></div> :
                <Button
                    size="default"
                    className="complete-job-button"
                    style={{ marginTop:"-30%", marginRight:"30px"}}
                    disabled={jobStatus === "completed"}
                    type='primary'
                    loading={savingJobStatus}
                    //className='cvat-annotation-header-button'
                    onClick={() => setIsCompleteClicked(true)}
                >
                    {t('tasks-page.complete')}
                </Button>
            }
            <Modal
                visible={isCompleteClicked}
                title="İşi tamamladığınızda kaydedilmemiş etiketler kaybolacaktır!"
                onOk={async () => await changeJobStatusWrapper() }
                onCancel={() => setIsCompleteClicked(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setIsCompleteClicked(false)}
                    >
                        İptal
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={async () => await changeJobStatusWrapper()}
                    >
                        Kaydet ve tamamla
                    </Button>,
                    // <Button
                    //     onClick={() => jobStatusChanger()}
                    // >
                    //     Tamamla
                    // </Button>,
                ]}
            >
                <p>Güncel eklenen etiketleri kaydedelim mi?</p>
            </Modal>
            {/* <Button type='link' className='cvat-annotation-header-button' onClick={showShortcuts}>
                <Icon component={ShortcutIcon} />
                {t('top-bar.shortcuts')}
            </Button> */}
            {/* <Button
                type='link'
                className='cvat-annotation-header-button'
                onClick={(e: React.MouseEvent) => {
                    Modal.info({
                        width: 700,
                        icon: <Icon component={GuidelinesIcon} />,
                        title: t('keywords.guidelines'),
                        style: {whiteSpace: "pre-wrap"},
                        content:(<div>
                            {hasGuidelinesFile &&
                                <Row gutter={[8, 8]}>
                                    <Col>
                                    <Popconfirm
                                        title= "Veriyi indirmek istediğinize emin misiniz?"
                                        cancelText={t('keywords.no')}
                                        okText={t('keywords.yes')}
                                        onConfirm={
                                            (e) => downloadTaskGuidelines()
                                        }
                                    >
                                        <Button style={{marginTop:"5px"}} type="primary" ghost size="small">
                                        <Icon type="file-pdf"/>
                                        {t('top-bar.downloadGuidelines')}
                                        </Button>
                                    </Popconfirm>
                                    </Col>
                                </Row>
                            }
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <Divider/>
                                    <text>{jobInstance.task.guidelines}</text>
                                </Col>
                            </Row>
                            </div>
                        ),
                    });
                }}
            >
                <Icon component={GuidelinesIcon} />
                {t('keywords.guidelines')}
            </Button> */}
            {/* <Button
                type='link'
                className='cvat-annotation-header-button'
                onClick={(): void => {
                    if (window.document.fullscreenEnabled) {
                        if (window.document.fullscreenElement) {
                            window.document.exitFullscreen();
                        } else {
                            window.document.documentElement.requestFullscreen();
                        }
                    }
                }}
            >
                <Icon component={FullscreenIcon} />
                {t('top-bar.fullscreen')}
            </Button> */}
            {/* <Button type='link' className='cvat-annotation-header-button' onClick={showStatistics}>
                <Icon component={InfoIcon} />
                {t('top-bar.statistics')}
            </Button> */}
            {/* <div>
                {(Number(jobInstance.task.tasksIdAnnota[0].doccanoProjectId) == 0) ?
                <Select
                    className='cvat-workspace-selector'
                    onChange={changeWorkspace}
                    value={workspace}
                >
                    <Select.Option
                        key={Workspace.STANDARD}
                        value={Workspace.STANDARD}
                    >
                        {t('top-bar.standard')}
                    </Select.Option>
                    <Select.Option
                        key={Workspace.ATTRIBUTE_ANNOTATION}
                        value={Workspace.ATTRIBUTE_ANNOTATION}
                    >
                        {t('top-bar.attributeAnnotation')}
                    </Select.Option>
                </Select> : null }
            </div> */}
        </Col>
    );
}

export default React.memo(RightGroup);
