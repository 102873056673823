const dashboardPageStepsProvider = [
    {
        selector: '[data-tour="step-menu"]',
        content: "Veri Kovanı'na hoşgeldiniz. Ana menü seçenekleri burada listelenir."
    },
    {
        selector: '[data-tour="step-message"]',
        content: "Uygulama içinde platform yöneticisi veya veri sağlayıcılar ile mesajlaşmak için buraya tıklayabilirsiniz."
    },
    {
        position:"left",
        selector: '[data-tour="step-profile"]',
        content: "Avatar resminiz ve kullanıcı adınızın olduğu bu bölümün üzerine geldiğinizde profil sayfanıza gidebileceğiniz veya uygulamadan çıkış yapabileceğiniz bir menü açılır."
    },
    {
        selector: '[data-tour="step-1"]',
        content: "Pano sayfasında varsayılan olarak son 24 saatteki veriler gösterilmektedir. Buradaki filtre alanlarından başlangıç-bitiş tarihine göre veya kullanıcı bazında filtreleme yapabilirsiniz. Ayrıca grafiklerinizi günlük, haftalık veya aylık seçenekleriyle görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-2"]',
        content: "Devam eden görevlerinizi, devam eden işlerinizi, ve tamamlanan işleriniz üzerindeki etiket sayılarını görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-5"]',
        content: "Tamamlanan işlerinizin sayısı-zaman grafiğini burada görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-6"]',
        content: "Tamamlanan işleriniz üzerindeki etiket sayıları kullanılarak hazırlanan etiket sayısı-zaman grafiğini burada görüntüleyebilirsiniz."
    },
    {
        selector: '[data-tour="step-tour-icon"]',
        content: "Açık olan sayfada gezintiye çıkmak için bu butonu kullanabilirsiniz. "
    },

  ];

  export default dashboardPageStepsProvider;