// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Pagination from 'antd/lib/pagination';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ModelRunnerModalContainer from 'containers/model-runner-dialog/model-runner-dialog';
import TaskItem from 'containers/tasks-page/task-item';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';
import Layout from 'antd/lib/layout';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';

export interface ContentListProps {
    onSwitchPage(page: number): void;
    currentTasksIndexes: number[];
    currentPage: number;
    numberOfTasks: number;
}
/**
 * Detecting Mobile 
 * @returns whether or not detecting mobile 
 */
function detecMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true
    } else {
        return false
    }
}
/**
 * Getting Window Dimensions
 * @returns dict of width and height
 */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
/**
 * 
 * @param props includes onSwitchPage(page: number) function,and  currentTasksIndexes, currentPagenumberOfTasks variables
 * @returns div includes rows
 */
function TaskListComponent(props: ContentListProps & RouteComponentProps): JSX.Element {
    const {
        currentTasksIndexes,
        numberOfTasks,
        currentPage,
        onSwitchPage,
        history
    } = props;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    //let spanParameterAlt = parseInt(24/parseInt(innerWidth/475))
    let spanParameter = parseInt(15000/innerWidth)
    let scale = "scale(1.0)"
    let marginTop = "0%"
    let zoom = '100%'
    if(innerHeight >= innerWidth){
        spanParameter = 24
        zoom = '70%'
        /*scale = "scale(1.5)"
        marginTop = "25%"*/
    }


    const taskViews = currentTasksIndexes.map(
        (tid, id): JSX.Element =>
        <Col span={spanParameter} style={{zoom:zoom}}>
        <div
        style={{transform:scale, marginTop: marginTop,cursor:"pointer"}}
        onClick={(e: React.MouseEvent): void => {
            //e.preventDefault();
            if(detecMobile()){
                Modal.info({
                    content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                    okText: 'Tamam',
                });
            }else{
                history.push(`/tasks/${tid}`)
            }
        }}
        ><TaskItem idx={id} taskID={tid} key={tid} /></div>
        </Col>,
    );
    /**
     * Use effect for handling resize of window
     */
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <>
            <Row type='flex' justify='space-around' align='middle'>
                    { taskViews }
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14}>
                    <ConfigProvider locale ={trTR}>
                        <Pagination
                            className='cvat-tasks-pagination'
                            onChange={onSwitchPage}
                            total={numberOfTasks}
                            pageSize={9}
                            current={currentPage}
                            showQuickJumper
                        />
                    </ConfigProvider>

                </Col>
            </Row>
            <ModelRunnerModalContainer />
        </>
    );
}

export default withRouter(TaskListComponent);