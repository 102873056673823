/*
* Copyright (C) 2019 Intel Corporation
* SPDX-License-Identifier: MIT
*/

//const { url } = require('inspector');

/* global
    require:false
*/

(() => {
    const FormData = require('form-data');
    const {
        ServerError,
    } = require('./exceptions');
    const store = require('store');
    const config = require('./config');
    const DownloadWorker = require('./download.worker');
    const i18n = require('../../cvat-ui/src/i18n');

    function generateError(errorData) {
        if (errorData.response) {
            const message = `${JSON.stringify(errorData.response.data) || ''}.`;
            return new ServerError(message, errorData.response.status, errorData.response.data); // data also appended thanks to js unpacking
        }

        // Server is unavailable (no any response)
        const message = `${errorData.message}.`; // usually is "Error Network"
        return new ServerError(message, 0);
    }

    class WorkerWrappedAxios {
        constructor() {
            const worker = new DownloadWorker();
            const requests = {};
            let requestId = 0;

            worker.onmessage = (e) => {
                if (e.data.id in requests) {
                    if (e.data.isSuccess) {
                        requests[e.data.id].resolve(e.data.responseData);
                    } else {
                        requests[e.data.id].reject(e.data.error);
                    }

                    delete requests[e.data.id];
                }
            };

            worker.onerror = (e) => {
                if (e.data.id in requests) {
                    requests[e.data.id].reject(e);
                    delete requests[e.data.id];
                }
            };

            function getRequestId() {
                return requestId++;
            }

            async function get(url, requestConfig) {
                return new Promise((resolve, reject) => {
                    const newRequestId = getRequestId();
                    requests[newRequestId] = {
                        resolve,
                        reject,
                    };
                    worker.postMessage({
                        url,
                        config: requestConfig,
                        id: newRequestId,
                    });
                });
            }

            Object.defineProperties(this, Object.freeze({
                get: {
                    value: get,
                    writable: false,
                },
            }));
        }
    }

    class ServerProxy {
        constructor() {
            const Axios = require('axios');
            Axios.defaults.withCredentials = true;
            Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
            Axios.defaults.xsrfCookieName = 'csrftoken';
            const workerAxios = new WorkerWrappedAxios();

            let token = store.get('token');
            if (token) {
                Axios.defaults.headers.common.Authorization = `Token ${token}`;
            }

            async function logout() {
                const token = store.get('token');

                try {
                    /*await Axios.delete(`${config.backendAPI}/annota/delete-token/${token}`, {
                        proxy: config.proxy,
                    })*/
                    await Axios.post(`${config.backendAPI}/auth/logout`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
                store.remove('token');
                Axios.defaults.headers.common.Authorization = '';
                let url = window.location.href;
                let count = 0;
                for (var i = 0; i < url.length; i++) {
                    if (url[i] == "/") {
                        count = count + 1;
                    }
                    if (count === 3) {
                        break;
                    }
                }
                url = url.slice(0,i) + "/auth/login";
                window.location.replace(url);

                // window.location.href get remove after 3rd slash and window.replace
            }

            async function about() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/about`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }


                return response.data;
            }

            async function share(directory) {
                const { backendAPI } = config;
                directory = encodeURIComponent(directory);

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/share?directory=${directory}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function exception(exceptionObject) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/server/exception`, JSON.stringify(exceptionObject), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

            }

            async function formats() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/server/annotation/formats`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function userAgreements() {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/restrictions/user-agreements`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function register(username, firstName, lastName, email, password1, password2, confirmations) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username,
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        password1,
                        password2,
                        confirmations,
                    });
                    response = await Axios.post(`${config.backendAPI}/auth/register`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function validateCaptcha(token) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        response: token,
                    });
                    response = await Axios.post(`${config.backendAPI}/annota/validate-captcha`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function annotaRegister(username, firstName, lastName, email, password1, password2, phoneNumber, emailCode,
                smsCode, regId, dateOfBirth, postCode, address, city, town, isWorking, base64Photo, workplace, computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation, education, tckn, tcserial, regType) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username,
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        password1,
                        password2,
                        phoneNumber,
                        emailCode,
                        smsCode,
                        regId,
                        dateOfBirth,
                        postCode,
                        address,
                        city,
                        town,
                        isWorking,
                        base64Photo,
                        workplace,
                        computerExperience,
                        certificateExplanation,
                        plExplanation,
                        aiExplanation,
                        othersExplanation,
                        education,
                        tckn,
                        tcserial,
                        regType,
                    });
                    response = await Axios.post(`${config.backendAPI}/annota/register`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function verifyInformation(username, firstName, lastName, tckn, tcserial) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username: username,
                        first_name: firstName,
                        last_name: lastName,
                        tckn: tckn,
                        tcserial: tcserial,
                    });
                    response = await Axios.post(`${config.backendAPI}/annota/verify-information`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                return response.data;
            }

            async function annotaRegisterSpecialAnnotator(username, firstName, lastName, email, password1, password2, phoneNumber, emailCode,
                smsCode, regId, dateOfBirth, postCode, address, city, town, isWorking, base64Photo, workplace, computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation, education, tckn, tcserial, regType, uuid) {
                let response = null;
                try {
                    const data = JSON.stringify({
                        username,
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        password1,
                        password2,
                        phoneNumber,
                        emailCode,
                        smsCode,
                        regId,
                        dateOfBirth,
                        postCode,
                        address,
                        city,
                        town,
                        isWorking,
                        base64Photo,
                        workplace,
                        computerExperience,
                        certificateExplanation,
                        plExplanation,
                        aiExplanation,
                        othersExplanation,
                        education,
                        tckn,
                        tcserial,
                        regType,
                        uuid
                    });
                    response = await Axios.post(`${config.backendAPI}/annota/register/specialannotator`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function approve(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/approve/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user1')} '${userId}' ${i18n.default.t('server-proxy.couldntApproveOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function reject(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/reject/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user2')} '${userId}' ${i18n.default.t('server-proxy.couldntRejectOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function forgive(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/forgive/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user3')} '${userId}' ${i18n.default.t('server-proxy.couldntForgiveOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function demote(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/demote/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user9')} '${userId}' ${i18n.default.t('server-proxy.couldntDemoteOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function promote(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/promote/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user4')} '${userId}' ${i18n.default.t('server-proxy.couldntPromoteOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function bless(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/bless/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user5')} '${userId}' ${i18n.default.t('server-proxy.couldntBlessOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function curse(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/curse/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user6')} '${userId}' ${i18n.default.t('server-proxy.couldntCurseOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function block(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/block/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user7')} '${userId}' ${i18n.default.t('server-proxy.couldntBlockOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function unblock(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/unblock/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.user8')} '${userId}' ${i18n.default.t('server-proxy.couldntUnblockOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function give(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/give/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function take(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/take/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function makeDP(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/makeDP/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function makeSA(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/makeSA/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;

            }

            async function takeDP(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/takeDP/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function makeSSBDP(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/makeSSBDP/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function takeSSBDP(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/takeSSBDP/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function takeSA(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/takeSA/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function makeAnnotator(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/makeAnnotator/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function takeAnnotator(userId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/takeAnnotator/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function addRole(userId, role) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({role});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/addRole/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not give permission to '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function removeRole(userId, role) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({role});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/users/${userId}/removeRole/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }


            async function trainingstate(userId, jobId, state) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/trainingstate/${userId}/${jobId}/${state}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.job1')} '${jobId}' ${i18n.default.t('server-proxy.couldntChangeStateOfTrainingOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function assigntask(taskId, userId = null) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/assigntask/${taskId}` + `?userId=${userId}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.task1')} '${taskId}' ${i18n.default.t('server-proxy.couldntSelectForAssignOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function assigntaskRandom(taskId, userId = null) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/assigntaskRandom/${taskId}` + `?userId=${userId}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.task1')} '${taskId}' ${i18n.default.t('server-proxy.couldntSelectForAssignOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function setassigneelimit(taskId, assigneeCount) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/tasks/${taskId}/setlimit/` + `?assigneeLimit=${assigneeCount}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.task2')} '${taskId}' ${i18n.default.t('server-proxy.couldntAssignLimitOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function setTaskDifficulty(taskId, taskDifficulty) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/tasks/${taskId}/set-task-difficulty/` + `?taskDifficulty=${taskDifficulty}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function selecttraining(userId, taskId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/train/${userId}/${taskId}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.job2')} '${jobId}' ${i18n.default.t('server-proxy.couldntSelectForTrainingOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function selecttest(userId, taskId) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/test/${userId}/${taskId}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.task3')} '${taskId}' ${i18n.default.t('server-proxy.couldntSelectForTestOnServer')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function teststate(userId, jobId, state) {
                let mainResponse = null;
                try {
                    const data = JSON.stringify({});
                    mainResponse = await Axios.post(`${config.backendAPI}/annota/teststate/${userId}/${jobId}/${state}`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `${i18n.default.t('server-proxy.job3')}: '${jobId}' ${i18n.default.t('server-proxy.couldntChangeStateOfTest')}`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            function setAuthorizationHeader(value) {
                Axios.defaults.headers.common.Authorization = `Token ${value}`;
            }

            async function login(username, password, captchaToken) {
                const authenticationData = ([
                    `${encodeURIComponent('username')}=${encodeURIComponent(username)}`,
                    `${encodeURIComponent('password')}=${encodeURIComponent(password)}`,
                    `${encodeURIComponent('captchaToken')}=${encodeURIComponent(captchaToken)}`,
                ]).join('&').replace(/%20/g, '+');

                Axios.defaults.headers.common.Authorization = '';
                let authenticationResponse = null;
                try {
                    authenticationResponse = await Axios.post(
                        `${config.backendAPI}/auth/login`,
                        authenticationData, {
                        proxy: config.proxy,
                    },
                    );
                } catch (errorData) {
                    if (process.env.DEPLOYMENT_PLACE === "SSYZ") {
                        window.location.replace('https://verikovani.ssyz.org.tr')

                    }
                    throw generateError(errorData);
                }

                console.log(captchaToken)
                if (authenticationResponse.headers['set-cookie']) {
                    // Browser itself setup cookie and header is none
                    // In NodeJS we need do it manually
                    const cookies = authenticationResponse.headers['set-cookie'].join(';');
                    Axios.defaults.headers.common.Cookie = cookies;
                }

                token = authenticationResponse.data.key;
                let doccanoToken = authenticationResponse.data.doccanoToken

                /*let doccanoResult;
                const data = {
                    username,
                    password,
                    captchaToken
                };
                try {
                    doccanoResult = await Axios.post(
                        `${config.backendAPIDoccano}/v1/auth-token`,
                        data, {
                        proxy: config.proxy,
                    }
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }*/


                store.set('token', token);

                /*let response = null;
                try {
                    response = await Axios.post(
                        `${config.backendAPIDoccano}/v1/cross-login`,
                        {
                            username: username,
                            password: password
                        },
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }*/

                try {
                    await Axios.post(
                        `${config.backendAPI}/annota/set-token/${doccanoToken}/${username}`,
                        {
                            proxy: config.proxy,
                        }
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                Axios.defaults.headers.common.Authorization = `Token ${token}`;

                if (process.env.DEPLOYMENT_PLACE == 'SSYZ') {
                    window.location.replace(`${config.doccanoUIRedirectURL}/authenticator?username=${encodeURI(authenticationResponse.data.email)}&token=${encodeURI(doccanoToken)}&action=login`)
                } else {
                window.location.replace(`${config.doccanoUIRedirectURL}/authenticator?username=${encodeURI(username)}&token=${encodeURI(doccanoToken)}&action=login`)
            }

            }


            async function reset(email) {
                const data = new FormData();
                data.append('email', email)

                let response = null;

                try {
                    response = await Axios.post(`${config.backendAPI}/auth/password/reset/`, data, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                if (response.status === 200) {
                    return "Success"
                } else {
                    return response
                }
            }

            async function resetConfirm(uidb64, token, password1, password2) {
                const data = new FormData();
                data.append('uid', uidb64)
                data.append('token', token)
                data.append('new_password1', password1)
                data.append('new_password2', password2)

                let response = null;

                try {
                    response = await Axios.post(`${config.backendAPI}/auth/password/reset/confirm/${uidb64}/${token}/`, data, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                if (response.status === 200) {
                    return "Success"
                } else {
                    return response
                }
            }

            async function authorized() {
                try {
                    await module.exports.users.getSelf();
                } catch (serverError) {
                    if (serverError.code === 401) {
                        return false;
                    }

                    throw serverError;
                }

                return true;
            }

            async function serverRequest(url, data) {
                try {
                    return (await Axios({
                        url,
                        ...data,
                    })).data;
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getTestJobs(state = null, userId = null, pageNum = 1) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/tests/`;
                let response = null;
                if (state != null && userId == null && pageNum != null) {
                    baseUrl = baseUrl + `?state=${state}` + `&page=${pageNum}`;
                }
                else if (state != null && userId != null) {
                    baseUrl = baseUrl + `?state=${state}` + `&userId=${userId}`;
                }
                else if (state == null && userId != null) {
                    baseUrl = baseUrl + `?page_size=all&userId=${userId}`;
                }
                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetTestsFromServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function getTrainingJobs(state = null) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/trainings/`;
                let response = null;
                if (state != null) {
                    baseUrl = baseUrl + `?state=${state}`;
                }
                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetTrainingsFromServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function createVerification(correlation_id, verificationType, phoneNumber = null, email = null, emailCode = null, token) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/create-verification/` + `${correlation_id}/${verificationType}/${token}`;
                let response = null;
                if (phoneNumber != null) {
                    baseUrl = baseUrl + `?phoneNumber=${phoneNumber}` + `&emailCode=${emailCode}`;
                } else if (email != null) {
                    baseUrl = baseUrl + `?email=${email}`;
                } else {
                    return response;
                }
                try {
                    response = await Axios.post(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntCreateVerificationCode'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function createVerificationSpecialAnnotator(correlation_id, verificationType, phoneNumber = null, email = null, emailCode = null, token, uuid = null) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/create-verification/specialannotator/` + `${correlation_id}/${verificationType}/${token}/${uuid}`;
                let response = null;
                if (phoneNumber != null) {
                    baseUrl = baseUrl + `?phoneNumber=${phoneNumber}` + `&emailCode=${emailCode}`;
                } else if (email != null) {
                    baseUrl = baseUrl + `?email=${email}`;
                } else {
                    return response;
                }
                try {
                    response = await Axios.post(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntCreateVerificationCode'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function checkVerification(correlation_id, target = null, code = null) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/check-verification` + `?regId=${correlation_id}` + `&target=${target}` + `&code=${code}`;
                let response = null;
                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntCheckVerificationCode'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function createContactMail(targetEmail = null, userEmail = null, fullName = null, message = null) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/create-contact-mail` + `?targetEmail=${targetEmail}` + `&userEmail=${userEmail}` + `&fullName=${fullName}` + `&message=${message}`
                let response = null;
                try {
                    response = await Axios.post(baseUrl, {
                        proxy: config.proxy,
                        data: {
                            message: message
                        }
                    });
                } catch (errorData) {
                    throw generateError(errorData, 'Could not create contact mail');
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function checkVerification(correlation_id, target = null, code = null, type) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/check-verification` + `?regId=${correlation_id}` + `&target=${target}` + `&code=${code}` + `&type=${type}`;;
                let response = null;
                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, 'Could not check verification code');
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function verifyCode(correlation_id, target, code) {
                const { backendAPI } = config;
                let baseUrl = `${backendAPI}/annota/verify/` + `${correlation_id}/${target}` + `?code=${code}`;
                let response = null;
                try {
                    response = await Axios.post(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntVerifyCode'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.status;
            }

            async function getTasks(filter = '', tasksViewType = null, taskType = null, onlyTasks = null, annotaTaskStatus = null, taskClassificationType = null, expiredOnly = null) {
                const { backendAPI } = config;

                let response = null;
                let query = `${backendAPI}/tasks?page_size=9`
                try {
                    if(onlyTasks != null){
                        query += `&onlyTasks=true`
                    }
                    if(tasksViewType != null){
                        query += `&tasksViewType=${tasksViewType}`
                    }
                    if(taskType != null){
                        query += `&taskType=${taskType}`
                        // response = await Axios.get(`${backendAPI}/tasks?page_size=9&tasksViewType=${tasksViewType}&${filter}`, {
                        //     proxy: config.proxy,
                        // });
                    }
                    if(annotaTaskStatus != null){
                        query += `&annotaTaskStatus=${annotaTaskStatus}`
                    }
                    if(taskClassificationType != null){
                        query += `&taskClassificationType=${taskClassificationType}`
                    }
                    if(expiredOnly != null){
                        query += `&expiredOnly=${expiredOnly}`
                    }
                    query += `&${filter}`
                    response = await Axios.get(query, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function getAnnotaTasks(state = null, taskId = null, pageNum = null, filter = '') {
                const { backendAPI } = config;

                let response = null;
                let baseUrl = `${backendAPI}/annota/tasks/`

                let params = []

                if (taskId != null) {
                    baseUrl = `${backendAPI}/annota/tasks/${taskId}/`;
                    try {
                        response = await Axios.get(baseUrl, {
                            proxy: config.proxy,
                        });
                    } catch (errorData) {
                        throw generateError(errorData, i18n.default.t('server-proxy.couldntGetAnnotaTasksFromServer'));
                    }
                    return response.data;
                }

                if(pageNum!=null){
                    params.push(`page=${pageNum}`)
                    params.push(`page_size=9`)
                }
                if (state != null) {
                    if (typeof state == 'string') {
                        params.push(`state=${state}`)
                    }
                    else {
                        for (var i = 0; i < state.length; i++) {
                            params.push(`state=${state[i]}`)
                        }
                    }
                }
                if (filter != '') {
                    params.push(filter)
                }
                baseUrl = baseUrl.concat('?', params.join('&'))

                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetAnnotaTasksFromServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                response.data.results.count = response.data.count;
                return response.data.results;
            }

            async function approveTask(taskId) {
                const { backendAPI } = config;
                let response = null;

                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${taskId}/approve/`, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntApproveTaskOnServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response
            }

            async function rejectTask(taskId) {
                const { backendAPI } = config;
                let response = null;

                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${taskId}/reject/`, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntRejectTaskOnServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response
            }

            async function saveTask(id, taskData) {
                const { backendAPI } = config;

                try {
                    await Axios.patch(`${backendAPI}/tasks/${id}`, JSON.stringify(taskData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

            }

            async function deleteTask(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/tasks/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteRole(id) {
                const { backendAPI } = config;
                let response = null
                try {
                    response = await Axios.delete(`${backendAPI}/annota/roles/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response;
            }

            async function exportDataset(id, format) {
                const { backendAPI } = config;
                let url = `${backendAPI}/tasks/${id}/dataset?format=${format}`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios
                                .get(`${url}`, {
                                    proxy: config.proxy,
                                });
                            if (response.status === 202) {
                                setTimeout(request, 3000);
                            } else {
                                url = `${url}&action=download`;
                                resolve(url);
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }

                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                    }

                    setTimeout(request);
                });
            }

            async function createTask(taskSpec, taskDataSpec, onUpdate) {
                const { backendAPI } = config;

                async function wait(id) {
                    return new Promise((resolve, reject) => {
                        async function checkStatus() {
                            try {
                                const response = await Axios.get(`${backendAPI}/tasks/${id}/status`);
                                if (['Queued', 'Started'].includes(response.data.state)) {
                                    if (response.data.message !== '') {
                                        onUpdate(response.data.message);
                                    }
                                    setTimeout(checkStatus, 1000);
                                } else if (response.data.state === 'Finished') {
                                    resolve();
                                } else if (response.data.state === 'Failed') {
                                    // If request has been successful, but task hasn't been created
                                    // Then passed data is wrong and we can pass code 400
                                    const message = i18n.default.t('server-proxy.couldntCreateTask')
                                        + ` ${response.data.message}.`;
                                    reject(new ServerError(message, 400));
                                } else {
                                    // If server has another status, it is unexpected
                                    // Therefore it is server error and we can pass code 500
                                    reject(new ServerError(
                                        `${i18n.default.t('server-proxy.unknownTaskStateReceived')}: ${response.data.state}`,
                                        500,
                                    ));
                                }
                            } catch (errorData) {
                                reject(
                                    generateError(errorData),
                                );
                            }
                            if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                                logout();
                                return;
                            }
                        }

                        setTimeout(checkStatus, 1000);
                    });
                }

                const taskData = new FormData();
                for (const [key, value] of Object.entries(taskDataSpec)) {
                    if (Array.isArray(value)) {
                        value.forEach((element, idx) => {
                            taskData.append(`${key}[${idx}]`, element);
                        });
                    } else {
                        taskData.set(key, value);
                    }
                }
                //batchOfFiles.append(`datasetId`, taskData.datasetId);
                taskData.append("textProjectType", taskSpec.textProjectType)
                taskData.append("shapes", taskSpec.shapes)
                taskData.append("taskTypes", taskSpec.taskTypes)
                taskData.append("users", taskSpec.users)
                taskData.append("isPrivateTask", taskSpec.isPrivateTask)
                taskData.append("guidelinesFile", taskSpec.guidelinesFile[0])
                taskData.append("thumbnail", taskSpec.thumbnail[0])
                taskData.append("assigneeCount", taskSpec.assigneeCount)
                taskData.append("taskDifficulty", taskSpec.taskDifficulty)
                taskData.append("taskType", taskSpec.taskType)

                if(taskSpec.metaData != undefined && taskSpec.metaData.length != 0 && taskSpec.metaData != null){
                    taskData.append("metaData", JSON.stringify(taskSpec.metaData))
                }

                let metaDataDatasetDefault = []
                if(taskSpec.metaDataDataset != undefined && taskSpec.metaDataDataset.length != 0 && taskSpec.metaDataDataset != null){
                    metaDataDatasetDefault = taskSpec.metaDataDataset
                }

                if(taskDataSpec.imageMaskData != undefined && taskDataSpec.imageMaskData.length != 0 && taskDataSpec.imageMaskData != null){
                    taskData.append("imageMaskData", JSON.stringify(taskDataSpec.imageMaskData))
                }
                let response = null;

                onUpdate(i18n.default.t('server-proxy.taskIsCreatingInServer'));
                try {
                    response = await Axios.post(`${backendAPI}/tasks`, JSON.stringify(taskSpec), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                //Create Dataset for task before task data process
                //To block data duplication
                //If task is not being created from already uploaded dataset
                let createdDataset;
                if (taskDataSpec.dataset_files.length == 0) {
                    const { Dataset } = require('./session');
                    const description = {
                        name: taskSpec.datasetName?taskSpec.datasetName:`'#${response.data.id}: ${response.data.name}' görevine ait Veri Kümesi`,
                        maskpoints: taskSpec.maskpoints,
                        fromTask: response.data.id,
                        isPrivate: taskSpec.isPrivate,
                        metaData: metaDataDatasetDefault,
                        projectName: taskSpec.projectName,
                        mediaType: taskSpec.mediaType?taskSpec.mediaType:"",
                        dataFormat: taskSpec.dataFormat,
                        dataTimeLength: taskSpec.dataTimeLength,
                        dataSize: taskSpec.dataSize,
                        dataContext: taskSpec.dataContext,
                        dataPrivacy: taskSpec.dataPrivacy?taskSpec.dataPrivacy:"",
                        dataUsage: taskSpec.dataUsage,
                        imageMaskData: taskDataSpec.imageMaskData,
                        imageQuality: taskDataSpec.image_quality,
                        frameFilter: taskDataSpec.frame_filter,
                    };
                    const datasetInstance = new Dataset(description);
                    datasetInstance.clientFiles = taskDataSpec.client_files
                    datasetInstance.serverFiles = taskDataSpec.server_files
                    datasetInstance.remoteFiles = taskDataSpec.remote_files
                    try {
                        createdDataset = await datasetInstance.save();
                    } catch (errorData) {
                        await deleteTask(response.data.id);
                        throw generateError(errorData);
                    }
                }

                onUpdate(i18n.default.t('server-proxy.uploadDataToServer'));
                try {
                    await Axios.post(`${backendAPI}/tasks/${response.data.id}/data`, taskData, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    try {
                        await deleteTask(response.data.id);
                        if (taskDataSpec.dataset_files.length == 0) {
                            await deleteDataset(createdDataset.id)
                        }
                    } catch (_) {
                        // ignore
                    }

                    throw generateError(errorData);
                }

                try {
                    await wait(response.data.id);
                } catch (createException) {
                    await deleteTask(response.data.id);
                    if (taskDataSpec.dataset_files.length == 0) {
                        await deleteDataset(createdDataset.id)
                    }
                    throw createException;
                }

                // const createdTask = await getTasks(`?id=${response.id}`);
                // return createdTask[0];
                return response.data;
            }

            async function setTaskType(taskId, taskType) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/task-types/`, {
                        proxy: config.proxy,
                        taskId: taskId,
                        taskType: taskType
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntCreateTestTrainingTask'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response;
            }

            async function getJob(jobID) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/jobs/${jobID}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function saveJob(id, jobData) {
                const { backendAPI } = config;

                try {
                    await Axios.patch(`${backendAPI}/jobs/${id}`, JSON.stringify(jobData), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getUsers(id = null, pageNum = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (id === null && pageNum == null) {
                        response = await Axios.get(`${backendAPI}/users?page_size=all`, {
                            proxy: config.proxy,
                        });
                    } else if (pageNum != null) {
                        response = await Axios.get(`${backendAPI}/users?page=${pageNum}`, {
                            proxy: config.proxy,
                        });
                    }
                    else {
                        response = await Axios.get(`${backendAPI}/users/${id}`, {
                            proxy: config.proxy,
                        });
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getAnnotaUsers(userId = null, pageNum = 1, state = null, level = null, expert = null, isBlocked = null, getAll = null, isSuperuser = null, filter = '', role = null, isDPDashBoardSearch = null) {
                const { backendAPI } = config;
                let response = null;
                let baseUrl = `${backendAPI}/annota/users/`
                if (userId != null) {
                    baseUrl = `${backendAPI}/annota/users/${userId}/`;
                }
                else {
                    let params = []
                    if (getAll != null) {
                        params.push('page_size=all')
                    }
                    else {
                        params.push(`page=${pageNum}`)
                    }
                    if (state != null) {
                        params.push(`state=${state}`)
                    }
                    if (isBlocked != null) {
                        params.push(`isBlocked=${isBlocked}`)
                    }

                    if (isSuperuser != null) {
                        params.push(`isSuperuser=${isSuperuser}`)
                    }
                    if (filter != '') {
                        params.push(filter)
                    }

                    if (role != null) {
                        if (typeof role == 'string') {
                            params.push(`role=${role}`)
                        }
                        else {
                            for (var i = 0; i < role.length; i++) {
                                params.push(`role=${role[i]}`)
                            }
                        }
                    }
                    if (isDPDashBoardSearch != null) {
                        params.push(`isDPDashBoardSearch=${isDPDashBoardSearch}`)
                    }
                    baseUrl = baseUrl.concat('?', params.join('&'))
                }
                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetUserFromServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getMessageUsersInteracted() {
                const { backendAPI } = config;
                let response = null;
                // let baseUrl = `${backendAPI}/annota/users/`

                // let params = []
                // params.push(`interactedUser=${userId}`)

                // baseUrl = baseUrl.concat('?', params.join('&'))

                let baseUrl = `${backendAPI}/annota/messages/get-message-users/`

                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetUserFromServer'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getMessages(interactedUserId = null, pageNum = null) {
                const { backendAPI } = config;
                let response = null;
                let baseUrl = `${backendAPI}/annota/messages/`
                let params = []
                params.push(`interactedUser=${interactedUserId}`)

                if (pageNum != null) {
                    params.push(`page=${pageNum}`)
                }
                baseUrl = baseUrl.concat('?', params.join('&'))

                try {
                    response = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData, "Error when loading messages");
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function createMessage(recipientId, content) {
                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    recipientId,
                    content,
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/messages/create-message/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function markMessages(senderId) {
                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    senderId
                });
                try {
                    response = await Axios.patch(`${backendAPI}/annota/messages/mark-as-read/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function getTrainingList(state = null, userId = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (state === null) {
                        response = await Axios.get(`${backendAPI}/annota/get-trainings/`, {
                            proxy: config.proxy,
                        });
                    } else if (state !== null && userId === null) {
                        response = await Axios.get(`${backendAPI}/annota/get-trainings/?state=${state}`, {
                            proxy: config.proxy,
                        });
                    } else {
                        response = await Axios.get(`${backendAPI}/annota/get-trainings/?state=${state}&userId=${userId}`, {
                            proxy: config.proxy,
                        });
                    }
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetTrainingList'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getTrainingTestTasks(taskType = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (taskType === null) {
                        response = await Axios.get(`${backendAPI}/annota/trainingstests/`, {
                            proxy: config.proxy,
                        });
                    } else {
                        response = await Axios.get(`${backendAPI}/annota/trainingstests/?taskType=${taskType}`, {
                            proxy: config.proxy,
                        });
                    }
                } catch (errorData) {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntGetTrainingList'));
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getUserJobs(userId = null) {
                const { backendAPI } = config;
                let response = null;
                let baseUrl = `${backendAPI}/annota/userjobs`
                if (userId != null) {
                    baseUrl = `${backendAPI}/annota/userjobs/${userId}`;
                    try {
                        response = await Axios.get(baseUrl, {
                            proxy: config.proxy,
                        });
                    } catch (errorData) {
                        throw generateError(errorData, i18n.default.t('server-proxy.couldntGetJobsOfUsersFromServer'));
                    }

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data;
                } else {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntReceiveUserId'));
                }

            }

            async function getUserJobsStatistics(userId = null, timeFilter = null) {
                const { backendAPI } = config;
                let response = null;
                let baseUrl = `${backendAPI}/annota/userjobsstatistics`
                if (userId != null) {
                    baseUrl = `${backendAPI}/annota/userjobsstatistics/${userId}/${timeFilter}`;
                    try {
                        response = await Axios.get(baseUrl, {
                            proxy: config.proxy,
                        });
                    } catch (errorData) {
                        throw generateError(errorData, i18n.default.t('server-proxy.couldntGetJobsOfUsersFromServer'));
                    }

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data;
                } else {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntReceiveUserId'));
                }

            }

            async function getTaskJobs(taskId = null) {
                const { backendAPI } = config;
                let response = null;
                let baseUrl = `${backendAPI}/annota/taskjobs`
                if (taskId != null) {
                    baseUrl = `${backendAPI}/annota/taskjobs/${taskId}`;
                    try {
                        response = await Axios.get(baseUrl, {
                            proxy: config.proxy,
                        });
                    } catch (errorData) {
                        throw generateError(errorData, i18n.default.t('server-proxy.couldntGetJobsOfTaskFromServer'));
                    }

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data;
                } else {
                    throw generateError(errorData, i18n.default.t('server-proxy.couldntReceiveTaskId'));
                }

            }

            async function getSelf() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/users/self`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getAnnotaSelf(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/getAnnotaSelf/${id}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }
            async function updateUser(id, fields) {
                const { backendAPI } = config;
                const requestFunc = Axios.patch.bind(Axios);
                const url = `${backendAPI}/annota/updateUser/${id}`;

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify(fields), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function downloadDataset(id, onDownloadProgressStatus) {
                const { backendAPI } = config;
                try {
                    response = await Axios.get(`${backendAPI}/datasets/${id}/download-dataset`, {
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => {
                            const {loaded, total} = progressEvent;
                            let percent = Math.floor( (loaded * 100) / total )
                            onDownloadProgressStatus(percent);
                        }
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Dataset_' + id + '.zip');
                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function updateUserPatch(id, fields) {
                const { backendAPI } = config;
                const requestFunc = Axios.patch.bind(Axios);
                const url = `${backendAPI}/annota/users/${id}/`;

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify(fields), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response;
            }

            async function updateInformations(id, fields) {
                const { backendAPI } = config;
                const requestFunc = Axios.patch.bind(Axios);
                const url = `${backendAPI}/annota/updateInformations/${id}`;

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify(fields), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }
            async function changePassword(id, pass, newPass) {
                const { backendAPI } = config;
                const requestFunc = Axios.patch.bind(Axios);
                const url = `${backendAPI}/annota/changePass/${id}`;

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify({ "pass": pass, "newPass": newPass }), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateAvatarId(userId, avatarId) {
                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    avatarId,
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/users/${userId}/updateAvatarId/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getPreview(tid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${tid}/data?type=preview`, {
                        proxy: config.proxy,
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    const code = errorData.response ? errorData.response.status : errorData.code;
                    throw new ServerError(
                        `${i18n.default.t('server-proxy.couldntGetPreviewFrameForTask')} ${tid} ${i18n.default.t('server-proxy.fromServer')}`,
                        code,
                    );
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getData(tid, chunk) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await workerAxios.get(
                        `${backendAPI}/tasks/${tid}/data?type=chunk&number=${chunk}&quality=compressed`,
                        {
                            proxy: config.proxy,
                            responseType: 'arraybuffer',
                        },
                    );
                    await Axios.post(`${backendAPI}/annota/clear-chunk/${tid}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }


                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response;
            }

            async function getMeta(tid) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${tid}/data/meta`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getTaskPath(taskId) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/get-task-path/${taskId}`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getTextTaskDataset_(projectId) {
                const { backendAPI } = config;

                //doccano token needed
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/get-token`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                let doccanoToken = response.data.doccanoToken
                let doccanoResult;
                const configCustom = {
                    headers: { Authorization: `Token ${doccanoToken}` }
                };
                try {
                    doccanoResult = await Axios.get(
                        `${config.backendAPIDoccano}/v1/projects/${projectId}/docs/download?q=json`,
                        configCustom,
                        {
                            responseType: 'blob',
                        }
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                let result;
                if (doccanoResult.data.id == undefined || doccanoResult.data.id == null)
                    result = doccanoResult.data
                else
                    result = JSON.stringify(doccanoResult.data)

                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.' + 'json'); // or any other extension
                document.body.appendChild(link);
                link.click();

                return response.data;
            }

            async function getTextTaskDataset(projectId, labels=null) {
                const { backendAPI } = config;

                let doccanoResult;
                let params = [];
                let baseUrl = `${backendAPI}/annota/download-text-annotations/${projectId}`;

                if (labels) {
                    if (typeof labels == 'string') {
                        params.push(`label=${labels}`);
                    }
                    else {
                        for (var i = 0; i < labels.length; i++) {
                            params.push(`label=${labels[i]}`);
                        }
                    }
                    baseUrl = baseUrl.concat('?', params.join('&'))
                }
                try {
                    doccanoResult = await Axios.get(baseUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
                let result;
                if (doccanoResult.data.data.id == undefined || doccanoResult.data.data.id == null)
                    result = doccanoResult.data.data
                else
                    result = JSON.stringify(doccanoResult.data.data)

                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.' + 'json'); // or any other extension
                document.body.appendChild(link);
                link.click();

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getPCDAnnotations(dataId, username, jobId, fileMode) {
                const { backendAPI } = config;
                let token = store.get('token');
                const baseURL = config.backendAPI
                let hitachiUrl = ''
                let endpointMode = "pcdtext"
                if(fileMode)
                    endpointMode = "pcdfile"
                if(baseURL.includes("7000")){
                    try{
                        hitachiUrl = config.hitachiURL + `/api/${endpointMode}/%2F` + dataId  +"%2Fraw%2F" + username + "%2F" + "0.pcd" + "%2F" + token + "%2F" + jobId
                    }
                    catch{
                        hitachiUrl = config.hitachiURL +  `/api/${endpointMode}/%2F` + dataId  +"%2Fraw%2F" + "none" + "%2F" + "0.pcd" + "%2F" + token + "%2F" + jobId
                    }
                }else{
                    try{
                        hitachiUrl = config.hitachiURL + `/api/${endpointMode}/%2Fdata%2F` + dataId  +"%2Fraw%2F" + username + "%2F" + "0.pcd" + "%2F" + token + "%2F" + jobId
                    }
                    catch{
                        hitachiUrl = config.hitachiURL + `/api/${endpointMode}/%2Fdata%2F` + dataId  +"%2Fraw%2F" + "none" + "%2F" + "0.pcd" + "%2F" + token + "%2F" + jobId
                    }
                }
                window.open(hitachiUrl)
                /*let pcdOutputResult;
                try {
                    pcdOutputResult = await Axios.get(hitachiUrl, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
                const url = window.URL.createObjectURL(new Blob([pcdOutputResult]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.' + 'txt'); // or any other extension
                document.body.appendChild(link);
                link.click();

                return response.data;*/
            }


            async function getPointCloudTasks(tid) {
                const { backendAPIHitachi } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPIHitachi}/api/listing`, {
                        proxy: config.proxy,
                    }
                    );
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function getAnnotations(session, id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/${session}s/${id}/annotations`, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function updateAnnotations(session, id, data, action) {
                const { backendAPI } = config;
                let requestFunc = null;
                let url = null;
                if (action.toUpperCase() === 'PUT') {
                    requestFunc = Axios.put.bind(Axios);
                    url = `${backendAPI}/${session}s/${id}/annotations`;
                } else {
                    requestFunc = Axios.patch.bind(Axios);
                    url = `${backendAPI}/${session}s/${id}/annotations?action=${action}`;
                }

                let response = null;
                try {
                    response = await requestFunc(url, JSON.stringify(data), {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            // Session is 'task' or 'job'
            async function uploadAnnotations(session, id, file, format) {
                const { backendAPI } = config;

                let annotationData = new FormData();
                annotationData.append('annotation_file', file);

                return new Promise((resolve, reject) => {
                    async function request() {
                        try {
                            const response = await Axios
                                .put(`${backendAPI}/${session}s/${id}/annotations?format=${format}`, annotationData, {
                                    proxy: config.proxy,
                                });
                            if (response.status === 202) {
                                annotationData = new FormData();
                                setTimeout(request, 3000);
                            } else {
                                resolve();
                            }
                        } catch (errorData) {
                            reject(generateError(errorData));
                        }

                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }

                    }

                    setTimeout(request);
                });
            }

            // Session is 'task' or 'job'
            async function dumpAnnotations(id, name, format, labels=null) {
                const { backendAPI } = config;
                const baseURL = `${backendAPI}/tasks/${id}/annotations`;
                let query = `format=${encodeURIComponent(format)}`;
                if (name) {
                    const filename = name.replace(/\//g, '_');
                    query += `&filename=${encodeURIComponent(filename)}`;
                }
                if (labels) {
                    if (typeof labels == 'string') {
                        query += `&label=${encodeURIComponent(labels)}`;
                    }
                    else {
                        for (var i = 0; i < labels.length; i++) {
                            query += `&label=${encodeURIComponent(labels[i])}`;
                        }
                    }
                }

                let url = `${baseURL}?${query}`;

                return new Promise((resolve, reject) => {
                    async function request() {
                        Axios.get(`${url}`, {
                            proxy: config.proxy,
                        }).then((response) => {

                            if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                                logout();
                                return;
                            }

                            if (response.status === 202) {
                                setTimeout(request, 3000);
                            } else {
                                query = `${query}&action=download`;
                                url = `${baseURL}?${query}`;
                                resolve(url);
                            }
                        }).catch((errorData) => {
                            reject(generateError(errorData));
                        });
                    }

                    setTimeout(request);
                });
            }

            async function createDataset(taskData, metaDataSpecific, files, onUpdate, onUpdateProgress) {
                const { backendAPI } = config;

                async function wait(id) {
                    return new Promise((resolve, reject) => {
                        async function checkStatus() {
                            try {
                                const response = await Axios.get(`${backendAPI}/datasets/${id}/status`);
                                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                                    logout();
                                    return;
                                }
                                if (['Queued', 'Started'].includes(response.data.state)) {
                                    if (response.data.message !== '') {
                                        onUpdate(response.data.message);
                                    }
                                    setTimeout(checkStatus, 1000);
                                } else if (response.data.state === 'Finished') {
                                    resolve();
                                } else if (response.data.state === 'Failed') {
                                    // If request has been successful, but task hasn't been created
                                    // Then passed data is wrong and we can pass code 400
                                    const message = i18n.default.t('server-proxy.couldntCreateDatasetOnServer')
                                        + ` ${response.data.message}.`;
                                    reject(new ServerError(message, 400));
                                } else {
                                    // If server has another status, it is unexpected
                                    // Therefore it is server error and we can pass code 500
                                    reject(new ServerError(
                                        `${i18n.default.t('server-proxy.unknownDatasetStateReceived')}: ${response.data.state}`,
                                        500,
                                    ));
                                }
                            } catch (errorData) {
                                reject(
                                    generateError(errorData),
                                );
                            }
                        }

                        setTimeout(checkStatus, 1000);
                    });
                }

                const batchOfFiles = new FormData();
                let filesLength = 0;
                let fileSourceType = ''
                for (const key in files) {
                    if (Object.prototype.hasOwnProperty.call(files, key)) {
                        for (let i = 0; i < files[key].length; i++) {
                            filesLength = files[key].length
                            fileSourceType = key
                            batchOfFiles.append(`${key}[${i}]`, files[key][i]);
                        }
                    }
                }

                batchOfFiles.append("imageQuality", taskData.imageQuality)
                batchOfFiles.append("frameFilter", taskData.frameFilter)
                batchOfFiles.append("expandFilesLength", filesLength)
                batchOfFiles.append("expandFilesSourceType", fileSourceType)
                batchOfFiles.append("fromTask", taskData.fromTask)

                if(taskData.imageMaskData != undefined && taskData.imageMaskData.length != 0 && taskData.imageMaskData != null){
                    batchOfFiles.append("imageMaskData", JSON.stringify(taskData.imageMaskData))
                }
                if(taskData.metaData != undefined && taskData.metaData.length != 0 && taskData.metaData != null){
                    batchOfFiles.append("metaData", JSON.stringify(taskData.metaData))
                }

                batchOfFiles.append("metaDataSpecific", JSON.stringify(metaDataSpecific))

                let response = null;

                onUpdate(i18n.default.t('server-proxy.taskIsCreatingInServer'));
                try {
                    response = await Axios.post(`${backendAPI}/datasets`, JSON.stringify(taskData), {


                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }


                onUpdate(i18n.default.t('server-proxy.uploadDataToServer'));
                try {
                    const options = {
                        onUploadProgress: (progressEvent) => {
                          const {loaded, total} = progressEvent;
                          let percent = Math.floor( (loaded * 100) / total )
                          onUpdateProgress(percent);
                        }
                    }
                    await Axios.post(`${backendAPI}/datasets/${response.data.id}/data`, batchOfFiles, options, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    try {
                        await deleteDataset(response.data.id);
                    } catch (_) {
                        // ignore
                    }

                    throw generateError(errorData);
                }

                try {
                    await wait(response.data.id);
                } catch (createException) {
                    await deleteDataset(response.data.id);
                    throw createException;
                }

                const createdDataset = await getDatasets(response.data.id);
                return createdDataset[0];
            }

            async function expandDataset(files, id, maskPoints) {
                const { backendAPI } = config;

                const batchOfFiles = new FormData();
                let filesLength = 0;
                let fileSourceType = ''
                for (const key in files) {
                    if (Object.prototype.hasOwnProperty.call(files, key)) {
                        for (let i = 0; i < files[key].length; i++) {
                            filesLength = files[key].length
                            fileSourceType = key
                            batchOfFiles.append(`${key}[${i}]`, files[key][i]);
                        }
                    }
                }

                batchOfFiles.append("maskPoints", maskPoints)
                batchOfFiles.append("expandFilesLength", filesLength)
                batchOfFiles.append("expandFilesSourceType", fileSourceType)

                let response = null;

                try {
                    response = await Axios.post(`${backendAPI}/datasets/${id}/expand`, batchOfFiles, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    return errorData.response
                    //throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response
            }

            async function setPrivacy(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/datasets/${id}/set-privacy`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function setPrivacyTask(id) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${id}/set-privacy/`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function setUsers(id, users) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/datasets/${id}/set-users?users=${users}`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function setUsersTask(id, users) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${id}/set-users/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function removeUsersTask(id, users) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${id}/remove-users/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function setViewersTask(id, users) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${id}/set-viewers/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function removeViewersTask(id, users) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/tasks/${id}/remove-viewers/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function removeUser(id, userId) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/datasets/${id}/remove-user?userId=${userId}`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function getDatasets(id = null, count = null, pageNum = 1, datasetViewType = "all") {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (id == null) {
                        response = await Axios.get(`${backendAPI}/datasets?page_size=${count}` + `&page=${pageNum}` + `&datasetViewType=${datasetViewType}`, {
                            proxy: config.proxy,
                        });
                        response.data.results.count = response.data.count;
                        return response.data.results;
                    }
                    else {
                        response = await Axios.get(`${backendAPI}/datasets/${id}`, {
                            proxy: config.proxy,
                        });
                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                        return [response.data];
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getVSPDatasets(id = null, count = null, pageNum = 1) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (id == null) {
                        response = await Axios.get(`${backendAPI}/vsp/datasets?page_size=${count}` + `&page=${pageNum}`, {
                            proxy: config.proxy,
                        });
                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                        response.data.results.count = response.data.count;
                        return response.data.results;
                    }
                    else {
                        response = await Axios.get(`${backendAPI}/vsp/datasets/${id}`, {
                            proxy: config.proxy,
                        });
                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                        return [response.data];
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }

            }

            async function getCkanDatasets(name = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    if (name == null) {
                        response = await Axios.get(`${backendAPI}/ckan/datasets/unique`, {
                            proxy: config.proxy,
                        });
                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                        return response.data.results;
                    }
                    else {
                        response = await Axios.get(`${backendAPI}/ckan/datasets/${name}`, {
                            proxy: config.proxy,
                        });
                        if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                            logout();
                            return;
                        }
                        return [response.data];
                    }
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getTextAnnotations(projectId, username) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/get-text-annotations/${projectId}/${username}`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data;

                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteTextAnnotations(projectId, username) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/delete-text-annotations/${projectId}/${username}`, {
                        proxy: config.proxy,
                    });
                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }
                    return response.data;

                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function deleteDataset(id) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/annota/delete-dataset/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }

            }

            async function downloadAnnotationImages(id,size) {
                const { backendAPI } = config;

                let response;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${id}/download-annotation-images/${size}`, {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    if(String(errorData).includes("status code 400"))
                        response = "notReady"
                    else if(String(errorData).includes("status code 405"))
                        response = "preparing"
                    return response;
                }
                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if(size == 'large')
                    link.setAttribute('download', 'Annotation_Images_Upscaled_' + id + '.zip');
                else if(size == 'middle')
                    link.setAttribute('download', 'Annotation_Images_Original_' + id + '.zip');
                else if(size == 'small')
                    link.setAttribute('download', 'Annotation_Images_Downscaled_' + id + '.zip');
                document.body.appendChild(link);
                link.click();

                return response;
            }


            async function updateDatasetName(id, name) {
                const { backendAPI } = config;
                const formData = new FormData();
                formData.append("name", name);
                try {
                    await Axios.post(`${backendAPI}/annota/update-datasetname/${id}`, formData, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function trackObject(data) {
                const { backendAPI } = config;

                let result = null
                try {
                    result = await Axios.post(`${backendAPI}/annota/track`, JSON.stringify(data));
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof result.data !== 'undefined' && result.data.status === "logout") {
                    logout();
                    return;
                }

                return result;
            }

            async function saveLogs(logs) {
                const { backendAPI } = config;

                try {
                    await Axios.post(`${backendAPI}/server/logs`, JSON.stringify(logs), {

                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }


            async function updateGroupName(id, name) {
                const { backendAPI } = config;
                const formData = new FormData();
                formData.append("name", name);
                try {
                    await Axios.post(`${backendAPI}/annota/update-groupname/${id}`, formData, {
                        proxy: config.proxy,
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getGroups(userId = null) {
                let mainResponse = null;
                let baseURL = null;
                try {
                    const data = JSON.stringify({});
                    baseURL = `${config.backendAPI}/annota/groups`;
                    if (userId) {
                        baseURL = baseURL + `?userId=${userId}`
                    }
                    mainResponse = await Axios.get(baseURL, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not fetch groups of '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function getSpecialAnnotators() {
                let mainResponse = null;
                let baseURL = null;
                try {
                    const data = JSON.stringify({});
                    baseURL = `${config.backendAPI}/annota/special_annotators`;
                    // if (userId) {
                    //     baseURL = baseURL + `?userId=${userId}`
                    // }
                    mainResponse = await Axios.get(baseURL, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not fetch groups of '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }


            async function setGroupUsers(groupId = null, users = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/groups/${groupId}/set-users/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function setUserPermissions(userId = null, permissions = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/users/${userId}/set-permissions/`, {
                        proxy: config.proxy,
                        data: {
                            permissions: permissions
                        }
                    });

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function removeGroupUsers(groupId = -1, users = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/groups/${groupId}/remove-users/`, {
                        proxy: config.proxy,
                        data: {
                            users: users
                        }
                    });

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function deleteGroup(id = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/delete-group/${id}`, {
                        proxy: config.proxy,
                    });

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response.data.results;
                } catch (errorData) {
                    throw generateError(errorData);
                }


                return response
            }

            async function deleteJob(id = null) {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/delete-job/${id}`, {
                        proxy: config.proxy,
                    });

                    if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                        logout();
                        return;
                    }

                    return response;
                } catch (errorData) {
                    throw generateError(errorData);
                }
                return response
            }

            async function createNewGroup(userId = null, groupName = null) {
                let mainResponse = null;
                let baseURL = null;
                try {
                    const data = JSON.stringify({ "owner": userId, "groupName": groupName });
                    baseURL = `${config.backendAPI}/annota/groups/`;
                    /*if(userId){
                        baseURL = baseURL + `?userId=${userId}`
                    }*/
                    mainResponse = await Axios.post(baseURL, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData, `Could not take permission from '${userId}' user on the server`);
                }

                if (typeof mainResponse.data !== 'undefined' && mainResponse.data.status === "logout") {
                    logout();
                    return;
                }

                return mainResponse;
            }

            async function getSystemResources() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/system-resources`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function downloadSystemResources(exportFormat) {
                const { backendAPI } = config;

                let response = null;
                let baseURL = `${backendAPI}/annota/download-system-resources`
                try {
                    let params = []
                    if (exportFormat != null) {
                        params.push(`exportFormat=${exportFormat}`)
                    }
                    baseURL = baseURL.concat('?', params.join('&'))
                    response = await Axios.get(baseURL, {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (exportFormat == "pdf"){
                    link.setAttribute('download', "Sistem_Kaynak_Kullanımı.pdf");
                } else if (exportFormat == "docx") {
                    link.setAttribute('download', "Sistem_Kaynak_Kullanımı.docx");
                } else if (exportFormat == "csv") {
                    link.setAttribute('download', "Sistem_Kaynak_Kullanımı.csv");
                } else { // exportFormat == xlsx
                    link.setAttribute('download', "Sistem_Kaynak_Kullanımı.xlsx");
                }

                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function getSystemLogList(startDate, endDate, userId, pageNum) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/search-elk-logs`, {
                        proxy: config.proxy,
                        data: {startDate:startDate, endDate:endDate, userId:userId, pageNum:pageNum}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getSystemLogStats(startDate, endDate, numRecords, level) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/search-elk-logs-stats`, {
                        proxy: config.proxy,
                        data: {startDate:startDate, endDate:endDate, numRecords:numRecords, level:level}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getJobLogs() {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/get-job-logs`, {
                        proxy: config.proxy
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }
                return response.data;
            }

            async function getDashboardData(startDate, endDate, userId, timeFilter) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/dashboard-data`, {
                        proxy: config.proxy,
                        data: {startDate:startDate, endDate:endDate, userId:userId, timeFilter: timeFilter}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getPaymentsUsers(startDate, endDate) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/payments/users`, {
                        proxy: config.proxy,
                        data: {startDate:startDate, endDate:endDate}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function makePayment(startDate, endDate, username, receiptForm) {

                const { backendAPI } = config;
                let payment_data = new FormData
                payment_data.append("start_date", startDate)
                payment_data.append("end_date", endDate)
                if(receiptForm.length != 0)
                    payment_data.append("receipt_file", receiptForm[0])
                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/pay_annotations/${username}`, payment_data, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function deletePayment(paymentId) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/user_payments/cancel/${paymentId}`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function uploadReceipt(paymentId, receiptForm) {

                const { backendAPI } = config;
                let payment_data = new FormData
                if(receiptForm.length != 0)
                    payment_data.append("receipt_file", receiptForm[0])
                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/payments/${paymentId}/upload-receipt`, payment_data, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getDashboardDataStatistics(taskId, startDate, endDate, userId) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/dashboard-data-statistics`, {
                        proxy: config.proxy,
                        data: {taskId:taskId, startDate:startDate, endDate:endDate, userId:userId}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function createSpecialAnnotatorRequest(email) {

                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/annota/request-special-annotator`, {
                        proxy: config.proxy,
                        data: {email:email}
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function downloadDashboardData(startDate, endDate, userId, exportFormat) {
                const { backendAPI } = config;

                let response = null;
                let baseURL = `${backendAPI}/annota/download-dashboard-data`
                try {
                    let params = []
                    if (startDate != null) {
                        params.push(`startDate=${startDate}`)
                    }
                    if (endDate != null) {
                        params.push(`endDate=${endDate}`)
                    }
                    if (userId != null) {
                        params.push(`userId=${userId}`)
                    }
                    if(exportFormat != null){
                        params.push(`exportFormat=${exportFormat}`)
                    }
                    baseURL = baseURL.concat('?', params.join('&'))
                    response = await Axios.get(baseURL, {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (exportFormat == "pdf"){
                    link.setAttribute('download', "VK_Pano_Bilgileri.pdf");
                } else if (exportFormat == "docx") {
                    link.setAttribute('download', "VK_Pano_Bilgileri.docx");
                } else if (exportFormat == "xlsx") {
                    link.setAttribute('download', "VK_Pano_Bilgileri.xlsx");
                } else { //exportFormat == "csv"
                    link.setAttribute('download', "VK_Pano_Bilgileri.csv");
                }


                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function downloadPaymentsData(startDate, endDate, exportFormat) {
                const { backendAPI } = config;

                let response = null;
                let baseURL = `${backendAPI}/annota/download-payments-data`
                try {
                    let params = []
                    if (startDate != null) {
                        params.push(`startDate=${startDate}`)
                    }
                    if (endDate != null) {
                        params.push(`endDate=${endDate}`)
                    }
                    if(exportFormat != null){
                        params.push(`exportFormat=${exportFormat}`)
                    }
                    baseURL = baseURL.concat('?', params.join('&'))
                    response = await Axios.get(baseURL, {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (exportFormat == "pdf"){
                    link.setAttribute('download', "VK_Ödeme_Bilgileri.pdf");
                } else if (exportFormat == "docx") {
                    link.setAttribute('download', "VK_Ödeme_Bilgileri.docx");
                } else if (exportFormat == "xlsx") {
                    link.setAttribute('download', "VK_Ödeme_Bilgileri.xlsx");
                } else { // exportFormat == "csv"
                    link.setAttribute('download', "VK_Ödeme_Bilgileri.csv");
                }

                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function downloadReceipt(paymentId) {

                const { backendAPI } = config;

                let response = null;
                let baseURL = `${backendAPI}/annota/payments/${paymentId}/download-receipt`
                try {
                    response = await Axios.get(baseURL, {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "ödeme_" + paymentId + "_dekont.pdf");
                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function downloadTaskGuidelines(taskId) {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/tasks/${taskId}/download-guidelines`,
                        {
                        responseType: 'blob',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "Yönerge.pdf");
                document.body.appendChild(link);
                link.click();

                return response;
            }

            async function sendModelExportInfoToYZP(taskId, exportType) {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.post(`${backendAPI}/tasks/${taskId}/send-model-export-info-to-yzp`, {"exportType":exportType}, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }
                return response;
            }

            async function getFaq() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/faq?page_size=all`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function createFaq(number, question, answer) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    number,
                    question,
                    answer
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/faq/create-faq/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateFaq(id, number, question, answer) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    id,
                    number,
                    question,
                    answer
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/faq/update-faq/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function deleteFaq(id) {
                const { backendAPI } = config;

                try {
                    await Axios.delete(`${backendAPI}/annota/faq/${id}`);
                } catch (errorData) {
                    throw generateError(errorData);
                }
            }

            async function getLevelScoreIntervals() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/levelScoreIntervals`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function checkUpdateLevelScoreIntervalsStatus(id) {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/levelScoreIntervals/${id}/status`);
                } catch (errorData) {
                    return false
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response;
            }

            async function updateLevelScoreIntervals(levelScoreIntervals) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    levelScoreIntervals
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/levelScoreIntervals/update-intervals/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getRegistrationStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/config`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getThresholdStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/testthreshold`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getJobThresholdStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/jobthreshold`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getPaymentLimitStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/paymentlimit`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getJobCompleteTimesStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/job-complete-times`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getAnnotationPriceStatus() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/annotationprice`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function getSessionTimeoutDuration() {
                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/session-timeout-dur`,
                        {
                        responseType: 'application/json',
                    });
                    console.log("server proxy getSessTimeout: ", response)
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }


            async function getRegistrationStatusPublic() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/config-public`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data.results;
            }

            async function updateRegistrationStatus(status) {


                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    status
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/config/update-registration-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateThresholdStatus(status) {


                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    status
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/testthreshold/update-threshold-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updatePaymentLimitStatus(minimum, maximum) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    minimum,
                    maximum
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/paymentlimit/update-payment-limit-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateJobCompleteTimesStatus(annotation_job, evaluation_job) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    annotation_job,
                    evaluation_job
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/job-complete-times/update-job-complete-times-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateAnnotationPriceStatus(status) {


                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    status
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/annotationprice/update-annotation-price-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateSessionTimeoutDuration(timeoutDuration) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    timeoutDuration
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/session-timeout-dur/update-session-timeout-dur/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateJobThresholdStatus(status) {

                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    status
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/jobthreshold/update-job-threshold-status/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getRoles(pageNum=0) {

                const { backendAPI } = config;
                let response = null;
                try {
                    if(pageNum ==0){ //for admin-page
                        response = await Axios.get(`${backendAPI}/annota/roles?page_size=all`,
                        {
                        responseType: 'application/json',
                    });
                    }else{ //for roles page with pagination
                    response = await Axios.get(`${backendAPI}/annota/roles?page=${pageNum}`,
                        {
                        responseType: 'application/json',
                    });
                }
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function getPermissions() {

                const { backendAPI } = config;
                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/permissions`,
                        {
                        responseType: 'application/json',
                    });
                } catch (errorData) {
                    return false
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function createRole(roleName,permissions) {

                const { backendAPI } = config;
                let response = null;
                const data = {"name":roleName,"permissions":permissions}
                try {
                    response = await Axios.post(`${backendAPI}/annota/roles/create-role/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateRole(id,permissions) {

                const { backendAPI } = config;
                let response = null;
                const data = {"id":id,"permissions":permissions}
                try {
                    response = await Axios.post(`${backendAPI}/annota/roles/update-role/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function updatePaymentConstant(value) {
                const { backendAPI } = config;
                let response = null;
                const data = {"value": value}
                try {
                    response = await Axios.post(`${backendAPI}/annota/payment_constant/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function getPaymentConstant() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/payment_constant`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updateAutoSaveDuration(value) {
                const { backendAPI } = config;
                let response = null;
                const data = {"value": value}
                try {
                    response = await Axios.post(`${backendAPI}/annota/auto_save_duration/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }


            async function getAutoSaveDuration() {
                const { backendAPI } = config;

                let response = null;
                try {
                    response = await Axios.get(`${backendAPI}/annota/auto_save_duration`, {
                        proxy: config.proxy,
                    });

                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            async function updatePhoto(userId, base64Photo) {
                const { backendAPI } = config;
                let response = null;
                const data = JSON.stringify({
                    base64Photo,
                });
                try {
                    response = await Axios.post(`${backendAPI}/annota/users/${userId}/updatePhoto/`, data, {
                        proxy: config.proxy,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (errorData) {
                    throw generateError(errorData);
                }

                if (typeof response.data !== 'undefined' && response.data.status === "logout") {
                    logout();
                    return;
                }

                return response.data;
            }

            Object.defineProperties(this, Object.freeze({
                server: {
                    value: Object.freeze({
                        about,
                        share,
                        formats,
                        exception,
                        login,
                        logout,
                        reset,
                        resetConfirm,
                        authorized,
                        register,
                        annotaRegister,
                        verifyInformation,
                        annotaRegisterSpecialAnnotator,
                        validateCaptcha,
                        request: serverRequest,
                        userAgreements,
                        getSystemResources,
                        downloadSystemResources,
                        getDashboardData,
                        getPaymentsUsers,
                        makePayment,
                        uploadReceipt,
                        deletePayment,
                        getDashboardDataStatistics,
                        downloadDashboardData,
                        downloadPaymentsData,
                        downloadTaskGuidelines,
                        sendModelExportInfoToYZP,
                        downloadReceipt,
                        getSystemLogList,
                        getSystemLogStats,
                        getJobLogs,
                    }),
                    writable: false,
                },
                groups: {
                    value: Object.freeze({
                        getGroups,
                        getSpecialAnnotators,
                        createNewGroup,
                        setGroupUsers,
                        updateGroupName,
                        removeGroupUsers,
                        deleteGroup,
                    }),
                    writable: false,
                },
                tasks: {
                    value: Object.freeze({
                        getTasks,
                        saveTask,
                        createTask,
                        deleteTask,
                        exportDataset,
                        assigntask,
                        assigntaskRandom,
                        setassigneelimit,
                        setTaskDifficulty,
                        getAnnotaTasks,
                        approveTask,
                        rejectTask,
                        setTaskType,
                        getPointCloudTasks,
                        getTaskPath,
                        expandDataset,
                        getTextTaskDataset,
                        getPCDAnnotations,
                        getTextAnnotations,
                        deleteTextAnnotations,
                        setPrivacyTask,
                        setUsersTask,
                        removeUsersTask,
                        setViewersTask,
                        removeViewersTask,
                        downloadAnnotationImages
                    }),
                    writable: false,
                },

                jobs: {
                    value: Object.freeze({
                        getTestJobs,
                        getTrainingJobs,
                        getJob,
                        saveJob,
                        trainingstate,
                        teststate,
                        selecttraining,
                        selecttest,
                        getTrainingList,
                        getTrainingTestTasks,
                        getUserJobs,
                        getUserJobsStatistics,
                        getTaskJobs,
                        deleteJob
                    }),
                    writable: false,
                },

                users: {
                    value: Object.freeze({
                        getUsers,
                        getSelf,
                        getAnnotaSelf,
                        changePassword,
                        updateUser,
                        updateUserPatch,
                        updateInformations,
                        getAnnotaUsers,
                        approve,
                        reject,
                        forgive,
                        demote,
                        promote,
                        bless,
                        curse,
                        block,
                        unblock,
                        give,
                        take,
                        makeDP,
                        makeSA,
                        takeSA,
                        takeDP,
                        makeSSBDP,
                        takeSSBDP,
                        makeAnnotator,
                        takeAnnotator,
                        createVerification,
                        createVerificationSpecialAnnotator,
                        createContactMail,
                        verifyCode,
                        checkVerification,
                        createSpecialAnnotatorRequest,
                        updateAvatarId,
                        setUserPermissions,
                        addRole,
                        updatePhoto,
                        removeRole
                    }),
                    writable: false,
                },


                frames: {
                    value: Object.freeze({
                        getData,
                        getMeta,
                        getPreview,
                    }),
                    writable: false,
                },

                annotations: {
                    value: Object.freeze({
                        updateAnnotations,
                        getAnnotations,
                        dumpAnnotations,
                        uploadAnnotations,
                    }),
                    writable: false,
                },

                datasets: {
                    value: Object.freeze({
                        getDatasets,
                        createDataset,
                        deleteDataset,
                        updateDatasetName,
                        setPrivacy,
                        setUsers,
                        removeUser,
                        getCkanDatasets,
                        trackObject,
                        getVSPDatasets,
                        downloadDataset,
                    }),
                    writable: false
                },
                logs: {
                    value: Object.freeze({
                        save: saveLogs,
                    }),
                    writable: false,
                },
                management: {
                    value: Object.freeze({
                        getFaq,
                        getLevelScoreIntervals,
                        updateLevelScoreIntervals,
                        checkUpdateLevelScoreIntervalsStatus,
                        createFaq,
                        updateFaq,
                        deleteFaq,
                        getRegistrationStatus,
                        getRegistrationStatusPublic,
                        updateRegistrationStatus,
                        getThresholdStatus,
                        updateThresholdStatus,
                        getJobThresholdStatus,
                        updateJobThresholdStatus,
                        getAnnotationPriceStatus,
                        updateAnnotationPriceStatus,
                        getSessionTimeoutDuration,
                        updateSessionTimeoutDuration,
                        getPaymentLimitStatus,
                        getJobCompleteTimesStatus,
                        updatePaymentLimitStatus,
                        updateJobCompleteTimesStatus,
                        getRoles,
                        createRole,
                        getPermissions,
                        updateRole,
                        deleteRole,
                        updatePaymentConstant,
                        getPaymentConstant,
                        updateAutoSaveDuration,
                        getAutoSaveDuration
                    }),
                    writable: false,
                },
                messages: {
                    value: Object.freeze({
                        getMessageUsersInteracted,
                        getMessages,
                        createMessage,
                        markMessages,
                    }),
                    writable: false,
                },
                yzp: {
                    value: Object.freeze({
                        setAuthorizationHeader
                    }),
                    writable: false,
                }
            }));
        }
    }

    const serverProxy = new ServerProxy();
    module.exports = serverProxy;
})();