import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Table, Input, Radio, Card, Icon, Modal, Checkbox, Tooltip } from 'antd';
import getCore from 'cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import i18n from '../i18n';
import Moment from 'moment';
import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';
import { RouteComponentProps } from 'react-router';
import PaymentsPageUsers from 'containers/payments-page-users';
import UserJobsStatisticsPage from 'containers/user-job-statistics'
import MaskedInput from 'antd-mask-input'
import FormItem from 'antd/lib/form/FormItem';
import { useTranslation } from 'react-i18next';

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}
type PaymentsPageProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class_component PaymentsPageComponent for /payments path
 */
class PaymentsPageComponent  extends React.PureComponent<PaymentsPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            user: null,
            iban: props.auth.annotaUser[0].iban != null ? props.auth.annotaUser[0].iban.substring(2) : null,
            payment_requested: props.auth.annotaUser[0].payment_requested,
            errorFlagIban: false,
            errorMessageIban: null,
        }

        this.onChangePaymentRequested = this.onChangePaymentRequested.bind(this);
        // this.onChangeIban = this.onChangeIban.bind(this);
        this.onBlurIban = this.onBlurIban.bind(this);
        this.updateIban = this.updateIban.bind(this);
    }

    /**
     * @async
     * @description updates iban input, handles response with Modal success and error
     */
    private async updateIban(){

        let response = await cvat.users.updateUserPatch(this.props.auth.user.id, {
            payment_requested: this.state.payment_requested,
            iban: "TR"+this.state.iban.split(" ").join(""),
        })
        if(response.status == 200) {
            this.props.verifyAuthorized();
            Modal.success({
                title: i18n.t('keywords.success'),
                content: i18n.t('payments-page.saveSuccessMessage'),
            });
        } else{
            Modal.error({
                title: i18n.t('keywords.error'),
                content: i18n.t('payments-page.updateIbanError'),
            });
        }
    }

    /**
     *
     * @param e payment request checkbox event
     * @description changes state on payment_requested
     */
    public onChangePaymentRequested(e) {
        this.setState({
            payment_requested: e.target.checked,
        });
    }

    // public onChangeIban(e) {
    //     this.setState({
    //         iban: e.target.value,
    //     });
    // }

    /**
     *
     * @param e
     * @description check iban input length handle result, update state on iban check
     */
    public onBlurIban(e) {
        let ibanCheck = e.target.value.replace('_','');
        ibanCheck = ibanCheck.split(" ").join("");
        if(ibanCheck.length< 24) {
            this.setState({
                errorFlagIban: true,
                errorMessageIban: i18n.t('payments-page.ibanInputErrorMessage')
            });
        }
        else {
            this.setState({
                iban: e.target.value,
                errorFlagIban: false,
                errorMessageIban: null,
            });
        }
    }

    /**
     *
     * @returns save button template
     */
    private renderSaveButton(): JSX.Element {
        return (
            <FormItem >
                <Button disabled={this.state.errorFlagIban} onClick={this.updateIban}>
                    Kaydet
                </Button>
            </FormItem>
        );
    }

    /**
     *
     * @returns JSX template for /payments
     */
    render(){
        return (
            <section>
                {(this.props.auth.user.isSuperuser !== true) ?
                <div className="approval-page">
                    <h1 style={{marginTop:'1%'}}>{i18n.t('payments-page.paymentHeader')}</h1>
                    <Form>
                        <FormItem style={{width:'23%'}} label = "IBAN">
                            <MaskedInput
                            id="iban"
                            prefix="TR"
                            mask= "11 1111 1111 1111 1111 1111 11"
                            defaultValue= {this.state.iban}
                            onChange={this.onBlurIban} />
                            {(this.state.errorFlagIban === true) ? <Text style={{color: "red", fontStyle: "italic"}}>{this.state.errorMessageIban}</Text> : null }
                        </FormItem>
                        <FormItem >
                            <Tooltip title={i18n.t('payments-page.monthlyPaymentDesc')}>
                                <Checkbox onChange={this.onChangePaymentRequested} checked={this.state.payment_requested}>{i18n.t('payments-page.monthlyPayment')}</Checkbox>
                            </Tooltip>
                            <Text style={{fontSize:"11px"}} >({i18n.t('payments-page.monthlyPaymentDesc')})</Text>
                        </FormItem>
                        {this.renderSaveButton()}
                    </Form>
                </div> : null }

                {(this.props.auth.user.isSuperuser === true) ?
                <Row style={{marginRight: "5%"}}>
                    <PaymentsPageUsers></PaymentsPageUsers>
                </Row> : null }

                {/* {(this.props.auth.user.isSuperuser !== true) ?
                <Row>
                    <UserJobsStatisticsPage user={this.props.auth.user} ></UserJobsStatisticsPage>
                </Row> : null } */}

            </section>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentsPageComponent));
