import React from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';
import { Radio, Icon, Modal, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  PreviousIcon,
  NextIcon,
  NoDataVector
} from 'icons';

interface FunctionProp {
  fileList: any;
  getFiles: (images: any) => void;
  visible: boolean;
  setInvisible: () => void;
}
interface OwnProps {
  fileList: any;
  visible: boolean;
  setInvisible: () => void;
  editor: any;
  onReady: any;
  getFiles: (images: any) => void;
}
let initialPos: any, bounds: any, rect: any, dragging = false;
export default function ImageMaskEditor(props: FunctionProp) {
  const { editor, onReady } = useFabricJSEditor();
  return <ImageMaskEditorClass setInvisible={props.setInvisible} visible={props.visible} getFiles={props.getFiles} fileList={props.fileList} editor={editor} onReady={onReady} />
}
class ImageMaskEditorClass extends React.Component<OwnProps> {
  public constructor(props: OwnProps) {
    super(props)

    this.state = {
      drawingMode: false,
      imageIndex: 0,
      canvasObjects: [],
      rectProps: {
        stroke: 'black',
        strokeWidth: 1,
        fill: 'black',
      }
    }
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onDelete = this.onDelete.bind(this)

  }
  componentWillUnmount() {
    this.state.drawingMode && this.uninstall();
    rect = null;
    dragging = false;
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const { editor } = this.props;
    let flag = 0;
    if (prevProps.fileList != this.props.fileList) {
      this.setState({ canvasObjects: [] });
    }
    if (prevProps.visible == true && this.props.visible == false) { //closing modal
      let newObj = editor.canvas.toJSON();
      let newArr = this.state.canvasObjects;
      newArr[prevState.imageIndex] = newObj;
      editor.canvas.clear();
      this.state.drawingMode && this.uninstall();
      rect = null;
      dragging = false;
      this.setState({ drawingMode: false, imageIndex: 0, canvasObjects: newArr });
    }
    else if (prevProps.visible == false && this.props.visible == true) { //opening modal
      if (!this.state.canvasObjects[this.state.imageIndex]) {
        this.convertImage(this.props.fileList[this.state.imageIndex])
        flag = 1;
      } else {
        if (flag == 0)
          editor.canvas.loadFromJSON(this.state.canvasObjects[this.state.imageIndex], editor.canvas.renderAll.bind(editor.canvas))
      }
    }

    else if (this.props.visible == true && prevProps.visible == true) { //after init of modal
      if (!this.state.canvasObjects[this.state.imageIndex]) {
        this.convertImage(this.props.fileList[this.state.imageIndex])
        flag = 1;
      }

      if (this.state.drawingMode != prevState.drawingMode)
        this.state.drawingMode ? this.install() : this.uninstall();
      else {
        if (this.state.drawingMode && (this.state.imageIndex == prevState.imageIndex)) {
          this.install()
        }
      }

      if (this.state.imageIndex != prevState.imageIndex) {
        //this.props.editor.canvas.includeDefaultValues = false;
        let newObj = editor.canvas.toJSON(["height","width"]);
        editor.canvas.clear();
        if (this.state.canvasObjects.length == 0) {
          let canvasObj = new Array();
          canvasObj[prevState.imageIndex] = newObj
          this.setState({ canvasObjects: canvasObj }, () => {
            if (flag == 0) editor.canvas.loadFromJSON(this.state.canvasObjects[this.state.imageIndex], editor.canvas.renderAll.bind(editor.canvas))
          });
        }
        else {
          let newArr = this.state.canvasObjects;
          newArr[prevState.imageIndex] = newObj;
          this.setState({ canvasObjects: newArr }, () => {
            if (flag == 0) editor.canvas.loadFromJSON(this.state.canvasObjects[this.state.imageIndex], editor.canvas.renderAll.bind(editor.canvas))
          });
        }

      }

    }
  }

  /**
   * Get files
   * @returns files
   */
  getFiles() {
    const { editor } = this.props;
    if (editor) {
      //this.props.editor.canvas.includeDefaultValues = false;
      let lastData = editor.canvas.toJSON(["width", "height"])

      console.log(this.state.canvasObjects)
      console.log(lastData)
      let data = new Array();
      for (let i = 0; i < this.state.canvasObjects.length; i++) {
        /* let scaleX = this.state.canvasObjects[i].width/this.state.canvasObjects[i].backgroundImage.width;
        let scaleY = this.state.canvasObjects[i].height / this.state.canvasObjects[i].backgroundImage.height; */
        let imgWidth = this.state.canvasObjects[i].backgroundImage.width;
        let imgHeight = this.state.canvasObjects[i].backgroundImage.height;
        let canvasWidth = this.state.canvasObjects[i].width;
        let canvasHeight = this.state.canvasObjects[i].height

        let imgRatio = imgWidth / imgHeight;
        let canvasRatio = canvasWidth / canvasHeight;
        let scale = 1;
        if (imgRatio <= canvasRatio) {
          if (imgHeight > canvasHeight) {
            scale=canvasHeight/imgHeight;
          }
          else{
            scale=canvasWidth/imgWidth;
          }
        } else {
          if (imgWidth > canvasWidth) {
            scale=canvasWidth/imgWidth;
          }
          else{
            scale=canvasHeight/imgHeight;
          }
        }
        let scaleX = scale;
        let scaleY = scale;
        let offsetX=(this.state.canvasObjects[i].width-this.state.canvasObjects[i].backgroundImage.width*this.state.canvasObjects[i].backgroundImage.scaleX)/2
        let offsetY=(this.state.canvasObjects[i].height-this.state.canvasObjects[i].backgroundImage.height*this.state.canvasObjects[i].backgroundImage.scaleY)/2
        data[i] = {};
        for (let j = 0; j < this.state.canvasObjects[i].objects.length; j++) {
          let realWidth = this.state.canvasObjects[i].objects[j].width / scaleX;
          let realHeight = this.state.canvasObjects[i].objects[j].height / scaleY;
          let realTop = (this.state.canvasObjects[i].objects[j].top-offsetY) / scaleY;
          let realLeft = (this.state.canvasObjects[i].objects[j].left-offsetX) / scaleX;
          data[i][j] = { top: realTop, left: realLeft, width: realWidth, height: realHeight };
        }
      }
      /* let scaleX = lastData.width / lastData.backgroundImage.width;
      let scaleY = lastData.height / lastData.backgroundImage.height; */
      let imgWidth = lastData.backgroundImage.width;
      let imgHeight = lastData.backgroundImage.height;
      let canvasWidth = lastData.width;
      let canvasHeight = lastData.height

      let imgRatio = imgWidth / imgHeight;
      let canvasRatio = canvasWidth / canvasHeight;
      let scale = 1;
      if (imgRatio <= canvasRatio) {
        if (imgHeight > canvasHeight) {
          scale=canvasHeight/imgHeight;
        }
        else{
          scale=canvasWidth/imgWidth;
        }
      } else {
        if (imgWidth > canvasWidth) {
          scale=canvasWidth/imgWidth;
        }
        else{
          scale=canvasHeight/imgHeight;
        }
      }
      let scaleX = scale;
      let scaleY = scale;
      let offsetX=(lastData.width-lastData.backgroundImage.width*scaleX)/2
      let offsetY=(lastData.height-lastData.backgroundImage.height*scaleY)/2
      data[this.state.imageIndex] = {};
      for (let j = 0; j < lastData.objects.length; j++) {
        let realWidth = lastData.objects[j].width / scaleX;
        let realHeight = lastData.objects[j].height / scaleY;
        let realTop = (lastData.objects[j].top-offsetY) / scaleY;
        let realLeft = (lastData.objects[j].left-offsetX) / scaleX;
        data[this.state.imageIndex][j] = { top: realTop, left: realLeft, width: realWidth, height: realHeight };

      }
      console.log(data)
      return this.props.getFiles(data);
    }

  }

  /**
  * Listen mouseDown evemt
  *
  * @param e MouseEvent
  * @returns na
  */
  onMouseDown(e: MouseEvent) {
    const { editor } = this.props;
    if (editor) {
      dragging = true;
      if (!this.state.drawingMode) {
        return
      }
      initialPos = { ...e.pointer }
      bounds = {}
      if (this.state.drawingMode) {
        rect = new fabric.Rect({
          left: initialPos.x,
          top: initialPos.y,
          width: 0, height: 0,
          ...this.state.rectProps
        });
        editor.canvas.add(rect)
      }
    }
  }

  /**
   * Update canvas
   *
   * @param pointer
   */
  update(pointer: any) {
    const { editor } = this.props;

    if (editor) {
      if (initialPos.x > pointer.x) {
        bounds.x = Math.max(0, pointer.x)
        bounds.width = initialPos.x - bounds.x
      } else {
        bounds.x = initialPos.x
        bounds.width = pointer.x - initialPos.x
      }
      if (initialPos.y > pointer.y) {
        bounds.y = Math.max(0, pointer.y)
        bounds.height = initialPos.y - bounds.y
      } else {
        bounds.height = pointer.y - initialPos.y
        bounds.y = initialPos.y
      }

      if (this.state.drawingMode) {
        rect.left = bounds.x
        rect.top = bounds.y
        rect.width = bounds.width
        rect.height = bounds.height
        rect.dirty = true

        editor.canvas.requestRenderAll()
      }
    }
  }

  /**
  * Listen mouse movement
  *
  * @param e MouseEvent
  * @returns na
  */
  onMouseMove(e: MouseEvent) {
    const { editor } = this.props;

    if (editor) {
      if (!dragging || !this.state.drawingMode) {
        return
      }
      requestAnimationFrame(() => this.update(e.pointer))
    }
  }

  /**
  * Listen mouse up
  *
  * @param e MouseEvent
  * @returns na
  */
  onMouseUp(e: MouseEvent) {
    const { editor } = this.props;

    if (editor) {

      dragging = false;
      if (!this.state.drawingMode) { return }
      if (this.state.drawingMode && rect && (rect.width == 0 || rect.height == 0)) {
        editor.canvas.remove(rect)
      }
      /* if (!options.drawRect || !rect) {
        /* rect = new fabric.Rect({
          ...bounds, left: bounds.x, top: bounds.y,
          ...options.rectProps
        });
        //editor.canvas.add(rect)
        // rect.dirty = true
        editor.canvas.requestRenderAll()
      } */
      //rect.setCoords() // important!
      this.state.drawingMode && this.uninstall();
    }
  }


  install() {
    const { editor } = this.props;

    if (editor) {

      dragging = false; rect = null
      editor.canvas.on('mouse:down', this.onMouseDown);
      editor.canvas.on('mouse:move', this.onMouseMove);
      editor.canvas.on('mouse:up', this.onMouseUp);
    }
  }

  uninstall() {
    const { editor } = this.props;

    if (editor) {

      dragging = false; rect = null
      editor.canvas.off('mouse:down', this.onMouseDown);
      editor.canvas.off('mouse:move', this.onMouseMove);
      editor.canvas.off('mouse:up', this.onMouseUp);
    }
  }

  /**
   * Convert image and set background image
   *
   * @param file
   */
  convertImage(file: any) {
    const { editor } = this.props;

    if (editor) {
      let reader = new FileReader();
      let filePromise = new Promise(resolve => {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
      filePromise.then(fileContent => {
        fabric.Image.fromURL(String(fileContent), function (myImg) {
          let imgWidth = myImg.width;
          let imgHeight = myImg.height;
          let canvasWidth = editor.canvas.getWidth();
          let canvasHeight = editor.canvas.getHeight();

          let imgRatio = imgWidth / imgHeight;
          let canvasRatio = canvasWidth / canvasHeight;
          if (imgRatio <= canvasRatio) {
            if (imgHeight > canvasHeight) {
              myImg.scaleToHeight(canvasHeight);
            }
            else{
              myImg.scaleToWidth(canvasWidth);

            }
          } else {
            if (imgWidth > canvasWidth) {
              myImg.scaleToWidth(canvasWidth);
            }
            else{
              myImg.scaleToHeight(canvasHeight);

            }
          }
          var center = editor.canvas.getCenter();

          editor.canvas.setBackgroundImage(myImg, editor.canvas.renderAll.bind(editor.canvas), {
             /* scaleX: editor.canvas.width / myImg.width,
             scaleY: editor.canvas.height / myImg.height, */
            top: center.top,
            left: center.left,
            originX: 'center',
            originY: 'center'

          });
        });
      });
    }
  }

  /**
   * Set drawingMode
   *
   * @param e event
   */
  onChange(e) {
    const { editor } = this.props;

    if (editor) {
      if (e.target.value == false)
        this.uninstall()
      this.setState({ drawingMode: e.target.value })
    }
  }

  /**
   * Handle deleting mask
   * @param e
   */
  onDelete(e) {
    const { editor } = this.props;

    if (editor) {

      if (!this.state.drawingMode) {
        let activeObj = editor.canvas.getActiveObject()
        if (activeObj) {
          Modal.confirm({
            title: 'Şekli Sil',
            content: 'Bu şekil silinsin mi?',
            onOk: () => {
              editor.canvas.remove(activeObj)
            },
            okButtonProps: {
              type: 'danger',
            },
            okText: 'Sil',
            cancelText: 'İptal'
          });
        }
      }
    }
  }

  render() {
    const { editor, fileList } = this.props;

    if (editor) {
      if (fileList.length > 0)
        return (
          <Modal
            visible={this.props.visible}
            maskClosable={false}
            destroyOnClose={false}
            width={800}
            onOk={() => { this.getFiles(); this.props.setInvisible() }}
            onCancel={() => {
              this.props.setInvisible()
            }}
            okText="Kaydet"
            cancelText="İptal"
          >
            <div style={{ height: "560px", width: "750px" }}>
              <Text strong style={{ color: "#5A5C69" }}>Görüntü Maskele</Text>
              <br />
              <Text>Görüntüde görünmesini istemediğiniz bölgeleri mouse yardımıyla kutu ekleyerek saklayabilirsiniz.</Text>
              <br />
              <br/>
              <Radio.Group size="small" onChange={(e) => this.onChange(e)} value={this.state.drawingMode} defaultValue="a">
                <Radio.Button style={{ color: "#6C63FF" }} value={false}>Kutu Düzenle</Radio.Button>
                <Radio.Button style={{ color: "#6C63FF" }} value={true}>Kutu Ekle</Radio.Button>
              </Radio.Group>
              &nbsp;
              <Button size="small" type="link" style={{ color: "#6C63FF" }} onClick={this.onDelete}>Sil</Button>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Icon component={PreviousIcon} onClick={(e) => this.state.imageIndex > 0 && this.setState({ imageIndex: this.state.imageIndex - 1 })} />
                <div style={{marginTop:"15px", height: "100%", width: "100%" }}>
                  <FabricJSCanvas className="sample-canvas" onReady={this.props.onReady} />
                </div>
                <Icon component={NextIcon} onClick={(e) => this.state.imageIndex < this.props.fileList.length - 1 && this.setState({ imageIndex: this.state.imageIndex + 1 })} />

              </div>

            </div>
          </Modal>

        );
      else
        return (
          <Modal
            visible={this.props.visible}
            maskClosable={false}
            destroyOnClose={false}
            width={800}
            onOk={() => { this.getFiles(); this.props.setInvisible() }}
            onCancel={() => {
              this.props.setInvisible(); this.state.drawingMode && this.uninstall();
              rect = null;
              dragging = false;
              this.setState({ drawingMode: false });
            }}
          okText="Kaydet"
          cancelText="İptal"
          >
            <div style={{ height: "560px", width: "750px" }}>
              <Text strong style={{ color: "#5A5C69" }}>Görüntü Maskele</Text>
              <br />
              <Text>Görüntüde görünmesini istemediğiniz bölgeleri mouse yardımıyla kutu ekleyerek saklayabilirsiniz.</Text>
              <br />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <Icon component={NoDataVector}></Icon>
                <br />
                <Text style={{ fontSize: "18px" }} type="secondary">Veri Yok</Text>
                <div style={{display:"none"}}>
                <FabricJSCanvas className="sample-canvas" onReady={this.props.onReady} />
                </div>
              </div>
            </div>
          </Modal>);
    }
    else {
      return (
        <Modal
          visible={this.props.visible}
          maskClosable={false}
          destroyOnClose={false}
          width={800}
          onOk={() => { this.getFiles(); this.props.setInvisible() }}
          onCancel={() => {
            this.props.setInvisible(); this.state.drawingMode && this.uninstall();
            rect = null;
            dragging = false;
            this.setState({ drawingMode: false });
          }}
        /* okText="Kaydet"
        cancelText="İptal" */
        >
          <div style={{ height: "560px", width: "750px" }}>
            <Text strong style={{ color: "#5A5C69" }}>Görüntü Maskele</Text>

              <FabricJSCanvas className="sample-canvas" onReady={this.props.onReady} />

          </div>
        </Modal>
      )
    }
  }
}
