// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { connect } from 'react-redux';

import getCore from 'cvat-core-wrapper';
import HeaderComponent from 'components/header/header';
import { SupportedPlugins, CombinedState } from 'reducers/interfaces';
import { logoutAsync } from 'actions/auth-actions';

const core = getCore();

interface StateToProps {
    logoutFetching: boolean;
    installedAnalytics: boolean;
    installedAutoAnnotation: boolean;
    installedTFSegmentation: boolean;
    installedTFAnnotation: boolean;
    username: string;
    isAdmin: boolean;
    groups: any;
    hasNewMessages: number;
    avatarId: any;
    toolName: string;
    serverHost: string;
    serverVersion: string;
    serverDescription: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
    switchSettingsShortcut: string;
    userState:string;
    level:any;
    expert:any;
}

interface DispatchToProps {
    onLogout: typeof logoutAsync;
}

/**
 *
 * @param state information containing level string
 * @returns user level based on state information
 */
function getUserLevel(state: String) {
    switch (state) {
        case 'freshman':
            return '1';
        case 'sophomore':
            return '2';
        case 'junior':
            return '3';
        case 'senior':
            return '4';
        case 'master':
            return '5';
        case 'qualified':
            return 'Nitelikli';
        case 'expert':
            return true;
        default:
            return 0;
    }
}

/**
 *
 * @param states combined state containing auth, plugins, about and shortcut information
 * @returns Header information parsed from combined state
 */
function mapStateToProps(states: CombinedState): StateToProps {
    const {
        auth: {
            fetching: logoutFetching,
            user: {
                username,
                isSuperuser,
                groups,
                hasNewMessages,
                avatarId,

            },
            annotaUser: {
                0:{
                    state,
                    level,
                    expert
                }
            }
        },
        plugins: {
            list,
        },
        about: {
            server,
            packageVersion,
        },
        shortcuts: {
            normalizedKeyMap,
        },
    } = states;

    return {
        logoutFetching,
        installedAnalytics: list[SupportedPlugins.ANALYTICS],
        installedAutoAnnotation: list[SupportedPlugins.AUTO_ANNOTATION],
        installedTFSegmentation: list[SupportedPlugins.TF_SEGMENTATION],
        installedTFAnnotation: list[SupportedPlugins.TF_ANNOTATION],
        isAdmin: isSuperuser,
        username: username,
        groups: groups,
        hasNewMessages,
        avatarId,
        level:getUserLevel(level),
        expert:getUserLevel(expert),
        toolName: server.name as string,
        serverHost: core.config.backendAPI.slice(0, -7),
        serverDescription: server.description as string,
        serverVersion: server.version as string,
        coreVersion: packageVersion.core,
        canvasVersion: packageVersion.canvas,
        uiVersion: packageVersion.ui,
        switchSettingsShortcut: normalizedKeyMap.OPEN_SETTINGS,
        userState:state
    };
}

/**
 *
 * @param dispatch
 * @returns onLogout action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
const mapDispatchToProps: DispatchToProps = {
    onLogout: logoutAsync,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderComponent);
