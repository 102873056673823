import React from 'react';

import {
    Row,
    Col,
    InputNumber,
    Cascader,
    Button,
} from 'antd';
import i18n from '../../i18n'

import Form, { FormComponentProps } from 'antd/lib/form/Form';

export interface MaskConfiguration {
    maskingInstance: any;
}

type Props = FormComponentProps & {
    onSubmit(values: Object): void;
    files : any[]
};

let options = [];

const defaultState = {
    options: [],
};

/**
 * @class_component Mask Configuration Form
 */
class MaskConfigurationForm extends React.PureComponent<Props> {

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
    }

    /**
     *
     * @param e form submission event
     * @description form field validation and onSubmit call passed with props
     */
    handleSubmit = e => {
        const { onSubmit } = this.props
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.fileId=values.fileId[0]
                values.fileName=options[values.fileId].label
                onSubmit(values);
            }
        });
      };

    /**
     * @description reset form fields
     */
    public resetFields(): void {
        const { form } = this.props;
        form.resetFields();
    }

    componentDidMount(){
        const { files } = this.props;

        options = []
        for(let i=0; i < files.length; i++){
            if(files[i].name != undefined){
                options.push(
                    {
                        value: i,
                        label: files[i].name
                    }
                )
            }else{
                options.push(
                    {
                        value: i,
                        label: files[i]
                    }
                )
            }
        }

        this.setState({
          options: options
        })
    }

    componentDidUpdate(prevProps) {
        const { files } = this.props;

        // Typical usage (don't forget to compare props):
        if (files !== prevProps.files) {

            options = []
            for(let i=0; i < files.length; i++){
                if(files[i].name != undefined){
                    options.push(
                        {
                            value: i,
                            label: files[i].name
                        }
                    )
                }else{
                    options.push(
                        {
                            value: i,
                            label: files[i]
                        }
                    )
                }
            }

            this.setState({
              options: options
            })
        }
      }

    /**
     *
     * @returns Mask From JSX Element
    */
    private renderMaskForm(): JSX.Element {
        const { getFieldDecorator }= this.props.form

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row gutter = {8} style={{marginTop: '3%'}}>
                    <Col span = {9}>
                        <Form.Item>
                            {getFieldDecorator('fileId', {
                                rules: [{ required: true, message: i18n.t('messages.selectFiles')}],
                            })(
                                <Cascader options={this.state.options} placeholder={i18n.t('keywords.selectFiles')} />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span = {6}>
                        <Form.Item>
                            {getFieldDecorator('start', {
                                rules: [{ required: true, message: i18n.t('keywords.enterStartFrame')}],
                            })(
                                <InputNumber
                                style={{width: "100%"}}
                                placeholder={i18n.t('keywords.startFrame')}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span = {6}>
                        <Form.Item>
                            {getFieldDecorator('stop', {
                                rules: [{ required: true, message: i18n.t('messages.enterStopFrame')}],
                            })(
                                <InputNumber
                                style={{width: "100%"}}
                                placeholder={i18n.t('keywords.stopFrame')}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span = {3}>
                        <Form.Item>
                            <Button
                                className="button-create-task"
                                type='primary'
                                htmlType='submit'
                            >{i18n.t('keywords.add')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }

    /**
     *
     * @returns Masking Form JSX Element
     */
    public render(): JSX.Element {

        return (
            <div>
                { this.renderMaskForm() }
            </div>
        );
    }
}

export default Form.create<Props>()(MaskConfigurationForm);
