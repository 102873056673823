import '../styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Menu, Dropdown, Button, Row, Col, Modal, Divider, Card, Descriptions, Progress, Statistic, DatePicker, Spin, Popconfirm, Icon, ConfigProvider, Table, Select, Checkbox, Upload} from 'antd';
import i18n from '../../i18n';
import { AuthState } from '../../reducers/interfaces';
import { authorizedAsync } from '../../actions/auth-actions';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import getCore from '../../cvat-core-wrapper';
import { Column, Line, Area } from '@ant-design/charts';
import Text from 'antd/lib/typography/Text';

import DashboardJobStatisticsPage from "./dashboard-job-statistics-modal";
import UserSelectorDashboard from './user-selector-dashboard';
import trTR from 'antd/es/locale/tr_TR';
import {
    TrophyIcon,
    MedalIcon,
    MedalFadedIcon,
    DoneIcon,
    ListIcon,
    LookUpIcon,
    DashboardScoreVector,
} from 'icons';
import './styles.scss';

const dateFormat = ['YYYY-MM-DD'];

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns  auth properties
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatching function
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type DashboardPageProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class_component DashboardPageComponent
 */
class DashboardPageComponent extends React.PureComponent<DashboardPageProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            totalTasks: 0,
            activeTasks: 0,
            annotatorCount:0,
            totalJobs: 0,
            activeJobs: 0,
            completedNumJobs: 0,
            annotationMap: [],
            taskMap: [],
            startDate: null,
            endDate: null,
            userId: null,
            // users:[],
            modalVisible:false,
            tableUser: "",
            tasks: [],
            tableTask: "",
            userScore: "",
            userLoaded: false,
            width: 1920,
            height: 1080,
            level: "",
            timeFilter: "month",
            paymentsGroupByUsers: [],
            paymentCheckboxStartDate: null,
            paymentCheckboxEndDate: null,
            receiptFile: [],
            paymentMinLimit: 50,
            paymentMaxLimit: 3000,
            levelScoreIntervals: new Array(),
            exportFormat: "",
            isDashboardPopVisible: false,
            isPaymentPopVisible: false

        }

        this.getDashboardStats = this.getDashboardStats.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.getSelectedUser = this.getSelectedUser.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.setTimeFilter = this.setTimeFilter.bind(this);
        this.getPaymentStats = this.getPaymentStats.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.deletePayment = this.deletePayment.bind(this);
        this.getLevelScoreIntervals = this.getLevelScoreIntervals.bind(this);
    }

    /**
     *
     * @param type
     * @returns Menu template for dashboard and payment sections in dashboard page
     * @description Renders csv, xlsx, docx and pdf options
     */
    getMenu(type: any){
        if(type == "dashboard"){
            return (
                <Menu>
                    <Menu.Item
                        key="csv"
                        onClick={() => {
                            this.setState({ isDashboardPopVisible: true });
                            this.setState({ exportFormat: "csv" });
                        }}
                    >
                        csv
                    </Menu.Item>
                    <Menu.Item
                        key="xlsx"
                        onClick={() => {
                            this.setState({ isDashboardPopVisible: true });
                            this.setState({ exportFormat: "xlsx" });
                        }}
                    >
                        xlsx
                    </Menu.Item>
                    <Menu.Item
                        key="docx"
                        onClick={() => {
                            this.setState({ isDashboardPopVisible: true });
                            this.setState({ exportFormat: "docx" });
                        }}
                    >
                        docx
                    </Menu.Item>
                    <Menu.Item
                        key="pdf"
                        onClick={() => {
                            this.setState({ isDashboardPopVisible: true });
                            this.setState({ exportFormat: "pdf" });
                        }}
                    >
                        pdf
                    </Menu.Item>
                </Menu>
            );
        } else { //type=="payment"
            return (
                <Menu>
                    <Menu.Item
                        key="csv"
                        onClick={() => {
                            this.setState({ isPaymentPopVisible: true });
                            this.setState({ exportFormat: "csv" });
                        }}
                    >
                        csv
                    </Menu.Item>
                    <Menu.Item
                        key="xlsx"
                        onClick={() => {
                            this.setState({ isPaymentPopVisible: true });
                            this.setState({ exportFormat: "xlsx" });
                        }}
                    >
                        xlsx
                    </Menu.Item>
                    <Menu.Item
                        key="docx"
                        onClick={() => {
                            this.setState({ isPaymentPopVisible: true });
                            this.setState({ exportFormat: "docx" });
                        }}
                    >
                        docx
                    </Menu.Item>
                    <Menu.Item
                        key="pdf"
                        onClick={() => {
                            this.setState({ isPaymentPopVisible: true });
                            this.setState({ exportFormat: "pdf" });
                        }}
                    >
                        pdf
                    </Menu.Item>
                </Menu>
            );
        }
    }

    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    private getLevelScoreIntervals() {
        let promise = cvat.management.getLevelScoreIntervals();
        promise.then(result => {
            this.setState({ levelScoreIntervals: result})
        })
    }

    public componentDidMount(): void {
        this.getDashboardStats();
        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasPaymentPermission = permissions.filter(object => object.permission_id__name == 'view_payments').length > 0;
        this.getPaymentStats()
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.getPaymentLimitStatus()
        this.getLevelScoreIntervals()
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     *
     * @param record
     * @returns Returns Upload template for pdf file
     */
    renderUploadPDFField(record: any) {
        return (
            <Upload multiple={false}
                accept="application/pdf"
                listType='text'
                fileList={record["receiptFile"]}
                beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                    if(newLocalFiles[0].type!="application/pdf"){
                        Modal.error({
                            title: 'Hata!',
                            content: 'Sadece PDF dosyaları yükleyebilirsiniz!',
                        });
                        return false
                    }
                    record["receiptFile"]= newLocalFiles
                    this.setState({
                        receiptFile: newLocalFiles
                    });
                    return false;
                }}
                onRemove={(): boolean => {
                    this.setState({
                        receiptFile: []
                    });
                    return false;
                }}
            >
                <Button type="default" className="button-default-dashboard-page">
                    <Icon type="upload" /> Dekont Yükle
                </Button>
            </Upload>
        )
    }

    /**
     *
     * @returns Payment card template for dashboard payment section
     */
    renderPaymentsCard(){
        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasPaymentPermission = permissions.filter(object => object.permission_id__name == 'view_payments').length > 0
        let spanParameter = 24
        return(
            <Col data-tour="step-3" span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329"}} title='Ödeme Listesi'
                    extra={(this.props.auth.user.groups.includes("admin") || hasPaymentPermission)
                    &&
                        <Popconfirm
                            title="Veriyi indirmek istediğinize emin misiniz?"
                            cancelText={i18n.t("keywords.no")}
                            okText={i18n.t("keywords.yes")}
                            visible={this.state.isPaymentPopVisible}
                            onConfirm={() => {
                                this.setState({
                                    isPaymentPopVisible:false
                                });
                                this.downloadPaymentsData();
                            } }
                            onCancel={()=>{this.setState({isPaymentPopVisible:false})}}
                        >
                            <Dropdown overlay={this.getMenu("payment")} placement="bottomCenter" >
                                <Button>
                                    <Icon type="file-excel" />
                                    {i18n.t('dashboard-page.download')}
                                </Button>
                            </Dropdown>
                        </Popconfirm>
                    }
                >
                    <Table
                        dataSource={this.state.paymentsGroupByUsers}
                        size="small"
                        scroll={{ y: 500 }}
                        expandedRowRender={(record) => <>{this.handleExpand(record)} </>}
                    >
                         {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column title='Kullanıcı Adı' dataIndex="user_id__username" key="user_id__username"  /> }
                        <Column title='Doğrulanmış Etiket/Toplam Etiket' key="annotation_count__sum" render={(record)=>
                             <h1>{record.evaluated_annotation_count__sum}/{record.annotation_count__sum}</h1>
                        }/>
                        <Column title='Bekleyen Ödeme Miktarı' key="payment_amount__sum" render={(record)=>
                             <h1>{record.payment_amount__sum.toFixed(2)} TL</h1>
                        }/>
                        <Column title='Yapılan Ödeme Miktarı' key="payment_amount__done" render={(record)=>
                             <h1>{record.payment_amount__done.toFixed(2)} TL</h1>
                        }/>
                        {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column title='Ödeme Durumu' render={(record)=>{
                            return(
                                (record.payment_amount__sum != 0) ?
                                (record.payment_amount__sum >= this.state.paymentMinLimit) ?
                                <div>
                                    {(this.state.receiptFile.length == 0) ?
                                    <Popconfirm
                                        title="Dekont yüklemeden ödeme yapmak istediğinize emin misiniz?"
                                        cancelText={i18n.t('keywords.no')}
                                        okText={i18n.t('keywords.yes')}
                                        onConfirm={
                                            (e) => {
                                                this.makePayment(record.user_id__username)
                                            }
                                        }
                                    >
                                        <Checkbox checked={record.payment_amount__sum == 0}>Ödendi</Checkbox>
                                    </Popconfirm>
                                    : <Checkbox checked={record.payment_amount__sum == 0} onChange={(e)=>this.makePayment(record.user_id__username)}>Ödendi</Checkbox> }
                                    {this.renderUploadPDFField(record)}
                                </div>
                                : <h1>Bekleyen aylık ödeme tutarı {this.state.paymentMinLimit} TL ve üzerinde olmalı.</h1> :
                                <h1>Bekleyen ödeme yok.</h1>
                            )}}/> }
                    </Table>

                </Card>
            </Col>
        )
    }

    private getPaymentLimitStatus() {
        let promise = cvat.management.getPaymentLimitStatus();
        promise.then(status => {
            this.setState({ paymentMinLimit: status[0].minimum, paymentMaxLimit: status[0].maximum })
        })
    }

    /**
     * @description Get dashboard data, sets state for rendering
     */
    private getDashboardStats(){
        let promise = cvat.server.getDashboardData(this.state.startDate, this.state.endDate, this.state.userId, this.state.timeFilter);
        promise.then(
            stats => {
                this.setState({
                    totalTasks: stats.totalNumTasks,
                    activeTasks: stats.activeNumTasks,
                    annotatorCount: stats.activeNumJobUsers,
                    totalJobs: stats.totalNumJobs,
                    activeJobs: stats.activeNumJobs,
                    completedNumJobs: stats.completedNumJobs,
                    annotationMap: stats.annotationMap,
                    taskMap: stats.taskMap,
                    tasks: stats.tasks,
                    userScore: stats.userScore,
                    userLoaded: this.state.userId,
                    level: stats.level
                })
            }
        )
    }

    /**
     * @description Get payment information, sets state for rendering
     */
    private getPaymentStats(){
        let promise = cvat.server.getPaymentsUsers(this.state.startDate, this.state.endDate);
        promise.then(
            stats => {
                this.setState({
                    paymentsGroupByUsers: stats.payments,
                    paymentCheckboxStartDate: stats.start_date,
                    paymentCheckboxEndDate: stats.end_date,
                    receiptFile: []
                })
            }
        )
    }

    /**
     * @async
     * @description download dashboard data with error handling
     */
    async downloadDashboardData() {
        let response;
        let loading = true;
        Modal.info({
            title: "Veri indiriliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });

        if(this.state.exportFormat == ""){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri formatı algılanamadı!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        } else {
            response = await cvat.server.downloadDashboardData(this.state.startDate, this.state.endDate, this.state.userId, this.state.exportFormat);
        }

        if (response.status === 200) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
        this.setState({exportFormat: ""});
    }

    /**
     * @async
     * @description download payments data with error handling
     */
    async downloadPaymentsData() {

        let response;
        let loading = true;

        Modal.info({
            title: "Veri indiriliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });

        if(this.state.exportFormat == ""){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri formatı algılanamadı!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        } else {
            response = await cvat.server.downloadPaymentsData(this.state.startDate, this.state.endDate, this.state.exportFormat);
        }

        if (response.status === 200) {
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }

        this.setState({exportFormat: ""});
    }

    /**
     *
     * @returns task card template
     */
    renderTasksCard(){
        let spanParameter = 7
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        return(
            <Col span={spanParameter}>
                <Card style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329", borderLeftWidth:"15px", borderLeftColor:"#EC868D"}} >
                    <Row type="flex" align="middle" justify="space-between">
                        <Col span={18}>
                            <span style={{color:"#0F123F",fontWeight:"600"}}>{i18n.t('dashboard-page.tasks')}</span>
                            <Statistic value =  {this.state.activeTasks} />
                        </Col>
                        <Col span={4}>
                            <Icon className='cvat-menu-icon' component={ListIcon} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    /**
     *
     * @returns jobs card template
     */
    renderJobsCard(){
        let spanParameter = 7
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        return(
            <Col span={spanParameter}>
                <Card style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329", borderLeftWidth:"15px", borderLeftColor:"#86DFEC"}}>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col span={18}>
                            <span style={{color:"#0F123F",fontWeight:"600"}}>{i18n.t('dashboard-page.jobs')}</span>
                            <Statistic value = {this.state.activeJobs+"/"+this.state.totalJobs} />
                        </Col>
                        <Col span={4}>
                            <Icon className='cvat-menu-icon' component={DoneIcon} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    /**
     *
     * @returns annotation statistics template
     */
    renderNumAnnotationsCard(){
        let spanParameter = 7
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        let annotationCount = 0;
        for(var i = 0; i < this.state.annotationMap.length; i++){
            annotationCount += this.state.annotationMap[i].count
        }
        return(
            <Col span={spanParameter}>
                <Card style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329", borderLeftWidth:"15px", borderLeftColor:"#CD86EC"}}>
                    <Row type="flex" align="middle" justify="space-between">
                        <Col span={18}>
                            <span style={{color:"#0F123F",fontWeight:"600"}}>Etiketler (Tamamlanan)</span>
                            <Statistic value = {annotationCount} />
                        </Col>
                        <Col span={4}>
                            <Icon className='cvat-menu-icon' component={LookUpIcon} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    /**
     * @deprecated this is not used anymore
     * @returns annotation card template
     */
    renderAnnotationsCard(){
        let spanParameter = 9
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        var config = {
            data: this.state.annotationMap,
            xField: 'month',
            yField: 'count',
            meta: {
              month: { alias: 'ay' },
              count: { alias: i18n.t("dashboard-page.annotationCount") },
            },
        };
        return(
            <Col span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329"}} title={i18n.t('dashboard-page.annotations')}
                extra={(this.props.auth.user.groups.includes("admin")|| this.props.auth.user.groups.includes("provider") || this.props.auth.user.groups.includes("system_admin"))
                &&


                        <Button type="default" className="button-default-dashboard-page">
                            <Icon type="file-excel"/>
                                {i18n.t('dashboard-page.download')}
                            </Button>

                } >
                    <Column {...config}/>
                </Card>
            </Col>
        )
    }

    /**
     *
     * @returns Dashboard Data Section template with export and error handling
     */
    renderAnnotationsChart(){
        let spanParameter = 12
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        var config = {
            data: this.state.annotationMap,
            xField: 'month',
            yField: 'count',
            meta: {
              month: { alias: 'ay' },
              count: { alias: i18n.t("dashboard-page.annotationCount") },
            },
            areaStyle: function areaStyle() {
                return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' };
            },
        };
        return (
            <Col data-tour="step-6" span={spanParameter}>
                <Card
                    headStyle={{
                        background: "#F2F4F8",
                        color: "#0F123F",
                        fontWeight: "600",
                    }}
                    style={{
                        borderRadius: "15px",
                        boxShadow: "0px 3px 20px #B3B3B329",
                    }}
                    title="Tamamlanan Etiketler"
                    extra={
                        (this.props.auth.user.groups.includes("admin") ||
                            this.props.auth.user.groups.includes("provider") ||
                            this.props.auth.user.groups.includes(
                                "system_admin"
                            )) && (
                            <Popconfirm
                                title="Veriyi indirmek istediğinize emin misiniz?"
                                cancelText={i18n.t("keywords.no")}
                                okText={i18n.t("keywords.yes")}
                                visible={this.state.isDashboardPopVisible}
                                onConfirm={() => {
                                    this.setState({
                                        isDashboardPopVisible: false,
                                    });
                                    this.downloadDashboardData();
                                }}
                                onCancel={() => {
                                    this.setState({
                                        isDashboardPopVisible: false,
                                    });
                                }}
                            >
                                <Dropdown
                                    overlay={this.getMenu("dashboard")}
                                    placement="bottomCenter"
                                >
                                    <Button>
                                        <Icon type="file-excel" />
                                        {i18n.t("dashboard-page.download")}
                                    </Button>
                                </Dropdown>
                            </Popconfirm>
                        )
                    }
                >
                    <Area {...config} />
                </Card>
            </Col>
        );
    }

    /**
     *
     * @returns Tasks template
     */
    renderTasksChart(){
        let spanParameter = 12
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        var config = {
            data: this.state.taskMap,
            xField: 'month',
            yField: 'count',
            meta: {
              month: { alias: 'ay' },
              count: { alias: 'İş Sayısı' },
            },
            areaStyle: function areaStyle() {
                return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff'};
            },
        };
        return(
            <Col data-tour="step-5" span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329"}} title="Tamamlanan İşler">
                    <Area {...config}/>
                </Card>
            </Col>
        )
    }

    public getStatus(state: String) {
        switch (state) {
            case null:
                return '1';
            case 'freshman':
                return '1';
            case 'sophomore':
                return '2';
            case 'junior':
                return '3';
            case 'senior':
                return '4';
            case 'master':
                return '5';
        }
    }

    /**
     *
     * @returns user score card template
     */
    renderUserScoreCard(){
        let spanParameter = 8
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        let intervals = this.state.levelScoreIntervals
        let levelProgressPercent = 0
        if(intervals.length > 0) {
            if(this.state.userScore < intervals[0].maxScore){
                levelProgressPercent = this.state.userScore / intervals[0].maxScore
            }else if(this.state.userScore < intervals[1].maxScore){
                levelProgressPercent = (this.state.userScore - intervals[0].maxScore)/ (intervals[1].maxScore-intervals[0].maxScore)
            }else if(this.state.userScore < intervals[2].maxScore){
                levelProgressPercent = (this.state.userScore - intervals[1].maxScore)/ (intervals[2].maxScore-intervals[1].maxScore)
            }else if(this.state.userScore < intervals[3].maxScore){
                levelProgressPercent = (this.state.userScore - intervals[2].maxScore)/ (intervals[3].maxScore-intervals[2].maxScore)
            }else{
                levelProgressPercent = 100
            }
        }

        let levelTitle = "Seviye: " + this.getStatus(this.state.level)
        return(
            <Col data-tour="step-4" span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329"}} title={i18n.t('dashboard-page.score')} >
                    <Statistic
                    value={this.state.userScore}
                    >
                    </Statistic>
                    <h3>{levelTitle}</h3>
                    <Progress percent={(levelProgressPercent*100).toFixed(2)} />
                    <text>Etiketleme yapın, seviye yükseltin.</text>
                    <br/>
                    <Row type="flex" justify="center">
                    <Icon component={DashboardScoreVector}></Icon>
                    </Row>
                   {/*  <br></br>
                    <Row align="middle">
                        <Icon className='cvat-menu-icon' component={MedalIcon} />
                        <Icon className='cvat-menu-icon' component={MedalFadedIcon} />
                        <Icon className='cvat-menu-icon' component={MedalFadedIcon} />
                        <Icon className='cvat-menu-icon' component={MedalFadedIcon} />
                        <Icon className='cvat-menu-icon' component={MedalFadedIcon} />
                        <Icon className='cvat-menu-icon' component={TrophyIcon} />
                    </Row>
                    <text>5 rozet biriktirdiğinizde bir kupa kazanırsınız.</text> */}
                </Card>
            </Col>
        )
    }

    /**
     *
     * @returns task list section template
     */
    renderTaskListCard(){
        let spanParameter = 16
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        return(
            <Col data-tour="step-3" span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} style={{borderRadius: "15px", boxShadow: "0px 3px 20px #B3B3B329"}} title={i18n.t('dashboard-page.taskList')}>
                    <Table
                        dataSource={this.state.tasks}
                        size="small"
                        scroll={{ y: 240 }}

                    >
                        <Column title={i18n.t("dashboard-page.taskNo")} dataIndex="id" key="id"  />
                        <Column title={i18n.t('dashboard-page.taskName')} dataIndex="taskname" key="taskname" />
                        <Column render={(record)=>
                            <Button  style={{color: "#5D5DF9"}} size="small" type="link" onClick={()=>{this.setState({modalVisible:true, tableTask:record.id})}} >Detaylar</Button>
                        }/>
                    </Table>

                </Card>
            </Col>
        )
    }

    /**
     * @async
     * @param username
     * @description make payment api proxy call
     */
    public async makePayment(username: any){
        await cvat.server.makePayment(this.state.paymentCheckboxStartDate, this.state.paymentCheckboxEndDate, username,  this.state.receiptFile);
        this.getPaymentStats()
    }

    /**
     * @async
     * @param paymentId
     * @description upload receipt api proxy call
     */
    public async upload_receipt(paymentId: any){
        await cvat.server.uploadReceipt(paymentId, this.state.receiptFile);
        this.getPaymentStats()
    }

    /**
     * @async
     * @param paymentId
     * @description download receipt api proxy call
     */
    public async download_receipt(paymentId: any){
        await cvat.server.downloadReceipt(paymentId);
    }

    /**
     * @async
     * @param paymentId
     * @description delete payment api proxy call
     */
    public async deletePayment(paymentId: any){
        let result = await cvat.server.deletePayment(paymentId);
        this.getPaymentStats()
        return result;
    }

    /**
     *
     * @param record
     * @returns Payment section template on expand, works per given expanded record
     */
    private handleExpand(record: any) {
        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasPaymentPermission = permissions.filter(object => object.permission_id__name == 'view_payments').length > 0
        return (
            <Row>
                <Col span={22}>
                    <Table
                            dataSource={record.jobs_waiting_payment}
                            size="small"
                            scroll={{ y: 240 }}
                            title={() => 'Ödeme Bekleyen İşler'}
                        >
                        <Column title="İş No" dataIndex="job_id" key="job_id"  />
                        <Column title='Doğrulanmış Etiket/Toplam Etiket' key="annotation_count" render={(record)=>
                             <h1>{record.evaluated_annotation_count}/{record.annotation_count}</h1>
                        }/>
                        <Column title="Hak Edilen Ödeme Miktarı" key="payment_amount" render={(record)=>
                             <h1>{record.payment_amount.toFixed(2)} TL</h1>
                        }/>
                        <Column title="Doğrulanma Tarihi" key="evaluated_at" render={(record)=>
                             <h1>{record.evaluated_at.substring(0,10)}</h1>
                        }/>
                        <Column title="Seviye" dataIndex="current_level" key="current_level" />
                        <Column title="Etiket Fiyatı" dataIndex="current_annotation_price" key="current_annotation_price" />
                    </Table>
                </Col>
                <Col span={22}>
                    <Table
                            dataSource={record.payments_done}
                            size="small"
                            scroll={{ y: 240 }}
                            title={() => 'Önceki Ödemeler'}
                        >
                        <Column title="Ödeme No" dataIndex="id" key="id" />
                        <Column title="Ödeme Miktarı" key="payment_amount" render={(record)=>
                             <h1>{record.payment_amount.toFixed(2)} TL</h1>
                        }/>
                        <Column title="Ödeme Tarihi" key="payment_date" render={(record)=>
                             <h1>{record.payment_date.substring(0,10)}</h1>
                        }/>
                       {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column title="Ödemeyi Yapan" dataIndex="payer__username" key="payer__username"/> }

                        {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column title="Dekont Yükle" key='receipt' render={(record)=>
                            this.renderUploadPDFField(record)
                        }/> }
                        {this.state.receiptFile.length != 0 && (this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                            <Column title="" key='receipt_save' render={(record)=>
                                <Button type="default" className="button-default-dashboard-page"
                                    onClick = {() => {this.upload_receipt(record.id)}}
                                >
                                    Kaydet
                                </Button>
                            }/>
                        }
                        {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column title="Dekont İndir" key='receipt' render={(record)=>
                            (record.receipt_file_name) &&
                            <Button type="default" className="button-default-dashboard-page"
                                onClick={() =>  this.download_receipt(record.id)}
                            >
                                İndir
                            </Button>
                        }/> }
                        {(this.props.auth.user.groups.includes("admin") || hasPaymentPermission) &&
                        <Column render={(record)=>
                            <Popconfirm
                                title="Ödemeyi kaldırmak istediğinizden emin misiniz?"
                                cancelText={i18n.t('keywords.no')}
                                okText={i18n.t('keywords.yes')}
                                onConfirm={
                                    (e) => {
                                        this.deletePayment(record.id)
                                    }
                                }
                            >
                                <Button size="small" className="button-default-create-task"> {i18n.t('keywords.delete')} </Button>
                            </Popconfirm>
                        }/> }
                    </Table>
                </Col>
            </Row>

        );
    }

    /**
     * @deprecated is not used anymore
     * @returns Annotator Count template
     */
    renderAnnotatorsCard(){
        let spanParameter = 8
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        return(
            <Col span={spanParameter}>
                <Card headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:"600"}} title={i18n.t('dashboard-page.activeAnnotators')}>
                    <Statistic
                    value={this.state.annotatorCount}
                    >
                    </Statistic>

                </Card>
            </Col>
        )
    }

    /**
     * @deprecated is not used anymore
     * @returns Completed Jobs template
     */
    renderCompletedJobsCard(){
        let spanParameter = 8
        if(this.state.width / this.state.height   < 1.7){
            spanParameter = 24
        }
        return(
            <Col span={spanParameter}>
                <Card title={i18n.t('dashboard-page.completedJobs')} >
                    <Statistic
                    value={this.state.completedNumJobs}
                    >
                    </Statistic>
                </Card>
            </Col>
        )
    }

    /**
     *
     * @returns DashboardJobStatisticsPage instance passing state props
     */
    renderModal(){
        return(
            <DashboardJobStatisticsPage
                visible={this.state.modalVisible}
                setInvisible={() => this.setState({ modalVisible: false })}
                taskId={this.state.tableTask}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                userId={this.state.userId}
            />
        )
    }

    public handleStartDateChange(date:any, datestring:any) {
        this.setState({ startDate: datestring})
    }

    public handleEndDateChange(date:any, datestring:any) {
        this.setState({ endDate: datestring})
    }

    public getSelectedUser(user: any) {
        this.setState({
            userId: user,
        });
    }

    public setTimeFilter(value: any) {
        this.setState({
            timeFilter: value,
        });
    }

    /**
     * @description Class render function
     * @returns Page in JSX using templates defined above
     */
    render() {
        let mobileView = false
        if(this.state.width / this.state.height   < 1.7){
            mobileView = true
        }
        let padding = "unset";
        if(this.props.auth.user.groups.includes("provider"))
            padding = "16px";

        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasPaymentPermission = permissions.filter(object => object.permission_id__name == 'view_payments').length > 0
        return (
            <div className="approval-page">
                <Text className='cvat-title'>{i18n.t('dashboard-page.dashboard')}</Text>
                <br></br>

                {!mobileView ?
                <Row data-tour="step-1" style={{paddingTop:"15px"}} gutter={8} justify="start" type="flex">

                <ConfigProvider locale ={trTR}>
                    <Col span={2.5}>
                    <DatePicker format = {dateFormat}
                        placeholder='Başlangıç Tarihi' onChange={this.handleStartDateChange}
                    />
                    </Col>
                    <Col span={2.5}>
                    <DatePicker format = {dateFormat}
                        placeholder='Bitiş Tarihi' onChange={this.handleEndDateChange}
                    />
                    </Col>
                    <Col span={2.5}>
                        <Select placeholder='Zaman Aralığı' style={{ width: 150, marginLeft: "3px"}} onChange={this.setTimeFilter}>
                            <Select.Option value="day">Günlük</Select.Option>
                            <Select.Option value="week">Haftalık</Select.Option>
                            <Select.Option value="month">Aylık</Select.Option>
                        </Select>
                    </Col>
                </ConfigProvider>
                {(this.props.auth.user.groups.includes("admin")|| this.props.auth.user.groups.includes("provider") || this.props.auth.user.groups.includes("system_admin"))
                &&  <Col span={2.5}>
                    <UserSelectorDashboard
                        user={this.props.auth.user}
                        parentMethod={this.getSelectedUser}
                    />
                    </Col>
                }


                <Col span={5}>
                <Button className="button-default-dashboard-page" onClick={() => {
                    this.getDashboardStats();
                    this.getPaymentStats()
                }} > {i18n.t('dashboard-page.filter')} </Button>
                </Col>
                </Row> :
                <div>

                <Row justify = 'center' type="flex" gutter={[0,0]}>
                    <Col span={24}>
                        <ConfigProvider locale ={trTR}>
                            <Col span={24}>
                            <DatePicker format = {dateFormat} style={{margin:'1%', float:'right'}}
                                placeholder='Başlangıç Tarihi' onChange={this.handleStartDateChange}
                            /></Col>
                            <Col span={24}>
                            <DatePicker format = {dateFormat} style={{margin:'1%', float:'right'}}
                                placeholder='Bitiş Tarihi' onChange={this.handleEndDateChange}
                            /></Col>
                            <Col span={24}>
                                <Select placeholder='Zaman Aralığı' style={{ width: 150, margin:'1%', float:'right'}} onChange={this.setTimeFilter}>
                                    <Select.Option value="day">Günlük</Select.Option>
                                    <Select.Option value="week">Haftalık</Select.Option>
                                    <Select.Option value="month">Aylık</Select.Option>
                                </Select>
                            </Col>
                        </ConfigProvider>
                        {(this.props.auth.user.groups.includes("admin")|| this.props.auth.user.groups.includes("provider") || this.props.auth.user.groups.includes("system_admin"))
                        &&
                        <Col span={24}>
                            <div style={{margin:'1%', float:'right'}} >
                            <UserSelectorDashboard
                                user={this.props.auth.user}
                                parentMethod={this.getSelectedUser}
                            />
                            </div>
                        </Col>
                        }
                        <Col span={24}>
                            <Button className="button-default-dashboard-page" onClick={() => {
                            this.getDashboardStats()
                            this.props.auth.user.groups.includes("admin")&&this.getPaymentStats()
                            }} style={{margin:'1%', float:'right'}}> {i18n.t('dashboard-page.filter')} </Button>
                        </Col>
                    </Col>
                </Row>
                </div> }

                <Row data-tour="step-2" style={{marginTop:"2px",marginLeft:"0",marginRight:"0",marginBottom:"-21px"}} justify="space-between" type="flex" gutter={[0,42]}>
                        {this.renderTasksCard()}
                        {this.renderJobsCard()}
                        {this.renderNumAnnotationsCard()}
                        {/* {(this.props.auth.user.groups.includes("admin")|| this.props.auth.user.groups.includes("provider") )&&this.renderAnnotatorsCard()}
                        {(this.props.auth.user.groups.includes("admin")|| this.props.auth.user.groups.includes("provider") )&&this.renderCompletedJobsCard()} */}
                        {/* {(this.props.auth.user.groups.includes("annotator"))&&<Button style={{marginLeft:"2%"}}onClick={()=>{this.setState({modalVisible:true, tableUser:this.props.auth.user.id})}}>Etiketleme Detaylarını Gör</Button>} */}

                {/* <Col>
                    {this.renderAnnotationsCard()}

                </Col> */}
                {this.renderModal()}
                </Row>
                <Row justify="space-between" type="flex" gutter={[42,42]} >
                <Col style={{justifyContent: "space-between", display: "flex", alignContent: "space-around", flexWrap: "wrap" , padding:padding}}>

                      {!(this.props.auth.user.groups.includes("provider"))&&this.renderTaskListCard()}
                        {!(this.props.auth.user.groups.includes("provider")) && ((this.props.auth.user.groups.includes("annotator")) || (this.props.auth.user.groups.includes("admin") && this.state.userId && this.state.userLoaded)) && this.renderUserScoreCard()}
                    </Col>
                </Row >
                <Row gutter={[32,32]}>
                    <Col style={{justifyContent: "space-between", display: "flex", alignContent: "space-around", flexWrap: "wrap", padding:"unset"}}>
                        {this.renderTasksChart()}
                        {this.renderAnnotationsChart()}
                    </Col>
                </Row>
                <Row gutter={[32,32]}>
                    <Col style={{justifyContent: "space-between", display: "flex", alignContent: "space-around", flexWrap: "wrap", padding:"unset"}}>
                        {this.state.paymentsGroupByUsers.length > 0 && this.renderPaymentsCard()}
                    </Col>
                </Row>

            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashboardPageComponent));
