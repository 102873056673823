// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { GlobalHotKeys, ExtendedKeyMapOptions, configure } from 'react-hotkeys';
import ShorcutsDialog from 'components/shortcuts-dialog/shortcuts-dialog';
import { shortcutsActions } from 'actions/shortcuts-actions';
import { connect } from 'react-redux';
import Layout from 'antd/lib/layout';
import Spin from 'antd/lib/spin';
import Result from 'antd/lib/result';
import { useTranslation } from 'react-i18next';
import {
    CombinedState,
} from '../../reducers/interfaces';
import { Workspace } from 'reducers/interfaces';
import AnnotationTopBarContainer from 'containers/annotation-page/top-bar/top-bar';
import StatisticsModalContainer from 'containers/annotation-page/top-bar/statistics-modal';
import StandardWorkspaceComponent from './standard-workspace/standard-workspace';
import AttributeAnnotationWorkspace from './attribute-annotation-workspace/attribute-annotation-workspace';
import { Modal} from 'antd';
import getCore from 'cvat-core-wrapper';
const core = getCore();

import {
    collectStatisticsAsync,
    showStatistics as showStatisticsAction,
} from 'actions/annotation-actions';

interface Props {
    job: any | null | undefined;
    fetching: boolean;
    getJob(): void;
    saveLogs(): void;
    workspace: Workspace;
}
interface StateToProps{
    keyMap: Record<string, ExtendedKeyMapOptions>;

}
interface DispatchToProps {
    switchShortcutsDialog: () => void;
    showStatistics(sessionInstance: any): void;
}

/**
 * Convert combinedState to StateToProps
 *
 * @param state CombinedState
 * @returns keyMap as object
 */
function mapStateToProps(state: CombinedState): StateToProps {
    const { shortcuts } = state;

    return {
        keyMap: shortcuts.keyMap,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        switchShortcutsDialog: (): void => dispatch(shortcutsActions.switchShortcutsDialog()),

        showStatistics(sessionInstance: any): void {
            dispatch(collectStatisticsAsync(sessionInstance));
            dispatch(showStatisticsAction(true));
        },
    };
}

/**
 * Check mobile usage
 *
 * @returns true if mobile is used
 */
function detecMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true
    } else {
        return false
    }
}

/**
 * Create annotation page component
 * @param props
 * @returns Layout
 */
function AnnotationPageComponent(props: Props & DispatchToProps & StateToProps): JSX.Element {
    const {
        job,
        fetching,
        getJob,
        saveLogs,
        workspace,
        switchShortcutsDialog,
        keyMap
    } = props;
    const { t } = useTranslation();

    useEffect(() => {
        saveLogs();
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '100%';
        }
        if(detecMobile()){
            Modal.info({
                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                okText: 'Tamam',
            });
            setTimeout(function(){ window.location.assign('/dashboard') }, 100);
        }

        return () => {
            saveLogs();
            if (root) {
                root.style.minHeight = '';
            }
        };
    }, []);

    if (job === null) {
        if (!fetching) {
            getJob();
        }

        return <Spin size='large' className='cvat-spinner' />;
    }

    if (typeof (job) === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title={t('standard-workspace.jobNotFoundError')}
                subTitle={t('standard-workspace.jobNotFoundTip')}
            />
        );
    }

/*     const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
            OPEN_SETTINGS: keyMap.OPEN_SETTINGS,
        }; */

    function showStatistics () {
        props.showStatistics(props.job)
    };

    function showShortcuts () {
        props.switchShortcutsDialog()
    };

    async function downloadTaskGuidelines() {
        let response;
        let loading = true;
        Modal.info({
            title: "Veri indiriliyor...",
            visible: loading,
            content: <Spin size='large' className='vk-spinner'/>,
            okButtonProps: { style: { display: 'none' } },
        });
        response = await core.server.downloadTaskGuidelines(props.job.task.id);

        if(response){
            Modal.info({
                title: t('response-messages.info'),
                content: 'Veri başarıyla indirildi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        } else {
            Modal.error({
                title: t('keywords.error'),
                content: 'Veri indirilemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    return (
        <Layout className='cvat-annotation-page'>
            <Layout.Header className='cvat-annotation-header'>
                <AnnotationTopBarContainer pcTask={ job.task.tasksIdAnnota[0].pointCloudFileName?true:false} textTask={ Number(job.task.tasksIdAnnota[0].doccanoProjectId) != 0 } />
            </Layout.Header>
            { workspace === Workspace.STANDARD ? (
                <Layout.Content>
                    <ShorcutsDialog />
                    <GlobalHotKeys >
                        <StandardWorkspaceComponent job={job}
                            showStatistics={showStatistics}
                            showShortcuts={showShortcuts}
                            downloadTaskGuidelines={downloadTaskGuidelines}
                            hasGuidelinesFile = {job.task.hasGuidelinesFile}/>
                    </GlobalHotKeys>
                </Layout.Content>
            ) : (
                <Layout.Content>
                    <ShorcutsDialog />
                    <GlobalHotKeys >
                        <AttributeAnnotationWorkspace />
                    </GlobalHotKeys>
                </Layout.Content>
            )}
            <StatisticsModalContainer />
        </Layout>
    );
}
export default connect(mapStateToProps,
    mapDispatchToProps,)(AnnotationPageComponent);
