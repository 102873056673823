const datasetCreateSteps = [
    {
        selector: '[data-tour="step-dataset-name"]',
        content: "Veri Kümesi ismi girebilirsiniz. ",
        action: node => {
            node.focus();
          },
    },
    {
        selector: '[data-tour="step-specs"]',
        content: "Veri kümesine ait ilgili özellikleri buradaki bilgileri doldurarak girebilirsiniz. ",


    },
    {
        selector: '[data-tour="step-metadata"]',
        content: "Yukarıdaki bilgiler dışında girmek istediğiniz özellikler varsa anahtar-değer çifti şeklinde girebilirsiniz. Eklediğiniz çiftleri burada görüntüleyebilir, ekleme/çıkarma yapabilirsiniz. ",


    },
    {
        selector: '[data-tour="step-key"]',
        content: "Anahtarı buraya girebilirsiniz. "
    },
    {
        selector: '[data-tour="step-value"]',
        content: "Değeri buraya girebilirsiniz. "
    },
    {
        selector: '[data-tour="step-add-pair"]',
        content: "Ekleye basarak çifti ekleyebilirsiniz. "
    },
    {
        selector: '[data-tour="step-file-manager"]',
        content: "Buradan veri kümesini oluşturacak verileri yükleyebilirsiniz. "
    },
    {
        selector: '[data-tour="step-upload"]',
        content: "Buraya basarak veri kümesini oluşturabilirsiniz. "
    },
  ];

  export default datasetCreateSteps;
