import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Tabs, Progress, Spin } from 'antd';
import 'antd/dist/antd.css';
import getCore from '../cvat-core-wrapper';

import i18n from '../i18n';
import Column from 'antd/lib/table/Column';
import { string, element } from 'prop-types';
import { CVATLogo } from 'icons';
import { resolve } from 'url';
const { Panel } = Collapse;
const { TabPane } = Tabs;
const cvat = getCore();
let backendEndPoint = String(cvat.config.backendAPI)
const { Option } = Select;

class UserJobsStatisticsPage extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            userSuccessfulJobs: [],
            userUnsuccessfulJobs: [],
            userWaitingEvaluationJobs: [],
            filter: "current",
            total_annotation: 0
        }
        this.callback(this.props.user.id,this);
    }

    private getLevel(state: String) {
        switch (state) {
            case null:
                return '0';
            case 'freshman':
                return '1';
            case 'sophomore':
                return '2';
            case 'junior':
                return '3';
            case 'senior':
                return '4';
            case 'master':
                return '5';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    private getStatus(state: String) {
        switch (state) {
            case null:
                return 'Uzman Değil';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    private determineStatusPercentage(status: string, jobs?: any[]) {
        if (jobs) {
            let finishedJobCount = 0;
            for (let i = 0; i < jobs[0].length; i++) {
                if (jobs[0][i].status === "completed")
                    finishedJobCount++;
            }
            return Math.round(finishedJobCount * 100 / jobs[0].length);
        }
        if (status === "annotation")
            return 0;
        else if (status === "validation")
            return 50;
        else
            return 100;
    }

    /**
     * @async
     * @param key
     * @param self
     * @description get user jobs statistics api proxy call, define job table template
     */
    public async callback(key: any, self: any) {
        if(key){
            let response = await cvat.jobs.getUserJobsStatistics(key, this.state.filter);

            let tableTypes = ["successful", "unsuccessful", "waitingevaluation"]
            let jobs = response.jobs
            let totalAnnotation = response.total_annotation

            tableTypes.forEach(element => {

                let title = ""
                let lowerBound = 0
                let upperBound = 0;
                if(element == 'successful'){
                    title = i18n.t('statistics-page.successfulJobs')
                    lowerBound = 1,
                    upperBound = 2
                }else if(element == 'unsuccessful'){
                    title = i18n.t('statistics-page.unsuccessfulJobs')
                    lowerBound = 0.5,
                    upperBound = 1
                }else{
                    title = i18n.t('statistics-page.waitingEvaluationJobs')
                    lowerBound = 0,
                    upperBound = 0.5
                }

                let jobTable =(
                    <div>
                    <Table style={{ backgroundColor: "#f0f2f5", borderRadius: "8px", marginBottom : '2%' }}
                        title={() => title}
                        rowKey={record => record.id}
                        size="small"
                        dataSource={jobs.filter(object => object.score >= lowerBound && object.score < upperBound)}
                        //dataSource={jobs}
                        pagination={{ defaultPageSize: 5, showSizeChanger: true, position: 'bottom' , pageSizeOptions: ['5', '10', '20']}}
                    >
                        <Column align='center'  title={i18n.t('annotation-eval-page.jobID')} dataIndex="id" />

                        (
                            {
                            <Column align='center' title={i18n.t('annotation-eval-page.taskID')} dataIndex="task_id" />
                            }
                        )

                        <Column align='center' title={i18n.t('annotation-eval-page.preview')} render={(record) =>
                            <img src={backendEndPoint + '/tasks/' + record.task_id + '/data?type=preview'} style={{ width: "15em", height: "10em" }} />
                        }
                        />
                        <Column align='center' title={i18n.t('annotation-eval-page.annotationScore')} render={(record) => <>
                            {(record.score === 0 ? "-" : record.score)}</>
                        } />

                        {/* <Column title={i18n.t('keywords.status')} render={(record) =>
                            <Progress type="circle" percent={self.determineStatusPercentage(record.status)} />
                        } /> */}

                        <Column align='center'  title={i18n.t('statistics-page.annotation')} render={(record) =>
                            <>{(record.shapeCount + record.tagCount === 0 ? "-" : record.shapeCount + record.tagCount)}</>
                        } />

                        {/* <Column title={i18n.t('statistics-page.tag')} render={(record) =>
                            <>{(record.tagCount === 0 ? "-" : record.tagCount)}</>
                        } /> */}

                        <Column align='center'  title={i18n.t('statistics-page.track')} render={(record) =>
                            <>{(record.interpolationCount + record.trackerUsedObjectsCount === 0 ? "-" : record.interpolationCount + record.trackerUsedObjectsCount)}</>
                        } />

                        {/* <Column title={i18n.t('statistics-page.autoTrack')} render={(record) =>
                            // <>{(record.trackerUsedObjectsCount === 0 ? "-" : record.trackerUsedObjectsCount)}</>
                        } /> */}

                        <Column align='center'  title={i18n.t('statistics-page.totalAnnotation')} render={(record) =>
                            <>{(record.total === 0 ? "-" : record.total)}</>
                        } />

                        <Column align='center'  title={i18n.t('statistics-page.completedTime')} render={(record) =>
                            <>{record.completed_at}</>
                        } />

                    </Table>
                </div>
                )

                if(element == 'successful'){
                    self.setState({
                        userSuccessfulJobs: jobTable,
                        total_annotation: totalAnnotation
                    })
                }else if(element == 'unsuccessful'){
                    self.setState({
                        userUnsuccessfulJobs: jobTable,
                    })
                }else{
                    self.setState({
                        userWaitingEvaluationJobs: jobTable,
                    })
                }

            });
        }
    }

    /**
     *
     * @returns use jobs tab JSX template
     */
    public renderUserJobs(){
        return (
        <>
            <Tabs animated={false} size="large" defaultActiveKey="0" >
                <TabPane tab={i18n.t('statistics-page.successfulJobs')} key="0">
                    {this.state.userSuccessfulJobs}
                </TabPane>
                <TabPane tab={i18n.t('statistics-page.unsuccessfulJobs')} key="1">
                    {this.state.userUnsuccessfulJobs}
                </TabPane>
                <TabPane tab={i18n.t('statistics-page.waitingEvaluationJobs')} key="2">
                    {this.state.userWaitingEvaluationJobs}
                </TabPane>
            </Tabs>
        </>
        )
    }

    public async setFilter(takenFilter: any) {
        this.setState({
            filter: takenFilter,
        }, () => {
            this.callback(this.props.user.id,this)
        });
    }

    /**
     *
     * @returns User Job Statistics JSX Template
     */
    public render() {
        return (
            <div className='approval-page'>
                <div style={{ marginLeft: '0%', marginRight: '5%' }}>
                    <Select style={{ width: '10%', marginBottom: '1.5%' }} defaultValue="current" onChange={(value) => this.setFilter(value)}>
                        <Option value="current">{i18n.t('statistics-page.thisMonth')}</Option>
                        <Option value="last">{i18n.t('statistics-page.prevMonth')}</Option>
                    </Select>
                    <h1>{i18n.t('statistics-page.totalSuccessfulAnnotation')}: {this.state.total_annotation}</h1>
                    {this.renderUserJobs()}
                </div>
            </div>
        )
    }
}

export default withRouter(connect(
)(UserJobsStatisticsPage));
