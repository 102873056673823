// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import 'antd/dist/antd.css';
import '../styles.scss';
import './styles.scss';
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { GlobalHotKeys, ExtendedKeyMapOptions, configure } from 'react-hotkeys';
import Spin from 'antd/lib/spin';
import Layout from 'antd/lib/layout';
import notification from 'antd/lib/notification';
import i18n from '../i18n'

import GlobalErrorBoundary from 'components/global-error-boundary/global-error-boundary';
import ShorcutsDialog from 'components/shortcuts-dialog/shortcuts-dialog';
import SettingsPageContainer from 'containers/settings-page/settings-page';
import TasksPageContainer from 'containers/tasks-page/tasks-page';
import CreateTaskPageContainer from 'containers/create-task-page/create-task-page';
import CreateDatasetPageContainer from 'containers/create-dataset-page/create-dataset-page';
import ModifyDatasetPageContainer from 'containers/modify-dataset-page/modify-dataset-page';
import TaskPageContainer from 'containers/task-page/task-page';
import ModelsPageContainer from 'containers/models-page/models-page';
import CreateModelPageContainer from 'containers/create-model-page/create-model-page';
import AnnotationPageContainer from 'containers/annotation-page/annotation-page';
import LoginPageContainer from 'containers/login-page/login-page';
import RegisterPageContainer from 'containers/annota-register-page/register-page';
import SpecialAnnotatorRegisterPageContainer from 'containers/annota-register-page-special-annotator/register-page'
import HeaderContainer from 'containers/header/header';
import SidebarContainer from 'containers/header/side-bar';
import RolesPage from "containers/roles-page";
import TestTrainingPage from 'containers/test-training-page';
import AnnotationEvaluationPage from 'containers/annotation-evaluation-page';
import TestEvaluationPage from 'containers/test-evaluation-page';
import ProviderGroupPage from 'containers/provider-group-page';
import AdminPage from 'containers/admin-page';
import RejectedPage from 'containers/rejected-page';
import TaskApprovalPage from 'containers/task-approval-page'
import WelcomePageHTML from 'containers/welcome-page-html';
import RedirectComponent from 'containers/redirect';
import ContactUsComponent from 'components/contact-us-pages/contact-us-page'
import RegisterHelpComponent from 'components/contact-us-pages/register-help-page'
import DatasetsPage from '../containers/datasets-page';
import VSPDatasetsPage from '../containers/vsp-datasets-page';
import DatasetsPageDDO from '../containers/datasets-page-ddo';
import ResetPageContainer from 'containers/reset-page/reset-page';
import ResetConfirmPageContainer from 'containers/reset-confirm-page/reset-confirm-page';
import ProfilePageComponent from 'containers/profile-page';
import PaymentsPageComponent from 'containers/payments-page';
import ChangePassword from 'containers/change-password';
import { customWaViewHit } from 'utils/enviroment';
import MessagesPageComponent from 'containers/messages-page/messages-page';
import DashboardPageComponent from 'containers/dashboard-page/dashboard-page';
import SystemResourcesComponent from 'containers/system-resources-page';
import PlatformManComponent from 'containers/platform-man-page/platform-man-page';
import LogsPageComponent from 'containers/logs-page';

import getCore from 'cvat-core-wrapper';
const cvat = getCore()
import { NotificationsState } from 'reducers/interfaces';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Input, Icon } from 'antd';
import { TestTrainingIcon, VKSpin } from 'icons';
import { useTour } from "@reactour/tour";
import steps from "../steps";
import datasetCreateSteps from "components/create-dataset-page/steps.js";
import tasksPageAnnotatorSteps from 'containers/tasks-page/steps-annotator.js';
import tasksPageProviderSteps from 'containers/tasks-page/steps-provider.js';
import tasksPageExpertSteps from 'containers/tasks-page/steps-expert.js';
import tasksPageExpertProviderSteps from 'containers/tasks-page/steps-expert-provider.js';
import dashboardPageStepsAnnotator from 'containers/dashboard-page/steps-annotator.js';
import dashboardPageStepsProvider from 'containers/dashboard-page/steps-provider.js';
import testTrainingPageSteps from "containers/test-training-page-steps.js";
import providerGroupSteps from "containers/provider-group-page-steps.js";
import datasetsSteps from "containers/datasets-steps.js"
import messageSteps from "containers/messages-page/steps.js";
import taskDetailsAnnotatorSteps from "components/task-page/steps-annotator.js";
import taskDetailsProviderSteps from "components/task-page/steps-provider.js"
import createTaskSteps from "containers/create-task-page/steps.js";
import annotationPageSteps from "containers/annotation-page/steps.js";
import profilePageSteps from "containers/profile-page-steps.js";
import YzpLogin from './yzp-login/yzp-login';


/**
 * @description Some function and variable props of the CVATApp as interface
 */
interface CVATAppProps {
    loadFormats: () => void;
    loadUsers: () => void;
    loadAbout: () => void;
    verifyAuthorized: () => void;
    logoutUser: () => void;
    loadUserAgreements: () => void;
    initPlugins: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    keyMap: Record<string, ExtendedKeyMapOptions>;
    userInitialized: boolean;
    userFetching: boolean;
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    usersInitialized: boolean;
    usersFetching: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    installedAutoAnnotation: boolean;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    userAgreementsFetching: boolean,
    userAgreementsInitialized: boolean,
    notifications: NotificationsState;
    user: any;
    annotaUser: any;
    config: any;
}

/**
 * 
 * @param props CVATAppProps and RouteComponentProps from react-router-dom
 * @returns CVATApplicationClass with modified props
 * @description For the pathname of location, set react tour accordingly
 */
function CVATApplication(props: CVATAppProps & RouteComponentProps) {

    let location = useLocation();
    const { setSteps,setIsOpen, setCurrentStep } = useTour();
    useEffect(() => {
        if (props.user){
            if(!props.user.groups.includes("admin")){
                setCurrentStep(0);
                if (location.pathname === "/tasks") {
                    if(props.user.groups.includes("provider") && props.annotaUser[0].expert === "expert"){
                        setSteps(tasksPageExpertProviderSteps);
                    }else if(props.user.groups.includes("provider")){
                        setSteps(tasksPageProviderSteps);
                    }else if(props.annotaUser[0].expert === "expert"){
                        setSteps(tasksPageExpertSteps);
                    }
                    else{
                        setSteps(tasksPageAnnotatorSteps);
                    }
                } else if (location.pathname === "/dashboard") {
                    if(props.user.groups.includes("provider")){
                        setSteps(dashboardPageStepsProvider);
                    }else{
                        setSteps(dashboardPageStepsAnnotator);
                    }
                } else if(location.pathname === "/testtraining"){
                    setSteps(testTrainingPageSteps);
                } else if(location.pathname === "/providergroup"){
                    setSteps(providerGroupSteps)
                } else if(location.pathname === "/datasets/create"){
                    setSteps(datasetCreateSteps)
                } else if(location.pathname === "/datasets"){
                    setSteps(datasetsSteps)
                } else if(location.pathname === "/messages"){
                    setSteps(messageSteps)
                } else if(location.pathname === "/tasks/create"){ //for createtask
                    setSteps(createTaskSteps);
                } else if(location.pathname.includes("/tasks/")&&location.pathname.includes("/jobs/")){ //for annotation pages
                    setSteps(annotationPageSteps);
                } else if(location.pathname.includes("/tasks/")){ //for task details pages
                    if(props.user.groups.includes("provider")){
                        setSteps(taskDetailsProviderSteps);
                    }else{
                        setSteps(taskDetailsAnnotatorSteps);
                    }
                } else if(location.pathname === "/profile"){
                    setSteps(profilePageSteps)
                } else {
                    setSteps(steps);
                }
                let users;
                if(localStorage.getItem("users")){
                    users = JSON.parse(localStorage.getItem("users"))
                }
                else
                    users = new Array()

                if(!users.includes(props.user.email)){
                    setIsOpen(true)
                    users.push(props.user.email)
                    localStorage.setItem("users",JSON.stringify(users))
                }
            }
        }
    }, [location.pathname, setCurrentStep, setSteps, props.user]);
    return <CVATApplicationClass {...props} ></CVATApplicationClass>
}

/**
 * @description CVATApplication base class   
 */
class CVATApplicationClass extends React.PureComponent<CVATAppProps & RouteComponentProps> {
    /**
     * 
     * @param props CVATAppProps & RouteComponentProps
     */
    constructor(props: any) {
        super(props);
        this.state = {
            width: 1920,
            height: 1080,
        }
        this.handleResize = this.handleResize.bind(this);
    }

    /**
     * @description Apply some configurations when the component is already placed in the DOM
     */
    public componentDidMount(): void {
        const core = getCore();
        const { verifyAuthorized, history } = this.props;
        configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

        // Logger configuration
        const userActivityCallback: (() => void)[] = [];
        window.addEventListener('click', () => {
            userActivityCallback.forEach((handler) => handler());
        });
        core.logger.configure(() => window.document.hasFocus, userActivityCallback);

        customWaViewHit(location.pathname, location.search, location.hash);
        history.listen((location) => {
            customWaViewHit(location.pathname, location.search, location.hash);
        });

        verifyAuthorized();
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }

    /**
     * @description re-set the width and height state from window
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    /**
     *  @description Apply some configurations right before the component will unmount from DOM
     */
    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }


    /**
     * 
     * @returns used for if some states did not initialized 
     */
    public componentDidUpdate(): void {
        const {
            verifyAuthorized,
            loadFormats,
            loadUsers,
            loadAbout,
            loadUserAgreements,
            initPlugins,
            userInitialized,
            userFetching,
            formatsInitialized,
            formatsFetching,
            usersInitialized,
            usersFetching,
            aboutInitialized,
            aboutFetching,
            pluginsInitialized,
            pluginsFetching,
            user,
            logoutUser,
            userAgreementsFetching,
            userAgreementsInitialized,
        } = this.props;


        this.showErrors();
        this.showMessages();

        if (!userInitialized && !userFetching) {
            verifyAuthorized();
            return;
        }

        if (!userAgreementsInitialized && !userAgreementsFetching) {
            loadUserAgreements();
            return;
        }

        if (user == null) {
            return;
        }

        if (!formatsInitialized && !formatsFetching) {
            loadFormats();
        }

        if (!usersInitialized && !usersFetching) {
            loadUsers();
        }

        if (!aboutInitialized && !aboutFetching) {
            loadAbout();
        }

        if (!pluginsInitialized && !pluginsFetching) {
            initPlugins();
        }
    }

    /**
     * @description Shows the messages from notifications props using notification lib
     */
    private showMessages(): void {
        function showMessage(title: string): void {
            notification.info({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
            });
        }

        const {
            notifications,
            resetMessages,
        } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.messages)) {
            for (const what of Object.keys(notifications.messages[where])) {
                const message = notifications.messages[where][what];
                shown = shown || !!message;
                if (message) {
                    showMessage(message);
                }
            }
        }

        if (shown) {
            resetMessages();
        }
    }

    /**
     * @description Shows the errors from notifications props using notification lib
     */
    private showErrors(): void {
        function showError(title: string, _error: any): void {
            const error = _error.toString();
            notification.error({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
                description: error.length > 200 ? i18n.t('messages.openConsole') : error,
            });

            console.error(error);
        }



        function showWarning(title: string, warning: any): void {
            const error = warning.toString();
            notification.warning({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: `${title}`,
                        }}
                    />
                ),
                duration: null,
                description: warning.length > 200 ? i18n.t('messages.openConsole') : warning,
            });

        }

        const {
            notifications,
            resetErrors,
        } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.errors)) {
            for (const what of Object.keys(notifications.errors[where])) {
                if (where == "auth") {
                    if (what != "register" && what != "authorized") {
                        const error = notifications.errors[where][what];
                        shown = shown || !!error;
                        if (error) {
                            if (error.message == i18n.t('notifications-reducer.login.401.blocked')) {
                                showWarning(error.message, error.reason);
                            } else {
                            showError(error.message, error.reason);
                            }
                        }
                    }
                }
                else {
                    const error = notifications.errors[where][what];
                    shown = shown || !!error;
                    if (error) {
                        showError(error.message, error.reason);
                    }
                }
            }
        }

        if (shown) {
            resetErrors();
        }
    }
    /**
     * 
     * @description Configures the router settings, including setting URLs, paths, and redirects.
     * @returns Array includes Route objects that contains path and component informations
     */
    private getRouters(){
        const { userInitialized,
            usersInitialized,
            aboutInitialized,
            pluginsInitialized,
            formatsInitialized,
            installedAutoAnnotation,
            installedTFSegmentation,
            installedTFAnnotation,
            switchShortcutsDialog,
            user,
            annotaUser,
            logoutUser,
            history,
            keyMap,
            config,} = this.props;
            const withModels = installedAutoAnnotation
            || installedTFAnnotation || installedTFSegmentation;
        let routerArr = new Array();

        routerArr.push(<Route exact path='/yzp-login' component={YzpLogin} />)

        let permissions = annotaUser[0].group_role_permissions
        let hasStatsPermission = permissions.filter(object => object.permission_id__name == 'view_system_usage').length > 0
        let hasLogsPermission = permissions.filter(object => object.permission_id__name == 'view_logs').length > 0
        let hasTaskCreatePermission = permissions.filter(object => object.permission_id__name == 'create_task').length > 0
        let hasDatasetCreatePermission = permissions.filter(object => object.permission_id__name == 'create_dataset').length > 0
        let hasUserGroupsCreatePermission = permissions.filter(object => object.permission_id__name == 'create_user_groups').length > 0
        let hasListDatasetPermission = permissions.filter(object => object.permission_id__name  == 'list_datasets').length > 0
        let hasDashboardViewPermission = permissions.filter(object => object.permission_id__name  == 'view_dashboard').length > 0
        let hasTestTrainingViewPermission = permissions.filter(object => object.permission_id__name  == 'view_test_training').length > 0
        let hasViewTasksPermission = permissions.filter(object => object.permission_id__name  == 'annotate_image').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'annotate_text').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'annotate_video').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'annotate_3dpc').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'create_task').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'approve_tasks').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'dump_annotations').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'export_images').length > 0 ||
            permissions.filter(object => object.permission_id__name  == 'share_task').length > 0
        let hasEvaluateTestsPermission = permissions.filter(object => object.permission_id__name  == 'evaluate_tests').length > 0
        let hasApproveTasksPermission = permissions.filter(object => object.permission_id__name  == 'approve_tasks').length > 0
        let hasViewUsersPermission = permissions.filter(object => object.permission_id__name  == 'view_users').length > 0

        let isSystemAdmin = user.groups.includes("system_admin")
        let isSuperuser = user.isSuperuser || user.groups.includes("admin")
        let router_redirect = ""
        if(isSuperuser)
            router_redirect = "/dashboard"
        else{
            if(hasDashboardViewPermission)
                router_redirect = "/dashboard"
            else if(hasViewTasksPermission)
                router_redirect = "/tasks"
            else if(hasLogsPermission)
                router_redirect = "/logs"
            else if(hasStatsPermission)
                router_redirect = "/stats"
            else if(hasDatasetCreatePermission)
                router_redirect = "/datasets/create"
            else if(hasTaskCreatePermission)
                router_redirect = "/tasks/create"
            else
                router_redirect = "/profile"
        }

        if(user.groups.includes("candidate")){
            routerArr.push(<Route exact path='/tasks/:tid/jobs/:jid' component={AnnotationPageContainer} />)
            routerArr.push(<Route exact path='/testtraining' component={TestTrainingPage} />)
            routerArr.push(<Route exact path='/profile' component={ProfilePageComponent} />)
            routerArr.push(<Route exact path='/payments' component={PaymentsPageComponent} />)
            routerArr.push(<Route exact path='/change-pass' component={ChangePassword} />)
            routerArr.push(<Redirect push to={{ pathname: '/testtraining', state: { referrer: location.pathname, redirectMessage: i18n.t('test-training.redirectMessage') } }} />)
        }
        else{
            (hasListDatasetPermission||isSuperuser) && routerArr.push(<Route exact path='/datasets' component={DatasetsPage} />);
            (hasDatasetCreatePermission||isSuperuser) && routerArr.push(<Route exact path='/datasets/create' component={CreateDatasetPageContainer} />);
            (hasTaskCreatePermission||isSuperuser) &&routerArr.push(<Route exact path='/tasks/create' component={CreateTaskPageContainer} />);
            routerArr.push(<Route exact path='/datasets/modify/:id' component={ModifyDatasetPageContainer} />);
            (hasUserGroupsCreatePermission||isSuperuser) && routerArr.push(<Route exact path='/providergroup' component={ProviderGroupPage} /> );

            withModels && routerArr.push(<Route exact path='/models' component={ModelsPageContainer} />)
            installedAutoAnnotation && routerArr.push(<Route exact path='/models/create' component={CreateModelPageContainer} />)

            if(user.groups.includes("ssbprovider")||isSuperuser){
                routerArr.push(<Route exact path='/ssb/datasets' component={VSPDatasetsPage} />)
            }

            (isSuperuser||hasDashboardViewPermission)&&routerArr.push(<Route exact path='/dashboard' component={DashboardPageComponent} />);
            (isSuperuser||hasViewTasksPermission)&&routerArr.push(<Route exact path='/tasks' component={TasksPageContainer} />);
            (isSuperuser||hasViewTasksPermission||hasApproveTasksPermission)&&routerArr.push(<Route exact path='/tasks/:id' component={TaskPageContainer} />);
            (isSuperuser||hasViewTasksPermission||hasApproveTasksPermission)&&routerArr.push(<Route exact path='/tasks/:tid/jobs/:jid' component={AnnotationPageContainer} />);
            (isSuperuser||hasEvaluateTestsPermission||hasTestTrainingViewPermission)&&routerArr.push(<Route exact path='/testtraining' component={TestTrainingPage} />);
            routerArr.push(<Route exact path='/profile' component={ProfilePageComponent} />)
            //routerArr.push(<Route exact path='/payments' component={PaymentsPageComponent} />)
            routerArr.push(<Route exact path='/change-pass' component={ChangePassword} />)
            routerArr.push(<Route exact path='/messages' component={MessagesPageComponent} />);
            (hasStatsPermission||isSuperuser)&&routerArr.push(<Route exact path='/stats' component={SystemResourcesComponent} />);
            (hasLogsPermission||isSuperuser)&&routerArr.push(<Route exact path='/logs' component={LogsPageComponent} />);
            (hasEvaluateTestsPermission||isSuperuser)&&routerArr.push(<Route exact path='/evaluate-tests' component={TestEvaluationPage} />);
            (hasApproveTasksPermission||isSuperuser)&&routerArr.push(<Route exact path='/approve-tasks' component={TaskApprovalPage} />);
            (hasViewUsersPermission||isSuperuser)&&routerArr.push(<Route exact path='/manage-users' component={AdminPage} />);
            if(isSuperuser){
                routerArr.push(<Route exact path='/manage-platform' component={PlatformManComponent} />)
                routerArr.push(<Route exact path='/annotation-eval-page' component={AnnotationEvaluationPage} />)
                routerArr.push(<Route exact path='/roles' component={RolesPage}/>)
            }
            routerArr.push(<Redirect push to={router_redirect} />)

        }
        return routerArr;
    }

    // Where you go depends on your URL
    /**
     * 
     * @returns Routes and redirect in switchs
     * @description Configures Routes and redirect in switchs according to the User spesific informations
     */
    public render(): JSX.Element {
        const {
            userInitialized,
            usersInitialized,
            aboutInitialized,
            pluginsInitialized,
            formatsInitialized,
            installedAutoAnnotation,
            installedTFSegmentation,
            installedTFAnnotation,
            switchShortcutsDialog,
            user,
            annotaUser,
            logoutUser,
            history,
            keyMap,
            config,
        } = this.props;
        Spin.setDefaultIndicator(<Icon className="cvat-spinner-logo" component={VKSpin}></Icon>)

        const readyForRender = (userInitialized && user == null)
            || (userInitialized && formatsInitialized
                && pluginsInitialized && usersInitialized && aboutInitialized);

        const withModels = installedAutoAnnotation
            || installedTFAnnotation || installedTFSegmentation;

        const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
/*             OPEN_SETTINGS: keyMap.OPEN_SETTINGS,
 */        };

        const handlers = {
            SWITCH_SHORTCUTS: (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                switchShortcutsDialog();
            },
            /* OPEN_SETTINGS: (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                if (history.location.pathname.endsWith('settings')) {
                    history.goBack();
                } else {
                    history.push('/settings');
                }
            }, */
        };

        let layoutMargin = 100
        if(this.state.width / this.state.height   < 1.7){
            layoutMargin = 60
        }

        if (readyForRender) {
            if (cvat.config.prodType == "registerOnly" && process.env.DEPLOYMENT_PLACE === "SSB") {
                return (
                    <GlobalErrorBoundary>
                        <Switch>
                            <Route exact path='/auth/register' component={RegisterPageContainer} />
                            <Redirect to='/auth/register' />
                        </Switch>
                    </GlobalErrorBoundary>

                )
            }
            if (user) {
                /*if(userLogOutCheck == 0 && user != null && user.isSuperuser == false){
                    logoutUser()
                }*/
                if (annotaUser[0].isBlocked == true || annotaUser[0].state == 'rejected') {
                    return (
                        <GlobalErrorBoundary>
                            <Switch>
                                <Layout>
                                    <HeaderContainer> </HeaderContainer>
                                    <Layout.Content>
                                        <Switch>
                                            <Route exact path='/rejected-blocked' component={RejectedPage} />
                                            <Redirect to='/rejected-blocked' />
                                        </Switch>
                                    </Layout.Content>
                                </Layout>
                            </Switch>
                        </GlobalErrorBoundary>
                    )
                } else if(annotaUser[0].canLogin == true || user.isSuperuser||user.groups.includes("admin")){
                    return (
                        <GlobalErrorBoundary>
                            <Layout>
                                <HeaderContainer></HeaderContainer>
                                <Layout style={{marginTop:layoutMargin}}>
                                    <SidebarContainer></SidebarContainer>
                                    <Layout>
                                        <Layout.Content >
                                            <Switch>
                                                {this.getRouters()}
                                            </Switch>
                                            {/* eslint-disable-next-line */}
                                            <a id='downloadAnchor' style={{ display: 'none' }} download />
                                        </Layout.Content>
                                    </Layout>
                                </Layout>
                            </Layout>
                        </GlobalErrorBoundary>
                    );
                }
            }
            if(config && config.registrationOpen) {
                return (
                    <GlobalErrorBoundary>
                        <Switch>
                            {process.env.DEPLOYMENT_PLACE === "SSYZ" && <Route exact path='/yzp-login' component={YzpLogin} />}
                            <Route exact path='/auth/login' component={LoginPageContainer} />
                            
                            {process.env.DEPLOYMENT_PLACE !=="SSYZ" && <>
                            <Route exact path='/auth/contact' component={RegisterHelpComponent} />
                            <Route exact path='/auth/reset-password' component={ResetPageContainer}/>
                            <Route exact path='/auth/reset-password-confirm/:uidb64/:token' component={ResetConfirmPageContainer}/>
                            <Route exact path='/auth/register' component={RegisterPageContainer} />
                            <Route exact path='/auth/register/specialannotator/:uidb64' component={SpecialAnnotatorRegisterPageContainer} />
                            </>}
                            <Route exact path='/home' component={RedirectComponent} />
                            <Route exact path='/contact' component={ContactUsComponent} />
                            <Route exact path='/messages' component={MessagesPageComponent} />
                            <Redirect to='/home' />
                        </Switch>
                    </GlobalErrorBoundary>
                );
            }
            return (
                <GlobalErrorBoundary>
                    <Switch>
                        {process.env.DEPLOYMENT_PLACE === "SSYZ" && <Route exact path='/yzp-login' component={YzpLogin} />}
                        <Route exact path='/auth/login' component={LoginPageContainer} />
                        <Route exact path='/home' component={RedirectComponent} />
                        {process.env.DEPLOYMENT_PLACE !== "SSYZ" && <>
                        <Route exact path='/auth/reset-password' component={ResetPageContainer} />
                        <Route exact path='/auth/reset-password-confirm/:uidb64/:token' component={ResetConfirmPageContainer} />
                        <Route exact path='/auth/register' state='Please sign in' render={() => { window.location.assign('/home.html#warning');  return null;}}/>
                        <Route exact path='/auth/register/specialannotator/:uidb64' component={SpecialAnnotatorRegisterPageContainer} />
                        <Route exact path='/auth/contact' component={RegisterHelpComponent} />
                        </>}
                        <Route exact path='/contact' component={ContactUsComponent} />
                        <Route exact path='/messages' component={MessagesPageComponent} />
                        <Redirect to='/home' />
                    </Switch>
                </GlobalErrorBoundary>
            );
        }
        return (
            <Spin size='large' className='cvat-spinner' />
        );
    }
}

export default withRouter(CVATApplication);
