// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Spin from 'antd/lib/spin';
import Result from 'antd/lib/result';
import i18n from '../../i18n'

import SegmentDetails from './segment-details';
import DetailsContainer from 'containers/task-page/details';
import JobListContainer from 'containers/task-page/job-list';
import ModelRunnerModalContainer from 'containers/model-runner-dialog/model-runner-dialog';
import { Task } from 'reducers/interfaces';
import TopBarComponent from './top-bar';
import { any } from 'prop-types';
import getCore from 'cvat-core-wrapper';
const cvat = getCore();
import { Modal } from 'antd';
interface TaskPageComponentProps {
    task: Task | null | undefined;
    fetching: boolean;
    deleteActivity: boolean | null;
    installedGit: boolean;
    getTask: () => void;
    user: any;
}

type Props = TaskPageComponentProps & RouteComponentProps<{id: string}>;

/**
 * @cass_component Task Page Component rendering /task pages for individual task views.
 */
class TaskPageComponent extends React.PureComponent<Props> {

    constructor(props: any) {
        super(props);
        this.state = {
            taskPath: "",
            annotaTask: ''
        }
        this.getTaskPathFromDB()
        if(this.detecMobile()){
            Modal.info({
                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                okText: 'Tamam',
            });
            setTimeout(function(){ window.location.assign('/dashboard') }, 100);
        }
        this.props.getTask()
    }

    /**
     *
     * @returns boolean checks if mobile device
     */
    public detecMobile() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true
        } else {
            return false
        }
    }

    /**
     * @async
     * @description get task and annota task path with getTaskPath api proxy call
     */
    async getTaskPathFromDB(){
        let response = null;
        let url = String(window.location.href)
        let temp = url.split('/')
        response = await cvat.tasks.getTaskPath(temp[temp.length-1]);
        let responseAnnotaTask = await cvat.tasks.getAnnotaTasks(null,temp[temp.length-1]);
        console.log(response)
        this.setState({
            taskPath : response.task_path,
            annotaTask: responseAnnotaTask
        })
    }

    public componentDidUpdate(): void {
        const {
            deleteActivity,
            history,
        } = this.props;

        if (deleteActivity) {
            history.replace('/tasks');
        }
    }

    /**
     *
     * @returns JSX Template for Individual Task Page, id given in url
     */
    public render(): JSX.Element {
        const {
            task,
            fetching,
            getTask,
        } = this.props;

        if (task === null) {
            if (!fetching) {
                getTask();
            }

            return (
                <Spin size='large' className='cvat-spinner' />
            );
        }

        if (typeof (task) === 'undefined') {
            return (
                <Result
                    className='cvat-not-found'
                    status='404'
                    title={i18n.t('task-page.taskNotFoundError')}
                    subTitle={i18n.t('task-page.taskNotFoundTip')}
                />
            );
        }

        let viewer = task.instance.tasksIdAnnota[0].viewers.filter(vUser => this.props.user.id == vUser.id).length>0

        /**
         * @description Renders Details, Segment Details, Job List and ModelRunnerModal Container for task instance view
         */
        return (
            <div className="approval-page">
                <Row type='flex' justify='start' align='top' className='cvat-task-details-wrapper'>
                    <DetailsContainer task={(task as Task)} viewer={viewer} />
                    <SegmentDetails taskInstance={(task as Task).instance} viewer={viewer} user={this.props.user}></SegmentDetails>
                    {!viewer&&
                        <JobListContainer task={(task as Task)} taskPath={this.state.taskPath} annotaTask={this.state.annotaTask} />
                    }
                </Row>
                <ModelRunnerModalContainer />
            </div>
        );
    }
}

export default withRouter(TaskPageComponent);
