import React from 'react';

import {
    Input,
} from 'antd';

import Form, { FormComponentProps } from 'antd/lib/form/Form';

export interface BaseConfiguration {
    name: string;
}

type Props = FormComponentProps & {
    onSubmit(values: BaseConfiguration): void;
};

/**
 * @class_component Basic Configuration Form Component
 */
class BasicConfigurationForm extends React.PureComponent<Props> {
    /**
     *
     * @deprecated is not used anymore.
     */
    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                form,
                onSubmit,
            } = this.props;

            form.validateFields((error, values): void => {
                if (!error) {
                    onSubmit({
                        name: "",
                    });
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }

    public resetFields(): void {
        const { form } = this.props;
        form.resetFields();
    }

    public render(): JSX.Element {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            // <Form onSubmit={(e: React.FormEvent): void => e.preventDefault()}>
            //     <Form.Item hasFeedback label={<span>Name</span>}>
            //         { getFieldDecorator('name', {
            //             rules: [{
            //                 required: true,
            //                 message: 'Please, specify a name',
            //             }],
            //         })(
            //             <Input />,
            //         ) }
            //     </Form.Item>
            // </Form>
            <div></div>
        );
    }
}

export default Form.create<Props>()(BasicConfigurationForm);
