import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import React from 'react'
import Backend from 'i18next-xhr-backend';
import translationEN from '../public/locales/en/translation.json';
import translationTR from '../public/locales/tr/translation.json';

const resources = {
    en: {
      translation: translationEN
    },
    tr: {
      translation: translationTR
    }
  };

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: true,

    lng: 'tr',
    fallbackLng: 'tr',
    whitelist: ['en', 'tr'],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    compatibilityJSON: 'v3'

  });

export default i18n;