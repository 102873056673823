import '../create-task-page/styles.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Row,
    Col,
} from 'antd';

import Text from 'antd/lib/typography/Text';
import { Modal} from 'antd';
import CreateDatasetContent, { CreateDatasetData } from './create-dataset-content';

interface Props {
    onCreate: (data: CreateDatasetData) => void;
    status: string;
    statusDatasetUploadProgress: string;
    installedGit: boolean;
}

/**
 * Check mobile usage
 *
 * @returns true if mobile is used
 */
function detecMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true
    } else {
        return false
    }
}

/**
 * Create adding dataset page
 *
 * @param props consists of status, statusDatasetUploadProgress, installedGit variables and onCreate function
 * @returns div
 */
export default function CreateDatasetPage(props: Props): JSX.Element {
    const {
        status,
        onCreate,
        installedGit,
        statusDatasetUploadProgress
    } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if(detecMobile()){
            Modal.info({
                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                okText: 'Tamam',
            });
            setTimeout(function(){ window.location.assign('/dashboard') }, 100);
        }
    }, []);

    return (
        <div className="approval-page">
            <Text className='cvat-title'>{t('create-dataset.createDataset')}</Text>
            <Row type='flex' justify='center' align='top' className='cvat-create-task-form-wrapper'>
                    <CreateDatasetContent
                        status={status}
                        statusDatasetUploadProgress={statusDatasetUploadProgress}
                        onCreate={onCreate}
                        installedGit={installedGit}
                    />
            </Row>
        </div>
    );
}
