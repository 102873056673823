// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';

import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Slider, { SliderValue } from 'antd/lib/slider';
import Tooltip from 'antd/lib/tooltip';
import InputNumber from 'antd/lib/input-number';
import Text from 'antd/lib/typography/Text';
import { LinkIcon } from 'icons';

import { clamp } from 'utils/math';
import { useTranslation } from 'react-i18next';

interface Props {
    startFrame: number;
    stopFrame: number;
    frameNumber: number;
    frameFilename: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<InputNumber>;
    onSliderChange(value: SliderValue): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
}
/**
 * Create slider component for navigation and frame number as a input
 *
 * @param props consists of startFrame, stopFrame, frameNumber, inputFrameRef variables and onSliderChange, onInputChange, onURLIconClick functions
 * @returns Col, Row
 */
function PlayerNavigation(props: Props): JSX.Element {
    const {
        startFrame,
        stopFrame,
        frameNumber,
        frameFilename,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
    } = props;

    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);
    const { t } = useTranslation();
    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    return (
        <>
            <Col className='cvat-player-controls'>
                <Row type='flex'>
                    <Col>
                        <Slider
                            className='cvat-player-slider'
                            min={startFrame}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <Tooltip title={`${t('standard-workspace.toFocusHere')} ${t('standard-workspace.press')}`}>
                    <InputNumber
                        className='cvat-player-frame-selector'
                        type='number'
                        value={frameInputValue}
                        onChange={(value: number | undefined) => {
                            if (typeof (value) === 'number') {
                                setFrameInputValue(Math.floor(
                                    clamp(value, startFrame, stopFrame),
                                ));
                            }
                        }}
                        onBlur={() => {
                            onInputChange(frameInputValue);
                        }}
                        onPressEnter={() => {
                            onInputChange(frameInputValue);
                        }}
                        formatter={value => `${parseInt(value)+1}`}
                        parser={value => `${parseInt(value)-1}`}
                        ref={inputFrameRef}
                    />
                    <Text className="image-count-text" style={{fontWeight :"bold"}}>  / {stopFrame+1}</Text>
                </Tooltip>
            </Col>
            <Col>
            <Row type='flex' justify='center'>
                    {/* <Col className='cvat-player-filename-wrapper'>
                        <Tooltip className="annotation-topbar-tooltip">
                            <Text type='secondary'>{t('standard-workspace.createFrameURL')}</Text>
                        </Tooltip>
                    </Col> */}
                    <Col offset={1}>
                        <Tooltip title= {t('standard-workspace.createFrameURL')}>
                            <Icon className='cvat-player-frame-url-icon' type='link' component={LinkIcon} onClick={onURLIconClick} />
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default React.memo(PlayerNavigation);
