import './styles.scss';
import React, { Component } from 'react';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Button, Row, Table, Input, Modal, Transfer, ConfigProvider, Pagination, Col, Popconfirm } from 'antd';
import getCore from '../cvat-core-wrapper';
import i18n from '../i18n'
import Column from 'antd/lib/table/Column';
import { getCVATStore } from '../cvat-store';
import { AuthState, CombinedState } from '../reducers/interfaces';
import Text from 'antd/lib/typography/Text';
import tr_TR from 'antd/es/locale/tr_TR';

const cvat = getCore();
let store: null | Store<CombinedState> = null;
let backendEndPoint = String(cvat.config.backendAPI)
let backendRoot = String(cvat.config.root)

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}
interface VisibleTopBarProps {
    user: any;
    onSearch: (value: string) => void;
    searchValue: string;
    auth: AuthState;
}

/**
 *
 * @param state
 * @returns auth properties
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

type ProviderGroupPageProps = VisibleTopBarProps & RouteComponentProps;
class RolesPage extends React.PureComponent<ProviderGroupPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            rolesCount: 0,
            currentPage:1,
            roles: [],
            permissions: [],
            targetKeys: [],
            selectedKeys: [],
            modalType: "",
            visible: false,
            newRoleName: "",
            editRole: "",
            width: 1920,
            height: 1080,
        }

        this.getRoles = this.getRoles.bind(this);
        this.getPermissions = this.getPermissions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.filterOption=this.filterOption.bind(this)
        this.handleResize = this.handleResize.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleDeleteRole = this.handleDeleteRole.bind(this);
        this.getRoles();
        this.getPermissions();
    }

    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     * @description get roles call updates the list
     */
    public updateList() {
        const groupsAsync = this.getRoles();
    }

    private getPermissionName(state: String) {
        switch (state) {
            case 'annotate_image':
                return 'İmge Etiketleme';
            case 'annotate_video':
                return 'Video Etiketleme';
            case 'annotate_text':
                return 'Metin Etiketleme';
            case 'annotate_3dpc':
                return 'Nokta Kümesi Etiketleme';
            case 'view_logs':
                return 'Logları Görüntüleme';
            case 'view_system_usage':
                return 'Kaynak Kullanımı Görüntüleme';
            case 'create_task':
                return 'Görev Oluşturma';
            case 'create_dataset':
                return 'Veri Kümesi Oluşturma';
            case 'create_user_groups':
                return 'Kullanıcı Grubu Oluşturma';
            case 'dump_annotations':
                return 'Etiket İndirme';
            case 'export_images':
                return 'Etiketlenmiş İmgeleri İndirme';
            case 'share_task':
                return 'Görev Paylaşma';
            case 'list_datasets':
                return 'Veri Kümelerini Listeleme';
            case 'view_dashboard':
                return 'Pano Görüntüleme';
            case 'view_users':
                return 'Kullanıcıları Yönetme';
            case 'view_payments':
                return 'Ödeme Yönetme';
            case 'download_datasets':
                return 'Veri Kümesi İndirme';
            case 'evaluate_tests':
                return 'Test Değerlendirme';
            case 'approve_tasks':
                return 'Görev Onaylama';
            case 'view_test_training':
                return 'Eğitim Test Sayfası Görüntüleme';
            case 'manage_roles_permissions':
                return 'Rol Yetki Düzenleme';
            case null:
                return '';
        }
    }

    private getRoleName(name: String) {
        switch (name) {
            case 'annotator':
                return 'Etiketleyici';
            case 'candidate':
                return 'Aday Etiketleyici';
            case 'special_annotator':
                return 'Özel Etiketleyici';
            case 'system_admin':
                return 'Sistem Yöneticisi';
            case 'provider':
                return 'Veri Sağlayıcı';
            case 'ssbprovider':
                return 'SSB Veri Sağlayıcı';
            case 'admin':
                return 'Platform Yöneticisi';
            case 'yzp_integration':
                return 'YZP Entegrasyon Rolü';
            case null:
                return '';
            default:
                return name
        }
    }

    /**
     * @async
     * @param pageNo
     * @description get roles async api proxy call, sets state for roles count and roles
     */
    async getRoles(pageNo=1) {
        let response = await cvat.management.getRoles(pageNo)
        this.setState({
            rolesCount: response.count,
            roles: response.results,
        });
    }

    /**
     * @async
     * @description get permissions async api proxy call, sets state for permissions
     */
    async getPermissions() {
        let response = await cvat.management.getPermissions()
        this.setState({
            permissions: response.map(p => { return { key: (p.id).toString(), title: this.getPermissionName(p.name), disabled: (p.name == 'manage_roles_permissions')} })
        });
    }

    /**
     * @description update roles async api call, clear state
     */
    async modifyRole() {
        const response = await cvat.management.updateRole(this.state.editRole.id,this.state.targetKeys);
        this.setState({
            targetKeys:[],
            selectedKeys:[],
            editRole:""
        });
        this.updateList();

    }

    /**
     * @async
     * @description create role api proxy call, Modal success and error handling, clears state, updates list
     */
    async handleCreateNewRole() {
        cvat.management.createRole(this.state.newRoleName, this.state.targetKeys).then(response=>{
            this.setState({
                newRoleName: "",
                targetKeys:[],
                selectedKeys:[]
            });
            this.updateList();
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Rol başarıyla oluşturuldu!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        }, error=> {
            Modal.error({
                title: 'Rol Oluşturulamadı!',
                content: 'Aynı isimli rol zaten mevcut!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        });
    }

    /**
     * @async
     * @param roleId
     * @description delete role api proxy call, modal success and error handling
     */
    async handleDeleteRole(roleId: any) {
        cvat.management.deleteRole(roleId).then(response => {
            this.updateList();
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Rol başarıyla silindi!',
                onOk() {
                    Modal.destroyAll();
                },
            });
        }, error => {
            Modal.error({
                title: 'Rol Silinemedi!',
                content: 'Role sahip kullanıcıları güncelleyin!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        });
    }

    /**
     *
     * @param type
     * @param item
     * @description switch case with modal type
     */
    private showModal(type: string, item?: any) {
        switch (type) {
            case "create":
                this.setState({
                    visible: true,
                    modalType: "create",
                })
                break;
            case "edit":
                this.setState({
                    visible: true,
                    modalType: "edit",
                    editRole: item,
                    targetKeys: item.permissions.map(item => (item.id).toString()),
                })
                break;
            case "read":
                this.setState({
                    visible: true,
                    modalType: "read",
                    editRole: item,
                    targetKeys: item.permissions.map(item => (item.id).toString()),
                })
                break;
        }
    }

    /**
     *
     * @param e modal event
     * @description cancel modal
     */
    handleCancel = e => {
        this.setState({
            visible: false,
            newRoleName: "",
            targetKeys:[],
            selectedKeys:[]
        }), () => {
            Modal.destroyAll();
        };
    };

    /**
     *
     * @param e event
     * @returns void
     * @description handle new role creation and role modification OK button click event
     */
    handleOk = e => {
        this.setState({
            visible: false,
        }, () => {
            if (this.state.modalType === "create") {
                this.handleCreateNewRole();
            }
            else {
                this.modifyRole();
            }
            Modal.destroyAll();
        });

        return;
    }

    handleChange(nextTargetKeys){
        this.setState({ targetKeys: nextTargetKeys });

    }

    handleSelectChange(sourceSelectedKeys,targetSelectedKeys){
        this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });

    }

    filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

    /**
     * @description Main Modal template for Edit, Read, Create Roles
     * @returns Modal template according to modalType set in state
     */
    private renderModal() {
        let basic_roles = ['annotator', 'provider', 'system_admin', 'ssbprovider', 'admin',  'candidate', 'special_annotator']
        if(process.env.DEPLOYMENT_PLACE == 'SSYZ') {
            basic_roles.push('yzp_integration');
        }
        if (this.state.modalType === "edit")
            return (<Modal
                title="Düzenle"
                visible={this.state.visible}
                onOk={this.handleOk}
                okText='Tamam'
                cancelText='İptal'
                width="max-content"
                onCancel={this.handleCancel}
            >
                <ConfigProvider locale={tr_TR}>
                    <Transfer
                        dataSource={this.state.permissions}
                        showSearch
                        listStyle={{
                            width: 300,
                            height: 300,
                          }}
                        titles={['Eksik Yetkiler', 'Var Olan Yetkiler']}
                        locale={{ itemUnit: "", itemsUnit: "", searchPlaceholder: "Ara" }}
                        selectedKeys={this.state.selectedKeys}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChange}
                        onSelectChange={this.handleSelectChange}
                        filterOption = {this.filterOption}
                        render={item => String(item.title)}
                        disabled={basic_roles.includes(this.state.editRole.name)}
                    />
                </ConfigProvider>

            </Modal>)
        else if (this.state.modalType === "create")
            return (<Modal
                title="Yeni Rol Oluştur"
                visible={this.state.visible}
                onOk={this.handleOk}
                width="max-content"

                okText='Tamam'
                cancelText='İptal'
                onCancel={this.handleCancel}
            >
                <div>
                    <Row>
                        <Input onChange={(e) => this.setState({ newRoleName: e.target.value })}></Input>
                    </Row>

                    <Row>
                        <ConfigProvider locale={tr_TR}>
                            <Transfer
                                dataSource={this.state.permissions}
                                showSearch
                                filterOption = {this.filterOption}
                                listStyle={{
                                    width: 300,
                                    height: 300,
                                  }}
                                titles={['Eksik Yetkiler', 'Var Olan Yetkiler']}
                                locale={{ itemUnit: "", itemsUnit: "", searchPlaceholder: "Ara" }}
                                selectedKeys={this.state.selectedKeys}
                                targetKeys={this.state.targetKeys}
                                onChange={this.handleChange}
                                onSelectChange={this.handleSelectChange}
                                render={item => String(item.title)}
                            />
                        </ConfigProvider>
                    </Row>
                </div>
            </Modal>)
        else if (this.state.modalType === "read")
            return (<Modal
                title="Görüntüle"
                visible={this.state.visible}
                width="max-content"
                onCancel={this.handleCancel}
                footer={[
                    <Button key="ok" onClick={this.handleOk}>
                      Tamam
                    </Button>
                ]}
            >
                <ConfigProvider locale={tr_TR}>
                    <Transfer
                        dataSource={this.state.permissions}
                        showSearch
                        listStyle={{
                            width: 300,
                            height: 300,
                          }}
                        titles={['Eksik Yetkiler', 'Var Olan Yetkiler']}
                        locale={{ itemUnit: "", itemsUnit: "", searchPlaceholder: "Ara" }}
                        selectedKeys={this.state.selectedKeys}
                        targetKeys={this.state.targetKeys}
                        onChange={this.handleChange}
                        onSelectChange={this.handleSelectChange}
                        filterOption = {this.filterOption}
                        render={item => String(item.title)}
                        disabled={this.state.editRole.name === "admin"}
                    />
                </ConfigProvider>
            </Modal>)
    }

    private handlePagination(value:number){
        this.getRoles(value)
        this.setState({
            currentPage:value,

        });
    }

    /**
     *
     * @returns Roles Table JSX Template
     */
    private renderTopComp() {
        let spanTopCard = 12
        let width = '90%'
        if (this.state.width < this.state.height) {
            spanTopCard = 24
            width = '100%'
        }

        let basic_roles = ['annotator', 'provider', 'system_admin', 'ssbprovider', 'admin', 'special_annotator', 'candidate']
        if(process.env.DEPLOYMENT_PLACE == 'SSYZ') {
            basic_roles.push('yzp_integration');
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title">Roller</Text>
                <Button onClick={() => this.showModal("create")} style={{marginLeft: '2%'}}>Yeni Rol Oluştur</Button>
                <div style={{ paddingTop: "15px" }}>
                    <div style={{ padding: "1% 2% 1% 2%", width: width, left: "15.9%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 10px 30px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "6px" }}>
                        <Table pagination={false} dataSource={this.state.roles}>
                            <Column key="name"  render={(record) =>  <p>{this.getRoleName(record.name)}</p>}/>
                            <Column render={(record) =>
                                (basic_roles.includes(record.name) ? <Button  onClick={() => this.showModal("read", record)}>Görüntüle</Button> :
                                <Button  onClick={() => this.showModal("edit", record)}>Düzenle</Button> )} />
                            <Column render={(record) => <Popconfirm title="Rolü silmek istediğinizden emin misiniz?"
                                    disabled={basic_roles.includes(record.name)}
                                    cancelText={i18n.t('keywords.no')}
                                    okText={i18n.t('keywords.yes')}
                                    onConfirm={ (e) => {this.handleDeleteRole(record.id) }
                                    }>
                                        <Button disabled={basic_roles.includes(record.name)}>Sil</Button>
                                    </Popconfirm>
                            } />
                        </Table>
                    </div>
                    <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                <Col>
                    <Pagination
                        defaultCurrent= {1}
                        pageSize= {10}
                        onChange= {this.handlePagination}
                        current={this.state.currentPage}
                        total={ this.state.rolesCount}
                    />
                </Col>
                </Row>
                </div>
            </div>

        )
    }

    public render() {
        return (
            <div>
                {this.renderTopComp()}
                {this.renderModal()}
            </div>);
    }
}

export default withRouter(connect(mapStateToProps,
)(RolesPage));
