// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import { Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';

import {
    FirstIcon,
    BackJumpIcon,
    PreviousIcon,
    PlayIcon,
    PauseIcon,
    NextIcon,
    ForwardJumpIcon,
    LastIcon,
} from 'icons';
import { useTranslation } from 'react-i18next';

interface Props {
    playing: boolean;
    playPauseShortcut: string;
    nextFrameShortcut: string;
    previousFrameShortcut: string;
    forwardShortcut: string;
    backwardShortcut: string;
    onSwitchPlay(): void;
    onPrevFrame(): void;
    onNextFrame(): void;
    onForward(): void;
    onBackward(): void;
    onFirstFrame(): void;
    onLastFrame(): void;
    textTask: boolean;
}

/**
 * Creates component group to direct first frame, 10 frame back, 1 frame back, play whole frames/video, 1 frame next, 10 frame next, last frame
 *
 * @param props consists of playing, playPauseShortcut, nextFrameShortcut, previousFrameShortcut, forwardShortcut, backwardShortcut,textTask variables and onSwitchPlay, onPrevFrame, onNextFrame, onForward, onBackward, onFirstFrame, onLastFrame functions
 * @returns Col
 */
function PlayerButtons(props: Props): JSX.Element {
    const {
        playing,
        playPauseShortcut,
        nextFrameShortcut,
        previousFrameShortcut,
        forwardShortcut,
        backwardShortcut,
        onSwitchPlay,
        onPrevFrame,
        onNextFrame,
        onForward,
        onBackward,
        onFirstFrame,
        onLastFrame,
        textTask
    } = props;
    const { t } = useTranslation();
    return (
        <Col className='cvat-player-buttons'>
            {!textTask &&
            <Tooltip title={`${t('standard-workspace.goFirstFrame')}`}>
                <Icon component={FirstIcon} onClick={onFirstFrame} />
            </Tooltip>}
            {!textTask &&
            <Tooltip title={`${t('standard-workspace.backWithAStep')} ${backwardShortcut}`}>
                <Icon component={BackJumpIcon} onClick={onBackward} />
            </Tooltip>}
            <Tooltip title={`${t('standard-workspace.goBack')} ${previousFrameShortcut}`}>
                <Icon component={PreviousIcon} onClick={onPrevFrame} />
            </Tooltip>
            {!textTask ?
            <div> {!playing
                ?  (
                    <Tooltip title={`${t('standard-workspace.play')} ${playPauseShortcut}`}>
                        <Icon
                            component={PlayIcon}
                            onClick={onSwitchPlay}
                        />
                    </Tooltip>
                )
                : (
                    <Tooltip title={`${t('standard-workspace.pause')} ${playPauseShortcut}`}>
                        <Icon
                            component={PauseIcon}
                            onClick={onSwitchPlay}
                        />
                    </Tooltip>
                )}
            </div> : null }

            <Tooltip title={`${t('standard-workspace.goNext')} ${nextFrameShortcut}`}>
                <Icon component={NextIcon} onClick={onNextFrame} />
            </Tooltip>
            {!textTask &&
            <Tooltip title={`${t('standard-workspace.goNextWithAStep')} ${forwardShortcut}`}>
                <Icon component={ForwardJumpIcon} onClick={onForward} />
            </Tooltip>}
            {!textTask &&
            <Tooltip title={`${t('standard-workspace.goLastFrame')}`}>
                <Icon component={LastIcon} onClick={onLastFrame} />
            </Tooltip>}
        </Col>
    );
}

export default React.memo(PlayerButtons);
