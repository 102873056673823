import '../styles.scss';
import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Icon, Spin, Collapse, Divider, Comment, List, Form, Input, Modal, Avatar } from 'antd';
import i18n from '../../i18n';
import { AuthState } from '../../reducers/interfaces';
import { authorizedAsync } from '../../actions/auth-actions';
import NewMessageComponent from './new-message-component';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import getCore from '../../cvat-core-wrapper';
import MessageUsersList from './message-users-list';
import Text from 'antd/lib/typography/Text';
import UserSelectorMessaging from './user-selector-messaging';
import consts from 'consts';
const { AVATAR_MAP_CHAT } = consts;

import {
    SendIcon,
    MailIcon
} from 'icons';
const { Panel } = Collapse;
const { TextArea } = Input;

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns auth information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type MessagesPageProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class_component Renders users message page using defined event functions, templates and props
 */
class MessagesPageComponent extends React.PureComponent<MessagesPageProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            interactedUsers: [],
            userMessages: [],
            insideLoader: false,
            modalVisible: true,
            activeConversationUserId: null,
            activeConversationUser: null,
            newMessageContent: null,
            currentPage: 1,
            pageSize: 10,
            userLoader: false,
            conversationPanel:false,
            currentMessagePage: 1,
            numTotalUserMessages: 0,
            loadMoreVisible: false,
            scrollHeight: 0,
            width: 1920,
            height: 1080,
        }
        this.getInteractedUsers = this.getInteractedUsers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.openConversation = this.openConversation.bind(this);
        this.renderConversationPanel = this.renderConversationPanel.bind(this);
        this.getSelectedUser = this.getSelectedUser.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        })
    }

    public componentDidMount(): void {
        this.getInteractedUsers(true);
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }

    public componentWillUnmount(): void {
        this.props.verifyAuthorized();
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     *
     * @param init
     * @description set interacted users for current user in state
     */
    public getInteractedUsers(init: boolean) {
        if (init)
            this.setState({ userLoader: true })

        let promise = cvat.messages.getMessageUsersInteracted();
        promise.then(
            users => {
                this.setState({ interactedUsers: users, userLoader: false })
            }
        )
    }

    handleChange(page: number) {
        this.setState({
            currentPage: page,
        });
    };

    public getSelectedUser(user: any) {
        this.setState({
            activeConversationUserId: user
        });
    }

    /**
     *
     * @returns UserSelector Messaging component instance for selecting target user in message page
     */
    private renderTargetUserField(){
        return (
            <div >
                <span style={{fontSize: 10}}>* Aday yada onaylanmayan kullanıcılara mesaj gönderilemez.</span>
                <br></br>
                <UserSelectorMessaging
                    user={this.props.auth.user}
                    parentMethod={this.getSelectedUser}
                    onChange={(value: string): void => {}}
                    mode="Default"
                />
            </div>
        )
    }

    /**
     * @async
     * @param senderId
     * @description Get marked messages sended by sender id
     */
    async markMessages(senderId: string) {
        await cvat.messages.markMessages(senderId);
    }

    public renderLoader() {
        return <Spin className='cvat-spinner' />
    }

    /**
     *
     * @param key
     * @param self
     * @param keyChanged
     * @param page
     * @description Template for conversations
     *
     */
    public loadConversation(key: any, self: any, keyChanged: boolean, page: number) {
        if (key) {
            self.setState({
                insideLoader: true
            });
            const customPanelStyleOutgoing = {
                display: 'flex',
                justifyContent: 'flex-end',
                paddingLeft: '100px',
                paddingRight: '10px',
                //margin: '10px',
            };

            const customPanelStyleIncoming = {
                paddingLeft: '10px',
                paddingRight: '100px',
                //margin: '10px',
            };
            const customConvoBoxStyleIncoming={ borderRadius:'4px',
             maxWidth: 'fit-content',
             backgroundColor: 'rgba(209, 211, 226, 0.4)',
             minHeight: '30px',
             alignText: 'center'
            };
            const customConvoBoxStyleOutgoing={ borderRadius:'4px',
             maxWidth: 'fit-content',

             backgroundColor: 'rgba(93, 93, 249, 0.1)',
             minHeight: '30px',
             alignText: 'center',
            };

            let comments = new Array();
            let promise = cvat.messages.getMessages(key, page);
            promise.then(
                messages => {
                    for (let i = 0; i < messages.results.length; i++) {

                        var style = customPanelStyleIncoming
                        var convoBoxStyle=customConvoBoxStyleIncoming
                        var className="";
                        if (messages.results[i].sender.id.id == this.props.auth.user.id) {
                            style = customPanelStyleOutgoing
                            convoBoxStyle = customConvoBoxStyleOutgoing
                            className="message-outgoing";
                        }

                        comments.push(
                            <div>
                                {messages.results[i].senderSwitched&&
                                    <Comment style={style} className={className!=""?"message-convo-box"+" "+className:"message-convo-box"}
                                        content={<div style={className!=""?{display:"flex",flexDirection:"row-reverse",alignItems:"center"}:{}}><Avatar src={AVATAR_MAP_CHAT.get(messages.results[i].avatarId)}/>
                                        <a style={{fontWeight:"600",padding:"5px",color:"#5D5DF9"}}>{messages.results[i].sender.id.username}</a>
                                        {messages.results[i].dateFormatted}
                                        </div>}></Comment>}
                                <Comment style={style} className={className!=""?"message-convo-box"+" "+className:"message-convo-box"}
                                    content={<div style={convoBoxStyle}>
                                        <p style={{padding:"6px",marginLeft:"5px", marginRight:"5px",marginBottom:"0px"}}> {messages.results[i].content} </p>
                                    </div> }
                                ></Comment>
                            </div>
                        )
                    }
                    comments.reverse();
                    self.setState({
                        // userMessages: [...comments, ...this.state.userMessages],
                        activeConversationUserId: key,
                        insideLoader: false,
                        numTotalUserMessages: messages.count,
                        currentMessagePage: page
                    });
                    if (keyChanged) {
                        self.setState({
                            userMessages: comments
                        });
                        this.updateScroll(key);
                    }
                    else {
                        self.setState({
                            userMessages: [...comments, ...self.state.userMessages]
                        });
                        this.fixScroll(key);
                    }
                    if (self.state.userMessages.length >= messages.count) {
                        self.setState({
                            loadMoreVisible: false
                        });
                    }
                    else {
                        self.setState({
                            loadMoreVisible: true
                        });
                    }
                    this.markMessages(key);
                    this.getInteractedUsers(false);
                }
            )
        }
    }

    public updateScroll(value: string) {
        var element = document.getElementById("conversationPanel");
        element.scrollTop = element.scrollHeight;
    }

    public fixScroll(value: string) {
        var element = document.getElementById("conversationPanel");
        element.scrollTop = element.scrollHeight - this.state.scrollHeight;
    }


    /**
     *
     * @description create message api proxy call
     */
    handleAddComment = () => {

        if(this.state.activeConversationUserId == null) {
            Modal.error({content: "Kullanıcı seçiniz."});
            return;
        }
        if(this.state.newMessageContent == null || this.state.newMessageContent == "") {
            Modal.error({content: "Mesajınızı yazınız."});
            return;
        }
        let promise = cvat.messages.createMessage(this.state.activeConversationUserId, this.state.newMessageContent)
        promise.then(
            result => {
                this.loadConversation(this.state.activeConversationUserId, this, true, 1);
                if(this.state.modalVisible==true){
                    this.getInteractedUsers(true);
                    this.setState({ newMessageContent: "", conversationPanel:false, modalVisible:false  });
                }
                else{
                    this.setState({ newMessageContent: "", modalVisible:false  });
                }
            }
        )
    };

    handleNewMessageContent = e => {
        this.setState({
            newMessageContent: e.target.value,
        });
    };

    handleLoadMore = (self: any) => {
        var element = document.getElementById("conversationPanel");
        self.setState({ scrollHeight: element.scrollHeight })
        this.loadConversation(this.state.activeConversationUserId, self, false, self.state.currentMessagePage + 1,)
    };

    handleLoadConversation = (self: any, key: any) => {
        this.loadConversation(key, self, true, 1)
    };


    /**
     *
     * @returns Conversation Panel template
     */
    public renderConversationPanel() {
        const customPanelStyle = {
            marginBottom: 24,
            overflow: 'hidden',
            boxShadow: '0px 3px 20px #B3B3B329',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '0.5px solid #E3E6F0',
            marginTop:"-2px",
            borderRadius: "0px 0px 8px 8px",
            widthMin: "1058px",
            opacity: 1,
        };

        let loadMoreButtonStyle = { display: 'none', margin: 'auto' };
        if (this.state.loadMoreVisible && !this.state.modalVisible)
            loadMoreButtonStyle = { display: 'flex', margin: 'auto' };

        let spanTextBox = 23
        let spanSendIcon = 1
        if(this.state.width / this.state.height   < 1.7){
            spanTextBox = 22
            spanSendIcon = 2
        }

        return (
            <div>
                <div style={{backgroundColor:"#F2F4F8",
                        color:"#5A5C69",
                        height:"65px",
                        padding:"2%",
                        justifyContent:"flex-start",
                        alignItems:"center",
                        display:"flex",
                        margin:"auto",
                        border: "0.5px solid #E3E6F0",
                        borderRadius: "8px 8px 0px 0px",
                        width:"100%"}}
                   >
                        {this.state.activeConversationUser ? <Text className="cvat-text-color" style={{color:"#5A5C69"}}>Alıcı: {this.state.activeConversationUser.name}</Text>:<Text style={{color:"#5A5C69"}} className="cvat-text-color">Yeni Mesaj</Text>}
                </div>
                <div style={customPanelStyle}>
                    {<div id="conversationPanel" style={{ minHeight: '10vh', maxHeight: '50vh', overflowY: 'auto' }}>
                        {
                            <Button style={{...loadMoreButtonStyle, border:"none", color: "#0F123F", marginTop:'10px'}} ghost
                                className='button-default-tests-page'
                                onClick={(e) => { this.handleLoadMore(this); }}>
                                Daha Fazla Yükle
                            </Button>
                        }
                        {(this.state.modalVisible ? this.renderTargetUserField() : null)}

                        {(this.state.insideLoader ? this.renderLoader() : this.state.userMessages)}
                    </div>}
                    <div>
                        <br></br>
                        <Form.Item style={{ margin: "1.3%" }}>
                            <Input.Group >
                                <Col span={spanTextBox}>
                                    <Input.TextArea disabled={this.state.activeConversationUser && this.state.activeConversationUser.name == "chatbot"} data-tour="step-message-content" className="input-message-text" style={{ padding: "10px" }} placeholder="Mesajınızı yazınız..." autoSize value={this.state.newMessageContent} onChange={this.handleNewMessageContent} />
                                </Col>
                                <Col span={spanSendIcon}>
                                    <Button disabled={this.state.activeConversationUser && this.state.activeConversationUser.name == "chatbot"} data-tour="step-send" type="primary" block onClick={this.handleAddComment} style={{ opacity:"0.75", borderColor: "#0F123F", background: "#0F123F 0% 0%", borderRadius: "8px", padding: "12%", height: "fit-content",minWidth:"fit-content" }}><Icon component={SendIcon} /></Button>
                                </Col>
                            </Input.Group>
                        </Form.Item>
                    </div>
                </div>
            </div>
        )
    };

    public openConversation(user: any) {
        this.setState({
            modalVisible: false,
            conversationPanel:true,
            activeConversationUserId: user.id,
            activeConversationUser: user
        });
        this.handleLoadConversation(this, user.id)
    }

    changeModalState(visible: boolean) {
        this.setState({ conversationPanel:true,modalVisible: visible, activeConversationUser:null, activeConversationUserId:null, userMessages:null  })
    }

    /**
     *
     * @returns Messages page template
     */
    render() {
        let spanParameterUserList = 7
        let spanParameterDetails = 16
        let marginTop = 0
        if(this.state.width / this.state.height   < 1.7){
            spanParameterUserList = 24
            spanParameterDetails = 24
            marginTop = '5%'
        }

        return (
            <div className="approval-page">
                <Text className="cvat-title">Mesajlar</Text>
                <br></br>
                <br></br>
                {/* {this.renderModal()} */}
                <Row gutter={24} justify='space-between'>
                    <Col span={spanParameterUserList}>
                        <MessageUsersList auth={this.props.auth} loading={this.state.userLoader} isMobile={spanParameterUserList==24} users={this.state.interactedUsers} activeChatBox={this.state.activeConversationUser}  modalStatus={this.changeModalState.bind(this)} openConversation={this.openConversation}></MessageUsersList>
                    </Col>
                    <Col span={spanParameterDetails}  style={{marginTop: marginTop}}>
                        {this.state.conversationPanel&&this.renderConversationPanel()}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessagesPageComponent));
