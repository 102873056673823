// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import i18n from '../../i18n'
import { Col, Row } from 'antd';

export interface ResetData {
    email: string;
}

type ResetFormProps = {
    onSubmit(resetData: ResetData): void;
} & FormComponentProps;

/**
 * @class_component Reset Form Component for reset password page
 */
class ResetFormComponent extends React.PureComponent<ResetFormProps> {

    /**
     *
     * @param e form submit event
     * @description form field validataion and submit
     */
    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
        } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                onSubmit(values);
            }
        });
    };

    /**
     *
     * @returns Email field template
     */
    private renderEmailField(): JSX.Element {
        const { form } = this.props;
        return (
            <Form.Item>
                <Row gutter={8}>
                    <Col>
                        {form.getFieldDecorator('email', {
                            rules: [{
                                type: 'email',
                                message: 'Geçersiz e-posta adresi!',
                            }, {
                                required: true,
                                message: 'Lütfen e-posta adresinizi girin',
                            }],
                        })(
                            <Input
                                autoComplete='email'
                                prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder='E-posta adresi'
                            />,
                        )}
                    </Col>
                </Row>
            </Form.Item>
        );
    }

    /**
     *
     * @returns Reset Password Template
     */
    public render(): JSX.Element {

        return (
            <Form onSubmit={this.handleSubmit} className='login-form'>
                {this.renderEmailField()}

                <Row gutter={24}>
                    <Col className="gutter-row" span={12} >
                        <Button
                            id="back"
                            type='primary'
                            onClick={
                                () => {window.location.assign('login')}
                            }
                            className='login-form-button'
                            style={{lineHeight:'40px', paddingBottom:"40px"}}
                        >
                            {i18n.t('keywords.back')}
                        </Button>
                    </Col>
                    <Col className="gutter-row" span={12} >
                        <Form.Item>
                            <Button
                                id="sendEmail"
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                style={{ lineHeight:'40px',paddingBottom:"40px"}}
                            >
                                {i18n.t('register-form.sendEmail')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        );
    }
}

export default Form.create<ResetFormProps>()(ResetFormComponent);
