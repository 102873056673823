import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Tabs, Progress, Spin, Input, List } from 'antd';
import 'antd/dist/antd.css';
import getCore from '../cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import tr_TR from 'antd/lib/locale/tr_TR';

import i18n from '../i18n';
import Column from 'antd/lib/table/Column';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';

const { Panel } = Collapse;

const { TabPane } = Tabs;
const cvat = getCore();

let backendEndPoint = String(cvat.config.backendAPI)


class AnnotationEvaluationPage extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPageUser: 1,
            currentPageTask: 1,
            totalJobCount: 0,
            isTasks: false,
            users: [],
            userJobs: [],
            filter:"",
            tasks: [],
            taskJobs: [],
            insideLoader: false,
            totalUserCount: 0,
            totalTaskCount: 0,
        }
        this.getUsers(1);
    }

    /**
     * @async
     * @param pageNum
     * @param filter
     * @returns filtered users with params
     */
    async getUsers(pageNum: any, filter?:any) {
        let users;
        if(filter){
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, null, null,  "False", filter);
        }
        else{
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, null, null, "False");
        }
        this.setState({
            totalUserCount: users.count,
            currentPageUser: pageNum,
            users: users.users
        })
        return users;
    }

    /**
     * @async
     * @param pageNum
     * @param filter
     * @returns tasks filtered with params
     */
    async getTasks(pageNum = 1,  filter?:any) {
        const tasks = await cvat.tasks.getAnnotaTasks(["proper", "allocated", "completed"], null, pageNum,filter);
        this.setState({
            tasks: tasks,
            currentPageTask: pageNum,
            totalTaskCount: tasks.count
        })
        return tasks;
    }

    /**
     *
     * @param state
     * @returns level number or Turkish string
     */
    private getLevel(state: String) {
        switch (state) {
            case null:
                return '0';
            case 'freshman':
                return '1';
            case 'sophomore':
                return '2';
            case 'junior':
                return '3';
            case 'senior':
                return '4';
            case 'master':
                return '5';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    /**
     *
     * @param state
     * @returns Turkish state
     */
    private getStatus(state: String) {
        switch (state) {
            case null:
                return 'Uzman Değil';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    /**
     * @deprecated
     * @param status
     * @param jobs
     * @returns Stats template
     */
    private determineStatusPercentage(status: string, jobs?: any[]) {
        if (jobs) {
            let finishedJobCount = 0;
            for (let i = 0; i < jobs[0].length; i++) {
                if (jobs[0][i].status === "completed")
                    finishedJobCount++;
            }
            return finishedJobCount+"/"+jobs[0].length;
        }
        if (status === "annotation")
            return <span>tamamlanmadı</span>;
        else if (status === "validation")
            return <span>tamamlanmadı</span>;
        else
            return <span style={{color:"#61C200"}}>tamamlandı</span>;
    }

    /**
     *
     * @param key
     * @param taskOrUserTab
     * @param self
     * @description get user or task jobs according to taskOrUserTab param, constructs job table template, updates state
     */
    public callback(key: any, taskOrUserTab: any, self: any) {
        if(key){
            self.setState({
                insideLoader: true
            })
            let promise = taskOrUserTab === 0 ? cvat.jobs.getUserJobs(key) : cvat.jobs.getTaskJobs(key);
            promise.then(
                jobs => {
                    const jobTable =( <ConfigProvider locale={tr_TR}>
                        <List
                        className="annotation-evaluation-list"
                            size="small"
                            grid={{column:3, gutter:8}}
                            itemLayout="vertical"
                            pagination={{size:"small", defaultPageSize: 3, position: 'bottom',hideOnSinglePage:true}}
                            dataSource={jobs}
                            renderItem={item => (
                                <List.Item >
                                    <div style={{padding:"4%",background: "#F8F9FC 0% 0% no-repeat padding-box", borderRadius: "8px"}}>
                                        <Row>
                                            <Text strong>Etiketleme Puanı: </Text> <span>{(item.score === 0 ? "-" :((item.score-0.5)*100).toPrecision(3)+"%")}</span>
                                        </Row>
                                        <br/>
                                        <Row >
                                            <Text strong>Görev Durumu: </Text>{self.determineStatusPercentage(item.status)}
                                        </Row>
                                        <br/>
                                        <Row type="flex" justify="center">
                                            <img src={backendEndPoint + '/tasks/' + item.task_id + '/data?type=preview'} style={{width:"100%",height:"15em"}} />
                                        </Row>
                                        <Row type="flex" justify="space-between" style={{marginTop:"5px"}}>
                                        {
                                            taskOrUserTab===0 ?<Text>Görev#{item.task_id}</Text>:<Text>Kullanıcı: {item.username}</Text>}
                                            <Text>İş#{item.id}</Text>
                                        </Row>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </ConfigProvider>
                    /* const jobTable =(
                        <div>
                        <Table style={{ backgroundColor: "#f0f2f5", borderRadius: "8px" }}
                            rowKey={record => record.id}
                            size="small"
                            dataSource={jobs}
                            pagination={false}
                        >
                            <Column title={i18n.t('annotation-eval-page.jobID')} dataIndex="id" />
                            (
                                {
                                taskOrUserTab===0 ?
                                <Column title={i18n.t('annotation-eval-page.taskID')} dataIndex="task_id" />:
                                <Column title={i18n.t('admin-page.userName')} dataIndex="username" />
                                }

                            )
                            <Column title={i18n.t('annotation-eval-page.preview')} render={(record) =>
                                <img src={backendEndPoint + '/tasks/' + record.task_id + '/data?type=preview'} style={{ width: "15em", height: "10em" }} />
                            }
                            />
                            <Column title={i18n.t('annotation-eval-page.annotationScore')} render={(record) => <>
                                {(record.score === 0 ? "-" : record.score)}</>
                            } />
                            <Column title={i18n.t('keywords.status')} render={(record) =>
                                <Progress type="circle" percent={self.determineStatusPercentage(record.status)} />
                            } />
                        </Table>
                    </div> */
                    )
                    if(taskOrUserTab === 0){
                        self.setState({
                            userJobs: jobTable,
                        })
                    }else{
                        self.setState({
                            taskJobs: jobTable,
                        })
                    }

                    self.setState({
                        insideLoader: false
                    })
                }
            )
        }
    }

    /**
     *
     * @returns loader Spin template
     */
    public renderLoader(){
        return <Spin style={{ margin: '0 50%' }} />
    }

    /**
     *
     * @param value
     * @description get users api proxy call with value on page change
     */
    handleChangeUsers = value => {
        this.getUsers(value);
    };

    /**
     *
     * @param value
     * @description get tasks api proxy call with value on page change
     */
    handleChangeTasks = value => {
        this.getTasks(value);
    }


    /**
     * @async
     * @param searchString
     * @description get tasks or users with search string, set state on searchString with param
     */
    public async setSearchFilter(searchString: any) {
        this.setState({
            filter: searchString,
            currentPage: 1
        });
        if(this.state.isTasks){
            const tasksAsync = this.getTasks(1,{"search": searchString})
        }
        else{
            const usersAsync = this.getUsers(1,{"search": searchString});
        }
    }

    /**
     *
     * @returns user list template
     */
    public renderUserList(){
        const panels = new Array();

        for(const user of this.state.users){
            panels.push(
                <Panel style={{background: "#F2F4F8 0% 0% no-repeat padding-box",boxShadow: "0px 3px 20px #B3B3B329",borderRadius: "8px 8px 0px 0px"}} header={<Row>
                        <Text strong>Kullanıcı: </Text> {user.id.username}
                        <Text style={{marginLeft:"35px"}} strong>Kullanıcı Seviyesi: </Text> {this.getLevel(user.level)}
                        <Text style={{marginLeft:"35px"}} strong>Kullanıcı Durumu: </Text> {this.getStatus(user.expert)}  </Row>}
                    key={user.id.id}>
                    {(this.state.insideLoader  ? this.renderLoader() : this.state.userJobs)}
                </Panel>
            )
        }
        return (
            <Row type="flex">
                <Col span={24}>
                <Collapse className="edy-collapse" bordered={false} accordion={true} onChange={(key) => this.callback(key, 0, this)}>
                    {this.state.totalUserCount!==0 ?panels: <h3>Sonuç Bulunamadı</h3>}
                </Collapse>
                </Col>
                <Col style={{marginLeft: "40%",marginTop:"15px"}} md={22} lg={18} xl={16} xxl={14}>
                    <ConfigProvider locale ={trTR}>
                        <Pagination
                            defaultCurrent={1}
                            pageSize={10}
                            onChange={this.handleChangeUsers}
                            current={this.state.currentPageUser}
                            total={this.state.totalUserCount}
                            showQuickJumper
                        />
                    </ConfigProvider>
                </Col>
            </Row>)
    }

    /**
     *
     * @returns task kist template
     */
    public renderTaskList(){
        const panels = new Array();

        for(const task of this.state.tasks){
            panels.push(
                <Panel header={<Row><Text strong>Görev#{task.id.id}: </Text>{task.id.name}</Row>} key={task.id.id}>
                    {(this.state.insideLoader  ? this.renderLoader() : this.state.taskJobs)}
                </Panel>
            )
        }
        return (
            <Row type="flex">
            <Col span={24}>

            <Collapse className="edy-collapse" bordered={false} style={{background: "#F2F4F8 0% 0% no-repeat padding-box",  borderRadius: "8px 8px 0px 0px"}}  accordion={true} onChange={(key) => this.callback(key, 1, this)}>
                {this.state.totalTaskCount!==0 ?panels: <h3>Sonuç Bulunamadı</h3>}
            </Collapse>
            </Col>
            <Col style={{marginLeft: "40%",marginTop:"15px"}} md={22} lg={18} xl={16} xxl={14}>
                <ConfigProvider locale ={trTR}>
                    <Pagination
                        defaultCurrent={1}
                        pageSize={10}
                        onChange={this.handleChangeTasks}
                        current={this.state.currentPageTask}
                        total={this.state.totalTaskCount}
                        showQuickJumper
                    />
                </ConfigProvider>
            </Col>
            </Row>
        )
    }

    /**
     *
     * @param key
     * @description change between task view and user view according to param
     */
    public changeViewType(key: any){
        switch (key) {
            case "1":
                this.getUsers(1).then(
                    users => {
                        this.setState({
                             isTasks: false,
                             filter:""
                        })
                    }
                )
                break;
            case "2":
                this.getTasks().then(
                    tasks => {
                        this.setState({
                             isTasks: true,
                             filter:""
                        })
                    }
                )
                break;
        }

    }

    /**
     *
     * @returns Annotation Evaluation Page template using class functions
     */
    public render() {
        const buttonStyle={
            color:"#F2F4F8",
            background: "#0F123F 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #B3B3B329",
            border: "1px solid #707070",
            borderRadius: "8px",
            opacity: "0.75",
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('header.annotationEvaluation')}</Text>
                <div style={{paddingTop:"15px"}}>
                    <Row type="flex" justify="center" gutter={[48,0]}>
                        <Col span={8}/>
                        <Col style={{display:"flex",justifyContent:"space-around"}} span={8}>
                            <Col span={8}>
                                <Button  className="tasks-button"  style={!this.state.isTasks?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("1");}}>{i18n.t('admin-page.users')}</Button>
                            </Col>
                            <Col span={8}>
                                <Button  className="tasks-button"  style={this.state.isTasks?{...buttonStyle, marginTop: "30px"}:{marginTop: "30px"}} onClick={(e) => { this.changeViewType("2");}}>{i18n.t('header.tasks')}</Button>
                            </Col>
                        </Col>
                        <Col span={8}>
                            <Col span={8}/>
                            <Col span={16}>
                                <Input.Search
                                    style={{marginTop: "30px"}}
                                    onSearch={this.setSearchFilter.bind(this)}
                                    placeholder={i18n.t('keywords.search')}
                                    value={this.state.filter}
                                    onChange={(e)=>{this.setState({filter:e.target.value})}}
                                    />
                            </Col>
                        </Col>
                    </Row>
                </div>
                {/* <Tabs size="large" style={{ marginTop: "30px" }} defaultActiveKey="1" onChange={() => this.changeViewType(this)}>
                    <TabPane tab={i18n.t('admin-page.users')} key="1">
                    </TabPane>
                    <TabPane tab={i18n.t('header.tasks')} key="2">
                    </TabPane>
                </Tabs> */}
                <div style={{ margin: '4em 2em 18em' }}>
                    {(this.state.isTasks ? this.renderTaskList() : this.renderUserList())}
                </div>
            </div>
        )
    }
}

export default withRouter(connect(
)(AnnotationEvaluationPage));
