import React from 'react';
import { connect } from 'react-redux';
import { registerAnnotaAsync } from '../../actions/auth-actions';
import RegisterPageComponent from '../../components/annota-register-page/register-page';
import { CombinedState } from '../../reducers/interfaces';

interface StateToProps {
    fetching: boolean;
}

interface DispatchToProps {
    onRegister: (username: string, firstName: string,
        lastName: string, email: string,
        password1: string, password2: string,
        phoneNumber: string, emailCode: string, smsCode: string,
        regId: string, birthDate: Date,
        postCode: string, address: string, city:string, town: string,
        isWorking: string, workplace: string, experienceExplanation: string,
        certificateExplanation: string, plExplanation: string, aiExplanation: string,
        othersExplanation: string, educationInfo: JSON,tckn: string, tcSerial: string,
        base64Photo: string, regType: string, captchaCallback: () => void) => void;
}

/**
 *
 * @param state -> combined state containing auth details
 * @returns fetching information mapped from CombinedState
 */
function mapStateToProps(state: CombinedState): StateToProps {
    return {
        fetching: state.auth.fetching,
    };
}

/**
 *
 * @param dispatch
 * @returns onRegister action dispatching function
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onRegister: (...args): void => dispatch(registerAnnotaAsync(...args)),
    };
}

/**
 *
 * @param props
 * @returns RegisterPageComponent
 * @description Passes props to RegisterPageComponent
 */
function RegisterPageContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <RegisterPageComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RegisterPageContainer);
