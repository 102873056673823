// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Tooltip from 'antd/lib/tooltip';
import Select from 'antd/lib/select';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import i18n from '../../i18n'

import patterns from 'utils/validation-patterns';
import {
    equalArrayHead,
    idGenerator,
    Label,
    Attribute,
} from './common';


export enum AttributeType {
    SELECT = 'SELECT',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

type Props = FormComponentProps & {
    label: Label | null;
    onSubmit: (label: Label | null) => void;
    textTask: any;
};

class LabelForm extends React.PureComponent<Props, {}> {
    private continueAfterSubmit: boolean;

    constructor(props: Props) {
        super(props);
        this.continueAfterSubmit = false;
    }

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();

        const {
            form,
            label,
            onSubmit,
        } = this.props;

        form.validateFields((error, formValues): void => {
            if (!error) {
                onSubmit({
                    name: formValues.labelName,
                    id: label ? label.id : idGenerator(),
                    attributes: formValues.keys.map((key: number, index: number): Attribute => {
                        let attrValues = formValues.values[key];
                        if (!Array.isArray(attrValues)) {
                            if (formValues.type[key] === AttributeType.NUMBER) {
                                attrValues = attrValues.split(';');
                            } else {
                                attrValues = [attrValues];
                            }
                        }

                        attrValues = attrValues.map((value: string) => value.trim());

                        return {
                            name: formValues.attrName[key],
                            input_type: formValues.type[key],
                            mutable: formValues.mutable[key],
                            id: label && index < label.attributes.length
                                ? label.attributes[index].id : key,
                            values: attrValues,
                        };
                    }),
                });

                form.resetFields();

                if (!this.continueAfterSubmit) {
                    onSubmit(null);
                }
            }
        });
    };

    /**
     * Add attribute
     */
    private addAttribute = (): void => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(idGenerator());
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    /**
     * Remove
     * @param key number
     */
    private removeAttribute = (key: number): void => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter((_key: number) => _key !== key),
        });
    };

    /**
     * Render attribute name input
     * @param key number
     * @param attr Attribute
     * @returns Col
     */
    private renderAttributeNameInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.name : '';
        const { form } = this.props;

        return (
            <Col span={5}>
                <Form.Item hasFeedback>
                    {form.getFieldDecorator(`attrName[${key}]`, {
                        initialValue: value,
                        rules: [{
                            required: true,
                            message: i18n.t('messages.name'),
                        }, {
                            pattern: patterns.validateAttributeName.pattern,
                            message: patterns.validateAttributeName.message,
                        }],
                    })(<Input disabled={locked} placeholder={i18n.t('keywords.name')} />)}
                </Form.Item>
            </Col>
        );
    }

    private renderAttributeTypeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const type = attr ? attr.input_type.toUpperCase() : AttributeType.SELECT;
        const { form } = this.props;

        return (
            <Col span={4}>
                <Form.Item>
                        { form.getFieldDecorator(`type[${key}]`, {
                            initialValue: type,
                        })(
                            <Select disabled={locked}>
                                <Select.Option value={AttributeType.SELECT}>
                                    {i18n.t('label-editor.select')}
                                </Select.Option>
                                <Select.Option value={AttributeType.RADIO}>
                                    {i18n.t('label-editor.radio')}
                                </Select.Option>
                                <Select.Option value={AttributeType.CHECKBOX}>
                                    {i18n.t('label-editor.checkbox')}
                                </Select.Option>
                                <Select.Option value={AttributeType.TEXT}>
                                    {i18n.t('label-editor.text')}
                                </Select.Option>
                                <Select.Option value={AttributeType.NUMBER}>
                                    {i18n.t('label-editor.number')}
                                </Select.Option>
                            </Select>,
                        )}
                </Form.Item>
            </Col>
        );
    }

    private renderAttributeValuesInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const existedValues = attr ? attr.values : [];
        const { form } = this.props;

        const validator = (_: any, values: string[], callback: any): void => {
            if (locked && existedValues) {
                if (!equalArrayHead(existedValues, values)) {
                    callback(i18n.t('label-editor.onlyNewValues'));
                }
            }

            for (const value of values) {
                if (!patterns.validateAttributeValue.pattern.test(value)) {
                    callback(i18n.t('label-editor.invalidAttributeValue'), {value: value});
                }
            }

            callback();
        };

        return (
            <Tooltip title={i18n.t('label-editor.enterForNewValue')}>
                <Form.Item>
                    { form.getFieldDecorator(`values[${key}]`, {
                        initialValue: existedValues,
                        rules: [{
                            required: true,
                            message: i18n.t('messages.value'),
                        }, {
                            validator,
                        }],
                    })(
                        <Select
                            mode='tags'
                            dropdownMenuStyle={{ display: 'none' }}
                            placeholder={i18n.t('label-editor.attributeValues')}
                        />,
                    )}
                </Form.Item>
            </Tooltip>
        );
    }

    private renderBooleanValueInput(key: number, attr: Attribute | null): JSX.Element {
        const value = attr ? attr.values[0] : 'false';
        const { form } = this.props;

        return (
            <Tooltip title={i18n.t('messages.defaultValue')}>
                <Form.Item>
                    { form.getFieldDecorator(`values[${key}]`, {
                        initialValue: value,
                    })(
                        <Select>
                            <Select.Option value='false'> {i18n.t('keywords.false')} </Select.Option>
                            <Select.Option value='true'> {i18n.t('keywords.true')} </Select.Option>
                        </Select>,
                    )}
                </Form.Item>
            </Tooltip>
        );
    }

    private renderNumberRangeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.values.join(';') : '';
        const { form } = this.props;

        const validator = (_: any, strNumbers: string, callback: any): void => {
            const numbers = strNumbers
                .split(';')
                .map((number): number => Number.parseFloat(number));
            if (numbers.length !== 3) {
                callback(i18n.t('messages.invalidInput'));
            }

            for (const number of numbers) {
                if (Number.isNaN(number)) {
                    callback(i18n.t('messages.invalidInput'));
                }
            }

            if (numbers[0] >= numbers[1]) {
                callback(i18n.t('messages.invalidInput'));
            }

            if (+numbers[1] - +numbers[0] < +numbers[2]) {
                callback(i18n.t('messages.invalidInput'));
            }

            callback();
        };

        return (
            <Form.Item>
                { form.getFieldDecorator(`values[${key}]`, {
                    initialValue: value,
                    rules: [{
                        required: true,
                        message: i18n.t('messages.range'),
                    }, {
                        validator,
                    }],
                })(
                    <Input disabled={locked} placeholder={i18n.t('label-editor.minMaxStep')} />,
                )}
            </Form.Item>
        );
    }

    private renderDefaultValueInput(key: number, attr: Attribute | null): JSX.Element {
        const value = attr ? attr.values[0] : '';
        const { form } = this.props;

        return (
            <Form.Item>
                { form.getFieldDecorator(`values[${key}]`, {
                    initialValue: value,
                })(
                    <Input placeholder={i18n.t('keywords.defaultValue')} />,
                )}
            </Form.Item>
        );
    }

    private renderMutableAttributeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.mutable : false;
        const { form } = this.props;

        return (
            <Form.Item>
                <Tooltip title={i18n.t('label-editor.mutableTooltip')}>
                    { form.getFieldDecorator(`mutable[${key}]`, {
                        initialValue: value,
                        valuePropName: 'checked',
                    })(
                        <Checkbox disabled={locked}> {i18n.t('label-editor.mutable')} </Checkbox>,
                    )}
                </Tooltip>
            </Form.Item>
        );
    }

    private renderDeleteAttributeButton(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;

        return (
            <Form.Item>
                <Tooltip title={i18n.t('label-editor.deleteAttribute')}>
                    <Button
                        type='link'
                        size="large"
                        className='cvat-delete-attribute-button'
                        disabled={locked}
                        onClick={(): void => {
                            this.removeAttribute(key);
                        }}
                    >
                        <Icon type='close-circle' />
                    </Button>
                </Tooltip>
            </Form.Item>
        );
    }

    private renderAttribute = (key: number, index: number): JSX.Element => {
        const {
            label,
            form,
        } = this.props;

        const attr = (label && index < label.attributes.length
            ? label.attributes[index]
            : null);

        return (
            <Form.Item key={key}>
                <Row type='flex' justify='space-between' align='middle'>
                    { this.renderAttributeNameInput(key, attr) }
                    { this.renderAttributeTypeInput(key, attr) }
                    <Col span={6}>
                        {((): JSX.Element => {
                            const type = form.getFieldValue(`type[${key}]`);
                            let element = null;
                            if ([AttributeType.SELECT, AttributeType.RADIO].includes(type)) {
                                element = this.renderAttributeValuesInput(key, attr);
                            } else if (type === AttributeType.CHECKBOX) {
                                element = this.renderBooleanValueInput(key, attr);
                            } else if (type === AttributeType.NUMBER) {
                                element = this.renderNumberRangeInput(key, attr);
                            } else {
                                element = this.renderDefaultValueInput(key, attr);
                            }

                            return element;
                        })()}
                    </Col>
                    <Col span={5}>
                        { this.renderMutableAttributeInput(key, attr) }
                    </Col>
                    <Col span={2}>
                        { this.renderDeleteAttributeButton(key, attr) }
                    </Col>
                </Row>
            </Form.Item>
        );
    };

    private renderLabelNameInput(): JSX.Element {
        const {
            label,
            form,
        } = this.props;
        const value = label ? label.name : '';
        const locked = label ? label.id >= 0 : false;

        return (
            <Col span={10}>
                <Form.Item hasFeedback>
                    {form.getFieldDecorator('labelName', {
                        initialValue: value,
                        rules: [{
                            required: true,
                            message: i18n.t('messages.name'),
                        }, {
                            pattern: patterns.validateAttributeName.pattern,
                            message: patterns.validateAttributeName.message,
                        }],
                    })(<Input disabled={locked} placeholder={i18n.t('label-editor.labelName')} />)}
                </Form.Item>
            </Col>
        );
    }

    private renderNewAttributeButton(): JSX.Element {
        return (
            <Col style={{display:"flex"}} span={3}>
                <Form.Item>
                    <Button size="large" className="cvat-constructor-viewer-new-item" type='ghost' onClick={this.addAttribute}>
                        {i18n.t('label-editor.addAttribute')}
                        <Icon type='plus' />
                    </Button>
                </Form.Item>
            </Col>
        );
    }

    private renderDoneButton(): JSX.Element {
        return (
            <Col>
                <Tooltip title={i18n.t('label-editor.doneTooltip')}>
                    <Button
                        className="button-label"
                        id="button_done"
                        style={{ width: '150px' }}
                        type='primary'
                        htmlType='submit'
                        onClick={(): void => {
                            this.continueAfterSubmit = false;
                        }}
                    >
                        Ekle
                    </Button>
                </Tooltip>
            </Col>
        );
    }

    private renderContinueButton(): JSX.Element {
        const { label } = this.props;

        return (
            label ? <div />
                : (
                    <Col offset={1}>
                        <Tooltip title={i18n.t('label-editor.saveAndContinueTooltip')}>
                            <Button
                                className="button-label"
                                style={{ width: '150px' }}
                                type='primary'
                                htmlType='submit'
                                onClick={(): void => {
                                    this.continueAfterSubmit = true;
                                }}
                            >
                                {i18n.t('label-editor.saveAndContinue')}
                            </Button>
                        </Tooltip>
                    </Col>
                )
        );
    }

    private renderCancelButton(): JSX.Element {
        const { onSubmit } = this.props;

        return (
            <Col offset={1}>
                <Tooltip title={i18n.t('label-editor.cancelTooltip')}>
                    <Button
                        className="button-label"
                        style={{ width: '150px' }}
                        type='danger'
                        onClick={(): void => {
                            onSubmit(null);
                        }}
                    >
                        {i18n.t('keywords.cancel')}
                    </Button>
                </Tooltip>
            </Col>
        );
    }

    public render(): JSX.Element {
        const {
            label,
            form,
            textTask
        } = this.props;

        form.getFieldDecorator('keys', {
            initialValue: label
                ? label.attributes.map((attr: Attribute): number => attr.id)
                : [],
        });

        const keys = form.getFieldValue('keys');
        const attributeItems = keys.map(this.renderAttribute);

        return (
            <Form                 className="new-faq"
            onSubmit={this.handleSubmit}>
                <Row justify='start' align='middle'>
                    { this.renderLabelNameInput() }
                    &nbsp;&nbsp;
                    { !textTask ? this.renderNewAttributeButton() : null}
                </Row>
                { attributeItems.length > 0
                    && (
                        <Row type='flex' justify='start' align='middle'>
                            <Col>
                                <Text>{i18n.t('label-editor.attributes')}</Text>
                            </Col>
                        </Row>
                    )}
                { attributeItems.reverse() }
                <Row type='flex' justify='start' align='middle'>
                    { this.renderDoneButton() }
{/*                     { this.renderContinueButton() }
 */}                    { this.renderCancelButton() }
                </Row>
            </Form>
        );
    }
}

export default Form.create<Props>()(LabelForm);
