/*
* Copyright (C) 2019 Intel Corporation
* SPDX-License-Identifier: MIT
*/

module.exports = {
    backendAPI: 'http://localhost:8080/api/v1',
    backendAPIHitachi: 'http://localhost:3006',
    backendAPIDoccano: 'http://localhost:8000',
    proxy: false,
    doccanoUIRedirectURL: 'http://localhost:4000',
    reactAppEnv: 'development',
    hitachiURL: 'http://localhost:3006'
};
