const messageSteps = [
    {
        selector:'[data-tour="step-new-message"]',
        content:"Buraya basarak yeni mesaj oluşturabilirsiniz. ",
        action: node => {
            node.click();
          },
    },
    {
        selector:'[data-tour="step-recipient"]',
        content:"Mesaj yazmak istediğiniz kullanıcının adının en az ilk üç harfini girerek kullanıcıyı aratabilirsiniz. "
    },
    {
        selector:'[data-tour="step-message-content"]',
        content:"Mesajınızı buraya girebilirsiniz. "
    },
    {
        selector:'[data-tour="step-send"]',
        content:"Buraya basarak mesajınızı gönderebilirsiniz. "
    },
    {
        selector:'[data-tour="step-user-list"]',
        content:"Yaptığınız konuşmalar burada listelenir. Herhangi bir konuşmaya basarak konuşmanızı görüntüleyebilir, konuşmaya devam edebilirsiniz. "
    },


  ];

  export default messageSteps;