import React,{ useState, useEffect, useRef } from 'react';
import './styles.scss';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Button,
    Form,
    Modal,
    Select
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getCore from 'cvat-core-wrapper';
import UserSelectorMessaging from './user-selector-messaging';

const core = getCore();
interface Props {
    user: any;
    visible:boolean;
    setInvisible:()=>void;
    updateInteractedUsers:(init:boolean)=>void;
    recipient?:any;
}
/**
 * @class_component NewMessageComponent for messages-page
 */
class NewMessageComponent extends React.PureComponent<Props&FormComponentProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            charCount: 0,
            userToSend: "",
            content: ""
        };
        this.renderTargetUserField =this.renderTargetUserField.bind(this);
        this.getSelectedUser = this.getSelectedUser.bind(this);
        this.renderDefaultRecipientField = this.renderDefaultRecipientField.bind(this);

    }

    evalCharCount = e => {
        this.setState({
          charCount: e.target.value.length,
          content: e.target.value,
        });
    };

    private renderMessageField(){
        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('message', {
                        rules: [{
                            required: true,
                            message: 'Lütfen mesajınızı girin',
                        }],
                    })(
                        <TextArea
                            className="input-message-text"
                            id="messageBody"
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            placeholder='Mesajınız'
                            onChange = {this.evalCharCount}
                        />
                    )}
                </Form.Item>
                <p  style={{float:'right'}}>Karakter Sayısı: {this.state.charCount}/4000</p>
            </div>
        );
    }

    public getSelectedUser(user: any) {
        console.log(user)
        this.setState({
            userToSend: user,
        });
        this.props.form.setFieldsValue({to:user})
    }

    private renderTargetUserField(){
        const { form } = this.props;
        return (
            <Form.Item id="recipient" label="Alıcı">
                {form.getFieldDecorator('to', {
                        rules: [{
                            required: true,
                            message: 'Lütfen alıcı seçin',
                        }],
                    })(<UserSelectorMessaging
                        user={this.props.user}
                        parentMethod={this.getSelectedUser}
                        onChange={(value: string): void => {
                        }}
                        mode="Default"
                />)
                }
            </Form.Item>
        );
    }

    /**
     * @async
     * @description create message api proxy call
     */
   private async handleSendMessage(){
        if(this.props.recipient)
            this.props.form.setFieldsValue({to:this.props.recipient.id})
        let resp = await core.messages.createMessage(this.props.form.getFieldValue("to"), this.props.form.getFieldValue("message"))
        let response = resp.status;
        console.log(resp)
        if(response != "Başarılı"){
            Modal.error({
                title: 'Hata!',
              content: response,
            });
        }
        else{
             Modal.success({
                okButtonProps:{id:"ok"},
              title: 'Başarılı!',
              content: resp.message,
            });
            this.setState({user:""});
            this.props.setInvisible();
            this.props.form.setFieldsValue({to:""});
            this.props.updateInteractedUsers(false);
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (err) {
            console.log('Received values of form: ', values);
          }
          else {
            console.log('Received values of form: ', values);

            this.handleSendMessage();
          }
        });
    };

    /**
     *
     * @returns Recipient selection template
     */
    renderDefaultRecipientField(){
        const { form,recipient } = this.props;
        console.log(this.props.recipient.username)
        let recId=recipient.id
        return (
            <Form.Item label="Alıcı">
                {form.getFieldDecorator('to', {
                    initialValue:recipient.username,
                })( <Select disabled>
                        <Select.Option key={this.props.recipient.id} value={this.props.recipient.username}>
                            {this.props.recipient.username}
                        </Select.Option>
                    </Select>)
                }

            </Form.Item>
        )
    }

    /**
     *
     * @returns New Message template
     */
    public render() {
        const{recipient} = this.props;
        return (
        <div>
            <Form>
            <Modal
                maskClosable={false}
                title="Yeni Mesaj Oluştur"
                visible={this.props.visible}
                onOk={this.handleSubmit}
/*                 okButtonProps={{id:"send",htmlType:'submit' }}
 */               /*  okText="Gönder"
                cancelText="İptal" */
                onCancel={()=>{
                    this.props.setInvisible();
                    this.props.form.setFieldsValue({to:""});
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" className="button-send" onClick={(e)=>{this.props.setInvisible();this.props.form.setFieldsValue({to:""});}}>
                      İptal
                    </Button>,
                    <Button key="submit" className="button-send" type="primary" onClick={this.handleSubmit}>
                      Gönder
                    </Button>,
                ]}
            >

                {recipient? this.renderDefaultRecipientField():this.renderTargetUserField()}

                {this.renderMessageField()}

            </Modal>
            </Form>
        </div>
        );
    }
}

export default Form.create<FormComponentProps&Props>()(NewMessageComponent);
