import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Pagination, Table, Icon, Modal } from 'antd';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'
import Column from 'antd/lib/table/Column';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';
import Text from 'antd/lib/typography/Text';
import { NoDataVector } from 'icons';

const cvat = getCore();
let backendEndPoint = String(cvat.config.backendAPI)
let backendRoot = String(cvat.config.root)
let testViews = new Array();
let TestList = <div></div>
let currentTasks = new Array();
class TestEvaluationPage extends Component {
    constructor(props: any) {
        super(props);

        this.state = {
            currentPage: 1,
            totalTestCount: 0,
            width: 1920,
            height: 1080,
            imageMap: {}
        }

        this.prepareCardViews = this.prepareCardViews.bind(this);
        this.handleResize = this.handleResize.bind(this);
        let self = this;
        const testsAsync = self.getTests(1);
        testViews = new Array()
        testsAsync.then(self.prepareCardViews.bind(self));
    }
    public componentDidMount(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    }
    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }
    /**
     * change window width and height
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        },()=>
            this.prepareCardViews(currentTasks)
        )
    }

    /**
     *
     * @param testJobs
     * @description sets test job view template
     */
    prepareCardViews(testJobs: any[]){
        let spanParameter = parseInt(15000/this.state.width)
        let zoom = '100%'
        if(innerHeight >= innerWidth){
            spanParameter = 24,
            zoom = '70%'
        }
        testViews = testJobs.map(item=>
            <Col span={spanParameter} style={{zoom:zoom}}>
                <Row className='cvat-test-tasks-list-item-updated' type='flex'>
                    <Col span={24}>
                        <div className='cvat-test-task-item-preview-wrapper-updated'>
                            <img alt={i18n.t('task-page.preview')} className='cvat-test-task-item-preview' src={this.state.imageMap instanceof Map ? this.state.imageMap.get(item.task_id):""}/>
                        </div>
                    </Col>
                    <div style={{marginLeft:"3%",marginRight:"3%", marginTop:"5%", width:"100%", height:"100%", maxHeight:"270px"}}>
                        <Row type="flex" gutter={[48, 0]}>
                            <Col span={24}>
                                <Text strong style={{fontSize:"16px",color:"#5A5C69"}} className='cvat-text-color'>Test: {item.task_name}</Text>
                            </Col>
                            <Col span={24}>
                                <Text strong style={{fontSize:"16px",color:"#5A5C69"}} className='cvat-text-color'>Kullanıcı: {item.user_name}</Text>
                            </Col>
                        </Row>
                        <br></br>
                        <Row type="flex" gutter={[48, 48]} >
                            <Col span={24}>
                                <Text strong style={{color: item.state === "failed" ? "red" : ""}}>{item.state === "failed" ? "Başarız Olduğu" : null} Etiketleme Skoru: </Text><span>{(item.score === 0 ? "-" :((item.score-0.5)*100).toPrecision(3)+"%")}</span>
                            </Col>
                        </Row>
                        <Row type="flex" gutter={[48, 48]} >
                            <Col span={24}>
                                <Text strong >Görev Eşik Skoru: </Text><span>{(item.threshold+"%")}</span>
                            </Col>
                        </Row>
                        <div style={{position:"absolute", bottom:"5px", minWidth:"82%"}}>
                            <Link id={item.task_name+"Inspect"} to={{ pathname: '/tasks/' + item.task_id + '/jobs/' + item.jobId }}><Button type='link' style={{ margin: "8px" , color:"#6C63FF"}}>{i18n.t('test-evaluation.inspect')}</Button></Link>
                            <div style={{float:"right"}}>
                                <Button className="button-default-groups-page" id={item.task_name+"Fail"}
                                disabled={item.state === "failed" ? true : false}
                                onClick={() => Modal.confirm({
                                    title: <p><b>{item.user_name}</b> kullanıcısının <b>{item.task_name} </b>isimli test görevi başarısız olarak kaydedilecektir. Devam edilsin mi ?</p>,
                                    // `${item.user_name} kullanıcısının ${item.task_name} isimli test görevine ait işi başarısız olarak kaydedilecektir.`,
                                    // content: 'Görevle ilişkilendirilmiş bütün veriler silinecek. Devam edilsin mi?',
                                    onOk: () => {
                                        this.evaluateTest(item.userId, item.jobId, 'failed')
                                    },
                                    okButtonProps: {
                                        type: 'danger',
                                    },
                                    okText: 'Onayla',
                                    cancelText: "Vazgeç",
                                })}
                                style={{ margin: "8px",borderRadius:"4px",backgroundColor:"inherit" }}>{i18n.t('test-evaluation.fail')}</Button>
                                <Button className="button-groups-page" id={item.task_name+"Pass"} type='primary'
                                onClick={() => Modal.confirm({
                                    title: <p><b>{item.user_name}</b> kullanıcısının <b>{item.task_name} </b>isimli test görevi başarılı olarak kaydedilecektir. Devam edilsin mi ?</p>,
                                    // `${item.user_name} kullanıcısının ${item.task_name} isimli test görevine ait işi başarısız olarak kaydedilecektir.`,
                                    // content: 'Görevle ilişkilendirilmiş bütün veriler silinecek. Devam edilsin mi?',
                                    onOk: () => {
                                        this.evaluateTest(item.userId, item.jobId, 'succeeded')
                                    },
                                    okButtonProps: {
                                        type: 'danger',
                                    },
                                    okText: 'Onayla',
                                    cancelText: "Vazgeç",
                                })}
                                style={{ margin: "8px",borderRadius:"4px" }}>{i18n.t('test-evaluation.pass')}</Button>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
        )
        this.setState({
            state: this.state
        });
    }

    /**
     * @async
     * @param userId
     * @param jobId
     * @param state
     * @description evaluation calls, update test list template
     */
    private async evaluateTest(userId: any, jobId: any, state: any) {
        let response = null;
        response = await cvat.jobs.teststate(Number(userId), Number(jobId), state);
        if (response.data.status == i18n.t('response-messages.success')) {
            testViews = new Array();
            const tasksAsync = this.getTests(this.state.curentPage);
            tasksAsync.then(this.prepareCardViews.bind(self));
            if(testViews.length>0){
                TestList = <div>
                    <Row type='flex' justify='space-around' align='middle'>
                        {testViews}
                    </Row>
                    <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                        <Col>
                            <ConfigProvider locale ={trTR}>
                                <Pagination
                                        defaultCurrent={1}
                                        pageSize={10}
                                        onChange={this.handleChange}
                                        current={this.state.currentPage}
                                        total={this.state.totalTestCount}
                                        showQuickJumper={true}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                </div>
            }
            else{
                TestList =  <div><Icon component={NoDataVector}></Icon><br />
                <Text style={{ fontSize: "18px" }} type="secondary">Veri Yok</Text>
                </div>
            }
            this.setState({
                state: this.state
            })
        }
    }

    /**
     *
     * @param value
     * @returns tests
     */
    async getTests(value: any) {
        let tests;
        let testsResponses = await Promise.all([
            cvat.jobs.getTestJobs('completed', null, value),
            cvat.jobs.getTestJobs('failed', null, value),
        ])
        tests = [...testsResponses[0], ...testsResponses[1]]
        currentTasks = tests;
        let imageMap = new Map();
        for(var i = 0; i < currentTasks.length; i++){
            let previewShowTask = await cvat.frames.getPreview(currentTasks[i].task_id)
            imageMap.set(currentTasks[i].task_id, previewShowTask);
        }
        this.setState({
            totalTestCount: tests.count,
            imageMap: imageMap
        });
        return tests;
    }

    /**
     *
     * @returns test list template
     */
    private renderTestList() {
        if(testViews.length>0){
        TestList = <div>
            <Row type='flex' justify='space-around' align='middle' style={{paddingTop:"15px"}}>{testViews}</Row>
            <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                <Col>
                    <Pagination
                        defaultCurrent={1}
                        pageSize={10}
                        onChange={this.handleChange}
                        current={this.state.currentPage}
                        total={this.state.totalTestCount}
                        showQuickJumper={true} />
                </Col>
            </Row>
        </div>;
        }else{
            TestList =   <div style={{paddingTop: "15px",position: "fixed",display: "flex",left: "50%",top: "50%",flexDirection: "column",alignItems: "center"}}><Icon component={NoDataVector}/><br />
            <Text style={{ fontSize: "18px" }} type="secondary">VERİ YOK</Text><br/><Text type="secondary">Henüz onay bekleyen test yok.</Text></div>
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('test-evaluation.pendingTests')}</Text>
                {TestList}

            </div>
        )
    }

    /**
     *
     * @param value
     * @description handle page change and get tests accordingly
     */
    handleChange = value => {
        testViews = new Array()
        const tasksAsync = this.getTests(value);
        tasksAsync.then(this.prepareCardViews.bind(self));
        this.setState({
            currentPage: value,
        });
    };

    public render() {
        return this.renderTestList();
    }
}

export default withRouter(connect(
)(TestEvaluationPage));
