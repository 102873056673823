// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { AnyAction, Dispatch, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
    TasksQuery,
    CombinedState,
} from 'reducers/interfaces';
import { getCVATStore } from 'cvat-store';
import getCore from 'cvat-core-wrapper';
import { getInferenceStatusAsync } from './models-actions';
import {
    Modal,
} from 'antd';

const cvat = getCore();

export enum TasksActionTypes {
    GET_TASKS = 'GET_TASKS',
    GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS',
    GET_TASKS_FAILED = 'GET_TASKS_FAILED',
    LOAD_ANNOTATIONS = 'LOAD_ANNOTATIONS',
    LOAD_ANNOTATIONS_SUCCESS = 'LOAD_ANNOTATIONS_SUCCESS',
    LOAD_ANNOTATIONS_FAILED = 'LOAD_ANNOTATIONS_FAILED',
    DUMP_ANNOTATIONS = 'DUMP_ANNOTATIONS',
    DUMP_ANNOTATIONS_SUCCESS = 'DUMP_ANNOTATIONS_SUCCESS',
    DUMP_ANNOTATIONS_FAILED = 'DUMP_ANNOTATIONS_FAILED',
    EXPORT_DATASET = 'EXPORT_DATASET',
    EXPORT_DATASET_SUCCESS = 'EXPORT_DATASET_SUCCESS',
    EXPORT_DATASET_FAILED = 'EXPORT_DATASET_FAILED',
    DELETE_TASK = 'DELETE_TASK',
    DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
    DELETE_TASK_FAILED = 'DELETE_TASK_FAILED',
    CREATE_TASK = 'CREATE_TASK',
    CREATE_TASK_STATUS_UPDATED = 'CREATE_TASK_STATUS_UPDATED',
    CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS',
    CREATE_TASK_FAILED = 'CREATE_TASK_FAILED',
    UPDATE_TASK = 'UPDATE_TASK',
    UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS',
    UPDATE_TASK_FAILED = 'UPDATE_TASK_FAILED',
    HIDE_EMPTY_TASKS = 'HIDE_EMPTY_TASKS',
    CREATE_DATASET_STATUS_PROGRESS = 'CREATE_DATASET_STATUS_PROGRESS',
    DOWNLOAD_DATASET_STATUS_PROGRESS = 'DOWNLOAD_DATASET_STATUS_PROGRESS',
}

function getTasks(): AnyAction {
    const action = {
        type: TasksActionTypes.GET_TASKS,
        payload: {},
    };

    return action;
}

function getTasksSuccess(array: any[], previews: string[],
    count: number, query: TasksQuery): AnyAction {
    const action = {
        type: TasksActionTypes.GET_TASKS_SUCCESS,
        payload: {
            previews,
            array,
            count,
            query,
        },
    };

    return action;
}

function getTasksFailed(error: any, query: TasksQuery): AnyAction {
    const action = {
        type: TasksActionTypes.GET_TASKS_FAILED,
        payload: {
            error,
            query,
        },
    };

    return action;
}

/**
 * 
 * Actions for getting tasks operation
 * 
 * @param query TasksQuery
 * @param tasksViewType string
 * @param taskType string
 * @param onlyTasks boolean
 * @param annotaTaskStatus string
 * @param taskClassificationType string
 * @param onlyExpired string
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function getTasksAsync(query: TasksQuery, tasksViewType?: string, taskType?: string, onlyTasks?: boolean, annotaTaskStatus?: string, taskClassificationType?: string, onlyExpired?: string):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(getTasks());

        // We need remove all keys with null values from query
        const filteredQuery = { ...query };
        for (const key in filteredQuery) {
            if (filteredQuery[key] === null) {
                delete filteredQuery[key];
            }
        }

        let result = null;
        try {
            result = await cvat.tasks.get(filteredQuery, tasksViewType, taskType, onlyTasks, annotaTaskStatus, taskClassificationType, onlyExpired);
        } catch (error) {
            dispatch(getTasksFailed(error, query));
            return;
        }

        const array = Array.from(result);
        const previews = [];
        const promises = array
            .map((task): string => (task as any).frames.preview());

        dispatch(
            getInferenceStatusAsync(
                array.map(
                    (task: any): number => task.id,
                ),
            ),
        );

        for (const promise of promises) {
            try {
                // a tricky moment
                // await is okay in loop in this case, there aren't any performance bottleneck
                // because all server requests have been already sent in parallel

                // eslint-disable-next-line no-await-in-loop
                previews.push(await promise);
            } catch (error) {
                previews.push('');
            }
        }

        dispatch(getTasksSuccess(array, previews, result.count, query));
    };
}

function dumpAnnotation(task: any, dumper: any): AnyAction {
    const action = {
        type: TasksActionTypes.DUMP_ANNOTATIONS,
        payload: {
            task,
            dumper,
        },
    };

    return action;
}

function dumpAnnotationSuccess(task: any, dumper: any): AnyAction {
    const action = {
        type: TasksActionTypes.DUMP_ANNOTATIONS_SUCCESS,
        payload: {
            task,
            dumper,
        },
    };

    return action;
}

function dumpAnnotationFailed(task: any, dumper: any, error: any): AnyAction {
    const action = {
        type: TasksActionTypes.DUMP_ANNOTATIONS_FAILED,
        payload: {
            task,
            dumper,
            error,
        },
    };

    return action;
}

/**
 * 
 * Actions for dump annotations
 * 
 * @param task any
 * @param dumper any
 * @param labels any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function dumpAnnotationsAsync(task: any, dumper: any, labels: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(dumpAnnotation(task, dumper));
            const url = await task.annotations.dump(dumper, null, labels);
            const downloadAnchor = (window.document.getElementById('downloadAnchor') as HTMLAnchorElement);
            downloadAnchor.href = url;
            downloadAnchor.click();
        } catch (error) {
            dispatch(dumpAnnotationFailed(task, dumper, error));
            return;
        }

        dispatch(dumpAnnotationSuccess(task, dumper));
    };
}

function loadAnnotations(task: any, loader: any): AnyAction {
    const action = {
        type: TasksActionTypes.LOAD_ANNOTATIONS,
        payload: {
            task,
            loader,
        },
    };

    return action;
}

function loadAnnotationsSuccess(task: any): AnyAction {
    const action = {
        type: TasksActionTypes.LOAD_ANNOTATIONS_SUCCESS,
        payload: {
            task,
        },
    };

    return action;
}

function loadAnnotationsFailed(task: any, error: any): AnyAction {
    const action = {
        type: TasksActionTypes.LOAD_ANNOTATIONS_FAILED,
        payload: {
            task,
            error,
        },
    };

    return action;
}

/**
 * 
 * Actions for loading annotations
 * 
 * @param task any
 * @param loader any
 * @param file File
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function loadAnnotationsAsync(task: any, loader: any, file: File):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const store = getCVATStore();
            const state: CombinedState = store.getState();
            if (state.tasks.activities.loads[task.id]) {
                throw Error('Only one loading of annotations for a task allowed at the same time');
            }
            dispatch(loadAnnotations(task, loader));
            await task.annotations.upload(file, loader);
        } catch (error) {
            dispatch(loadAnnotationsFailed(task, error));
            return;
        }

        dispatch(loadAnnotationsSuccess(task));
    };
}

function exportDataset(task: any, exporter: any): AnyAction {
    const action = {
        type: TasksActionTypes.EXPORT_DATASET,
        payload: {
            task,
            exporter,
        },
    };

    return action;
}

function exportDatasetSuccess(task: any, exporter: any): AnyAction {
    const action = {
        type: TasksActionTypes.EXPORT_DATASET_SUCCESS,
        payload: {
            task,
            exporter,
        },
    };

    return action;
}

function exportDatasetFailed(task: any, exporter: any, error: any): AnyAction {
    const action = {
        type: TasksActionTypes.EXPORT_DATASET_FAILED,
        payload: {
            task,
            exporter,
            error,
        },
    };

    return action;
}

/**
 * 
 * Actions for exporting dataset
 * 
 * @param task any
 * @param exporter any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function exportDatasetAsync(task: any, exporter: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        dispatch(exportDataset(task, exporter));

        try {
            const url = await task.annotations.exportDataset(exporter.name);
            const downloadAnchor = (window.document.getElementById('downloadAnchor') as HTMLAnchorElement);
            downloadAnchor.href = url;
            downloadAnchor.click();
        } catch (error) {
            dispatch(exportDatasetFailed(task, exporter, error));
        }

        dispatch(exportDatasetSuccess(task, exporter));
    };
}

function deleteTask(taskID: number): AnyAction {
    const action = {
        type: TasksActionTypes.DELETE_TASK,
        payload: {
            taskID,
        },
    };

    return action;
}

function deleteTaskSuccess(taskID: number): AnyAction {
    const action = {
        type: TasksActionTypes.DELETE_TASK_SUCCESS,
        payload: {
            taskID,
        },
    };

    return action;
}

function deleteTaskFailed(taskID: number, error: any): AnyAction {
    const action = {
        type: TasksActionTypes.DELETE_TASK_FAILED,
        payload: {
            taskID,
            error,
        },
    };

    return action;
}

/**
 * 
 * Actions for deleting dataset
 * 
 * @param taskInstance any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function deleteTaskAsync(taskInstance: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(deleteTask(taskInstance.id));
            await taskInstance.delete();
        } catch (error) {
            dispatch(deleteTaskFailed(taskInstance.id, error));
            return;
        }

        dispatch(deleteTaskSuccess(taskInstance.id));
    };
}

function createTask(): AnyAction {
    const action = {
        type: TasksActionTypes.CREATE_TASK,
        payload: {},
    };

    return action;
}

function createTaskSuccess(): AnyAction {
    const action = {
        type: TasksActionTypes.CREATE_TASK_SUCCESS,
        payload: {},
    };

    return action;
}

function createTaskFailed(error: any): AnyAction {
    const action = {
        type: TasksActionTypes.CREATE_TASK_FAILED,
        payload: {
            error,
        },
    };

    return action;
}

function createTaskUpdateStatus(status: string): AnyAction {
    const action = {
        type: TasksActionTypes.CREATE_TASK_STATUS_UPDATED,
        payload: {
            status,
        },
    };

    return action;
}

function createDatasetUpdateStatus(statusDatasetUploadProgress: string): AnyAction {
    const action = {
        type: TasksActionTypes.CREATE_DATASET_STATUS_PROGRESS,
        payload: {
            statusDatasetUploadProgress,
        },
    };
    return action;
}

export function downloadDatasetUpdateStatus(datasetDownloadProgress: string): AnyAction {
    const action = {
        type: TasksActionTypes.DOWNLOAD_DATASET_STATUS_PROGRESS,
        payload: {
            datasetDownloadProgress,
        },
    };
    return action;
}

/**
 * 
 * Actions for creating dataset
 * 
 * @param data any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function createDatasetAsync(data: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {

        let maskJsonStringToSend;
        let files;
        if(data.files.local.length > 0)
            files = data.files.local
        else if(data.files.remote.length > 0)
            files = data.files.remote
        else
            files = data.files.share

        if(data.maskJson.maskpoint != null){
            var maskArray = data.maskJson.maskpoint
            var myMap = new Map();

            for(var i = 0; i < maskArray.length; i++){
                var oldValue = myMap.get(String(maskArray[i].fileId));
                if(oldValue == null){
                    myMap.set(String(maskArray[i].fileId), [ maskArray[i] ]);
                }else{
                    let updatedArrayForFileMask = Array.from(oldValue).concat(maskArray[i])
                    myMap.set(String(maskArray[i].fileId), updatedArrayForFileMask );
                }
            }

            let finalResult = new Array()

            for(var i = 0; i < files.length; i++){
                var oldValue = myMap.get(String(i));
                if(oldValue == null){
                    finalResult.push(new Array())
                }else{
                    finalResult.push(oldValue)
                }
            }

            let maskTotal = {
                "maskpoint": finalResult
            }
            maskJsonStringToSend = JSON.stringify(maskTotal);
        }else{

            let finalResult = new Array()
            for(var i = 0; i < files.length; i++){
                finalResult.push(new Array())
            }
            let maskTotal = {
                "maskpoint":finalResult
            }
            maskJsonStringToSend = JSON.stringify(maskTotal);
        }

        if(data.isPrivate == undefined)
            data.isPrivate = true
        const description: any = {
            name: data.basic.name,
            maskpoints: maskJsonStringToSend,
            isPrivate: data.isPrivate,
            metaData: data.metaData,
            projectName: data.projectName,
            mediaType: data.mediaType,
            dataFormat: data.dataFormat,
            dataTimeLength: data.dataTimeLength,
            dataSize: data.dataSize,
            dataContext: data.dataContext,
            dataPrivacy: data.dataPrivacy,
            dataUsage: data.dataUsage,
        };

        const datasetInstance = new cvat.classes.Dataset(description);
        datasetInstance.clientFiles = data.files.local;
        datasetInstance.serverFiles = data.files.share;
        datasetInstance.remoteFiles = data.files.remote;

        /*
        if (data.advanced.repository) {
            const [gitPlugin] = (await cvat.plugins.list()).filter(
                (plugin: any): boolean => plugin.name === 'Git',
            );

            if (gitPlugin) {
                gitPlugin.callbacks.onStatusChange = (status: string): void => {
                    dispatch(createTaskUpdateStatus(status));
                };
                gitPlugin.data.task = taskInstance;
                gitPlugin.data.repos = data.advanced.repository;
                gitPlugin.data.lfs = data.advanced.lfs;
            }
        }*/

        let createdDataset = null;

        try {
            createdDataset = await datasetInstance.save(async (status: string): Promise<void> => {
                dispatch(createTaskUpdateStatus(status));
            }, async (status: string): Promise<void> => {
                dispatch(createDatasetUpdateStatus(status));
            });
            dispatch(createTaskSuccess());
        } catch (error) {
            dispatch(createTaskFailed(error));
            //dispatch(deleteTaskAsync(createdDataset));
        }
    };
}

/**
 * 
 * Actions for modifying dataset 
 * 
 * @param data any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function modifyDatasetAsync(data: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        //let maskJsonStringToSend = JSON.stringify(data.maskJson);
        let maskJsonStringToSend;
        let files;
        if(data.files.local.length > 0)
            files = data.files.local
        else if(data.files.remote.length > 0)
            files = data.files.remote
        else
            files = data.files.share

        if(data.maskJson.length > 0){
            var maskArray = data.maskJson
            var myMap = new Map();

            for(var i = 0; i < maskArray.length; i++){
                var oldValue = myMap.get(String(maskArray[i].fileId));
                if(oldValue == null){
                    myMap.set(String(maskArray[i].fileId), [ maskArray[i] ]);
                }else{
                    let updatedArrayForFileMask = Array.from(oldValue).concat(maskArray[i])
                    myMap.set(String(maskArray[i].fileId), updatedArrayForFileMask );
                }
            }

            let finalResult = new Array()

            for(var i = 0; i < files.length; i++){
                var oldValue = myMap.get(String(i));
                if(oldValue == null){
                    finalResult.push(new Array())
                }else{
                    finalResult.push(oldValue)
                }
            }

            let maskTotal = {
                "maskpoint": finalResult
            }
            maskJsonStringToSend = JSON.stringify(maskTotal);
        }else{
            let finalResult = new Array()
            for(var i = 0; i < files.length; i++){
                finalResult.push(new Array())
            }
            let maskTotal = {
                "maskpoint":finalResult
            }
            maskJsonStringToSend = JSON.stringify(maskTotal);
        }

        const datasetFiles = {
            client_files: data.files.local,
            server_files: data.files.share,
            remote_files: data.files.remote,
        };

        let tryExpandDataset = await cvat.tasks.expandDataset(datasetFiles,data.id,maskJsonStringToSend)

        if(tryExpandDataset.status == 202){
            Modal.info({
                title: 'Başarılı!',
                content: "Dataset Güncellendi",
                okText: 'Tamam',
                onOk() {
                    //window.location.reload();
                    window.location.assign('/datasets/modify/' + data.id)
                },
            });
        }
        else{
            return Promise.reject(tryExpandDataset.data)
        }
    };
}

function arrayUnique(array: any) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
}

/**
 * 
 * Actions for creating task
 * 
 * @param data any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function createTaskAsync(data: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {

        let maskJsonStringToSend;
        let finalResult = new Array()
        if(data.maskJson == undefined|| data.maskJson.length == 0){
            finalResult.push(new Array())
        }else{
            finalResult.push(new Array())
            finalResult[0] = data.maskJson.maskpoint
        }
        let maskTotal = {
            "maskpoint":finalResult
        }
        maskJsonStringToSend = JSON.stringify(maskTotal);

        if(data.isPrivate == undefined)
            data.isPrivate = false
        if(data.textProjectType == undefined)
            data.textProjectType = 'SequenceLabeling'
        var shapeList;
        if(data.shapes == undefined || data.shapes.length == 0)
            shapeList = ['rectangle', 'polygon', 'polyline', 'points', 'cuboid', 'tag']
        else
            shapeList = data.shapes
        var shapeListJSON = {
            "shapeList" : shapeList
        }
        data.shapes = JSON.stringify(shapeListJSON)

        var taskTypesList;
        if(data.taskTypes == undefined || data.taskTypes.length == 0)
            taskTypesList = ['other']
        else
            taskTypesList = data.taskTypes
        var taskTypesListJSON = {
            "taskTypesList" : taskTypesList
        }
        data.taskTypes = JSON.stringify(taskTypesListJSON)


        if(data.isPrivateTask == undefined)
            data.isPrivateTask = false
        var userList;
        if(data.users == undefined || data.users.length == 0 || data.isPrivateTask == false)
            userList = []
        else
            userList = data.users

        if(data.usersFromGroupsData.length != 0 && data.isPrivateTask)
            userList = arrayUnique(userList.concat(data.usersFromGroupsData))

        var userListJSON = {
            "userList" : userList
        }
        data.users = JSON.stringify(userListJSON)

        const description: any = {
            name: data.basic.name,
            guidelines: data.basic.guidelines,
            labels: data.labels,
            z_order: data.advanced.zOrder,            
            image_quality: 70,
            frame_filter: 'step=1',
            maskpoints: maskJsonStringToSend,
            datasetId: data.selectedDatasetId,
            use_zip_chunks: data.advanced.useZipChunks,
            isPrivate: data.isPrivate,
            textProjectType: data.textProjectType,
            shapes: data.shapes,
            isPrivateTask: data.isPrivateTask,
            users: data.users,
            assigneeCount: data.assigneeCount,
            metaData: data.metaData,
            taskDifficulty: data.taskDifficulty,
            taskTypes: data.taskTypes,
            taskType: data.basic.taskType,
            metaDataDataset: data.metaDataDataset,
            projectName: data.projectName,
            mediaType: data.mediaType,
            dataFormat: data.dataFormat,
            dataTimeLength: data.dataTimeLength,
            dataSize: data.dataSize,
            dataContext: data.dataContext,
            dataPrivacy: data.dataPrivacy,
            dataUsage: data.dataUsage,
            datasetName:data.datasetName
        };

        if(data.guidelinesFile){
            description.guidelinesFile = data.guidelinesFile;
        }

        if(data.thumbnail){
            description.thumbnail = data.thumbnail;
        }

        if (data.advanced.bugTracker) {
            description.bug_tracker = data.advanced.bugTracker;
        }
        if (data.advanced.segmentSize) {
            description.segment_size = data.advanced.segmentSize;
        }
        if (data.advanced.overlapSize) {
            description.overlap = data.advanced.overlapSize;
        }
        if (data.advanced.startFrame) {
            description.start_frame = data.advanced.startFrame;
        }
        if (data.advanced.stopFrame) {
            description.stop_frame = data.advanced.stopFrame;
        }
        if (data.advanced.frameFilter) {
            description.frame_filter = data.advanced.frameFilter;
        }
        if (data.advanced.imageQuality) {
            description.image_quality = data.advanced.imageQuality;
        }
        if (data.advanced.frameFilter) {
            description.frame_filter = data.advanced.frameFilter;
        }
        if (data.advanced.dataChunkSize) {
            description.data_chunk_size = data.advanced.dataChunkSize;
        }
        if (data.imageMaskData) {
            description.imageMaskData = data.imageMaskData;
        }

        const taskInstance = new cvat.classes.Task(description);
        taskInstance.clientFiles = data.files.local;
        taskInstance.serverFiles = data.files.share;
        taskInstance.remoteFiles = data.files.remote;
        taskInstance.datasetFiles = data.files.dataset;
        if(data.files.ssbdataset.length > 0){
            taskInstance.datasetFiles = data.files.ssbdataset;
        }

        if (data.advanced.repository) {
            const [gitPlugin] = (await cvat.plugins.list()).filter(
                (plugin: any): boolean => plugin.name === 'Git',
            );

            if (gitPlugin) {
                gitPlugin.callbacks.onStatusChange = (status: string): void => {
                    dispatch(createTaskUpdateStatus(status));
                };
                gitPlugin.data.task = taskInstance;
                gitPlugin.data.repos = data.advanced.repository;
                gitPlugin.data.lfs = data.advanced.lfs;
            }
        }

        let createdTask = null;

        dispatch(createTask());
        try {
            createdTask = await taskInstance.save(async (status: string): Promise<void> => {
                dispatch(createTaskUpdateStatus(status));
            });
            // if(data.basic.taskType != "normal"){
            //     await cvat.tasks.setTaskType(createdTask.id,data.basic.taskType)
            // }
            dispatch(createTaskSuccess());
            if(createdTask.owner.groups.includes("provider")) {
                Modal.info({
                    content: "Görevi platform yöneticisi onayı sonrasında görüntüleyebileceksiniz.",
                    okText: 'Tamam',
                });
            }
        } catch (error) {
            dispatch(createTaskFailed(error));
            dispatch(deleteTaskAsync(createdTask));
        }
    };
}

function updateTask(): AnyAction {
    const action = {
        type: TasksActionTypes.UPDATE_TASK,
        payload: {},
    };

    return action;
}

function updateTaskSuccess(task: any): AnyAction {
    const action = {
        type: TasksActionTypes.UPDATE_TASK_SUCCESS,
        payload: {
            task,
        },
    };

    return action;
}

function updateTaskFailed(error: any, task: any): AnyAction {
    const action = {
        type: TasksActionTypes.UPDATE_TASK_FAILED,
        payload: {
            error,
            task,
        },
    };

    return action;
}

/**
 * 
 * Actions for updating task
 * 
 * @param taskInstance any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function updateTaskAsync(taskInstance: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(updateTask());
            await taskInstance.save();
            const [task] = await cvat.tasks.get({ id: taskInstance.id });
            dispatch(updateTaskSuccess(task));
        } catch (error) {
            // try abort all changes
            let task = null;
            try {
                [task] = await cvat.tasks.get({ id: taskInstance.id });
            } catch (fetchError) {
                dispatch(updateTaskFailed(error, taskInstance));
                return;
            }

            dispatch(updateTaskFailed(error, task));
        }
    };
}

/**
 * 
 * Actions for updating job list
 * 
 * @param taskInstance any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */

export function updateJoblistAsync(taskInstance: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const [task] = await cvat.tasks.get({ id: taskInstance.id });
            dispatch(updateTaskSuccess(task));
        } catch (error) {
            // try abort all changes
            let task = null;
            try {
                [task] = await cvat.tasks.get({ id: taskInstance.id });
            } catch (fetchError) {
                dispatch(updateTaskFailed(error, taskInstance));
                return;
            }

            dispatch(updateTaskFailed(error, task));
        }
    };
}

/**
 * 
 * Actions for updating job
 * 
 * a job is a part of a task, so for simplify we consider
 * updating the job as updating a task
 * 
 * @param jobInstance any
 * @returns ThunkAction<Promise<void>, {}, {}, AnyAction>
 */
export function updateJobAsync(jobInstance: any):
ThunkAction<Promise<void>, {}, {}, AnyAction> {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch(updateTask());
            await jobInstance.save();
            const [task] = await cvat.tasks.get({ id: jobInstance.task.id });
            dispatch(updateTaskSuccess(task));
        } catch (error) {
            // try abort all changes
            let task = null;
            try {
                [task] = await cvat.tasks.get({ id: jobInstance.task.id });
            } catch (fetchError) {
                dispatch(updateTaskFailed(error, jobInstance.task));
                return;
            }

            dispatch(updateTaskFailed(error, task));
        }
    };
}

export function hideEmptyTasks(hideEmpty: boolean): AnyAction {
    const action = {
        type: TasksActionTypes.HIDE_EMPTY_TASKS,
        payload: {
            hideEmpty,
        },
    };

    return action;
}
