// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import LabelForm from './label-form';
import { Label } from './common';

interface Props {
    label: Label;
    onUpdate: (label: Label | null) => void;
    textTask: any;
}


/**
 * Render Updater component
 * 
 * @param props consists of testTask, label variables and onUpdate function
 * @returns div
 */
export default function ConstructorUpdater(props: Props): JSX.Element {
    const {
        label,
        onUpdate,
        textTask
    } = props;

    return (
        <div className='cvat-label-constructor-updater'>
            <LabelForm label={label} onSubmit={onUpdate} textTask = {textTask} />
        </div>
    );
}
