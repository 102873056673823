// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import DetailsComponent from 'components/task-page/details';
import { updateTaskAsync, updateJoblistAsync } from 'actions/tasks-actions';
import {
    Task,
    CombinedState,
} from 'reducers/interfaces';

interface OwnProps {
    task: Task;
    viewer: boolean;
}

interface StateToProps {
    registeredUsers: any[];
    installedGit: boolean;
    user: any;
    annotaUser: any;
}

interface DispatchToProps {
    onTaskUpdate: (taskInstance: any) => void;
    updateJoblist: (taskInstance: any) => void;
}

/**
 *
 * @param state -> combined state containing plugins, user, registered user, git integration and annota user information
 * @returns user and git integration information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    const { list } = state.plugins;

    return {
        user: state.auth.user,
        registeredUsers: state.users.users,
        installedGit: list.GIT_INTEGRATION,
        annotaUser: state.auth.annotaUser
    };
}

/**
 *
 * @param dispatch
 * @returns onTaskUpdate, updateJobList action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onTaskUpdate: (taskInstance: any): void => dispatch(updateTaskAsync(taskInstance)),
        updateJoblist: (taskInstance: any): void => dispatch(updateJoblistAsync(taskInstance)),
    };
}

/**
 *
 * @param props
 * @returns DetailsComponent
 * @description Passes details properties to component
 */
function TaskPageContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        task,
        installedGit,
        registeredUsers,
        onTaskUpdate,
        user,
        viewer,
        updateJoblist,
        annotaUser
    } = props;

    return (
        <DetailsComponent
            user={user}
            previewImage={task.preview}
            taskInstance={task.instance}
            installedGit={installedGit}
            onTaskUpdate={onTaskUpdate}
            registeredUsers={registeredUsers}
            viewer={viewer}
            updateJoblist={updateJoblist}
            annotaUser={annotaUser}
        />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskPageContainer);
