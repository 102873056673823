import { Avatar, Button, Card, Col, Icon, Input, List, message, Modal, Row, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import Dragger from "antd/lib/upload/Dragger";
import consts from "consts";
import getCore from "cvat-core-wrapper";
import { AvatarChangeIcon, AvatarSelectIcon, EmptyCircleIcon, UploaderAdd } from "icons";
import Moment from "moment";
import React from "react";
import { RegionDropdown } from "react-country-region-selector";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { authorizedAsync } from "../actions/auth-actions";
import i18n from "../i18n";
import { AuthState } from "../reducers/interfaces";
import ChangePassword from "./change-password";
import PaymentsComponent from "./payments-component";
import "./styles.scss";

const { AVATAR_MAP, AVATAR_LEVEL_MAP, AVATAR_LEVEL_MAP_EXPERT } = consts;

interface StateToProps {
    auth: AuthState;
}
interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatching function
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    };
}

type ProfilePageProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class_component ProfilePageComponent for /profile path
 */
class ProfilePageComponent extends React.PureComponent<ProfilePageProps> {
    editable: boolean;
    constructor(props: any) {
        super(props);
        this.state = {
            user: null,
            cvatUser: null,
            address: null,
            city: null,
            town: null,
            isWorking: null,
            workplace: null,
            experienceExplanation: null,
            certificateExplanation: null,
            plExplanation: null,
            aiExplanation: null,
            othersExplanation: null,
            visible: false,
            firstName: false,
            lastName: false,
            modalType: "",
            iban: "",
            paymentRequested: false,
            changedAddress: null,
            changedCity: null,
            changedTown: null,
            changedBasicExplanation: null,
            changedAI: null,
            changedPL: null,
            changedWorkplace: null,
            changedCertificates: null,
            changedFirstName: null,
            changedLastName: null,
            changedEmail: null,
            changedPhone: null,
            width: 1920,
            height: 1080,
            avatarId: 1,
            isAvatarUpdating: false,
            changedAvatarId: 1,
            expert: false,
            tcNo: null,
            serialNo: null,
            isPhotoUpdating: false,
            base64Photo: null,
            fileList: [],
        };
        this.getUser();
        this.handleResize = this.handleResize.bind(this);
        this.prepareAvatars = this.prepareAvatars.bind(this);
        this.selectAvatar = this.selectAvatar.bind(this);
        this.updateUserAvatarId = this.updateUserAvatarId.bind(this);
        this.changeVerifyInformation = this.changeVerifyInformation.bind(this);
        this.renderAvatarSelectionModal =
            this.renderAvatarSelectionModal.bind(this);
        this.updatePhoto = this.updatePhoto.bind(this);
        this.editable = process.env.DEPLOYMENT_PLACE === "SSB";
    }

    /**
     *
     * @param state
     * @returns Level number or Turkish level
     */
    private getUserLevel(state: String) {
        switch (state) {
            case "freshman":
                return "1";
            case "sophomore":
                return "2";
            case "junior":
                return "3";
            case "senior":
                return "4";
            case "master":
                return "5";
            case "qualified":
                return "Nitelikli";
            case "expert":
                return "Uzman";
            default:
                return "";
        }
    }

    /**
     *
     * @param state
     * @returns Turkish level name
     */
    private getUserLevelName(state: String) {
        switch (state) {
            case "freshman":
                return "Çaylak";
            case "sophomore":
                return "Azimli";
            case "junior":
                return "Deneyimli";
            case "senior":
                return "Üstat";
            case "master":
                return "Büyük Üstat";
        }
    }

    /**
     * @description updates window width and height on state
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight,
        });
    }

    public componentDidMount(): void {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    /**
     * @async
     * @description get user information with api proxy calls, sets state for user information
     */
    async getUser() {
        const [user] = await cvat.users.get({ self: true });
        const annotaUser = await cvat.users.getAnnotaSelf(user.id);
        let userLevel = "";
        let userStatus = null;
        let isExpert = false;
        if (user.isSuperuser) {
            userStatus = "Platform Yöneticisi";
            userLevel = this.getUserLevel(
                annotaUser["models_to_return"][0].level
            );
        } else if (user.groups.includes("provider")) {
            userStatus = "Veri Sağlayıcı";
            userLevel = this.getUserLevel(
                annotaUser["models_to_return"][0].level
            );
        } else if (user.groups.includes("candidate")) {
            userStatus = "Aday Etiketleyici";
            userLevel = this.getUserLevel(
                annotaUser["models_to_return"][0].level
            );
        } else if (user.groups.includes("annotator")) {
            userLevel = this.getUserLevel(
                annotaUser["models_to_return"][0].level
            );
            isExpert = annotaUser["models_to_return"][0].expert === "expert";
            if (isExpert) {
                userStatus =
                    userLevel != ""
                        ? this.getUserLevelName(
                              annotaUser["models_to_return"][0].level
                          ) + " | Uzman Etiketleyici"
                        : "Uzman Etiketleyici";
            } else {
                userStatus =
                    userLevel != ""
                        ? this.getUserLevelName(
                              annotaUser["models_to_return"][0].level
                        ) + " | Etiketleyici"
                        : "Etiketleyici";
            }
        }
        if (user.isSpecialAnnotator) {
            userStatus = "Özel Etiketleyici";
        }
        console.log(annotaUser["models_to_return"]);
        this.setState({
            user: annotaUser["models_to_return"][0],
            cvatUser: user,
            address: annotaUser["models_to_return"][0].address,
            city: annotaUser["models_to_return"][0].city,
            town: annotaUser["models_to_return"][0].town,
            isWorking: annotaUser["models_to_return"][0].isWorking,
            workplace: annotaUser["models_to_return"][0].workPlace,
            experienceExplanation:
                annotaUser["models_to_return"][0].computerExperience,
            certificateExplanation:
                annotaUser["models_to_return"][0].certificates,
            plExplanation:
                annotaUser["models_to_return"][0].programmingLanguages,
            aiExplanation:
                annotaUser["models_to_return"][0].aiAndAnnotationExperience,
            firstName: user.firstName,
            lastName: user.lastName,
            tckn: annotaUser["models_to_return"][0].tckn,
            phone: annotaUser["models_to_return"][0].phoneNumber,
            dateOfBirth: annotaUser["models_to_return"][0].dateOfBirth,
            email: user.email,
            username: user.username,
            userLevel: userLevel,
            photoFile: annotaUser["models_to_return"][0].photoFile,
            userStatus: userStatus,
            paymentRequested:
                annotaUser["models_to_return"][0].payment_requested,
            iban: annotaUser["models_to_return"][0].iban,
            avatarId: annotaUser["models_to_return"][0].avatarId,
            changedAvatarId: annotaUser["models_to_return"][0].avatarId,
            expert: isExpert,
        });
        this.prepareAvatars();
    }

    /**
     * @async
     * @description clears user update states, send update request, get user to update state
     */
    private async updateUser() {
        this.setState({
            changedAddress: null,
            changedAI: null,
            changedBasicExplanation: null,
            changedCertificates: null,
            changedCity: null,
            changedTown: null,
            changedWorkplace: null,
            changedPL: null,
        });
        await cvat.users.updateUser(this.state.user.id_id, {
            address: this.state.address,
            city: this.state.city,
            town: this.state.town,
            workplace: this.state.workplace,
            experienceExplanation: this.state.experienceExplanation,
            certificateExplanation: this.state.certificateExplanation,
            plExplanation: this.state.plExplanation,
            aiExplanation: this.state.aiExplanation,
        });
        this.getUser();
    }

    /**
     * @async
     * @description clears update logic, api proxy call for update, handles error and success with Modals
     */
    private async handleUpdates() {
        this.setState({
            changedFirstName: null,
            changedLastName: null,
            changedEmail: null,
            changedPhone: null,
        });

        console.log(this.state.verificationFirstName)
        const response = await cvat.users.updateInformations(
            this.state.user.id_id,
            {
                tckn: this.state.tcNo,
                serialNo: this.state.serialNo,
                firstName: this.state.verificationFirstName,
                lastName: this.state.verificationLastName,
                email: this.state.email,
                phone: this.state.phone,
            }
        );

        if (!response.Success) {
            Modal.error({
                title: "Hata",
                content: "TC Seri Numaranızı Veya İsminizi Yanlış Girdiniz",
                okText: "Tamam",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
        }
        this.setState({
            sendUpdate: false,
            serialNo: null,
            changedFirstName: null,
            changedLastName: null,
        });
        this.getUser();
    }

    /**
     *
     * @description verify user information, handles error and success with Modals
     */
    private async changeVerifyInformation() {
        const response = await cvat.server.verifyInformation(
            this.state.username,
            this.state.verificationFirstName,
            this.state.verificationLastName,
            this.state.tcNo,
            this.state.serialNo
        );
        if (response.status != "Başarılı") {
            Modal.error({
                title: i18n.t("keywords.error"),
                content: response.status,
            });
        } else {
            this.showModal("generalInfo");
            this.setState({
                sendUpdate: true,
            })
            // open another modal
            return;
        }
    }

    /**
     *
     * @param e Modal OK event
     * @returns Check for changed fields and call for update information
     */
    handleOk = (e) => {
        // if (this.state.changedFirstName || this.state.changedLastName) {
        //     this.setState(
        //         {
        //             firstName: this.state.changedFirstName
        //                 ? this.state.changedFirstName
        //                 : this.state.firstName,
        //             lastName: this.state.changedLastName
        //                 ? this.state.changedLastName
        //                 : this.state.lastName,
        //             visible: false,
        //         },
        //         () => {
        //             this.handleNameUpdate();
        //             Modal.destroyAll();
        //         }
        //     );
        //     return;
        // }
        console.log(this.state.changedFirstName);
        console.log(this.state.firstName);
        console.log(this.state.verificationFirstName);
        if (
            this.state.changedFirstName ||
            this.state.changedLastName ||
            this.state.changedEmail ||
            this.state.changedPhone ||
            this.state.sendUpdate
        ) {

            this.setState(
                {
                    firstName: this.state.changedFirstName
                        ? this.state.changedFirstName
                        : this.state.firstName,
                    lastName: this.state.changedLastName
                        ? this.state.changedLastName
                        : this.state.lastName,
                    email: this.state.changedEmail
                        ? this.state.changedEmail
                        : this.state.email,
                    phone: this.state.changedPhone
                        ? this.state.changedPhone
                        : this.state.phone,
                    visible: false,
                },
                () => {
                    console.log(this.state.changedFirstName);
                    console.log(this.state.firstName);
                    console.log(this.state.verificationFirstName);
                    console.log(this.state);
                    this.handleUpdates();
                    Modal.destroyAll();
                }
            );
            return;
        }

        if (this.state.tcNo !== null || this.state.serialNo !== null) {
            console.log(this.state.firstName);
            this.setState(
                {
                    visible: false,
                },
                () => {
                    this.changeVerifyInformation();
                    Modal.destroyAll();
                }
            );
            return;
        }

        this.setState(
            {
                address: this.state.changedAddress
                    ? this.state.changedAddress
                    : this.state.address,
                aiExplanation: this.state.changedAI
                    ? this.state.changedAI
                    : this.state.aiExplanation,
                experienceExplanation: this.state.changedBasicExplanation
                    ? this.state.changedBasicExplanation
                    : this.state.experienceExplanation,
                certificateExplanation: this.state.changedCertificates
                    ? this.state.changedCertificates
                    : this.state.certificateExplanation,
                city: this.state.changedCity
                    ? this.state.changedCity
                    : this.state.city,
                town: this.state.changedTown
                    ? this.state.changedTown
                    : this.state.town,
                workplace: this.state.changedWorkplace
                    ? this.state.changedWorkplace
                    : this.state.workplace,
                plExplanation: this.state.changedPL
                    ? this.state.changedPL
                    : this.state.plExplanation,
                visible: false,
            },
            () => {
                this.updateUser();
                Modal.destroyAll();
            }
        );
    };

    /**
     *
     * @param e Modal CANCEL event
     * @description updates users, sets Modal visibility false
     */
    handleCancel = (e) => {
        this.getUser();
        this.setState({
            visible: false,
        }),
            () => {
                Modal.destroyAll();
            };
    };

    /**
     *
     * @param type
     * @description show Modal with type given in param
     */
    private showModal(type: string) {
        this.setState({
            visible: true,
            modalType: type,
        });
    }

    /**
     *
     * @returns avatar selection template
     */
    prepareAvatars() {
        let avatars = new Array();
        for (let i = 1; i <= AVATAR_MAP.size; i++) {
            avatars.push(
                <div
                    key={i}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        alignContent: "center",
                    }}
                >
                    <Card
                        hoverable
                        style={
                            i == this.state.changedAvatarId
                                ? {
                                      borderColor: "#0F123F",
                                      borderWidth: "1.5px",
                                  }
                                : {
                                      borderColor: "#FCCE5A",
                                      borderWidth: "1.5px",
                                  }
                        }
                    >
                        <Avatar
                            style={{
                                justifyContent: "center",
                                display: "flex",
                                margin: "auto",
                                transform: "scale(2.5)",
                            }}
                            shape="square"
                            src={AVATAR_MAP.get(i)}
                        ></Avatar>
                    </Card>
                    {i == this.state.changedAvatarId ? (
                        <Icon
                            style={{ transform: "scale(0.7)" }}
                            component={AvatarSelectIcon}
                        ></Icon>
                    ) : null}
                </div>
            );
        }
        return avatars;
    }

    private async updatePhoto(){
        if (this.state.base64Photo == null) {
            Modal.error({
                title: "Hata",
                content: "Bir fotoğraf seçmediniz.",
                okText: "Tamam",
                onOk: () => {
                    Modal.destroyAll();
                },
            });
            return;
        }
        var response = await cvat.users.updatePhoto(
            this.state.user.id_id,
            this.state.base64Photo
            );
        if (response.status == i18n.t("response-messages.success")) {
            this.setState({
                base64Photo: null,
                isPhotoUpdating: false,
                fileList: [],
            });
            this.getUser();
            Modal.success({
                title: i18n.t("keywords.success"),
                okText: "Tamam",
            });
            this.se
        }
    }

    /**
     *
     * @returns profile page avatar field template
     */
    private renderAvatarAndUserPhotoField() {
        let avatar;
        if (this.state.userLevel === 0) {
            avatar = AVATAR_MAP.get(this.state.avatarId);
        } else if (this.state.expert === true) {
            avatar = AVATAR_LEVEL_MAP_EXPERT.get(
                this.state.avatarId + this.state.userLevel
            );
        } else {
            avatar = AVATAR_LEVEL_MAP.get(
                this.state.avatarId + this.state.userLevel
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {/* {this.state.isAvatarUpdating&&<Icon component={AvatarArrowBackward} style={{fontSize:"25px"}} onClick={this.changeAvatarIdBackward} />} */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        position: "relative",
                        left: "15px",
                    }}
                >
                    <img
                        style={{
                            justifyContent: "center",
                            display: "flex",
                            margin: "auto",
                            width: "120px",
                        }}
                        src={avatar}
                    ></img>
                    <Icon
                        data-tour="step-change-avatar"
                        component={AvatarChangeIcon}
                        style={{
                            top: "5px",
                            position: "relative",
                            right: "43px",
                            transform: "scale(0.9)",
                        }}
                        onClick={(e) => {
                            this.setState({ isAvatarUpdating: true });
                        }}
                    />
                    {this.state.user != null && <img
                        style={{
                            justifyContent: "center",
                            display: "flex",
                            margin: "auto",
                            width: "120px",
                        }}
                        src={this.state.user.photoFile}></img>}
                    {this.state.user != null && this.editable &&
                    // <Upload
                    // action={() => this.updatePhoto(this.state.base64Photo)}
                    // transformFile={(file) =>{
                    //     const reader = new FileReader();
                    //     return new Promise(resolve => {
                    //         reader.readAsDataURL(file);
                    //         reader.onload = () => {
                    //             this.setState({base64Photo: reader.result});
                    //         };
                    //     });
                    // }}
                    // >
                        <Icon
                        data-tour="step-change-photo"
                        component={AvatarChangeIcon}
                        style={{
                            top: "5px",
                            position: "relative",
                            right: "43px",
                            transform: "scale(0.9)",
                        }}
                        onClick={(e) => {
                            this.setState({ isPhotoUpdating: true });
                        }}
                    />
                    // </Upload> 
                    }
                </div>
                {/* {this.state.isAvatarUpdating&&<Icon component={AvatarArrowForward} style={{fontSize:"25px"}} onClick={this.changeAvatarIdForward} /> } */}
            </div>
        );
    }

    /**
     *
     * @param avatarId
     * @description sets state on selected avatar id for rendering
     */
    private selectAvatar(avatarId: number) {
        this.setState({
            changedAvatarId: avatarId,
        });
    }

    /**
     *
     * @returns Avatar Selection Modal template
     */
    private renderAvatarSelectionModal() {
        const avatars = this.prepareAvatars();
        return (
            <Modal
                width="fit-content"
                title="Avatarlar"
                okText="Kaydet"
                cancelText="İptal"
                visible={this.state.isAvatarUpdating}
                closable={false}
                footer={[
                    <Button
                        key="back"
                        className="button-default-groups-page"
                        onClick={(e) => {
                            this.setState({ isAvatarUpdating: false });
                        }}
                    >
                        İptal
                    </Button>,
                    <Button
                        key="submit"
                        className="button-send"
                        type="primary"
                        onClick={() => this.updateUserAvatarId()}
                    >
                        Kaydet
                    </Button>,
                ]}
            >
                <List
                    grid={{ column: 6, xs: 2 }}
                    // grid={{gutter: 16, column: 6}}
                    size="small"
                    className="avatar-selection"
                    dataSource={avatars}
                    renderItem={(item) => (
                        <List.Item>
                            <div
                                style={{ width: "fit-content" }}
                                onClick={(e: React.MouseEvent): void =>
                                    this.selectAvatar(item.key)
                                }
                            >
                                {item}
                            </div>
                        </List.Item>
                    )}
                />
            </Modal>
        );
    }

    /**
     *
     * @returns Photo Upload Modal
     */
     private renderPhotoUploadModal() {
        return (
            <Modal
                width="fit-content"
                title="Fotoğraf Yükleme"
                okText="Kaydet"
                cancelText="İptal"
                visible={this.state.isPhotoUpdating}
                closable={false}
                footer={[
                    <Button
                        key="back"
                        className="button-default-groups-page"
                        onClick={(e) => {
                            this.setState({ isPhotoUpdating: false });
                        }}
                    >
                        İptal
                    </Button>,
                    <Button
                        key="submit"
                        className="button-send"
                        type="primary"
                        onClick={this.updatePhoto}
                    >
                        Kaydet
                    </Button>,
                ]}
            >
                <Upload
                // onPreview={(file) => {
                //     if (!file.url && !file.preview && this.state.base64Photo != null) {
                //         file.preview = this.state.base64Photo;
                //       }
                // }}
                beforeUpload={(file, fileList) => {
                    if (file.name.includes(".jpg") || file.name.includes(".png") || file.name.includes(".jpeg")) {
                        this.setState({fileList: [file]});
                        return true;
                    }
                    message.error('Sadece JPG/PNG dosyası yükleyebilirsiniz!');
                    this.setState({fileList: []});
                    return false;
                }}
                transformFile={(file) =>{
                    const reader = new FileReader();
                    return new Promise(resolve => {
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.setState({base64Photo: reader.result});
                        };
                    });
                }}
                fileList={this.state.fileList}
                >
                    <Button type="upload">
                        <Icon type="upload" /> Profil Fotoğrafı Seç
                    </Button>
                </Upload>
            </Modal>
        );
    }

    /**
     *
     * @returns JSX template for general info
     */
    private renderGeneralInfoField(): JSX.Element {
        return (
            <Card
                data-tour="step-user-first-info"
                style={{
                    boxShadow: "0px 3px 20px #B3B3B329",
                    border: "0.5px solid #E3E6F0",
                    borderRadius: "8px",
                }}
            >
                {this.editable && <Icon
                    data-tour="step-change-gen-info"
                    style={{ float: "right" }}
                    type="edit"
                    onClick={(e) => this.showModal("verificationInfo")}
                />}
                <br></br>
                <div style={{ position: "relative" }}>
                    {this.renderAvatarAndUserPhotoField()}
                    <br></br>
                    <b
                        style={{
                            fontSize: "30px",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        {this.state.username}
                    </b>
                    <b
                        style={{
                            fontSize: "20px",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        {this.state.userStatus}
                    </b>
                    <br></br>
                    <div
                        style={{
                            background: "#F2F4F8 0% 0% no-repeat padding-box",
                            width: "-webkit-fill-available",
                            borderRadius: " 8px",
                            height: "260px",
                            marginTop: "-250px",
                            marginBottom: "4%",
                        }}
                    ></div>
                </div>
                {this.renderChangePasswordField()}
                <div
                    style={{
                        background: "#F2F4F8 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        padding: "2% 2% 0px 2%",
                        marginTop: "2%",
                    }}
                >
                    <b>{i18n.t("admin-page.name")}: </b> {this.state.firstName}{" "}
                    {this.state.lastName}
                    <br></br>
                    <br></br>
                    {/*                     <b>{i18n.t('admin-page.userStatus')}: </b> {this.state.userStatus}<br></br><br></br>
                     */}
                    <b>{i18n.t("admin-page.email")}: </b> {this.state.email}
                    <br></br>
                    <br></br>
                    <b>{i18n.t("admin-page.phoneNumber")}: </b>{" "}
                    {this.state.phone}
                    <br></br>
                    <br></br>
                    <b>{i18n.t("admin-page.birthDate")}: </b>{" "}
                    {Moment(this.state.dateOfBirth).format("D-M-YYYY")}
                    <br></br>
                    <br></br>
                    <b>{i18n.t("admin-page.IDNo")}: </b> {"*******"}
                    <br></br>
                    <br></br>
                    <b>{i18n.t("admin-page.workingState")}: </b>
                    {this.state.isWorking == "Working" ? (
                        <>{this.state.workplace}</>
                    ) : (
                        <>{i18n.t("profile-page.studentalumnus")}</>
                    )}
                    <br></br>
                    <br></br>
                </div>
            </Card>
        );
    }

    /**
     *
     * @returns JSX template for other infor
     */
    private renderOtherField(): JSX.Element {
        return (
            <Card
                data-tour="step-address-info"
                headStyle={{ backgroundColor: "#F2F4F8", fontWeight: "600" }}
                style={{
                    boxShadow: "0px 3px 20px #B3B3B329",
                    border: "0.5px solid #E3E6F0",
                    borderRadius: "8px",
                    marginTop: "2.2%",
                }}
                title="Adres Bilgileri:"
                extra={[
                    this.editable && <Icon
                        data-tour="step-edit-address-info"
                        type="edit"
                        onClick={(e) => this.showModal("otherInfo")}
                    />,
                ]}
            >
                <b>{i18n.t("admin-page.address")}: </b>
                {this.state.address}
                <br></br>
                <br></br>
                <b>{i18n.t("admin-page.city")}: </b>
                {this.state.city}
                <b style={{ marginLeft: "15%" }}>
                    {i18n.t("admin-page.town")}:{" "}
                </b>
                {this.state.town}
                <br></br>
                <br></br>
            </Card>
        );
    }

    /**
     *
     * @returns JSX template for payment information in profile page
     */
    private renderPaymentField(): JSX.Element {
        return (
            <Card
                data-tour="step-payment-info"
                headStyle={{ backgroundColor: "#F2F4F8", fontWeight: "600" }}
                style={{
                    boxShadow: "0px 3px 20px #B3B3B329",
                    border: "0.5px solid #E3E6F0",
                    borderRadius: "8px",
                }}
                title="Hesap Bilgileri:"
                extra={
                    this.state.cvatUser &&
                    !this.state.cvatUser.isSuperuser && [
                        <Icon
                            data-tour="step-edit-payment-info"
                            type="edit"
                            onClick={(e) => this.showModal("paymentInfo")}
                        />,
                    ]
                }
            >
                <b>IBAN: </b>
                {this.state.iban}{" "}
                <span style={{ margin: "35px" }}>
                    {" "}
                    {this.state.paymentRequested ? (
                        <Icon
                            style={{
                                color: "#FCCE5A",
                                fontSize: "156%",
                                top: "4px",
                                position: "relative",
                            }}
                            type="check-circle"
                        />
                    ) : (
                        <Icon
                            style={{
                                color: "#FCCE5A",
                                transform: "scale(0.8)",
                                top: "5px",
                                position: "relative",
                            }}
                            component={EmptyCircleIcon}
                        />
                    )}{" "}
                    Aylık Ödeme
                </span>{" "}
                <br></br>
                <br></br>
                <p>
                    {" "}
                    Ödenmemiş miktar 50 TL'ye ulaştığında sistemden otomatik
                    olarak ödeme yapılması için 'Aylık Ödeme'yi işaretleyin.
                </p>
            </Card>
        );
    }

    // private changeAvatarIdForward() {
    //     let newAvatarId = (this.state.avatarId % 10) +1
    //     this.setState({
    //         avatarId: newAvatarId
    //     });
    // }

    // private changeAvatarIdBackward() {
    //     let newAvatarId = this.state.avatarId == 1 ? AVATAR_MAP.size : this.state.avatarId -1
    //     this.setState({
    //         avatarId: newAvatarId
    //     });
    // }

    /**
     * @async
     * @description update user avatar id using changed state, handle response with success and error Modals
     */
    private async updateUserAvatarId() {
        let response = await cvat.users.updateAvatarId(
            this.state.user.id_id,
            this.state.changedAvatarId
        );

        this.setState({ isAvatarUpdating: false }, () => {
            if (response.status == i18n.t("response-messages.success")) {
                Modal.success({
                    title: i18n.t("keywords.success"),
                    okText: "Tamam",
                });
                this.props.verifyAuthorized();
                this.getUser();
            } else {
                Modal.error({
                    title: i18n.t("keywords.error"),
                    okText: "Tamam",
                });
            }
        });
    }

    /**
     *
     * @returns JSX template for experience information
     */
    private renderExperienceField(): JSX.Element {
        return (
            <Card
                data-tour="step-exp-info"
                headStyle={{ backgroundColor: "#F2F4F8", fontWeight: "600" }}
                style={{
                    boxShadow: "0px 3px 20px #B3B3B329",
                    border: "0.5px solid #E3E6F0",
                    borderRadius: "8px",
                    marginTop: "2.2%",
                }}
                title="Tecrübeler:"
                extra={[
                    this.editable && <Icon
                        data-tour="step-edit-exp-info"
                        type="edit"
                        onClick={(e) => this.showModal("experienceInfo")}
                    />,
                ]}
            >
                <p style={{ overflowWrap: "break-word" }}>
                    <b>{i18n.t("admin-page.computerExperience")}: </b>
                    {this.state.experienceExplanation}
                </p>
                <br></br>
                <p style={{ overflowWrap: "break-word" }}>
                    <b>{i18n.t("profile-page.ai-and-annotation")}: </b>
                    {this.state.aiExplanation}
                </p>
                <br></br>
                <p style={{ overflowWrap: "break-word" }}>
                    <b>{i18n.t("profile-page.certifications")}: </b>
                    {this.state.certificateExplanation}
                </p>
                <br></br>
                <p style={{ overflowWrap: "break-word" }}>
                    <b>{i18n.t("profile-page.programming-languages")}:</b>{" "}
                    {this.state.plExplanation}
                </p>
                <br></br>
            </Card>
        );
    }

    /**
     *
     * @returns Template for change password field
     */
    renderChangePasswordField() {
        return (
            <div
                style={{
                    background: "#F2F4F8 0% 0% no-repeat padding-box",
                    borderRadius: "8px",
                    padding: "2%",
                    marginTop: "2%",
                    marginBottom: "3.5%",
                }}
            >
                <b>Şifre: </b>*******{" "}
                {this.editable && <Icon
                    data-tour="step-change-password"
                    style={{ float: "right" }}
                    onClick={(e) => this.showModal("passwordChange")}
                    type="edit"
                />}
            </div>
        );
    }

    /**
     *
     * @returns Various Modal templates returned according to ModalType set in state
     */
    private renderModal() {
        const self = this;
        switch (this.state.modalType) {
            case "verificationInfo":
                return (
                    <Modal
                        title="Doğrulama bilgilerini sağlayınız"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "verificationInfo"
                        }
                        onOk={this.handleOk} //TODO
                        okText="Doğrula"
                        cancelText="İptal"
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                    >
                        İsminizi Girin:{" "}
                        <Input
                            placeholder="İsim Gir"
                            onChange={(e) => {
                                this.setState({ verificationFirstName: e.target.value });
                            }}
                        />
                        Soyisminizi Girin:{" "}
                        <Input
                            placeholder="Soyisim Gir"
                            onChange={(e) =>
                                this.setState({ verificationLastName: e.target.value })
                            }
                        />
                        TC Seri Nonuzu Girin:{" "}
                        <Input
                            placeholder="Seri No Gir"
                            onChange={(e) => {
                                this.setState({ serialNo: e.target.value });
                            }}
                        />
                        {i18n.t("admin-page.IDNo")}:{" "}
                        <Input
                            placeholder="TC Kimlik No Gir"
                            onChange={(e) =>
                                this.setState({ tcNo: e.target.value })
                            }
                        />
                    </Modal>
                );
            case "paymentInfo":
                return (
                    <Modal
                        title="Hesap Bilgilerinizi Güncelleyin"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "paymentInfo"
                        }
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okText="Kaydet"
                        cancelText="İptal"
                        footer={null}
                        destroyOnClose={true}
                    >
                        <PaymentsComponent />
                    </Modal>
                );
            case "passwordChange":
                return (
                    <Modal
                        title="Şifrenizi Güncelleyin"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "passwordChange"
                        }
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        okText="Kaydet"
                        cancelText="İptal"
                        destroyOnClose={true}
                    >
                        <ChangePassword />
                    </Modal>
                );
            case "otherInfo":
                return (
                    <Modal
                        title="Yeni Bilgilerinizi Girin"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "otherInfo"
                        }
                        onOk={this.handleOk}
                        okText="Kaydet"
                        cancelText="İptal"
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                    >
                        <h4>{i18n.t("admin-page.address")}:</h4>
                        <TextArea
                            rows={4}
                            placeholder={i18n.t("admin-page.address")}
                            defaultValue={this.state.address}
                            onChange={(e) =>
                                this.setState({
                                    changedAddress: e.target.value,
                                })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("admin-page.city")}:</h4>
                        <RegionDropdown
                            defaultOptionLabel={this.state.city}
                            country={"Turkey"}
                            value={this.state.changedCity}
                            onChange={(value) =>
                                this.setState({ changedCity: value })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("admin-page.town")}:</h4>

                        <TextArea
                            rows={1}
                            placeholder={i18n.t("admin-page.town")}
                            defaultValue={this.state.town}
                            onChange={(e) =>
                                this.setState({ changedTown: e.target.value })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("profile-page.workplace")}:</h4>
                        <TextArea
                            rows={1}
                            placeholder={i18n.t("profile-page.workplace")}
                            defaultValue={
                                this.state.isWorking === "Working"
                                    ? this.state.workplace
                                    : i18n.t("profile-page.studentalumnus")
                            }
                            disabled={this.state.isWorking != "Working"}
                            onChange={(e) =>
                                this.setState({
                                    changedWorkplace: e.target.value,
                                })
                            }
                        />
                    </Modal>
                );
            case "experienceInfo":
                return (
                    <Modal
                        title="Tecrübelerinizi Güncelleyin"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "experienceInfo"
                        }
                        onOk={this.handleOk}
                        okText="Kaydet"
                        cancelText="İptal"
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                    >
                        <h4>Temel Bilgisayar Becerileri:</h4>
                        <TextArea
                            rows={4}
                            placeholder="Temel Bilgisayar Becerileri"
                            defaultValue={this.state.experienceExplanation}
                            onChange={(e) =>
                                this.setState({
                                    changedBasicExplanation: e.target.value,
                                })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("profile-page.ai-and-annotation")}:</h4>
                        <TextArea
                            rows={4}
                            placeholder="Yapay Zeka ve Etiketleme Tecrübesi"
                            defaultValue={this.state.aiExplanation}
                            onChange={(e) =>
                                this.setState({ changedAI: e.target.value })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("profile-page.certifications")}:</h4>
                        <TextArea
                            rows={4}
                            placeholder="Sertifikalar"
                            defaultValue={this.state.certificateExplanation}
                            onChange={(e) =>
                                this.setState({
                                    changedCertificates: e.target.value,
                                })
                            }
                        />
                        <br></br>
                        <br></br>
                        <h4>{i18n.t("profile-page.programming-languages")}:</h4>
                        <TextArea
                            rows={4}
                            placeholder="Programlama Dilleri"
                            defaultValue={this.state.plExplanation}
                            onChange={(e) =>
                                this.setState({ changedPL: e.target.value })
                            }
                        />
                        <br></br>
                        <br></br>
                    </Modal>
                );
            case "generalInfo":
                return (
                    <Modal
                        title="Güncellenecek Bilgiler"
                        visible={
                            this.state.visible &&
                            this.state.modalType === "generalInfo"
                        }
                        onOk={this.handleOk}
                        okText="Kaydet"
                        cancelText="İptal"
                        onCancel={this.handleCancel}
                        destroyOnClose={true}
                    >
                        İsminizi Girin:{" "}
                        {() => {
                            console.log(this.state.verificationFirstName);
                            console.log(this.state.verificationLastName);
                            this.setState({
                                changedFirstName: this.state.verificationFirstName,
                            });
                        }}
                        <Input
                            defaultValue={this.state.verificationFirstName}
                            onChange={(e) => {
                                this.setState({
                                    changedFirstName: this.state.verificationFirstName,
                                });
                            }}
                            disabled={true}
                        />
                        Soyisminizi Girin:{" "}
                        {() => {
                            console.log(this.state.verificationLastName);
                            console.log(this.state.verificationFirstName);
                            this.setState({
                                changedFirstName: this.state.verificationLastName,
                            });
                        }}
                        <Input
                            defaultValue={this.state.lastName}
                            onChange={(e) =>
                                this.setState({
                                    changedLastName: this.state.verificationLastName,
                                })
                            }
                            disabled={true}
                        />
                        {i18n.t("admin-page.email")}:{" "}
                        <Input
                            defaultValue={this.state.email}
                            onChange={(e) =>
                                this.setState({
                                    changedEmail: e.target.value,
                                })
                            }
                        />
                        {i18n.t("admin-page.phoneNumber")}:{" "}
                        <Input
                            defaultValue={this.state.phone}
                            onChange={(e) =>
                                this.setState({
                                    changedPhone: e.target.value,
                                })
                            }
                        />
                    </Modal>
                );
        }
    }

    /**
     *
     * @returns Profile page template by using class functions
     */
    render() {
        let spanParameterGeneralInfo = 8;
        let spanParameterDetails = 14;
        if (this.state.width / this.state.height < 1.7) {
            spanParameterGeneralInfo = 24;
            spanParameterDetails = 24;
        }

        return (
            <div className="approval-page">
                <Text className="cvat-title">Profil</Text>
                {this.renderModal()}
                <br></br>
                <Row>
                    <Col
                        style={{
                            marginLeft: "0%",
                            marginTop: "2%",
                            marginRight: "1%",
                        }}
                        span={spanParameterGeneralInfo}
                    >
                        {this.renderGeneralInfoField()}
                    </Col>
                    <Col
                        style={{
                            marginLeft: "0%",
                            marginTop: "2%",
                            marginRight: "1%",
                        }}
                        span={spanParameterDetails}
                    >
                        <Row>
                            {this.renderPaymentField()}
                            {this.renderOtherField()}
                            {this.renderExperienceField()}
                        </Row>
                    </Col>
                </Row>
                {this.renderAvatarSelectionModal()}
                {this.renderPhotoUploadModal()}
            </div>
        );
    }
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfilePageComponent)
);
