import React, {Component} from 'react';
import {
    Row,
    Col,
    Checkbox,
    Button,
    Table,
    Popconfirm,
    ConfigProvider,
    Collapse,
    List,
    Icon
} from 'antd';
import Text from 'antd/lib/typography/Text';

import ViewerSelector from "./viewer-selector-task";
import i18n from '../../i18n'
import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
const core = getCore();
import tr_TR from 'antd/lib/locale/tr_TR';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Column from 'antd/lib/table/Column';
interface Props{
    taskInstance:any;
    user: any;
}

/**
 * @class_component TaskViewers component for task viewer users view rendering
 */
class TaskViewers extends Component<Props> {
    constructor(props){
        super(props);
        this.state = {
            annotaTask: any,
            users: [],
            userGroups: [],
        };
        this.getSelectedUsers = this.getSelectedUsers.bind(this);
    }
    componentDidMount() {
        this.getAnnotaTask(this.props.taskInstance.id)
    }

    /**
     * @async
     * @param taskId
     * @sets state on annotaTask
     */
    async getAnnotaTask(taskId: any){
        let response = null;
        response = await core.tasks.getAnnotaTasks(null,taskId);
        this.setState({
            annotaTask : response,
        }, () => {
        });
    }

    /**
     *
     * @param userList
     * @sets state on users given param
     */
    public getSelectedUsers(userList: any){
        this.setState({
            users : userList,
        });
    }

    /**
     * @async
     * @param id
     * @param userList
     * @description update state, set task viewers with api proxy call
     */
    public async setUsers(id: any, userList?: any){
        let usersTotal;
        if(userList){
            let userTotalList = new Array()
            for(let i = 0; i < userList.length; i++){
                userTotalList.push(userList[i].id.username)
            }
            usersTotal = {
                "userIds": userTotalList
            }
        }else{
            usersTotal = {
                "userIds": this.state.users
            }
        }
        await core.tasks.setViewersTask(id,JSON.stringify(usersTotal));
        this.getAnnotaTask(id)
    }

    /**
     * @async
     * @param id
     * @param users
     * @description updates state, remove viewer from task
     */
    public async removeUsers(id: any, users: any){
        let usersTotal = {
            "userIds": users
        }
        await core.tasks.removeViewersTask(id,JSON.stringify(usersTotal));
        this.getAnnotaTask(id)
    }

    /**
     *
     * @param test
     * @returns expansion template for viewer table
     */
    public handleExpand(test: any){
        return(
            <Table
                title={()=><h2>Grup Üyeleri</h2>}
                bordered
                style={{ marginTop: '2%' }}
                rowKey={record => record.id.id}
                dataSource={test.members}
                pagination={false}
                showHeader={false}
                locale={{ emptyText: 'Sonuç Bulunamadı' }}
            >
                <Column dataIndex="id.username" key="id.username" />
            </Table>
        )
    }

    /**
     *
     * @returns Viewer table template with functionality
     */
    render () {
        const columns = [
            {
                title: i18n.t('admin-page.userName'),
                dataIndex: 'username',
                key: 'username',
                render: text => <a>{text}</a>,
            },
            {
                title: i18n.t('datasets.delete'),
                key: 'action',
                render: (record) => (
                      <Popconfirm
                          title={i18n.t('datasets.deleteUserWarning')}
                          cancelText={i18n.t('keywords.no')}
                          okText={i18n.t('keywords.yes')}
                          onConfirm={
                              (e) => {
                                this.removeUsers(this.state.annotaTask.id.id,[record.username])
                              }
                          }
                      >
                          <Button > {i18n.t('datasets.delete')} </Button>
                      </Popconfirm>
                ),
              },
        ];

        return (
            <div className='mini-component' style={{margin:"1.5%"}}>

                <div>
                    <ConfigProvider locale={tr_TR}>
                        <Row type='flex' style={{ marginTop: '1%' }}>
                            <Col span = {24} >
                                <Row>
                                    <Col span={18}>
                                        <ViewerSelector
                                            parentMethod={this.getSelectedUsers}
                                            onChange={(value: string): void => {
                                            }}
                                            size="default"
                                            placeholder="Veri Sağlayıcı Ara"
                                            className="add-user-task-page"
                                            showArrow={true}
                                            user={this.props.user}
                                        />
                                    </Col>
                                    <Col span={4} style={{ marginLeft: '1%' }}>
                                        <Button type='primary' className="button-segment-details" onClick={() => this.setUsers(this.state.annotaTask.id.id)}>{i18n.t('keywords.add')}</Button>
                                    </Col>
                                </Row>
                                <Col span={24} style={{marginTop:"1%"}}>
                                    <List
                                        style={{ marginTop: '2%' }}
                                        rowKey={record => record.id}
                                        dataSource={this.state.annotaTask.viewers}
                                        size="small"
                                        pagination={{ size:"small",defaultPageSize: 5, showSizeChanger: true, position: 'bottom' , pageSizeOptions: ['5', '10', '20']}}
                                        locale={{ emptyText: "Paylaşılan Veri Sağlayıcı Yok" }}
                                        renderItem={record => (
                                            <div style={{ display: "flex" }}>
                                                <Text style={{ marginBottom: "10px", marginRight: "7px", padding: "2%", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>
                                                    {record.username}
                                                    <Popconfirm
                                                    title={i18n.t('datasets.deleteUserWarning')}
                                                    cancelText={i18n.t('keywords.no')}
                                                    okText={i18n.t('keywords.yes')}
                                                    onConfirm={
                                                        (e) => {
                                                            this.removeUsers(this.state.annotaTask.id.id,[record.username])
                                                        }
                                                    }
                                                    >
                                                        <Icon style={{float:"right", padding:"1%"}} type="delete" />
                                                    </Popconfirm>
                                                </Text>
                                            </div>
                                        )}
                                        >
                                    </List>
                                </Col>
                            </Col>
                        </Row>
                    </ConfigProvider>
                </div>
            </div>
        )
    }
}
export default (TaskViewers);
