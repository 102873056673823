import './styles.scss';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Collapse, Pagination, Table, List, Icon, Spin, Modal } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';
import Text from 'antd/lib/typography/Text';

import getCore from 'cvat-core-wrapper';
import i18n from '../i18n'
import Column from 'antd/lib/table/Column';
import tr_TR from 'antd/lib/locale/tr_TR';

import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';
import moment from 'moment';
import { NoDataVector } from 'icons';

const cvat = getCore();
const baseURL = cvat.config.backendAPI.slice(0, -7);
let backendEndPoint = String(cvat.config.backendAPI)

let taskViews = new Array();
let TaskList = <div></div>
let currentTasks = new Array();
let previewCurrent;

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns auth properties
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 * @param dispatch
 * @returns verifyAuthorized action dispatcher
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type TaskApprovalPageProps = StateToProps & DispatchToProps & RouteComponentProps;

/**
 * @class_component Task Approval Page for /approve-tasks route
 */
class TaskApprovalPage extends React.PureComponent<TaskApprovalPageProps> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentPage: 1,
            totalTaskCount: 0,
            width: 1920,
            height: 1080,
            imageMap: {},
            pageLoading: true
        }

        this.handleTasks = this.handleTasks.bind(this);
        this.renderJobViewsAndJobs = this.renderJobViewsAndJobs.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        let self = this;
        this.rejectTask = this.rejectTask.bind(this);
        const tasksAsync = self.getTasks(1);
        tasksAsync.then(self.handleTasks.bind(self));
        taskViews = new Array();
    }

    public printPreview(data: any) {
        previewCurrent = data
    }

    async handleTasks(tasks: any[]) {
        let spanParameter = parseInt(15000/this.state.width)

        let zoom = '100%'
        if(innerHeight >= innerWidth){
            spanParameter = 24,
            zoom = '70%'
        }

        taskViews = new Array();
        taskViews = tasks.map(item=>
            <Col span={spanParameter} style={{zoom:zoom}}>
                <Row className='cvat-test-tasks-list-item-updated' type='flex'>
                    <Col span={24}>
                        <div className='cvat-test-task-item-preview-wrapper-updated'>
                            <img alt={i18n.t('task-page.preview')} className='cvat-test-task-item-preview' src={this.state.imageMap instanceof Map ? this.state.imageMap.get(item.id.id):""} />
                        </div>
                    </Col>
                    <div style={{marginLeft:"3%",marginRight:"3%", marginTop:"5%", width:"100%", height:"100%", maxHeight:"270px"}}>
                        <Row type="flex" gutter={[48, 0]}>
                            <Col span={24}>
                                <Text strong style={{fontSize:"16px",color:"#5A5C69"}} className='cvat-text-color'>Görev#{item.id.id}: {item.id.name}</Text>
                            </Col>
                            <Col span={24}>
                                <Text type="secondary" style={{color:"#5A5C69"}}>Oluşturulma Tarihi: {moment(item.id.created_date).locale('tr').format('Do MMMM YYYY')}</Text>
                            </Col>
                        </Row>
                        <br></br>
                        <Row type="flex" gutter={[48, 48]}>
                            <Col span={24}>

                            {this.renderJobViewsAndJobs(item.id)}

                            </Col>
                        </Row>
                        <div style={{position:"absolute", bottom:"5px", minWidth:"82%"}}>
                            <Text style={{position: "relative", top: "15px"}}>{item.doccanoProjectId == 0?'Görsel':'Metin'}</Text>
                            <div style={{float:"right"}}>
                                {/* <Button className="button-default-groups-page" id={item.id.name +"Reject"} onClick={() => this.rejectTask(item.id.id)} style={{ margin: "8px",borderRadius:"4px",backgroundColor:"inherit" }}>{i18n.t('task-approval.reject')}</Button> */}
                                <Button className="button-default-groups-page" id={item.id.name +"Reject"} onClick={() => Modal.confirm({
                                    title: `${item.id.id} numaralı görev reddedildiğinde görev silinecek.`,
                                    content: 'Görevle ilişkilendirilmiş bütün veriler silinecek. Devam edilsin mi?',
                                    onOk: () => {
                                        this.rejectTask(item.id.id)
                                    },
                                    okButtonProps: {
                                        type: 'danger',
                                    },
                                    okText: 'Sil',
                                })} style={{ margin: "8px",borderRadius:"4px",backgroundColor:"inherit" }}>{i18n.t('task-approval.reject')}</Button>
                                <Button className="button-groups-page" id={item.id.name + "Approve"} type='primary' onClick={() => this.approveTask(item.id.id)} style={{ margin: "8px",borderRadius:"4px" }}>{i18n.t('task-approval.approve')}</Button>
                            </div>
                         </div>

                    </div>
                </Row>
            </Col>
            )
        /* taskViews.push(
            <Table style={{ marginTop: '2%' }} rowKey={record => record.id.id} dataSource={tasks} pagination={false} locale={{emptyText: 'Sonuç Bulunamadı'}}>
                <Column title="Önizleme" render={(record) =>
                    <img id={record.id.name} src={backendEndPoint + '/tasks/' + record.id.id + '/data?type=preview'} width="200px" />
                } />
                <Column title={i18n.t('task-approval.taskName')} dataIndex="id.name" />
                <Column title="Kesitler" render={(record) =>
                    <>{this.renderJobViewsAndJobs(record.id)}</>
                } />
                <Column title={i18n.t('task-approval.createdAt')} render={(record) =>
                    <>{record.id.created_date.toString().substring(0, 10)}</>
                } />
                <Column title={i18n.t('task-approval.frameCount')} dataIndex="id.frameCount" />
                <Column title={i18n.t('task-approval.segmentSize')} dataIndex="id.segmentSize" />
                <Column title="İşlemler" key="actions" render={(record) =>
                    <div>
                        <Button id={record.id.name +"Approve"} type='primary' onClick={() => this.approveTask(record.id.id)} style={{ margin: "8px" }}>{i18n.t('task-approval.approve')}</Button>
                        <Button id={record.id.name + "Reject"} type='primary' onClick={() => this.rejectTask(record.id.id)} style={{ margin: "8px" }}>{i18n.t('task-approval.reject')}</Button>
                    </div>
                } />
            </Table>
        ); */

        this.setState({
            state: this.state
        });
    }

    /**
     *
     * @param id
     * @returns job list of task with passed id
     */
    private renderJobViewsAndJobs(id: any) {
        let taskEngine = id;

        let jobsOfTask = new Array()
        for (let i = 0; i < taskEngine.segments.length; i++) {
            jobsOfTask.push(taskEngine.segments[i].jobs[0])
        }

        return  <ConfigProvider locale={tr_TR}>
                    <List
                    className="task-approval-segments-list"
                        size="small"
                        grid={{column:3}}
                        itemLayout="vertical"
                        pagination={{size:"small", defaultPageSize: 9, position: 'bottom',hideOnSinglePage:true}}
                        dataSource={jobsOfTask}
                        renderItem={item => (
                            <List.Item >
                                <a style={{color:"#6C63FF"}}href={`/tasks/${taskEngine.id}/jobs/${item.id}`}>{`İş #${item.id}`}</a>
                            </List.Item>
                        )}
                    />
                </ConfigProvider>;
    }

    /**
     * @async
     * @param taskId
     * @description approve task api proxy call, if success update views, sets state
     */
    private async approveTask(taskId: any) {
        let response = null;
        response = await cvat.tasks.approveTask(Number(taskId));
        if (response.data.status == i18n.t('response-messages.success')) {
            const tasksAsync = this.getTasks(this.state.currentPage);
            tasksAsync.then(this.handleTasks.bind(self));
            taskViews = new Array();
            TaskList = <Row type='flex' justify='space-around' align='middle'>{taskViews}</Row>
            this.setState({
                state: this.state
            })
        }
    }

    /**
     * @async
     * @param taskId
     * @description task rejection api proxy call, if successful update state
     */
    private async rejectTask(taskId: any) {
        let response = null;
        response = await cvat.tasks.rejectTask(Number(taskId));
        if (response.data.status == i18n.t('response-messages.success')) {
            const tasksAsync = this.getTasks(this.state.currentPage);
            tasksAsync.then(this.handleTasks.bind(self));
            taskViews = new Array();
            TaskList = <Row type='flex' justify='space-around' align='middle'>{taskViews}</Row>
            this.setState({
                state: this.state
            })
        }
    }

    public componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
        this.props.verifyAuthorized();
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    /**
     * @description resize width and height of window
     */
    public handleResize() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: innerWidth,
            height: innerHeight
        },()=>
            this.handleTasks(currentTasks)
        )
    }

    /**
     * @async
     * @param index
     * @returns tasks on page index
     */
    async getTasks(index: any) {

        this.setState({
            pageLoading: true
        });
        let tasks;
        tasks = await cvat.tasks.getAnnotaTasks('candidate', null, index);
        currentTasks = tasks;
        let imageMap = new Map();
        for (var i = 0; i < currentTasks.length; i++) {
            try {
            let previewShowTask = await cvat.frames.getPreview(currentTasks[i].id.id)
            imageMap.set(currentTasks[i].id.id, previewShowTask);
            } catch (e) {
                // DO Nothing for now
            }
        }
        this.setState({
            totalTaskCount: tasks.count,
            imageMap: imageMap,
            pageLoading: false
        });
        return tasks;
    }

    /**
     *
     * @returns Spinner component
     */
    public renderLoader() {
        return <Spin size='large' className='cvat-spinner' />
    }

    /**
     *
     * @returns Task List template
     */
    private renderTaskList() {
        let permissions = this.props.auth.annotaUser[0].group_role_permissions
        let hasApproveTasksPermission = permissions.filter(object => object.permission_id__name  == 'approve_tasks').length > 0

        if (this.props.auth.user.isSuperuser || hasApproveTasksPermission) {
            if(taskViews.length>0){

                TaskList = <div style={{paddingTop:"15px"}}>
                    <Row type='flex' justify='space-around' align='middle'>{taskViews}</Row>
                    <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                        <Col>
                            <ConfigProvider locale ={trTR}>
                                <Pagination defaultCurrent={1}
                                        pageSize={9}
                                        onChange={this.handleChange}
                                        current={this.state.currentPage}
                                        total={this.state.totalTaskCount}
                                        showQuickJumper={true}
                                />
                            </ConfigProvider>
                        </Col>
                    </Row>
                </div>
            }else{
                TaskList = <div style={{paddingTop: "15px",position: "fixed",display: "flex",left: "50%",top: "50%",flexDirection: "column",alignItems: "center"}}><Icon component={NoDataVector}/><br />
                <Text style={{ fontSize: "18px" }} type="secondary">VERİ YOK</Text><br/><Text type="secondary">Henüz onay bekleyen görev yok.</Text></div>
            }
        }
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('header.pendingTasks')}</Text>

                { this.state.pageLoading ? this.renderLoader() : TaskList }
            </div>
        )
    }

    /**
     *
     * @returns Task list approval component by using class functions
     */
    public render() {
        return this.renderTaskList();
    }

    /**
     *
     * @param value
     * @description change page and get tasks accordingly
     */
    handleChange = value => {
        this.setState({
            currentPage: value,
        });
        const tasksAsync = this.getTasks(value);
        tasksAsync.then(this.handleTasks.bind(self));
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskApprovalPage));
