// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import moment from 'moment';
import i18n from '../i18n'

import getCore from '../cvat-core-wrapper';
import { any } from 'prop-types';
const cvat = getCore();
import consts from 'consts';
const {AVATAR_MAP} = consts;
import { Tooltip, Avatar } from 'antd';

export interface ItemProps {
    taskType: string;
    taskInstance: any;
    previewImage: string;
    user: any;
    continuingTasks: any;
    updateTests(userId?: any): void;
    updateTrainings(userId?: any): void
}

/**
 * @class_component Test Training Item Component
 */
class TestTrainingItemComponent extends React.PureComponent<ItemProps & RouteComponentProps> {
    constructor(props: ItemProps & RouteComponentProps) {
        super(props);
        this.state = {
            annotaTask: any,
            userHasJobOnTask: false,
            previewState: any
        }
    }
    /**
     *
     * @param response
     * @description set error on Modal
     */
    public popupError(response: string) {
        if (response != i18n.t('response-messages.success')) {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: response,
            });
        }
    }

    /**
     * @async
     * @description get preview api proxy call, sets previes state
     */
    public async getPreview(){
        const { taskInstance, previewImage } = this.props;
        let previewShowTask = await cvat.frames.getPreview(taskInstance.id)
        this.setState({
            previewState: previewShowTask
        })

    }

    /**
     * @async
     * @param userId
     * @param jobId
     * @param state
     * @description change training state
     */
    private async handleClickCompleteTraining(userId: any, jobId: any, state: string) {
        let approveResponse = null;
        approveResponse = await cvat.jobs.trainingstate(Number(userId), Number(jobId), state);
        this.props.updateTrainings(userId)
        this.popupError(approveResponse.data.status);
        this.componentDidMount()
    }

    /**
     * @async
     * @param userId
     * @param taskId
     * @description select training and update state
     */
    private async handleClickSelectTraining(userId: any, taskId: any) {
        let approveResponse = null;
        approveResponse = await cvat.jobs.selecttraining(Number(userId), Number(taskId));
        this.popupError(approveResponse.data.status);
        if (approveResponse.data.status === "Başarılı") {
            const trainingTasksContinuing = await cvat.jobs.getTrainingList('started', this.props.user.id);
            let record = trainingTasksContinuing.results.filter(object => object.task_id == taskId)[0];
            if(this.detecMobile()){
                this.props.updateTrainings(userId)
            }else{
                window.location.replace("tasks/" + record.task_id + "/jobs/" + record.jobId)
            }
        }
        this.componentDidMount()
        //window.location.reload();
    }

    /**
     * @async
     * @param userId
     * @param jobId
     * @description complete test and update state
     */
    private async handleClickCompleteTest(userId: any, jobId: any) {
        let approveResponse = null;
        approveResponse = await cvat.jobs.teststate(Number(userId), Number(jobId), 'completed');
        this.props.updateTests(userId)
        this.popupError(approveResponse.data.status);
        this.componentDidMount()
    }

    /**
     *
     * @param id
     * @param list
     * @returns bool if id exists in list
     */
    private searchID(id: any, list: any[]) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].task_id == id)
                return true;
        }
        return false;
    }

    /**
     *
     * @returns preivew template
     */
    private renderPreview(): JSX.Element {
        const { previewImage } = this.props;
        let previewShow;
        if (previewImage != null && previewImage != "") {
            previewShow = previewImage
        }
        else {
            previewShow = "https://lh3.googleusercontent.com/proxy/o41TXuOJYnVr7oLT9M5aIdJGjno_jHsixB9eR1jZlfiQI534gdRdUxRqma5t69pbdG2jLk5g8O8a_w90DScLPlXT0s_NtywwfXK5ruMhGjwXaEhU3LeenulR4ACw-GkKVO3Ir082ODio3FW7Q-DY-IX9hXCb"
        }
        return (
                <Col span={24}>
                    <div className='cvat-test-task-item-preview-wrapper-updated'>
                        <img alt={i18n.t('task-page.preview')} className='cvat-test-task-item-preview' src={this.state.previewState} />
                    </div>
                </Col>
        );
    }

    /**
     *
     * @returns action row template
     */
    private renderActionRow() {
        const{continuingTasks,taskInstance,user} = this.props;
        let records = continuingTasks.filter(object => object.task_id == taskInstance.id);
        let colView = new Array();
        for (let i = 0; i < records.length; i++) {
            let record = records[i];
            if (record.state === "started") {
                colView.push(<Col span={12}>
                    <Button className="button-default-tests-page" id={taskInstance.name + "CompleteTest"} type='default' size="large" onClick={() => this.handleClickCompleteTest(user.id, record.jobId)}>{i18n.t('test-training.complete')} </Button>
                    </Col>);
                if(this.detecMobile()){
                    colView.push(<Col span={12}><Button className="button-default-tests-page"  style={{float:"right",border:"none", color: "#6C63FF"}} ghost id={taskInstance.name  + "Continue"} type='primary' size="large"
                        onClick={(): void => {
                            Modal.info({
                                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                                okText: 'Tamam',
                            })
                        }}
                        >
                        {i18n.t('test-training.continue')}
                        <Avatar style={{marginLeft:"5px"}}src={AVATAR_MAP.get(user.avatarId)} />
                    </Button></Col>)
                }else{
                    colView.push(<Col span={12}><Button className="button-default-tests-page"  style={{float:"right",border:"none", color: "#6C63FF"}} ghost id={taskInstance.name  + "Continue"} type='primary' size="large" >
                        <Link style={{color: "#6C63FF"}} to={{ pathname: '/tasks/' + record.task_id + '/jobs/' + record.jobId }}> {i18n.t('test-training.continue')}</Link>
                        <Avatar style={{marginLeft:"5px"}} src={AVATAR_MAP.get(user.avatarId)} />
                    </Button></Col>)
                }

            }
        }
        return (
            <div style={{width:"84%",position:"absolute", bottom:"3%"}}>
            <Row type="flex" align="bottom" justify={colView.length>0?"space-between":"end"} gutter={[0,24]}>
            {colView.length>0?
                colView:
                (taskInstance.owner.id != user.id) ?
                <Col span={24}>
                    <Button className="button-default-tests-page"  style={{float:"right",border:"none", color:"#6C63FF"}} id={taskInstance.name + "Select"} type='primary' size="large" ghost onClick={() => this.handleClickSelectTest(user.id, taskInstance.id)}>
                        {i18n.t('test-training.select')}
                    </Button>
                </Col>: null
            }
            </Row>
            </div>
        );
    }

    /**
     *
     * @returns training action template
     */
    private getTrainingActionColumn() {
        const{continuingTasks,taskInstance,user} = this.props;

        let record = continuingTasks.filter(object => object.task_id == taskInstance.id)[0];
        let colView = new Array();
        if (this.searchID(taskInstance.id, continuingTasks)){
            colView.push(<Col span={5}>
                <Button id={taskInstance.name + "CompleteTraining"} className="button-default-tests-page"  type='primary' size='large' onClick={() => this.handleClickCompleteTraining(user.id, record.jobId, 'completed')}>{i18n.t('test-training.complete')} </Button>
                </Col>
                );
            colView.push(<Col span={5}>
            <Button id={taskInstance.name  + "AbortTraining"} className="button-default-tests-page" ghost type='primary' style={{border:"none",color: "#6C63FF"}} size='large' onClick={() => this.handleClickCompleteTraining(user.id, record.jobId, 'aborted')}>{i18n.t('test-training.abort')} </Button>
            </Col>  );
            if(this.detecMobile()){
                colView.push(<Col span={12}><Button id={taskInstance.name  + "Continue"} type='primary' ghost size='large' className="button-default-tests-page"  style={{float:"right",border:"none", color: "#6C63FF"}}
                    onClick={(): void => {
                        Modal.info({
                            content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                            okText: 'Tamam',
                        })
                    }}
                    >
                    {i18n.t('test-training.continue')}
                    <Avatar src={AVATAR_MAP.get(user.avatarId)} />
                </Button></Col>);
            }else{
                colView.push(<Col span={12}><Button id={taskInstance.name  + "Continue"} type='primary' ghost size='large' className="button-default-tests-page"  style={{float:"right",border:"none", color: "#6C63FF"}} >
                    <Link style={{color: "#6C63FF"}} to={{ pathname: '/tasks/' + record.task_id + '/jobs/' + record.jobId }}> {i18n.t('test-training.continue')}</Link>
                    <Avatar src={AVATAR_MAP.get(user.avatarId)} />
                </Button></Col>);
            }


        }
        return(
            <div style={{width:"84%",position:"absolute", bottom:"3%"}}>
                <Row type="flex" align="bottom" justify={colView.length>0?"space-between":"end"} gutter={[0,24]}>
                {colView.length>0?
                        colView:
                        <Col span={22}>
                            <Button id={taskInstance.name  + "Select"} type='primary' size='large' className="button-default-tests-page"  style={{float:"right",border:"none", color:"#6C63FF"}}  ghost onClick={() => this.handleClickSelectTraining(user.id, Number(taskInstance.id))}>{i18n.t('test-training.selectTraining')} </Button>
                        </Col>
                    }
                </Row>
            </div>
            );
    }

    /**
     *
     * @returns statuses template
     */
    renderStatus(){
        const {continuingTasks, taskInstance,taskType} = this.props;
        if(taskType=="test"){

            let statuses = new Array();
            let records = continuingTasks.filter(object => object.task_id == taskInstance.id);
            if(records.length){
                for (let i = 0; i < records.length; i++) {
                    let record = records[i];
                    statuses.push( <div id={taskInstance.name  + "TaskState"}>

                    <Text >Durum: {this.getTaskState(record)} </Text>
                </div>);

                }
            }
            return statuses
        }else{
            return this.searchID(taskInstance.id, continuingTasks) ? <Text>Durum: <Text className='cvat-test-pending-color'>{i18n.t('test-training.inProgress')}</Text></Text> :<Text>Durum: - </Text>
        }
    }

    /**
     * @async
     * @param userId
     * @param taskId
     * @description select test and update state
     */
    private async handleClickSelectTest(userId: any, taskId: any) {
        let approveResponse = null;
        approveResponse = await cvat.jobs.selecttest(Number(userId), Number(taskId));
        this.popupError(approveResponse.data.status);
        if (approveResponse.data.status === "Başarılı") {
            const testTasksContinuing = await cvat.jobs.getTestJobs('started', this.props.user.id);
            let record = testTasksContinuing.filter(object => object.task_id == taskId)[0];
            if(this.detecMobile()){
                this.props.updateTests(userId)
            }else{
                window.location.replace("tasks/" + record.task_id + "/jobs/" + record.jobId)
            }
        }
        this.componentDidMount()
        //window.location.reload();
    }

    /**
     *
     * @returns description JSX template
     */
    private renderDescription(): JSX.Element {
        // Task info
        const { taskInstance, user } = this.props;
        const { id } = taskInstance;

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;
        let marginTop = '-5%'
        if(user.groups.length == 1 && (user.groups.includes('candidate') || user.groups.includes('annotator')))
            marginTop = '0%'
        return (
/*                 <Col span={24} className='cvat-task-item-description' style={{marginLeft:'5%', marginTop:marginTop}}>
 */                    <Text strong style={{fontSize:"16px",color:"#5A5C69"}} className='cvat-text-color'>{name}</Text>
/*                 </Col>
 */        );
    }

    /**
     * @deprecated is not used anymore.
     * @returns task type template
     */
    private renderTaskType(): JSX.Element {
        const {
            taskInstance,
            user
        } = this.props;

        let viewer = taskInstance.tasksIdAnnota[0].viewers.filter(vUser => user.id == vUser.id).length>0
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        let typeTask = ''
        if(taskInstance.tasksIdAnnota[0].doccanoProjectId != 0)
            typeTask = 'Metin'
        else
            typeTask = 'Görsel'

        return (
            // <Row type='flex' justify='space-between'>
            // //     <Col span={6} style={{marginBottom:'-5%'}}>
            // //         <Col >
            // //             <Text style={{color:"#5A5C69"}} type='secondary'>{typeTask}</Text>
            // //         </Col>
            // //     </Col>
            //     {viewer &&
            //     <Col span={17} style={{marginBottom:'-5%'}}>
            //         <Col >
            //             <Text style={{color:"#5A5C69"}} type='secondary'>Paylaşılan Görev: {owner} tarafından</Text>
            //         </Col>
            //     </Col>
            //     }
            // </Row>
            <Col span={23} >
                <Col >
                    <Text style={{color:"#5A5C69"}} type='secondary'>{typeTask}</Text>
                    {viewer &&
                        <Text style={{color:"#5A5C69", float:'right'}} type='secondary'>Paylaşılan Görev: {owner} tarafından</Text>
                     }
                </Col>

            </Col>

        );
    }

    /**
     *
     * @returns boolean true if mobile device
     */
    public detecMobile() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true
        } else {
            return false
        }
    }

    /**
     *
     * @param record
     * @returns record state template
     */
    private getTaskState(record: string) {
        if (record.state === "completed")
            return <Text strong className='cvat-test-pending-color'>{i18n.t('test-training.stateWaitingReview')}</Text>;
        else if (record.state === "succeeded")
            return record.score>0 ? <Text strong className='cvat-test-successful-color'>{(i18n.t('test-training.stateSucceeded') + " " + ((record.score - 0.5) * 100).toPrecision(3) + "%")}</Text> : <Text strong className='cvat-test-successful-color'>{i18n.t('test-training.stateSucceeded')}</Text>
        else if (record.state === "failed")
            return record.score>0 ? <Text strong className='cvat-test-unsuccessful-color'>{(i18n.t('test-training.stateFail') + " " + ((record.score - 0.5) * 100).toPrecision(3) + "%") }</Text>  :  <Text strong className='cvat-test-unsuccessful-color'>{i18n.t('test-training.stateFail')}</Text>
        return <Text strong className='cvat-test-inprogress-color'>{(i18n.t('test-training.inProgress'))}</Text>;
    }

    public componentDidMount() {
        const { taskInstance } = this.props;
        this.getTaskState(taskInstance)
        this.getPreview()
    }

    /**
     *
     * @returns test training item JSX template using class functions
     */
    public render(): JSX.Element {
        const {
            taskInstance,
            user,
            taskType
        } = this.props;
        const style = {};

        return (
            <Row className='cvat-test-tasks-list-item-updated' type='flex' style={{ ...style }}>
                {this.renderPreview()}
                <div style={{marginLeft:"3%",marginRight:"3%", marginTop:"5%", width:"100%", height:"100%", maxHeight:"270px"}}>
                    <Row type="flex" gutter={[48, 0]}>
                        <Col span={24}>
                            {this.renderDescription()}
                        </Col>
                    </Row>
                    <br></br>
                    <Row type="flex" gutter={[48, 48]}>
                        <Col span={24}>
                            {this.renderStatus()}
                        </Col>
                    </Row>

                    {taskType=="test" &&this.renderActionRow()}
                    {taskType=="training" &&this.getTrainingActionColumn()}
                </div>
            </Row>
        )
    };
}

export default withRouter(TestTrainingItemComponent);
