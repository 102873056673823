/**
 * @description video type enums and values
 */
export enum VideoTypeList {
    mts = 'mts',
    qtvr = 'qtvr',
    divx = 'divx',
    mxu = 'mxu',
    mpeg = 'mpeg',
    flc = 'flc',
    fli = 'fli',
    flv = 'flv',
    vdr = 'vdr',
    movie = 'movie',
    anim = 'anim',
    _3gp2 = '3gp2',
    mpls = 'mpls',
    wmp = 'wmp',
    m2ts = 'm2ts',
    f4v = 'f4v',
    _3gpp = '3gpp',
    wmv = 'wmv',
    dv = 'dv',
    vivo = 'vivp',
    fxm = 'fxm',
    webm = 'webm',
    lrv = 'lrv',
    qt = 'qt',
    rv = 'rv',
    ts = 'ts',
    nsv = 'nsv',
    cpi = 'cpi',
    mk3d = 'mk3d',
    avf = 'avf',
    avi = 'avi',
    viv = 'viv',
    axv = 'axv',
    rvx = 'rvx',
    vob = 'vob',
    moov = 'moov',
    _3g2 = '3g2',
    _3ga = '3ga',
    _3gp = '3gp',
    ogg = 'ogg',
    ogm = 'ogm',
    mkv = 'mkv',
    ogv = 'ogv',
    bdm = 'bdm',
    clpi = 'clpi',
    mng = 'mng',
    mp2 = 'mp2',
    mp4 = 'mp4',
    m1u = 'm1u',
    mov = 'mov',
    mpe = 'mpe',
    mpg = 'mpg',
    mpl = 'mpl',
    m2t = 'm2t',
    bdmv = 'bdmv',
    m4u = 'm4u',
    m4v = 'm4v',
}
