// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ExtendedKeyMapOptions } from 'react-hotkeys';

import { boundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActions, AuthActionTypes } from 'actions/auth-actions';
import { ShortcutsActions, ShortcutsActionsTypes } from 'actions/shortcuts-actions';
import { ShortcutsState } from './interfaces';
import i18n from '../i18n'

function formatShortcuts(shortcuts: ExtendedKeyMapOptions): string {
    const list: string[] = shortcuts.sequences as string[];
    return `[${list.map((shortcut: string): string => {
        let keys = shortcut.split('+');
        keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
        keys = keys.join('+').split(/\s/g);
        keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
        return keys.join(' ');
    }).join(', ')}]`;
}

const defaultKeyMap = {
    /* SWITCH_SHORTCUTS: {
        name: 'Show shortcuts',
        description: 'Mevcut kısayollar listesini aç/kapa',
        sequences: ['f1'],
        action: 'keydown',
    }, */
    /* OPEN_SETTINGS: {
        name: 'Open settings',
        description: 'Go to the settings page or go back',
        sequences: ['f2'],
        action: 'keydown',
    }, */

    SWITCH_ALL_LOCK: {
        name: i18n.t('shortcuts-dialog.switchAllLock'),
        description: i18n.t('shortcuts-dialog.switchAllLockDesc'),
        sequences: ['t+l'],
        action: 'keydown',
    },
    SWITCH_LOCK: {
        name: i18n.t('shortcuts-dialog.switchLock'),
        description: i18n.t('shortcuts-dialog.switchLockDesc'),
        sequences: ['l'],
        action: 'keydown',
    },
    SWITCH_ALL_HIDDEN: {
        name: i18n.t('shortcuts-dialog.switchAllHidden'),
        description: i18n.t('shortcuts-dialog.switchAllHiddenDesc'),
        sequences: ['t+h'],
        action: 'keydown',
    },
    SWITCH_HIDDEN: {
        name: i18n.t('shortcuts-dialog.switchHidden'),
        description: i18n.t('shortcuts-dialog.switchHiddenDesc'),
        sequences: ['h'],
        action: 'keydown',
    },
    SWITCH_OCCLUDED: {
        name: i18n.t('shortcuts-dialog.switchOccluded'),
        description: i18n.t('shortcuts-dialog.switchOccludedDesc'),
        sequences: ['q', '/'],
        action: 'keydown',
    },
    SWITCH_KEYFRAME: {
        name: i18n.t('shortcuts-dialog.switchKeyframe'),
        description: i18n.t('shortcuts-dialog.switchKeyframeDesc'),
        sequences: ['k'],
        action: 'keydown',
    },
    SWITCH_OUTSIDE: {
        name: i18n.t('shortcuts-dialog.switchOutside'),
        description: i18n.t('shortcuts-dialog.switchOutsideDesc'),
        sequences: ['o'],
        action: 'keydown',
    },
    DELETE_OBJECT: {
        name: i18n.t('shortcuts-dialog.deleteObject'),
        description: i18n.t('shortcuts-dialog.deleteObjectDesc'),
        sequences: ['del', 'shift+del'],
        action: 'keydown',
    },
    TO_BACKGROUND: {
        name: i18n.t('shortcuts-dialog.toBackground'),
        description: i18n.t('shortcuts-dialog.toBackgroundDesc'),
        sequences: ['-', '_'],
        action: 'keydown',
    },
    TO_FOREGROUND: {
        name: i18n.t('shortcuts-dialog.toForeground'),
        description: i18n.t('shortcuts-dialog.toForegroundDesc'),
        sequences: ['+', '='],
        action: 'keydown',
    },
    COPY_SHAPE: {
        name: i18n.t('shortcuts-dialog.copyShape'),
        description: i18n.t('shortcuts-dialog.copyShapeDesc'),
        sequences: ['ctrl+c'],
        action: 'keydown',
    },
    PROPAGATE_OBJECT: {
        name: i18n.t('shortcuts-dialog.propagateObject'),
        description: i18n.t('shortcuts-dialog.propagateObjectDesc'),
        sequences: ['ctrl+b'],
        action: 'keydown',
    },
    NEXT_KEY_FRAME: {
        name: i18n.t('shortcuts-dialog.nextKeyFrame'),
        description: i18n.t('shortcuts-dialog.nextKeyFrameDesc'),
        sequences: ['r'],
        action: 'keydown',
    },
    PREV_KEY_FRAME: {
        name: i18n.t('shortcuts-dialog.prevKeyFrame'),
        description: i18n.t('shortcuts-dialog.prevKeyFrameDesc'),
        sequences: ['e'],
        action: 'keydown',
    },

    /* NEXT_ATTRIBUTE: {
        name: 'Next attribute',
        description: 'Go to the next attribute',
        sequences: ['ArrowDown'],
        action: 'keydown',
    },
    PREVIOUS_ATTRIBUTE: {
        name: 'Previous attribute',
        description: 'Go to the previous attribute',
        sequences: ['ArrowUp'],
        action: 'keydown',
    },
    NEXT_OBJECT: {
        name: 'Next object',
        description: 'Go to the next object',
        sequences: ['Tab'],
        action: 'keydown',
    },
    PREVIOUS_OBJECT: {
        name: 'Previous object',
        description: 'Go to the previous object',
        sequences: ['Shift+Tab'],
        action: 'keydown',
    }, */

    INCREASE_BRIGHTNESS: {
        name: i18n.t('shortcuts-dialog.increaseBrightness'),
        description: i18n.t('shortcuts-dialog.increaseBrightnessDesc'),
        sequences: ['shift+b+='],
        action: 'keypress',
    },
    DECREASE_BRIGHTNESS: {
        name: i18n.t('shortcuts-dialog.decreaseBrightness'),
        description: i18n.t('shortcuts-dialog.decreaseBrightnessDesc'),
        sequences: ['shift+b+-'],
        action: 'keydown',
    },
    INCREASE_CONTRAST: {
        name: i18n.t('shortcuts-dialog.increaseContrast'),
        description: i18n.t('shortcuts-dialog.increaseContrastDesc'),
        sequences: ['shift+c+='],
        action: 'keydown',
    },
    DECREASE_CONTRAST: {
        name: i18n.t('shortcuts-dialog.decreaseContrast'),
        description: i18n.t('shortcuts-dialog.decreaseContrastDesc'),
        sequences: ['shift+c+-'],
        action: 'keydown',
    },
    INCREASE_SATURATION: {
        name: i18n.t('shortcuts-dialog.increaseSaturation'),
        description: i18n.t('shortcuts-dialog.increaseSaturationDesc'),
        sequences: ['shift+s+='],
        action: 'keydown',
    },
    DECREASE_SATURATION: {
        name: i18n.t('shortcuts-dialog.decreaseSaturation'),
        description: i18n.t('shortcuts-dialog.decreaseSaturationDesc'),
        sequences: ['shift+s+-'],
        action: 'keydown',
    },
    /* INCREASE_GRID_OPACITY: {
        name: 'Grid opacity+',
        description: 'Make the grid more visible',
        sequences: ['shift+g+='],
        action: 'keydown',
    }, */
    /* DECREASE_GRID_OPACITY: {
        name: 'Grid opacity-',
        description: 'Make the grid less visible',
        sequences: ['shift+g+-'],
        action: 'keydown',
    }, */
    /* CHANGE_GRID_COLOR: {
        name: 'Grid color',
        description: 'Set another color for the image grid',
        sequences: ['shift+g+enter'],
        action: 'keydown',
    }, */

    PASTE_SHAPE: {
        name: i18n.t('shortcuts-dialog.pasteShape'),
        description: i18n.t('shortcuts-dialog.pasteShapeDesc'),
        sequences: ['ctrl+v'],
        action: 'keydown',
    },
    SWITCH_DRAW_MODE: {
        name: i18n.t('shortcuts-dialog.switchDrawMode'),
        description: i18n.t('shortcuts-dialog.switchDrawModeDesc'),
        sequences: ['n'],
        action: 'keydown',
    },
    SWITCH_MERGE_MODE: {
        name: i18n.t('shortcuts-dialog.switchMergeMode'),
        description: i18n.t('shortcuts-dialog.switchMergeModeDesc'),
        sequences: ['m'],
        action: 'keydown',
    },
    SWITCH_GROUP_MODE: {
        name: i18n.t('shortcuts-dialog.switchGroupMode'),
        description: i18n.t('shortcuts-dialog.switchGroupModeDesc'),
        sequences: ['g'],
        action: 'keydown',
    },
    RESET_GROUP: {
        name: i18n.t('shortcuts-dialog.resetGroup'),
        description: i18n.t('shortcuts-dialog.resetGroupDesc'),
        sequences: ['shift+g'],
        action: 'keyup',
    },
    CANCEL: {
        name: i18n.t('shortcuts-dialog.cancel'),
        description: i18n.t('shortcuts-dialog.cancelDesc'),
        sequences: ['esc'],
        action: 'keydown',
    },
    /* CLOCKWISE_ROTATION: {
        name: 'Rotate clockwise',
        description: 'Change image angle (add 90 degrees)',
        sequences: ['ctrl+r'],
        action: 'keydown',
    },
    ANTICLOCKWISE_ROTATION: {
        name: 'Rotate anticlockwise',
        description: 'Change image angle (substract 90 degrees)',
        sequences: ['ctrl+shift+r'],
        action: 'keydown',
    }, */

    SAVE_JOB: {
        name: i18n.t('shortcuts-dialog.saveJob'),
        description: i18n.t('shortcuts-dialog.saveJobDesc'),
        sequences: ['ctrl+s'],
        action: 'keydown',
    },
    UNDO: {
        name: i18n.t('shortcuts-dialog.undo'),
        description: i18n.t('shortcuts-dialog.undoDesc'),
        sequences: ['ctrl+z'],
        action: 'keydown',
    },
    REDO: {
        name: i18n.t('shortcuts-dialog.redo'),
        description: i18n.t('shortcuts-dialog.redoDesc'),
        sequences: ['ctrl+shift+z', 'ctrl+y'],
        action: 'keydown',
    },
    NEXT_FRAME: {
        name: i18n.t('shortcuts-dialog.nextFrame'),
        description: i18n.t('shortcuts-dialog.nextFrameDesc'),
        sequences: ['f'],
        action: 'keydown',
    },
    PREV_FRAME: {
        name: i18n.t('shortcuts-dialog.prevFrame'),
        description: i18n.t('shortcuts-dialog.prevFrameDesc'),
        sequences: ['d'],
        action: 'keydown',
    },
    FORWARD_FRAME: {
        name: i18n.t('shortcuts-dialog.forwardFrame'),
        description: i18n.t('shortcuts-dialog.forwardFrameDesc'),
        sequences: ['v'],
        action: 'keydown',
    },
    BACKWARD_FRAME: {
        name: i18n.t('shortcuts-dialog.backwardFrame'),
        description: i18n.t('shortcuts-dialog.backwardFrameDesc'),
        sequences: ['c'],
        action: 'keydown',
    },
    /* SEARCH_FORWARD: {
        name: 'Search forward',
        description: 'Search the next frame that satisfies to the filters',
        sequences: ['right'],
        action: 'keydown',
    }, */
    /* SEARCH_BACKWARD: {
        name: 'Search backward',
        description: 'Search the previous frame that satisfies to the filters',
        sequences: ['left'],
        action: 'keydown',
    }, */
    PLAY_PAUSE: {
        name: i18n.t('shortcuts-dialog.playPause'),
        description: i18n.t('shortcuts-dialog.playPauseDesc'),
        sequences: ['space'],
        action: 'keydown',
    },
   /*  FOCUS_INPUT_FRAME: {
        name: 'Focus input frame',
        description: 'Focus on the element to change the current frame',
        sequences: ['`', '~'],
        action: 'keydown',
    }, */
    /* SWITCH_AUTOMATIC_BORDERING: {
        name: 'Switch automatic bordering',
        description: 'Switch automatic bordering for polygons and polylines during drawing/editing',
        sequences: ['Control'],
        action: 'keydown',
    }, */
} as any as Record<string, ExtendedKeyMapOptions>;


const defaultState: ShortcutsState = {
    visibleShortcutsHelp: false,
    keyMap: defaultKeyMap,
    normalizedKeyMap: Object.keys(defaultKeyMap)
        .reduce((acc: Record<string, string>, key: string) => {
            const normalized = formatShortcuts(defaultKeyMap[key]);
            acc[key] = normalized;
            return acc;
        }, {}),
};

/**
 * Reducer according to ShortcutsState. Details on action names.
 * 
 * @param state ShortcutsState
 * @param action ShortcutsActions | boundariesActions | AuthActions
 * @returns ShortcutsState
 */

export default (
    state = defaultState,
    action: ShortcutsActions | boundariesActions | AuthActions,
): ShortcutsState => {
    switch (action.type) {
        case ShortcutsActionsTypes.SWITCH_SHORTCUT_DIALOG: {
            return {
                ...state,
                visibleShortcutsHelp: !state.visibleShortcutsHelp,
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        default: {
            return state;
        }
    }
};
