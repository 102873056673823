/*
* Copyright (C) 2019-2020 Intel Corporation
* SPDX-License-Identifier: MIT
*/

const { get } = require('store');
const { getPreview } = require('./frames');

/* global
    require:false
*/

/**
    * External API which should be used by for development
    * @module API
*/

function build() {
    const PluginRegistry = require('./plugins');
    const loggerStorage = require('./logger-storage');
    const Log = require('./log');
    const ObjectState = require('./object-state');
    const Statistics = require('./statistics');
    const { Job, Task, Dataset } = require('./session');
    const { Attribute, Label } = require('./labels');

    const {
        ShareFileType,
        TaskStatus,
        TaskMode,
        AttributeType,
        ObjectType,
        ObjectShape,
        LogType,
        HistoryActions,
        colors,
    } = require('./enums');

    const {
        Exception,
        ArgumentError,
        DataError,
        ScriptingError,
        PluginError,
        ServerError,
    } = require('./exceptions');

    const User = require('./user');
    const pjson = require('../package.json');
    const config = require('./config');

    /**
        * API entrypoint
        * @namespace cvat
        * @memberof module:API
    */
    const cvat = {
        /**
            * Namespace is used for an interaction with a server
            * @namespace server
            * @package
            * @memberof module:API.cvat
        */
        server: {
            /**
                * @typedef {Object} ServerInfo
                * @property {string} name A name of the tool
                * @property {string} description A description of the tool
                * @property {string} version A version of the tool
                * @global
            */

            /**
                * Method returns some information about the annotation tool
                * @method about
                * @async
                * @memberof module:API.cvat.server
                * @return {ServerInfo}
                * @throws {module:API.cvat.exceptions.ServerError}
                * @throws {module:API.cvat.exceptions.PluginError}
            */
            async about() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.about);
                return result;
            },
            /**
                * @typedef {Object} FileInfo
                * @property {string} name A name of a file
                * @property {module:API.cvat.enums.ShareFileType} type
                * A type of a file
                * @global
            */

            /**
                * Method returns a list of files in a specified directory on a share
                * @method share
                * @async
                * @memberof module:API.cvat.server
                * @param {string} [directory=/] - Share directory path
                * @returns {FileInfo[]}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async share(directory = '/') {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.share, directory);
                return result;
            },
            /**
                * Method returns available annotation formats
                * @method formats
                * @async
                * @memberof module:API.cvat.server
                * @returns {module:API.cvat.classes.AnnotationFormats}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async formats() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.formats);
                return result;
            },
            /**
                * Method returns user agreements that the user must accept
                * @method userAgreements
                * @async
                * @memberof module:API.cvat.server
                * @returns {Object[]}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
           async userAgreements() {
            const result = await PluginRegistry
                .apiWrapper(cvat.server.userAgreements);
            return result;
            },
            /**

                * Method allows to register on a server
                * @method register
                * @async
                * @memberof module:API.cvat.server
                * @param {string} username An username for the new account
                * @param {string} firstName A first name for the new account
                * @param {string} lastName A last name for the new account
                * @param {string} email A email address for the new account
                * @param {string} password1 A password for the new account
                * @param {string} password2 The confirmation password for the new account
                * @param {Object} userConfirmations An user confirmations of terms of use if needed
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async register(username, firstName, lastName, email, password1, password2, userConfirmations) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.register, username, firstName,
                        lastName, email, password1, password2, userConfirmations);
                return result;
            },

            async validateCaptcha(token) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.validateCaptcha,token);
                return result;
            },

            async getSystemResources(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getSystemResources);
                return result;
            },

            async downloadSystemResources(exportFormat){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.downloadSystemResources, exportFormat);
                return result;
            },

            async getSystemLogList(startDate, endDate, userId, pageNum){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getSystemLogList, startDate, endDate, userId, pageNum);
                return result;
            },

            async getSystemLogStats(startDate, endDate, numRecords, level){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getSystemLogStats, startDate, endDate, numRecords, level);
                return result;
            },

            async getJobLogs() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getJobLogs);
                return result;
            },

            async getDashboardData(startDate, endDate, userId, timeFilter) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getDashboardData, startDate, endDate, userId, timeFilter);
                return result;
            },

            async getPaymentsUsers(startDate, endDate){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getPaymentsUsers, startDate, endDate);
                return result;
            },

            async makePayment(startDate, endDate, username, receiptForm){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.makePayment, startDate, endDate, username, receiptForm);
                return result;
            },

            async uploadReceipt(paymentId, receiptForm){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.uploadReceipt, paymentId, receiptForm);
                return result;
            },

            async deletePayment(paymentId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.deletePayment, paymentId);
                return result;
            },

            async getDashboardDataStatistics(taskId, startDate, endDate, userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.getDashboardDataStatistics, taskId, startDate, endDate, userId);
                return result;
            },

            async downloadDashboardData(startDate, endDate, userId, exportFormat){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.downloadDashboardData, startDate, endDate, userId, exportFormat);
                return result;
            },

            async downloadPaymentsData(startDate, endDate, exportFormat){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.downloadPaymentsData, startDate, endDate, exportFormat);
                return result;
            },

            async downloadReceipt(paymentId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.downloadReceipt, paymentId);
                return result;
            },

            async downloadTaskGuidelines(taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.downloadTaskGuidelines,taskId);
                return result;
            },

            async sendModelExportInfoToYZP(taskId, exportType){
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.sendModelExportInfoToYZP,taskId, exportType);
                return result;
            },

            async annotaRegister(username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.annotaRegister,username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                        smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                        certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType);
                return result;
            },

            async verifyInformation(username,firstName,lastName, tckn, tcserial) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.verifyInformation,username,firstName,lastName, tckn, tcserial);
                return result;
            },

            async annotaRegisterSpecialAnnotator(username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType, uuid) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.annotaRegisterSpecialAnnotator,username,firstName,lastName,email,password1,password2,phoneNumber,emailCode,
                        smsCode,regId,dateOfBirth,postCode,address,city,town,isWorking,base64Photo,workplace,computerExperience,
                        certificateExplanation, plExplanation, aiExplanation, othersExplanation,education, tckn, tcserial, regType, uuid);
                return result;
            },

            /**
                * Method allows to login on a server
                * @method login
                * @async
                * @memberof module:API.cvat.server
                * @param {string} username An username of an account
                * @param {string} password A password of an account
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async login(username, password, captchaToken) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.login, username, password, captchaToken);
                return result;
            },
            /**
                * Method allows to logout from the server
                * @method logout
                * @async
                * @memberof module:API.cvat.server
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async logout() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.logout);
                return result;
            },
            /**
                * Method allows to reset your password from the server
                * @method reset
                * @async
                * @memberof module:API.cvat.server
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async reset(email) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.reset, email);
                return result;
            },
            /**
                * Method allows to reset your password from the server
                * @method reset
                * @async
                * @memberof module:API.cvat.server
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async resetConfirm(uidb64, token, password1, password2) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.resetConfirm, uidb64, token, password1, password2);
                return result;
            },
            /**
                * Method allows to know whether you are authorized on the server
                * @method authorized
                * @async
                * @memberof module:API.cvat.server
                * @returns {boolean}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async authorized() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.authorized);
                return result;
            },
            /**
                * Method allows to do requests via cvat-core with authorization headers
                * @method request
                * @async
                * @memberof module:API.cvat.server
                * @param {string} url
                * @param {Object} data request parameters: method, headers, data, etc.
                * @returns {Object | undefined} response data if exist
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async request(url, data) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.server.request, url, data);
                return result;
            },
        },
        /**
            * Namespace is used for getting tasks
            * @namespace tasks
            * @memberof module:API.cvat
        */
        tasks: {
            /**
                * @typedef {Object} TaskFilter
                * @property {string} name Check if name contains this value
                * @property {module:API.cvat.enums.TaskStatus} status
                * Check if status contains this value
                * @property {module:API.cvat.enums.TaskMode} mode
                * Check if mode contains this value
                * @property {integer} id Check if id equals this value
                * @property {integer} page Get specific page
                * (default REST API returns 20 tasks per request.
                * In order to get more, it is need to specify next page)
                * @property {string} owner Check if owner user contains this value
                * @property {string} assignee Check if assigneed contains this value
                * @property {string} search Combined search of contains among all fields
                * @global
            */

            /**
                * Method returns list of tasks corresponding to a filter
                * @method get
                * @async
                * @memberof module:API.cvat.tasks
                * @param {TaskFilter} [filter={}] task filter
                * @returns {module:API.cvat.classes.Task[]}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async get(filter = {}, tasksViewType, taskType, onlyTasks, annotaTaskStatus, taskClassificationType, expiredOnly) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.get, filter, tasksViewType, taskType, onlyTasks, annotaTaskStatus, taskClassificationType, expiredOnly);
                return result;
            },
            async assigntask(taskId,userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.assigntask,taskId,userId);
                return result;
            },
            async assigntaskRandom(taskId,userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.assigntaskRandom,taskId,userId);
                return result;
            },
            async getAnnotaTasks(state, taskId, pageNum,filter = {}){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getAnnotaTasks,state, taskId, pageNum,filter);
                return result;
            },
            async approveTask(taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.approveTask,taskId);
                return result;
            },
            async rejectTask(taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.rejectTask,taskId);
                return result;
            },
            async setassigneelimit(taskId,assigneeCount){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setassigneelimit,taskId,assigneeCount);
                return result;
            },
            async setTaskDifficulty(taskId,taskDifficulty){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setTaskDifficulty,taskId,taskDifficulty);
                return result;
            },
            async setTaskType(taskId,taskType){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setTaskType,taskId,taskType);
                return result;
            },
            async getPointCloudTasks(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getPointCloudTasks);
                return result;
            },

            async getTaskPath(taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getTaskPath,taskId);
                return result;
            },

            async expandDataset(files,id,maskPoints){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.expandDataset,files,id,maskPoints);
                return result;
            },

            async getTextTaskDataset(projectId, labels=null){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getTextTaskDataset,projectId, labels);
                return result;
            },
            async getPCDAnnotations(dataId, username, jobId, fileMode){
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getPCDAnnotations, dataId, username, jobId, fileMode);
                return result;
            },
            async getTextAnnotations(projectId, username) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.getTextAnnotations, projectId, username);
                return result;
            },
            async deleteTextAnnotations(projectId, username) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.deleteTextAnnotations, projectId, username);
                return result;
            },

            async setPrivacyTask(id) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setPrivacyTask,id);
                return result;
            },

            async setUsersTask(id,users) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setUsersTask,id,users);
                return result;
            },

            async removeUsersTask(id,users) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.removeUsersTask,id,users);
                return result;
            },

            async setViewersTask(id,users) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.setViewersTask,id,users);
                return result;
            },

            async removeViewersTask(id,users) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.removeViewersTask,id,users);
                return result;
            },

            async downloadAnnotationImages(id,size) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.tasks.downloadAnnotationImages, id,size);
                return result;
            },

        },
        /**
            * Namespace is used for getting jobs
            * @namespace jobs
            * @memberof module:API.cvat
        */
        jobs: {
            /**
                * @typedef {Object} JobFilter
                * Only one of fields is allowed simultaneously
                * @property {integer} taskID filter all jobs of specific task
                * @property {integer} jobID filter job with a specific id
                * @global
            */

            /**
                * Method returns list of jobs corresponding to a filter
                * @method get
                * @async
                * @memberof module:API.cvat.jobs
                * @param {JobFilter} filter job filter
                * @returns {module:API.cvat.classes.Job[]}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async get(filter = {}) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.get, filter);
                return result;
            },

            async trainingstate(userId,jobId,state){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.trainingstate,userId,jobId,state);
                return result;
            },

            async teststate(userId,jobId,state){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.teststate,userId,jobId,state);
                return result;
            },

            async selecttraining(userId,taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.selecttraining,userId,taskId);
                return result;
            },

            async selecttest(userId,taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.selecttest,userId,taskId);
                return result;
            },

            async getTrainingList(state,userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getTrainingList,state,userId);
                return result;
            },

            async getTrainingTestTasks(taskType){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getTrainingTestTasks,taskType);
                return result;
            },

            async getTrainingJobs(state) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getTrainingJobs, state);
                return result;
            },

            async getTestJobs(state,userId, pageNum) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getTestJobs, state,userId, pageNum);
                return result;
            },
            async getUserJobs(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getUserJobs, userId);
                return result;
            },
            async getUserJobsStatistics(userId, timeFilter){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getUserJobsStatistics, userId, timeFilter);
                return result;
            },
            async getTaskJobs(taskId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.getTaskJobs, taskId);
                return result;
            },

            async deleteJob(id){
                const result = await PluginRegistry
                    .apiWrapper(cvat.jobs.deleteJob,id);
                return result;
            }
        },

        datasets: {
            async get(setId, count, pageNum, datasetViewType) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.get, setId, count, pageNum, datasetViewType);
                return result;
            },

            async getVSPDatasets(setId, count, pageNum) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.getVSPDatasets, setId, count, pageNum);
                return result;
            },

            async deleteDataset(setId) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.deleteDataset, setId);
                return result;
            },

            async updateDatasetName(setId, name) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.updateDatasetName, setId, name);
                return result;
            },

            async trackObject(data) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.trackObject,data);
                return result;
            },

            async setPrivacy(id) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.setPrivacy,id);
                return result;
            },

            async setUsers(id,users) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.setUsers,id,users);
                return result;
            },

            async removeUser(id,userId) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.removeUser,id,userId);
                return result;
            },

            async getCkanDatasets(name) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.getCkanDatasets, name);
                return result;
            },

            async downloadDataset(setId, onDownloadProgressStatus = () => {}) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.datasets.downloadDataset, setId,onDownloadProgressStatus);
                return result;
            },

        },
        /**
            * Namespace is used for getting users
            * @namespace users
            * @memberof module:API.cvat
        */
        users: {
            /**
                * @typedef {Object} UserFilter
                * @property {boolean} self get only self
                * @global
            */

            /**
                * Method returns list of users corresponding to a filter
                * @method get
                * @async
                * @memberof module:API.cvat.users
                * @param {UserFilter} [filter={}] user filter
                * @returns {module:API.cvat.classes.User[]}
                * @throws {module:API.cvat.exceptions.PluginError}
                * @throws {module:API.cvat.exceptions.ServerError}
            */
            async get(filter = {}) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.get, filter);
                return result;
            },


            async approve(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.approve,userId);
                return result;
            },

            async getAnnotaSelf(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.getAnnotaSelf,userId);
                return result;
            },

            async updateUser(userId, fields){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.updateUser,userId, fields);
                return result;
            },

            async updateUserPatch(userId, fields){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.updateUserPatch,userId, fields);
                return result;
            },

            async updateInformations(userId, fields){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.updateInformations,userId, fields);
                return result;
            },
            async updateAvatarId(userId, avatarId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.updateAvatarId, userId, avatarId);
                return result;
            },

            async updatePhoto(userId, photo){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.updatePhoto, userId, photo);
                return result;
            },

            async changePassword(userId, pass, newPass){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.changePassword,userId, pass, newPass);
                return result;
            },

            async reject(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.reject,userId);
                return result;
            },

            async forgive(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.forgive,userId);
                return result;
            },

            async promote(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.promote,userId);
                return result;
            },

            async demote(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.demote,userId);
                return result;
            },

            async bless(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.bless,userId);
                return result;
            },

            async curse(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.curse,userId);
                return result;
            },

            async block(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.block,userId);
                return result;
            },

            async unblock(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.unblock,userId);
                return result;
            },

            async give(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.give,userId);
                return result;
            },

            async take(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.take,userId);
                return result;
            },

            async makeDP(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.makeDP,userId);
                return result;
            },

            async makeSA(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.makeSA,userId);
                return result;
            },

            async takeDP(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.takeDP,userId);
                return result;
            },

            async makeSSBDP(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.makeSSBDP,userId);
                return result;
            },

            async takeSSBDP(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.takeSSBDP,userId);
                return result;
            },
            async takeSA(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.takeSA,userId);
                return result;
            },

            async makeAnnotator(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.makeAnnotator,userId);
                return result;
            },

            async takeAnnotator(userId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.takeAnnotator,userId);
                return result;
            },

            async addRole(userId, role){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.addRole,userId,role);
                return result;
            },

            async removeRole(userId, role){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.removeRole,userId, role);
                return result;
            },

            async getAnnotaUsers(userId,pageNum,state,level,expert,isBlocked,getAll,isSuperuser,filter = {},role, isDPDashBoardSearch){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.getAnnotaUsers,userId,pageNum,state,level,expert,isBlocked,getAll,isSuperuser,filter,role, isDPDashBoardSearch);
                return result;
            },

            async createVerification(correlation_id,verificationType,phoneNumber,email, emailCode, token){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.createVerification,correlation_id,verificationType,phoneNumber,email, emailCode, token);
                return result;
            },

            async createVerificationSpecialAnnotator(correlation_id,verificationType,phoneNumber,email, emailCode, token, uuid){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.createVerificationSpecialAnnotator,correlation_id,verificationType,phoneNumber,email, emailCode, token, uuid);
                return result;
            },

            async createContactMail(targetEmail, userEmail, fullName, message){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.createContactMail,targetEmail, userEmail, fullName, message);
                return result;
            },

            async checkVerification(correlation_id,target,code,type){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.checkVerification,correlation_id,target,code,type);
                return result;
            },

            async verifyCode(correlation_id,target,code){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.verifyCode,correlation_id,target,code);
                return result;
            },

            async createSpecialAnnotatorRequest(email){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.createSpecialAnnotatorRequest, email);
                return result;
            },

            async setUserPermissions(userId, permissions){
                const result = await PluginRegistry
                    .apiWrapper(cvat.users.setUserPermissions,userId,permissions);
                return result;
            },
        },
        groups: {
            async getGroups(userId,groupId){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.getGroups,userId,groupId);
                return result;
            },
            async getSpecialAnnotators() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.getSpecialAnnotators);
                return result;
            },
            async createNewGroup(userId, groupName){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.createNewGroup, userId, groupName);
                return result;
            },
            async setGroupUsers(groupId, users){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.setGroupUsers,groupId,users);
                return result;
            },
            async removeGroupUsers(groupId,users){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.removeGroupUsers,groupId,users);
                return result;
            },
            async updateGroupName(id,name){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.updateGroupName,id,name);
                return result;
            },
            async deleteGroup(id){
                const result = await PluginRegistry
                    .apiWrapper(cvat.groups.deleteGroup,id);
            }
        },
        management: {
            async getFaq(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getFaq);
                return result;
            },
            async getLevelScoreIntervals(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getLevelScoreIntervals);
                return result;
            },
            async updateLevelScoreIntervals(levelScoreIntervals){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateLevelScoreIntervals, levelScoreIntervals);
                return result;
            },
            async checkUpdateLevelScoreIntervalsStatus(id){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.checkUpdateLevelScoreIntervalsStatus, id);
                return result;
            },
            async getRoles(pageNum){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getRoles,pageNum);
                return result;
            },
            async createRole(roleName,permissions){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.createRole,roleName,permissions);
                return result;
            },
            async deleteRole(id){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.deleteRole,id);
                return result;
            },
            async updateRole(id,permissions){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateRole,id,permissions);
                return result;
            },
            async getPermissions(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getPermissions);
                return result;
            },
            async createFaq(number, question, answer){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.createFaq, number, question, answer);
                return result;
            },
            async updateFaq(id, number, question, answer){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateFaq, id, number, question, answer);
                return result;
            },
            async deleteFaq(id){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.deleteFaq, id);
                return result;
            },
            async getRegistrationStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getRegistrationStatus);
                return result;
            },
            async getRegistrationStatusPublic(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getRegistrationStatusPublic);
                return result;
            },
            async updateRegistrationStatus(status){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateRegistrationStatus, status);
                return result;
            },
            async getThresholdStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getThresholdStatus);
                return result;
            },
            async updateThresholdStatus(status){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateThresholdStatus, status);
                return result;
            },
            async getJobThresholdStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getJobThresholdStatus);
                return result;
            },

            async updatePaymentConstant(value){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updatePaymentConstant, value);
                return result;
            },
            async getPaymentConstant(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getPaymentConstant);
                return result;
            },

            async updateAutoSaveDuration(value){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateAutoSaveDuration, value);
                return result;
            },
            async getAutoSaveDuration(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getAutoSaveDuration);
                return result;
            },

            async getPaymentLimitStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getPaymentLimitStatus);
                return result;
            },

            async getJobCompleteTimesStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getJobCompleteTimesStatus);
                return result;
            },

            async updateJobThresholdStatus(status){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateJobThresholdStatus, status);
                return result;
            },

            async updatePaymentLimitStatus(minimum, maximum){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updatePaymentLimitStatus, minimum, maximum);
                return result;
            },

            async updateJobCompleteTimesStatus(annotation_job, evaluation_job){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateJobCompleteTimesStatus, annotation_job, evaluation_job);
                return result;
            },

            async getAnnotationPriceStatus(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getAnnotationPriceStatus);
                return result;
            },
            async updateAnnotationPriceStatus(status){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateAnnotationPriceStatus, status);
                return result;
            },
            async getSessionTimeoutDuration(){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.getSessionTimeoutDuration);
                return result;
            },
            async updateSessionTimeoutDuration(timeoutDuration){
                const result = await PluginRegistry
                    .apiWrapper(cvat.management.updateSessionTimeoutDuration, timeoutDuration);
                return result;
            },

        },

        messages: {
            async getMessageUsersInteracted(userId = {}){
                const result = await PluginRegistry
                    .apiWrapper(cvat.messages.getMessageUsersInteracted,userId);
                return result;
            },
            async getMessages(userId, pageNum){
                const result = await PluginRegistry
                    .apiWrapper(cvat.messages.getMessages, userId, pageNum);
                return result;
            },
            async createMessage(recipientId = {}, content){
                const result = await PluginRegistry
                    .apiWrapper(cvat.messages.createMessage, recipientId, content);
                return result;
            },
            async markMessages(senderId = {}){
                const result = await PluginRegistry
                    .apiWrapper(cvat.messages.markMessages, senderId);
                return result;
            },
        },

        frames: {
            async getPreview(tid){
                const result = await getPreview(tid)
                return result;
            },
        },

        /**
            * Namespace is used for plugin management
            * @namespace plugins
            * @memberof module:API.cvat
        */
        plugins: {
            /**
                * @typedef {Object} Plugin
                * A plugin is a Javascript object. It must have properties are listed below. <br>
                * It also mustn't have property 'functions' which is used internally. <br>
                * You can expand any API method including class methods. <br>
                * In order to expand class method just use a class name
                * in a cvat space (example is listed below).
                *
                * @property {string} name A name of a plugin
                * @property {string} description A description of a plugin
                * Example plugin implementation listed below:
                * @example
                * plugin = {
                *   name: 'Example Plugin',
                *   description: 'This example plugin demonstrates how plugin system in CVAT works',
                *   cvat: {
                *     server: {
                *       about: {
                *         // Plugin adds some actions after executing the cvat.server.about()
                *         // For example it adds a field with installed plugins to a result
                *         // An argument "self" is a plugin itself
                *         // An argument "result" is a return value of cvat.server.about()
                *         // All next arguments are arguments of a wrapped function
                *         // (in this case the wrapped function doesn't have any arguments)
                *         async leave(self, result) {
                *           result.plugins = await self.internal.getPlugins();
                *           // Note that a method leave must return "result" (changed or not)
                *           // Otherwise API won't work as expected
                *           return result;
                *         },
                *       },
                *     },
                *     // In this example plugin also wraps a class method
                *     classes: {
                *       Job: {
                *         prototype: {
                *           annotations: {
                *             put: {
                *               // The first argument "self" is a plugin, like in a case above
                *               // The second argument is an argument of the
                *               // Job.annotations.put()
                *               // It contains an array of objects to put
                *               // In this sample we round objects coordinates and save them
                *               enter(self, objects) {
                *                 for (const obj of objects) {
                *                   if (obj.type != 'tag') {
                *                     const points = obj.position.map((point) => {
                *                       const roundPoint = {
                *                         x: Math.round(point.x),
                *                         y: Math.round(point.y),
                *                       };
                *                       return roundPoint;
                *                     });
                *                   }
                *                 }
                *               },
                *             },
                *           },
                *         },
                *       },
                *     },
                *   },
                *   // In general you can add any others members to your plugin
                *   // Members below are only examples
                *   internal: {
                *     async getPlugins() {
                *       // Collect information about installed plugins
                *       const plugins = await cvat.plugins.list();
                *       return plugins.map((el) => {
                *         return {
                *           name: el.name,
                *           description: el.description,
                *         };
                *       });
                *     },
                *   },
                * };
                * @global
            */

            /**
                * Method returns list of installed plugins
                * @method list
                * @async
                * @memberof module:API.cvat.plugins
                * @returns {Plugin[]}
                * @throws {module:API.cvat.exceptions.PluginError}
            */
            async list() {
                const result = await PluginRegistry
                    .apiWrapper(cvat.plugins.list);
                return result;
            },
            /**
                * Install plugin to CVAT
                * @method register
                * @async
                * @memberof module:API.cvat.plugins
                * @param {Plugin} [plugin] plugin for registration
                * @throws {module:API.cvat.exceptions.PluginError}
            */
            async register(plugin) {
                const result = await PluginRegistry
                    .apiWrapper(cvat.plugins.register, plugin);
                return result;
            },
        },
        /**
            * Namespace to working with logs
            * @namespace logger
            * @memberof module:API.cvat
        */
        /**
             * Method to logger configuration
             * @method configure
             * @memberof module:API.cvat.logger
             * @param {function} isActiveChecker - callback to know if logger
             * should increase working time or not
             * @param {object} userActivityCallback - container for a callback <br>
             * Logger put here a callback to update user activity timer <br>
             * You can call it outside
             * @instance
             * @async
             * @throws {module:API.cvat.exceptions.PluginError}
             * @throws {module:API.cvat.exceptions.ArgumentError}
         */

        /**
            * Append log to a log collection <br>
            * Durable logs will have been added after "close" method is called for them <br>
            * Ignore rules exist for some logs (e.g. zoomImage, changeAttribute) <br>
            * Payload of ignored logs are shallowly combined to previous logs of the same type
            * @method log
            * @memberof module:API.cvat.logger
            * @param {module:API.cvat.enums.LogType | string} type - log type
            * @param {Object} [payload = {}] - any other data that will be appended to the log
            * @param {boolean} [wait = false] - specifies if log is durable
            * @returns {module:API.cvat.classes.Log}
            * @instance
            * @async
            * @throws {module:API.cvat.exceptions.PluginError}
            * @throws {module:API.cvat.exceptions.ArgumentError}
        */

        /**
            * Save accumulated logs on a server
            * @method save
            * @memberof module:API.cvat.logger
            * @throws {module:API.cvat.exceptions.PluginError}
            * @throws {module:API.cvat.exceptions.ServerError}
            * @instance
            * @async
        */
        logger: loggerStorage,
        /**
            * Namespace contains some changeable configurations
            * @namespace config
            * @memberof module:API.cvat
        */
        config: {
            /**
                * @memberof module:API.cvat.config
                * @property {string} backendAPI host with a backend api
                * @memberof module:API.cvat.config
                * @property {string} proxy Axios proxy settings.
                * For more details please read <a href="https://github.com/axios/axios"> here </a>
                * @memberof module:API.cvat.config
                * @memberof module:API.cvat.config
            */
            get backendAPI() {
                return config.backendAPI;
            },
            set backendAPI(value) {
                config.backendAPI = value;
            },
            get doccanoUIRedirectURL() {
                return config.doccanoUIRedirectURL;
            },
            set doccanoUIRedirectURL(value) {
                config.doccanoUIRedirectURL = value;
            },
            get backendAPIDoccano() {
                return config.backendAPIDoccano;
            },
            set backendAPIDoccano(value) {
                config.backendAPIDoccano = value;
            },
            get proxy() {
                return config.proxy;
            },
            set proxy(value) {
                config.proxy = value;
            },
            get hitachiURL() {
                return config.hitachiURL;
            },
            set hitachiURL(value) {
                config.hitachiURL = value;
            },
        },
        /**
            * Namespace contains some library information e.g. api version
            * @namespace client
            * @memberof module:API.cvat
        */
        client: {
            /**
                * @property {string} version Client version.
                * Format: <b>{major}.{minor}.{patch}</b>
                * <li style="margin-left: 10px;"> A major number is changed after an API becomes
                * incompatible with a previous version
                * <li style="margin-left: 10px;"> A minor number is changed after an API expands
                * <li style="margin-left: 10px;"> A patch number is changed after an each build
                * @memberof module:API.cvat.client
                * @readonly
            */
            version: `${pjson.version}`,
        },
        /**
            * Namespace is used for access to enums
            * @namespace enums
            * @memberof module:API.cvat
        */
        enums: {
            ShareFileType,
            TaskStatus,
            TaskMode,
            AttributeType,
            ObjectType,
            ObjectShape,
            LogType,
            HistoryActions,
            colors,
        },
        /**
            * Namespace is used for access to exceptions
            * @namespace exceptions
            * @memberof module:API.cvat
        */
        exceptions: {
            Exception,
            ArgumentError,
            DataError,
            ScriptingError,
            PluginError,
            ServerError,
        },
        /**
            * Namespace is used for access to classes
            * @namespace classes
            * @memberof module:API.cvat
        */
        classes: {
            Task,
            User,
            Job,
            Dataset,
            Log,
            Attribute,
            Label,
            Statistics,
            ObjectState,
        },
    };

    cvat.server = Object.freeze(cvat.server);
    cvat.tasks = Object.freeze(cvat.tasks);
    cvat.jobs = Object.freeze(cvat.jobs);
    cvat.users = Object.freeze(cvat.users);
    cvat.plugins = Object.freeze(cvat.plugins);
    cvat.client = Object.freeze(cvat.client);
    cvat.enums = Object.freeze(cvat.enums);
    cvat.datasets = Object.freeze(cvat.datasets);
    cvat.groups = Object.freeze(cvat.groups);
    cvat.messages = Object.freeze(cvat.messages)
    cvat.frames = Object.freeze(cvat.frames)

    const implementAPI = require('./api-implementation');

    Math.clamp = function (value, min, max) {
        return Math.min(Math.max(value, min), max);
    };

    const implemented = Object.freeze(implementAPI(cvat));
    return implemented;
}

module.exports = build();
