/**
 *  @description Task details annotator tour steps
 */
const taskDetailsAnnotatorSteps = [
    {
        selector: '[data-tour="step-task-name"]',
        content: "Görevin ismini burada görebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-task-info"]',
        content: "Görevle ilgili genel bilgileri buradan görebilirsiniz. Veri sağlayıcının sağladığı görev görseli burada gösterilir. "
    },

    {
        selector: '[data-tour="step-random-assign"]',
        content: "Burada görevden daha önce iş alınıp alınmadığını/görevin doluluğunu görebilir, görev dolu değilse kendinize rastgele bir iş atayabilirsiniz. ",
    },
    {
        selector: '[data-tour="step-guidelines"]',
        content: "Görev ile ilgili yönergeleri burada görebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-guideline-message"]',
        content: "Buradan görev ile ilgili sorularınızı görevin sahibine yöneltebilirsiniz. Konuşmayı mesajlar kısmına giderek devam ettirebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-segments"]',
        content: "Burada görev segmentleri görüntülenir. Segmentlerin görevdeki hangi çerçeveler arasında olduğunu ve her segmentte çalışabilecek etiketleyici sayısını görüp, boş yeri olan istediğiniz segmentten iş almak için kandime ata düğmesini kullanabilirsiniz. ",
    },
    {
        selector: '[data-tour="step-jobs-list"]',
        content: "Üzerinize aldığınız işleri burada görebilirsiniz. İşin adına tıklayarak işe gidebilirsiniz. ",
    },
  ];

  export default taskDetailsAnnotatorSteps;
