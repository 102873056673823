// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Select from 'antd/lib/select';
import { useState, useEffect } from 'react';
import getCore from 'cvat-core-wrapper';
import User from '../../../../cvat-core/src/user'
const cvat = getCore();

interface Props {
    parentMethod: (userList: any) => void;
    onChange: (user: string) => void;
    mode?:"Default";
    placeholder?:string;
    className?:any;
    size?:"small"|"large"|"default";
    showArrow?:true|false;
    user: any;
}

/**
 *
 * @param props
 * @returns Viewer selector for task page
 */
export default function ViewerSelector(props: Props): JSX.Element {
    const {
        parentMethod,
        onChange,
        mode,
        placeholder,
        className,
        size,
        showArrow
    } = props;
    const [usersSearched, setUsers] = useState([]);

    /**
     * @descrition Select component for viewer selection, handling state and selections
     */
    return (
        <Select
            mode={mode? mode:"multiple"}
            size={size?size:'small'}
            placeholder={placeholder? placeholder:'Kullanıcıları Ara'}
            showSearch
            dropdownStyle={{background:"#F2F4F8"}}
            showArrow={showArrow?true:false}
            className={className?className:'cvat-user-selector'}
            autoClearSearchValue
            onSearch={async (value: string): Promise<void> => {
                if(value.length >= 3){
                    let usersAfterSearch = await cvat.users.getAnnotaUsers(null, 1, 'approved', null, null, null, null, null, {"search": value}, ["provider"]);
                    usersAfterSearch.users = usersAfterSearch.users.filter(user => user.id.id != props.user.id)
                    console.log(usersAfterSearch)
                    let usersFinal = new Array()
                    for(let i = 0; i < usersAfterSearch.users.length ; i++){
                        usersFinal.push(usersAfterSearch.users[i].id)
                    }
                    setUsers(usersFinal)
                }else{
                    setUsers([])
                }
            }}
            onChange={(value: string, option:any): void => {
                console.log(option)
                if(mode)
                    parentMethod(option.key)
                else
                    parentMethod(value)
            }}
        >
            {usersSearched.map((user): JSX.Element => (
                <Select.Option key={user.id} value={user.username}>
                    {user.username}
                </Select.Option>
            ))}
        </Select>
    );
}
