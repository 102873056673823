/**
 * @description provider group tour steps selector and contents
 */
const providerGroupSteps = [
    {
        selector: '[data-tour="step-new-group-name"]',
        content: "Oluşturmak istediğiniz kullanıcı grubu ismini buraya girebilirsiniz. ",
        action: node => {
            node.focus();
          },
    },
    {
        selector: '[data-tour="step-group-user"]',
        content: "Gruba eklemek istediğiniz kullanıcıları, isimlerinin en az ilk üç harfini yazarak buradan bulabilirsiniz. Kullanıcıyı seçmek için üstüne tıklayabilirsiniz. ",
        highlightedSelectors:['.ant-select-dropdown-menu'],
        mutationObservables:['.ant-select-dropdown'],
    },
    {
        selector: '[data-tour="step-create"]',
        content: "Grubu oluşturmak için bu düğmeye tıklayabilirsiniz. "
    },
    {
        selector: '[data-tour="step-groups"]',
        content: "Oluşturduğunuz gruplar burada listelenir. "
    },
    {
        selector: '[data-tour="step-email"]',
        content: "Davet etmek istediğiniz etiketleyicinin mail adresini girdikten sonra davet et düğmesine basarak buradan davet gönderebilirsiniz. "
    },
  ];

  export default providerGroupSteps;
