// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import { useTranslation } from 'react-i18next';

import { EmptyTasksIcon } from 'icons';
/**
 * 
 * @param props includes canCreateNewTask boolean value 
 * @returns div
 */
export default function EmptyListComponent(props): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='cvat-empty-tasks-list'>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Icon className='cvat-empty-tasks-icon' component={EmptyTasksIcon} />
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col>
                    <Text strong>{t('tasks-page.noTasksCreated')} ...</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle' hidden= {props.canCreateNewTask}>
                <Col>
                    <Text type='secondary'>{t('tasks-page.toGetStarted')}</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle' hidden= {props.canCreateNewTask}>
                <Col>
                    <Link to='/tasks/create' style={{color:"#6C63FF"}}>{t('tasks-page.createNewTask')}</Link>
                </Col>
            </Row>
        </div>
    );
}
