// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import {
    TasksState,
    TasksQuery,
    CombinedState,
    AuthState
} from 'reducers/interfaces';

import TasksListComponent from 'components/tasks-page/task-list';

import {
    getTasksAsync,
} from 'actions/tasks-actions';

interface StateToProps {
    tasks: TasksState;
    auth: AuthState;
}

interface DispatchToProps {
    getTasks: (query: TasksQuery) => void;
}

interface OwnProps {
    onSwitchPage: (page: number) => void;
}

/**
 *
 * @param state -> combined state containing tasks and auth information
 * @returns tasks and auth information
 */
function mapStateToProps(state: CombinedState): StateToProps {
    return {
        tasks: state.tasks,
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns getTasks action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getTasks: (query: TasksQuery): void => {
            dispatch(getTasksAsync(query));
        },
    };
}

type TasksListContainerProps = StateToProps & DispatchToProps & OwnProps;

/**
 *
 * @param props
 * @returns TasksListComponent passing it props
 */
function TasksListContainer(props: TasksListContainerProps): JSX.Element {
    const {
        tasks,
        onSwitchPage,
        auth
    } = props;
    // let newTasks = new Array();

    // console.log(tasks)
    // newTasks = tasks.current.filter(function(value, index, arr){

    //     if(auth.user.groups.includes("provider")){
    //         let taskType = value.instance.tasksId[0]
    //         if(taskType){
    //             taskType = taskType.taskType
    //             if( taskType === "test" || taskType === "training")
    //             {
    //                 if(auth.user.id === value.instance.owner.id)
    //                     return value;
    //             }
    //         }
    //         else
    //             return value;
    //     }
    //     else if(auth.user.groups.includes("admin")){
    //         return value;
    //     }
    //     else{
    //         let taskType = value.instance.tasksId[0]
    //         if(!taskType)
    //         {
    //             return value;
    //         }
    //     }
    // })
    // console.log(newTasks);
    // tasks.current = newTasks;
    // tasks.count = newTasks.length;
    return (
        <TasksListComponent
            onSwitchPage={onSwitchPage}
            currentTasksIndexes={tasks.current.map((task) => task.instance.id)}
            currentPage={tasks.gettingQuery.page}
            numberOfTasks={tasks.count}
        />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TasksListContainer);
