import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter,BrowserRouter as Router,Route,Switch,Link,Redirect } from 'react-router-dom';
import {
    Col,
    Row,
    Button,
    Input,
    Spin,
    Modal,
} from 'antd';

import { AuthState } from '../reducers/interfaces';
import { authorizedAsync } from '../actions/auth-actions';
import i18n from '../i18n'

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state
 * @returns authorization information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatchers
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type ApprovalPageProps = StateToProps & DispatchToProps
    & RouteComponentProps;

/**
 * @description Rejected Page class component will rendered for /rejected-blocked
 */
class RejectedPage extends React.PureComponent<ApprovalPageProps> {
    constructor(props: any) {
        super(props);
    }

    /**
     *
     * @returns show rejection or blocked warning message template
     */
    public render() {
        if(this.props.auth.annotaUser[0].isBlocked == true){
            return (
                <div style={{display: "flex", position: "relative", top: "200px", justifyContent: "center"}}>
                    <h1>{i18n.t('rejected-page.blockedUserWarning')}</h1>
                </div>
            )
        }else{
            return (
                <div style={{display: "flex", position: "relative", top: "200px", justifyContent: "center"}}>
                    <h1>{i18n.t('rejected-page.rejectedUserWarning')}</h1>
                </div>
            )
        }
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(RejectedPage));
