import React from 'react';
import getCore from 'cvat-core-wrapper';
import { Button, Row, Col, Pagination, Table, Input, Radio, Card, Icon, Modal, Checkbox, Tooltip } from 'antd';
import { List } from 'antd';

const core = getCore();

interface Props {
    annotaUser:any;
    updateUsers(): void
}

/**
 * @class_component user permissions class component
 */
class UserPermissions extends React.PureComponent<Props> {
  constructor(props: any) {
    super(props);
    this.state = {
        ownedPermissions: this.props.annotaUser.permissions,
        otherPermissions: this.props.annotaUser.role_permissions.filter(itemX => !this.props.annotaUser.permissions.map(itemY => { return itemY.id;}).includes(itemX.id))
    }
    this.setPermissions = this.setPermissions.bind(this);
  }

  /**
   *
   * @param item
   * @description add permission in owned and other permissions in state
   */
  addPermission(item: any){
    let permissions = this.state.ownedPermissions
    permissions.push(item)
    let updatedOtherPermissions = this.state.otherPermissions.filter(object => object.id != item.id)
    this.setState({
        ownedPermissions: permissions,
        otherPermissions: updatedOtherPermissions
    })
  }

  /**
   *
   * @param item
   * @description update owned and other permissions in state
   */
  removePermission(item: any){
    let updatedOwnedPermissions = this.state.ownedPermissions.filter(object => object.id != item.id)
    let updatedOtherPermissions = this.state.otherPermissions
    updatedOtherPermissions.push(item)
    this.setState({
        ownedPermissions: updatedOwnedPermissions,
        otherPermissions: updatedOtherPermissions
    })
}

  /**
  * @async
  * @description configure owned permissions and set user permissions api proxy call
  */
  async setPermissions(){
    let permissionIds = []
    for(let i = 0; i < this.state.ownedPermissions.length; i++){
        permissionIds.push(this.state.ownedPermissions[i].id)
    }
    let permissionJSON = {
        "permissionIds": permissionIds
    }
    await core.users.setUserPermissions(this.props.annotaUser.id.id, JSON.stringify(permissionJSON))
    this.props.updateUsers()
  }


  private getPermissionName(state: String) {
    switch (state) {
        case 'annotate_image':
            return 'İmge Etiketleme';
        case 'annotate_video':
            return 'Video Etiketleme';
        case 'annotate_text':
            return 'Metin Etiketleme';
        case 'annotate_3dpc':
            return 'Nokta Kümesi Etiketleme';
        case null:
            return '';
        case 'view_logs':
            return 'Logları Görüntüleme';
        case 'view_system_usage':
            return 'Kaynak Kullanımı Görüntüleme';
        case 'create_task':
            return 'Görev Oluşturma';
        case 'create_dataset':
            return 'Veri Kümesi Oluşturma';
        case 'create_user_groups':
            return 'Kullanıcı Grubu Oluşturma';
        case 'dump_annotations':
            return 'Etiket İndirme';
        case 'export_images':
            return 'Etiketlenmiş İmgeleri İndirme';
        case 'share_task':
            return 'Görev Paylaşma';
        case 'list_datasets':
            return 'Veri Kümelerini Listeleme';
    }
}

 /**
 *
 * @returns user permissions page JSX template
 */
  render() {
    return (
      <div>
        <Row>
            <Col span = {11}>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.ownedPermissions}
                    header={<div> <b style={{whiteSpace:"break-spaces"}}>Var Olan Yetkiler </b></div>}
                    renderItem={item => (
                    <List.Item
                        actions={
                            [<Icon onClick={()=>this.removePermission(item)} type="minus" />]
                        }
                    >
                        <List.Item.Meta
                        description={this.getPermissionName(item.name)}
                        />
                    </List.Item>
                    )}
                />
            </Col>
            <Col style={{marginLeft:'8%'}} span = {11}>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.otherPermissions}
                    header={<div><b style={{whiteSpace:"break-spaces"}}>Eksik Yetkiler </b></div>}
                    renderItem={item => (
                    <List.Item
                        actions={
                            [<Icon onClick={()=>this.addPermission(item)} type="plus" />]
                        }
                    >
                        <List.Item.Meta
                        description={this.getPermissionName(item.name)}
                        />

                    </List.Item>
                    )}
                />
            </Col>
        </Row>
        <Row>
            <Button style={{float:"right"}} onClick={this.setPermissions}>
                Kaydet
            </Button>
        </Row>
      </div>
    );
  }
}
export default UserPermissions;
