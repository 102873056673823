// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import { useTranslation } from 'react-i18next';
import { Icon, Spin } from 'antd'

import LoginForm, { LoginData } from './login-form';
import CookieDrawer from './cookie-policy-drawer';
import './styles.scss';

interface LoginPageComponentProps {
    fetching: boolean;
    onLogin: (username: string, password: string, captchaToken: string) => void;
}

/**
 *
 * @param props
 * @returns Login Page Component JSX Element
 */
function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {

    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    /**
     * @description get fetching information and onLogin event action function props
     */
    const {
        fetching,
        onLogin,
    } = props;
    const { t } = useTranslation();

    /**
     * @description Render title and Login Form along with register link
     */
    return (
        <div style={{justifyContent: "center", display: "flex", margin:"auto"}}>
        <div style={{display: process.env.DEPLOYMENT_PLACE==="SSYZ" ? "none" : "flex"}}>
            <img className="login-logo" src='/logologin.png' ></img>
            <img className="login-image" src='/login_image.png' ></img>

            <div className="login-component">
                <Title className="login-title" level={1}> {t('login-form.signIn')} </Title>
                <div className="login-input" >
                    <LoginForm
                        fetching={fetching}
                        onSubmit={(loginData: LoginData): void => {
                            onLogin(loginData.username, loginData.password, loginData.captchaToken);
                        }}
                    />

                </div>

                <Text strong className="login-text-noaccount">
                    {t('login-form.noAccount')}
                    <Link style={{color:"#FCCE5A"}} to='/auth/register'> {t('login-form.register')}.</Link>
                </Text>
            </div>
            <CookieDrawer />
        </div>
        {process.env.DEPLOYMENT_PLACE==="SSYZ" ? <Spin size='large' className='cvat-spinner' /> : null}
        </div>
    );
}

export default withRouter(LoginPageComponent);
