// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import {
    Input,
    Modal,
    Radio,
    Select,
    Table,
    Popconfirm,
    Slider,
    Upload,
    Icon,
    List,
    Checkbox,
    Tooltip
} from 'antd';

import { Row, Col } from 'antd/lib/grid';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import i18n from '../../i18n'
import ImageMaskEditor from './image-mask-editor';
import FileManagerContainer from 'containers/file-manager/file-manager';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import AdvancedConfigurationForm, { AdvancedConfiguration } from './advanced-configuration-form';
import LabelsEditor from '../labels-editor/labels-editor';
import { Files } from '../file-manager/file-manager';
import {isVideo, isText} from '../file-type-validator';
import {FileResourceTypeSelection} from 'cvat-core/src/enums';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import RadioGroup from 'antd/lib/radio/group';
import UserSelector from './user-selector-task';
import Column from 'antd/lib/table/Column';
import getCore from 'cvat-core-wrapper';
import { any } from 'prop-types';
const core = getCore();

let maskingPointsContent = <div></div>
let maskArray = new Array()

export interface CreateTaskData {
    basic: BaseConfiguration;
    advanced: AdvancedConfiguration;
    labels: any[];
    files: Files;
}

interface Props {
    onCreate: (data: CreateTaskData) => void;
    status: string;
    installedGit: boolean;
    user: any
}

type State = CreateTaskData;

const defaultState = {
    basic: {
        name: '',
        guidelines: "",
        taskType: "",
        maskStartFrame: '',
        maskStopFrame: '',
        maskJson: [],
        maskListView: [],
        selectedDatasetId: '',
        isPrivate: true,
        fromDataset: false,
        textProjectType: 'SequenceLabeling',
        shapes: [],
        shapesSelectContent: [],
        taskTypesSelectContent: [],
        taskTypes: [],
        users: [],
        usersSelectContent: [],
    },
    advanced: {
        zOrder: false,
        lfs: false,
        useZipChunks: true,
    },
    labels: [],
    files: {
        local: [],
        share: [],
        remote: [],
        dataset: [],
        ssbdataset: [],
    },
    fileType: '',
    enableMask: false,
    enableTextType: false,
    usersFromGroups: [],
    usersFromGroupsData: [],
    isPrivateTask: false,
    usedGroups: [],
    assigneeCount: 2,
    guidelinesFile:[],
    thumbnail: [],
    metaData: [],
    key: '',
    value: '',
    taskDifficulty: 1,
    nameNotValid:false,
    guidelinesNotValid:false,
    taskTypeNotValid:false,
    metaDataDataset: [],
    metaDataDatasetModalVisible:false,
    keyDataset: '',
    valueDataset: '',
    projectName: '',
    mediaType: undefined,
    dataFormat: '',
    dataTimeLength: '',
    dataSize: '',
    dataContext: '',
    dataPrivacy: undefined,
    dataUsage: '',
    datasetName:"",
    modalVisible:false,
    previewFiles:[],
    imageMaskData:[]
};

export default class CreateTaskContent extends React.PureComponent<Props, State> {
    private basicConfigurationComponent: any;
    private advancedConfigurationComponent: any;
    private fileManagerContainer: any;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
        this.getFiles = this.getFiles.bind(this);
        this.getFileType = this.getFileType.bind(this);
        this.getSelectedUsers = this.getSelectedUsers.bind(this);
        this.handleUserGroups()
        this.handleChangeUserLimit = this.handleChangeUserLimit.bind(this);
        this.handleChangeTaskDifficulty = this.handleChangeTaskDifficulty.bind(this);
        this.addpair = this.addpair.bind(this)
        this.handleSubmitClick = this.handleSubmitClick.bind(this)
        this.addpairDataset = this.addpairDataset.bind(this)
        this.renderImageEditor = this.renderImageEditor.bind(this);
        this.emptyMethod = this.emptyMethod.bind(this);
        this.getMaskedData = this.getMaskedData.bind(this);
    }

    /**
     * Add key value pair state
     *
     * @param key
     * @param value
     */
    public addpairDataset(key: any, value: any){
        let id;
        if(this.state.metaDataDataset.length == 0)
            id = 0;
        else
            id = this.state.metaDataDataset[this.state.metaDataDataset.length-1].id+1

        let pair = {
            "id": id,
            "key": key,
            "value": value,
        }


        this.setState({
            keyDataset: '',
            valueDataset: '',
            metaDataDataset: [...this.state.metaDataDataset, pair],
        });
    }

    /**
     * Set project name
     *
     * @param e event
     */
    async setProjectName(e: any){
        this.setState({
            projectName: e.target.value
        })
    }

    /**
     * Set media type
     *
     * @param e event
     */
    async setMediaType(e: any){
        this.setState({
            mediaType: e
        })
    }

    /**
     * Set data format
     *
     * @param e event
     */
    async setDataFormat(e: any){
        this.setState({
            dataFormat: e.target.value
        })
    }

    /**
     * Set data time length
     *
     * @param e event
     */
    async setDataTimeLength(e: any){
        this.setState({
            dataTimeLength: e.target.value
        })
    }

    /**
     * Set data size
     *
     * @param e event
     */
    async setDataSize(e: any){
        this.setState({
            dataSize: e.target.value
        })
    }

    /**
     *  Set data context
     *
     * @param e event
     */
    async setDataContext(e: any){
        this.setState({
            dataContext: e.target.value
        })
    }

    /**
     * Set data privacy
     *
     * @param e event
     */
    async setDataPrivacy(e: any){
        this.setState({
            dataPrivacy: e
        })
    }

    /**
     * Set data usage
     *
     * @param e event
     */
    async setDataUsage(e: any){
        this.setState({
            dataUsage: e.target.value
        })
    }

    /**
     * Set datasetName state
     *
     * @param e event
     */
    async setDatasetName(e: any){
        this.setState({
            datasetName: e.target.value
        },()=>console.log(this.state))
    }

    /**
     * Render metadataDataset
     *
     * @returns Modal
     */
    private renderMetaDataDataset(): JSX.Element {

        return (
             <Modal
            destroyOnClose={true}
            title="Veri Kümesi Yapılandırma"
            visible={this.state.metaDataDatasetModalVisible}
            onCancel={()=>
                Modal.confirm({
                    title: 'Kaydetmeden çıkmak istiyor musunuz?',
                    content: 'Veri setiyle ilgili girdiğiniz özellikler silinecek.',
                    onOk: () => {
                        this.setState({
                            metaDataDatasetModalVisible:false,
                            projectName:"",
                            mediaType:undefined,
                            dataFormat:"",
                            dataTimeLength:"",
                            dataSize:"",
                            dataContext:"",
                            dataPrivacy:undefined,
                            dataUsage:"",
                            metaDataDataset:[],
                            keyDataset:"",
                            valueDataset:"",
                            datasetName:"",
                        })
                    },
                    okButtonProps: {
                      type: 'danger',
                    },
                    okText: 'Devam',
                    cancelText: 'İptal'
                })
            }
            onOk={()=>this.setState({metaDataDatasetModalVisible:false})}
            cancelText="İptal"
            okText="Kaydet"
            closable={false}
            >

                 <Row type="flex" justify="start">
                 <Row gutter={8}>
                                        <Col span={24}>
                                        <Text className='cvat-text-color'>{i18n.t('keywords.name')}:</Text>
                                            <Input className="input-create-task" value={this.state.datasetName}  onChange={(e) => this.setDatasetName(e)} />

                                        </Col>
                                    </Row>
                                    <br/>
                            <Row type='flex' justify='space-between' align='middle'>
                                <Text className="cvat-text-color" style={{marginBottom:"10px"}}>Veri Kümesi Özellikleri</Text>
                                <Col span={24} >

                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Input  className="input-create-task" value ={this.state.projectName} placeholder="Proje Adı" onChange={(e) => this.setProjectName(e)}/>
                                        </Col>
                                        <Col span={12}>
                                            <Select
                                                className="input-create-task"
                                                style={{ width: '100%' }}
                                                placeholder="Veri Türü"
                                                value={this.state.mediaType}
                                                dropdownStyle={{background:"#F2F4F8"}}
                                                onChange={(e) => this.setMediaType(e)}
                                            >
                                                <Select.Option value={"text"}  >Metin</Select.Option>
                                                <Select.Option value={"image"} >Görüntü</Select.Option>
                                                <Select.Option value={"video"} >Video</Select.Option>
                                                <Select.Option value={"other"} >Diğer</Select.Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={8} style={{marginTop:'2.5%'}}>
                                        <Col span={12}>
                                            <Input className="input-create-task" value ={this.state.dataFormat} placeholder="Veri Formatı" onChange={(e) => this.setDataFormat(e)}/>
                                        </Col>
                                        <Col span={12}>
                                            <Input className="input-create-task" value ={this.state.dataTimeLength} placeholder="Veri Miktarı" onChange={(e) => this.setDataTimeLength(e)}/>
                                        </Col>
                                    </Row>
                                    <Row gutter={8} style={{marginTop:'2.5%'}}>
                                        <Col span={12}>
                                            <Input className="input-create-task" value ={this.state.dataSize} placeholder="Veri Boyutu" onChange={(e) => this.setDataSize(e)}/>
                                        </Col>
                                        <Col span={12}>
                                            <Input className="input-create-task" value ={this.state.dataContext} placeholder="Veri İçeriği" onChange={(e) => this.setDataContext(e)}/>
                                        </Col>
                                    </Row>
                                    <Row gutter={8} style={{marginTop:'2.5%'}}>
                                        <Col span={12}>
                                            <Select
                                                className="input-create-task"
                                                style={{ width: '100%' }}
                                                placeholder="Gizlilik Derecesi"
                                                dropdownStyle={{background:"#F2F4F8"}}
                                                onChange={(e) => this.setDataPrivacy(e)}
                                            >
                                                <Select.Option value={"unclassified"}  >Tasnif Dışı</Select.Option>
                                                <Select.Option value={"private"} >Gizli</Select.Option>
                                                <Select.Option value={"restricted"} >Hizmete Özel</Select.Option>
                                            </Select>
                                        </Col>
                                        <Col span={12}>
                                            <Input className="input-create-task" value ={this.state.dataUsage} placeholder="Kullanım Durumu" onChange={(e) => this.setDataUsage(e)}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br/>
                <Row type='flex' justify='space-between' gutter={12}>
                    <Col span={24}>
                    <div>
                        <br/>
                    <Text className='cvat-text-color'>Anahtar-Değer:</Text>
                    <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px",marginTop:"10px", paddingLeft:"0.5%", paddingTop:"1%"}}>
                    <Row gutter = {8} >
                        <Col span = {8}>
                            <Input
                                className="input-create-task"
                                style={{backgroundColor:"#FFFFFF"}}
                                placeholder='Anahtar'
                                value = {this.state.keyDataset}
                                onChange={(value) =>
                                    this.setState({
                                        keyDataset: value.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span = {8}>
                            <Input
                                className="input-create-task"
                                style={{backgroundColor:"#FFFFFF"}}
                                placeholder='Değer'
                                value = {this.state.valueDataset}
                                onChange={(value) =>
                                    this.setState({
                                        valueDataset: value.target.value
                                    })
                                }
                            />
                        </Col>
                        <Col span = {6}>
                            <Button
                                type='primary'
                                size='small'
                                className="button-create-task"
                                onClick={(): void => {
                                    if(this.state.keyDataset == '' || this.state.keyDataset == undefined ){
                                        Modal.error({
                                            title: i18n.t('keywords.error'),
                                            content: 'Anahtarı giriniz!',
                                        });
                                    }else if(this.state.valueDataset == '' || this.state.valueDataset == undefined ){
                                        Modal.error({
                                            title: i18n.t('keywords.error'),
                                            content: 'Değeri giriniz!',
                                        });
                                    }else{
                                        this.addpairDataset(this.state.keyDataset, this.state.valueDataset)
                                    }
                                }
                                }
                            >{i18n.t('keywords.add')}</Button>
                        </Col>
                    </Row>
                    <List
                        style={{ marginTop: '2%' }}
                        rowKey='fileName'
                        dataSource={this.state.metaDataDataset}
                        size="small"
                        pagination={false}
                        locale={{ emptyText: 'Anahtar-Değer verisi bulunmuyor.' }}
                        renderItem={item => (
                            <Row type="flex" gutter={[12,12]}>
                                <Col span={8}>
                                    <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.key}</Text>

                                </Col>
                                <Col span={8}>
                                    <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.value}</Text>

                                </Col>
                                <Col span={4}>
                                    <Popconfirm
                                        title="Silmek istediğinizden emin misiniz?"
                                        cancelText={i18n.t('keywords.no')}
                                        okText={i18n.t('keywords.yes')}
                                        onConfirm={
                                            (e) => {
                                                let currentMetaData = this.state.metaDataDataset
                                                currentMetaData = currentMetaData.filter(object => object.id != item.id)
                                                this.setState({
                                                    metaDataDataset: currentMetaData
                                                })
                                            }
                                        }
                                    >
                                        <Button size="small" className="button-default-create-task"> {i18n.t('keywords.delete')} </Button>
                                    </Popconfirm>
                                </Col>

                            </Row>
                        )}
                    >
                    </List>
                    </div>
                </div>
                 </Col>
                            </Row>


                </Row>

             </Modal>

        );
    }

    /**
     * Set usersFromGroups state
     */
    async handleUserGroups() {
        let response = await core.groups.getGroups(this.props.user.id);
        this.setState({
            usersFromGroups: response.data.results
        });
    }

    /**
     * Add key value pair state
     *
     * @param key
     * @param value
     */
    public addpair(key: any, value: any){
        let id;
        if(this.state.metaData.length == 0)
            id = 0;
        else
            id = this.state.metaData[this.state.metaData.length-1].id+1

        let pair = {
            "id": id,
            "key": key,
            "value": value,
        }

        this.setState({
            key: '',
            value: '',
            metaData: [...this.state.metaData, pair],
        });
    }

    /**
     * Handle expand
     *
     * @param test group
     * @returns Table
     */
    public handleExpand(test: any){
        return(
            <Table
                rowKey={record => record.id.id}
                dataSource={test.members}
                pagination={false}
                showHeader={false}
                locale={{ emptyText: 'Sonuç Bulunamadı' }}
            >
                <Column dataIndex="id.username" key="id.username" />
            </Table>
        )
    }

    /**
     * Set state to get file
     *
     * @param type string
     */
    public getFileType(type:string){
        let file_type = this.state.fileType;

        if(type === FileResourceTypeSelection.SHARED){
            this.setState({enableMask:true});
        }
        else if(type.includes(FileResourceTypeSelection.DATASET)|| type.includes(FileResourceTypeSelection.SSBDATASET)){
            const fileType = type.includes("video") ? "video" : this.state.fileType;
            const isTextType = type.includes("text") ? "text" : fileType;
            if (file_type !== '' && file_type !== fileType) {
                this.state.taskTypesSelectContent = []
            }
            this.setState({
                fileType: fileType,
                enableMask: (isVideo(fileType)),
                enableTextType: isText(isTextType)
            });
        }
        else if(type !== ""){
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(type);
            const fileType = ext[1] ? ext[1] : this.state.fileType;
            if (file_type !== '' && file_type !== fileType) {
                this.state.taskTypesSelectContent = []
            }
            this.setState({
                fileType: fileType,
                enableMask: (isVideo(ext[1]) || isVideo(fileType)),
                enableTextType: (isText(ext[1]) || isText(fileType))
            });
        }
        else{
            this.setState({
                fileType:"",
                enableMask:false
            });
        }
    }
    public componentDidUpdate(prevProps: Props): void {
        const { status } = this.props;

        if (status === 'CREATED' && prevProps.status !== 'CREATED') {
            notification.info({
                message: i18n.t('create-task.taskCreated'),
            });

            //this.basicConfigurationComponent.resetFields();
            if (this.advancedConfigurationComponent) {
                this.advancedConfigurationComponent.resetFields();
            }

            this.fileManagerContainer.reset();
            this.handleUserGroups()

            this.setState({
                ...defaultState,
            });

            this.setState({
                isPrivate:false,
                textProjectType: 'SequenceLabeling',
                shapes: [],
                shapesSelectContent: [],
                taskTypesSelectContent:[],
                taskTypes:[],
                isPrivateTask: false,
                users: [],
                usersSelectContent: [],
                assigneeCount:2,
                metaData: [],
                key: '',
                value: '',
                metaDataDataset: [],
                keyDataset: '',
                valueDataset: '',
                projectName: '',
                mediaType: '',
                dataFormat: '',
                dataTimeLength: '',
                dataSize: '',
                dataContext: '',
                dataPrivacy: '',
                dataUsage: '',
                imageMaskData:[]
            });
        }
    }

    /**
    * Validate name
    *
    * @returns boolean
    */
    private validateName= (): boolean => {
        const { name} = this.state.basic;
        return !!name.length
    }

    /**
    * Validate guidelines
    *
    * @returns boolean
    */
    private validateGuidelines= (): boolean => {
        const { guidelines} = this.state.basic;
        return !!guidelines.length
    }

    /**
     * Validate taskType
     *
     * @returns boolean
     */
    private validateTaskType= (): boolean => {
        const { taskType} = this.state.basic;
        return !!taskType.length
    }

    /**
     * Validate labels
     *
     * @returns boolean
     */
    private validateLabels = (): boolean => {
        const { labels } = this.state;
        return !!labels.length;
    };


    /**
     * Validate files
     *
     * @returns boolean
     */
    private validateFiles() {
        const files = this.fileManagerContainer.getFiles();

        return new Promise(resolve => {
            this.setState(
                {
                    files: files
                },
                () => {
                    const totalLen = Object.keys(files).reduce((acc, key) => acc + files[key].length, 0,);
                    resolve(!!totalLen);

                }
            );
        });
    };

    /**
     * Set selectedDatasetId state
     */
    private getSelectedDatasetId = (): void => {
        const returnedDatasetId = this.fileManagerContainer.getSelectedDataset();
        console.log("Inside content" + returnedDatasetId)
        this.setState({
            selectedDatasetId: returnedDatasetId,
        });
    };

    /**
     * Set advanced state
     *
     * @param values
     */
    private handleSubmitAdvancedConfiguration = (values: AdvancedConfiguration): void => {
        this.setState({
            advanced: { ...values },
        });
    };

    /**
     * Handle submit click
     *
     * @returns Promise
     */
    private async handleSubmitClick() {
        if(!this.validateName()||!this.validateGuidelines()||!this.validateTaskType()){
            this.setState({
                nameNotValid:!this.validateName(),
                guidelinesNotValid:!this.validateGuidelines(),
                taskTypeNotValid: !this.validateTaskType()
            })
            return;
        }
        this.getSelectedDatasetId()
        if (!this.validateLabels()) {
            notification.error({
                message: i18n.t('create-task.taskCreateFailed'),
                description: i18n.t('create-task.atLeastOneLabel'),
            });
            return;
        }
        const result = await this.validateFiles();

        if (!result) {
            notification.error({
                message: i18n.t('create-task.taskCreateFailed'),
                description: i18n.t('create-task.atLeastOneFile'),
            });
            return;
        }
        else {

            if (this.advancedConfigurationComponent) {
                this.advancedConfigurationComponent.submit()
                    .then((): void => {
                        const { onCreate } = this.props;
                        onCreate(this.state);
                    }).catch((error: Error): void => {
                        notification.error({
                            message: i18n.t('create-task.taskCreateFailed'),
                            description: error.toString(),
                        });
                    });
            } else {
                try {

                    const { onCreate } = this.props;
                    onCreate(this.state);
                } catch (error) {
                    notification.error({
                        message: i18n.t('create-task.taskCreateFailed'),
                        description: error.toString(),
                    });
                };

            }
        }
    };

    /**
     * Set fromDataset state
     *
     * @param stateTaken
     */
    public getFiles(stateTaken: boolean) {
        this.setState({
            fromDataset: stateTaken
        });
    }

    /**
     * Add mask for privacy purposes
     *
     * @param start frame number
     * @param stop frame number
     */
    public addMask(start: any, stop: any) {
        let id;
        if (maskArray.length == 0)
            id = 0;
        else
            id = maskArray[maskArray.length - 1].id + 1

        let mask = {
            "id": id,
            "start": start,
            "stop": stop
        }

        maskArray.push(
            mask
        )

        let maskTotal = {
            "maskpoint": maskArray
        }

        this.setState({
            maskStartFrame: '',
            maskStopFrame: '',
            maskJson: maskTotal,
        });

        this.renderMaskList()
    }

    /**
     * Set privacy asynchronously
     *
     * @param e event
     */
    async setPrivacy(e: any) {
        if (e.target.value === i18n.t('keywords.private')) {
            this.setState({
                isPrivate: true
            })
        } else {
            this.setState({
                isPrivate: false
            })
        }
    }

    /**
     * Set privacy of task
     *
     * @param e event
     */
    async setPrivacyTask(e: any) {
        this.setState({
            isPrivateTask: e.target.checked
        })

    }


    /**
     * Set assigneeCount state
     */
    async handleChangeUserLimit(value: any) {
        this.setState({
            assigneeCount: Number(value)
        })
    }

    /**
     * Set taskDifficulty state
     */
    async handleChangeTaskDifficulty(value: any) {
        this.setState({
            taskDifficulty: Number(value)
        })
    }

    /**
     * Set textProjectType state
     * @param e event
     */
    onChangeTextProjectType = e => {
        this.setState({
            textProjectType: e.target.value,
        });
    };

    /**
     * Set users and textProjectType state
     * @param userList
     */
    public getSelectedUsers(userList: any) {
        this.setState({
            users: userList,
            usersSelectContent: userList
        });
    }

    /**
     * Delete duplicates from array
     *
     * @param array
     * @returns aunique
     */
    public arrayUnique(array: any) {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a;
    }

    /**
     * Set usersFromGroupsData, usedGroups state
     *
     * @param userList array
     * @param groupId
     */
    public setUsers(userList: any, groupId: any) {
        let updatedUsers = this.arrayUnique(this.state.usersFromGroupsData.concat(userList));
        let updatedGroups = this.arrayUnique(this.state.usedGroups.concat([groupId]));
        this.setState({
            usersFromGroupsData: updatedUsers,
            usedGroups: updatedGroups
        }, () => {
            console.log(updatedUsers)
        });
    }

    /**
     * Delete selected groupIf from users
     *
     * @param userList array
     * @param groupId
     */
    public async removeUsers(userList: any, groupId: any) {
        let arr = this.state.usersFromGroupsData
        let updatedGroups = this.state.usedGroups.filter(item => item !== groupId)
        for (var i = 0; i < userList.length; i++) {
            try {
                arr = arr.filter(item => item !== userList[i])
            } catch {
            }
        }
        this.setState({
            usersFromGroupsData: arr,
            usedGroups: updatedGroups
        }, () => {
            console.log(arr)
        });
    }

    /**
     * Render labels block
     * @returns Col
     */
    private renderLabelsBlock(): JSX.Element {
        const { labels } = this.state;

        return (
            <Col span={24}>
                <Text className='cvat-text-color' type='danger'>* </Text>
                <Text className='cvat-text-color'>{i18n.t('keywords.labels')}:</Text>
                <LabelsEditor
                    labels={labels}
                    onSubmit={
                        (newLabels): void => {
                            this.setState({
                                labels: newLabels,
                            });
                        }
                    }
                    textTask = {this.state.enableTextType}
                />
            </Col>
        );
    }

    /**
     * Clear imageMaskData state
     * @param files
     */
    public emptyMethod(files:any){
        this.setState({previewFiles:files,imageMaskData:[]},()=>console.log(this.state))
    }

    private renderFilesBlock(): JSX.Element {
        return (
            <div>
                <Text className='cvat-text-color' type='danger'>* </Text>
                <Text className='cvat-text-color'>{i18n.t('keywords.selectFiles')}:</Text>
                <FileManagerContainer
                    ref={
                        (container: any): void => { this.fileManagerContainer = container; }
                    }
                    withRemote
                    onLoad={this.emptyMethod}
                    isModify = {false}
                    user = {this.props.user}
                    parentMethod={this.getFiles}
                    getFileType={this.getFileType}
                />
            </div>
        );
    }
    /**
     * Render advanced block
     *
     * @returns Col
     */
    private renderAdvancedBlock(): JSX.Element {
        const { installedGit } = this.props;
        return (
            <Col data-tour="step-advanced" span={24}>
                <Collapse style={{boxShadow: "0px 3px 20px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "8px"}}>
                    <Collapse.Panel
                        key='1'
                        header={
                            <Text className='cvat-title-original'style={{color:"#0F123F"}}>{i18n.t('create-task.advancedConfiguration')}</Text>
                        }
                        style={{background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 3px 20px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "8px"}}
                    >
                        <AdvancedConfigurationForm
                            installedGit={installedGit}
                            wrappedComponentRef={
                                (component: any): void => {
                                    this.advancedConfigurationComponent = component;
                                }
                            }
                            onSubmit={this.handleSubmitAdvancedConfiguration}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>
        );
    }

    /**
     * Render maskList
     */
    public renderMaskList() {
        let maskViews = new Array();
        for (let i = 0; i < maskArray.length; i++) {
            maskViews.push(
                <ul class="list-unstyled" key={maskArray[i].id} >
                    <Row gutter = {8} >
                        <Col span = {8}>
                            <p>{i18n.t('keywords.startFrame')}: {maskArray[i].start}</p>
                        </Col>
                        <Col span = {8}>
                            <p>{i18n.t('keywords.stopFrame')}: {maskArray[i].stop}</p>
                        </Col>
                        <Col span = {8}>
                            <Button className="button-default-create-task" type='primary' size='small' ghost  onClick={(): void => {
                                let updateMaskArray = maskArray.filter(object => object.id != maskArray[i].id)
                                maskArray = updateMaskArray

                                this.renderMaskList();

                            }}>{i18n.t('keywords.delete')}</Button>
                        </Col>
                    </Row>
                </ul>
            )
        }
        this.setState({
            maskListView : maskViews,
        })
    }

    /**
     * Render masking field
     * @returns div
     */
    public renderMaskingField(){
        return(
            <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px", paddingLeft:"0.5%", paddingTop:"1%",maxWidth:"fit-content", backgroundColor: 'white'}}>
                {(maskArray.length != 0 ) ? <p>{i18n.t('keywords.maskedIntervals')}</p> : null}
                {this.state.maskListView}
                <div style={{display: 'flex',  alignItems: 'center'}}>
                    <Button
                        className="button-create-task"
                        type='primary'
                        size='default'
                        onClick={(): void => {
                            maskingPointsContent =

                            <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px", paddingLeft:"0.5%", paddingTop:"1%"}}>
                                <Row gutter = {8} style={{marginTop: '3%'}}>
                                    <Col span = {6}>
                                        <Input
                                            className="input-create-task"
                                            type='number'
                                            placeholder={i18n.t('keywords.startFrame')}
                                            onChange={(value) => this.setState({ maskStartFrame: value.target.value })}
                                        />
                                    </Col>
                                    <Col span = {6}>
                                        <Input
                                            className="input-create-task"
                                            type='number'
                                            placeholder={i18n.t('keywords.stopFrame')}
                                            onChange={(value) => this.setState({ maskStopFrame: value.target.value })}
                                        />
                                    </Col>
                                    <Col span = {3}>
                                        <Button
                                            className="button-create-task"
                                            type='primary'
                                            size='middle'
                                            onClick={(): void => {
                                                if(Number(this.state.maskStartFrame) > Number(this.state.maskStopFrame)){
                                                    Modal.error({
                                                        title: i18n.t('keywords.error'),
                                                        content: i18n.t('messages.startFrameCannotBeLarger'),
                                                    });
                                                }else if(this.state.maskStartFrame == '' || this.state.maskStartFrame == undefined ){
                                                    Modal.error({
                                                        title: i18n.t('keywords.error'),
                                                        content: i18n.t('messages.enterStartFrame'),
                                                    });
                                                }else if(this.state.maskStopFrame == '' || this.state.maskStopFrame == undefined){
                                                    Modal.error({
                                                        title: i18n.t('keywords.error'),
                                                        content: i18n.t('messages.enterStopFrame'),
                                                    });
                                                }else{
                                                    this.addMask(this.state.maskStartFrame,this.state.maskStopFrame)
                                                    maskingPointsContent = <div></div>
                                                    this.setState({ state: this.state })
                                                }

                                            }

                                            }
                                        >{i18n.t('keywords.add')}</Button>
                                    </Col>
                                    <Col span = {3}>
                                        <Button
                                            className="button-create-task"
                                            type='primary'
                                            size='default'
                                            onClick={(): void => {
                                                maskingPointsContent = <div></div>
                                                this.setState({ state: this.state })
                                                }} 
                                        >{i18n.t('keywords.cancel')}</Button>
                                    </Col>
                                </Row>
                            </div>
                            this.setState({ state: this.state })
                        }}
                    >{i18n.t('keywords.maskVideo')}</Button>
                    <span>&nbsp;</span>
                    <Tooltip title="Maskelemek istediğiniz aralığın frame değerlerini girerek videoyu maskeleyebilirsiniz. Detaylı kılavuz için tıklayın." >
                        <a href="../guides/goruntu_maskeleme_guide.pdf" target="_blank" rel="noopener" style={{ marginRight: '1%', marginLeft: '1%' }}>
                            <Icon style={{color:"rgb(190, 192, 207)", fontSize: '190%'}} type ="question-circle"/>
                        </a>
                    </Tooltip>
                </div>
                {maskingPointsContent}
            </div>
        );
    }

    /**
     * Render metadata part
     *
     * @returns div
     */
    private renderMetaData(): JSX.Element {

        return (
            <div>
                <Text className='cvat-text-color'>Anahtar-Değer:</Text>
                <div style={{background: "#F2F4F8 0% 0% no-repeat padding-box", borderRadius: "8px", paddingLeft:"0.5%", paddingTop:"1%"}}>
                <Row gutter = {8} >
                    <Col span = {8}>
                        <Input
                            style={{backgroundColor:"#FFFFFF"}}
                            className="input-create-task"
                            placeholder='Anahtar'
                            value = {this.state.key}
                            onChange={(value) =>
                                this.setState({
                                    key: value.target.value
                                })
                            }
                        />
                    </Col>
                    <Col span = {8}>
                        <Input
                            style={{backgroundColor:"#FFFFFF"}}
                            className="input-create-task"
                            placeholder='Değer'
                            value = {this.state.value}
                            onChange={(value) =>
                                this.setState({
                                    value: value.target.value
                                })
                            }
                        />
                    </Col>
                    <Col span = {6}>
                        <Button
                            type='primary'
                            size='small'
                            className="button-create-task"
                            onClick={(): void => {
                                if(this.state.key == '' || this.state.key == undefined ){
                                    Modal.error({
                                        title: i18n.t('keywords.error'),
                                        content: 'Anahtarı giriniz!',
                                    });
                                }else if(this.state.value == '' || this.state.value == undefined ){
                                    Modal.error({
                                        title: i18n.t('keywords.error'),
                                        content: 'Değeri giriniz!',
                                    });
                                }else{
                                    this.addpair(this.state.key, this.state.value)
                                }
                            }
                            }
                        >{i18n.t('keywords.add')}</Button>
                    </Col>
                </Row>
                <List
                    style={{ marginTop: '2%' }}
                    rowKey='fileName'
                    dataSource={this.state.metaData}
                    size="small"
                    pagination={false}
                    locale={{ emptyText: 'Anahtar-Değer verisi bulunmuyor.' }}
                    renderItem={item => (
                        <Row type="flex" gutter={[12,12]}>
                            <Col span={8}>
                                <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.key}</Text>

                            </Col>
                            <Col span={8}>
                                <Text style={{ padding: "3px", color: "#5A5C69", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "0.5px solid #D1D3E2", borderRadius: "8px", width: "100%" }}>{item.value}</Text>

                            </Col>
                            <Col span={4}>
                                <Popconfirm
                                    title="Silmek istediğinizden emin misiniz?"
                                    cancelText={i18n.t('keywords.no')}
                                    okText={i18n.t('keywords.yes')}
                                    onConfirm={
                                        (e) => {
                                            let currentMetaData = this.state.metaData
                                            currentMetaData = currentMetaData.filter(object => object.id != item.id)
                                            this.setState({
                                                metaData: currentMetaData
                                            })
                                        }
                                    }
                                >
                                    <Button size="small" className="button-default-create-task"> {i18n.t('keywords.delete')} </Button>
                                </Popconfirm>
                            </Col>

                        </Row>
                    )}
                >
                </List>
            </div>
        </div>
        );
    }

    /**
     * Reneder upload component
     *
     * @returns Upload
     */
    renderUploadPDFField() {
        return (
            <Upload multiple={false}
                accept="application/pdf"
                listType='text'
                fileList={this.state.guidelinesFile as any[]}
                beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                    if(newLocalFiles[0].type!="application/pdf"){
                        Modal.error({
                            title: 'Hata!',
                            content: 'Sadece PDF dosyaları yükleyebilirsiniz!',
                        });
                        return false
                    }
                    this.setState({
                        guidelinesFile: newLocalFiles
                    });
                    return false;
                }}
                onRemove={(): boolean => {
                    this.setState({
                        guidelinesFile: []
                    });
                    return false;
                }}
            >
                <Button className="button-default-create-task">
                    <Icon type="upload" /> Yönerge Dökümanı Yükle
                </Button>
            </Upload>
        )
    }

    /**
     * Render upload thumbnail field
     * @returns Upload
     */
    renderUploadThumbnailField() {
        return (
            <div>
                <Upload multiple={false}
                    accept="image/*"
                    listType='text'
                    fileList={this.state.thumbnail as any[]}
                    beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                        if(!newLocalFiles[0].type.includes("image")){
                            Modal.error({
                                title: 'Hata!',
                                content: 'Sadece görüntü dosyaları yükleyebilirsiniz!',
                            });
                            return false
                        }
                        if(newLocalFiles[0].size > 200000){
                            Modal.error({
                                title: 'Hata!',
                                content: 'Fotoğraf boyutu 200 KB ve altında olmalı!',
                            });
                            return false
                        }
                        this.setState({
                            thumbnail: newLocalFiles
                        });
                        return false;
                    }}
                    onRemove={(): boolean => {
                        this.setState({
                            thumbnail: []
                        });
                        return false;
                    }}
                >
                    <Button className="button-default-create-task">
                        <Icon type="upload" /> *Ön İzleme Yükle
                    </Button>
                </Upload>
                <Text style={{color:"#0F123F"}}>Ön izleme yükleyerek etiketleyicilerinizin ilgisini çekin.</Text>
            </div>
        );
    }

    /**
     * Render image editor
     *
     * @returns ImageMaskEditor
     */
    renderImageEditor(): JSX.Element{
        return(

            <ImageMaskEditor
                visible={this.state.modalVisible}
                getFiles={this.getMaskedData}
                fileList={this.state.previewFiles}
                setInvisible={()=>this.setState({modalVisible:false})}
            />

        );
    }

    /**
     * Set imageMaskData state
     *
     * @param data
     */
    getMaskedData(data:any){
        console.log(data)
        this.setState({imageMaskData:data},()=>console.log(this.state))
    }

    /**
     * Render shape selector
     *
     * @returns div
     */
    renderShapeSelector() {
        return (
            <div style = {{marginTop:'3%'}}>
                <Text className='cvat-text-color' type='danger' >* </Text>
                <Text className='cvat-text-color' > Şekiller:</Text>
                <Col span={24}>
                    <Select
                        className="input-create-task"
                        mode="multiple"
                        dropdownStyle={{background:"#F2F4F8"}}
                        style={{ width: '100%' }}
                        placeholder="Şekil seçiniz"
                        showArrow={true}
                        optionLabelProp="label"
                        onChange={(value) => {
                            console.log(value)
                            this.setState({
                                shapes : value,
                                shapesSelectContent: value
                            });
                        }}
                        value={this.state.shapesSelectContent}
                        optionFilterProp="children"
                        filterOption={(input, option) =>{
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        }
                    >
                        <Select.Option value={"rectangle"} label = {"Sınır Kutusu"} >Sınır Kutusu</Select.Option>
                        <Select.Option value={"polygon"} label = {"Poligon"}>Poligon</Select.Option>
                        <Select.Option value={"polyline"} label = {"Poliçizi"}>Poliçizi</Select.Option>
                        <Select.Option value={"points"} label = {"Nokta Kümesi"}>Nokta Kümesi</Select.Option>
                        <Select.Option value={"tag"} label = {"Etiket"}>Etiket</Select.Option>
                        <Select.Option value={"cuboid"} label = {"Küboid"}>Küboid</Select.Option>
                    </Select>
                </Col>
            </div>
        )
    }

    /**
     * Render task category block
     *
     * @returns div
     */
    renderTaskCategoryBlock() {
        return (
            !this.state.enableTextType ?
                <div style = {{marginTop:'3%'}}>
                    {/* <Text className='cvat-text-color' type='danger' >* </Text> */}
                    <Text className='cvat-text-color' > Görev Kategorisi:</Text>
                    <Col span={24}>
                        <Select
                            className="input-create-task"
                            mode="multiple"
                            dropdownStyle={{background:"#F2F4F8"}}
                            showArrow={true}
                            placeholder="Görev kategorisi seçiniz"
                            style={{ width: '100%' }}
                            optionLabelProp="label"
                            onChange={(value) => {
                                console.log(value)
                                this.setState({
                                    taskTypes : value,
                                    taskTypesSelectContent: value
                                });
                            }}
                            value={this.state.taskTypesSelectContent}
                            optionFilterProp="children"
                            filterOption={(input, option) =>{
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            }
                        >
                            <Select.Option value={"ObjectDetection"} label={"Nesne Tespiti"} >Nesne Tespiti</Select.Option>
                            <Select.Option value={"ObjectTracking"} label={"Nesne Takibi"}>Nesne Takibi</Select.Option>
                            <Select.Option value={"EventExtraction"} label={"Olay Çıkarımı"}>Olay Çıkarımı</Select.Option>
                            <Select.Option value={"Segmentation"} label={"Anlamsal Bölütleme"}>Anlamsal Bölütleme</Select.Option>
                            <Select.Option value={"Other"} label={"Diğer"}>Diğer</Select.Option>
                        </Select>
                    </Col>
                </div> :
                <div style = {{marginTop:'3%'}}>
                    {/* <Text className='cvat-text-color' type='danger' >* </Text> */}
                    <Text className='cvat-text-color' style={{marginLeft: '0.5%' }}> Görev Kategorisi:</Text>
                    <Col span={24}>
                        <Select
                            dropdownStyle={{background:"#F2F4F8"}}
                            className="input-create-task"
                            placeholder="Görev kategorisi seçiniz"
                            mode="multiple"
                            showArrow={true}
                            style={{ width: '100%' }}
                            optionLabelProp="label"
                            onChange={(value) => {
                                console.log(value)
                                this.setState({
                                    taskTypes: value,
                                    taskTypesSelectContent: value
                                });
                            }}
                            value={this.state.taskTypesSelectContent}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            }
                        >
                            <Select.Option value={"NLP"} label={"Doğal Dil İşleme"} >Doğal Dil İşleme</Select.Option>
                            <Select.Option value={"NER"} label={"Varlık İsim Tanıma"}>Varlık İsim Tanıma</Select.Option>
                            <Select.Option value={"Classification"} label={"Doküman Sınıflandırma"}>Doküman Sınıflandırma</Select.Option>
                            <Select.Option value={"SentimentAnalysis"} label={"Duygu Analizi"}>Duygu Analizi</Select.Option>
                            <Select.Option value={"SequenceToSequence"} label={"Sekans Öğrenme"}>Sekans Öğrenme</Select.Option>
                            <Select.Option value={"Other"} label={"Diğer"}>Diğer</Select.Option>
                        </Select>
                    </Col>
                </div>
        )
    }

    /**
     * Render popconfirm
     * @returns div
     */
    public render(): JSX.Element {
        const { status } = this.props;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED';
        const columnsUserGroups = [
            {
                title: i18n.t('groups.groupName'),
                dataIndex: 'groupName',
                key: 'groupName',
                render: text => <a style={{color:"#5A5C69"}}>{text}</a>,
            },
            {
                key: 'action',
                render: (record) => (
                    <div>
                        <Popconfirm
                            disabled={this.state.usedGroups.indexOf(record.id) !== -1}
                            title={i18n.t('groups.addWarning')}
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => {
                                    let addingUsers = new Array()
                                    for (let i = 0; i < record.members.length; i++) {
                                        addingUsers.push(record.members[i].id.username)
                                    }
                                    this.setUsers(addingUsers, record.id)
                                }
                            }
                        >
                            <Button disabled={this.state.usedGroups.indexOf(record.id) !== -1} className="button-create-task" size="small" > {i18n.t('keywords.add')} </Button>
                        </Popconfirm>
                        &nbsp;
                        &nbsp;
                        <Popconfirm
                        disabled={this.state.usedGroups.indexOf(record.id) === -1}
                        title={i18n.t('groups.removeWarning')}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        onConfirm={
                            (e) => {
                                let removingUsers = new Array()
                                for (let i = 0; i < record.members.length; i++) {
                                    removingUsers.push(record.members[i].id.username)
                                }
                                this.removeUsers(removingUsers, record.id)
                            }
                        }
                    >
                            <Button disabled={this.state.usedGroups.indexOf(record.id) === -1} className="button-default-create-task" size="small"> {i18n.t('keywords.delete')} </Button>
                        </Popconfirm>
                    </div>
                ),
            },
            {

            },
        ];

        return (
            <div>
                <div className='cvat-create-task-content'>
                    <Row type='flex' justify='space-between' gutter={[24, 24]} >
                        <Col span={12}>
                            <Text className='cvat-text-color' type='danger'>* </Text>
                            <Text className='cvat-text-color'>{i18n.t('keywords.name')}:</Text>
                            <Input data-tour="step-name" className="input-create-task" value={this.state.basic.name} suffix={this.state.nameNotValid&&<Icon theme="filled" style={{animationName: "diffZoomIn2 !important",color:"#f5222d"}}type="close-circle"/>} onChange={(e) => this.setState({ basic: { name: e.target.value, taskType: this.state.basic.taskType, guidelines: this.state.basic.guidelines },nameNotValid:false })} />
                            {this.state.nameNotValid&&<Text type="danger">Lütfen bir ad giriniz</Text>}
                            <br></br>
                            <br></br>
                            <br></br>
                            <Row data-tour="step-difficulty" gutter={[0, 48]}>
                                <Text className='cvat-text-color' type='danger' >* </Text>
                                <Text className='cvat-text-color'> Görev Zorluğu: {this.state.taskDifficulty}</Text>
                                <br></br>
                                <Col span={12} >
                                <Slider min={1} max={10} step={1} className="task-slider" onChange={this.handleChangeTaskDifficulty} dots={true} tooltipPlacement='right'></Slider>
                            </Col>
                            </Row>

                            <Row data-tour="step-user-limit"gutter={[0, 48]}>
                                <Text className='cvat-text-color' type='danger' >* </Text>
                                <Text className='cvat-text-color'> Kullanıcı Limiti: {this.state.assigneeCount}</Text>
                                <br></br>
                                <Col span={12} >
                                <Slider min={2} max={10} step={1} className="task-slider" onChange={this.handleChangeUserLimit} dots={true} tooltipPlacement='right'></Slider>
                            </Col>
                            </Row>

                        </Col>
                        <Col data-tour="step-type-privacy" span={12}>
                            <Col span={24} >
                                <Text className='cvat-text-color' type='danger'>* </Text>
                                <Text className='cvat-text-color'>{i18n.t('create-task.selectTaskType')}:</Text>
                                <br></br>
                                <br></br>
                                <Radio.Group value={this.state.basic.taskType} onChange={(e) => this.setState({ basic: { name: this.state.basic.name, guidelines: this.state.basic.guidelines, taskType: e.target.value },taskTypeNotValid:false })}>
                                    <Radio value={"normal"}>{i18n.t('keywords.normal')}</Radio>
                                    <Radio value={"test"}>{i18n.t('keywords.test')}</Radio>
                                    <Radio value={"training"}>{i18n.t('keywords.training')}</Radio>
                                </Radio.Group>
                                {this.state.taskTypeNotValid&&<Text type="danger">Lütfen görev tipi seçiniz</Text>}

                            </Col>
                            {this.state.enableTextType &&
                                <Col span={24} >
                                    <Text className='cvat-text-color' type='danger'>* </Text>
                                    <Text className='cvat-text-color'>Metin Görev Tipi: </Text>
                                    <br></br>
                                    <br></br>
                                    <Radio.Group defaultValue={'SequenceLabeling'} onChange={this.onChangeTextProjectType} value={this.state.textProjectType}>
                                        <Radio value={'SequenceLabeling'}>Metin Etiketleme</Radio>
                                        <Radio value={'Seq2seq'}>Sekans Öğrenme</Radio>
                                        <Radio value={'DocumentClassification'}>Doküman Sınıflandırma</Radio>
                                    </Radio.Group>
                                </Col>
                            }

                            <Col span={24} >
                                <Text className='cvat-text-color' type='danger' >* </Text>
                                <Text className='cvat-text-color'> Görev Gizliliği:</Text>
                                    <Checkbox style={{marginLeft:"2%"}} checked={this.state.isPrivateTask} onChange={(e)=>this.setPrivacyTask(e)}>
                                        {i18n.t('keywords.private')}
                                    </Checkbox>
                            </Col>

                            {this.state.isPrivateTask && <Col span={24} >
                                <Row type="flex" justify="space-around" gutter={8}>
                                    <Col span={8}>
                                        <UserSelector
                                            parentMethod={this.getSelectedUsers}
                                            onChange={(value: string): void => {
                                            }}
                                            placeholder="Kullanıcı Ekleyiniz"
                                            className="input-create-task"
                                            showArrow={true}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <Text strong>Kullanıcı Grupları</Text>
                                            <br/>
                                            <Text type="secondary">Eklediğiniz gruptaki üyeler görev etiketleyicilerine eklenir.</Text>
                                            <Table
                                                className="cvat-task-groups-table"
                                                rowClassName={(record,index)=> {return"table-row-groups"}}
                                                rowKey={record => record.id}
                                                expandedRowRender={(record) =>this.handleExpand(record)}
                                                locale={{emptyText: i18n.t('groups.noUserGroups')}}
                                                columns={columnsUserGroups}
                                                expandIconColumnIndex={2}
                                                expandIconAsCell={false}
                                                expandIcon={({ expanded, onExpand, record }) =>

                                                    <Icon type="down" onClick={e => onExpand(record, e)} />

                                                }
                                                dataSource={this.state.usersFromGroups}
                                                pagination={{ size:"small", defaultPageSize: 5, showSizeChanger: true, position: 'bottom' , pageSizeOptions: ['5', '10', '20']}}>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>}

                        </Col>
                    </Row>
                    <Row type='flex' justify='space-between' gutter={[24, 24]}>
                        <Col data-tour="step-guide-shape-category" span={12}>
                            <Text className='cvat-text-color' type='danger'>* </Text>
                            <Text className='cvat-text-color'>{i18n.t('keywords.guidelines')}:</Text>
                            <Input.TextArea
                                className="input-create-task"
                                value={this.state.basic.guidelines}
                                onChange={(e) => this.setState({ basic: { name: this.state.basic.name, taskType: this.state.basic.taskType, guidelines: e.target.value },guidelinesNotValid:false })}
                                rows={5}
                                placeholder={i18n.t('create-task.guidelineTooltip')}
                            />
                            {this.state.guidelinesNotValid&&<Text type="danger">Lütfen yönerge giriniz</Text>}

                            <br></br>
                            <br></br>
                            <Row type="flex" justify="start">
                                <div style={{margin:"5px 10px"}}>
                                {this.renderUploadPDFField()}
                                </div>
                                <div style={{margin:"5px 10px"}}>
                                {this.renderUploadThumbnailField()}
                                </div>
                            </Row>
                            {this.renderShapeSelector()}
                            {this.renderTaskCategoryBlock()}
                        </Col>
                        <Col data-tour="step-labels" span={12}>

                            {this.renderLabelsBlock()}
                        </Col>
                        <Col data-tour="step-metadata" span={12}>
                            {this.renderMetaData()}
                        </Col>
                        <Col data-tour="step-files" span={12}>
                            {this.renderFilesBlock()}
                            {this.state.enableMask ? <Col span={18}> {this.renderMaskingField()}</Col> : <></>}
                            {!(isVideo(this.state.fileType)||isText(this.state.fileType))&&
                                <Button style={{marginTop:"12px"}} className="button-create-task" onClick={()=>this.setState({modalVisible:true})}> Görüntü Maskele</Button>
                            }

                            {!(isVideo(this.state.fileType) || isText(this.state.fileType)) && <a href="../guides/goruntu_maskeleme_guide.pdf" target="_blank" rel="noopener noreferrer" style={{ marginRight: '1%', marginLeft: '1%' }}>
                                <Tooltip title="Maskelemek istediğiniz görüntüler için siyah kutular ekleyerek görüntüyü maskeleyebilirsiniz. Detaylı kılavuz için tıklayın." >
                                    <Icon style={{ color: "rgb(190, 192, 207)", fontSize: '190%' }} type="question-circle" />
                                </Tooltip>
                            </a>}
                            {(!this.state.fromDataset) &&
                                <Button style={{marginTop:"12px"}} className="button-create-task" onClick={()=>this.setState({metaDataDatasetModalVisible:true})}>Veri Kümesi Yapılandır</Button>
                            }
                            {this.renderImageEditor()}
                            {this.renderMetaDataDataset()}
                        </Col>
                        {loading ? <Col span={18}> <Alert message={status} /></Col> : <></>}
                    </Row>
                </div>
                <br></br>

                <Row type="flex" justify="start">

                    {this.renderAdvancedBlock()}

                </Row>
                <br></br>
                <Row data-tour="step-submit" justify="center" type="flex">
                    <Col >
                        <Button
                            id="Submit"
                            className="button-create-task"
                            size="large"
                            loading={loading}
                            disabled={loading}
                            type='primary'
                            onClick={this.handleSubmitClick}
                        >
                            {i18n.t('create-task.createNewTask')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}
