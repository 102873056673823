// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Menu, { ClickParam } from 'antd/lib/menu';
import Modal from 'antd/lib/modal';

import DumpSubmenu from './dump-submenu';
import LoadSubmenu from './load-submenu';
import ExportSubmenu from './export-submenu';
import getCore from 'cvat-core-wrapper';
import { useTranslation } from 'react-i18next';
import { Slider, Select, Button } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useState, useEffect } from 'react';

const cvat = getCore();
let assigneeCount = 0
let taskDifficulty = 0


interface Props {
    taskID: number;
    taskMode: string;
    bugTracker: string;

    loaders: any[];
    dumpers: any[];
    loadActivity: string | null;
    dumpActivities: string[] | null;
    exportActivities: string[] | null;

    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    installedAutoAnnotation: boolean;
    inferenceIsActive: boolean;
    user: any;
    taskInstance: any
    viewer:boolean
    onClickMenu: (params: ClickParam, file?: File, labels?:any) => void;
    annotaUser: any
}

export enum Actions {
    DUMP_TASK_ANNO = 'dump_task_anno',
    LOAD_TASK_ANNO = 'load_task_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    DELETE_TASK = 'delete_task',
    RUN_AUTO_ANNOTATION = 'run_auto_annotation',
    OPEN_BUG_TRACKER = 'open_bug_tracker',
    SET_ASSIGNEE_LIMIT = 'set_assignee_limit',
    EXPORT_TEXT_DATASET = 'export_text_dataset',
    SET_TASK_DIFFICULTY = 'set_task_difficulty',
    DOWNLOAD_IMAGES_LARGE = 'download_images_large',
    DOWNLOAD_IMAGES_MIDDLE = 'download_images_middle',
    DOWNLOAD_IMAGES_SMALL = 'download_images_small',
    SELECT_LABELS = 'select_labels'
}

/**
 * Converts value to number to ensure variable type
 * @param value
 */
const handleChange = (value) => {
    assigneeCount = Number(value)
}

/**
 * Converts value to number to ensure variable type
 * @param value
 */
const handleChangeTaskDifficulty = (value) => {
    taskDifficulty = Number(value)
}

export default function ActionsMenuComponent(props: Props): JSX.Element {
    const {
        taskID,
        taskMode,
        bugTracker,

        installedAutoAnnotation,
        installedTFAnnotation,
        installedTFSegmentation,
        inferenceIsActive,
        user,

        dumpers,
        loaders,
        onClickMenu,
        dumpActivities,
        exportActivities,
        loadActivity,
        taskInstance,
        viewer,
        annotaUser
    } = props;
    const { t } = useTranslation();

    const [labelSelectionModalVisible, setLabelSelectionModalVisible] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState();

    const renderModelRunner = installedAutoAnnotation
        || installedTFAnnotation || installedTFSegmentation;

    let permissions = annotaUser[0].group_role_permissions
    let hasDumpPermission = permissions.filter(object => object.permission_id__name == 'dump_annotations').length > 0
    let hasImageExportPermission = permissions.filter(object => object.permission_id__name == 'export_images').length > 0

    let latestParams: ClickParam | null = null;

    /**
     * Update Modal based on action
     * This actions can be LOAD_TASK_ANNO, DOWNLOAD_IMAGES_LARGE, DOWNLOAD_IMAGES_MIDDLE, DOWNLOAD_IMAGES_SMALL, DELETE_TASK, SET_ASSIGNEE_LIMIT, SET_TASK_DIFFICULTY, EXPORT_TEXT_DATASET, SELECT_LABELS
     *
     * @param params ClickParam
     * @param file File
     * @returns Menu component which consists of task detail
     */
    async function onClickMenuWrapper(params: ClickParam | null, file?: File): void {
        params.domEvent.preventDefault()
        params.domEvent.stopPropagation()

        let annotaTask = await cvat.tasks.getAnnotaTasks(null,taskID);
        const copyParams = params || latestParams;
        if (!copyParams) {
            return;
        }
        latestParams = copyParams;

        if (copyParams.keyPath.length === 2) {
            const [, action] = copyParams.keyPath;
            if (action === Actions.LOAD_TASK_ANNO) {
                if (file) {
                    Modal.confirm({
                        title: 'Önceki etiketlemelerinizi kaybedeceksiniz.',
                        content: 'Yeni etiketlemeler yüklüyorsunuz. Devam edilsin mi?',
                        onOk: () => {
                            onClickMenu(copyParams, file);
                        },
                        okButtonProps: {
                            type: 'danger',
                        },
                        okText: 'Güncelle',
                    });
                }
            } else {
                let annotatedImagesResult;
                if (copyParams.key === Actions.DOWNLOAD_IMAGES_LARGE) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(taskInstance.id, 'large');
                }  else if (copyParams.key === Actions.DOWNLOAD_IMAGES_MIDDLE) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(taskInstance.id, 'middle');
                }  else if (copyParams.key === Actions.DOWNLOAD_IMAGES_SMALL) {
                    annotatedImagesResult = await cvat.tasks.downloadAnnotationImages(taskInstance.id, 'small');
                } else{
                    onClickMenu(copyParams, undefined, selectedLabels);
                }
                if(annotatedImagesResult){
                    if(annotatedImagesResult == 'notReady'){
                        Modal.error({
                            title: 'Etiketlenmiş imgeler hazır değil!',
                            content: `Görev üzerinde tamamlannmış bir iş bulunmalı!`,
                        });
                    }else if(annotatedImagesResult == 'preparing'){
                        Modal.error({
                            title: 'Etiketlenmiş imgeler hazırlanıyor!',
                            content: `Lütfen daha sonra tekrar deneyiniz!`,
                        });
                    }
                }
            }
        } else if (copyParams.key === Actions.DELETE_TASK) {
            Modal.confirm({
                title: `${taskID} numaralı görev silinecek.`,
                content: 'Görevle ilişkilendirilmiş bütün veriler silinecek. Devam edilsin mi?',
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    type: 'danger',
                },
                okText: 'Sil',
            });
        } else if (copyParams.key === Actions.SET_ASSIGNEE_LIMIT) {
            if(assigneeCount == 0) {
                Modal.error({
                    content: `Değiştirmek istediğiniz kullanıcı limitini kaydırma düğmesi üzerinden ayarlayınız.`,
                });
                return;
            }
            let tryApiAssignTask = await cvat.tasks.setassigneelimit(taskID,assigneeCount);
            // Modal.success({
            //     title: 'Başarılı',
            //     content: tryApiAssignTask.data.status,
            // });
            window.location.reload()
            return;
        } else if (copyParams.key === Actions.SET_TASK_DIFFICULTY) {
            if(taskDifficulty == 0) {
                Modal.error({
                    content: `Görev zorluğunu seçiniz.`,
                });
                return;
            }
            let response = await cvat.tasks.setTaskDifficulty(taskID,taskDifficulty);
            // Modal.success({
            //     title: 'Başarılı',
            //     content: response.data.status,
            // });
            window.location.reload()
            return;
        } else if (copyParams.key === Actions.EXPORT_TEXT_DATASET) {
            let textTaskJsonExportResult = await cvat.tasks.getTextTaskDataset(annotaTask.doccanoProjectId, selectedLabels);
            return;
        } else if (copyParams.key === Actions.SELECT_LABELS) {
            setLabelSelectionModalVisible(true)
        } else {
            onClickMenu(copyParams);
        }
    }
    return (
        <div>
        <Menu
            selectable={false}
            className='cvat-actions-menu'
            onClick={onClickMenuWrapper}
        >
            {(Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) != 0 && ((user.id == taskInstance.owner.id  && hasDumpPermission) || user.isSuperuser || viewer  || hasDumpPermission) ) ? <Menu.Item key={Actions.EXPORT_TEXT_DATASET}>Etiketleri İndir</Menu.Item> : null}
            {(Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) == 0 && !taskInstance.tasksIdAnnota[0].pointCloudFileName && ((user.id == taskInstance.owner.id  && hasImageExportPermission) || user.isSuperuser || viewer || hasImageExportPermission) ) ?
                   <SubMenu title="İmgeleri İndir" onTitleClick={({ key, domEvent })=>{domEvent.preventDefault();domEvent.stopPropagation()}}>
                        <Menu.Item className='cvat-menu-dump-submenu-item' key={Actions.DOWNLOAD_IMAGES_LARGE}>Yükseltilmiş</Menu.Item>
                        <Menu.Item className='cvat-menu-dump-submenu-item' key={Actions.DOWNLOAD_IMAGES_MIDDLE}>Orijinal</Menu.Item>
                        <Menu.Item className='cvat-menu-dump-submenu-item' key={Actions.DOWNLOAD_IMAGES_SMALL}>Küçültülmüş</Menu.Item>
                    </SubMenu>
            : null}
            {(Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) == 0 && !taskInstance.tasksIdAnnota[0].pointCloudFileName && ((user.id == taskInstance.owner.id && hasDumpPermission)|| user.isSuperuser || viewer  || hasDumpPermission) ) &&
                DumpSubmenu({
                    taskMode,
                    dumpers,
                    dumpActivities,
                    menuKey: Actions.DUMP_TASK_ANNO,
                })
            }
            {!taskInstance.tasksIdAnnota[0].pointCloudFileName && ((user.id == taskInstance.owner.id && hasDumpPermission) || user.isSuperuser) && <Menu.Item key={Actions.SELECT_LABELS}>
                İndirilecek etiketleri seç
            </Menu.Item> }
            {(Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) == 0 && !taskInstance.tasksIdAnnota[0].pointCloudFileName && (user.id == taskInstance.owner.id || user.isSuperuser)) &&
                LoadSubmenu({
                    loaders,
                    loadActivity,
                    onFileUpload: (file: File): void => {
                        onClickMenuWrapper(null, file);
                    },
                    menuKey: Actions.LOAD_TASK_ANNO,
                })
            }
           {/*  {(Number(taskInstance.tasksIdAnnota[0].doccanoProjectId) == 0 && (user.id == taskInstance.owner.id || user.isSuperuser)) &&
                ExportSubmenu({
                    exporters: dumpers,
                    exportActivities,
                    menuKey: Actions.EXPORT_TASK_DATASET,
                })
            } */}
            {!!bugTracker && <Menu.Item key={Actions.OPEN_BUG_TRACKER}>{t('actions-menu.openBugTracker')}</Menu.Item>}
            {
                renderModelRunner
                    && (
                        <Menu.Item
                            disabled={inferenceIsActive}
                            key={Actions.RUN_AUTO_ANNOTATION}
                        >
                            {t('keywords.automaticAnnotation')}
                        </Menu.Item>
                    )
            }
            <hr />
            {!(viewer) &&
                <Menu.Item key={Actions.DELETE_TASK}>{t('keywords.delete')}</Menu.Item>
            }
            {!(viewer) &&
                <div onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
                    <Slider className="task-slider" min={2} max={5} step={1} onChange={handleChange} defaultValue={taskInstance.tasksIdAnnota[0].numOfAssigneeLimit} dots={true} tooltipPlacement='right'></Slider>
                </div>
            }
            {!(viewer) &&
                <Menu.Item key={Actions.SET_ASSIGNEE_LIMIT}>{t('actions-menu.setAssigneeLimit')}</Menu.Item>
            }
            {!(viewer) &&
                <div onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
                    <Slider className="task-slider" min={1} max={5} step={1} onChange={handleChangeTaskDifficulty} defaultValue={taskInstance.tasksIdAnnota[0].taskDifficulty} dots={true} tooltipPlacement='right'></Slider>
                </div>
            }
            {!(viewer) &&
                <Menu.Item key={Actions.SET_TASK_DIFFICULTY}>{t('actions-menu.setTaskDifficulty')}</Menu.Item>
            }
        </Menu>

        <div onClick={(e)=>{e.preventDefault();e.stopPropagation()}}>
        <Modal
            closable={false}
            visible={labelSelectionModalVisible}
            // onOk={()=>setLabelSelectionModalVisible(false)}
            footer={[
                <Button key="submit" className="button-send" type="primary" onClick={()=>setLabelSelectionModalVisible(false)}>
                Tamam
                </Button>,
            ]}
            >
            <Select
                dropdownStyle={{background:"#F2F4F8"}}
                className="input-create-task"
                placeholder="İndirilecek etiketleri seçiniz"
                mode="multiple"
                // showArrow={true}
                style={{ width: '100%' }}
                value={selectedLabels}
                onChange={(value) => setSelectedLabels(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>{
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                }
                >
                    {taskInstance.labels.map((label: any): JSX.Element => (
                        <Select.Option
                            value={label.id}
                            key={label.id}
                        >
                            {label.name}
                        </Select.Option>
                    ))}
            </Select>
        </Modal>
        </div>
        </div>
    );
}
