import './styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col, Modal, Select, Collapse, Table, Divider, Pagination, Input, Icon, Radio, Checkbox, Popconfirm, Menu, Dropdown } from 'antd';
import 'antd/dist/antd.css';
import getCore from 'cvat-core-wrapper';
import i18n from '../i18n';
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import { exportDatasetAsync } from 'actions/tasks-actions';
import { recordKeyCombination } from 'react-hotkeys';
import { array } from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { EmptyCircleIcon } from 'icons';
import UserPermissions from './user-permissions'

const cvat = getCore();
const { Option } = Select;
let UserList = <div></div>
let userTable = new Array();

/**
 * @class_component AdminPage Component renders admin sections
 */
class AdminPage extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            searchFilter: null,
            userStatusFilter: null,
            groupFilter: [],
            currentPage: 1,
            totalRelatedUserCount: 0,
            clickedOption: "",
            modalVisible: false,
            currentUser: null,
            rolesCount: 0,
            roles: new Array()
        }
        this.handleOptChange = this.handleOptChange.bind(this);
        this.updateCurrentUsers = this.updateCurrentUsers.bind(this);
        const usersAsync = this.getUsers(this.state.searchFilter, this.state.userStatusFilter, 1, this.state.groupFilter);
        this.getRoles = this.getRoles.bind(this);
        this.getRoles();
    }

    /**
     * @description get and set table width
     */
    getSize() {
        const tdWidthArr = [...document.querySelectorAll('.ant-table-tbody>tr:first-of-type>td')].map(td => td.offsetWidth);
        console.log(tdWidthArr);
        /*  const table2 = document.querySelectorAll('table')[1];
         */

        /* document.querySelectorAll('table')[0].querySelectorAll('td').forEach((td,i)=>{
 
            td.style.paddingLeft="1px";
        }) */
        document.querySelectorAll('table')[0].querySelectorAll('th').forEach((th, i) => {
            th.style.width = tdWidthArr[i] + 'px';
            th.style.padding = "16px 16px 16px 1px";
        })
    }

    getRole(user: any) {
        let roles = "";
        if (user.groups.includes("admin"))
            roles += "Platform Yönetici "
        if (user.groups.includes("system_admin"))
            roles += "Sistem Yönetici "
        if (user.groups.includes("provider"))
            roles += "Veri Sağlayıcı "
        if (user.groups.includes("annotator") || user.groups.includes("candidate"))
            roles += "Etiketleyici"
        return roles;

    }

    /**
     * @async
     * @description get roles api proxy call, sets state on role count and roles
     */
    async getRoles() {
        let response = await cvat.management.getRoles()
        console.log(`Roles response : ${response.results}`);
        this.setState({
            // rolesCount: response.count-1,//admin excluded
            rolesCount: response.count,//admin excluded
            // roles: response.results.filter(object => object.name != 'admin'),
            roles: response.results,
        }, () => {
            this.getUsers(this.state.searchFilter, this.state.userStatusFilter, 1, this.state.groupFilter);
        });
    }

    /**
     * @async
     * @param users
     * @description creates new userTable template, updates state
     */
    async handleUsers(users: any[]) {
        userTable = new Array();
        console.log(users)
        userTable.push(
            <Table
                rowKey={record => record.id.id}
                locale={{ emptyText: 'Sonuç Bulunamadı' }}
                expandedRowRender={(record) => <>{this.handleExpand(record)} </>}
                dataSource={users}
                pagination={false}
                expandIconColumnIndex={8}
                expandIconAsCell={false}
                rowClassName={(record, index) => { return "table-row-datasets" }}
                className="custom-table-datasets"
                expandIcon={({ expanded, onExpand, record }) =>
                    <Icon type="down" onClick={e => onExpand(record, e)} />
                }
                style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 10px 30px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "6px" }}
            >
                <Column title={i18n.t('admin-page.userName')} key="id.username" render={(record) =>
                    <div>
                        {record.id.username}
                        <p style={{ fontSize: 10, margin: 0, padding: 0 }}>
                            {record.id.groups.map((eachRole: String) => this.getRoleName(eachRole)).join(", ")}
                        </p>
                    </div>
                } />
                <Column title={i18n.t('admin-page.name') + " " + i18n.t('admin-page.lastName')} render={(record) => record.id.first_name + " " + record.id.last_name} key="id.first_name" />
                <Column title={i18n.t('admin-page.userState')} render={(record) => <> {!record.isBlocked ? this.getStatus(record.state) : "Engelli"} </>} key="state" />
                <Column title={i18n.t('admin-page.userLevel')}
                    render={(record) =>
                        this.getStatus(record.level) == '-' ? '-' :
                            <>
                                <div style={{ display: "flex" }}>
                                    {this.getStatus(record.level)}
                                    <div style={{ marginLeft: "3px", display: "flex", flexDirection: "column" }}>
                                        <Popconfirm title="Kullanıcı seviyesini arttırmak istediğinizden emin misiniz? Kullanıcı skorunu yeni seviyenin minimum skoruyla güncelleyeceksiniz."
                                            cancelText={i18n.t('keywords.no')}
                                            okText={i18n.t('keywords.yes')}
                                            onConfirm={(e) => { this.handleUser(record.id.id, "promote") }
                                            }>
                                            <Icon style={{ color: "#0F123F" }} type="up" />
                                        </Popconfirm>
                                        <Popconfirm title="Kullanıcı seviyesini düşürmek istediğinizden emin misiniz? Kullanıcı skorunu yeni seviyenin minimum skoruyla güncelleyeceksiniz."
                                            cancelText={i18n.t('keywords.no')}
                                            okText={i18n.t('keywords.yes')}
                                            onConfirm={(e) => { this.handleUser(record.id.id, "demote") }
                                            }>
                                            <Icon style={{ color: "#0F123F" }} type="down" />
                                        </Popconfirm>
                                    </div>
                                </div>
                                <p style={{ margin: 0, padding: 0, fontSize: 10 }}>
                                    {"%" + record.percentageOfCurrentLevel.toFixed(2)}
                                </p>
                            </>
                    }
                    key="level" />
                <Column title="Uzman"
                    render={(record) => <Popconfirm
                        title={record.expert != "expert" ? "Kullanıcıya uzmanlık veriyorsunuz. Devam edilsin mi?" : "Kullanıcının uzmanlığını kaldırıyorsunuz. Devam edilsin mi?"}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        disabled={record.state == "rejected"}
                        onConfirm={
                            (e) => record.expert != "expert" ? this.handleUser(record.id.id, "bless") : this.handleUser(record.id.id, "curse")
                        }>
                        <Checkbox disabled={record.state == "rejected"} checked={record.expert == "expert"} >Uzman</Checkbox>
                    </Popconfirm>}
                    key="expert" />
                <Column title="Kullanıcı Rolü" key="action" render={(record) =>
                    <div>
                        {this.showUserSetOptionsTable(record)}
                        <div style={{ marginTop: "5px" }}>
                            {(record.state == "pending") && <div>
                                <Button style={{ borderRadius: "4px" }} className="button-groups-page" onClick={() => this.handleUser(record.id.id, "approve")}>Kullanıcıyı Onayla</Button>
                                <Button style={{ borderRadius: "4px", marginLeft: "5px" }} className="button-groups-page" onClick={() => this.handleUser(record.id.id, "reject")}>Kullanıcıyı Reddet</Button>
                            </div>
                            }
                            {/* {!((record.state=="rejected")||record.id.groups.includes("candidate"))&& <Button style = {{borderRadius:"4px"}} className="button-groups-page" id={record.id.username + "Apply"} type="primary" onClick={() =>
                            this.setState({
                                modalVisible: true,
                                currentUser: record
                                })
                            }>
                            Yetki
                        </Button>} */}
                        </div>
                    </div>
                }
                />
                <Column title="Giriş İzni"
                    render={(record) => <Popconfirm
                        title={record.canLogin ? "Kullanıcının giriş iznini kaldırıyorsunuz. Devam edilsin mi?" : "Kullanıcıya giriş izni veriyorsunuz. Devam edilsin mi?"}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        disabled={record.state == "rejected"}
                        onConfirm={
                            (e) => record.canLogin ? this.handleUser(record.id.id, "take") : this.handleUser(record.id.id, "give")
                        }>
                        <Checkbox disabled={record.state == "rejected"} checked={record.canLogin} >{!record.canLogin ? "İzin Ver" : "İzni Kaldır"}</Checkbox>
                    </Popconfirm>}
                    key="permission" />
                <Column title="Engel"
                    render={(record) => <Popconfirm
                        title={record.isBlocked ? "Kullanıcının engeli kaldırılsın mı?" : "Kullanıcı engellensin mi?"}
                        cancelText={i18n.t('keywords.no')}
                        okText={i18n.t('keywords.yes')}
                        onConfirm={
                            (e) => record.isBlocked ? this.handleUser(record.id.id, "unblock") : this.handleUser(record.id.id, "block")
                        }>
                        <div>
                            <Icon style={record.isBlocked ? { color: "#FF5F5F", cursor: "pointer", transform: "scaleX(-1)" } : { cursor: "pointer", transform: "scaleX(-1)" }} type="stop" />
                            {record.isBlocked && <Text style={{ marginLeft: "4px" }}>Engellendi</Text>}
                        </div>
                    </Popconfirm>}
                    key="block" />
                <Column></Column>
            </Table>
        );

        this.setState({
            state: this.state
        });
        this.getSize()
    }

    /**
     *
     * @param e modal OK event
     * @description get users with current filters, close modal
     */
    handleOk = e => {
        this.setState({
            modalVisible: false,
        }, () => {
            this.getUsers(this.state.searchFilter, this.state.userStatusFilter, this.state.currentPage, this.state.groupFilter);
            Modal.destroyAll();
        });
    };

    /**
     *
     * @param e model CANCEL event
     * @description get users with current filters, close modal
     */
    handleCancel = e => {
        this.setState({
            modalVisible: false,
        }), () => {
            this.getUsers(this.state.searchFilter, this.state.userStatusFilter, this.state.currentPage, this.state.groupFilter);
            Modal.destroyAll();
        };
    };

    /**
     * @description get users with current filters
     */
    public updateCurrentUsers() {
        this.getUsers(this.state.searchFilter, this.state.userStatusFilter, this.state.currentPage, this.state.groupFilter);
    }

    /**
     *
     * @returns Modal template for permission update, renders UserPermissions Component instance
     */
    private renderModal() {

        return (
            <Modal
                title="Kullanıcı Yetkilerini Güncelleyin"
                visible={this.state.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                footer={null}
            >
                <UserPermissions updateUsers={this.updateCurrentUsers} annotaUser={this.state.currentUser}></UserPermissions>
            </Modal>
        )
    }

    /**
     *
     * @param user
     * @returns Expanded row template per user selected to be expanded on table
     */
    private handleExpand(user: any) {
        let JSONUserEducation = JSON.parse(user.education);
        return (
            <Row type="flex" gutter={[32, 0]} justify="space-around">
                <Col span={7} style={{ borderRadius: "8px" }} >
                    <div style={{ padding: "10px 16px", backgroundColor: "#FFFFFF", borderRadius: "8px", border: "1px solid #D1D3E2" }} >
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.userInfo')}</b></p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.email')}:</b> {user.id.email}</p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.phoneNumber')}:</b> {user.phoneNumber}</p>
                        {(user.dateOfBirth != null) ? <p><b>{i18n.t('admin-page.birthDate')}:</b> {user.dateOfBirth.toString().substring(0, 10)}</p> : null}
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.workingState')}:</b> {(user.isWorking == "Working") ? "Çalışıyor" : (user.isWorking == "Student") ? "Öğrenci" : "Mezun"}</p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.company')}:</b> {user.workPlace}</p>
                    </div>
                    <div style={{ marginTop: "10px", padding: "10px 16px", backgroundColor: "#FFFFFF", borderRadius: "8px", border: "1px solid #D1D3E2" }} >
                        <p><b>Adres Bilgileri</b></p>
                        <p style={{ whiteSpace: "break-spaces" }}>
                            <b>{i18n.t('admin-page.city')}:</b> {user.city}
                            <span style={{ left: "25%", position: "relative" }}><b>{i18n.t('admin-page.town')}:</b> {user.town}</span>
                        </p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.address')}:</b> {user.address}</p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>{i18n.t('admin-page.postCode')}:</b> {user.postCode}</p>
                    </div>
                    <div style={{ marginTop: "10px", padding: "10px 16px", backgroundColor: "#FFFFFF", borderRadius: "8px", border: "1px solid #D1D3E2" }} >
                        <p><b>Ödeme Bilgileri</b></p>
                        <p style={{ whiteSpace: "break-spaces" }}>
                            <b>Aylık Ödeme:</b>  {user.payment_requested ? <Icon style={{ color: "#FCCE5A", fontSize: "156%", top: "4px", position: "relative" }} type="check-circle" /> : <Icon style={{ color: "#FCCE5A", transform: "scale(0.8)", top: "5px", position: "relative" }} component={EmptyCircleIcon} />}
                        </p>
                        <p style={{ whiteSpace: "break-spaces" }}><b>IBAN:</b>{user.iban}</p>
                    </div>
                </Col>
                <Col span={7} style={{ paddingTop: "10px", backgroundColor: "#FFFFFF", borderRadius: "8px", border: "1px solid #D1D3E2" }}>
                    <p><b>Eğitim</b></p>
                    {(JSONUserEducation != null && JSONUserEducation.highSchool.length != 0) ? <p style={{ marginBottom: "0px" }}><b>{i18n.t('admin-page.highSchool')}</b></p> : null}
                    {(JSONUserEducation != null && JSONUserEducation.highSchool.length != 0) ? this.renderEducationFiled(JSONUserEducation.highSchool) : null}
                    {(JSONUserEducation != null && JSONUserEducation.associate.length != 0) ? <p style={{ marginBottom: "0px" }}><b>{i18n.t('admin-page.associate')}</b></p> : null}
                    {(JSONUserEducation != null && JSONUserEducation.associate.length != 0) ? this.renderEducationFiled(JSONUserEducation.associate) : null}
                    {(JSONUserEducation != null && JSONUserEducation.bachelor.length != 0) ? <p style={{ marginBottom: "0px" }}><b>{i18n.t('admin-page.bachelor')}</b></p> : null}
                    {(JSONUserEducation != null && JSONUserEducation.bachelor.length != 0) ? this.renderEducationFiled(JSONUserEducation.bachelor) : null}
                    {(JSONUserEducation != null && JSONUserEducation.master.length != 0) ? <p style={{ marginBottom: "0px" }}><b>{i18n.t('admin-page.master')}</b></p> : null}
                    {(JSONUserEducation != null && JSONUserEducation.master.length != 0) ? this.renderEducationFiled(JSONUserEducation.master) : null}
                    {(JSONUserEducation != null && JSONUserEducation.phd.length != 0) ? <p style={{ marginBottom: "0px" }}><b>{i18n.t('admin-page.phd')}</b></p> : null}
                    {(JSONUserEducation != null && JSONUserEducation.phd.length != 0) ? this.renderEducationFiled(JSONUserEducation.phd) : null}
                </Col>
                <Col span={7} style={{ paddingTop: "10px", backgroundColor: "#FFFFFF", borderRadius: "8px", border: "1px solid #D1D3E2" }} >
                    <p><b>Tecrübeler</b></p>
                    <p style={{ whiteSpace: "break-spaces" }}><b>Temel Bilgisayar Kullanım Becerileri:</b> {user.computerExperience}</p>
                    <p style={{ whiteSpace: "break-spaces" }}><b>Sertifika bilgisi:</b> {user.certificates}</p>
                    <p style={{ whiteSpace: "break-spaces" }}><b>Programlama Dilleri:</b> {user.programmingLanguages}</p>
                    <p style={{ whiteSpace: "break-spaces" }}><b>Yapay Zeka ve Etiketleme Konularında Deneyim:</b> {user.aiAndAnnotationExperience}</p>
                    <p style={{ whiteSpace: "break-spaces" }}><b>Diğer deneyimler:</b> {user.otherExperience}</p>

                </Col>
            </Row>
        );
    }

    /**
     *
     * @param schoolList
     * @returns schoolViews template for rendering school information rows
     */
    private renderEducationFiled(schoolList: any): JSX.Element {
        let schoolViews = new Array();
        let schoolsTaken = schoolList
        for (let i = 0; i < schoolsTaken.length; i++) {
            schoolViews.push(
                <Row >
                    <p style={{ whiteSpace: "break-spaces" }}>
                        {i18n.t('admin-page.schoolName')}: {schoolsTaken[i].schoolName}
                        <span style={{ left: "25%", position: "relative" }}>{i18n.t('admin-page.department')}: {schoolsTaken[i].department}</span>
                    </p>
                </Row>
            )
        }
        return (
            <div>
                {schoolViews}
            </div>
        );
    }

    /**
     *
     * @param user
     * @returns user options dropdown template
     */
    public showUserSetOptionsTable(user: any) {

        let menuItems = new Array();
        console.log(user);
        let groups = user.id.groups;
        if (user.state == "rejected") {
            return <Button style={{ marginLeft: "5%", borderRadius: "4px" }} className="button-groups-page" onClick={() => this.handleUser(user.id.id, "forgive")}>Kullanıcıyı Affet</Button>
        }
        else if (groups.includes("candidate")) {
            return <Text>Aday Etiketleyicinin Rolü Değiştirilemez</Text>
        }
        else //not rejected user not candidate
        {
            this.state.roles.forEach(element => {
                menuItems.push(<Menu.Item style={{ display: "flex", justifyContent: "space-between" }} key={element.id}>{this.getRoleName(element.name)}<Checkbox className="admin-page-dropdown-checkbox" onChange={(e) => e.target.checked ? this.handleUserRole(user.id.id, "addRole", element.id, user.id.groups) : this.handleUserRole(user.id.id, "removeRole", element.id, user.id.groups)} checked={groups.includes(element.name)} /></Menu.Item>)
            });
            // menuItems.push(<Menu.Item style={{display:"flex",justifyContent:"space-between"}} key="annotator">Etiketleyici <Checkbox className="admin-page-dropdown-checkbox" onChange={(e)=>e.target.checked?this.handleUser(user.id.id,"makeAnnotator"):this.handleUser(user.id.id,"takeAnnotator")} checked={groups.includes("annotator")||groups.includes("candidate")}/></Menu.Item>)
            // menuItems.push(<Menu.Item style={{display:"flex",justifyContent:"space-between"}}key="DP">Veri Sağlayıcı <Checkbox className="admin-page-dropdown-checkbox" onChange={(e)=>e.target.checked?this.handleUser(user.id.id,"makeDP"):this.handleUser(user.id.id,"takeDP")} checked={groups.includes("provider")}/></Menu.Item>)
            // menuItems.push(<Menu.Item style={{display:"flex",justifyContent:"space-between"}}key="SSBDP">SSB Veri Sağlayıcı <Checkbox className="admin-page-dropdown-checkbox" onChange={(e)=>e.target.checked?this.handleUser(user.id.id,"makeSSBDP"):this.handleUser(user.id.id,"takeSSBDP")} checked={groups.includes("ssbprovider")} /></Menu.Item>)
            // menuItems.push(<Menu.Item style={{display:"flex",justifyContent:"space-between"}}key="SA">Sistem Yöneticisi <Checkbox className="admin-page-dropdown-checkbox" onChange={(e)=>e.target.checked?this.handleUser(user.id.id,"makeSA"):this.handleUser(user.id.id,"takeSA")} checked={groups.includes("system_admin")} /></Menu.Item>)
        }

        /*  if (user.id.groups.includes('system_admin'))
             optItems.push(<Option value="takeSA">Sistem Yöneticisi Kaldır</Option>);
         else
             optItems.push(<Option value="makeSA">Sistem Yöneticisi Yap</Option>);
 
 
         if (user.id.groups.includes('provider'))
             optItems.push(<Option value="takeDP">Veri Sağlayıcı Kaldır</Option>);
         else
             optItems.push(<Option value="makeDP">Veri Sağlayıcı Yap</Option>);
 
         if (user.id.groups.includes('ssbprovider'))
             optItems.push(<Option value="takeSSBDP">SSB Veri Sağlayıcı Kaldır</Option>);
         else
             optItems.push(<Option value="makeSSBDP">SSB Veri Sağlayıcı Yap</Option>);
 
         if (!(user.id.groups.includes('annotator') || (user.id.groups.includes('candidate'))))
             optItems.push(<Option value="makeAnnotator">Etiketleyici Yap <Checkbox></Checkbox></Option>);
         else
             optItems.push(<Option value="takeAnnotator">Etiketleyici Kaldır</Option>);
 
             if (user.state == "pending" && user.isBlocked == 0) {
                 optItems.push(<Option value="approve">{i18n.t('admin-page.approve')}</Option>);
                 optItems.push(<Option value="reject">{i18n.t('admin-page.reject')} </Option>);
             }
             else if (user.state == "rejected") {
                 optItems.push(<Option value="forgive">{i18n.t('admin-page.forgive')}</Option>);
             } */

        let dropdownMenu = (
            <Dropdown overlayStyle={{ overflowY: "auto", maxHeight: "156px" }} trigger={["click"]} overlay={<Menu multiple>{menuItems}</Menu>} >
                <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between", background: "#FFFFFF 0% 0% no-repeat padding-box", border: "1px solid #D1D3E2", borderRadius: "4px", padding: "4px" }}>
                    <Text style={{ color: "#5A5C69" }}>Kullanıcı Rolü</Text>
                    <Icon type="down" />
                </div>
            </Dropdown>
        )
        return dropdownMenu;
    }

    /**
     * @async
     * @param userId
     * @param action
     * @param role
     * @param groups
     * @description handle add and remove actions, update user list with current filters
     */
    private async handleUserRole(userId: any, action: any, role: any, groups: any) {
        let response = null;
        let request_sent = false;
        switch (action) {
            case ("addRole"): {
                response = await cvat.users.addRole(Number(userId), role);
                request_sent = true;
                break;
            }
            case ("removeRole"): {
                if (groups.length === 1) {
                    Modal.error({
                        content: "Kullanıcıda bulunan tek rolü kaldırabilmek için önce yeni rolünü seçiniz."
                    })
                }
                else {
                    response = await cvat.users.removeRole(Number(userId), role);
                    request_sent = true;
                }
                break;
            }
        }
        if (request_sent && response.data.status != i18n.t('response-messages.success')) {
            Modal.error({
                content: response.data.status
            })
        }
        else if (request_sent) {
            Modal.success({
                content: "Başarılı!" // action.toUpperCase() + " " + response.data.status
            })
        }
        const usersAsync = this.getUsers(this.state.searchFilter, this.state.userStatusFilter, this.state.currentPage, this.state.groupFilter);
        userTable = new Array();
    }

    /**
     * @async
     * @param userId
     * @param action
     * @description action api proxy calls for various user permission and role actions
     */
    private async handleUser(userId: any, action: any) {
        let response = null;
        switch (action) {
            case ("approve"): {
                response = await cvat.users.approve(Number(userId));
                break;
            }
            case ("reject"): {
                response = await cvat.users.reject(Number(userId));
                break;
            }
            case ("forgive"): {
                response = await cvat.users.forgive(Number(userId));
                break;
            }
            case ("demote"): {
                response = await cvat.users.demote(Number(userId));
                break;
            }
            case ("promote"): {
                response = await cvat.users.promote(Number(userId));
                break;
            }
            case ("bless"): {
                response = await cvat.users.bless(Number(userId));
                break;
            }
            case ("curse"): {
                response = await cvat.users.curse(Number(userId));
                break;
            }
            case ("block"): {
                response = await cvat.users.block(Number(userId));
                break;
            }
            case ("unblock"): {
                response = await cvat.users.unblock(Number(userId));
                break;
            }
            case ("give"): {
                response = await cvat.users.give(Number(userId));
                break;
            }
            case ("take"): {
                response = await cvat.users.take(Number(userId));
                break;
            }
            case ("makeDP"): {
                response = await cvat.users.makeDP(Number(userId));
                break;
            }
            case ("makeSA"): {
                response = await cvat.users.makeSA(Number(userId));
                break;
            }
            case ("takeSA"): {
                response = await cvat.users.takeSA(Number(userId));
                break;
            }
            case ("takeDP"): {
                response = await cvat.users.takeDP(Number(userId));
                break;
            }
            case ("makeSSBDP"): {
                response = await cvat.users.makeSSBDP(Number(userId));
                break;
            }
            case ("takeSSBDP"): {
                response = await cvat.users.takeSSBDP(Number(userId));
                break;
            }
            case ("makeAnnotator"): {
                response = await cvat.users.makeAnnotator(Number(userId));
                break;
            }
            case ("takeAnnotator"): {
                response = await cvat.users.takeAnnotator(Number(userId));
                break;
            }
            case ("addRole"): {
                response = await cvat.users.addRole(Number(userId), role);
                break;
            }
            case ("removeRole"): {
                response = await cvat.users.removeRole(Number(userId), role);
                break;
            }
        }
        if (response.data.status != i18n.t('response-messages.success')) {
            Modal.error({
                content: response.data.status
            })
        }
        else {
            Modal.success({
                content: "Başarılı!" // action.toUpperCase() + " " + response.data.status
            })
        }

        const usersAsync = this.getUsers(this.state.searchFilter, this.state.userStatusFilter, this.state.currentPage, this.state.groupFilter);
        userTable = new Array();
    }

    /**
     * @async
     * @param searchFilter
     * @param userStatusFilter
     * @param pageNum
     * @param groupFilter
     * @returns get user api proxy calls with given filters, filters out super users and return users, refresh user template
     */
    async getUsers(searchFilter: any, userStatusFilter: any, pageNum: any, groupFilter?: any) {
        let users;

        if (userStatusFilter == "all")
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, null, null, null, searchFilter, groupFilter);
        else if (userStatusFilter == "blocked")
            users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, "True", null, null, searchFilter, groupFilter);
        else
            users = await cvat.users.getAnnotaUsers(null, pageNum, userStatusFilter, null, null, null, null, null, searchFilter, groupFilter);

        // if (filter == "all")
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null,null,null, null, null, groupFilter);
        // else if (filter == "approval")
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, 'pending', null, null, "False",null, null, null, groupFilter);
        // else if (filter == "blocked")
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, "True",null, null, null, groupFilter);
        // else if (filter == "rejected")
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, 'rejected', null, null,null,null, null, null, groupFilter);
        // else if (filter == "approved")
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, 'approved', null, null, "False",null, null, null, groupFilter);
        // else if (typeof filter == "object" && ("search" in filter))
        //     users = await cvat.users.getAnnotaUsers(null, pageNum, null, null, null, null, null, null, filter, groupFilter);

        // let usersArr = this.filterOutSuperUser(users.users);
        let usersArr = users.users;
        this.setState({
            totalRelatedUserCount: users.count,
        });

        this.handleUsers(usersArr)
        return users;
    }

    /**
     *
     * @param users
     * @returns users array without super users
     */
    private filterOutSuperUser(users: any) {
        let usersArr = new Array;
        for (let i = 0; i < users.length; i++) {
            if (!users[i].id.is_superuser)
                usersArr.push(users[i]);
        }
        return usersArr;
    }

    /**
     *
     * @param value
     * @description sets state on groupFilters with param, getUsers with updated state filters, clears userTable
     */
    setGroupFilter(value: any) {
        this.setState({ groupFilter: value })
        const usersAsync = this.getUsers(this.state.searchFilter, this.state.userStatusFilter, 1, value);
        userTable = new Array();
    }

    /**
     *
     * @param name
     * @returns Turkish name
     */
    private getRoleName(name: String) {
        switch (name) {
            case 'annotator':
                return 'Etiketleyici';
            case 'system_admin':
                return 'Sistem Yöneticisi';
            case 'provider':
                return 'Veri Sağlayıcı';
            case 'ssbprovider':
                return 'SSB Veri Sağlayıcı';
            case 'admin':
                return 'Platform Yöneticisi';
            case 'candidate':
                return 'Aday Etiketleyici';
            case 'special_annotator':
                return 'Özel Etiketleyici';
            case null:
                return '';
            default:
                return name
        }
    }

    /**
     *
     * @returns user list JSX template
     */
    private renderUserList() {
        UserList = <div className="datasets-page">{userTable}</div>;
        let rolesList = new Array()
        this.state.roles.forEach(element => {
            rolesList.push(<Option value={element.name}>{this.getRoleName(element.name)}</Option>)
        });
        rolesList.push(<Option value="expert">{i18n.t('admin-page.expertAnnotator')}</Option>)
        return (
            <div className='approval-page'>
                <Text className="cvat-title">{i18n.t('admin-page.users')}</Text>
                {this.renderModal()}
                <div style={{ paddingTop: "15px" }}>
                    <Text>Filtrele: </Text>
                    <Select style={{ width: '10%', margin: "5px 3px" }} placeholder="Kullanıcı Durumu" onChange={(value) => this.setUserStatusFilter(value)}>
                        <Option value="all">{i18n.t('admin-page.all')}</Option>
                        <Option value="pending">{i18n.t('admin-page.approval')}</Option>
                        <Option value="blocked">{i18n.t('admin-page.blocked')}</Option>
                        <Option value="rejected">{i18n.t('admin-page.rejected')}</Option>
                        <Option value="approved">{i18n.t('admin-page.approved')}</Option>
                    </Select>
                    <Select mode="multiple"
                        placeholder={"Kullanıcı Rolü"}
                        style={{ minWidth: '10%', margin: "5px 3px" }}
                        allowClear={true}
                        onChange={(value) => {
                            console.log(value)
                            this.setGroupFilter(value)
                        }
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        }
                    >
                        {rolesList}
                    </Select>
                    <Col style={{ float: "right" }} span={4}>
                        <Input.Search
                            onSearch={this.setSearchFilter.bind(this)}
                            placeholder={"Kullanıcılarda " + i18n.t('keywords.search')}
                        />
                    </Col>
                </div>
                {UserList}
                <Row type='flex' justify='center' align='middle' style={{ marginTop: '1%', marginBottom: '1%' }}>
                    <Col>
                        <Pagination
                            defaultCurrent={1}
                            pageSize={10}
                            onChange={this.handleChange}
                            current={this.state.currentPage}
                            total={this.state.totalRelatedUserCount}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    /**
     *
     * @param takenFilter
     * @description set user status filter with param and get users with changed status filters, clears user table
     */
    public async setUserStatusFilter(takenFilter: any) {

        this.setState({
            userStatusFilter: takenFilter,
            currentPage: 1
        });
        const usersAsync = this.getUsers(this.state.searchFilter, takenFilter, 1, this.state.groupFilter);
        userTable = new Array();
    }

    /**
     *
     * @param searchString
     * @description Set search filter with param and get users with changed status filters, clears user table
     */
    public async setSearchFilter(searchString: any) {
        this.setState({
            searchFilter: { "search": searchString },
            currentPage: 1
        });
        this.getUsers({ "search": searchString }, this.state.userStatusFilter, 1, this.state.groupFilter);
        userTable = new Array();
    }

    /**
     *
     * @param value
     * @description Get users with new page settings, update state, clears user table
     */
    handleChange = value => {
        userTable = new Array()
        const usersAsync = this.getUsers(this.state.searchFilter, this.state.userStatusFilter, value, this.state.groupFilter);
        this.setState({
            currentPage: value,
        });
    };

    /**
     *
     * @param value
     * @description sets states on clicked option with value
     */
    handleOptChange = value => {
        this.setState({ clickedOption: value });
    };

    private getStatus(state: String) {
        switch (state) {
            case 'initial':
                return 'Aday Etiketleyici';
            case 'pending':
                return 'Onay Bekliyor';
            case 'approved':
                return 'Onaylı';
            case null:
                return '-';
            case 'freshman':
                return '1';
            case 'sophomore':
                return '2';
            case 'junior':
                return '3';
            case 'senior':
                return '4';
            case 'master':
                return '5';
            case 'qualified':
                return 'Nitelikli';
            case 'expert':
                return 'Uzman';
        }
    }

    /**
     *
     * @returns user list template using class functions
     */
    public render() {
        return this.renderUserList();
    }
}

export default withRouter(connect(
)(AdminPage));
