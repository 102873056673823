// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';

import { MoveIcon } from 'icons';
import { ActiveControl } from 'reducers/interfaces';
import { Canvas } from 'cvat-canvas-wrapper';
import { useTranslation } from 'react-i18next';

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
}

/**
 * Create component to move the image
 *
 * @param props consists of canvasInstance and activeControl variables
 * @returns Icon within Tooltip
 */
function MoveControl(props: Props): JSX.Element {
    const { canvasInstance, activeControl } = props;
    const { t } = useTranslation();

    return (
        <Tooltip title={`${t('standard-workspace.moveTheImage')}`} placement='right'>
            <Icon
                component={MoveIcon}
                className={activeControl === ActiveControl.DRAG_CANVAS
                    ? 'cvat-active-canvas-control' : ''}
                onClick={(): void => {
                    if (activeControl === ActiveControl.DRAG_CANVAS) {
                        canvasInstance.dragCanvas(false);
                    } else {
                        canvasInstance.cancel();
                        canvasInstance.dragCanvas(true);
                    }
                }}
            />
        </Tooltip>
    );
}

export default React.memo(MoveControl);
