// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

type Props = {
    installedAutoAnnotation: boolean;
} & RouteComponentProps;

/**
 *
 * @param props
 * @returns TopBarComponent for Modals Page
 */
function TopBarComponent(props: Props): JSX.Element {
    const {
        installedAutoAnnotation,
        history,
    } = props;
    const { t } = useTranslation();

    /**
     * @description renders top bar functionality
     */
    return (
        <Row type='flex' justify='center' align='middle'>
            <Col md={11} lg={9} xl={8} xxl={7}>
                <Text className='cvat-title'>{t('keywords.models')}</Text>
            </Col>
            <Col
                md={{ span: 11 }}
                lg={{ span: 9 }}
                xl={{ span: 8 }}
                xxl={{ span: 7 }}
            >
                { installedAutoAnnotation
                    && (
                        <Button
                            size='large'
                            id='cvat-create-model-button'
                            type='primary'
                            onClick={
                                (): void => history.push('/models/create')
                            }
                        >
                            {t('models-page.createNewModel')}
                        </Button>
                    )}
            </Col>
        </Row>
    );
}

export default withRouter(TopBarComponent);
