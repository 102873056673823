// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { ModelFiles } from 'reducers/interfaces';
import CreateModelContent from './create-model-content';

interface Props {
    createModel(name: string, files: ModelFiles, global: boolean): void;
    isAdmin: boolean;
    modelCreatingStatus: string;
}

/**
 * Render adding model page component
 *
 * @param props consists of isAdmin, modelCreatinStatus variables and createModel function
 * @returns Row
 */
export default function CreateModelPageComponent(props: Props): JSX.Element {
    const {
        isAdmin,
        modelCreatingStatus,
        createModel,
    } = props;
    const { t } = useTranslation();

    return (
        <Row type='flex' justify='center' align='top' className='cvat-create-model-form-wrapper'>
            <Col md={20} lg={16} xl={14} xxl={9}>
                <Text className='cvat-title'>{t('create-model.uploadModel')}</Text>
                <CreateModelContent
                    isAdmin={isAdmin}
                    modelCreatingStatus={modelCreatingStatus}
                    createModel={createModel}
                />
            </Col>
        </Row>
    );
}
