import React,{ useState, useEffect, useRef } from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form,
    Modal,
    Select
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import getCore from 'cvat-core-wrapper';
import patterns from 'utils/validation-patterns';
import "./styles.scss";

const core = getCore();
interface Props {
    visible:boolean;
    setInvisible:()=>void;
    updateFaq:()=>void;
}
/**
 * @class Frequently Asked Questions Form Component class
 */
class NewFaqComponent extends React.PureComponent<Props&FormComponentProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            charCountQuestion: 0,
            charCountAnswer: 0,
            question: "",
            answer: "",
            number: "",
        };
    }

    /**
     *
     * @param rule
     * @param value
     * @param callback
     * @description validates frequently asked questions form question number
     */
    private validateNewFaqNumber = (rule: any, value: any, callback: any): void => {
        if (!patterns.validateNewFaqNumber.pattern.test(value)) {
            callback(patterns.validateNewFaqNumber.message);
        }
        callback();
    };

    evalCharCountQuestion = e => {
        this.setState({
            charCountQuestion: e.target.value.length,
            question: e.target.value,
        });
    };

    evalCharCountAnswer = e => {
        this.setState({
            charCountAnswer: e.target.value.length,
            answer: e.target.value,
        });
    };

    numberInput = e => {
        this.setState({
            number: e.target.value,
        });
    };


    /**
     *
     * @returns TextArea to enter question number
     */
    private renderQNumberField(){

        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('number', {
                        rules: [{
                            required: true,
                            message: 'Lütfen soru numarasını girin',
                        },{
                            validator: this.validateNewFaqNumber
                        }],
                    })(
                        <TextArea
                            id="questionNumber"
                            rows={1}
                            placeholder='Soru Numarası'
                            style={{ resize: 'vertical'}}
                            onChange = {this.numberInput}
                        />
                    )}
                </Form.Item>
            </div>
        );
    }

    /**
     *
     * @returns TextArea to enter question body
     */
    private renderQuestionField(){
        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('question', {
                        rules: [{
                            required: true,
                            message: 'Lütfen soruyu girin',
                        }],
                    })(
                        <TextArea
                            id="questionBody"
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            placeholder='Sorunuz'
                            onChange = {this.evalCharCountQuestion}
                        />
                    )}
                </Form.Item>
                <p  style={{float:'right'}}>Karakter Sayısı: {this.state.charCountQuestion}/4000</p>
            </div>
        );
    }

    /**
     *
     * @returns TextArea to enter answer body
     */
    private renderAnswerField(){
        const { form } = this.props;
        return (
            <div>
                <Form.Item >
                    {form.getFieldDecorator('answer', {
                        rules: [{
                            required: true,
                            message: 'Lütfen cevabı girin',
                        }],
                    })(
                        <TextArea
                            id="answerBody"
                            rows={7}
                            style={{overflowY: 'scroll', resize: 'vertical'}}
                            maxLength={4000}
                            placeholder='Cevap'
                            onChange = {this.evalCharCountAnswer}
                        />
                    )}
                </Form.Item>
                <p  style={{float:'right'}}>Karakter Sayısı: {this.state.charCountAnswer}/4000</p>
            </div>
        );
    }

    /**
     *  @async
     *  @description Sends createFAQ request and handles response
     */
    private async handleSaveFaq(){
        const {form} = this.props;
        let resp = await core.management.createFaq(0, form.getFieldValue("question"), form.getFieldValue("answer")) // New FAQ saved with number 0
        let response = resp.status;
        console.log(resp)
        if(response != "Başarılı"){
            Modal.error({
                title: 'Hata!',
              content: response,
            });
        }
        else{
             Modal.success({
                okButtonProps:{id:"ok"},
              title: 'Başarılı!',
              content: resp.message,
            });
            this.props.setInvisible();
            this.props.form.setFieldsValue({number:"", question:"", answer:""});
            this.props.updateFaq();
        }
    }

    /**
     *
     * @param e submit event
     * @description logs to console and calls handleSaveFAQ function to send save request
     */
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (err) {
            console.log('Received values of form: ', values);
          }
          else {
            console.log('Received values of form: ', values);

            this.handleSaveFaq();
          }
        });
    };

    /**
     *
     * @returns Form Component
     */
    public render() {
        return (
        <div >
            <Form>
            <Modal
                maskClosable={false}
                title="Yeni Soru-Cevap Oluştur"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                okButtonProps={{id:"send",htmlType:'submit' }}
                okText="Kaydet"
                className="new-faq"
                onCancel={()=>{
                    this.props.setInvisible();
                    this.props.form.setFieldsValue({number : "",question:"", answer:""});
                }}
                destroyOnClose={true}
            >
                {/* {this.renderQNumberField()} */}
                {this.renderQuestionField()}
                {this.renderAnswerField()}
            </Modal>
            </Form>
        </div>
        );
    }
}

export default Form.create<FormComponentProps&Props>()(NewFaqComponent);
