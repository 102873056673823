// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Tooltip from 'antd/lib/tooltip';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import i18n from '../../i18n'

import patterns from 'utils/validation-patterns';

export interface AdvancedConfiguration {
    bugTracker?: string;
    zOrder: boolean;
    imageQuality?: number;
    overlapSize?: number;
    segmentSize?: number;
    startFrame?: number;
    stopFrame?: number;
    frameFilter?: string;
    lfs: boolean;
    repository?: string;
    useZipChunks: boolean;
    dataChunkSize?: number;
}

type Props = FormComponentProps & {
    onSubmit(values: AdvancedConfiguration): void;
    installedGit: boolean;
};

/**
 * Check value is positive or not
 *
 * @param _
 * @param value number
 * @param callback
 * @returns
 */
function isPositiveInteger(_: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }

    const intValue = +value;
    if (Number.isNaN(intValue)
        || !Number.isInteger(intValue) || intValue < 1) {
        callback(i18n.t('session.valueMustBePositiveInteger'));
    }

    callback();
}

/**
 * Check value is non negative integer
 *
 * @param _
 * @param value number
 * @param callback
 * @returns
 */
function isNonNegativeInteger(_: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }

    const intValue = +value;
    if (Number.isNaN(intValue) || intValue < 0) {
        callback(i18n.t('session.valueMustBeNonNegativeInteger'));
    }

    callback();
}

/**
 * Check value is in proper range
 * @param min number
 * @param max number
 * @param _
 * @param value number
 * @param callback
 * @returns
 */
function isIntegerRange(min: number, max: number, _: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }

    const intValue = +value;
    if (Number.isNaN(intValue)
        || !Number.isInteger(intValue)
        || intValue < min || intValue > max
    ) {
        callback(i18n.t('create-task.valueMustBeInRange', {min: min, max: max}));
    }

    callback();
}

class AdvancedConfigurationForm extends React.PureComponent<Props> {

    /**
     * Handle submiting form
     *
     * @returns Promise
     */
    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                form,
                onSubmit,
            } = this.props;

            form.validateFields((error, values): void => {
                if (!error) {
                    const filteredValues = { ...values };
                    delete filteredValues.frameStep;

                    if (values.overlapSize && +values.segmentSize <= +values.overlapSize) {
                        reject(new Error(i18n.t('create-task.segmentGreaterThanOverlap')));
                    }

                    if (typeof (values.startFrame) !== 'undefined' && typeof (values.stopFrame) !== 'undefined'
                        && +values.stopFrame < +values.startFrame
                    ) {
                        reject(new Error(i18n.t('messages.startFrameCannotBeLarger')));
                    }

                    onSubmit({
                        ...values,
                        frameFilter: values.frameStep ? `step=${values.frameStep}` : undefined,
                    });
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Reset form fields
     */
    public resetFields(): void {
        const { form } = this.props;
        form.resetFields();
    }

    /**
     * Render z order
     *
     * @returns Form Item
     */
    private renderZOrder(): JSX.Element {
        const { form } = this.props;
        return (
            <Form.Item help={i18n.t('create-task.zOrderTooltip')}>
                {form.getFieldDecorator('zOrder', {
                    initialValue: false,
                    valuePropName: 'checked',
                })(
                    <Checkbox>
                        <Text className='cvat-text-color'>
                            {i18n.t('create-task.zOrder')}
                        </Text>
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    /**
     * Render image quality
     *
     * @returns Form Item
     */
    private renderImageQuality(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('create-task.imageQuality')}</span>}>
                <Tooltip title={i18n.t('create-task.imageQualityTooltip')}>
                    {form.getFieldDecorator('imageQuality', {
                        initialValue: 70,
                        rules: [{
                            required: true,
                            message: i18n.t('messages.required'),
                        }, {
                            validator: isIntegerRange.bind(null, 5, 100),
                        }],
                    })(
                        <Input
                            className='input-create-task'
                            size='large'
                            suffix={<Icon type='percentage' />}
                        />,
                    )}
                </Tooltip>
            </Form.Item>
        );
    }

    /**
     * Render overlap
     *
     * @returns Form Item
     */
    private renderOverlap(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('create-task.overlapSize')}</span>}>
                <Tooltip title={i18n.t('create-task.overlapTooltip')}>
                    {form.getFieldDecorator('overlapSize', {
                        rules: [{
                            validator: isNonNegativeInteger,
                        }],
                    })(
                        <Input className="input-create-task" size='large' />,
                    )}
                </Tooltip>
            </Form.Item>
        );
    }

    /**
     * Render segment size
     *
     * @returns Form Item
     */
    private renderSegmentSize(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('create-task.segmentSize')}</span>}>
                <Tooltip title={i18n.t('create-task.segmentTooltip')}>
                    {form.getFieldDecorator('segmentSize', {
                        rules: [{
                            validator: isPositiveInteger,
                        }],
                    })(
                        <Input className="input-create-task" size='large' />,
                    )}
                </Tooltip>
            </Form.Item>
        );
    }

    /**
     * Render start frame
     *
     * @returns Form Item
     */
    private renderStartFrame(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('keywords.startFrame')}</span>}>
                {form.getFieldDecorator('startFrame', {
                    rules: [{
                        validator: isNonNegativeInteger,
                    }],
                })(
                    <Input
                        className = "input-create-task"
                        size='large'
                        type='number'
                        min={0}
                        step={1}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render stop frame
     *
     * @returns Form Item
     */
    private renderStopFrame(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('keywords.stopFrame')}</span>}>
                {form.getFieldDecorator('stopFrame', {
                    rules: [{
                        validator: isNonNegativeInteger,
                    }],
                })(
                    <Input
                        className = "input-create-task"
                        size='large'
                        type='number'
                        min={0}
                        step={1}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render frame step
     *
     * @returns Form Item
     */
    private renderFrameStep(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('create-task.stepSize')}</span>}>
                {form.getFieldDecorator('frameStep', {
                    initialValue: 1,
                    rules: [{
                        validator: isPositiveInteger,
                    }],
                })(
                    <Input
                        className = "input-create-task"
                        size='large'
                        type='number'
                        min={1}
                        step={1}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render gitLSFBox
     *
     * @returns Form Item
     */
    private renderGitLFSBox(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item help={i18n.t('create-task.lfsTooltip')}>
                {form.getFieldDecorator('lfs', {
                    valuePropName: 'checked',
                    initialValue: false,
                })(
                    <Checkbox>
                        <Text className='cvat-text-color'>
                            {i18n.t('create-task.useLFS')}
                        </Text>
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    /**
     * Render gitRepositoryURL
     *
     * @returns Form Item
     */
    private renderGitRepositoryURL(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item
                hasFeedback
                label={<span className='cvat-text-color'>{i18n.t('create-task.annotationRepoURL')}</span>}
                extra={i18n.t('create-task.repoTooltip')}
            >
                {form.getFieldDecorator('repository', {
                    rules: [{
                        validator: (_, value, callback): void => {
                            if (!value) {
                                callback();
                            } else {
                                const [url, path] = value.split(/\s+/);
                                if (!patterns.validateURL.pattern.test(url)) {
                                    callback(i18n.t('create-task.gitURLNotValid'));
                                }

                                if (path && !patterns.validatePath.pattern.test(path)) {
                                    callback(i18n.t('create-task.gitPathNotValid'));
                                }

                                callback();
                            }
                        },
                    }],
                })(
                    <Input
                        className = "input-create-task"
                        size='large'
                        placeholder={i18n.t('create-task.repoURLExample')}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render gitRepositoryURL
     *
     * @returns Form Item
     */
    private renderGit(): JSX.Element {
        return (
            <>
                <Row>
                    <Col>
                        {this.renderGitRepositoryURL()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderGitLFSBox()}
                    </Col>
                </Row>
            </>
        );
    }

    /**
     * Render bugTracker
     *
     * @returns Form Item
     */
    private renderBugTracker(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item
                hasFeedback
                label={<span className='cvat-text-color'>{i18n.t('keywords.issueTracker')}</span>}
                extra={i18n.t('create-task.issueTrackerTooltip')}
            >
                {form.getFieldDecorator('bugTracker', {
                    rules: [{
                        validator: (_, value, callback): void => {
                            if (value && !patterns.validateURL.pattern.test(value)) {
                                callback(i18n.t('task-page.issueTrackerError'));
                            } else {
                                callback();
                            }
                        },
                    }],
                })(
                    <Input className = "input-create-task"
                    size='large' />,
                )}
            </Form.Item>
        );
    }

    /**
     * Render checkbox for using zip chunks
     *
     * @returns Checkbox within Form Item
     */
    private renderUzeZipChunks(): JSX.Element {
        const { form } = this.props;
        return (
            <Form.Item help={i18n.t('create-task.zipChunksTooltip')}>
                {form.getFieldDecorator('useZipChunks', {
                    initialValue: true,
                    valuePropName: 'checked',
                })(
                    <Checkbox>
                        <Text className='cvat-text-color'>
                            {i18n.t('create-task.useZipChunks')}
                        </Text>
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    /**
     * Render input field to specify chunk size
     * 
     * @returns
     */
    private renderChunkSize(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item label={<span className='cvat-text-color'>{i18n.t('create-task.chunkSize')}</span>}>
                <Tooltip
                    title={(
                        <text style={{whiteSpace: 'pre-wrap'}}>{i18n.t('create-task.chunkSizeRecommendation')}</text>
                    )}
                >
                    {form.getFieldDecorator('dataChunkSize', {
                        rules: [{
                            validator: isPositiveInteger,
                        }],
                    })(
                        <Input className = "input-create-task"
                        size='large' />,
                    )}
                </Tooltip>
            </Form.Item>
        );
    }

    /**
     * Render configuration form
     *
     * @returns Form
     */
    public render(): JSX.Element {
        const { installedGit } = this.props;

        return (
            <Form style={{background:"none", boxShadow:"none", border:"none"}} className='cvat-create-task-content'>
                {/* <Row>
                    <Col>
                        {this.renderZOrder()}
                    </Col>
                </Row> */}

                <Row>
                    <Col hidden={true}>
                        {this.renderUzeZipChunks()}
                    </Col>
                </Row>

                <Row type='flex' justify='start'>
                    <Col span={7}>
                        {this.renderImageQuality()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderOverlap()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderSegmentSize()}
                    </Col>
                </Row>

                {/* <Row type='flex' justify='start'>
                    <Col span={7} >
                        {this.renderStartFrame()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderStopFrame()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderFrameStep()}
                    </Col>
                </Row> */}

                <Row type='flex' justify='start'>
                    {/* <Col span={7}>
                        {this.renderChunkSize()}
                    </Col> */}
                    <Col span={7}>
                    {this.renderFrameStep()}
                    </Col>
                </Row>

                {/* { installedGit ? this.renderGit() : null}

                <Row>
                    <Col>
                        {this.renderBugTracker()}
                    </Col>
                </Row> */}
            </Form>
        );
    }
}

export default Form.create<Props>()(AdvancedConfigurationForm);
