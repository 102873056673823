/**
 * @description selector and content for tutorial tour
 */
const profileSteps = [
    {
        selector: '[data-tour="step-user-first-info"]',
        content: "Kullanıcı adınız, avatarınız ve rolünüz burada gösterilir. "
    },
    {
        selector: '[data-tour="step-change-avatar"]',
        content: "Avatar değiştirmek için buraya tıklayıp açılan pencereden istediğiniz avatarı seçebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-change-photo"]',
        content: "Fotoğrafınızı değiştirmek için buraya tıklayıp açılan pencereden yeni fotoğraf dosyası yükleyebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-change-gen-info"]',
        content: "Bu bölümde listelenen bilgileri değiştirmek için buraya tıklayabilirsiniz. Bu bölümdeki bilgileri değiştirebilmek için açılan pencerede TC Kimlik Seri No doğrulaması yapmanız gerekmektedir. "
    },
    {
        selector: '[data-tour="step-change-password"]',
        content: "Şifrenizi değiştirmek için buraya tıklayınız. "
    },
    {
        selector: '[data-tour="step-payment-info"]',
        content: "Size ödeme yaparken kullanılacak olan bilgileriniz burada görüntülenir. "
    },
    {
        selector: '[data-tour="step-edit-payment-info"]',
        content: "Ödeme bilgileriniz güncelleştirmek, ödeme tercihinizi değiştirmek için buraya basabilirsiniz. "
    },
    {
        selector: '[data-tour="step-address-info"]',
        content: "Adres bilgileriniz burada görüntülenir. "
    },
    {
        selector: '[data-tour="step-edit-address-info"]',
        content: "Adres bilgilerinizi güncellemek için buraya basabilirsiniz. "
    },
    {
        selector: '[data-tour="step-exp-info"]',
        content: "Tecrübelerinizi burada görüntüleyebilirsiniz. "
    },
    {
        selector: '[data-tour="step-edit-exp-info"]',
        content: "Tecrübelerinizi güncelleştirmek için buraya basarabilirsiniz. "
    },
  ];

  export default profileSteps;
