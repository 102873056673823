// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { connect } from 'react-redux';
import ResetConfirmPageComponent from 'components/reset-confirm-page/reset-confirm-page';
import { CombinedState } from 'reducers/interfaces';
import { resetPasswordConfirmAsync } from 'actions/auth-actions';

interface DispatchToProps {
    onReset: typeof resetPasswordConfirmAsync;
}

function mapStateToProps(state: CombinedState){
    return {};
}

/**
 *
 * @param dispatch
 * @returns onReset action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
const mapDispatchToProps: DispatchToProps = {
    onReset: resetPasswordConfirmAsync,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetConfirmPageComponent);
