import React from 'react';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const YzpLogin = () => {


    useEffect(() => {
        const config = require('cvat-core/src/config');
        const serverProxy = require('cvat-core/src/server-proxy');
        try {
            let doccanoToken = Cookies.get('doccanoToken');
            let username = Cookies.get('username');
            serverProxy.yzp.setAuthorizationHeader(doccanoToken);
            window.location.replace(`${config.doccanoUIRedirectURL}/authenticator?username=${encodeURI(username)}&token=${encodeURI(doccanoToken)}&action=login`)
        }
        catch (error) {
            console.log(error);
        }


    }, []);

    return (
        <>
        </>
    )
};

export default YzpLogin;