// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
    activatedStateID: number | null;
    visible: boolean;
    left: number;
    top: number;
    onPointDelete(): void;
}

/**
 * Create component to delete
 *
 * @param props consists of activatedStateID, visible, left, top variables and onPointDelete functions
 * @returns
 */
export default function CanvasPointContextMenu(props: Props): JSX.Element | null {
    const {
        onPointDelete,
        activatedStateID,
        visible,
        left,
        top,
    } = props;

    const { t } = useTranslation();
    if (!visible || activatedStateID === null) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className='cvat-canvas-point-context-menu' style={{ top, left }}>
            <Tooltip title={`${t('standard-workspace.deletePointDblClck')}`}>
                <Button type='link' icon='delete' onClick={onPointDelete}>
                    {t('standard-workspace.deletePoint')}
                </Button>
            </Tooltip>
        </div>,
        window.document.body,
    );
}
