import '../styles.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { Collapse, Progress, Input, Divider, Table, Descriptions, Switch, List, Modal, Button, ConfigProvider, InputNumber, Icon, Row, Col, Card, Pagination, Popconfirm, Spin, Alert } from 'antd';
import tr_TR from 'antd/lib/locale/tr_TR';
import Text from 'antd/lib/typography/Text';
import SystemResourcesComponent from '../system-resources-page'
import i18n from '../../i18n';
import { AuthState } from '../../reducers/interfaces';
import { authorizedAsync } from '../../actions/auth-actions';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import getCore from '../../cvat-core-wrapper';
import NewFaqComponent from './new-faq-component';
import UpdateFaqComponent from './update-faq-component';
import { any } from 'prop-types';
import Checkbox, { CheckboxProps } from 'antd/lib/checkbox';
import Column from 'antd/lib/table/Column';
import {DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Panel } = Collapse;
const { TextArea } = Input;

interface StateToProps {
    auth: AuthState;
}

interface DispatchToProps {
    verifyAuthorized: () => void;
}

/**
 *
 * @param state -> state containing auth information
 * @returns auth information
 */
function mapStateToProps(state: any): object {
    return {
        auth: state.auth,
    };
}

/**
 *
 * @param dispatch
 * @returns verifyAuthorized action dispatching functions
 * @description allows specifying which actions might need to be dispatched. Provides action dispatching functions as props.
 */
function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        verifyAuthorized: (): void => dispatch(authorizedAsync()),
    }
}

type PlatformManProps = StateToProps & DispatchToProps & RouteComponentProps;
const cvat = getCore();

/**
 * @class PlatformManComponent class component
 */
class PlatformManComponent extends React.PureComponent<PlatformManProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            initLoading: false,
            faqLoading: false,
            registrationLoader: false,
            registrationStatus: false,
            testThresHold: 50,
            faqs: [],
            newFaqModalVisible: false,
            editFaqModalVisible: false,
            item: "",
            jobThresHold: 30,
            annotationPrice: 0.04,
            paymenMinLimit: 50,
            paymenMaxLimit: 3000,
            levelScoreIntervals: new Array(),
            updatedLevelScoreIntervals: new Array(),
            updateLevelScoreIntervalsStatus: "Seviyeler güncelleniyor...",
            updateLevelScoreIntervalsStatusVisible: false,
            annotationJobCompleteTime: 7,
            evaluationJobCompleteTime: 7,
            sessionTimeoutDuration: 180,
            paymentConstant: 1,
            autoSaveDuration: 20,
        }
        this.getFaq = this.getFaq.bind(this);
        this.setRegistration = this.setRegistration.bind(this);
        this.setTestThreshold = this.setTestThreshold.bind(this)
        this.onChangeTest = this.onChangeTest.bind(this)
        this.setJobThreshold = this.setJobThreshold.bind(this)
        this.onChangeJobThreshold = this.onChangeJobThreshold.bind(this)
        this.setAnnotationPrice = this.setAnnotationPrice.bind(this)
        this.onChangeAnnotationPrice = this.onChangeAnnotationPrice.bind(this)
        this.setPaymentLimit = this.setPaymentLimit.bind(this)
        this.onChangePaymentMinLimit = this.onChangePaymentMinLimit.bind(this)
        this.onChangePaymentMaxLimit = this.onChangePaymentMaxLimit.bind(this)
        this.getLevelScoreIntervals = this.getLevelScoreIntervals.bind(this)
        this.updateLevelScoreIntervals = this.updateLevelScoreIntervals.bind(this)
        this.wait = this.wait.bind(this)
        this.modalUpdateLevelScoreIntervalsOnOk = this.modalUpdateLevelScoreIntervalsOnOk.bind(this)
        this.onChangeAnnotationJobTime = this.onChangeAnnotationJobTime.bind(this)
        this.onChangeEvaluationJobTime = this.onChangeEvaluationJobTime.bind(this)
        this.setJobCompleteTimes = this.setJobCompleteTimes.bind(this)
        this.setSessionTimeoutDuration = this.setSessionTimeoutDuration.bind(this)
        this.onChangeSessionTimeoutDuration = this.onChangeSessionTimeoutDuration.bind(this)
        this.setPaymentConstant = this.setPaymentConstant.bind(this)
        this.onChangePaymentConstant = this.onChangePaymentConstant.bind(this)
        this.setAutoSaveDuration = this.setAutoSaveDuration.bind(this)
        this.onChangeAutoSaveDuration = this.onChangeAutoSaveDuration.bind(this)
    }

    public componentDidMount(): void {
        this.setState({ initLoading: true })
        this.getFaq()
        this.getReg()
        this.getTestThreshold()
        this.getJobThreshold()
        this.getAnnotationPrice()
        this.getPaymentLimitStatus()
        this.getLevelScoreIntervals()
        this.getJobCompleteTimesStatus()
        this.getSessionTimeoutDuration()
        this.getPaymentConstant()
        this.getAutoSaveDuration()
    }

    /**
     * @description calls for registration status, sets state
    */
    private getReg() {
        let promise = cvat.management.getRegistrationStatus();
        promise.then(status => {
            console.log(status)
            this.setState({ registrationLoader:false, registrationStatus: status[0].registrationOpen })
        })
    }

    /**
     * @description calls for registration status, sets state
     */
    private getTestThreshold() {
        let promise = cvat.management.getThresholdStatus();
        promise.then(status => {
            this.setState({ testThresHold: status[0].threshold })
        })
    }

    private getPaymentConstant() {
        let promise = cvat.management.getPaymentConstant();
        promise.then(resp => {
            this.setState({ paymentConstant: resp.value });
        })
    }

    private getAutoSaveDuration() {
        let promise = cvat.management.getAutoSaveDuration();
        promise.then(resp => {
            this.setState({ autoSaveDuration: resp.value });
        })
    }


    /**
     * @description calls for job threshold status, sets state
     */
    private getJobThreshold() {
        let promise = cvat.management.getJobThresholdStatus();
        promise.then(status => {
            this.setState({ jobThresHold: status[0].threshold })
        })
    }

    /**
     * @description calls for session timeout duration, sets state
     */
    private getSessionTimeoutDuration() {
        let promise = cvat.management.getSessionTimeoutDuration();
        promise.then(status => {
            this.setState({ sessionTimeoutDuration: status[0].value })
        })
    }

    /**
     * @description calls for payment limit status, sets state
     */
    private getPaymentLimitStatus() {
        let promise = cvat.management.getPaymentLimitStatus();
        promise.then(status => {
            this.setState({ paymenMinLimit: status[0].minimum, paymenMaxLimit: status[0].maximum })
        })
    }

    /**
     * @description calls for job complete times status, sets state
     */
    private getJobCompleteTimesStatus() {
        let promise = cvat.management.getJobCompleteTimesStatus();
        promise.then(status => {
            this.setState({ annotationJobCompleteTime: status[0].annotation_job, evaluationJobCompleteTime: status[0].evaluation_job })
        })
    }

    /**
     * @description calls for annotation price status, sets state
     */
    private getAnnotationPrice() {
        let promise = cvat.management.getAnnotationPriceStatus();
        promise.then(status => {
            this.setState({ annotationPrice: status[0].price })
        })
    }

    /**
     * @description calls for faq, sets state
     */
    private getFaq() {
        let promise = cvat.management.getFaq();
        promise.then(
            faqRes => {
                console.log(faqRes);
                this.setState({
                    faqs: faqRes,
                    initLoading: false
                })

            }
        )
    }

    /**
     * @description calls for level score interval, sets state
     */
    private getLevelScoreIntervals() {
        let promise = cvat.management.getLevelScoreIntervals();
        promise.then(result => {
            this.setState({ levelScoreIntervals: result, updatedLevelScoreIntervals: result })
        })
    }

    /**
     *
     * @returns boolean on if updated level score intervals is correct
     */
    private checkUpdatedLevelScoreIntervals() {

        let updatedLevelScoreIntervals = this.state.updatedLevelScoreIntervals;
        let prevMaxScore = -1;

        for (var i=0; i<updatedLevelScoreIntervals.length; i++) {
            if(updatedLevelScoreIntervals[i].maxScore < updatedLevelScoreIntervals[i].minScore) {
                return false;
            }
            if (updatedLevelScoreIntervals[i].minScore != prevMaxScore+1) {
                return false;
            }
            prevMaxScore = updatedLevelScoreIntervals[i].maxScore;
        }
        return true;
    }

    /**
     * @description modal handling on level score interval update
     */
    private modalUpdateLevelScoreIntervalsOnOk() {

        this.setState({updateLevelScoreIntervalsStatusVisible:false});
        Modal.destroyAll();
    }

    /**
     * @async
     * @description updates level score intervals by update request, input error handling
     */
    async updateLevelScoreIntervals() {

        if(!this.checkUpdatedLevelScoreIntervals()) {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Güncellemiş olduğunuz puan seviye aralıklarını kontrol ediniz. Bir seviyenin alt limiti üst limitinden büyük olamaz. Bir üst seviyenin alt limiti daha düşük bir seviyenin üst limitinden küçük olamaz. Bütün puan aralıklarını kapsadığınızdan emin olun.',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
            return;
        }

        this.setState({updateLevelScoreIntervalsStatus: "Seviyeler güncelleniyor..."});
        this.setState({updateLevelScoreIntervalsStatusVisible:true});

        let response = await cvat.management.updateLevelScoreIntervals(this.state.updatedLevelScoreIntervals);

        if (response.status ==  i18n.t("response-messages.success")) {

            try{
                await this.wait(this,response.id);
            } catch(exception) { //TODO: güncellemeyi geri al
                Modal.error({ //TODO: exception geliyor mu kontrol et
                    title: i18n.t('keywords.error'),
                    content: 'Kullanıcı seviyeleri güncellenemedi!',
                    onOk : () => {
                        Modal.destroyAll();
                    },
                });
            }
            Modal.info({
                title: i18n.t('response-messages.info'),
                content: 'Puan seviye aralıkları başarıyla güncellendi!',
                onOk : () => {
                    this.modalUpdateLevelScoreIntervalsOnOk();
                },
            });
            this.setState({levelScoreIntervals: this.state.updatedLevelScoreIntervals})
        } else {
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Puan seviye aralıkları güncellenemedi!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }
    }

    /**
     * @async
     * @param self
     * @param id
     * @description sets state for level score interval status and error handling
     */
    async wait(self:any, id:any) {
        return new Promise((resolve, reject) => {
            async function checkStatus() {
                try {
                    const response = await cvat.management.checkUpdateLevelScoreIntervalsStatus(id);
                    self.setState({updateLevelScoreIntervalsStatus: self.getStatusMessage(response.data.state)})
                    if (['Queued', 'Started'].includes(response.data.state)) {
                        if (response.data.message !== '') {
                            self.setState({updateLevelScoreIntervalsStatus:response.data.message})
                        }
                        setTimeout(checkStatus, 1000);
                    } else if (response.data.state === 'Finished') {
                        resolve();
                    } else if (response.data.state === 'Failed') {
                        reject();
                    } else {
                        reject();
                    }
                } catch (errorData) {
                    reject(
                        // generateError(errorData),
                    );
                }
            }
            setTimeout(checkStatus, 1000);
        });
    }

    /**
     *
     * @param state containing user level
     * @returns user level name
     */
    private getUserLevelName(state: String) {
        switch (state) {
            case 'freshman':
                return 'Seviye 1 (Çaylak)';
            case 'sophomore':
                return 'Seviye 2 (Azimli)';
            case 'junior':
                return 'Seviye 3 (Deneyimli)';
            case 'senior':
                return 'Seviye 4 (Üstat)';
            case 'master':
                return 'Seviye 5 (Büyük Üstat)';
        }
    }

    /**
     *
     * @param state containing status
     * @returns status message
     */
    private getStatusMessage(state: String) {
        switch (state) {
            case 'Started':
                return 'Başladı';
            case 'Queued':
                return 'Kuyrukta Bekliyor';
            case 'Finished':
                return 'Tamamlandı';
            case 'Failed':
                return 'Başarısız oldu';
        }
    }

    /**
     * @async
     * @param checked
     * @description sends update registration status request
     */
    private async setRegistration(checked: boolean) {
        if (checked)
            this.setState({ registrationLoader: true })
        console.log(`switch to ${checked}`)
        let promise = cvat.management.updateRegistrationStatus(checked);
        promise.then(
            val => {
                this.getReg()
            }
        )
    }

    /**
     * @async
     * @description sends update test threshold status request
     */
    private async setTestThreshold() {
        let promise = cvat.management.updateThresholdStatus(this.state.testThresHold);
        promise.then(
            val => {
                this.getTestThreshold()
            }
        )
    }

    /**
     * @async
     * @description sends update job threshold status request
     */
    private async setJobThreshold() {
        let promise = cvat.management.updateJobThresholdStatus(this.state.jobThresHold);
        promise.then(
            val => {
                this.getJobThreshold()
            }
        )
    }

    /**
     * @async
     * @description sends update annotation price status request
     */
    private async setAnnotationPrice() {
        let promise = cvat.management.updateAnnotationPriceStatus(this.state.annotationPrice);
        promise.then(
            val => {
                this.getAnnotationPrice()
            }
        )
    }

    /**
     * @async
     * @description sends update payment limit status request and error handling
     */
    private async setPaymentLimit() {
        if(this.state.paymenMinLimit > this.state.paymenMaxLimit){
            Modal.error({
                title: i18n.t('keywords.error'),
                content: 'Ödeme alt limiti üst limitinden büyük olmamalı!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
        }else{
            let promise = cvat.management.updatePaymentLimitStatus(this.state.paymenMinLimit, this.state.paymenMaxLimit);
            promise.then(
                val => {
                    this.getPaymentLimitStatus()
                }
            )
        }
    }

    /**
     * @async
     * @description sends update job complete times status
     */
    private async setJobCompleteTimes() {
        let promise = cvat.management.updateJobCompleteTimesStatus(this.state.annotationJobCompleteTime, this.state.evaluationJobCompleteTime);
        promise.then(
            val => {
                this.getJobCompleteTimesStatus()
            }
        )
    }

    /**
     * @async
     * @description sends update session timeout duration status
     */
    private async setSessionTimeoutDuration() {
        let promise = cvat.management.updateSessionTimeoutDuration(this.state.sessionTimeoutDuration);
        promise.then(
            val => {
                this.getSessionTimeoutDuration()
            }
        )
    }

    private async setPaymentConstant(){
        let promise = cvat.management.updatePaymentConstant(this.state.paymentConstant);
        promise.then(
            val => {
                this.getPaymentConstant()
            }
        )
    }

    private async setAutoSaveDuration(){
        if (this.state.autoSaveDuration > this.state.sessionTimeoutDuration - 1){
            Modal.error({
                title: "Hata",
                content: 'Otomatik kayıt süresi oturumun zaman aşımı süresinden küçük olmalı!',
                onOk : () => {
                    Modal.destroyAll();
                },
            });
            this.getAutoSaveDuration();
            return;
        }
        let promise = cvat.management.updateAutoSaveDuration(this.state.autoSaveDuration);
        promise.then(
            val => {
                this.getAutoSaveDuration()
            }
        )
    }

    /**
     * @async
     * @description set edit faq model visibility
     */
    async editQuestion(faqItem: any) {
        this.setState({ editFaqModalVisible: true, item: faqItem })
    }

    async deleteItem(faqItem: any) {
        cvat.management.deleteFaq(faqItem.id).then(() => this.getFaq())
        //this.getFaq();
    }

    /**
     *
     * @returns Frequently Asked Questions Component
     */
    renderNewModal() {
        return (
            <NewFaqComponent
                visible={this.state.newFaqModalVisible}
                setInvisible={() => this.setState({ newFaqModalVisible: false })}
                updateFaq={this.getFaq}
            />
        )
    }

    /**
     *
     * @returns Update Frequently Asked Questions Component
     */
    renderEditModal() {
        return (
            <UpdateFaqComponent
                visible={this.state.editFaqModalVisible}
                setInvisible={() => this.setState({ editFaqModalVisible: false })}
                updateFaq={this.getFaq}
                item={this.state.item}
            />
        )
    }

    /**
     * @description set state for updated level score intervals
     */
    async refreshLevelScoreIntervals(){
        this.setState({
            updatedLevelScoreIntervals: this.state.levelScoreIntervals
        })
    }

    /**
     *
     * @param id
     * @param e min level score input event
     * @description set min value for updated level score intervals
     */
    async setMinLevelScoreIntervals(id:any, e: any){

        let val;
        if(e.target.value)
            val = parseInt(e.target.value);

        let updatedLevelScoreIntervals = [...this.state.updatedLevelScoreIntervals];
        let updatedLevelScoreInterval = {
            ...updatedLevelScoreIntervals[id-1],
            minScore: val
        }
        updatedLevelScoreIntervals[id-1] = updatedLevelScoreInterval;
        this.setState({updatedLevelScoreIntervals})
    }

    /**
     *
     * @param id
     * @param e min level score input event
     * @description set max value for updated level score intervals
     */
    async setMaxLevelScoreIntervals(id:any, e: any){

        let val;
        if(e.target.value)
            val = parseInt(e.target.value);

        let updatedLevelScoreIntervals = [...this.state.updatedLevelScoreIntervals];
        let updatedLevelScoreInterval = {
            ...updatedLevelScoreIntervals[id-1],
            maxScore: val
        }
        updatedLevelScoreIntervals[id-1] = updatedLevelScoreInterval;
        this.setState({updatedLevelScoreIntervals})
    }

    /**
     *
     * @returns Level Score Interval template
     */
    renderLevelScoreIntervals(){
        return(
            <Card style={{borderRadius: "8px"}} headStyle={{background:"#F2F4F8",color:"#0F123F",fontWeight:600}} title="Puan Seviye Aralıkları"
            extra={ <>
                { this.state.updateLevelScoreIntervalsStatusVisible && <Alert style={{display:"initial"}} message={this.state.updateLevelScoreIntervalsStatus}/>}
                <Popconfirm
                            title= "Sistemdeki bütün kullanıcıların seviyeleri güncellenecek. Değişiklikleri kaydetmek ve kullanıcı seviyelerini güncellemek istediğinize emin misiniz?"
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => this.updateLevelScoreIntervals()
                            }
                        > <Button type="default" className="button-default-dashboard-page"><Icon type="reload"/> Kaydet ve Kullanıcı Seviyelerini Güncelle</Button>
                    </Popconfirm>
                    <Popconfirm
                            title= "Değişiklikleri geri almak istediğinize emin misiniz?"
                            cancelText={i18n.t('keywords.no')}
                            okText={i18n.t('keywords.yes')}
                            onConfirm={
                                (e) => this.refreshLevelScoreIntervals()
                            }
                        > <Button type="default" className="button-default-dashboard-page"><Icon type="reload"/> Geri Al</Button>
                    </Popconfirm>
                    </> }>
                <Table
                    dataSource={this.state.updatedLevelScoreIntervals}
                    size="small"
                    // scroll={{ y: 200 }}
                    pagination={false}
                >
                    <Column title="Seviye" align="center" render={(record) => <Text>{ this.getUserLevelName(record.level)}</Text>} />
                    <Column title="Alt Limit" align="center" render={(record) => <Input type="number" value={record.minScore} onChange={(e) => this.setMinLevelScoreIntervals(record.id, e)} />}/>
                    <Column title="Üst Limit" align="center" render={(record) => <Input type="number" value={record.maxScore} onChange={(e) => this.setMaxLevelScoreIntervals(record.id, e)} />} />
                </Table>
            </Card>
        )
    }

    public onChangeTest(value: any) {
        this.setState({ testThresHold: value })
    }

    public onChangeJobThreshold(value: any) {
        this.setState({ jobThresHold: value })
    }

    public onChangeAnnotationPrice(value: any) {
        this.setState({ annotationPrice: value })
    }

    public onChangePaymentMinLimit(value: any) {
        this.setState({ paymenMinLimit: value })
    }

    public onChangePaymentMaxLimit(value: any) {
        this.setState({ paymenMaxLimit: value })
    }

    public onChangeAnnotationJobTime(value: any) {
        this.setState({ annotationJobCompleteTime: value })
    }

    public onChangeEvaluationJobTime(value: any) {
        this.setState({ evaluationJobCompleteTime: value })
    }

    public onChangeSessionTimeoutDuration(value: any) {
        this.setState({ sessionTimeoutDuration: value })
    }

    public onChangePaymentConstant(value: any) {
        this.setState({ paymentConstant: value })
    }

    public onChangeAutoSaveDuration(value: any) {
        this.setState({ autoSaveDuration: value })
    }

    private async onFaqOrderChange(items: []) {
        console.log(`FAQ order changed to ${items}`);
        let requests = []
        items.forEach((item, index) => {
            requests.push(this.changeFaqOrder(item, index));
        });

        await Promise.all(requests);
        this.getFaq();            
    }

    private async changeFaqOrder(item, toIndex){
        const { id, number, question, answer } = item;
        let resp = await cvat.management.updateFaq(id, toIndex + 1, question, answer)
        let response = resp.status;
        console.log(resp)
        if(response == "Başarılı"){
            console.log('Order succesfully changed');            
        }
        else{
            console.log('Order cannot be changed');
        }
    }

    render() {
        return (
            <div className="approval-page">
                <Row style={{ marginTop: "24px" }}>
                    <Text className="cvat-title">{i18n.t("keywords.settings")}</Text>
                    <Text style={{ float: 'right' }} strong>Versiyon {process.env.REACT_APP_VERSION}</Text>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Checkbox
                        onChange={e=>this.setRegistration(e.target.checked)}
                        checked={this.state.registrationStatus}
                    >
                        <Text style={{fontSize:"16px"}} strong>Kullanıcı Kayıt </Text><Text style={{fontSize:"16px"}}>(Kullanıcı kaydı {this.state.registrationStatus?"açık":"kapalı"})</Text>
                        <Text type="secondary"> Bu özellik etkin değilken sistem etkinleşene kadar kullanıcılar sisteme kayıt olamaz.</Text>
                    </Checkbox>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>Test Başarı Limiti: </Text>
                    <InputNumber
                        min={0}
                        max={100}
                        value={this.state.testThresHold}
                        onChange={this.onChangeTest}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                    />
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setTestThreshold}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>İş Başarı Limiti: </Text>
                    <InputNumber
                        min={0}
                        max={100}
                        value={this.state.jobThresHold}
                        onChange={this.onChangeJobThreshold}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        style ={{marginLeft: '17px'}}
                    />
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setJobThreshold}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>Etiket Fiyatı: </Text>
                    <InputNumber
                        min={0}
                        step={0.001}
                        max={100}
                        value={this.state.annotationPrice}
                        onChange={this.onChangeAnnotationPrice}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> TL</Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setAnnotationPrice}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong> Ödeme Katsayısı: </Text>
                    <InputNumber
                        min={0}
                        max={3600}
                        step={0.01}
                        value={this.state.paymentConstant}
                        onChange={this.onChangePaymentConstant}
                        style ={{marginLeft: '17px'}}
                    />
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setPaymentConstant}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>Alt Ödeme Tutarı: </Text>
                    <InputNumber
                        min={0}
                        max={100}
                        value={this.state.paymenMinLimit}
                        onChange={this.onChangePaymentMinLimit}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> TL</Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setPaymentLimit}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>Üst Ödeme Tutarı: </Text>
                    <InputNumber
                        min={0}
                        max={4000}
                        value={this.state.paymenMaxLimit}
                        onChange={this.onChangePaymentMaxLimit}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> TL</Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setPaymentLimit}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>İş Tamamlama Süresi: </Text>
                    <InputNumber
                        min={0}
                        max={100}
                        value={this.state.annotationJobCompleteTime}
                        onChange={this.onChangeAnnotationJobTime}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> Gün</Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setJobCompleteTimes}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong>Doğrulama İşi Tamamlama Süresi: </Text>
                    <InputNumber
                        min={0}
                        max={100}
                        value={this.state.evaluationJobCompleteTime}
                        onChange={this.onChangeEvaluationJobTime}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> Gün</Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setJobCompleteTimes}>
                        Uygula
                    </Button>
                </Row>
                <Row style={{marginTop:"24px"}}>
                    <Text strong> Oturumun Zaman Aşımına Uğrama Süresi: </Text>
                    <InputNumber
                        min={0}
                        max={3600}
                        value={this.state.sessionTimeoutDuration}
                        onChange={this.onChangeSessionTimeoutDuration}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> Saniye </Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setSessionTimeoutDuration}>
                        Uygula
                    </Button>
                </Row>

                <Row style={{marginTop:"24px"}}>
                    <Text strong> Otomatik Kayıt Süresi: </Text>
                    <InputNumber
                        min={0}
                        max={3600}
                        value={this.state.autoSaveDuration}
                        onChange={this.onChangeAutoSaveDuration}
                        style ={{marginLeft: '17px'}}
                    />
                    <Text strong> Saniye </Text>
                    <Button className="button-default-groups-page" size="small" style={{marginLeft:'1%'}} type="primary" onClick={this.setAutoSaveDuration}>
                        Uygula
                    </Button>
                </Row>

                <Row style={{marginTop:"24px"}}>

                {this.renderLevelScoreIntervals()}
                <br></br>

                <div style={{ padding: "1% 2% 1% 2%", left: "15.9%", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 10px 30px #B3B3B329", border: "0.5px solid #E3E6F0", borderRadius: "6px" }}>
                    <Text strong style={{fontSize:"16px"}}>Sıkça Sorulan Sorular</Text>
                    <br/>
                    <br/>
                    <Button size="small" className="button-groups-page"  id="newFaq" onClick={() => this.setState({ newFaqModalVisible: true })}>
                        Yeni Soru Ekle
                    </Button>
                    <ConfigProvider locale={tr_TR}>
                        <Row style={{marginTop:"24px"}}>
                            <Col span={12}>
                                Soru
                            </Col>
                            <Col span={12}>
                                Cevap
                            </Col>
                        </Row>
                        <Row>
                            <Divider/>
                        </Row>
                        <DragDropContext onDragEnd={(result) => {

                            if(!result.destination) return;

                            function arraymove(arr, fromIndex, toIndex) {
                                var element = arr[fromIndex];
                                arr.splice(fromIndex, 1);
                                arr.splice(toIndex, 0, element);
                            }

                            console.log(result);
                            
                            let newFaqList = [...this.state.faqs];
                            arraymove(newFaqList, result.source.index, result.destination.index);
                            this.onFaqOrderChange(newFaqList);                                
                        }}>
                            <Droppable droppableId='faqs'>
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >                                        
                                        {
                                            this.state.faqs.map((item, index) => {
                                                const { id, number, question, answer } = item;
                                                return (<Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                                                    {(provided) => (
                                                        <div 
                                                        {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                                                        >
                                                            <Row style={{
                                                                marginBottom: "8px",
                                                                maxWidth:"100%",padding:"3px",background: "#F2F4F8 0% 0% no-repeat padding-box", border: "1px solid #D1D3E2",borderRadius: "8px"
                                                            }}
                                                            >
                                                                <Col span={12}>
                                                                    {(index+1).toString()}. {question}
                                                                </Col>
                                                                <Col span={12}>
                                                                    {answer}
                                                                    <div style={{float:"right"}}>
                                                                        <Icon  type="edit" onClick={(e) => this.editQuestion(item)}/>
                                                                        <Icon style={{marginLeft:"5px",marginRight:"5px"}} type="delete" onClick={(e) => {
                                                                                Modal.confirm({
                                                                                    title: "Soru Silinecek",
                                                                                    content: "Seçtiğiniz soru kalıcı olarak silinecek. Devam Etmek istiyor musunuz?",
                                                                                    onOk: () => { this.deleteItem(item) },
                                                                                    okButtonProps: {
                                                                                        type: 'danger',
                                                                                    },
                                                                                    okText: "Sil"
                                                                                });
                                                                            }}/>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                        
                                                    )}
                                                </Draggable>)
                                            })                                            
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}                 
                            </Droppable>
                        </DragDropContext>
                </ConfigProvider>
                </div>
                </Row>

                {this.renderNewModal()}
                {this.renderEditModal()}
                <SystemResourcesComponent auth={this.props.auth}></SystemResourcesComponent>
            </div>

        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlatformManComponent));
