// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { Row, Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

import CreateTaskContent, { CreateTaskData } from './create-task-content';

interface Props {
    onCreate: (data: CreateTaskData) => void;
    status: string;
    error: string;
    installedGit: boolean;
    user: any
}

/**
 * Check mobile usage
 *
 * @returns true if mobile is used
 */
function detecMobile() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true
    } else {
        return false
    }
}
/**
 *
 * @param props consists of status, error, installedGit, user variable and onCreate function
 * @returns
 */
export default function CreateTaskPage(props: Props): JSX.Element {
    const {
        error,
        status,
        onCreate,
        installedGit,
        user
    } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if(detecMobile()){
            Modal.info({
                content: 'Ulaşmak istediğiniz sayfa mobil cihazlarda desteklenmemektedir!',
                okText: 'Tamam',
            });
            setTimeout(function(){ window.location.assign('/dashboard') }, 100);
        }
        if (error) {
            let errorCopy = error;
            const sshKeys: string[] = [];
            while (errorCopy.length) {
                const startIndex = errorCopy.search(/'ssh/);
                if (startIndex === -1) break;
                let sshKey = errorCopy.slice(startIndex + 1);
                const stopIndex = sshKey.search(/'/);
                sshKey = sshKey.slice(0, stopIndex);
                sshKeys.push(sshKey);
                errorCopy = errorCopy.slice(stopIndex + 1);
            }

            if (sshKeys.length) {
                Modal.error({
                    width: 800,
                    title: 'Could not clone the repository',
                    content: (
                        <>
                            <Paragraph>
                                <Text>Please make sure it exists and you have access</Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Consider adding the following public ssh keys to git: </Text>
                            </Paragraph>
                            <TextArea rows={10} value={sshKeys.join('\n\n')} />
                        </>
                    ),
                });
            }
        }
    }, [error]);

    return (
        <div className="approval-page">
            <Text className='cvat-title'>{t('create-task.createNewTask')}</Text>
            <Row type='flex' align='top' justify="center" className='cvat-create-task-form-wrapper'>
                    <CreateTaskContent
                        status={status}
                        onCreate={onCreate}
                        installedGit={installedGit}
                        user={user}
                    />
            </Row>
        </div>

    );
}
