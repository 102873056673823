/**
 *  @description Task Details tour steps
 */
const taskDetailsProviderSteps = [
    {
        selector: '[data-tour="step-task-name"]',
        content: "Görevin ismini burada görebilirsiniz. Görevin isminde değişiklik yapabilirsiniz. ",
    },
    {
        selector: '[data-tour="step-task-opts"]',
        content: "Görevin seçeneklerini buraya basarak görüntüleyebilirsiniz. Etiket indirme, etiket yükleme vb. gibi işlemler burada bulunur. ",
    },
    {
        selector: '[data-tour="step-task-info"]',
        content: "Görevle ilgili genel bilgileri buradan görebilirsiniz. Veri sağlayıcının sağladığı görev görseli burada gösterilir. "
    },
    {
        selector: '[data-tour="step-task-metadata-modal"]',
        content: "Görevle ilişkilendirilmiş anahtar değer çiftlerini buraya basarak görüntüleyebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-guidelines"]',
        content: "Görev ile ilgili yönergeleri burada görebilirsiniz. ",
    },
    {
        selector: '[data-tour="step-label-editor-task"]',
        content: "Göreve eklenmiş olan etiketleri burada görüntüleyebilir, yeni etiketler ekleyebilirsiniz. Varolan etiketler üzerinde değişiklik yapabilirsiniz. ",
    },
    {
        selector: '[data-tour="step-segments"]',
        content: "Burada görev segmentleri görüntülenir. Segmentlerin görevdeki hangi çerçeveler arasında olduğunu ve her segmentte çalışabilecek etiketleyici sayısını görüp, boş yeri olan istediğiniz segmentte istediğiniz etiketleyiciye iş atayabilirsiniz.  ",
    },
    {
        selector: '[data-tour="step-jobs-list"]',
        content: "Görevde çalışan etiketleyicilerin işlerini burada görüntülenir. İşe gitmek için iş adına basabilirsiniz. ",
    },
  ];

  export default taskDetailsProviderSteps;
