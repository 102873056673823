// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Model } from 'reducers/interfaces';
import UploadedModelItem from './uploaded-model-item';


interface Props {
    registeredUsers: any[];
    models: Model[];
    deleteModel(id: number): void;
}

/**
 *
 * @param props
 * @returns Uploaded Models List Component for Models Page Model List
 */
export default function UploadedModelsListComponent(props: Props): JSX.Element {
    const {
        models,
        registeredUsers,
        deleteModel,
    } = props;
    const { t } = useTranslation();

    /**
     * @description items containing UploadedModelItem Component instances to fill the Uploaded Model List table
     */
    const items = models.map((model): JSX.Element => {
        const owner = registeredUsers.filter((user) => user.id === model.ownerID)[0];
        return (
            <UploadedModelItem
                key={model.id as number}
                owner={owner}
                model={model}
                onDelete={(): void => deleteModel(model.id as number)}
            />
        );
    });

    /**
     * @description Renders List Table defining columns and passing items
     */
    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14}>
                    <Text className='cvat-text-color' strong>{t('models-page.uploadedByUser')}</Text>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14} className='cvat-models-list'>
                    <Row type='flex' align='middle' style={{ padding: '10px' }}>
                        <Col span={4} xxl={3}>
                            <Text strong>{t('keywords.framework')}</Text>
                        </Col>
                        <Col span={5} xxl={7}>
                            <Text strong>{t('keywords.name')}</Text>
                        </Col>
                        <Col span={3}>
                            <Text strong>{t('keywords.owner')}</Text>
                        </Col>
                        <Col span={4}>
                            <Text strong>{t('models-page.uploaded')}</Text>
                        </Col>
                        <Col span={5}>
                            <Text strong>{t('keywords.labels')}</Text>
                        </Col>
                        <Col span={3} xxl={2} />
                    </Row>
                    { items }
                </Col>
            </Row>
        </>
    );
}
