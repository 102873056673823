// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import { Link, withRouter } from 'react-router-dom';

import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import i18n from '../../i18n'
import ReCAPTCHA from "react-google-recaptcha";
import getCore from 'cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import "../../styles.scss"
const cvat = getCore();
let recaptchaRef;

export interface LoginData {
    username: string;
    password: string;
    captchaToken: string;
}

type LoginFormProps = {
    fetching: boolean;
    onSubmit(loginData: LoginData): void;
} & FormComponentProps;

/**
 * @class_component Login Form Component for login page on /auth-login
 */
class LoginFormComponent extends React.PureComponent<LoginFormProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            verifiedCaptcha: (cvat.config.reactAppEnv === 'production')?false:true,
            captchaToken: ""
        };
        this.invalidateCaptcha = this.invalidateCaptcha.bind(this);
    }

    private getQueryVariable(variable: string) {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    componentDidMount() {
        // Runs after the first render() lifecycle
        console.log('Hello');
        let token = this.getQueryVariable("YZP-Token");
        let yzpUsername = this.getQueryVariable("username") || token;
        if (token && process.env.DEPLOYMENT_PLACE === "SSYZ") {
            const {
                onSubmit,
            } = this.props;
            onSubmit({ username: token, password: yzpUsername, captchaToken: "" });
        }
    }

    /**
     * @description reset captcha set state
     */
    public invalidateCaptcha() {
        const { form } = this.props;

        recaptchaRef.reset();
        form.setFieldsValue({
            captcha: null
        });
        form.validateFields(['captcha'])
        this.setState({
            verifiedCaptcha: false
        })
    }

    /**
     *
     * @param e form submit event
     * @description validate form fields and call onSubmit passed with props
     */
    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
        } = this.props;

        form.getFieldDecorator('captchaToken');
        form.setFieldsValue({
            captchaToken: this.state.captchaToken
        });

        form.validateFields((error, values): void => {
            if (!error) {
                onSubmit(values);
            }
        });
    };

    /**
     *
     * @returns Captcha Field JSX Template
     */
    private renderCaptchaField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item className="login-form-captcha">
                {form.getFieldDecorator('captcha', {
                    rules: [{
                        required: true,
                        message: 'Lütfen insan olduğunuzu doğrulayınız!',
                    }],
                })(
                    <ReCAPTCHA
                        ref={el => { recaptchaRef = el; }}
                        hl="tr"
                        onExpired={() => {
                            this.setState({
                                verifiedCaptcha: false
                            })
                        }}
                        sitekey='6LfMPfMUAAAAAH4sLVJaR_1Re56ArdHUeNM0lxcP'
                        onChange={this.handleCaptcha}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     *
     * @param token
     * @description sets state handle captcha
     */
    handleCaptcha = async (token) => {
        this.setState({
            verifiedCaptcha: true,
            captchaToken: token
        })
        /*const result = await cvat.server.validateCaptcha(token)
        if (result.success) {
            this.setState({
                verifiedCaptcha: true
            })
        }*/
    }

    /**
     *
     * @returns username field JSX template
     */
    private renderUsernameField(): JSX.Element {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form.Item hasFeedback>
                {getFieldDecorator('username', {
                    rules: [{
                        required: true,
                        message: i18n.t('messages.enterEmail'),
                    },{
                        type: 'email',
                        message: 'Geçersiz e-posta adresi!',
                    }],

                })(
                    <Input
                        className="login-form-input"
                        autoComplete='email'
                        prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={i18n.t('admin-page.email')}
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     *
     * @returns password field JSX Template
     */
    private renderPasswordField(): JSX.Element {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form.Item hasFeedback>
                {getFieldDecorator('password', {
                    rules: [{
                        required: true,
                        message: i18n.t('messages.enterPassword'),
                    }],
                })(
                    <Input.Password
                        className="login-form-input"
                        autoComplete='current-password'
                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={i18n.t('keywords.password')}
                        type='password'
                    />,
                )}
            </Form.Item>
        );
    }

    /**
     *
     * @returns Login Form JSX Template by using class functions
     */
    public render(): JSX.Element {
        const { fetching } = this.props;
        return (
            <Form style={{display:"block"}} onSubmit={this.handleSubmit} className='login-form'>
                {this.renderUsernameField()}
                {this.renderPasswordField()}
                <div className='login-form-forgotpassword'>
                    <Link style={{color: "#5A5C69", fontSize:"12px", fontWeight: "bold"}} to='/auth/reset-password'> {i18n.t('login-form.forgotPassword')}</Link>
                </div>
                <br></br>
                <br></br>
                {(cvat.config.reactAppEnv === 'production' && process.env.DEVELOPMENT_PLACE === 'SSB') ? this.renderCaptchaField() : null}
                <Form.Item>
                    <Button
                        type='primary'
                        loading={fetching}
                        disabled={fetching}
                        htmlType='submit'
                        className='login-form-signin-button'
                    >
                        {i18n.t('login-form.signIn')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create<LoginFormProps>()(LoginFormComponent);
