// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

const UNDEFINED_ATTRIBUTE_VALUE = '__undefined__';
const NO_BREAK_SPACE = '\u00a0';
const CHANGELOG_URL = 'https://github.com/opencv/cvat/blob/develop/CHANGELOG.md';
const LICENSE_URL = 'https://github.com/opencv/cvat/blob/develop/LICENSE';
const GITTER_URL = 'https://gitter.im/opencv-cvat';
const GITTER_PUBLIC_URL = 'https://gitter.im/opencv-cvat/public';
const FORUM_URL = 'https://software.intel.com/en-us/forums/intel-distribution-of-openvino-toolkit';
const GITHUB_URL = 'https://github.com/opencv/cvat';
const GITHUB_IMAGE_URL = 'https://raw.githubusercontent.com/opencv/cvat/develop/cvat/apps/documentation/static/documentation/images/cvat.jpg';
const AUTO_ANNOTATION_GUIDE_URL = 'https://github.com/opencv/cvat/blob/develop/cvat/apps/auto_annotation/README.md';
const SHARE_MOUNT_GUIDE_URL = 'https://github.com/opencv/cvat/blob/master/cvat/apps/documentation/installation.md#share-path';
import {MailIcon, NewMailIcon1, NewMailIcon2, NewMailIcon3, NewMailIcon4, NewMailIcon5, NewMailIcon6, NewMailIcon7, NewMailIcon8, NewMailIcon9, NewMailIcon10} from "icons";

const AVATAR_MAP = new Map();
AVATAR_MAP.set(1,"/avatar-1.svg");
AVATAR_MAP.set(2,"/avatar-2.svg");
AVATAR_MAP.set(3,"/avatar-3.svg");
AVATAR_MAP.set(4,"/avatar-4.svg");
AVATAR_MAP.set(5,"/avatar-5.svg");
AVATAR_MAP.set(6,"/avatar-6.svg");
AVATAR_MAP.set(7,"/avatar-7.svg");
AVATAR_MAP.set(8,"/avatar-8.svg");
AVATAR_MAP.set(9,"/avatar-9.svg");
AVATAR_MAP.set(10,"/avatar-10.svg");
AVATAR_MAP.set(11,"/avatar-11.svg");
AVATAR_MAP.set(12,"/avatar-12.svg");

const AVATAR_MAP_CHAT = new Map();
AVATAR_MAP_CHAT.set(1,"/avatar-1.svg");
AVATAR_MAP_CHAT.set(2,"/avatar-2.svg");
AVATAR_MAP_CHAT.set(3,"/avatar-3.svg");
AVATAR_MAP_CHAT.set(4,"/avatar-4.svg");
AVATAR_MAP_CHAT.set(5,"/avatar-5.svg");
AVATAR_MAP_CHAT.set(6,"/avatar-6.svg");
AVATAR_MAP_CHAT.set(7,"/avatar-7.svg");
AVATAR_MAP_CHAT.set(8,"/avatar-8.svg");
AVATAR_MAP_CHAT.set(9,"/avatar-9.svg");
AVATAR_MAP_CHAT.set(10,"/avatar-10.svg");
AVATAR_MAP_CHAT.set(11,"/avatar-11.svg");
AVATAR_MAP_CHAT.set(12,"/avatar-12.svg");
AVATAR_MAP_CHAT.set(13,"/avatar-13.svg");


const AVATAR_LEVEL_MAP = new Map();
for(let i = 1; i <=12;i++){
    for(let j = 1; j <=5;j++){
        AVATAR_LEVEL_MAP.set(`${i}${j}`,`images/avatars/Avatar${i}L${j}.svg`);
    }
}

const AVATAR_LEVEL_MAP_EXPERT = new Map();
for(let i = 1; i <=12;i++){
    for(let j = 1; j <=5;j++){
        AVATAR_LEVEL_MAP_EXPERT.set(`${i}${j}`,`images/avatars/experts/Avatar${i}L${j}Exp.svg`);
    }
}

const NEW_MESSAGE_MAP = new Map();
NEW_MESSAGE_MAP.set(0, MailIcon);
NEW_MESSAGE_MAP.set(1, NewMailIcon1);
NEW_MESSAGE_MAP.set(2, NewMailIcon2);
NEW_MESSAGE_MAP.set(3, NewMailIcon3);
NEW_MESSAGE_MAP.set(4, NewMailIcon4);
NEW_MESSAGE_MAP.set(5, NewMailIcon5);
NEW_MESSAGE_MAP.set(6, NewMailIcon6);
NEW_MESSAGE_MAP.set(7, NewMailIcon7);
NEW_MESSAGE_MAP.set(8, NewMailIcon8);
NEW_MESSAGE_MAP.set(9, NewMailIcon9);
NEW_MESSAGE_MAP.set(10, NewMailIcon10);

export default {
    UNDEFINED_ATTRIBUTE_VALUE,
    NO_BREAK_SPACE,
    CHANGELOG_URL,
    LICENSE_URL,
    GITTER_URL,
    GITTER_PUBLIC_URL,
    FORUM_URL,
    GITHUB_URL,
    GITHUB_IMAGE_URL,
    AUTO_ANNOTATION_GUIDE_URL,
    SHARE_MOUNT_GUIDE_URL,
    AVATAR_MAP,
    AVATAR_LEVEL_MAP,
    AVATAR_LEVEL_MAP_EXPERT,
    NEW_MESSAGE_MAP,
    AVATAR_MAP_CHAT
};
