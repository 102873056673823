import React from 'react';

import {
    Row,
    Col,
    Alert,
    Button,
    notification,
    Table,
    Collapse,
    Popconfirm
} from 'antd';
import i18n from '../../i18n'

import Text from 'antd/lib/typography/Text';

import FileManagerContainer from 'containers/file-manager/file-manager';
import { Files } from 'components/file-manager/file-manager';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import MaskConfigurationForm, { MaskConfiguration } from 'components/modify-dataset-page/masking-form';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import {VideoTypeList} from 'containers/video-types';
import {isVideo} from '../file-type-validator';
import {FileResourceTypeSelection} from 'cvat-core/src/enums';

let masks = new Array()
let result = ''

export interface ModifyDatasetData {
    basic: BaseConfiguration;
    files: Files;
}

interface Props {
    onCreate: (data: ModifyDatasetData) => any;
    status: string;
    installedGit: boolean;
    user: any;
}

type State = ModifyDatasetData;

const defaultState = {
    basic: {
        name: '',
        maskStartFrame: '',
        maskStopFrame: '',
        maskJson: {},
        maskListView: [],
        datasetId: ''
    },
    files: {
        local: [],
        share: [],
        remote: [],
    },
    fileType: '',
    enableMask: false
};

/**
 * @class_component ModifyDatasetContent Component for modify-dataset-page
 */
export default class ModifyDatasetContent extends React.PureComponent<Props, State> {
    private basicConfigurationComponent: any;
    private fileManagerContainer: any;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
        this.getFileType = this.getFileType.bind(this);
    }

    /**
     *
     * @param type
     * @description set state according to type param on file type and mask enabling
     */
    public getFileType(type:string){
       if(type === FileResourceTypeSelection.SHARED){
           this.setState({enableMask:true});
        }
        else if(type !== ""){
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(type);
            const fileType = ext[1] ? ext[1] : this.state.fileType;
            this.setState({
                fileType: fileType,
                enableMask: (isVideo(ext[1]) || isVideo(fileType))
            });
        }
        else{
            this.setState({
                fileType:"",
                enableMask:false
            });
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        const { status } = this.props;

        if (status === 'CREATED' && prevProps.status !== 'CREATED') {
            notification.info({
                message: i18n.t('create-dataset.datasetUploaded'),
            });

            this.basicConfigurationComponent.resetFields();
            this.fileManagerContainer.reset();

            this.setState({
                ...defaultState,
            });
            this.setState({
                fileType: "",
                enableMask: false
            })
        }
    }


    /**
     *
     * @returns boolean checking if validation success on files
     */
    private validateFiles = (): boolean => {
        const files = this.fileManagerContainer.getFiles();
        files.remote = files.remote.filter(fileURL => fileURL.replace(/\s/g, '').length)
        this.setState({
            files
        });
        const totalLen = Object.keys(files).reduce(
            (acc, key) => acc + files[key].length, 0,
        );

        return !!totalLen;
    };

    /**
     *
     * @param values
     * @description sets state n basic configuration
     */
    private handleSubmitBasicConfiguration = (values: BaseConfiguration): void => {
        this.setState({
            basic: { ...values },
        });
    };

    /**
     *
     * @param values
     * @description sets state on mask configuration
     */
    private handleSubmitMaskConfiguration = (values: MaskConfiguration): void => {
        masks.push(values)
        this.setState({
            state : this.state
        });
    };

    /**
     *
     * @returns void, sets state, validate fields and calls submit
     */
    private handleSubmitClick = (): void => {
        let url = String(window.location.href)
        let temp = url.split('/')
        let datasetId = temp[temp.length-1]
        let fileSubmit = []
        for(let i = 0; i<masks.length; i++){
            fileSubmit.push((({ fileId, start, stop }) => ({ fileId, start, stop }))(masks[i]))
        }
        this.setState({
            id:datasetId,
            maskJson:fileSubmit,
        });
        if (!this.validateFiles()) {
            notification.error({
                message: i18n.t('modify-dataset-page.couldNotModify'),
                description: i18n.t('modify-dataset-page.atLeastOneFile'),
            });
            return;
        }

        this.basicConfigurationComponent.submit()
        .then(() => {
            return new Promise((resolve): void => {
                resolve();
            });
        }).then(async (): Promise<void> => {
            const { onCreate } = this.props;
            result = await onCreate(this.state);
        }).catch((e): any => {
            notification.error({
                message: i18n.t('keywords.error'),
                description: e,
            });
            masks = []
            this.setState({
                state : this.state
            });
        });
    };

    /**
     *
     * @returns basic configuration block JSX Template
     */
    private renderBasicBlock(): JSX.Element {
        return (
            <Col span={24}>
                <BasicConfigurationForm
                    wrappedComponentRef={
                        (component: any): void => { this.basicConfigurationComponent = component; }
                    }
                    onSubmit={this.handleSubmitBasicConfiguration}
                />
            </Col>
        );
    }

    /**
     *
     * @returns Files JSX Template using FileManagerContainer Component instance for files block
     */
    private renderFilesBlock(): JSX.Element {
        return (
            <Col span={24}>
                <Text type='danger' className='cvat-text-color'>* </Text>
                <Text className='cvat-text-color'>{i18n.t('keywords.selectFiles')}:</Text>
                <FileManagerContainer
                    ref={
                        (container: any): void => { this.fileManagerContainer = container; }
                    }
                    withRemote
                    onLoad={() => this.validateFiles()}
                    isModify = {true}
                    parentMethod={()=>null}
                    getFileType={this.getFileType}
                    user={this.props.user}
                />
            </Col>
        );
    }

    /*
    public addMask(start: any, stop: any){
        let id;
        if(maskArray.length == 0)
            id = 0;
        else
            id = maskArray[maskArray.length-1].id+1

        let mask = {
            "id": id,
            "start": start,
            "stop": stop
        }

        maskArray.push(
            mask
        )

        let maskTotal = {
            "maskpoint": maskArray
        }

        this.setState({
            maskStartFrame: '',
            maskStopFrame: '',
            maskJson: maskTotal,
        });

        this.renderMaskList()
    }

    public renderMaskList(){
        let maskViews = new Array();
        for (let i = 0; i < maskArray.length; i++) {
            maskViews.push(
                <ul class="list-unstyled" key={maskArray[i].id} >
                    <Row gutter = {8} >
                        <Col span = {8}>
                            <p>Başlangıç Çerçevesi: {maskArray[i].start}</p>
                        </Col>
                        <Col span = {8}>
                            <p>Bitiş Çerçevesi: {maskArray[i].stop}</p>
                        </Col>
                        <Col span = {8}>
                            <Button type='primary' size='small' ghost  onClick={(): void => {
                                let updateMaskArray = maskArray.filter(object => object.id != maskArray[i].id)
                                maskArray = updateMaskArray

                                    this.renderMaskList();

                            }}>Sil</Button>
                        </Col>
                    </Row>
                </ul>
            )
        }
        this.setState({
            maskListView : maskViews,
        })
    }


    public renderMaskingField(){
        return(
            <div>
                {(maskArray.length != 0 ) ? <p>Maskelenmiş Çerçeve Aralıkları</p> : null}
                {this.state.maskListView}
                <Button
                    type='primary'
                    size='default'
                    onClick={(): void => {
                        maskingPointsContent = <div>
                            <Row gutter = {8} style={{marginTop: '3%'}}>
                                <Col span = {6}>
                                    <Input
                                            placeholder='Start Frame'
                                            onChange={(value) => this.setState({ maskStartFrame: value.target.value })}
                                    />
                                </Col>
                                <Col span = {6}>
                                    <Input
                                            placeholder='Stop Frame'
                                            onChange={(value) => this.setState({ maskStopFrame: value.target.value })}
                                    />
                                </Col>
                                <Col span = {3}>
                                    <Button
                                        type='primary'
                                        size='middle'
                                        onClick={(): void => {
                                                if(Number(this.state.maskStartFrame) > Number(this.state.maskStopFrame)){
                                                    Modal.error({
                                                        title: 'Hata!',
                                                        content: 'Başlangıç çerçevesi bitiş çerçevesinden büyük olamaz!',
                                                    });
                                                }else if(this.state.maskStartFrame == '' || this.state.maskStartFrame == undefined ){
                                                    Modal.error({
                                                        title: 'Hata!',
                                                        content: 'Lütfen başlangıç çerçevesini seçin!',
                                                    });
                                                }else if(this.state.maskStopFrame == '' || this.state.maskStopFrame == undefined){
                                                    Modal.error({
                                                        title: 'Hata!',
                                                        content: 'Lütfen bitiş çerçevesini seçin!',
                                                    });
                                                }else{
                                                    this.addMask(this.state.maskStartFrame,this.state.maskStopFrame)
                                                    maskingPointsContent = <div></div>
                                                    this.setState({ state: this.state })
                                                }

                                            }

                                        }
                                    >Ekle</Button>
                                </Col>
                                <Col span = {3}>
                                    <Button
                                                type='primary'
                                                size='middle'
                                                onClick={(): void => {
                                                    maskingPointsContent = <div></div>
                                                    this.setState({ state: this.state })
                                                }}
                                    >İptal</Button>
                                </Col>
                            </Row>
                            </div>
                        this.setState({ state: this.state })
                    }}
        >Videoyu Maskele</Button>
                {maskingPointsContent}
            </div>
        );
    }*/

    /**
     *
     * @returns Masking Block JSX Element rendering Masking Table and confirmation pop-up
     */
    private renderMaskingsBlock(): JSX.Element {

        const columns = [
            {
                title: i18n.t('keywords.fileName'),
                dataIndex: 'fileName',
                key: 'fileName',
                render: text => <a className='cvat-text-color'>{text}</a>,
            },
            {
                title: i18n.t('keywords.start'),
                dataIndex: 'start',
                key: 'start',
                render: text => <a className='cvat-text-color'>{text}</a>,
            },
            {
                title: i18n.t('keywords.stop'),
                dataIndex: 'stop',
                key: 'stop',
                render: text => <a className='cvat-text-color'>{text}</a>,
            },
            {
                title: '',
                key: 'action',
                render: (record) => (
                      <Popconfirm
                          title={i18n.t('modify-dataset-page.deleteMaskingInterval')}
                          cancelText={i18n.t('keywords.no')}
                          okText={i18n.t('keywords.yes')}
                          onConfirm={
                              (e) => {
                                  masks.splice(masks.indexOf(record),1)
                                  this.setState({
                                    state : this.state
                                });
                              }
                          }
                      >
                          <Button > {i18n.t('keywords.delete')} </Button>
                      </Popconfirm>
                ),
              },
        ];
        return (
            <Table rowKey='fileName' size="small" locale={{emptyText: i18n.t('modify-dataset-page.noMaskingInterval')}} columns={columns} dataSource={masks}></Table>
        );
    }

    /**
     *
     * @returns Modify Dataset Content JSX Element using class functions
     */
    public render(): JSX.Element {
        const { status } = this.props;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED';

        let filteredFilesToSend = new Array()
        let filesToSend = new Array()
        if(this.state.files.local.length > 0){
            filesToSend = this.state.files.local
            filesToSend.forEach(element => {
                if(Object.values(VideoTypeList).includes(element.name.split('.').pop())){
                    filteredFilesToSend.push(element)
                }
            });
        }
        else{
            if(this.state.files.remote.length > 0)
                filesToSend = this.state.files.remote
            else
                filesToSend = this.state.files.share

            filesToSend.forEach(element => {
                if(Object.values(VideoTypeList).includes(element.split('.').pop())){
                    filteredFilesToSend.push(element)
                }
            });
        }

        return (
            <div style={{display:"contents"}}>
                <Row type='flex' justify='start' align='middle' className='cvat-create-task-content'>
                    {/* <Col span={24}>
                        <Text className='cvat-title-original'>{i18n.t('modify-dataset-page.expandDataset')}</Text>
                    </Col>*/}
                    { this.renderBasicBlock() }
                    { this.renderFilesBlock() }
                    {this.state.enableMask?<div>
                        <Col span={24}>
                        <MaskConfigurationForm
                            onSubmit={this.handleSubmitMaskConfiguration}
                            files={filteredFilesToSend}
                        />
                        </Col>
                        <Col span={24}>
                            <Collapse defaultActiveKey={"1"} accordion style={{marginBottom: '3%'}}>
                                <CollapsePanel header={i18n.t('keywords.maskedIntervals')} key={1}>
                                    {this.renderMaskingsBlock()}
                                </CollapsePanel>
                            </Collapse>
                        </Col>
                        </div>:null }
                    {loading? <Col span={18}>
                        <Alert message={status} /></Col> : null}
                </Row>


                <Row style={{marginTop:"14px"}} justify="center" type="flex">
                    <Button
                        className="button-create-task"
                        size="large"
                        loading={loading}
                        disabled={loading}
                        type='primary'
                        onClick={this.handleSubmitClick}
                    >
                        Veri Kümesine Ekle
                    </Button>
                </Row>
            </div>
        );
    }
}
