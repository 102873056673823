// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import i18n from '../../i18n'
import { Col, Row } from 'antd';
import patterns from 'utils/validation-patterns';

export interface ResetConfirmData {
    uidb64: string,
    token: string,
    password1: string;
    password2: string;
}

type ResetConfirmFormProps = {
    onSubmit(resetConfirmData: ResetConfirmData): void;
} & FormComponentProps;

/**
 * @class_component Reset Confirm From Component for password reset confirmation
 */
class ResetConfirmFormComponent extends React.PureComponent<ResetConfirmFormProps> {
    /**
     *
     * @param _
     * @param value
     * @param callback
     * @description password validation handler
     */
    private validatePassword = (_: any, value: any, callback: any): void => {
        const { form } = this.props;
        if (!patterns.validatePasswordLength.pattern.test(value)) {
            callback(patterns.validatePasswordLength.message);
        }

        if (!patterns.passwordContainsNumericCharacters.pattern.test(value)) {
            callback(patterns.passwordContainsNumericCharacters.message);
        }

        if (!patterns.passwordContainsUpperCaseCharacter.pattern.test(value)) {
            callback(patterns.passwordContainsUpperCaseCharacter.message);
        }

        if (!patterns.passwordContainsSpecialCharacter.pattern.test(value)) {
            callback(patterns.passwordContainsSpecialCharacter.message);
        }

        if (!patterns.passwordContainsLowerCaseCharacter.pattern.test(value)) {
            callback(patterns.passwordContainsLowerCaseCharacter.message);
        }

        if (value) {
            form.validateFields(['password2'], { force: true });
        }
        callback();
    };

    /**
     *
     * @param rule
     * @param value
     * @param callback
     * @description validate password match between double password input field values
     */
    private validateConfirmation = (rule: any, value: any, callback: any): void => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password1')) {
            callback('Şifreler uyuşmuyor!');
        } else {
            callback();
        }
    };

    /**
     *
     * @param e form submit event
     * @description validate from fields call onSubmit passed with props
     */
    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
        } = this.props;

        const url = window.location.href.split('/')
        const token = url.pop()
        const uidb64 = url.pop()

        form.getFieldDecorator('uidb64')
        form.setFieldsValue({
            uidb64: uidb64
        });

        form.getFieldDecorator('token')
        form.setFieldsValue({
            token: token
        });

        form.validateFields((error, values): void => {
            if (!error) {
                onSubmit(values);
            }
        });
    };

    /**
     *
     * @returns password field template
     */
    private renderPasswordField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password1', {
                    rules: [{
                        required: true,
                        message: 'Lütfen şifrenizi girin!',
                    }, {
                        validator: this.validatePassword,
                    }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder='Şifre'
                />)}
            </Form.Item>
        );
    }

    /**
     *
     * @returns password confirmation field template
     */
    private renderPasswordConfirmationField(): JSX.Element {
        const { form } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password2', {
                    rules: [{
                        required: true,
                        message: 'Lütfen şifrenizi tekrar girin!',
                    }, {
                        validator: this.validateConfirmation,
                    }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder='Şifre(tekrar)'
                />)}
            </Form.Item>
        );
    }

    /**
     *
     * @returns Password Reset Confirmation Form template
     */
    public render(): JSX.Element {

        return (
            <Form onSubmit={this.handleSubmit} className='login-form'>
                {this.renderPasswordField()}
                {this.renderPasswordConfirmationField()}

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                    >
                        {i18n.t('keywords.reset')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create<ResetConfirmFormProps>()(ResetConfirmFormComponent);
