// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import Icon from 'antd/lib/icon';

import { SplitIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { ActiveControl } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    splitTrack(enabled: boolean): void;
}

/**
 * Create component to split
 *
 * @param props consists of activeControl, canvasInstance variables and splitTrack function
 * @returns Icon within Tooltip
 */
function SplitControl(props: Props): JSX.Element {
    const {
        activeControl,
        canvasInstance,
        splitTrack,
    } = props;

    const dynamicIconProps = activeControl === ActiveControl.SPLIT
        ? {
            className: 'cvat-active-canvas-control',
            onClick: (): void => {
                canvasInstance.split({ enabled: false });
                splitTrack(false);
            },
        } : {
            onClick: (): void => {
                canvasInstance.cancel();
                canvasInstance.split({ enabled: true });
                splitTrack(true);
            },
        };

    const { t } = useTranslation();

    return (
        <Tooltip title={t('standard-workspace.splitTrack')} placement='right'>
            <Icon {...dynamicIconProps} component={SplitIcon} />
        </Tooltip>
    );
}

export default React.memo(SplitControl);
