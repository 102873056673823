import React,{ useState, useEffect, useRef } from 'react';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Form, List, Avatar, Spin, Button, Icon, ConfigProvider, Modal, Col, Row } from 'antd';
// import InfiniteScroll from 'react-infinite-scroller';
import getCore from 'cvat-core-wrapper';
import Text from 'antd/lib/typography/Text';
import ContactUsComponent from 'components/contact-us-pages/contact-us-page';
import consts from 'consts';
import { ContactUserIcon, NoMessageVector } from 'icons';
const { AVATAR_MAP_CHAT } = consts;

const cvat = getCore();
interface Props {
    loading:boolean;
    users:[];
    isMobile:boolean;
    openConversation:(user:any)=>void;
    modalStatus:(visible:boolean)=>void;
    activeChatBox:any;
    auth:any;
}

/**
 * @class_component MessageUsersListComponent
 * @description Renders JSX template of user list in message page
 */
class MessageUsersListComponent extends React.PureComponent<Props&FormComponentProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPage: 1,
            modalVisible:false,
        };
    }
    renderModal(){
        return <Modal
                visible={this.state.modalVisible}
                onCancel={() => this.setState({modalVisible:false})}
                footer={[]}
                destroyOnClose={true}
                title="Kullanıcıya Ulaş"
            >
            <ContactUsComponent setModalState={(value)=>this.setState({modalVisible:value})}></ContactUsComponent>
        </Modal>
    }
    public render() {
        const style1 = {
            height: this.props.isMobile?'300px':'600px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 20px #B3B3B329',
            border: '0.5px solid #E3E6F0',
            borderRadius: '0px 0px 8px 8px',
            opacity: 1,
            marginTop:"-2px",
        };

        return (
            <div>
                <div style={{backgroundColor:"#F2F4F8",
                        height:"65px",
                        padding:"0px",
                        display:"flex",
                        margin:"auto",
                        border: "0.5px solid #E3E6F0",
                        borderRadius: "8px 8px 0px 0px",
                        width:"100%"}}>
                            <Row type="flex" style={{width:"100%",alignItems: "center"}}>
                    <Col span={8}>
                    {this.props.auth.user.groups.includes("admin")&&<Icon onClick={e=>this.setState({modalVisible:true})} style={{alignSelf:"center", justifySelf:"start",gridColumnStart:"1",marginLeft:"2%",transform:"scale(0.8)"}} component={ContactUserIcon}/>}
                    </Col>
                    <Col span={8}>
                    <Button
                        data-tour="step-new-message"
                        style={{ color:"#6C63FF", padding:"unset"}}
                            type="link"
                            icon="edit"
                            onClick={(e)=>this.props.modalStatus(true)}>
                                Yeni Mesaj Oluştur
                    </Button>
                    </Col>
                    <Col span={8}>
                    </Col>
                    </Row>
                </div>

                <div data-tour="step-user-list" style = {this.props.users.length>0?{overflow:"auto",...style1}:{overflow:"none",...style1}}>
                <ConfigProvider renderEmpty={()=>
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <Text style={{display:"block"}}>Yeni mesaj oluşturarak sohbet başlatabilir, doğrudan veri sağlayıcılarla iletişime geçebilirsiniz.</Text>
                        <br/><br/>
                        <Icon component={NoMessageVector}/>
                    </div>
                    }>
                    <List className="users-list" style={{paddingBottom:"8px"}}
                        dataSource={this.props.users}
                        renderItem={item => (
                            <List.Item className={this.props.activeChatBox&&this.props.activeChatBox.id==item.id?"ant-list-item-active":""} onClick={(e) => { this.props.openConversation(item)}} key={item.id}>
                                <div className="custom-list-item">
                                    <div className="custom-list-item-inner">
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar size={40} src={AVATAR_MAP_CHAT.get(item.avatarId)} />
                                        }
                                        title={<span style={{fontWeight:"600"}}>{item.name}</span>}
                                        description={item.content}
                                    />
                                        <div style={{fontSize:"12px"}}>{item.date} &nbsp; </div>

                                        {item.numNewMessages > 0 ? <Icon style={{color:"#FCCE5A", fontSize:"22px"}}  type="exclamation-circle" theme="filled"/> : null}
                                    </div>
                                </div>
                            </List.Item>
                        )}>
                            {this.props.loading && (<Spin style={{margin: '0 50%'}}/> )}
                    </List>
                </ConfigProvider>
                </div>
                {this.renderModal()}
            </div>
        );
    }
}

export default Form.create<FormComponentProps&Props>()(MessageUsersListComponent);
