// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import Text from 'antd/lib/typography/Text';
import Tooltip from 'antd/lib/tooltip';
import Button from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
interface Props {
    currentLabel: string;
    clientID: number;
    occluded: boolean;
    objectsCount: number;
    currentIndex: number;
    normalizedKeyMap: Record<string, string>;
    nextObject(step: number): void;
}

/**
 * Create object switcher which has tooltips and buttons
 * 
 * @param props consists of currentLabel, clientID, occluded, objectsCount, currentIndex, normalizedKeyMap variables and nextObject function
 * @returns
 */
function ObjectSwitcher(props: Props): JSX.Element {
    const {
        currentLabel,
        clientID,
        objectsCount,
        currentIndex,
        nextObject,
        normalizedKeyMap,
    } = props;
    const { t } = useTranslation();

    const title = `${currentLabel} ${clientID} [${currentIndex + 1}/${objectsCount}]`;
    return (
        <div className='attribute-annotation-sidebar-switcher'>
            <Tooltip title={`${t('object-switcher.prev')} ${normalizedKeyMap.PREVIOUS_OBJECT}`}>
                <Button disabled={objectsCount <= 1} onClick={() => nextObject(-1)}>
                    <Icon type='left' />
                </Button>
            </Tooltip>
            <Tooltip title={title}>
                <Text className='cvat-text'>{currentLabel}</Text>
                <Text className='cvat-text'>{` ${clientID} `}</Text>
                <Text strong>{`[${currentIndex + 1}/${objectsCount}]`}</Text>
            </Tooltip>
            <Tooltip title={`${t('object-switcher.next')} ${normalizedKeyMap.NEXT_OBJECT}`}>
                <Button disabled={objectsCount <= 1} onClick={() => nextObject(1)}>
                    <Icon type='right' />
                </Button>
            </Tooltip>
        </div>
    );
}

export default React.memo(ObjectSwitcher);
